import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft, faMicrophone, faInfo, faInfoCircle, faRotateRight, faShield, faCloudArrowUp, faCircleDown, faFile, faTimes, faCopy, faMagnifyingGlass, faDownload } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import { Link } from "react-router-dom";
import React from 'react';
import { render } from '@testing-library/react';
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'

import loader from "../img/sample.gif";
import '../css/roomgpt.css';
import { UserID, PlanType, EulaValue, Features, Role, TextToImageSub, ImageTransformSub, MasterId, Companyid } from "../User";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

import Roomtheme from './roomthememask';
import Roomtype from './roomtypemask';

import Roomtheme2 from './roomthememask';
import Roomtype2 from './roomtypemask';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import heic2any from "heic2any";

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;
class Aeloroomgpt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            furnishmaskvisible:false,
            archmaskvisble:false,
            masklist: [],
            
            maskbeforeedit: [],
            roomtypevalue:'Select',
            roomthemevalue:'Select',
            outputImg:[],
            isemptyRoom:false,
            processidentity:'',
            userimageurl:'',
            outimgcarousel:[],
            activecarousel:0,
            isoutdoorStruct:false
        }

    }
    historygoback = () => {
        //window.history.back();
        var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
            var previewZone = $('.remove-preview').parents('.preview-zone');
            previewZone.addClass('hidden');
            $('.dropzone-wrapper').removeClass('hidden');
            document.getElementsByClassName("dropzone")[0].value = "";
            document.getElementById("showmebtn").style.display = "none";
            this.setState({ furnishmaskvisible:false,
                archmaskvisble:false,
                masklist: [],
                
                maskbeforeedit: [],
                roomtypevalue:'Select',
                roomthemevalue:'Select',
                outputImg:[],
                isemptyRoom:false,
                processidentity:'',
                userimageurl:'',
                outimgcarousel:[],
                activecarousel:0,
                isoutdoorStruct:false  }); 
                document.getElementById("secondaryprocessdiv").style.display="none";
                document.getElementById("uploadimagediv").style.display="block";
    }
    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }
    componentDidMount() {
        this.documentinit();
    }
    managemask = (e) => {
        var identity = e.currentTarget.dataset.identity;
        document.getElementById('maskcanvas' + identity).style.display = (e.currentTarget.checked ? 'block' : 'none');
        document.getElementById('maskcanvasdup' + identity).style.display = (e.currentTarget.checked ? 'block' : 'none');
    }
    showsegregatedmask = (e) => {
        document.getElementById('segregatedmask').style.display = 'block';
        document.getElementById('maskactions').style.display = 'block';
        document.getElementById('leftpanel').classList.add('overlaydisable');
        document.getElementById('maskimagediv').style.display = 'block';
        document.getElementById('imageoutputdiv').style.display = 'none';
        
        document.getElementById('segregatedmaskarch').style.display = 'none';
        var maskbeforeedittemp = []
        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
            var elm = {};
            elm.id = el.dataset.identity;
            elm.checked = el.checked;
            maskbeforeedittemp.push(elm);
        });
        this.setState({ maskbeforeedit: maskbeforeedittemp });

        document.getElementById('maskedimagefurnish').style.display = 'block';

    }
    maskactioncancel = (e) => {
        document.getElementById('segregatedmask').style.display = 'none';
        document.getElementById('maskactions').style.display = 'none';
        document.getElementById('leftpanel').classList.remove('overlaydisable');
        document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
            el.style.display = 'none';
        });
        document.querySelectorAll('.maskedImagedup img.furnish').forEach(function (el) {
            el.style.display = 'none';
        });
        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
            el.checked = false;
        });
        try {
            this.state.maskbeforeedit.forEach(function (item) {
                if (item.checked) {
                    document.getElementById('maskcanvas' + item.id).style.display = 'block';
                    document.getElementById('maskcanvasdup' + item.id).style.display = 'block';
                    document.getElementById('maskchk' + item.id).checked = true;
                }
            });
        } catch (error) {
            
        }
        
        document.getElementById('maskimagediv').style.display = 'none';
        document.getElementById('imageoutputdiv').style.display = 'block';
    }
    setIsemaptyRoom=()=>
    {
        
        this.setState({ isemptyRoom:!this.state.isemptyRoom  }); 
    }
   
    showsegregatedmaskarch = (e) => {
        document.getElementById('segregatedmask').style.display = 'none';
        document.getElementById('segregatedmaskarch').style.display = 'none';
        document.getElementById('maskactions').style.display = 'none';
        document.getElementById('maskimagediv').style.display = 'none';
        document.getElementById('imageoutputdiv').style.display = 'block';
    }

    removeallmask = (e) => {
        document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
            el.style.display = 'none';
        });
        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
            el.checked = false;
        });
        document.querySelectorAll('.maskedImagedup img.furnish').forEach(function (el) {
            el.style.display = 'none';
        });
    }

    maskactionsave = (e) => {
        var selectedmask = [];
        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
            if (el.checked) {
                selectedmask.push(el.dataset.identity);
            }
        });       
              
        if (selectedmask.length <= 0) {
            this.customAlertMessage('error','Please select any one mask to generate new image.')
            return;
        }
        document.getElementById('segregatedmask').style.display = 'none';
        document.getElementById('maskactions').style.display = 'none';
        document.getElementById('leftpanel').classList.remove('overlaydisable');
        document.getElementById('maskimagediv').style.display = 'none';
        document.getElementById('imageoutputdiv').style.display = 'block';
        this.customAlertMessage('success', 'Saved successfully');
    }

    showoutputimages = (e) => {
        document.getElementById('segregatedmask').style.display = 'none';
        document.getElementById('maskactions').style.display = 'none';
        document.getElementById('leftpanel').classList.remove('overlaydisable');
        document.getElementById('maskimagediv').style.display = 'none';
        document.getElementById('imageoutputdiv').style.display = 'block';
    }


    documentinit = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('3').classList.add("active");
        $(".dropzone").on("change", function () {
            var tempfile = $(".dropzone")[0].files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                self.customAlertMessage('error', 'File type not supported.');
                return;
            }
            if (tempfile.size < 10485760) {
                self.state.uploadedfile = tempfile;
                if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
                    self.readFile();
                }
            }
            else {
                self.customAlertMessage('error', 'Maximum file size exceeded.');
                return;
            }
        });

        $('.dropzone-wrapper').on('dragover', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').addClass('dragover');
        });

        $('.dropzone-wrapper').on('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').removeClass('dragover');
        });
        document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {
            event.preventDefault();
            var tempfile = event.dataTransfer.files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                this.customAlertMessage('error', 'File type not supported.');
                return;
            }
            self.state.uploadedfile = tempfile;
            if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
                self.readFileondrop(event.dataTransfer);
            }
            document.getElementById("showmebtn").style.display = "inline-block";
        });

        $('.remove-preview').on('click', function () {
            var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
            var previewZone = $('.remove-preview').parents('.preview-zone');
            previewZone.addClass('hidden');
            $('.dropzone-wrapper').removeClass('hidden');
            document.getElementsByClassName("dropzone")[0].value = "";
            document.getElementById("showmebtn").style.display = "none";
        });

    }
    blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

    readiphoneFile = () => {
        var self = this;
        var input = $('.dropzone')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {                
                fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file });

                var wrapperZone = $(input).parent();
                var previewZone = $(input).parent().parent().find('.preview-zone');
                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                $('.dropzone-wrapper').addClass('hidden');
                document.getElementById("showmebtn").style.display = "inline-block";
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    readiphoneFileondrop = (input) => {
        var self = this;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob }))
                .then((conversionResult) => {
                    // conversionResult is a BLOB
                    // of the PNG formatted image
                    var url = URL.createObjectURL(conversionResult);
                    var file = new File([conversionResult],  uuidv4() + ".png");
                    self.setState({ processedimage: url,uploadedfile:file });                    
                    var wrapperZone = $('.dropzone-wrapper');
                    var previewZone = $('.preview-zone');
                    wrapperZone.removeClass('dragover');
                    previewZone.removeClass('hidden');
                    $('.dropzone-wrapper').addClass('hidden');
                    self.hideoverlayiphone();
                })
                .catch((e) => {
                    // see error handling section
                    self.customAlertMessage('error','Error occured, please try again.'); 
                    self.hideoverlayiphone();
                });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    showoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "none";
        $('body').removeClass("loader");
    }

    readFile = () => {
        var self = this;
        var input = $('.dropzone')[0];

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                self.setState({ processedimage: e.target.result });
                var wrapperZone = $(input).parent();
                var previewZone = $(input).parent().parent().find('.preview-zone');
                var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');

                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                //boxZone.empty();
                //boxZone.append(htmlPreview);
                $('.dropzone-wrapper').addClass('hidden');
                document.getElementById("showmebtn").style.display = "inline-block";
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    readFileondrop = (input) => {
        var self = this;
        //var input = $('.dropzone')[0];

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                self.setState({ processedimage: e.target.result });
                //self.state.processedimage = e.target.result;
                //var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
                var wrapperZone = $('.dropzone-wrapper');
                var previewZone = $('.preview-zone');
                //var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');

                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                //boxZone.empty();
                //boxZone.append(htmlPreview);
                $('.dropzone-wrapper').addClass('hidden');

            };

            reader.readAsDataURL(input.files[0]);
            //document.getElementById('srcImg').src=this.state.processedimage;
        }
    }
    handleDownload = (e) => {
        var self = this;
		var url = e.currentTarget.dataset.fileurl;
        this.showoverlaytwo();
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('imgurl', url);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ai.chocolatechips.ai/watermarkchecker',
        data : data
        };

        axios.request(config)
        .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data != undefined) {
            if (response.data.output == true) {
                self.hideoverlaytwo();
                self.customAlertMessage('error','Watermarks detected, the download is not allowed.');    
            }
            else{
                var axiosint = require('axios');
                axiosint.get(url, {
                    responseType: 'blob',
                }).then((res) => {
                    var filename = uuidv4() + ".png";
                    fileDownload(res.data, filename);
                    self.hideoverlaytwo();
                })
            }            
        }
        })
        .catch((error) => {
        console.log(error);
        self.hideoverlaytwo();
        });
		
	}
    surprisemeimage = () => {
        var self = this;
        var roomtypemaskinit = document.getElementById('roomtypemaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        var roomthememaskinit = document.getElementById('roomthememaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        var colorprefinit = document.getElementById('colorprefinit').value;
        if (roomtypemaskinit == '' || roomtypemaskinit == 'Select') {
            this.customAlertMessage('error', 'Please select space type.');
            return;
        }
        if (roomthememaskinit == '' || roomthememaskinit == 'Select') {
            this.customAlertMessage('error', 'Please select design theme.');
            return;
        }
        if(roomtypemaskinit=='Backyard'||roomtypemaskinit=='Garden/Landscaping'||roomtypemaskinit=='Outdoor Living'
           ||roomtypemaskinit=='Poolside'||roomtypemaskinit=='Deck/Patio')
           {
            self.setState({isoutdoorStruct:true});
           }
           else
           {
            self.setState({isoutdoorStruct:false});
           }
        
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var prompt = this.getpromptinit();
        data.append('file', self.state.uploadedfile);
        data.append('prompt', prompt);
        data.append('uid', UserID);
        data.append('companyid', Companyid);
        data.append('IsEmptyRoom', this.state.isemptyRoom);
        this.showoverlay();
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatapi.aelo.ai/api/ArtificialIntelligence/propertyrecreate',
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data != undefined && response.data.Data != "error") {
                    var resultdata = JSON.parse(response.data.Data);
                    var resultdataarr = resultdata.Result;
                    var roomtype = roomtypemaskinit;
                    var roomtheme = roomthememaskinit;
            		var outputimages = resultdata.outputImg;
                    var outputimagearr = [];
                    var outputimgcarousel =[];
                    var inputimg = {};
                    inputimg.identity = -1;
                    inputimg.outimg = self.state.processedimage;
                    inputimg.original = true;
                    outputimgcarousel.push(inputimg);
                    for (let i = 0; i < outputimages.length; i++) {
                        var elem = {};
                        elem.identity = i+1;
                        elem.outimg = outputimages[i]+'?v='+Date.now();
                        outputimagearr.push(elem);
                        elem.original = false;
                        outputimgcarousel.push(elem);
                    }
                    var _processidentity = resultdata.processId;
                    var _userimageurl = resultdata.orignalImg;
            		self.setState({ masklist: resultdataarr,roomtypevalue:roomtype,outputImg:outputimagearr,
                        processidentity: _processidentity,userimageurl:_userimageurl,roomthemevalue:roomtheme,
                        outimgcarousel:outputimgcarousel});
                    setTimeout(() => {
                        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
                            el.checked = true;
                        });
                    }, 2000);
                    document.getElementById('floorpref')
                    document.getElementById('colorpref').value = document.getElementById('colorprefinit').value;
                    document.getElementById('secondaryprocessdiv').style.display = 'block';
                    document.getElementById('uploadimagediv').style.display = 'none';
                    if(self.state.isoutdoorStruct)
                    {
                        document.getElementById('tabpanel').style.display='none';
                    }
                    
                    this.hideoverlay();

                }
                this.hideoverlay();
            })
            .catch((error) => {
                console.log(error);
                this.hideoverlay();
                this.customAlertMessage('error', 'Please try again!');
            });

    }
    surprisemeimagefromoutput = (e) => {
        var fileurl = e.currentTarget.dataset.fileurl;

        
        this.regeneratewithmaskfromoutputimage(fileurl);
    }
    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }

    showoverlayone = () => {
        document.getElementById('overlayone').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlayone = () => {
        document.getElementById('overlayone').style.display = "none";
        $('body').removeClass("loader");
    }

    showoverlaytwo = () => {
        document.getElementById('overlaytwo').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlaytwo = () => {
        document.getElementById('overlaytwo').style.display = "none";
        $('body').removeClass("loader");
    }

    toggleoptions = (e) => {
        var linktype = e.currentTarget.dataset.linktype;
        if(linktype == "architect"){
            this.setState({furnishmaskvisible: (document.getElementById('maskimagediv').style.display == 'block')});
            document.getElementById('interiordiv').style.display = 'none';
            document.getElementById('architexturaldiv').style.display = 'block';
            document.getElementById('maskarch').style.display = 'none';
            document.getElementById('maskfurnish').style.display = 'none';
            document.getElementById('furnishlink').classList.remove("tab-active");
            document.getElementById('furnishlink').classList.add("tab-unactive");
            e.currentTarget.classList.remove("tab-unactive");
            e.currentTarget.classList.add("tab-active");
            this.showarchitectureoptions();
            document.getElementById('btnfurnishredesign').style.display = 'none';
            document.getElementById('btnarchredesign').style.display = 'inline-block';
            document.getElementById('imageoutputdiv').style.display = 'block';
            document.getElementById('maskimagediv').style.display = 'none';
        }
        else{
            document.getElementById('interiordiv').style.display = 'block';
            document.getElementById('architexturaldiv').style.display = 'none';
            document.getElementById('maskarch').style.display = 'none';
            document.getElementById('maskfurnish').style.display = 'block';
            document.getElementById('architecturallnk').classList.remove("tab-active");
            document.getElementById('architecturallnk').classList.add("tab-unactive");
            e.currentTarget.classList.remove("tab-unactive");
            e.currentTarget.classList.add("tab-active");
            this.showfurnishoptions();
            document.getElementById('btnfurnishredesign').style.display = 'inline-block';
            document.getElementById('btnarchredesign').style.display = 'none';
        }
    }

    showarchitectureoptions = ()=>{
        document.getElementById('maskedimagedupfurnish').style.display = 'none';
        document.getElementById('maskedimageduparch').style.display = 'block';
            
        document.getElementById('maskedimagefurnish').style.display = 'none';
        document.getElementById('maskedimagearch').style.display = 'block';
        this.loadarchmaskforselectionddl();
    }

    showfurnishoptions = ()=>{
        document.getElementById('maskedimagedupfurnish').style.display = 'block';
        document.getElementById('maskedimageduparch').style.display = 'none';
                
        if (this.state.furnishmaskvisible) {
            document.getElementById('imageoutputdiv').style.display = 'none';
            document.getElementById('maskedimagefurnish').style.display = 'block';
            document.getElementById('maskimagediv').style.display = 'block';
            this.showsegregatedmask();
        }
        else{
            document.getElementById('imageoutputdiv').style.display = 'block';
            document.getElementById('maskedimagefurnish').style.display = 'none';
            document.getElementById('maskimagediv').style.display = 'none';
        }        
        document.getElementById('maskedimagearch').style.display = 'none';
        
    }
    loadarchmaskforselectionddl = () =>{
        var archtype = document.getElementById('archtype').value;
      
        if (archtype == 'floor') {
         document.getElementById('floorprefdiv').style.display = 'block';
        }
        else{
         document.getElementById('floorprefdiv').style.display = 'none';
        }
        var filteredmask = this.state.masklist.filter(function (entry) { return entry.MaskName === archtype;}); //this.state.masklist.filter(function (i,d){ return d.MaskName == archtype; });
        if (filteredmask.length > 0) {
          var maskidentity = filteredmask[0].Maskid;
          document.querySelectorAll('.maskedImagedup img.arch').forEach(function (el) {
            el.style.display = 'none';
          });
          document.querySelectorAll('.maskedImage img.arch').forEach(function (el) {
            el.style.display = 'none';
          });
          document.getElementById('maskcanvasduparch'+maskidentity).style.display = 'block';
          document.getElementById('maskcanvasarch'+ maskidentity).style.display = 'block';
        }
        this.showsegregatedmaskarch();
        var radio = document.getElementsByName("archradio");
        for (var i = 0; i < radio.length; i++) {   
            if (radio[i].value == archtype) {        
                radio[i].checked = true;
            }  
        }
        document.getElementById('imageoutputdiv').style.display = 'block';
        document.getElementById('maskimagediv').style.display = 'none';
    }

    managemaskradio =()=>{
        var archtype = document.querySelector('input[name="archradio"]:checked').value;
        document.getElementById('archtype').value = archtype;
        if (archtype == 'floor') {
         document.getElementById('floorprefdiv').style.display = 'block';
        }
        else{
         document.getElementById('floorprefdiv').style.display = 'none';
        }
        var filteredmask = this.state.masklist.filter(function (entry) { return entry.MaskName === archtype;}); //this.state.masklist.filter(function (i,d){ return d.MaskName == archtype; });
        if (filteredmask.length > 0) {
          var maskidentity = filteredmask[0].Maskid;
          document.querySelectorAll('.maskedImagedup img.arch').forEach(function (el) {
            el.style.display = 'none';
          });
          document.querySelectorAll('.maskedImage img.arch').forEach(function (el) {
            el.style.display = 'none';
          });
          document.getElementById('maskcanvasduparch'+maskidentity).style.display = 'block';
          document.getElementById('maskcanvasarch'+ maskidentity).style.display = 'block';
        }
        this.showsegregatedmaskarch();
        
    }

    floorprefchange =(e)=>{
       var fpref = document.getElementById('floorpref');
       if (fpref.value == '0') {
        document.getElementById('materialprefdiv').style.display = 'block';
       }
       else{
        document.getElementById('materialprefdiv').style.display = 'none';
       }
    }
    archchange = (e) => {
        var archtype = document.getElementById('archtype');
        if (archtype.value == 'floor') {
         document.getElementById('floorprefdiv').style.display = 'block';
        }
        else{
         document.getElementById('floorprefdiv').style.display = 'none';
        }
    }
    regeneratewithmaskarch = () => {
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var self = this;
        this.showoverlayone();
        var selectedmask = document.querySelector('input[name="archradio"]:checked').dataset.identity;
        
        var prompttext = this.getarchprompt();
        data.append('masklist', selectedmask.toString());
        data.append('dirname', this.state.processidentity);
        data.append('prompt', prompttext);
        data.append('imgurl', this.state.userimageurl);
        data.append('emptyspace', false);
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ai.chocolatechips.ai/recreatedesign',  
        data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(response.data);
            if (response.data != undefined && response.data.Data != "error") {
                var resultdata = response.data;
                var outputarrtemp = []
                var outputimages = resultdata.outputImg;
                var outputimagearr = [];
                var outputimgcarousel =[];
                var inputimg = {};
                inputimg.identity = -1;
                inputimg.outimg = self.state.userimageurl;
                inputimg.original = true;
                outputimgcarousel.push(inputimg);
                for (let i = 0; i < outputimages.length; i++) {
                    var elem = {};
                    elem.identity = i+1;
                    elem.outimg = outputimages[i]+'?v='+Date.now();
                    outputimagearr.push(elem);
                    elem.original = false;
                    outputimgcarousel.push(elem);
                }
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                self.setState({ outputImg: outputimagearr,userimageurl:_userimageurl,
                    outimgcarousel:outputimgcarousel });    
                self.hideallandshowoutput();
                self.hideoverlayone();
            }
            else{
                self.customAlertMessage('error','Try again, Something went wrong.');
                self.hideoverlayone();
            }
            self.hideoverlayone();

        })
        .catch((error) => {
            console.log(error);
            self.hideoverlayone();
            self.customAlertMessage('error', 'Please try again!');
        });
    }
    regeneratewithmask = () =>{
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var self = this;        
        var selectedmask = []
        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
            if (el.checked) {
                selectedmask.push(el.dataset.identity);
            }
        });       
        this.showoverlayone();
        var prompttext = this.getprompt();
        data.append('masklist', selectedmask.toString());
        data.append('dirname', this.state.processidentity);
        data.append('prompt', prompttext);
        data.append('imgurl', this.state.userimageurl);
        data.append('emptyspace', this.state.isemptyRoom);
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://ai.chocolatechips.ai/recreatedesign',  
        data : data
        };
        var roomtypemaskinit = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        if(roomtypemaskinit=='Backyard'||roomtypemaskinit=='Garden/Landscaping'||roomtypemaskinit=='Outdoor Living'
        ||roomtypemaskinit=='Poolside'||roomtypemaskinit=='Deck/Patio')
        {
         self.setState({isoutdoorStruct:true});
        }
        else
        {
         self.setState({isoutdoorStruct:false});
        }
        axios.request(config)
        .then((response) => {
            console.log(response.data);
            if (response.data != undefined && response.data.Data != "error") {
                var resultdata = response.data;
                var outputarrtemp = []
                var outputimages = resultdata.outputImg;
                var outputimagearr = [];
                var outputimgcarousel = [];
                var inputimg = {};
                inputimg.identity = -1;
                inputimg.outimg = self.state.userimageurl;
                inputimg.original = true;
                outputimgcarousel.push(inputimg);
                for (let i = 0; i < outputimages.length; i++) {
                    var elem = {};
                    elem.identity = i+1;
                    elem.outimg = outputimages[i]+'?v='+Date.now();
                    outputimagearr.push(elem);
                    elem.original = false;
                    outputimgcarousel.push(elem);
                }
                // outputimages.forEach(element => {
                //     outputarrtemp.push(element+'?v='+Date.now());
                // });
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                self.setState({ outputImg: outputimagearr,userimageurl:_userimageurl,
                    outimgcarousel:outputimgcarousel,processedimage: _userimageurl});    
                document.getElementById('carouselview').style.display = 'none';
                document.getElementById('carouselviewhide').style.display = 'flex';
                self.hideallandshowoutput();
                if(self.state.isoutdoorStruct)
                {
                    document.getElementById('tabpanel').style.display='none';
                }
                else
                {
                    document.getElementById('tabpanel').style.display='flex';
                }
                self.hideoverlayone();

            }
            else{
                self.customAlertMessage('error','Try again, Something went wrong.');
                self.hideoverlayone();
            }
            self.hideoverlayone();

        })
        .catch((error) => {
            console.log(error);
            self.hideoverlayone();
            self.customAlertMessage('error', 'Please try again!');
        });
    }
    regeneratewithmaskarchfromoutputimage = (imgurl) => {
        var self = this;
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var prompt = this.getarchprompt();
        data.append('file', imgurl);
        data.append('prompt', prompt);
        data.append('uid', UserID);
        data.append('companyid', Companyid);
        this.showoverlaytwo();
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatapi.aelo.ai/api/ArtificialIntelligence/propertyrecreatewithurl',
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data != undefined && response.data.Data != "error") {
                    var resultdata = JSON.parse(response.data.Data);
                    var resultdataarr = resultdata.Result;
            		var outputimages = resultdata.outputImg;
                    var outputimagearr = [];
                    var outputimgcarousel = [];
                    var inputimg = {};
                    inputimg.identity = -1;
                    inputimg.outimg = imgurl;
                    inputimg.original = true;
                    outputimgcarousel.push(inputimg);
                    for (let i = 0; i < outputimages.length; i++) {
                        var elem = {};
                        elem.identity = i+1;
                        elem.outimg = outputimages[i]+'?v='+Date.now();
                        outputimagearr.push(elem);
                        elem.original = false;
                        outputimgcarousel.push(elem);
                    }
                    var _processidentity = resultdata.processId;
                    var _userimageurl = resultdata.orignalImg;
            		self.setState({ masklist: resultdataarr,outputImg:outputimagearr, processidentity: _processidentity
                        ,userimageurl:_userimageurl,processedimage:_userimageurl,outimgcarousel:outputimgcarousel});              
                    

                   
                    self.hideallandshowoutput();
                    this.hideoverlaytwo();

                }
                this.hideoverlaytwo();
            })
            .catch((error) => {
                console.log(error);
                this.hideoverlaytwo();
                this.customAlertMessage('error', 'Please try again!');
            });
    }
    regeneratewithmaskfromoutputimage = (imgurl) =>{
        
        var self = this;
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var prompt = this.getprompt();
        data.append('file', imgurl);
        data.append('prompt', prompt);
        data.append('uid', UserID);
        data.append('companyid', Companyid);
        this.showoverlaytwo();
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatapi.aelo.ai/api/ArtificialIntelligence/propertyrecreatewithurl',
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data != undefined && response.data.Data != "error") {
                    var resultdata = JSON.parse(response.data.Data);
                    var resultdataarr = resultdata.Result;
            		var outputimages = resultdata.outputImg;
                    var outputimagearr = [];
                    var outputimgcarousel = [];
                    var inputimg = {};
                    inputimg.identity = -1;
                    inputimg.outimg = imgurl;
                    inputimg.original = true;
                    outputimgcarousel.push(inputimg);
                    for (let i = 0; i < outputimages.length; i++) {
                        var elem = {};
                        elem.identity = i+1;
                        elem.outimg = outputimages[i]+'?v='+Date.now();
                        outputimagearr.push(elem);
                        elem.original = false;
                        outputimgcarousel.push(elem);
                    }
                    var _processidentity = resultdata.processId;
                    var _userimageurl = resultdata.orignalImg;
            		self.setState({ masklist: resultdataarr,outputImg:outputimagearr,processidentity: _processidentity
                        ,userimageurl:_userimageurl,processedimage:_userimageurl,outimgcarousel:outputimgcarousel});
                    setTimeout(() => {
                        document.querySelectorAll('.checkbox-custom input[type="checkbox"]').forEach(function (el) {
                            el.checked = true;
                        });
                        document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
                            el.style.display = 'block';
                        });
                        document.querySelectorAll('.maskedImagedup img.furnish').forEach(function (el) {
                            el.style.display = 'block';
                        });
                    }, 2000);
                    
                    document.getElementById('furnishlink').click();
                    self.hideallandshowoutput();
                    this.hideoverlaytwo();

                }
                this.hideoverlaytwo();
            })
            .catch((error) => {
                console.log(error);
                this.hideoverlaytwo();
                this.customAlertMessage('error', 'Please try again!');
            });
    }
   
    getprompt = () => {
        var prompt = '';
        prompt += document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
        prompt += ", " + document.getElementById('roomthememask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
        prompt += ", " + document.getElementById('colorpref').value;
        return prompt;
    }
    getpromptinit = () => {        
        var prompt = '';
        prompt += document.getElementById('roomtypemaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
        prompt += ", " + document.getElementById('roomthememaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
        prompt += ", " + document.getElementById('colorprefinit').value;
        return prompt;
    }
    getarchprompt = () => {
        var prompt = '';
        var archtype = document.getElementById('archtype').value;
        prompt += archtype;
        if (archtype == 'floor') {
            var floorpref = document.getElementById('floorpref').value;            
            if (floorpref == '0') {
                prompt += ", " + document.getElementById('materialpref').value; 
            }
            else{
                prompt += ", " + floorpref; 
            }
        }
        prompt += ", " + document.getElementById('colorpref').value;
        return prompt;
    }

    showenlarged =(e)=>{
        var fileidentity = e.currentTarget.dataset.identity;        
        this.setState({activecarousel:fileidentity == "-1" ? 0 : (parseInt(fileidentity)) })
        var enlargedivs = document.querySelectorAll('.enlargedimagediv');
        enlargedivs.forEach(function (el) {
            el.style.display = 'none';
        });       
        document.getElementById('enlargedimage'+fileidentity).style.display = 'block';
        //document.getElementById('hrefoutimg').click();
        document.getElementById('carouselview').style.display = 'flex';
        document.getElementById('carouselviewhide').style.display = 'none';
        

    }
    hideallandshowoutput = ()=>{
        document.getElementById('btnCloseoutimg').click();
        document.getElementById('maskimagediv').style.display = 'none';
        document.getElementById('segregatedmask').style.display = 'none';
        document.getElementById('maskactions').style.display = 'none';
        document.getElementById('imageoutputdiv').style.display = 'block';
        // if (document.getElementsByClassName('tab-active')[0].innerText == "Furnishing") {
        //     document.getElementById('viewoutputbtn').style.display = 'none';
        // }
    }
    handlecarouselchange = (e) =>{
        var arr = this.state.outimgcarousel[e];
        this.setState({userimageurl: arr.outimg});
    }
    
    render() {
        return (
            <>
                <div className="main_content_iner overly_inner ">
                    <div className="container-fluid p-0 ">
                        <div className="row">
                            <div className="col-12">
                                <div className="title-card1">
                                    <span className="float-start">
                                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />aelo.ai</span></h4>
                                    </span>
                                </div>
                            </div>
                            <div className='col-12' id='uploadimagediv'>
                                <div className="dropzone-wrapper" style={{ width: "60%", marginBottom: "40px", marginTop: "5px" }}>
                                    <div id="overlayiphone">
                                        <div id="loading" style={{ top: "35%" }}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div> 
                                    <div className="dropzone-desc">
                                        <h4>Drag and Drop</h4>
                                        <p><b>or Click to upload</b></p>
                                        <FontAwesomeIcon icon={faCloudArrowUp} />
                                        <p>
                                            Supported Files: PNG, JPG, HEIF, HEIC.
                                        </p>
                                    </div>
                                    <input type="file" name="img_logo" className="dropzone" accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"></input>
                                </div>

                                <div className="preview-zone hidden" style={{ marginTop: "0",textAlign:"unset" }}>
                                    <div className="box box-solid">
                                        <div className="grey-box box-dream">
                                            <div className="row">
                                                <div className="col-12 top-box" id="originalimagediv" style={{ paddingTop: "0" }}>
                                                    <div className="row">
                                                        
                                                    </div>
                                                    <div className="row">
                                                        <div className='col-4'>
                                                            <div className='row'>
                                                                <div className='col-12 label-info mb-30'>
                                                                    <label>Space Type</label>
                                                                    <Roomtype selval={this.state.roomtypevalue} identity="roomtypemaskinit"/>
                                                                </div>
                                                                <div className='col-12 label-info mb-30'>
                                                                   
                                                                    <input type='checkbox' className='furnishcheckbox' checked={this.state.isemptyRoom} onChange={this.setIsemaptyRoom}></input>
                                                                    <label>Furnish Empty Space</label>
                                                                </div>
                                                                <div className='col-12 label-info mb-30'>
                                                                    <label>Design Theme</label>
                                                                    <Roomtheme selval={this.state.roomthemevalue} identity="roomthememaskinit" />
                                                                </div>
                                                                <div className='col-12 label-info mb-30'>
                                                                    <label>Enter Color and Patterns Preference</label>
                                                                    <textarea className='common-textarea' id='colorprefinit'></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8 text-center top-box" style={{ position: "relative" }}>
                                                             <div id="overlay">
                                                                <div id="loading" style={{ top: "35%" }}>
                                                                    <h2 id="loaderheading">Generating...</h2>
                                                                    <img src={loader} />
                                                                </div>
                                                            </div> 
                                                            <div className='row'>
                                                            <div className="col-12" style={{marginBottom:"20px"}}>
                                                                
                                                                <center><h5 className="right-header"><b>Uploaded Image</b></h5></center>
                                                                <img className='dreamuploaded1' src={this.state.processedimage}></img>
                                                            </div>
                                                            <div className='col-12 text-center'>
                                                                <a href="javascript:;" id="showmebtn" onClick={this.surprisemeimage} className="common-save">Show Me</a>
                                                                <a href='javascript:;' className='remove-preview common-save common-save-grey'>Clear</a>                                                                
                                                             </div>
                                                            </div>
                                                            
                                                        </div>
                                                        
                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12' id='secondaryprocessdiv' style={{display:'none'}}>
                                <div className='row'>
                                    {/* Left Area */}                            
                                    <div className='col-md-4 col-sm-12' id='leftpanel'>
                                        <div className='row border-bottom' id='tabpanel'>
                                            <div className='col-6'>
                                                <a href='javascript:;' className='tab-active' data-linktype="furnish" onClick={this.toggleoptions} id='furnishlink'>Furnishing</a>
                                            </div>
                                            <div className='col-6'>
                                                <a href='javascript:;' className='tab-unactive' data-linktype="architect" onClick={this.toggleoptions} id='architecturallnk'>Architectural</a>
                                            </div>
                                        </div>
                                        <div className='row' id='tabpanelcontent'>
                                            <div className='wrap-scroll'>
                                                <div id='interiordiv'>
                                                    <div className='col-12 label-info mb-30'>
                                                        <label>Space Type</label>
                                                        <Roomtype2 selval={this.state.roomtypevalue} identity="roomtypemask" />
                                                    </div>
                                                    <div className='col-12 label-info mb-30'>
                                                                   
                                                        <input type='checkbox' className='furnishcheckbox' checked={this.state.isemptyRoom} onChange={this.setIsemaptyRoom}></input>
                                                        <label>Furnish Empty Space</label>
                                                    </div>
                                                    <div className='col-12 label-info mb-30'>
                                                        <label>Design Theme</label>
                                                        <Roomtheme2 selval={this.state.roomthemevalue} identity="roomthememask" />
                                                    </div>
                                                </div>                                               
                                                <div id='architexturaldiv' style={{display:'none'}}>
                                                    <div className='col-12 label-info mb-30'>
                                                        <label>Select the architectural element</label>
                                                        <select id="archtype" className='form-control' onChange={this.loadarchmaskforselectionddl}>
                                                            <option value="ceiling">Ceiling</option>
                                                            <option value="floor">Floor</option>
                                                            <option value="wall">Wall</option>
                                                            {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                        d.MaskName == 'window' || d.MaskName == 'door' ?
                                                                        <option value={d.MaskName}>{d.MaskName}</option> : null
                                                                    )) : null
                                                                    }
                                                        </select>
                                                    </div>
                                                    <div className='col-12 label-info mb-30' id='floorprefdiv' style={{display:'none'}}>
                                                        <label>Select material preferences</label>
                                                        {/* <Archtype selval={this.state.roomtypevalue} dataopt="1,2,3" /> */}
                                                        <select id="floorpref" class="form-control" onChange={this.floorprefchange}>
                                                            <option value="Ceramic Tile">Ceramic Tile</option>
                                                            <option value="0">Enter manually</option>
                                                            <option value="Granite flooring">Granite flooring</option>
                                                            <option value="Grey Carpet flooring">Grey Carpet flooring</option>
                                                            <option value="Hardwood Flooring">Hardwood Flooring</option>
                                                            <option value="Laminate wooden flooring">Laminate wooden flooring</option>
                                                            <option value="Marble flooring">Marble flooring</option>
                                                            <option value="Oak wood flooring">Oak wood flooring</option>
                                                            <option value="Porcelain Tile">Porcelain Tile</option>
                                                            <option value="Vinyl flooring">Vinyl flooring</option>

                                                        </select>
                                                    </div>
                                                    <div className='col-12 label-info mb-30' id='materialprefdiv' style={{display:'none'}}>
                                                    <label>Enter Material Preferences</label>
                                                    <textarea className='common-textarea' id='materialpref'></textarea>
                                                </div>
                                                </div>
                                                <div className='col-12 label-info mb-30'>
                                                    <label>Enter Color and Patterns Preference</label>
                                                    <textarea className='common-textarea' id='colorpref'></textarea>
                                                </div>
                                                <div className='col-12 label-info mb-30' style={{display:"none"}}>
                                                    <label>Masked Image</label>
                                                    <center>
                                                        {/* <img src={this.state.processedimage} style={{ maxWidth: "100%", margin: "15px 0" }} /> */}
                                                        <div className='positionRelative' style={{ marginTop: "15px" }}>
                                                            <span className='uploadedImage'>
                                                                <center><img src={this.state.processedimage} /></center>
                                                            </span>
                                                            <span className='maskedImagedup' id='maskedimagedupfurnish'>
                                                                <center>
                                                                    {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                        d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door'
                                                                        && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building' ?
                                                                            <img id={'maskcanvasdup' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='furnish' /> : null
                                                                    )) : null
                                                                    }
                                                                </center>
                                                            </span>
                                                            <span className='maskedImagedup' id='maskedimageduparch' style={{display:'none'}}>
                                                                <center>
                                                                    {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                        d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'door'
                                                                        || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'house' || d.MaskName == 'sky' || d.MaskName == 'building' ?
                                                                            <img id={'maskcanvasduparch' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='arch' /> : null
                                                                    )) : null
                                                                    }
                                                                </center>
                                                            </span>
                                                        </div>
                                                        <a href='javascript:;' id='maskfurnish' onClick={this.showsegregatedmask} style={{ color: "#9333ea" }}>Modify mask</a>
                                                        <a href='javascript:;' id='maskarch' onClick={this.showsegregatedmaskarch} style={{ color: "#9333ea",display:'none' }}>Modify mask</a>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 text-center'>
                                                <a href="javascript:;" className='common-save' style={{textAlign:"center" }} onClick={this.regeneratewithmask} id='btnfurnishredesign'>Generate New Design</a>
                                                <a href="javascript:;" className='common-save' style={{display:'none', textAlign:"center"}} onClick={this.regeneratewithmaskarch} id='btnarchredesign'>Generate New Design</a>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right Area */}
                                    <div className='col-md-8 col-sm-12' style={{position:"relative"}}>
                                    <div id="overlayone">
                                        <div id="loading" style={{ top: "40%" }}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div> 
                                        <div className='row'>

                                            <div className='col-12' id='imageoutputdiv'>
                                                <div className='row' id="carouselviewhide">
                                                    <div className='col-6'>
                                                        <div className='originalimageright'>
                                                            <img src={this.state.processedimage} />
                                                            <label>Original Image</label>
                                                        </div>
                                                    </div>
                                                    {this.state.outputImg.length > 0 ? this.state.outputImg.map((d) => (
                                                        <div className='col-6'>
                                                               <div className='originalimageright'>
                                                                   <img data-identity={d.identity} src={d.outimg} />
                                                                   <div className='icon-block' data-identity={d.identity} onClick={this.showenlarged}>
                                                                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                                                                   </div>
                                                               </div>
                                                           </div>
                                                     )) : null
                                                    }
                                                </div>

                                                {/* New Slider Div */}

                                                <div className='row' id="carouselview" style={{display:"none"}}>
                                                    <div className='col-12'>
                                                        <Carousel selectedItem={this.state.activecarousel} onChange={this.handlecarouselchange}>                                                            
                                                            {this.state.outimgcarousel.length > 0 ? this.state.outimgcarousel.map((d) => (                                                                
                                                                    <>
                                                                    {d.original == true ? 
                                                                    <>
                                                                    <div className='originalimageright' data-identity={d.id}>
                                                                <img src={d.outimg} />
                                                                <label>Original Image</label>
                                                            </div></>
                                                            : <div className='originalimageright' data-identity={d.id}>
                                                            <span className="float-end tooltip1 aelo-download" tooltip="Download" flow="left">
                                                                <a href='javascript:;' data-fileurl={d.outimg} onClick={this.handleDownload}>
                                                                <FontAwesomeIcon
                                                                    icon={faCircleDown}
                                                                />
                                                                </a>
                                                            </span>
                                                            <img src={d.outimg} />
                                                        </div>}
                                                            
                                                            </>
                                                     )) : null
                                                    }
                                                        </Carousel>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-12' id='maskimagediv' style={{display:'none'}}>
                                                <div className='positionRelative'>
                                                    <span className='uploadedImage'>
                                                        <center><img src={this.state.processedimage} /></center>
                                                    </span>
                                                    <span className='maskedImage' id='maskedimagefurnish'>
                                                        <center>
                                                            {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                                                                && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building' ?
                                                                    <img id={'maskcanvas' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='furnish' /> : null
                                                            )) : null
                                                            }
                                                        </center>
                                                    </span>
                                                    <span className='maskedImage' id='maskedimagearch' style={{display:'none'}}>
                                                        <center>
                                                            {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' ?
                                                                    <img id={'maskcanvasarch' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='arch' /> : null
                                                            )) : null
                                                            }
                                                            
                                                        </center>
                                                    </span>                                                    
                                                </div>
                                            </div>
                                            {/* <center>
                                                <a id="viewoutputbtn" href="javascript:;" className='save-btn' onClick={this.showoutputimages} style={{fontWeight:"normal"}}>View output</a>
                                            </center> */}
                                            <div className='col-12'>
                                                <div className='row selectionDiv'>
                                                    <div className='col-12 checkbox-custom' id='segregatedmask' style={{ display: 'none' }}>
                                                        <center>
                                                            {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                                                                && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building'?
                                                                    <label><input type='checkbox' id={'maskchk' + d.Maskid} onChange={this.managemask} data-label={d.MaskName} data-identity={d.Maskid} />{d.MaskName}</label> : null
                                                            )) : null
                                                            }
                                                        </center>
                                                    </div>
                                                    <div className='col-12 checkbox-custom' id='segregatedmaskarch' style={{ display: 'none' }}>
                                                        <center>
                                                        {this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' ?
                                                                <label><input type='radio' name='archradio' onChange={this.managemaskradio} data-label={d.MaskName}  data-identity={d.Maskid} value={d.MaskName}/>{d.MaskName}</label> : null
                                                            )) : null
                                                            }
                                                        </center>
                                                    </div>
                                                    <div className='col-12' id='maskactions' style={{ display: 'none' }}>
                                                        <center>
                                                            <a href="javascript:;" className='cancel-btn' onClick={this.maskactioncancel} style={{fontWeight:"normal"}}>Cancel</a>
                                                            <a href="javascript:;" className='save-btn' onClick={this.maskactionsave} style={{fontWeight:"normal"}}>Save Changes</a>
                                                            <a href='javascript:;' style={{ display: "block", marginTop: "20px", fontWeight:"normal" }} onClick={this.removeallmask}>Remove mask from the image</a>
                                                        </center>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <a href='javascript:;' id='hrefoutimg' data-bs-toggle="modal"
                          data-bs-target="#outimgmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>
                              <div className="modal fade" id="outimgmodal" tabIndex={-1} aria-labelledby="listeningModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style={{zIndex:2999}}>
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">                  
                  <button id="btnCloseoutimg" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"560px",overflowY:"auto",paddingBottom:"15px"}}>   
              <div className="col-12" style={{position:'relative'}}>    
              <div id="overlaytwo">
                                        <div id="loading" style={{ top: "35%" }}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div>            
              {this.state.outputImg.length > 0 ? 
                    this.state.outputImg.map((d) => (
                        <div id={'enlargedimage'+d.identity} style={{display:'none'}} className='enlargedimagediv'>
                            <center><img data-identity={d.identity} src={d.outimg}  style={{maxWidth:"100%",maxHeight:"450px",width:"auto"}}/></center>
                            <div className='col-12 text-center'>
                                <a href='javascript:;' data-fileurl={d.outimg} className='cancel-btn' onClick={this.handleDownload} >Download</a>
                                <a href="javascript:;" data-fileurl={d.outimg} onClick={this.surprisemeimagefromoutput} className="save-btn">Regenerate</a>
                            </div>
                        </div>
                    )) : null
                }
              </div>
              </div>
              
          </div>
      </div>
      </div>
                    </div>
                </div>
            
            </>
        )
    }

};

export default Aeloroomgpt;