import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faChevronLeft,
  faMicrophone,
  faInfo,
  faInfoCircle,
  faRotateRight,
  faShield,
  faCloudArrowUp,
  faCircleDown,
  faFile,
  faTimes,
  faCopy,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React from "react";
// import copy from 'copy-to-clipboard';
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard } from "copy-image-clipboard";
import loader from "../img/sample.gif";
// import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import imageprocessing from  "../img/sample.gif";//"../img/imageprocessing.gif";

import errorimage from '../img/error.png'
import { Companyid, UserID } from "../User";
import segdata from '../JSON/seg.json'
import coordinatedata from '../JSON/cood.json'

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;

class Imageclassification extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { 
      listtopopulaate:[]
  }
  }
  historygoback = () => {
    window.history.back();
  };

  componentDidMount() {
      var highlight = document.getElementsByClassName('li-box');//document.getElementById('tv');
      highlight.onmouseover = () => {
        console.log('mouse over');
          // document.getElementById('tvlabel').classList.add("highlight");
          // document.getElementById('firelabel').classList.add("highlight-off");
          // document.getElementById('hardwoodlabel').classList.add("highlight-off");
          // document.getElementById('ceilinglabel').classList.add("highlight-off");
      }
      highlight.onmouseout = () => {
        console.log('mouse out');
        // document.getElementById('tvlabel').classList.remove("highlight");
        // document.getElementById('firelabel').classList.remove("highlight-off");
        //   document.getElementById('hardwoodlabel').classList.remove("highlight-off");
        //   document.getElementById('ceilinglabel').classList.remove("highlight-off");
    }
    this.classifyimage();
  }

  // classifyimage = () => {
  //   var listtopopulaatetemp = [];
  //   for (let i = 0; i < segdata.length; i++) {
  //     const row = segdata[i];
  //     let xaxis = row['point_coords'][0][0];
  //     let yaxis = row['point_coords'][0][1];
  //     let imgheight = row.segmentation.size[0];
  //     let imgwidth = row.segmentation.size[1];
  //     var jsr = {};          
  //     jsr.x_axis = this.calcperc(xaxis,imgwidth)+'%';
  //     jsr.y_axis = this.calcperc(yaxis,imgheight)+'%';
  //     jsr.dname = row.class_name;
  //     listtopopulaatetemp.push(jsr);
  //   }    
  //   this.setState({ listtopopulaate: listtopopulaatetemp });
  // }
  // classifyimage = () => {
  //   var listtopopulaatetemp = [];
  //   for (let i = 0; i < segdata.length; i++) {
  //     const row = segdata[i];
  //     let xaxis = row['point_coords'][0][0];
  //     let yaxis = row['point_coords'][0][1];
  //     let imgheight = row.segmentation.size[0];
  //     let imgwidth = row.segmentation.size[1];
  //     //console.log("Decoded for "+ i + ": "+ this.Decodenew(row.segmentation.counts))
  //     var jsr = {};          
  //     jsr.id = i;
  //     jsr.x_axis = this.calcperc(xaxis,imgwidth)+'%';
  //     jsr.y_axis = this.calcperc(yaxis,imgheight)+'%';
  //     jsr.dname = row.class_name;
  //     listtopopulaatetemp.push(jsr);
  //   }    
  //   this.setState({ listtopopulaate: listtopopulaatetemp });
  // }
  classifyimage = () => {
    var listtopopulaatetemp = [];
    let imgheight = coordinatedata["Height"];
    let imgwidth = coordinatedata["width"];
    for (let i = 0; i < coordinatedata.Result.length; i++) {
      const row = coordinatedata.Result[i];
      let xaxis = row['x_centroid'];
      let yaxis = row['y_centroid'];
      var jsr = {};          
      jsr.id = i;
      jsr.x_axis = this.calcperc(xaxis,imgwidth)+'%';
      jsr.y_axis = this.calcperc(yaxis,imgheight)+'%';
      jsr.dname = row.MaskName;
      jsr.maskimg = row.MaskUrl;
      listtopopulaatetemp.push(jsr);
    }    
    this.setState({ listtopopulaate: listtopopulaatetemp });
  }
decode = (str) => {
  return str.replace(/(\d+)([a-zA-A])/g, function (match, num, letter) {
    var ret = '', i;
    for (i = 0; i < parseInt(num, 10); i++) {
        ret += letter;
    }
    return ret;
});
}

Decodenew = (input) => {
  var decoded = [];  
  var result = input.replace(/(\d+\D{1})/g, function (match) {
      var current = match.slice(-1)
      var run = match.slice(0, -1);
      decoded = decoded.concat(Array.apply(null, Array(parseInt(run))).map(String.prototype.valueOf,current));
    });
  
  return decoded;
}
  calcperc = (partialValue,totalValue) =>{
    return (100 * partialValue) / totalValue;
  }

  handlemouseenter = (e)=>{
    var identitytarget = e.currentTarget.dataset.objtgt;
    const elements = document.querySelectorAll('.result-tag');
    elements.forEach((element) => { 
      if (element.id == identitytarget) {
        element.classList.add('highlight');
      }
      else{
        element.classList.add('highlight-off');
      }      
     });
  }

  
  handlemouseleave = (e)=>{
    const elements = document.querySelectorAll('.result-tag');
    elements.forEach((element) => { element.classList.remove('highlight'); });
    elements.forEach((element) => { element.classList.remove('highlight-off'); });
  }

  handlemouseentermask = (e) => {
    var identitytarget = e.currentTarget.dataset.objtgt;
    const elements = document.querySelectorAll('.result-mask img');
    elements.forEach((element) => { 
      if (element.id == ('mask'+identitytarget)) {
        element.style.display =  'block';//.classList.add('highlight');
      }
      else{
        element.style.display =  'none';
      }      
     });
  }

  handlemouseleavemask = (e)=>{
    const elements = document.querySelectorAll('.result-mask img');
    elements.forEach((element) => { element.style.display = 'none'});
    //elements.forEach((element) => { element.classList.remove('highlight-off'); });
  }

  getmask = ()=>{
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('imgurl', 'https://openaiimages.s3.us-west-1.amazonaws.com/UM6EQEN5UC.jpg');
    data.append('dirname', 'testingnew11');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://ai.chocolatechips.ai/getobjectList',
      headers: { 
        ...data.getHeaders()
      },
      data : data
    };

    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });

  }

  render() {
    return (
      <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">
            <div className="col-12">
              <div className="title-card1">
                <span className="float-start">
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.historygoback}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      Image Classification
                    </span>
                  </h4>
                </span>
              </div>
            </div>

            <div className="col-12">
              <div className="row">
                  <div className="col-12">
                      <div className="gallery-results">
                        <div className="result-image">
                          {/* <img src="https://demo.restb.ai/images/demo/demo-1.jpg" /> */}
                          <img src='https://openaiimages.s3.us-west-1.amazonaws.com/UM6EQEN5UC.jpg' />
                        </div>
                        <div className="result-mask">
                        {this.state.listtopopulaate.length > 0 ? this.state.listtopopulaate.map((d) => (
                         <img src={d.maskimg} id={'mask'+d.id} style={{display:'none'}}></img>
                        )) : null}
                          
                        </div>
                        {this.state.listtopopulaate.length > 0 ? this.state.listtopopulaate.map((d) => (
                          <div className="result-tag" id={d.id} style={{top:(d.y_axis != '' ? d.y_axis : '0%'), left: (d.x_axis != ''? d.x_axis : '0%')}} onMouseEnter={this.handlemouseentermask} onMouseLeave={this.handlemouseleavemask} data-objtgt={d.id}>
                            <label>{d.dname}</label>
                          </div>
                        )) : null}
                        {/* <div className="result-tag" id="tvlabel" style={{top:"37.25%",left:"29.849999999999998%"}}>
                            <label>TV</label>
                        </div>
                        <div className="result-tag" id="firelabel" style={{top:"60.21634615384615%",left:"29.447115384615387%"}}>
                            <label>Fireplace</label>
                        </div>
                        <div className="result-tag" id="hardwoodlabel" style={{top:"82.8125%",left:"88.70192307692308%"}}>
                            <label>Hardwood floor</label>
                        </div>
                        <div className="result-tag" id="ceilinglabel" style={{top:"18.389423076923077%",left:"50.480769230769226%"}}>
                            <label>Coffered ceiling</label>
                        </div> */}
                      </div>
                  </div>
                  <div className="col-3" style={{display:'none'}}>
                    <div className="featurs-box">
                        <h2>Living Room</h2>
                        <ul>
                        {this.state.listtopopulaate.length > 0 ? this.state.listtopopulaate.map((d) => (
                         <>
                         <li data-objtgt={d.id} onMouseEnter={this.handlemouseenter} onMouseLeave={this.handlemouseleave}><span className="li-box"></span>{d.dname}</li><br/>
                         </>                          
                        )) : null}                            
                        </ul>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Imageclassification;
