import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove, faImage, faCloudArrowUp, faClose } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, { useState } from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader, { List } from "react-content-loader";
import * as axios from 'axios';
import Cookies from 'js-cookie';
import heic2any from "heic2any";

let uploadedFile = '';
let existingData="";
// const MyLoader = () => <List />
const MyLoader = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

const MyLoader1 = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

class sherlockwatermark extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyworddisabled: true,
            tonedisabled: true,
            copySuccess: '',
            wordcount: 0,
            Tonecount: 0,
            generatedimages: [],
            emailguid: NIL_UUID,
            emailhtml: "",
            defaultcontenterased: false,
            tabledata: [],
            dashtotalcount: -1,
            dashrecentimagescount: -1,
            dashauthorizedcount: -1,
            dashunauthorizedcount: -1,
            dashlastupdated: "",
            uploadedfile: null,
            processedimage: "",
            copySuccess: '',
            uploadedbase64:""
            

        }
    }
    componentDidMount() {
        //this.loadtabledata();
        // this.setState({emailguid: uuidv4()});
        this.jquerycode();
        //this.gettoppaneldata();
        // setInterval(() => {			
        // 	this.checkandupdatecontent();
        //   }, 10000);
        this.documentinit();
        this.checkForParameters();
        if(existingData == ""){
            this.loadWaterMarkData(0); 
        }
        else{
            this.loadWaterMarkData(existingData); 
        }       
    }
    checkForParameters = () =>{
        //InserOrUpdate
        var url_string = window.location.href;
        var url = new URL(url_string);
        var paramId = url.searchParams.get("Id");
        if (paramId !=null) {
            existingData = paramId;
        } else {
            existingData ="";
        }
    }
    customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
	showoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "none";
        $('body').removeClass("loader");
    }
    // events start
    documentinit = () => {
		var self = this;
		$(".dropzone").on("change",function () {
			var tempfile = $(".dropzone")[0].files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
                    self.readFile();
                }
			}
			else {
			    this.customAlertMessage('error', 'Maximum file size exceeded.');
			    return;
			}
			//self.readFile();
		  });
  
		  $('.dropzone-wrapper').on('dragover', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').addClass('dragover');
		  });
  
		  $('.dropzone-wrapper').on('dragleave', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').removeClass('dragover');
		  });
		  document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {			
			event.preventDefault();
			var tempfile = event.dataTransfer.files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
                self.readFileondrop(event.dataTransfer);
            }
			}
			else {
				this.customAlertMessage('error', 'Maximum file size exceeded.');
				return;
			}
		});
  
		  $('.remove-preview').on('click', function () {			
			$('.preview-zone').addClass('hidden');
			$('.dropzone-wrapper').removeClass('hidden');
			document.getElementsByClassName("dropzone")[0].value = "";
		  });

		  $(".filterslist").on('click', function (event) {
			event.stopPropagation();
			event.stopImmediatePropagation();
			//(... rest of your JS code)
			var stylecode = event.target.dataset.stylecode;
			self.applyfilter(self.state.uploadedfile, stylecode);
			$(".filterslist").removeClass("active");
			event.currentTarget.classList.add("active");
		});        
	}

	blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

      readiphoneFile = () => {
        var self = this;
        var input = $('.dropzone')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {                
                fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file });
                var wrapperZone = $(input).parent();
				var previewZone = $('.preview-zone');
				//(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	
				wrapperZone.removeClass('dragover');
				previewZone.removeClass('hidden');
				$('.dropzone-wrapper').addClass('hidden');
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    readiphoneFileondrop = (input) => {
        var self = this;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob }))
                .then((conversionResult) => {
                    // conversionResult is a BLOB
                    // of the PNG formatted image
                    var url = URL.createObjectURL(conversionResult);
                    var file = new File([conversionResult],  uuidv4() + ".png");
                    self.setState({ processedimage: url,uploadedfile:file });
                    var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					$('.dropzone-wrapper').addClass('hidden');
                    self.hideoverlayiphone();
                })
                .catch((e) => {
                    // see error handling section
                    self.customAlertMessage('error','Error occured, please try again.'); 
                    self.hideoverlayiphone();
                });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

			readFile = () => {
				var self = this;
				 var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result,uploadedfile:input.files[0]});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $(input).parent();
					var previewZone = $('.preview-zone');
					//var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				}
			}

			readFileondrop = (input) => {
				var self = this;
				 //var input = $('.dropzone')[0];
				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					//var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				}
			}
	  
			reset = (e) => {
				var eleel = $('.remove-preview').parents('.form-group').find('.dropzone');
				$('#originalimagediv').hide();
				// eleel.wrap('<form>').closest('form').get(0).reset();
				// eleel.unwrap();
			}

    //events end

    loadWaterMarkData = (Id) =>{
        var self = this;
          self.showoverlay();
  
          var userI = Cookies.get('userInfo');// getAccessToken();
          var decodedUser = decodeURIComponent(userI);
          // console.log(decodedUser);
          var parsedRes = JSON.parse(decodedUser);
          var compId = parsedRes.CompanyId;
  
          var config = {
              method: 'get',
              "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetWaterMarkInfo?CompanyId="+compId+"&Id="+Id,
              headers: {
                 'Content-Type': 'application/json' 
              }
          };
  
          axios(config)
              .then(function (resp) {                  
                  var response = (resp.data);
                  if (response.Status == "200") {
                     //console.log(response.Data);
                     var compWaterMark = response.Data;
                    //  if (compWaterMark != null && compWaterMark != "" && compWaterMark.WaterMarkContent !="") {   
                        if (compWaterMark != null && compWaterMark != "") {                      
                      document.getElementById('txtWatermarkCont').value=compWaterMark.WaterMarkContent;
                      document.getElementById('imgWaterMark').src = compWaterMark.WaterMarkLogo;
                      $('.preview-zone').removeClass('hidden');
                      $('.dropzone-wrapper').addClass('hidden');
                      document.getElementById('txtWatermarkCont').setAttribute('readonly', true);  
                      document.getElementById('txtWatermarkCont').setAttribute('disabled', true);  
                     } else {
                        $('.preview-zone').addClass('hidden');
                        $('.dropzone-wrapper').removeClass('hidden');
                        document.getElementById('txtWatermarkCont').removeAttribute('readonly');
                        document.getElementById('txtWatermarkCont').removeAttribute('disabled');
                     }
                    }
                  else {
                      //self.customAlertMessage('error', response.Message); // 'success','failure','info'
                      $('.preview-zone').addClass('hidden');
                      $('.dropzone-wrapper').removeClass('hidden');
                  }
                  self.hideoverlay();
              })
              .catch(function (error) {                 
                  self.hideoverlay();
              });
      }


    jquerycode = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('1').classList.add("active");
    }


    historygoback = () => {
        window.history.back();
    }
clearImageUploaded = () =>{
    $('.preview-zone').addClass('hidden');
			$('.dropzone-wrapper').removeClass('hidden');
			document.getElementsByClassName("dropzone")[0].value = "";
            document.getElementById('txtWatermarkCont').value = "";
            // document.getElementById('txtWatermarkCont').disabled = false;
            document.getElementById('txtWatermarkCont').removeAttribute('readonly');
            document.getElementById('txtWatermarkCont').removeAttribute('disabled');
}

updateWaterMark = () =>{
        var self = this;
        var txtWatermarkCont = document.getElementById('txtWatermarkCont');
        if(txtWatermarkCont.value == "" && self.state.uploadedfile == null){
            // self.customAlertMessage("error", "Please enter watermark name.");
            self.customAlertMessage("error", "Please enter watermark name or upload logo.");
            return;
        }
        var userI = Cookies.get('userInfo');// getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        // console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var compId = parsedRes.CompanyId;
        var FormData = require('form-data');
        //var fs = require('fs');
                var data = new FormData();
        var fileInput = self.state.uploadedfile; //$(".dropzone")[0].files[0];
        if (fileInput != null && (fileInput.type === 'image/jpg'  || fileInput.type === 'image/jpeg' || fileInput.type === "image/png")) {
            data.append("img",fileInput);
        }
        data.append("WatermarkContent", txtWatermarkCont.value);
        data.append("CompanyId", compId);
        if (existingData != "") {
            data.append("InserOrUpdate","1");
            data.append("Id", existingData);
        } else {
            data.append("InserOrUpdate","0");
        }        

        self.showoverlay();

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateWaterMarkInfo",
            // "url": "https://localhost:44372/api/AIUserManager/UpdateWaterMarkInfo",
            data: data
        };

        if (txtWatermarkCont.value.trim() == "" && (fileInput != null && (fileInput.type === 'image/jpg'  || fileInput.type === 'image/jpeg' || fileInput.type === "image/png"))) {
            config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/updatewatermarklogo",
                // "url": "https://localhost:44372/api/AIUserManager/updatewatermarklogo",
                data: data
            };
        }
        else if(txtWatermarkCont.value.trim() !== "" && fileInput == null){
            config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateWaterMark",
                // "url": "https://localhost:44372/api/AIUserManager/UpdateWaterMark",
                data: data
            };
        }

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        if (responseData == "true") {
                            setTimeout(() => {
                                // document.getElementById('txtWatermarkCont').disabled = false;  
                                document.getElementById('txtWatermarkCont').setAttribute('readonly', true);  
                                document.getElementById('txtWatermarkCont').setAttribute('disabled', true);  
                            }, 2000);
                            // document.getElementById('txtWatermarkCont').disabled = false;
                            self.customAlertMessage('success', "Updated successfully.");                            
                        } else {
                            self.customAlertMessage('error', "Updated failed.");
                        }                        
                    }
                    else {
                        self.customAlertMessage('error', "Updated failed.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', "Updated failed.");
            //   self.customAlertMessage('success', "Updated successfully.");
                self.hideoverlay();
            });
}


       

    render() {
        return (
            <>            
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">                    
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4><span style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faChevronLeft} /><Link to="/sherlocksetup"><u>Sherlock Setup:</u> Watermark Logo</Link></span></h4>
                                    <p>
                                    Please upload a high-quality, clear image of your logo, brokerage logo, or any other image used as the watermark. The image should have good contrast and be free of artifacts or blurriness to enable proper analysis by our AI systems. </p>
                                </span>
                            </div>
                        </div>

                        <div className='col-6' style={{ marginBottom: "20px" }}>
                            <div className='row' style={{position:'relative'}}>
                                <div id="overlay">
                                    <div id="loading">
                                        <h2 id="loaderheading">Loading...</h2>
                                        <img src={loader} />
                                    </div>
                                </div>
                                <div className='col-12' style={{marginBottom:"30px"}}>
                                    <div className='row'>
                                        <div className='col-lg-3 col-md-3'>
                                            <label  style={{paddingTop:"15px"}}>Watermark Name</label>
                                        </div>
                                        <div className='col-lg-8 col-md-8'>
                                            <input id="txtWatermarkCont" type="text" className='common-input' />
                                        </div>
                                        <div className='col-lg-1 col-md-1'>
                                            <span tooltip="Back" className='tooltip1' onClick={this.historygoback} flow="right" style={{cursor:'pointer'}}>
                                            <FontAwesomeIcon icon={faClose} style={{fontSize:'25px',color:'#9333ea',position:'relative',top:'5px'}} />
                                            </span>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12' style={{marginBottom:"30px"}}>
                                    <div className="dropzone-wrapper dropzone-wrapper-new hidden">
                                        <h4 className="input-head">
                                            <FontAwesomeIcon icon={faImage} />
                                            Upload watermark image
                                        </h4>
                                        <div id="overlayiphone">
                                            <div id="loading" style={{ top: "35%" }}>
                                            <h2 id="loaderheading">...</h2>
                                            <img src={loader} />
                                            </div>
                                        </div>
                                        <div className="dropzone-desc">
                                            <h4>Drag and Drop</h4>
                                            <p>
                                            <b>or Click to upload</b>
                                            </p>
                                            <FontAwesomeIcon icon={faCloudArrowUp} />
                                            <p>Supported Files: PNG, JPG, HEIF, HEIC.</p>
                                            <p>Size: up to 5 MB</p>
                                        </div>
                                        <input style={{top:'0'}}
                                            type="file"
                                            name="img_logo"
                                            className="dropzone"
                                            accept="image/png, image/jpeg, image/jpg, image/heif, image/heic" 
                                        ></input>
                                    </div>
                                </div>
                                <div className='preview-zone hidden'>
                                    <div className='col-12' style={{marginBottom:"30px"}}>
                                        <label>Uploaded File:</label>
                                        <div className='watermarkbox'>
                                            {/* <img id='imgWaterMark' style={{width:"200px",height:"300px"}} src=''/> */}
                                            <img id='imgWaterMark' style={{maxWidth:"200px"}} src={this.state.processedimage}/>                                        
                                        </div>                                    
                                    </div>                                    
                                </div>
                                <div className='col-12 mb-30 text-center'>
                                        <a href="javascript:;" onClick={this.clearImageUploaded} c className="common-save common-save-grey" style={{marginRight:"10px"}}>Clear</a>                                        
                                        <a href="javascript:;" onClick={this.updateWaterMark} className="common-save">Save</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            </>
        )
    }

}

export default sherlockwatermark;