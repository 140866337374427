import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4,NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg} from "../User";
import Emailtemplatedata from "./Emailtemplatenew"


class emailtemplate extends React.Component {
    constructor(props) {
		super(props);
		this.state = { 
		  keyworddisabled: true,
		  tonedisabled:true,
		  copySuccess: '',
		  wordcount: 0,
		  Tonecount:0,
		  generatedimages: [],
		  emailguid: NIL_UUID,
		  emailhtml: "",
		  defaultcontenterased: false,
          tabledata:[]
		  
	  }
	  }
      componentDidMount(){
        //this.loadtabledata();
		// this.setState({emailguid: uuidv4()});
		this.jquerycode();
		// setInterval(() => {			
		// 	this.checkandupdatecontent();
		//   }, 10000);
	  }

      
jquerycode = () =>{
	var self = this;
	Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('1').classList.add("active");
}

      loadtabledata = () => {
        var self = this;
        var axios = require('axios');

var config = {
  method: 'get',
maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/getsavedtemplate?userid='+ UserID,
  headers: { }
};

axios(config)
.then(function (resp) {
  console.log(JSON.stringify(resp.data));
  var response = resp.data;
self.setState({tabledata:response.Data});
  console.log(response);
})
.catch(function (error) {
  console.log(error);
});

      }
	  openemaildetails = (e) => {
		var url = e.currentTarget.dataset.to;
		window.location.href = url;
	  }
	  historygoback = () => {
        window.history.back();
    }
      render() {
        return (
            <div className="main_content_iner overly_inner">
        <div className="container-fluid p-0 ">
          <div className="row">
            <div className="col-12">
				<div className="title-card1">
					<span className="float-start">
                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Email</span></h4>
					</span>		
                    <span className="float-end mt-20">
                        <Link to="/email" className="common-save float-start">Create New</Link>
					</span>
				</div>
            </div>
			
			<div className="col-12">
				<div className="row">
					<div className="col-12">       
					<Emailtemplatedata></Emailtemplatedata>                 
						{/* <table className="common-table">
							<thead>
								<tr>
									<th className="width-inc">Template Name</th>
									<th>Description</th>
									<th className="width-inc">Created</th>
									<th className="width-inc">Modified</th>
								</tr>
							</thead>
							<tbody>
                            {this.state.tabledata.length > 0 ? this.state.tabledata.map((d,index) => (
                                <tr>
									<td style={{cursor:"pointer"}}><Link to={"/email?id="+d.id} >{d.title}</Link></td>
									<td>{d.description}</td>
									<td>{moment(d.createddate).format('MMM DD YYYY h:mm A')}</td>
									<td>{moment(d.lastupdated).format('MMM DD YYYY h:mm A')}</td>
								</tr>

                            )) : <tr>
                                <td colSpan={3} className="text-center">No data found</td>
                                </tr>
                                }
							</tbody>							
						</table> */}
					</div>
				</div>
			</div>
			
			
          </div>          
        </div>
      </div>
        )
      }

}

export default emailtemplate;