import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft, faMicrophone, faInfo, faInfoCircle, faRotateRight, faShield, faCloudArrowUp, faCircleDown, faFile, faTimes, faCopy, faMagnifyingGlass, faDownload, faShare, faQuestionCircle, faClone, faClose, faCheck, faCross, faCrosshairs, faRemove, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import { Link, json } from "react-router-dom";
import React, { useRef } from 'react';
import { render } from '@testing-library/react';
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'

import loader from "../img/loader1.gif";
import '../css/roomgpt.css?v=1.0.1';
import { PlanLevel, UserID, PlanType, EulaValue, Features, Role, TextToImageSub, ImageTransformSub, MasterId, Companyid,EmailId } from "../User";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

import Roomtheme from './roomthememask';
import Roomtype from './roomtypemask';

import Roomtheme2 from './roomthememask';
import Roomtype2 from './roomtypemask';

import heic2any from "heic2any";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import Cookies from 'js-cookie';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
// import {groupedOptions,themeexterioroptions,themeinterioroptions} from "../components/Aeloadvanced";
export const getAccessToken = () => Cookies.get('userInfo');
export const interioroptions = [
    { value: "-1", label: "Select" },
    { value: "balcony", label: "Balcony" },
    { value: "bar", label: "Bar" },
    { value: "bathroom", label: "Bathroom/Ensuite" },
    { value: "bedroom", label: "Bedroom" },
    { value: "dining", label: "Dining" },
    { value: "foyer", label: "Foyer" },
    { value: "gameroom", label: "Game Room/Rumpus Room" },
    { value: "hobbyroom", label: "Hobby Room/Craft Room" },
    { value: "kidsroom", label: "Kids Room" },
    { value: "kitchen", label: "Kitchen" },
    { value: "laundry", label: "Laundry" },
    { value: "livingroom", label: "Living Room/Family Room/Lounge" },
    { value: "mediaroom", label: "Media Room" },
    { value: "nursery", label: "Nursery" },
    { value: "home office", label: "Office" },
    { value: "pantry", label: "Pantry" },
    { value: "singleroom", label: "Single Room Studio/Unit" },
    { value: "study", label: "Study" },
    { value: "sunroom", label: "Sunroom" },
    { value:"walk-in closet", label: "Walk-In Closet" }
  ];
  export const exterioroptions = [
    //{ value: "-1", label: "Select" },
    { value: "backyard", label: "Backyard" },
    { value: "deck_patio", label: "Deck/Patio" },
    { value: "exterior house architecture", label: "Exterior House Architecture" },
    { value: "front yard", label: "Front Yard" },
    { value: "garden", label: "Garden/Landscaping" },
    { value: "outdoor_living", label: "Outdoor Living" },
    { value: "swimming pool & poolside", label: "Swimming Pool & Poolside" },
  ];
  
  const groupedOptions = [
    {
      label: "Interior",
      options: interioroptions,
    },
    {
      label: "Exterior",
      options: exterioroptions,
    },
  ];
  const crazylist = [
    "AI can be like Box of Chocolates - you never know what you will get",
    "AI is a Pandora's box. What am I gonna see now?",
    "Machines can be dumber than humans but they work hard. So sit back and let them work.",
    "Did you know with AI 1+1 can be 4…. Just kidding. Not!",
    "If you find something funny laugh it, find something mediocre try again, find something spectacular grab it.",
    "AI never repeats same images, find something good, grab it or keep trying.",
    "Crazy, Stupid, or Spectacular - you may get any of these from AI Juke box.",
    "Dumb, Dumber, or Ultra Smart - AI can render one of these or shades in between",
    "Four Images or a Funeral - keep trying and have fun till you find what you like",
    "Build it and they will come - Field of AI dreams",
    "AI can be like a Raffle Draw - you never know what will come out!",
    "AI can be like a Magic Trick - you never know what will happen next!",
    "AI is like a Jack-in-the-box - you never know when it'll pop up!",
    "AI is a Pandora's box. Will I get to see what's inside?",
    "AI is a Pandora's box. Is it battle of the bots time?",
    "Did you know with AI, 1 + 1 can be a pizza?? Just kidding. Or am I...?",
    "Laugh at the funny, pass on the meh, snatch up the stellar!",
    "LOL at the funnies, skip the so-so's, go for gold on the greats!",
    "Chuckle at the humorous, take a pass on the moderate, seize the sublime!",
    "AI one-of-a-kinds--snag a gem or keep searching!",
    "AI doesn't repeat--find your fortune or keep fishing!",
    "AI no doubles--grab a pic or keep hunting!",
    "AI Jukebox: Pick between Unexpected, Daffy, or Magnificent!",
    "AI Jukebox: Grab one of these - Whimiscal, Wacky, or Flawless!",
    "AI Jukebox: Choose from Outlandish, Zany, or Unbelievable!",
    "AI can be Dumb, Dumber, or Brainiac - confusing, right?",
    "Brains vs Bots: AI can be any level from Dumb to Ultra Smart",
    "Choose Smartness Level: AI, from Doofus to Genius - you decide!",
    "Picture Search & Perish - Enjoy the Process!",
    "Frame It or Farewell - Have Fun in the Journey!",
    "Construct it and they shall congregate - AI Oasis of false hopes",
    "Fix it up and they shall appear - Promised Land of AI Dreams",
    "Forge it and they will manifest - AI Land of Unrealistic Dreams"
  ]
  const crazylistone = [    
    "Love it, Love it not, Love it …. That’s AI.",
    "Keep spinning the wheel  of AI and see the possibilities",
    "You may laugh sometimes, but many times get astounded by what AI is capable of",
    "Share funny outputs with a friend on social media and have a great laugh",
    "About to experience the funny side of AI",
    "Experience great, outrageous, or ugly. That’s AI",
    "AI puts trees outside your high rise condo? Yup! Now you have seen it all.",
    "If you see a barbecue pit put by AI in the “backyard” of your high rise condo, don’t go to Costco to buy steaks.",
    "Have you seen an all-American backyard outside your high rise? Don’t worry, AI will put it there! ?",
    "Love it, Love it not, Love it …. AI's here to stay!",
    "Spin the AI wheel and explore the potential outcomes that arise!",
    "Keep turning the AI wheel to discover the different prospects it reveals.",
    "Keep rotating the wheel of AI and observe the options it generates.",
    "Sometimes you chuckle, but you will usually be surprised by the abilities of AI.",
    "AI can often astound you, even if you find yourself laughing at times.",
    "AI has the capability to amaze you, even though you may be amused at times.",
    "Show your buddy a humorous post on social media and have a grand giggle!",
    "Post a funny creation to social media and crack up alongside your friend!",
    "Spread some witty content with a mate over social media and have a roaring good time!",
    "Preparing for the mirthful AI expedition!",
    "Brace yourself – the funniest AI is almost here!",
    "Ready to embark on the hilarious AI adventure?",
    "AI for an extraordinary, ridiculous, or horrendous experience.",
    "Enjoy a marvelous, crazy, or dreadful adventure with AI.",
    "Get out of your comfort zone with AI and experience the wild, wacky, and crazy.",
    "AI puts trees outside your high rise condo? Believe it or not!",
    "AI is here to bring you an all-American backyard - even outside your high rise."
  ]
  const loadermessages = [
    "Just a moment, AI is brewing up something special for you.",
    "Hold on tight, AI is in the process of creating the images.",
    "Be patient, AI is on the case to make your dream spaces.",
    "Please bear with AI while it conjures up the perfect space.",
    "Keep calm and let AI do its thing while AI handles your request.",
    "Sit tight, AI is cooking up something awesome for you.",
    "Hold your horses, AI is busy crafting a personalized space.",
    "Stay tuned, AI is fine-tuning your request for maximum awesomeness.",    
    "Just a little longer, AI is diligently working on the image design. ",
    "Hang in there, AI is working hard to create your dream space.",
    "Bear with AI, AI is crafting something extraordinary for you.",
    "Don't fret, AI is navigating the complexities of your request.",
    "Your request is in good hands, AI is making progress.",
    "Rest assured, AI is working its magic.",
    "Hang on, AI is concocting something exquisite for you!",
    "Take a moment, AI is concocting something marvelous for you!",
    "Hold up, AI is preparing something extraordinary for you!",
    "Hang on, AI is gettin' busy craftin' up some visual masterpieces!",
    "Ready your grip - AI's about to start painting with pixels!",
    "Brace yourselves - AI's constructing a digital gallery!",
    "Rest easy, AI has your back to create your wildest visions!",
    "Perk up, AI is ready to jumpstart your utopia vision!",
    "Keep chillin', AI is furiously working on your dreamscape!",
    "Hang in there while AI builds the perfect area!",
    "Hold tight while AI cooks up the ideal space!",
    "No need to stress - AI will handle this for you!",
    "Leave it to AI - it got this in the bag!",
    "Hold on - AI is concocting something incredible for you.",
    "Hold tight, AI is stirring up something marvelous for you!",
    "Buckle up, AI is whipping up something spectacular for you.",
    "Ease up, AI's spirit is devotedly devising a tailor-made cosmos.",
    "Hold up, AI is fervently formulating a personalized space!",
    "Hang tight, AI is hard at work cobbling together a unique cosmos.",
    "Buckle up, AI's polishing your query for superior epicness.",
    "Hold tight, AI's optimizing your demand for extreme superbness.",
    "Get ready, AI's tweaking your wish for total greatness.",
    "AI is sticking with it -- nearly done with that image design!",
    "AI is toiling away on the image design, almost done!",
    "Keep the faith, AI is toiling assiduously to construct your desired space.",
    "Don't give up, AI is ceaselessly laboring to make your desired space.",
    "Hold on, AI is laboring hard to fabricate your ideal living space.",
    "Show patience while AI is creating something exceptional for you.",
    "No need to panic, AI is tackling the nuances of your request.",
    "Have no fear - AI is handling the complexities of your request.",
    "Don't stress, AI is managing the intricacies of your request.",
    "AI is chipping away at fulfilling your request.",
    "AI is diligently working to achieve your request.",
    "Believe us, AI is doing its magic.",
    "Have faith, AI is accomplishing its sorcery.",
    "Rely on it, AI is working its magic."

  ]
  const loadermessagesone = [    
    "AI is on it! Your request has AI’s full attention.",
    "AI is in the lab, brewing up the perfect space for you.",
    "Stay put, AI is hammering out the details of the spaces.",    
    "Hang tight, AI is working its magic on your envisioned space.",
    "Hang tight while AI spruce up the space design!",
    "AI is getting creative with your space design - stay tuned!",
    "Please wait, while AI gives your space design some extra pizzazz.",
    "Hold on while AI brings your space designs to life!",
    "Sit tight while AI perfects your space designs!",
    "AI is giving your room a makeover - almost there!",
    "AI is giving your space the upgrade it deserves - just a bit longer!",
    "AI got some amazing space designs in store for you - please wait!",
    "AI is creating something special - stay tuned while the magic happens!",
    "Be patient, AI is making your space look fabulous!",
    "Wear your sunglasses. You are going to be bedazzled.",
    "Where are your shades? You may need them soon.",
    "AI has your request in its sights!",
    "AI is taking action on your request.",
    "AI is on board - your request is top priority for AI.",
    "AI has concocted the ideal space in the lab.",
    "AI is in the laboratory, developing the perfect space for you.",
    "Don't move an inch, AI is smashing out the specifics of the chambers.",
    "Camp out, AI is clobbering out the facts of the gaps.",
    "Hold on, AI is bringing the magic to your envisioned space.",
    "Don't let go, AI is transforming your envisioned space like a wizard.",
    "Keep holding, AI is casting a spell on your envisioned space.",
    "Stick around and watch AI turn this joint into a swanky spot!",
    "Keep your cool while AI works its wizardry on the interior design!",
    "AI is gearing up to give your space a wild new look - hang tight!",
    "Get ready for awe-inspiring decor ideas - AI has taken the reins!",
    "Be patient while AI adds some flair to your space design!",
    "Just a sec while AI jazzes up your space design!",
    "Wait a minute while AI gives your space design some extra sizzle!",
    "Get ready for lift-off as AI brings your stellar concepts to life!",
    "Brace yourself as AI revives your intergalactic decor!",
    "Wait patiently while AI turns your architectural dreams into a reality!",
    "Buckle up while AI blasts off with your space designs!",
    "Button up while AI takes your space designs to new heights!",
    "Brace yourself while AI takes your space designs to the next level!",
    "AI is refreshing your space - nearly finished!",
    "AI is sprucing up your space - almost complete!",
    "AI will level up your area - simply have a bit of patience!",
    "AI is sending your area into the future - just a bit more hangin'!",
    "AI is giving your place the makeover it deserves - just a tad bit further!",
    "AI's cosmic collection is wanna-see worthy - hold tight!",
    "AI's happening out of this world - stay tuned!",
    "AI's storing stellar items - be on standby!",
    "AI is working on a surprise for your celestial abode - almost done!",
    "AI is crafting something extra-special for your orbit - nearly finished!",
    "AI is working some magic for your area - almost there!",
    "Take a chill pill, AI is ensuring your place looks smashing!",
    "Relax, AI is transforming your quarters into a thing of beauty!",
    "Hang tight, AI is giving your space a whole new stunning vibe!",
    "Put on your shades and prepare to be dazzled!",
    "Want to be amazed? Slip on your sunglasses.",
    "You better dust off your shades! They may soon be called for.",
    "Break out those sunnies of yours! You might be needing them soon."
  ]

  const finalmessagearr = [
    "Just a little more time, AI is putting the final pieces together.",
    "Almost there! Your image spaces are almost ready.",
    "AI appreciates your patience as it works on your request.",
    "Hold on a tad longer, AI is almost ready to dazzle you.",
    "Take a deep breath, AI is putting the finishing touches on your request.",
    "AI is putting the final touches on your space designs - please wait!",
    "AI is creating something special for your space - almost done!",
    "AI is crafting your space design masterpiece - just a minute!",
    "Hold on while AI makes your space designs shine!",
    "AI is making your dream space a reality - just a moment!",
    "In the nick of time, AI is arranging the last bits and bobs together.", 
    "Not long now, AI is carefully snapping the last bits into place.",
    "Only a notch more time, AI is dovetailing the final fragments together.",
    "Keep calm and carry on, your image spaces are almost ready!",
    "Get ready for launch! Your imaginations are almost complete!",
    "Hold on tight! Your picture paradises are almost primed!",
    "AI thanks you for being so chill as it struggles to fulfill your wish!",
    "Thanks for having AI's back - AI is trying its hardest on your task!",
    "AI is stoked you have the patience for it to sort out your order.",
    "Stop tapping your foot, AI is almost prepared to wow you!",
    "Don't give up now, AI is about to boggle your mind!",
    "Inhale deeply, AI has almost perfected your request.",
    "Breathe in slowly, AI is completing your request.",
    "Relax and let the AI take care of your request, take a deep breath.",
    "AI is spiffing up your space designs - hang tight!",
    "AI is jazzing up your space designs - stand by!",
    "AI has something up its sleeve - get ready for a surprise!",
    "Buckle up for a wild ride - AI has something brewing!",
    "AI is concocting something special - anticipate the unexpected!",
    "AI is inventing your interstellar design sensation - hold on tight!",
    "AI is beavering away on your stellar design gem - it won't take long!",
    "AI is spinning out your cosmic design work of art - stand by!",
    "Let AI make your space designs sparkle as you wait!",
    "AI is making your wildest space dreams come true - boom!",
    "AI is turning your space into a dream come true - quick now!"
 ];
 export const themeautomatedinterioroptions = [
    // { value: "-1", label: "Select" },
    { value: "1970s Revival", label: "1970s Revival" },
    { value: "Bohemian", label: "Bohemian" },
    { value: "Boho Chic", label: "Boho Chic" },
    { value: "Californian", label: "Californian" },
    { value: "Coastal", label: "Coastal" },
    { value: "Contemporary", label: "Contemporary" },
    { value: "Desert Modern", label: "Desert Modern" },
    { value: "Eclectic", label: "Eclectic" },
    { value: "English Countryside Chic", label: "English Countryside Chic" },
    { value: "Farmhouse", label: "Farmhouse" },
    { value: "French Country", label: "French Country" },
    { value: "Glam", label: "Glam" },
    { value: "Grandmillennial", label: "Grandmillennial" },
    { value: "Hollywood Regency", label: "Hollywood Regency" },
    { value: "Industrial", label: "Industrial" },
    { value: "Mediterranean", label: "Mediterranean" },
    { value: "Memphis", label: "Memphis" },
    { value: "Mid-Century Modern", label: "Mid-Century Modern" },
    { value: "Millennial Maximalist", label: "Millennial Maximalist" },
    { value: "Minimalist", label: "Minimalist" },
    { value: "Modern", label: "Modern" },
    { value: "Modern Farmhouse", label: "Modern Farmhouse" },
    { value: "Naturalist", label: "Naturalist" },
    { value: "Neo-Industrial", label: "Neo-Industrial" },
    { value: "New Traditional", label: "New Traditional" },
    { value: "Organic Minimalist", label: "Organic Minimalist" },
    { value: "Quiet Luxury", label: "Quiet Luxury " },
    { value: "Retro", label: "Retro" },
    { value: "Rustic", label: "Rustic" },
    { value: "Scandinavian", label: "Scandinavian" },
    { value: "Shabby Chic", label: "Shabby Chic" },
    { value: "Southwestern", label: "Southwestern" },
    { value: "Traditional", label: "Traditional" },
    { value: "Transitional", label: "Transitional" },
    
  ];
export const themeinterioroptions = [
    // { value: "-1", label: "Select" },
    { value: "1970s Revival", label: "1970s Revival" },
    { value: "Art Deco", label: "Art Deco" },
    { value: "Art Nouveau", label: "Art Nouveau" },
    { value: "Bohemian", label: "Bohemian" },
    { value: "Boho Chic", label: "Boho Chic" },
    { value: "Californian", label: "Californian" },
    { value: "Coastal", label: "Coastal" },
    { value: "Contemporary", label: "Contemporary" },
    { value: "Desert Modern", label: "Desert Modern" },
    { value: "Eclectic", label: "Eclectic" },
    { value: "English Countryside Chic", label: "English Countryside Chic" },
    { value: "Farmhouse", label: "Farmhouse" },
    { value: "French Country", label: "French Country" },
    { value: "Glam", label: "Glam" },
    { value: "Grandmillennial", label: "Grandmillennial" },
    { value: "Hollywood Regency", label: "Hollywood Regency" },
    { value: "Industrial", label: "Industrial" },
    { value: "Mediterranean", label: "Mediterranean" },
    { value: "Memphis", label: "Memphis" },
    { value: "Mid-Century Modern", label: "Mid-Century Modern" },
    { value: "Millennial Maximalist", label: "Millennial Maximalist" },
    { value: "Minimalist", label: "Minimalist" },
    { value: "Modern", label: "Modern" },
    { value: "Modern Farmhouse", label: "Modern Farmhouse" },
    { value: "Naturalist", label: "Naturalist" },
    { value: "Neo-Industrial", label: "Neo-Industrial" },
    { value: "New Traditional", label: "New Traditional" },
    { value: "Organic Minimalist", label: "Organic Minimalist" },
    { value: "Quiet Luxury", label: "Quiet Luxury " },
    { value: "Retro", label: "Retro" },
    { value: "Rustic", label: "Rustic" },
    { value: "Scandinavian", label: "Scandinavian" },
    { value: "Shabby Chic", label: "Shabby Chic" },
    { value: "Southwestern", label: "Southwestern" },
    { value: "Traditional", label: "Traditional" },
    { value: "Transitional", label: "Transitional" },
    
  ];
  export const themeexterioroptions = [
    { value: "Clipped boxwood hedges with luxurious lawn", label: "Clipped boxwood hedges with luxurious lawn"},
    { value: "Trendy Moroccan tiles with draping greenery", label: "Trendy Moroccan tiles with draping greenery"},
    { value: "Ivy-covered walls & pavers with grass joints", label: "Ivy-covered walls & pavers with grass joints"},
    { value: "Georgia classic pool with stonework", label: "Georgia classic pool with stonework"},
    { value: "Picturesque garden", label: "Picturesque garden"},
    { value: "Enchanting playhouse landscaping",label: "Enchanting playhouse landscaping"},
    { value: "Colorful plants",label: "Colorful plants"},
    { value: "English cottage landscaping with multiple hues",label: "English cottage landscaping with multiple hues"},
    { value: "Snug nook with a firepit and comfy seats",label: "Snug nook with a firepit and comfy seats"},
    { value: "Gravel landscaping",label: "Gravel landscaping"},
    { value: "Laid-back Californian garden design", label: "Laid-back Californian garden design"},
    { value: "Stylishly chic California garden landscape", label: "Stylishly chic California garden landscape"},
    { value: "California-style garden oasis", label:"California-style garden oasis"},
    { value: "Japanese inspired landscaping", label:"Japanese inspired landscaping"},
    { value: "Asian-influenced garden design", label: "Asian-influenced garden design"},
    { value: "Lush green lawn", label: "Lush green lawn"},
    { value: "Golf course inspired landscaping",label:"Golf course inspired landscaping"},
    { value: "Coastal Garden Landscaping", label: "Coastal Garden Landscaping"},
    { value: "English garden",label:"English garden"},
    { value: "Rose garden",label: "Rose garden"},
    { value: "Oceanic style horticulture",label: "Oceanic style horticulture"},
    { value: "Mediterranean inspired landscape",label: "Mediterranean inspired landscape"},
    { value: "Rock garden", label: "Rock garden"},
    { value: "Mossy rock garden", label : "Mossy rock garden"},
    { value: "Outdoor dining and lounging space", label: "Outdoor dining and lounging space"},
    { value: "Patio lounge", label: "Patio lounge"},
    { value: "Luxurious, entertaining oasis", label: "Luxurious, entertaining oasis"},
    { value: "Sparkling pool and bubbling spa", label: "Sparkling pool and bubbling spa"},
    { value: "Patio paradise with a swimming pool, hot tub, and fire pit", label: "Patio paradise with a swimming pool, hot tub, and fire pit"},
    { value: "Hawaiian paradise",label:"Hawaiian paradise"},
    { value: "Resort-style outdoor living space with swimming pool", label: "Resort-style outdoor living space with swimming pool"},
    { value: "Swimming pool, lush lawn, and cabana",label: "Swimming pool, lush lawn, and cabana"}, 
    { value: "Fruit trees and lawn", label: "Fruit trees and lawn"},
    { value: "Flower garden", label: "Flower garden"},
    { value: "Small pond surrounded by flowers and lawn",label: "Small pond surrounded by flowers and lawn"},
    { value: "Peaceful lagoon",label: "Peaceful lagoon"},
    { value: "Parisian garden",label: "Parisian garden"},
    { value: "Tropical paradise",label: "Tropical paradise"},
    { value: "Woodland landscaping",label: "Woodland landscaping"},
    { value: "Outdoor seating with barbeque pit", label: "Outdoor seating with barbeque pit"},
    { value: "Prairie landscape", label: "Prairie landscape"}, 
    { value: "Desert style landscaping", label: "Desert style landscaping"},
    { value: "Tuscan style landscaping", label: "Tuscan style landscaping"},
    { value: "Spanish style landscaping", label: "Spanish style landscaping"},
    { value: "Ornamental grasses around green lawn", label: "Ornamental grasses around green lawn"},
    { value: "French country landscaping", label: "French country landscaping"},
    { value: "Cactus garden", label: "Cactus garden"},
    { value: "Formal landscape with geometric pattern", label: "Formal landscape with geometric pattern"}
]

export const themeexteriorfrontyardoptions = [
    { value: "Clipped boxwood hedges with luxurious lawn", label: "Clipped boxwood hedges with luxurious lawn"},
    { value: "Trendy Moroccan tiles with draping greenery", label: "Trendy Moroccan tiles with draping greenery"},
    { value: "Ivy-covered walls & pavers with grass joints", label: "Ivy-covered walls & pavers with grass joints"},    
    { value: "Picturesque garden", label: "Picturesque garden"},    
    { value: "Colorful plants",label: "Colorful plants"},
    { value: "English cottage landscaping with multiple hues",label: "English cottage landscaping with multiple hues"},    
    { value: "Gravel landscaping",label: "Gravel landscaping"},
    { value: "Laid-back Californian garden design", label: "Laid-back Californian garden design"},
    { value: "Stylishly chic California garden landscape", label: "Stylishly chic California garden landscape"},
    { value: "California-style garden oasis", label:"California-style garden oasis"},
    { value: "Japanese inspired landscaping", label:"Japanese inspired landscaping"},
    { value: "Asian-influenced garden design", label: "Asian-influenced garden design"},
    { value: "Lush green lawn", label: "Lush green lawn"},
    { value: "Golf course inspired landscaping",label:"Golf course inspired landscaping"},
    { value: "Coastal Garden Landscaping", label: "Coastal Garden Landscaping"},
    { value: "English garden",label:"English garden"},
    { value: "Rose garden",label: "Rose garden"},
    { value: "Oceanic style horticulture",label: "Oceanic style horticulture"},
    { value: "Mediterranean inspired landscape",label: "Mediterranean inspired landscape"},
    { value: "Rock garden", label: "Rock garden"},
    { value: "Mossy rock garden", label : "Mossy rock garden"},
    { value: "Flower garden", label: "Flower garden"},
    { value: "Small pond surrounded by flowers and lawn",label: "Small pond surrounded by flowers and lawn"},    
    { value: "Parisian garden",label: "Parisian garden"},
    { value: "Tropical paradise",label: "Tropical paradise"},
    { value: "Woodland landscaping",label: "Woodland landscaping"},    
    { value: "Prairie landscape", label: "Prairie landscape"}, 
    { value: "Desert style landscaping", label: "Desert style landscaping"},
    { value: "Tuscan style landscaping", label: "Tuscan style landscaping"},
    { value: "Spanish style landscaping", label: "Spanish style landscaping"},
    { value: "Ornamental grasses around green lawn", label: "Ornamental grasses around green lawn"},
    { value: "French country landscaping", label: "French country landscaping"},
    { value: "Cactus garden", label: "Cactus garden"},
    { value: "Formal landscape with geometric pattern", label: "Formal landscape with geometric pattern"}
]

export const themeexteriorarchitecturaloptions = [
    { value: "Adobe Revival", label:"Adobe Revival"},
{ value: "African", label:"African"},
{ value: "American Craftsman", label:"American Craftsman"},
{ value: "Art Deco", label:"Art Deco"},
{ value: "Arts and Crafts", label:"Arts and Crafts"},
{ value: "Asian", label:"Asian"},
{ value: "Barn", label:"Barn"},
{ value: "Barndominiums", label:"Barndominiums"},
{ value: "Bauhaus", label:"Bauhaus"},
{ value: "Beach", label:"Beach"},
{ value: "Brutalist", label:"Brutalist"},
{ value: "Bungalow", label:"Bungalow"},
{ value: "Cabin", label:"Cabin"},
{ value: "California", label:"California"},
{ value: "Canadian", label:"Canadian"},
{ value: "Cape Cod", label:"Cape Cod"},
{ value: "Chateau", label:"Chateau"},
{ value: "Colonial", label:"Colonial"},
{ value: "Contemporary", label:"Contemporary"},
{ value: "Contemporary Craftsman", label:"Contemporary Craftsman"},
{ value: "Country", label:"Country"},
{ value: "Eastlake", label:"Eastlake"},
{ value: "Elizabethan", label:"Elizabethan"},
{ value: "English Cottage", label:"English Cottage"},
{ value: "Farmhouse", label:"Farmhouse"},
{ value: "Federal Revival", label:"Federal Revival"},
{ value: "Florida", label:"Florida"},
{ value: "French", label:"French"},
{ value: "French Cottage", label:"French Cottage"},
{ value: "French Country", label:"French Country"},
{ value: "French Normandy", label:"French Normandy"},
{ value: "Georgian", label:"Georgian"},
{ value: "Gothic Revival", label:"Gothic Revival"},
{ value: "Greek Revival", label:"Greek Revival"},
{ value: "Hacienda", label:"Hacienda"},
{ value: "Haussmann", label:"Haussmann"},
{ value: "Italian", label:"Italian"},
{ value: "Italianate", label:"Italianate"},
{ value: "Japanese", label:"Japanese"},
{ value: "Log", label:"Log"},
{ value: "McMansion", label:"McMansion"},
{ value: "Mediterranean", label:"Mediterranean"},
{ value: "Mid-century Modern", label:"Mid-century Modern"},
{ value: "Modern", label:"Modern"},
{ value: "Mountain", label:"Mountain"},
{ value: "Neoclassical", label:"Neoclassical"},
{ value: "New England", label:"New England"},
{ value: "Northwest", label:"Northwest"},
{ value: "Prairie", label:"Prairie"},
{ value: "Queen Anne", label:"Queen Anne"},
{ value: "Ranch", label:"Ranch"},
{ value: "Romanesque Revival", label:"Romanesque Revival"},
{ value: "Rustic", label:"Rustic"},
{ value: "Shingle", label:"Shingle"},
{ value: "South American", label:"South American"},
{ value: "Southern", label:"Southern"},
{ value: "Southwest", label:"Southwest"},
{ value: "Southwestern", label:"Southwestern"},
{ value: "Spanish", label:"Spanish"},
{ value: "Traditional", label:"Traditional"},
{ value: "Tropical", label:"Tropical"},
{ value: "Tudor", label:"Tudor"},
{ value: "Tuscan", label:"Tuscan"},
{ value: "Vernacular", label:"Vernacular"},
{ value: "Victorian", label:"Victorian"}
// { value: "adobe revival", label: "Adobe Revival"},
// { value: "african", label: "African"},
// { value: "american craftsman", label: "American Craftsman"},
// { value: "art deco", label: "Art Deco"},
// { value: "arts and crafts", label: "Arts and Crafts"},
// { value: "asian", label: "Asian"},
// { value: "barn", label: "Barn"},
// { value: "barndominiums", label: "Barndominiums"},
// { value: "bauhaus", label: "Bauhaus"},
// { value: "beach", label: "Beach"},
// { value: "brutalist", label: "Brutalist"},
// { value: "bungalow", label: "Bungalow"},
// { value: "cabin", label: "Cabin"},
// { value: "california", label: "California"},
// { value: "canadian", label: "Canadian"},
// { value: "cape cod", label: "Cape Cod"},
// { value: "chateau", label: "Chateau"},
// { value: "colonial", label: "Colonial"},
// { value: "contemporary", label: "Contemporary"},
// { value: "contemporary craftsman", label: "Contemporary Craftsman"},
// { value: "country", label: "Country"},
// { value: "eastlake", label: "Eastlake"},
// { value: "elizabethan", label: "Elizabethan"},
// { value: "english cottage", label: "English Cottage"},
// { value: "farmhouse", label: "Farmhouse"},
// { value: "federal revival", label: "Federal Revival"},
// { value: "florida", label: "Florida"},
// { value: "french", label: "French"},
// { value: "french cottage", label: "French Cottage"},
// { value: "french country", label: "French Country"},
// { value: "french normandy", label: "French Normandy"},
// { value: "georgian", label: "Georgian"},
// { value: "gothic revival", label: "Gothic Revival"},
// { value: "greek revival", label: "Greek Revival"},
// { value: "hacienda", label: "Hacienda"},
// { value: "haussmann", label: "Haussmann"},
// { value: "italian", label: "Italian"},
// { value: "italianate", label: "Italianate"},
// { value: "japanese", label: "Japanese"},
// { value: "log", label: "Log"},
// { value: "mcmansion", label: "McMansion"},
// { value: "mediterranean", label: "Mediterranean"},
// { value: "mid-century modern", label: "Mid-century Modern"},
// { value: "modern", label: "Modern"},
// { value: "mountain", label: "Mountain"},
// { value: "neoclassical", label: "Neoclassical"},
// { value: "new england", label: "New England"},
// { value: "northwest", label: "Northwest"},
// { value: "prairie", label: "Prairie"},
// { value: "queen anne", label: "Queen Anne"},
// { value: "ranch", label: "Ranch"},
// { value: "romanesque revival", label: "Romanesque Revival"},
// { value: "rustic", label: "Rustic"},
// { value: "shingle", label: "Shingle"},
// { value: "south american", label: "South American"},
// { value: "southern", label: "Southern"},
// { value: "southwest", label: "Southwest"},
// { value: "southwestern", label: "Southwestern"},
// { value: "spanish", label: "Spanish"},
// { value: "traditional", label: "Traditional"},
// { value: "tropical", label: "Tropical"},
// { value: "tudor", label: "Tudor"},
// { value: "tuscan", label: "Tuscan"},
// { value: "vernacular", label: "Vernacular"},
// { value: "victorian", label: "Victorian"}
]
//   [
//     //{ value: "-1", label: "Select" },
//     { value: "beautiful garden", label: "Beautiful garden" },
//     {
//       value: "charming playhouse garden landscaping",
//       label: "Charming playhouse garden landscaping",
//     },
//     {
//       value: "cottage garden's colorful planting palette",
//       label: "Cottage garden's colorful planting palette",
//     },
//     {
//       value: "cozy corner with fire pit and seating",
//       label: "Cozy corner with fire pit and seating",
//     },
//     {
//       value: "garden landscaping with gravel landscaping",
//       label: "Garden landscaping with gravel landscaping",
//     },
//     {
//       value: "hip california garden landscaping",
//       label: "Hip california garden landscaping",
//     },
//     { value: "lush green lawn", label: "Lush green lawn" },
//     {
//       value: "mediterranean garden landscaping",
//       label: "Mediterranean garden landscaping",
//     },
//     { value: "moss garden", label: "Moss garden" },
//     {
//       value: "outdoor dining and sitting area",
//       label: "Outdoor dining and sitting area",
//     },
//     {
//       value: "party-ready outdoor space with pool, spa, and fire feature",
//       label: "Party-ready outdoor space with pool, spa, and fire feature",
//     },
//     {
//       value: "resort-style landscaping and pool",
//       label: "Resort-style landscaping and pool",
//     },
//     {
//       value: "round swimming pool with lawn and pool house",
//       label: "Round swimming pool with lawn and pool house",
//     },
//   ];
  
export const ismobile =()=>{
    return window.screen.width <= 600;
} 


class Aeloadvancednew extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            furnishmaskoptions:[],
            masklist:[],
            outimgcarousel:[],
            outimgcarouseladvanced:[],
            generatedimagetype:'',
            selectedpreviewimage:'',
            isemptyRoom:false,
            roomtypemasktext:'',
            showingadvancedresult:false,
            isoutdoorStruct:false,
            selectedmasks:[],
            selectedmasksforselectedpreview:[],
            showoutputimagediv:false,
            showingautomatedoutput:false,
            roomthemeoptions:[],
            uploadedfile:'',
            advancetransformactive:false,
            furnishingactive:true,
            processedimage:'',
            masklistselected:[],
            advanceresultgenerated:false,
            automateresultgenerated:false,
            originalimagewidth:0,
            selectedimagewidth:0,
            Showingselectedpreview:false,
            maskgenerataedforexterior:false,
            maskgenerataedforarchitecture:false,
            selectedfurnishmaskoptions:[],
            selectedarchitecturemask:0,
            selectedoutputidentity:'',
            processidentity:'',
            processidentityselected:'',
            loadermessages:[],
            plantype:0,
            planlevel:0,
            menuIsOpen:false,
            menuIsOpen1:false,
            Allpreviewmaskselected:false,
            allmaskselected:false,
            keeparchitectureintact:false,
            showproducts:false,
            listtopopulaate: [],
            oriinalimageforproduct: "",
            productdatafromvision: []

        }
        this.selectInputRef = React.createRef();
        this.selectInputRef1 = React.createRef();
        this.roomtypeinputref = React.createRef();
    }
    componentDidMount(){
    //    this.toggleadvanceclass(false);  
       this.registerdropzone();   
       this.registerautocomplete(); 
       this.loadermessagesrandom();    
       console.log("navigator.canShare(): "+ navigator.canShare);
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        var parsedRes = JSON.parse(decodedUser);
        // var lPType = parseInt(parsedRes.PlanType);
        this.setState({plantype:parseInt(parsedRes.PlanType)});
        this.setState({planlevel:parseInt(parsedRes.PlanLevel)});
        try {
            Cookies.set('showStartUp', false);
        } catch (error) {
            
        }
    }

    classifyimage = (coordinatedata) => {    
        var hassofa = false;
        var sofaobject = {};
        var listtopopulaatetemp = [];
        let imgheight = coordinatedata["inputImgHeight"];
        let imgwidth = coordinatedata["inputImgWidth"];
        if (imgheight == undefined) {
          imgheight = 510;
        }
        for (let i = 0; i < coordinatedata.Result.length; i++) {
          const row = coordinatedata.Result[i];
          let xaxis = row['x_centroid'];
          let yaxis = row['y_centroid'];
          var jsr = {};          
          jsr.id = i;
          jsr.x_axis = this.calcperc(xaxis,imgwidth)+'%';
          jsr.y_axis = this.calcperc(yaxis,imgheight)+'%';
          jsr.dname = row.MaskName;
          jsr.maskimg = row.MaskUrl;
          listtopopulaatetemp.push(jsr);    
          if (row.MaskName.toLowerCase().indexOf("sofa") != -1) {
            hassofa = true;
            sofaobject = jsr;
          }
        }    
        console.log(listtopopulaatetemp);
        this.setState({ listtopopulaate: listtopopulaatetemp,oriinalimageforproduct: coordinatedata["orignalImg"],showproducts: true});
        if (hassofa == true) {
            this.getproductcatalog(sofaobject.dname,sofaobject.maskimg);
        }
      }
      
  calcperc = (partialValue,totalValue) =>{
    return (100 * partialValue) / totalValue;
  }
  getproductcatalog = (maskname,clipimageurl) => {
    console.log('clicked product');
    var self = this;    
    // var maskname = e.currentTarget.dataset.maskname;
    // var clipimageurl = e.currentTarget.dataset.clipimageurl;
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('clipimageurl', clipimageurl);
    data.append('maskname', maskname);
    data.append('uid', UserID);
    data.append('companyid', Companyid);
    this.showoverlaysearchone();
    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://uatapi.aelo.ai/api/ArtificialIntelligence/searchproduct',
    data : data
    };

    axios.request(config)
    .then((response) => {
    // console.log(JSON.stringify(response.data));
    var productdata = response.data.Data;
    self.setState({productdatafromvision:productdata });
    self.hideoverlaysearchone();
    })
    .catch((error) => {
    console.log(error);
    self.customAlertMessage('error','Something went wrong, Please try again.')
    self.hideoverlaysearchone();
    });

  }
  getproducts = (e) => {
    console.log('clicked product');    
    var maskname = e.currentTarget.dataset.maskname;
    var clipimageurl = e.currentTarget.dataset.clipimageurl;
    this.getproductcatalog(maskname,clipimageurl);
  }
  handlemouseentermask = (e) => {
    var identitytarget = e.currentTarget.dataset.objtgt;
    const elements = document.querySelectorAll('.result-mask img');
    elements.forEach((element) => { 
      if (element.id == ('mask'+identitytarget)) {
        element.style.display =  'block';//.classList.add('highlight');
      }
      else{
        element.style.display =  'none';
      }      
     });
  }

  handlemouseleavemask = (e)=>{
    const elements = document.querySelectorAll('.result-mask img');
    elements.forEach((element) => { element.style.display = 'none'});
    //elements.forEach((element) => { element.classList.remove('highlight-off'); });
  }

  getproductmask = (productsourceimageurl)=>{
    const axios = require('axios');
    const FormData = require('form-data');

    let data = new FormData();
    data.append('imgurl', productsourceimageurl);
    data.append('dirname',uuidv4() );

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://ai.chocolatechips.ai/getobjectList',      
      data : data
    };
this.showoverlaysearch();
    axios.request(config)
    .then((response) => {
      this.classifyimage(response.data);
      this.hideoverlaysearch();
    })
    .catch((error) => {
      console.log(error);
      this.customAlertMessage('error','Something went wrong, Please try again.')
      this.hideoverlaysearch();
    });

  }

  getproductmaskfromimage = ()=>{
    const axios = require('axios');
    const FormData = require('form-data');
    var productsourceimage = $(".dropzone")[0].files[0];
    let data = new FormData();
    data.append('img', productsourceimage);
    data.append('dirname',uuidv4() );

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://ai.chocolatechips.ai/getobjectListOrgImg',      
      data : data
    };
this.showoverlaysearchoriginal();
    axios.request(config)
    .then((response) => {
      this.classifyimage(response.data);
      this.hideoverlaysearchoriginal();
    })
    .catch((error) => {
      console.log(error);
      this.customAlertMessage('error','Something went wrong, Please try again.')
      this.hideoverlaysearchoriginal();
    });

  }
    registerautocompleteexterior = () =>{
        var self = this;
        $( "#roomthemetextarea" ).autocomplete({
            source: themeexterioroptions.sort( function( a, b ) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            }),
            minLength: 0,
            select: function( event, ui ) {
                document.getElementById('roomthemetextarea').classList.remove('errordrop');
                document.getElementById('clearauto').style.display="inline-block";
            },
            open: function() {
                $(this).removeClass( "ui-corner-all" ).addClass( "ui-corner-top" );
            },
            close: function() {
                $(this).removeClass( "ui-corner-top" ).addClass( "ui-corner-all" );
            }
        }).focus(function(){            
            // The following works only once.
            // $(this).trigger('keydown.autocomplete');
            // As suggested by digitalPBK, works multiple times
            // $(this).data("autocomplete").search($(this).val());
            // As noted by Jonny in his answer, with newer versions use uiAutocomplete
            $(this).data("uiAutocomplete").search($(this).val());
        });
    }
registerautocompleteexteriorfrontyard = () =>{
        var self = this;
        $( "#roomthemetextarea" ).autocomplete({
            source: themeexteriorfrontyardoptions.sort( function( a, b ) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            }),
            minLength: 0,
            select: function( event, ui ) {
                document.getElementById('roomthemetextarea').classList.remove('errordrop');
                document.getElementById('clearauto').style.display="inline-block";
            },
            open: function() {
                $(this).removeClass( "ui-corner-all" ).addClass( "ui-corner-top" );
            },
            close: function() {
                $(this).removeClass( "ui-corner-top" ).addClass( "ui-corner-all" );
            }
        }).focus(function(){            
            // The following works only once.
            // $(this).trigger('keydown.autocomplete');
            // As suggested by digitalPBK, works multiple times
            // $(this).data("autocomplete").search($(this).val());
            // As noted by Jonny in his answer, with newer versions use uiAutocomplete
            $(this).data("uiAutocomplete").search($(this).val());
        });
    }
    registerautocompleteexteriorarchitecture = () =>{
        var self = this;
        $( "#roomthemetextarea" ).autocomplete({
            source: themeexteriorarchitecturaloptions.sort( function( a, b ) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            }),
            minLength: 0,
            select: function( event, ui ) {
                document.getElementById('roomthemetextarea').classList.remove('errordrop');
                document.getElementById('clearauto').style.display="inline-block";
            },
            open: function() {
                $(this).removeClass( "ui-corner-all" ).addClass( "ui-corner-top" );
            },
            close: function() {
                $(this).removeClass( "ui-corner-top" ).addClass( "ui-corner-all" );
            }
        }).focus(function(){            
            // The following works only once.
            // $(this).trigger('keydown.autocomplete');
            // As suggested by digitalPBK, works multiple times
            // $(this).data("autocomplete").search($(this).val());
            // As noted by Jonny in his answer, with newer versions use uiAutocomplete
            $(this).data("uiAutocomplete").search($(this).val());
        });
    }
    registerautocomplete = () =>{
        var self = this;
        $( "#roomthemetextarea" ).autocomplete({
            source: (self.state.advancetransformactive == true ? themeinterioroptions.sort( function( a, b ) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            }) :
            themeautomatedinterioroptions.sort( function( a, b ) {
                return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
            })),
            minLength: 0,
            select: function( event, ui ) {
                document.getElementById('roomthemetextarea').classList.remove('errordrop');
                // document.getElementById('clearauto').style.display="inline-block";
                document.getElementById('clearauto').style.display="inline-block";
            },
            open: function() {
                $(this).removeClass( "ui-corner-all" ).addClass( "ui-corner-top" );
            },
            close: function() {
                $(this).removeClass( "ui-corner-top" ).addClass( "ui-corner-all" );
            }
        }).focus(function(){            
            // The following works only once.
            // $(this).trigger('keydown.autocomplete');
            // As suggested by digitalPBK, works multiple times
            // $(this).data("autocomplete").search($(this).val());
            // As noted by Jonny in his answer, with newer versions use uiAutocomplete
            $(this).data("uiAutocomplete").search($(this).val());
        });
        if ($("#roomthemetextarea").val().toLowerCase().indexOf("art deco") != -1 || $("#roomthemetextarea").val().toLowerCase().indexOf("art nouveau") != -1) {
            document.getElementById('roomthemetextarea').value = "";
            document.getElementById('clearauto').style.display = "none";
        }
    }
    registerdropzone =()=>{
        var self = this;
        document.getElementById('roomthemetextarea').addEventListener('keyup',function(){
            if(this.value == ""){
                document.getElementById('roomthemetextarea').classList.add('errordrop');

            }
            else{
                document.getElementById('roomthemetextarea').classList.remove('errordrop');
            }
            
        })
        document.getElementById('originalpreview').addEventListener('load', function(){
            self.setState({
                originalimagewidth:this.naturalWidth
            });
            if (self.state.selectedpreviewimage == "") {
                self.setState({
                    selectedimagewidth:this.naturalWidth
                });
            }
            this.width = 100+"%";
        });

        document.getElementById('selectedpreview').addEventListener('load', function(){
            self.setState({
                selectedimagewidth:this.naturalWidth
            });
            if (self.state.processedimage == "") {
                self.setState({                    
                    originalimagewidth:this.naturalWidth
                });
            }
        });

        $(".dropzone").on("change", function () {
            var tempfile = $(".dropzone")[0].files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                self.customAlertMessage('error', 'File type not supported.');
                return;
            }
            if (tempfile.size < 10485760) {
                self.state.uploadedfile = tempfile;
                if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
                    self.readFile();
                }
                
            }
            else {
                self.customAlertMessage('error', 'Maximum file size exceeded.');
                return;
            }
        });

        $('.dropzone-wrapper').on('dragover', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').addClass('dragover');
        });

        $('.dropzone-wrapper').on('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').removeClass('dragover');
        });
        document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {
            event.preventDefault();
            var tempfile = event.dataTransfer.files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                this.customAlertMessage('error', 'File type not supported.');
                return;
            }
            self.state.uploadedfile = tempfile;
            if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
                self.readFileondrop(event.dataTransfer);
            }
            // document.getElementById("showmebtn").style.display = "inline-block";
        });

        $('.remove-preview').on('click', function () {
            self.resettoupload();
            return;
            var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
            var previewZone = $('.remove-preview').parents('.preview-zone');
            previewZone.addClass('hidden');
            $('.dropzone-wrapper').removeClass('hidden');
            document.getElementsByClassName("dropzone")[0].value = "";
            document.getElementById('originalpreview').removeAttribute('src');
            document.getElementById('selectedpreview').removeAttribute('src'); 
            var advancetransformactive = self.state.advancetransformactive;
            var furnishingactive = self.state.furnishingactive;
            self.setState({
                furnishmaskoptions:[],
                masklist:[],
                outimgcarousel:[],
                outimgcarouseladvanced:[],
                generatedimagetype:'',
                selectedpreviewimage:'',
                isemptyRoom:false,
                roomtypemasktext:'',
                showingadvancedresult:false,
                isoutdoorStruct:false,
                selectedmasks:[],
                selectedmasksforselectedpreview:[],
                showoutputimagediv:false,
                showingautomatedoutput:false,
                roomthemeoptions:[],
                uploadedfile:'',
                advancetransformactive:advancetransformactive,
                furnishingactive:true,
                processedimage:'',
                masklistselected:[],
                advanceresultgenerated:false,
                automateresultgenerated:false,
                originalimagewidth:0,
                selectedimagewidth:0,
                Showingselectedpreview:false,
                maskgenerataedforexterior:false,
                maskgenerataedforarchitecture:false,
                selectedfurnishmaskoptions:[],

            });
            document.getElementById('selected').dataset.fileurl = "";
            document.getElementById('original').dataset.fileurl = "";
            var elem = document.getElementById('originalpreview');
            if(elem.getAttribute('src') == "")
            {
                elem.setAttribute('width', 0);
                elem.setAttribute('height', 0);
            }
        });
    }

    resettoupload = () => {        
        var previewZone = $('.preview-zone');
        previewZone.addClass('hidden');
        $('.dropzone-wrapper').removeClass('hidden');
        document.getElementsByClassName("dropzone")[0].value = "";
        document.getElementById('originalpreview').removeAttribute('src');
        document.getElementById('selectedpreview').removeAttribute('src'); 
        // document.getElementById('outputimagediv').style.display = 'none';
        // document.getElementById('showmasklink').style.display = 'none';
        // document.getElementById('furnituremaskdivddl').style.display = 'none';
        var advancetransformactive = this.state.advancetransformactive;
        var furnishingactive = this.state.furnishingactive;
        this.setState(
        {
            furnishmaskoptions:[],
            masklist:[],
            outimgcarousel:[],
            outimgcarouseladvanced:[],
            generatedimagetype:'',
            selectedpreviewimage:'',
            isemptyRoom:false,
            roomtypemasktext:'',
            showingadvancedresult:false,
            isoutdoorStruct:false,
            selectedmasks:[],
            selectedmasksforselectedpreview:[],
            showoutputimagediv:false,
            showingautomatedoutput:false,
            roomthemeoptions:[],
            uploadedfile:'',
            advancetransformactive:false,
            furnishingactive:true,
            processedimage:'',
            masklistselected:[],
            advanceresultgenerated:false,
            automateresultgenerated:false,
            originalimagewidth:0,
            selectedimagewidth:0,
            Showingselectedpreview:false,
            maskgenerataedforexterior:false,
            maskgenerataedforarchitecture:false,
            selectedfurnishmaskoptions:[],
            selectedarchitecturemask:0,
            selectedoutputidentity:'',
            processidentity:'',
            processidentityselected:'',
            loadermessages:[],
            // plantype:0,
            menuIsOpen:false,
            menuIsOpen1:false,
            Allpreviewmaskselected:false,
            allmaskselected:false,
            keeparchitectureintact:false
        }
        );
        document.getElementById('colorpref').value = "";
        document.getElementById('roomthemetextarea').value = "";
        document.getElementById('clearauto').style.display = "none";
        if (this.selectInputRef.current != null) {
            this.selectInputRef.current.clearValue();
        }
        if (this.selectInputRef1.current != null) {
            this.selectInputRef1.current.clearValue();
        }
        if (this.roomtypeinputref.current != null) {
            this.roomtypeinputref.current.clearValue();
        }
        document.getElementById('selected').dataset.fileurl = "";
        document.getElementById('original').dataset.fileurl = "";
        var elem = document.getElementById('originalpreview');
        if(elem.getAttribute('src') == "")
        {
            elem.setAttribute('width', 0);
            elem.setAttribute('height', 0);
        }
        document.getElementById('floorprefdiv').style.display = "none";
        document.getElementById('materialprefdiv').style.display = 'none';
        document.getElementById("floorpref").value = "Ceramic Tile";
        document.getElementById("materialpref").value = "";
        // document.querySelector('#maskedimagearch center').innerHTML = "";
        // document.querySelector('#maskedimagearchforoutput center').innerHTML = "";
        // this.toggleadvanceclass(false);
    }

    toggleadvanceclass = (show)=>{
        var optelem = document.getElementsByClassName('advanceoption');
        for (let i = 0; i < optelem.length; i++) {
            const element = optelem[i];
            if (element.id == "tabpanel") {
                if (this.state.isoutdoorStruct == false) {
                    element.style.display = (show == true ? 'flex':'none');
                }
                else{
                    document.getElementById('furnishcheckboxdiv').style.display = 'none';
                    element.style.display = 'none'
                }
                
            }
            else{
                element.style.display = (show == true ? 'block':'none');
            }
                   
        } 
    }
    // togglefurnitureclass = (show)=>{
    //     var optelem = document.getElementsByClassName('furniturediv');
    //     for (let i = 0; i < optelem.length; i++) {
    //         const element = optelem[i];
    //         element.style.display = (show == true ? 'block':'none');                   
    //     } 
    // }


    

    blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

      Creeatemaskonuploadbackend = () =>{    
        if (this.state.advancetransformactive == false || this.state.uploadedfile == "") {
            document.getElementById('selected').style.display = 'none';
            document.getElementById('original').style.display = 'block';
            return;
        }   
        if (this.state.uploadedfile == "") {
            return;
        } 
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayonemask(true);
        }
        else{
            this.showoverlaymask(true);
        }
        var self = this;
        var processid = uuidv4().split('-').join('');
        this.setState({processidentity:processid});
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('img', this.state.uploadedfile);
        data.append('dirname', processid);
        data.append('emptyspace', 'false');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatai.chocolatechips.ai/getMaskwithImage',
            data : data
        };

        axios.request(config)
        .then((response) => {
            if (response.data != null && response.data != "" && response.data != "error") {
                var resultdata = response.data;
                var resultdataarr = response.data.Result;
                var maskimgoption = [];
                if (self.state.advancetransformactive == true && self.state.furnishingactive == false) {
                    var filteredmask = resultdataarr.filter(function (d) { 
                        return d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'windowpane' || d.MaskName == 'fireplace' || d.MaskName == 'door'
                        || d.MaskName == 'fence' || d.MaskName == 'stairs'  || d.MaskName == 'sky' 
                    });
                    if (filteredmask.length <= 0) {
                        this.customAlertMessage('error','The image lacks the necessary architectural elements to continue. Please select a different option or upload a new image.');
                        return;
                    }
                }
                for (let j = 0; j < resultdataarr.length; j++) {
                    const d = resultdataarr[j];
                    // if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') 
                    {
                        var maskopt = {};
                        maskopt.value = d.Maskid;
                        maskopt.label = d.MaskName;
                        maskopt.selected = true;
                        maskimgoption.push(maskopt);    
                    }
                }
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                debugger;
                var filteredmasktoshow = maskimgoption.filter(function (d) { 
                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                     && d.label != 'fence' && d.label != 'stairs'  && d.label != 'sky' )
                });
                self.setState({ 
                    masklist: resultdataarr,
                    furnishmaskoptions:maskimgoption,
                    selectedmasks:filteredmasktoshow,
                    userimageurl: _userimageurl,
                    maskgenerataedforexterior:self.state.isoutdoorStruct,
                    maskgenerataedforarchitecture:!self.state.isoutdoorStruct
                });                
                document.getElementById('original').dataset.fileurl = _userimageurl;
                
            }
            else{
                self.customAlertMessage('error','Error occured, please try again with different image.');
                self.resettoupload();
            }       
            
            if (this.state.masklistselected.length <= 0) {
                this.Creeatemaskforoutputimagebackend();
            }
        })
        .catch((error) => {
            console.log(error);
            if (this.state.masklistselected.length <= 0) {
                this.Creeatemaskforoutputimagebackend();
            }
        });

      }
      Creeatemaskforoutputimagebackend = () => {
        if (document.getElementById('selected').dataset.fileurl == undefined || document.getElementById('selected').dataset.fileurl == "") {
            if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                this.hideoverlayonemask(true);
            }
            else{
                this.hideoverlaymask(true);
            }
            return;
        }
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayonemask(true);
        }
        else{
            this.showoverlaymask(true);
        }
        var fileurl = document.getElementById('selected').dataset.fileurl;
        var self = this;
        var processid = uuidv4().split('-').join('');
        this.setState({processidentityselected:processid});
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('imgurl', fileurl);
        data.append('dirname', processid);
        data.append('emptyspace', 'false');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatai.chocolatechips.ai/generateMaskOnly',
            data : data
        };

        axios.request(config)
        .then((response) => {
            if (response.data != null && response.data != "") {
                var resultdata = response.data;
                var resultdataarr = response.data.Result;
                var maskimgoption = [];
                for (let j = 0; j < resultdataarr.length; j++) {
                    const d = resultdataarr[j];
                    // if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') 
                    {
                        var maskopt = {};
                        maskopt.value = d.Maskid;
                        maskopt.label = d.MaskName;
                        maskopt.selected = true;
                        maskimgoption.push(maskopt);    
                    }
                }
                self.selectInputRef.current.clearValue();
                var _userimageurl = resultdata.orignalImg;
                var filteredmasktoshow = maskimgoption.filter(function (d) { 
                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                     && d.label != 'fence' && d.label != 'stairs'  && d.label != 'sky' )
                });
                self.setState({ 
                    masklistselected: resultdataarr,
                    selectedfurnishmaskoptions:maskimgoption,
                    userimageurl: _userimageurl,
                    maskgenerataedforexterior:self.state.isoutdoorStruct,
                    maskgenerataedforarchitecture:!self.state.isoutdoorStruct,
                    selectedmasksforselectedpreview:filteredmasktoshow
                    // if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') 
                });
                document.getElementById('selected').dataset.fileurl = _userimageurl;        
                if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayonemask(true);
                }
                else{
                    self.hideoverlaymask(true);
                }        
            }
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
            
        })
        .catch((error) => {
            console.log(error);
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
        });
      }

      Creeatemaskforoutputimage = () =>{
        if (document.getElementById('selected').dataset.fileurl == undefined || document.getElementById('selected').dataset.fileurl == "") {
            return;
        }
        var fileurl = document.getElementById('selected').dataset.fileurl;
        var self = this;
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayonemask(true);
        }
        else{
            this.showoverlaymask(true);
        }
        var processid = uuidv4().split('-').join('');
        this.setState({processidentityselected:processid});
        const axios = require('axios');
        const FormData = require('form-data');
        // const fs = require('fs');
        let data = new FormData();
        data.append('imgurl', fileurl);
        data.append('dirname', processid);
        data.append('emptyspace', 'false');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatai.chocolatechips.ai/generateMaskOnly',
            data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data != null && response.data != "") {
                var resultdata = response.data;
                var resultdataarr = response.data.Result;
                var maskimgoption = [];
                for (let j = 0; j < resultdataarr.length; j++) {
                    const d = resultdataarr[j];
                    // if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') 
                    {
                        var maskopt = {};
                        maskopt.value = d.Maskid;
                        maskopt.label = d.MaskName;
                        maskopt.selected = true;
                        maskimgoption.push(maskopt);    
                    }
                }
                // var _processidentity = resultdata.processId;
                self.selectInputRef.current.clearValue();
                var _userimageurl = resultdata.orignalImg;
                var filteredmasktoshow = maskimgoption.filter(function (d) { 
                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                     && d.label != 'fence' && d.label != 'stairs' &&  d.label != 'sky' )
                });
                self.setState({ 
                    masklistselected: resultdataarr,
                    selectedfurnishmaskoptions:maskimgoption,
                    userimageurl: _userimageurl,
                    maskgenerataedforexterior:self.state.isoutdoorStruct,
                    maskgenerataedforarchitecture:!self.state.isoutdoorStruct,
                    selectedmasksforselectedpreview:filteredmasktoshow
                });

                // self.setState({ 
                //     masklist: resultdataarr,
                //     furnishmaskoptions:maskimgoption,
                //     selectedmasks:maskimgoption,
                //     userimageurl: _userimageurl,
                //     maskgenerataedforexterior:self.state.isoutdoorStruct,
                //     maskgenerataedforarchitecture:!self.state.isoutdoorStruct
                // });

                if (document.getElementById('architecturallnk').className == "tab-active") {
                    setTimeout(() => {
                        self.showselectedpreview(true);    
                    }, 2000);
                }
                if (self.state.isoutdoorStruct == true) {
                    self.showselectedpreview(true);   
                }
                document.getElementById('selected').style.display = 'block';
                document.getElementById('original').style.display = 'none';
                document.getElementById('selected').dataset.fileurl = _userimageurl;
                
            }
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
            
        })
        .catch((error) => {
            console.log(error);
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
        });

      }
      Creeatemaskonuploadwithoutvalidation = () =>{       
        if (this.state.uploadedfile == "") {
            return;
        } 
        var self = this;
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayonemask(true);
        }
        else{
            this.showoverlaymask(true);
        }
        var processid = uuidv4().split('-').join('');
        this.setState({processidentity:processid});
        const axios = require('axios');
        const FormData = require('form-data');
        // const fs = require('fs');
        let data = new FormData();
        data.append('img', this.state.uploadedfile);
        data.append('dirname', processid);
        data.append('emptyspace', 'false');

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatai.chocolatechips.ai/getMaskwithImage',
            data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response.data != null && response.data != "" && response.data != "error") {
                var resultdata = response.data;
                var resultdataarr = response.data.Result;
                var maskimgoption = [];
                if (self.state.advancetransformactive == true && self.state.furnishingactive == false) {
                    var filteredmask = resultdataarr.filter(function (d) { 
                        return d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'windowpane' || d.MaskName == 'fireplace' || d.MaskName == 'door'
                        || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'sky' 
                    });
                    if (filteredmask.length <= 0) {
                        this.customAlertMessage('error','The image lacks the necessary architectural elements to continue. Please select a different option or upload a new image.');
                        if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayonemask(true);
                        }
                        else{
                            self.hideoverlaymask(true);
                        }
                        return;
                    }
                }
                for (let j = 0; j < resultdataarr.length; j++) {
                    const d = resultdataarr[j];
                    // if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') 
                    {
                        var maskopt = {};
                        maskopt.value = d.Maskid;
                        maskopt.label = d.MaskName;
                        maskopt.selected = true;
                        maskimgoption.push(maskopt);    
                    }
                }
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                var filteredmasktoshow = maskimgoption.filter(function (d) { 
                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                     && d.label != 'fence' && d.label != 'stairs' &&  d.label != 'sky' )
                });
                self.setState({ 
                    masklist: resultdataarr,
                    furnishmaskoptions:maskimgoption,
                    selectedmasks:filteredmasktoshow,
                    userimageurl: _userimageurl,
                    maskgenerataedforexterior:self.state.isoutdoorStruct,
                    maskgenerataedforarchitecture:!self.state.isoutdoorStruct
                });
                
                if (document.getElementById('architecturallnk').className == "tab-active" || self.state.isoutdoorStruct == true) {
                    setTimeout(() => {
                        // self.loadarchmaskforselectionddl();
                        self.showoriginalpreview();
                    }, 2000);
                    
                }
                document.getElementById('selected').style.display = 'none';
                document.getElementById('original').style.display = 'block';
                document.getElementById('original').dataset.fileurl = _userimageurl;
                
            }
            else{
                self.customAlertMessage('error','Error occured, please try again with different image.');
                self.resettoupload();
            }
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
        })
        .catch((error) => {
            console.log(error);
            if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayonemask(true);
            }
            else{
                self.hideoverlaymask(true);
            }
        });

      }
      Creeatemaskonupload = () =>{
        if (this.state.advancetransformactive == false || this.state.uploadedfile == "") {
            document.getElementById('selected').style.display = 'none';
            document.getElementById('original').style.display = 'block';
            return;
        }
        // if (document.getElementById("automatelink").className == "tab-active" || this.state.uploadedfile == "") {
        //     document.getElementById('selected').style.display = 'none';
        //     document.getElementById('original').style.display = 'block';
        //     return
        // }
        // if (document.getElementById("automatelink").className == "tab-active" || (this.state.furnishingactive == true && this.state.isoutdoorStruct == false)) {
        //     document.getElementById('selected').style.display = 'none';
        //     document.getElementById('original').style.display = 'block';
        //     return
        // }

        this.Creeatemaskonuploadwithoutvalidation();
      }
    //   getoriginalimagewidth=()=>{

    //     // originalimagewidth:0,
    //     // selectedimagewidth:0
    //   }
//#draganddropsectionstart
    readiphoneFile = () => {
        var self = this;
        var input = $('.dropzone')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {                
                fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".jpeg");
                
                var wrapperZone = $(input).parent();
                var previewZone = $('.preview-zone');
                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                $('.dropzone-wrapper').addClass('hidden');
                // document.getElementById("showmebtn").style.display = "inline-block";
                self.setState({ processedimage: url,uploadedfile:file },
                    ()=>{
                    self.Creeatemaskonupload();
                });
                
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    readiphoneFileondrop = (input) => {
        var self = this;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
                .then((conversionResult) => {
                    // conversionResult is a BLOB
                    // of the PNG formatted image
                    var url = URL.createObjectURL(conversionResult);
                    var file = new File([conversionResult],  uuidv4() + ".jpeg");                    
                    var wrapperZone = $('.dropzone-wrapper');
                    var previewZone = $('.preview-zone');                
                    wrapperZone.removeClass('dragover');
                    previewZone.removeClass('hidden');
                    $('.dropzone-wrapper').addClass('hidden');
                    self.setState({ processedimage: url,uploadedfile:file }
                        ,()=>{
                        self.Creeatemaskonupload();
                    });                    
                    self.hideoverlayiphone();
                })
                .catch((e) => {
                    // see error handling section
                    self.customAlertMessage('error','Error occured, please try again.'); 
                    self.hideoverlayiphone();
                });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    
    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    customAlertMessageone = () => {
        Swal.fire({
            text:"Please upload an image and select Space Type. Please note that the Architectural Transforms can be generated only on interior spaces.",
            icon: 'error',
            showConfirmButton: false,
            showCloseButton: true,
        })
    }

    customAlertMessageforemptyspace = () => {
        Swal.fire({
            text:"If you choose to change the flooring, we recommend entering appropriate prompts in the color and pattern preference field to get a more accurate result. \n Sample prompt: 'black leather sectional sofa'.",
            icon: 'info',
            showConfirmButton: true,
            showCloseButton: true,
        })
    }

    customAlertMessageclear = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "The original image and ALL generated images will be deleted. Click 'OK' to proceed.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#9333ea',
            cancelButtonColor: '#cbd0d5',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
                this.resettoupload();
            }
          });
    }

    readFile = () => {
        var self = this;
        var input = $('.dropzone')[0];

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                self.setState({ processedimage: e.target.result });
                var wrapperZone = $(input).parent();
                var previewZone = $('.preview-zone');
                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                $('.dropzone-wrapper').addClass('hidden');
                // document.getElementById("showmebtn").style.display = "inline-block";
                self.Creeatemaskonupload();
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    readFileondrop = (input) => {
        var self = this;
        //var input = $('.dropzone')[0];

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                self.setState({ processedimage: e.target.result });
                //self.state.processedimage = e.target.result;
                //var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
                var wrapperZone = $('.dropzone-wrapper');
                var previewZone = $('.preview-zone');
                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                $('.dropzone-wrapper').addClass('hidden');
                self.Creeatemaskonupload();

            };

            reader.readAsDataURL(input.files[0]);
            //document.getElementById('srcImg').src=this.state.processedimage;
        }
    }
    imagecheckboxselected =(e) => {
        var url = e.currentTarget.dataset.fileurl;
        
       var outputimagecheckbox = document.querySelectorAll('input[class=outputimagecheckbox]:checked');// document.getElementsByClassName('outputimagecheckbox');
       if (outputimagecheckbox.length > 0) {
        for (let i = 0; i < outputimagecheckbox.length; i++) {
            const element = outputimagecheckbox[i];
                element.checked = false;
           }
       }
       
    }

    externalshare =(e) => {
        var url = e.currentTarget.dataset.fileurl;
        var title = this.state.roomtypemasktext;
        try {
            if (navigator.canShare && navigator.share) {
                navigator.share({
                    title: title,
                    text: title,
                    url: url,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
            }
            else{
                this.customAlertMessage('error','Sharing option is not supported in this browser.');
            }
        } catch (error) {
            this.customAlertMessage('error','Error occured on sharing, please try again.');
        }
        
    }
    searchimage =(e)=>{
        console.log('search clicked');
        var url = e.currentTarget.dataset.fileurl;
        this.getproductmask(url);
    }
    searchimagefromuploadedfile = (e) => {
        // var url = e.currentTarget.dataset.fileurl;
        this.getproductmaskfromimage();
    }
    hideshowproducts = ()=>{
        this.setState({ listtopopulaate: [],oriinalimageforproduct: "",showproducts: false});
    }
    handleDownload = (e) => {
        var self = this;
		var url = e.currentTarget.dataset.fileurl;
        this.showoverlay();
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        data.append('imgurl', url);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://uatai.chocolatechips.ai/watermarkchecker',
        data : data
        };
        if (self.state.planlevel > 1) {
            var axiosint = require('axios');
            axiosint.get(url, {
                responseType: 'blob',
            }).then((res) => {
                var filename = uuidv4() + ".png";
                fileDownload(res.data, filename);
                self.hideoverlay();
            });
        }
        else 
        {
        axios.request(config)
        .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data != undefined) {
            if (response.data.output == true) {
                self.hideoverlay();
                self.customAlertMessage('error','Watermarks detected, the download is not allowed.');    
            }
            else{
                var axiosint = require('axios');
                axiosint.get(url, {
                    responseType: 'blob',
                }).then((res) => {
                    var filename = uuidv4() + ".png";
                    fileDownload(res.data, filename);
                    self.hideoverlay();
                })
            }            
        }
        })
        .catch((error) => {
        console.log(error);
        self.hideoverlay();
        });		
    }
	}
    showoverlay = () => {
        if (ismobile()) {
            this.showoverlaymobiledevice();
        }
        else{
            document.getElementById('overlay').style.display = "block";
            $('body').addClass("loader");
            document.getElementById('uploadedcenterdiv').style.display = "none";
            this.loadermessagesrandom();
        }
    }
    hideoverlay = () => {
        if (ismobile()) {
            this.hideoverlaymobiledevice();
        }
        else{
            document.getElementById('overlay').style.display = "none";
            $('body').removeClass("loader");
            document.getElementById('uploadedcenterdiv').style.display = "block";
        }
    }
showoverlaysearchoriginal = ()=>{
    if (ismobile()) {
            this.showoverlaysearchmobiledevice();        
    }
    else{
        document.getElementById('overlaysearchoriginal').style.display = "block";
        $('body').addClass("loader");
    }
}
hideoverlaysearchoriginal = ()=>{
    if (ismobile()) {
            this.hideoverlaysearchmobiledevice();        
    }
    else{
        document.getElementById('overlaysearchoriginal').style.display = "none";
        $('body').removeClass("loader");
    }
}

    showoverlaymask = (mask=false) => {
        if (ismobile()) {
            if(mask==true)
            {
                this.showoverlaymobiledevicemask();
            }
            else
            {
                this.showoverlaymobiledevice();
            }
            
        }
        else{
            document.getElementById('overlaymask').style.display = "block";
            $('body').addClass("loader");
        }
    }
    hideoverlaymask = (mask=false) => {
        if (ismobile()) {
            if(mask==true)
            {
                this.hideoverlaymobiledevicemask();
            }
            else
            {
                this.hideoverlaymobiledevice();
            }
            
        }
        else{
            document.getElementById('overlaymask').style.display = "none";
            $('body').removeClass("loader");
        }
    }


    showoverlayonemask = (mask=false) => {
        if (ismobile()) {
            if(mask==true)
            {
                this.showoverlaymobiledevicemask();
            }
            else
            {
                this.showoverlaymobiledevice();
            }
            
        }
        else{
            document.getElementById('overlayonemask').style.display = "block";
            $('body').addClass("loader");
        }
    }
    hideoverlayonemask = (mask=false) => {
        if (ismobile()) {
            if(mask==true)
            {
                this.hideoverlaymobiledevicemask();
            }
            else
            {
                this.hideoverlaymobiledevice();
            }
            
        }
        else{
            document.getElementById('overlayonemask').style.display = "none";
            $('body').removeClass("loader");
        }
    }

     showoverlaysearch = () => {
        if (ismobile()) {
                this.showoverlaysearchmobiledevice();            
        }
        else{
            document.getElementById('overlaysearch').style.display = "block";
            $('body').addClass("loader");
        }
    }
    hideoverlaysearch = () => {
        if (ismobile()) {
                this.hideoverlaysearchmobiledevice();
        }
        else{
            document.getElementById('overlaysearch').style.display = "none";
            $('body').removeClass("loader");
        }
    }
    showoverlaysearchone = () => {
        if (ismobile()) {
                this.showoverlaysearchmobiledevice();            
        }
        else{
            document.getElementById('overlaysearchone').style.display = "block";
            $('body').addClass("loader");
        }
    }
    hideoverlaysearchone = () => {
        if (ismobile()) {
                this.hideoverlaysearchmobiledevice();
        }
        else{
            document.getElementById('overlaysearchone').style.display = "none";
            $('body').removeClass("loader");
        }
    }

    loadermessagesrandom = () =>
    {
        var message =[];
        message.push(crazylist[Math.floor(Math.random()*33)]);
        var ddd = Array.from({length: 40}, () => Math.floor(Math.random() * 50));
        var duplicateremoved = ddd.filter((item,index) => ddd.indexOf(item) === index).slice(0,2);
        for (let i = 0; i < 2; i++) {              
            const element = loadermessages[duplicateremoved[i]] == null ? loadermessages[0] :loadermessages[duplicateremoved[i]];
            message.push(element);                
        }
        message.push(crazylistone[Math.floor(Math.random()*27)]);
        var dddd = Array.from({length: 40}, () => Math.floor(Math.random() * 57));
        var duplicateremovedd = dddd.filter((item,index) => dddd.indexOf(item) === index).slice(0,2);
        for (let i = 0; i < 2; i++) {              
            const element = loadermessagesone[duplicateremovedd[i]] == null ? loadermessages[0] :loadermessages[duplicateremovedd[i]];
            message.push(element);                
        }
        message.push(finalmessagearr[Math.floor(Math.random() * 35)]);
        this.setState({loadermessages : message});
    }
    showoverlayone = () => {
        if (ismobile()) {
            this.showoverlaymobiledevice();
        }
        else{
            document.getElementById('overlayone').style.display = "block";
            document.getElementById('scrollviewhide').style.display = "none";
            $('body').addClass("loader");
            this.loadermessagesrandom();
            
        }
    }
    hideoverlayone = () => {
        if (ismobile()) {
            this.hideoverlaymobiledevice();
        }
        else{
            document.getElementById('overlayone').style.display = "none";
            document.getElementById('scrollviewhide').style.display = "block";
            $('body').removeClass("loader");
        }
    }
    

    showoverlayiphone = () => {
        if (ismobile()) {
            this.showoverlaymobiledevice();
        }
        else{
            document.getElementById('overlayiphone').style.display = "block";
            $('body').addClass("loader");
        }        
    }
    hideoverlayiphone = () => {
        if (ismobile()) {
            this.hideoverlaymobiledevice();
        }
        else{
            document.getElementById('overlayiphone').style.display = "none";
            $('body').removeClass("loader");
        }
    }

    showoverlaymobiledevice = () => {
        document.getElementById('mobileoverlay').style.display = "block";
        $('body').addClass("loader");
        $('html').addClass("loader");
        this.loadermessagesrandom();
    }
    hideoverlaymobiledevice = () => {
        document.getElementById('mobileoverlay').style.display = "none";
        $('body').removeClass("loader");
        $('html').removeClass("loader");
    }
    showoverlaysearchmobiledevice = () => {
        document.getElementById('mobileoverlaysearch').style.display = "block";
        $('body').addClass("loader");
        $('html').addClass("loader");
        this.loadermessagesrandom();
    }
    hideoverlaysearchmobiledevice = () => {
        document.getElementById('mobileoverlaysearch').style.display = "none";
        $('body').removeClass("loader");
        $('html').removeClass("loader");
    }

    showoverlaymobiledevicemask = () => {
        document.getElementById('mobileoverlaymask').style.display = "block";
        $('body').addClass("loader");
        $('html').addClass("loader");
        this.loadermessagesrandom();
    }
    hideoverlaymobiledevicemask = () => {
        document.getElementById('mobileoverlaymask').style.display = "none";
        $('body').removeClass("loader");
        $('html').removeClass("loader");
    }

    // showoverlaytwo = () => {
    //     if (ismobile()) {
    //         this.showoverlaymobiledevice();
    //     }
    //     else{
    //         document.getElementById('overlaytwo').style.display = "block";
    //         document.getElementById('scrollviewhide').style.display = "none";
    //         $('body').addClass("loader");
    //         this.loadermessagesrandom();
            
    //     }
    // }
    // hideoverlaytwo = () => {
    //     if (ismobile()) {
    //         this.hideoverlaymobiledevice();
    //     }
    //     else{
    //         document.getElementById('overlaytwo').style.display = "none";
    //         document.getElementById('scrollviewhide').style.display = "block";
    //         $('body').removeClass("loader");
    //     }
    // }

    showOrHideTokensInner(){
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        var parsedRes = JSON.parse(decodedUser); 
        var lPType = parseInt(parsedRes.PlanType);
        var lPLevel = parseInt(parsedRes.PlanLevel);
        if(lPType == 0){
          document.getElementById('spanTokensCount').style.display ="none";
        }
        else{
          document.getElementById('spanTokensCount').style.display ="block";
        }
      }

    loadCounterDataInner = () =>{
        return;
        try {
            this.showOrHideTokensInner();
            //alert('inside counter update');
            setTimeout(() => {
          const axios = require('axios');
          let uconfig = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
            
            };
            axios.request(uconfig)
              .then((response) => {
              console.log(parseInt(JSON.stringify(response.data.Data)));
              if(parseInt(JSON.stringify(response.data.Data))>0)
              {
                document.getElementById('lblRC').innerText=response.data.Data;
              }
              else{
                document.getElementById('lblRC').innerText= 0;
              }
            })
            .catch((error) => {
              
            });
        }, 3000);
          } catch (error) {
            
          }
      }
focusfieldonerror = () => {
    setTimeout(() => {
        document.getElementById("tabpanelcontent").scrollIntoView({ behavior: "smooth" });
    }, 1000);    
    document.getElementsByClassName('scrollviewleft')[0].scroll({ top: 0, behavior: 'smooth' });
}
    processImagewithurlforselectedtransform = (transformurl) => {
		const axios = require('axios');
		let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
		if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayone();
        }
        else{
            this.showoverlay();
        }
		  axios.request(uconfig)
		  .then((response) => {
			console.log(parseInt(JSON.stringify(response.data.Data)));
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
				let n_prompt = 'Longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality';
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				let counter = 0;
				let a_prompt = '';
				while (counter < 10) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
					counter += 1;
				}
				let n_inputfilename = result+".jpg";		
				if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
					this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
                    document.getElementById('roomtypemask').className += ' errordrop';
                    this.focusfieldonerror();
                    if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
					this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
                    document.getElementById('roomthemetextarea').classList.add('errordrop');      
                    this.focusfieldonerror();              
                    if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				else
				{
					a_prompt = document.getElementById('roomthemetextarea').value;
				}
				a_prompt = a_prompt+", "+document.getElementById("colorpref").value;
                var finalPrompt = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText+", "+a_prompt;
				const FormData = require('form-data');
				var self = this;
                var fileurl = transformurl;
				let data = new FormData();
				let imgResolution = 768;
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				let trail = "false";
				if(lPType==0)
					trail = "true";
				data.append('imgurl', fileurl);
				data.append('prompt', document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
				data.append('a_prompt', a_prompt);
				data.append('n_prompt', n_prompt);
				data.append('num_sample', "4");
				data.append('image_resolution', imgResolution);
				data.append('n_inputfilename', n_inputfilename);
				data.append('trail',trail);
                debugger;
				// this.showoverlay();
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'https://flask.aelo.ai/dreamhomerecreate',		
					data: data
				};
                if (this.state.keeparchitectureintact == true && this.state.advancetransformactive == false && this.state.isoutdoorStruct == false) {
                    config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://flask.aelo.ai/advancedreamhomerecreate',		
                        data: data
                    };
                }
		
				axios.request(config)
					.then((response) => {
						n_inputfilename = "https://openaiimages.s3.us-west-1.amazonaws.com/"+n_inputfilename;
						var config = {
							method: 'post',
							"url": "https://api.aelo.ai/api/AIUserManager/UpdateDreamplanResponse?UserId="+UserID+"&inputfile=" + n_inputfilename
								+ "&outputfiles="+JSON.stringify(response.data.result)+"&prompt="+finalPrompt,
							headers: {
								'Content-Type': 'application/json'
							}
						};
						axios.request(config)
                        .then((response) => { 
                            console.log("inside userupdate");
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                        var outputimages = response.data.result;
                        var outputimagearr = [];
                        var outputimgcarousel =[];
                        // var inputimg = {};
                        // inputimg.identity = -1;
                        // inputimg.outimg = self.state.processedimage;
                        // inputimg.original = true;
                        // outputimgcarousel.push(inputimg);
                        for (let i = 0; i < outputimages.length; i++) {
                            var elem = {};
                            elem.identity = uuidv4();
                            elem.outimg = outputimages[i]+'?v='+Date.now();
                            outputimagearr.push(elem);
                            elem.original = false;
                            outputimgcarousel.push(elem);
                        }
                        // var _processidentity = resultdata.processId;
                        // var _userimageurl = resultdata.orignalImg;
                        var outwidth = response.data.outputImgWidth;
                        self.setState({ 
                            outimgcarousel:outputimgcarousel,
                            generatedimagetype:'auto',
                            // processedimage:fileurl,
                            roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                            showingadvancedresult:false,
                            showoutputimagediv:true,
                            selectedpreviewimage:fileurl,
                            automateresultgenerated:true,
                            originalimagewidth:outwidth
                        });
                        if (self.state.outimgcarouseladvanced.length <= 0 || self.state.advanceresultgenerated == false) {
                            self.setState({outimgcarouseladvanced:outputimgcarousel,selectedimagewidth:outwidth});
                        }
                        // setTimeout(() => {
                        //     this.showselectedpreview();
                        // }, 2000);
                        $('.preview-zone').addClass('hidden');
                        if (ismobile()) {
                            setTimeout(() => {
                                document.getElementsByClassName('scrollview')[0].scrollIntoView();
                            }, 2000);
                        }
                        // document.getElementById('generatebtnadvance').style.display = 'none';
                        // document.getElementById('generatebtnautomate').style.display = 'inline-block';
                        // this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }
                        this.loadCounterDataInner();
					})
					.catch((error) => {
						// this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || self.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }
						self.customAlertMessage('error', 'Please try again!');
					});
			}
			else
			{
				//Cookies.set('allowCurrentSubscription', true);
                if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				//console.log(decodedUser);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				if(lPType > 0){
					document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
				}				
				document.getElementById('btnUpgradeModelLimit').click();
			}
		  })
		  .catch((error) => {
			this.customAlertMessage('error', 'Please try again!');
            if (this.state.outimgcarousel.length > 0|| this.state.outimgcarouseladvanced.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }
			console.log(error);
		  });
		
	}
    processImagewithurl = () => {
		const axios = require('axios');
		let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
		if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.showoverlayone();
        }
        else{
            this.showoverlay();
        }
		  axios.request(uconfig)
		  .then((response) => {
			console.log(parseInt(JSON.stringify(response.data.Data)));
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
				let n_prompt = 'Longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality';
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				let counter = 0;
				let a_prompt = '';
				while (counter < 10) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
					counter += 1;
				}
				let n_inputfilename = result+".jpg";		
				if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
					this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
                    document.getElementById('roomtypemask').className += ' errordrop';
                    this.focusfieldonerror();
                    if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
					this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
                    document.getElementById('roomthemetextarea').classList.add('errordrop');
                    this.focusfieldonerror();
                    if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				else
				{
					a_prompt = document.getElementById('roomthemetextarea').value;
				}
				a_prompt = a_prompt+", "+document.getElementById("colorpref").value;
				
				const FormData = require('form-data');
				var self = this;
                var fileurl = document.querySelectorAll('input[class=outputimagecheckbox]:checked')[0].dataset.fileurl;
				let data = new FormData();
				let imgResolution = 768;
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				let trail = "false";
                var finalPrompt = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText+','+a_prompt;
				if(lPType==0)
					trail = "true";
				data.append('imgurl', fileurl);
				data.append('prompt', document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
				data.append('a_prompt', a_prompt);
				data.append('n_prompt', n_prompt);
				data.append('num_sample', "4");
				data.append('image_resolution', imgResolution);
				data.append('n_inputfilename', n_inputfilename);
				data.append('trail',trail);
				// this.showoverlay();
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'https://flask.aelo.ai/dreamhomerecreate',		
					data: data
				};
		
				axios.request(config)
					.then((response) => {
						n_inputfilename = "https://openaiimages.s3.us-west-1.amazonaws.com/"+n_inputfilename;
						var config = {
							method: 'post',
							"url": "https://api.aelo.ai/api/AIUserManager/UpdateDreamplanResponse?UserId="+UserID+"&inputfile=" + n_inputfilename
								+ "&outputfiles="+JSON.stringify(response.data.result)+"&prompt="+finalPrompt,
							headers: {
								'Content-Type': 'application/json'
							}
						};
						axios.request(config)
                        .then((response) => { 
                            console.log("inside userupdate");
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                        var outputimages = response.data.result;
                        var outputimagearr = [];
                        var outputimgcarousel =[];
                        // var inputimg = {};
                        // inputimg.identity = -1;
                        // inputimg.outimg = self.state.processedimage;
                        // inputimg.original = true;
                        // outputimgcarousel.push(inputimg);
                        for (let i = 0; i < outputimages.length; i++) {
                            var elem = {};
                            elem.identity = uuidv4();
                            elem.outimg = outputimages[i]+'?v='+Date.now();
                            outputimagearr.push(elem);
                            elem.original = false;
                            outputimgcarousel.push(elem);
                        }
                        // var _processidentity = resultdata.processId;
                        // var _userimageurl = resultdata.orignalImg;
                        var outwidth = response.data.outputImgWidth;
                        self.setState({ 
                            outimgcarousel:outputimgcarousel,
                            generatedimagetype:'auto',
                            // processedimage:fileurl,
                            roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                            showingadvancedresult:false,
                            showoutputimagediv:true,
                            selectedpreviewimage:fileurl,
                            originalimagewidth:outwidth
                        });
                        if (ismobile()) {
                            setTimeout(() => {
                                document.getElementsByClassName('scrollview')[0].scrollIntoView();
                            }, 2000);
                        }
                        // setTimeout(() => {
                        //     this.showselectedpreview();
                        // }, 2000);
                        $('.preview-zone').addClass('hidden');
                        // document.getElementById('generatebtnadvance').style.display = 'none';
                        // document.getElementById('generatebtnautomate').style.display = 'inline-block';
                        // this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }
                        this.loadCounterDataInner();
					})
					.catch((error) => {
						// this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }
						self.customAlertMessage('error', 'Please try again!');
					});
			}
			else
			{
				//Cookies.set('allowCurrentSubscription', true);
                if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				//console.log(decodedUser);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				if(lPType > 0){
					document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
				}				
				document.getElementById('btnUpgradeModelLimit').click();
			}
		  })
		  .catch((error) => {
			this.customAlertMessage('error', 'Please try again!');
            if (this.state.outimgcarousel.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }
			console.log(error);
		  });
		
	}
    processImage = () => {
        if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
            this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');   
            document.getElementById('roomtypemask').className += ' errordrop';     
            this.focusfieldonerror();   
            return;
        }
        if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
            this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');     
            document.getElementById('roomthemetextarea').classList.add('errordrop');      
            this.focusfieldonerror(); 
            return;
        }
		const axios = require('axios');
		let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
		  if (this.state.outimgcarousel.length > 0) {
            this.showoverlayone();
        }
        else{
            this.showoverlay();
        }
		  axios.request(uconfig)
		  .then((response) => {
			console.log(parseInt(JSON.stringify(response.data.Data)));
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
				let n_prompt = 'Longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality';
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				let counter = 0;
				let a_prompt = '';
				while (counter < 10) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
					counter += 1;
				}
				let n_inputfilename = result+".jpg";		
				if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
					this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
                    document.getElementById('roomtypemask').className += ' errordrop';
                    this.focusfieldonerror();
                    if (this.state.outimgcarousel.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
					this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
                    document.getElementById('roomthemetextarea').classList.add('errordrop');
                    this.focusfieldonerror();
                    if (this.state.outimgcarousel.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
					return;
				}
				else
				{
					a_prompt = document.getElementById('roomthemetextarea').value;
				}
				a_prompt = a_prompt+", "+document.getElementById("colorpref").value;
				var finalPrompt = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText+", "+a_prompt;
				const FormData = require('form-data');
				var self = this;
				let data = new FormData();
				let imgResolution = 768;
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				let trail = "false";
				if(lPType==0)
					trail = "true";
				data.append('img', self.state.uploadedfile);
				data.append('prompt', document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
				data.append('a_prompt', a_prompt);
				data.append('n_prompt', n_prompt);
				data.append('num_sample', "4");
				data.append('image_resolution', imgResolution);
				data.append('n_inputfilename', n_inputfilename);
				data.append('trail',trail);
                debugger;
				// this.showoverlay();
                let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'https://flask.aelo.ai/dreamhome',		
					data: data
				};
                if (this.state.keeparchitectureintact == true && this.state.advancetransformactive == false && this.state.isoutdoorStruct == false) {
                    config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: 'https://flask.aelo.ai/advancedreamhome',		
                        data: data
                    };
                }
				
		
				axios.request(config)
					.then((response) => {
						n_inputfilename = "https://openaiimages.s3.us-west-1.amazonaws.com/"+n_inputfilename;
						var config = {
							method: 'post',
							"url": "https://api.aelo.ai/api/AIUserManager/UpdateDreamplanResponse?UserId="+UserID+"&inputfile=" + n_inputfilename
								+ "&outputfiles="+JSON.stringify(response.data.result)+"&prompt="+finalPrompt,
							headers: {
								'Content-Type': 'application/json'
							}
						};
						axios.request(config)
                        .then((response) => { 
                            console.log("inside userupdate");
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                        var outputimages = response.data.result;
                        var outputimagearr = [];
                        var outputimgcarousel =[];
                        // var inputimg = {};
                        // inputimg.identity = -1;
                        // inputimg.outimg = self.state.processedimage;
                        // inputimg.original = true;
                        // outputimgcarousel.push(inputimg);
                        for (let i = 0; i < outputimages.length; i++) {
                            var elem = {};
                            elem.identity = uuidv4();
                            elem.outimg = outputimages[i]+'?v='+Date.now();
                            outputimagearr.push(elem);
                            elem.original = false;
                            outputimgcarousel.push(elem);
                        }
                        // var _processidentity = resultdata.processId;
                        // var _userimageurl = resultdata.orignalImg;
                        var outwidth = response.data.outputImgWidth;
                        self.setState({ 
                            outimgcarousel:outputimgcarousel,
                            // outimgcarouseladvanced:outputimgcarousel,
                            generatedimagetype:'auto',                            
                            roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                            showingadvancedresult:false,
                            showoutputimagediv:true,
                            automateresultgenerated:true,
                            originalimagewidth:outwidth
                        });                        
                        //setTimeout(() => {
                            this.loadCounterDataInner();
                        //}, 3000);
                        if (self.state.outimgcarouseladvanced.length <= 0 || self.state.advanceresultgenerated == false) {
                            self.setState({outimgcarouseladvanced:outputimgcarousel,selectedimagewidth:outwidth});                            
                        }
                        document.getElementById('original').dataset.fileurl = n_inputfilename;
                        if (ismobile()) {
                            setTimeout(() => {
                                document.getElementsByClassName('scrollview')[0].scrollIntoView();
                            }, 2000);
                        }
                        $('.preview-zone').addClass('hidden');
                        // document.getElementById('generatebtnadvance').style.display = 'none';
                        // document.getElementById('generatebtnautomate').style.display = 'inline-block';
                        // this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }                        
					})
					.catch((error) => {
						// this.hideoverlay();
                        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                            self.hideoverlayone();
                        }
                        else{
                            self.hideoverlay();
                        }
						self.customAlertMessage('error', 'Please try again!');
					});
			}
			else
			{
				//Cookies.set('allowCurrentSubscription', true);
                if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				//console.log(decodedUser);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				if(lPType > 0){
					document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
				}				
				document.getElementById('btnUpgradeModelLimit').click();
			}
		  })
		  .catch((error) => {
			this.customAlertMessage('error', 'Please try again!');
            if (this.state.outimgcarousel.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }
			console.log(error);
		  });
		
	}

    getprompt = () => {
        var prompt = '';
        prompt += document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        prompt += ", " + document.getElementById('roomthemetextarea').value;
        prompt += ", " + document.getElementById('colorpref').value;
        return prompt;
    }
    // getpromptinit = () => {        
    //     var prompt = '';
    //     prompt += document.getElementById('roomtypemaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
    //     prompt += ", " + document.getElementById('roomthememaskinit').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText
    //     prompt += ", " + document.getElementById('colorprefinit').value;
    //     return prompt;
    // }
    surprisemeimageparent = () => {
        if (this.state.furnishingactive == true && this.state.isoutdoorStruct == false) {
            // this.surprisemeimage();
            if (document.getElementById('selected').style.display == 'block') {
                var _fileurl = document.getElementById('selected').dataset.fileurl;
                this.regeneratewithmaskexternalimage(_fileurl);
            }
            else{
                var _fileurl = document.getElementById('original').dataset.fileurl;
                this.regeneratewithmaskexternalimage(_fileurl);
            }
        }
        else
        {
            if (this.state.isoutdoorStruct == true) {
                if (document.getElementById('selected').style.display == 'block') {
                    var _fileurl = document.getElementById('selected').dataset.fileurl;
                    this.regeneratewithmaskexternalimage(_fileurl);
                }
                else{
                    var _fileurl = document.getElementById('original').dataset.fileurl;
                    this.regeneratewithmaskexternalimage(_fileurl);
                }
            }
            else{
                if (document.getElementById('selected').style.display == 'block') {
                    var _fileurl = document.getElementById('selected').dataset.fileurl;
                    this.regeneratewithmaskarch(_fileurl);
                }
                else{
                    var _fileurl = document.getElementById('original').dataset.fileurl;
                    this.regeneratewithmaskarch(_fileurl);
                }
            }
           
        }
    }

    surprisemeimage = () => {
        var self = this;
        const axios = require('axios');
        if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
            this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
            document.getElementById('roomtypemask').className += ' errordrop';
            this.focusfieldonerror();
            return;
        }
        if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
            this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
            document.getElementById('roomthemetextarea').classList.add('errordrop');
            this.focusfieldonerror();
            return;
        }
        var roomtypemaskinit = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        var roomthememaskinit = document.getElementById('roomthemetextarea').value;
        var colorprefinit = document.getElementById('colorpref').value;
        if (roomtypemaskinit == '' || roomtypemaskinit == 'Select') {
            this.customAlertMessage('error', 'Please select space type.');
            return;
        }
        if (roomthememaskinit == '' || roomthememaskinit == 'Select') {
            this.customAlertMessage('error', 'Please select design style.');
            return;
        }
        let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
          if (this.state.outimgcarousel.length > 0) {
            this.showoverlayone();
        }
        else{
            this.showoverlay();
        }
          axios.request(uconfig)
		  .then((response) => {
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
                if(roomtypemaskinit=='Backyard'||roomtypemaskinit=='Garden/Landscaping'||roomtypemaskinit=='Outdoor Living'
            ||roomtypemaskinit=='Poolside'||roomtypemaskinit=='Deck/Patio')
            {
                self.setState({isoutdoorStruct:true,isemptyRoom:false});
            }
            else
            {
                self.setState({isoutdoorStruct:false});
            }
            
            
            const FormData = require('form-data');
            let data = new FormData();
            var prompt = this.getprompt();
            data.append('file', self.state.uploadedfile);
            data.append('prompt', prompt);
            data.append('uid', UserID);
            data.append('companyid', Companyid);
            data.append('IsEmptyRoom', self.state.isemptyRoom);
             
            
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://api.aelo.ai/api/ArtificialIntelligence/propertyrecreate',
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    if (response.data != undefined && response.data.Data != "error") {
                        var resultdata = JSON.parse(response.data.Data);
                        setTimeout(() => {
                            this.updateuserlog(UserID,resultdata.orignalImg,response.data.Data,prompt)
                        }, 2000);

                        var resultdataarr = resultdata.Result;
                        var roomtype = roomtypemaskinit;
                        var roomtheme = roomthememaskinit;
                        var outputimages = resultdata.outputImg;
                        var outputimagearr = [];
                        var outputimgcarousel =[];
                        for (let i = 0; i < outputimages.length; i++) {
                            var elem = {};
                            elem.identity = uuidv4();
                            elem.outimg = outputimages[i]+'?v='+Date.now();
                            outputimagearr.push(elem);
                            elem.original = false;
                            outputimgcarousel.push(elem);
                        }
                        // var maskimgoption = [];
                        // for (let j = 0; j < resultdataarr.length; j++) {
                        //     const d = resultdataarr[j];
                        //     if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                        //     && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') {
                        //         var maskopt = {};
                        //         maskopt.value = d.Maskid;
                        //         maskopt.label = d.MaskName;
                        //         maskopt.selected = true;
                        //         maskimgoption.push(maskopt);    
                        //     }
                        // }
                        var _processidentity = resultdata.processId;
                        var _userimageurl = resultdata.orignalImg;
                        var outwidth = resultdata.outputImgWidth;
                        if (this.state.Showingselectedpreview == true) {
                            self.setState({processidentityselected:_processidentity});
                        }
                        else{
                            self.setState({processidentity:_processidentity});
                        }
                        self.setState({ masklist: resultdataarr,roomtypevalue:roomtype,outputImg:outputimagearr,
                            userimageurl:_userimageurl,roomthemevalue:roomtheme,
                            // outimgcarousel:outputimgcarousel,
                            outimgcarouseladvanced:outputimgcarousel,
                            // processedimage:_userimageurl,
                            // furnishmaskoptions:maskimgoption,
                            roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                            showingadvancedresult:true,
                            showoutputimagediv:true,
                            advancetransformactive:true,
                            advanceresultgenerated:true,
                            selectedimagewidth:outwidth
                        });
                        if (self.state.outimgcarousel.length <= 0 || self.state.automateresultgenerated == false) {
                            self.setState({outimgcarousel:outputimgcarousel,originalimagewidth:outwidth});
                        }
                        document.getElementById('original').dataset.fileurl = _userimageurl;
                        $('.preview-zone').addClass('hidden');
                            // this.toggleadvanceclass(true);

                            // this.togglefurnitureclass(true);
                        setTimeout(() => {
                            document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
                                el.style.display = 'none';
                            });
                        }, 2000);
                        if (ismobile()) {
                            setTimeout(() => {
                                document.getElementsByClassName('scrollview')[0].scrollIntoView();
                            }, 2000);                            
                        }
                        if (this.state.outimgcarousel.length > 0) {
                            this.hideoverlayone();
                        }
                        else{
                            this.hideoverlay();
                        }
                        
                    }
                    if (this.state.outimgcarousel.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
                    this.loadCounterDataInner();
                })
                .catch((error) => {
                    console.log(error);
                    if (this.state.outimgcarousel.length > 0) {
                        this.hideoverlayone();
                    }
                    else{
                        this.hideoverlay();
                    }
                    this.customAlertMessage('error', 'Please try again!');
                });
            }
            else
			{
                if (this.state.outimgcarousel.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
				//Cookies.set('allowCurrentSubscription', true);
                if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
				var userI = getAccessToken();
				var decodedUser = decodeURIComponent(userI);
				//console.log(decodedUser);
				var parsedRes = JSON.parse(decodedUser);
				var lPType = parseInt(parsedRes.PlanType);
                var lPLevel = parseInt(parsedRes.PlanLevel);
				if(lPType > 0){
					document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
				}				
				document.getElementById('btnUpgradeModelLimit').click();
			}
        })
        .catch((error) => {
			this.customAlertMessage('error', 'Please try again!');
			console.log(error);
		  });

    }
    
    // surprisemeimagefromoutput = (e) => {
    //     var fileurl = e.currentTarget.dataset.fileurl;        
    //     this.regeneratewithmaskfromoutputimage(fileurl);
    // }

    regeneratewithmask = () =>{
        if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
            this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
            document.getElementById('roomtypemask').className += ' errordrop';
            this.focusfieldonerror();
            return;
        }
        if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
            this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
            document.getElementById('roomthemetextarea').classList.add('errordrop');
            this.focusfieldonerror();
            return;
        }
        if (this.state.outimgcarousel.length > 0) {
            this.showoverlayone();
        }
        else{
            this.showoverlay();
        }
        const axios = require('axios');
        let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
          axios.request(uconfig)
		  .then((response) => {
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
        const FormData = require('form-data');
        let data = new FormData();
        var self = this;        
        var selectedmask = [];
        var masksfurnish = this.state.selectedmasks;
        for (let i = 0; i < masksfurnish.length; i++) {
            const element = masksfurnish[i];
            selectedmask.push(element.value);            
        }            
        var prompttext = this.getprompt();        
        var _processidentity = "";
        if (this.state.Showingselectedpreview == true) {
            _processidentity = this.state.processidentityselected;
        }
        else{
            _processidentity = this.state.processidentity;
        }
        data.append('masklist', selectedmask.toString());
        data.append('dirname', _processidentity);
        data.append('prompt', prompttext);
        data.append('imgurl', this.state.userimageurl);
        data.append('emptyspace', this.state.isemptyRoom);
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://uatai.chocolatechips.ai/recreatedesign',  
        data : data
        };
        var roomtypemaskinit = document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
        if(roomtypemaskinit=='Backyard'||roomtypemaskinit=='Garden/Landscaping'||roomtypemaskinit=='Outdoor Living'
        ||roomtypemaskinit=='Poolside'||roomtypemaskinit=='Deck/Patio')
        {
         self.setState({isoutdoorStruct:true,isemptyRoom:false});
        }
        else
        {
         self.setState({isoutdoorStruct:false});
        }
        axios.request(config)
        .then((response) => {
            console.log(response.data);
            if (response.data != undefined && response.data != "error") {
                var resultdata = response.data;
                setTimeout(() => {
                    this.updateuserlog(UserID,resultdata.orignalImg,JSON.stringify(resultdata),prompttext);
                }, 2000);
                // var resultdataarr = resultdata.Result;
                var outputarrtemp = []
                var outputimages = resultdata.outputImg;
                var outputimagearr = [];
                var outputimgcarousel = [];
                for (let i = 0; i < outputimages.length; i++) {
                    var elem = {};
                    elem.identity = uuidv4();
                    elem.outimg = outputimages[i]+'?v='+Date.now();
                    outputimagearr.push(elem);
                    elem.original = false;
                    outputimgcarousel.push(elem);
                }

                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                var outwidth = resultdata.outputImgWidth;
                self.setState({ outputImg: outputimagearr,
                    userimageurl:_userimageurl,
                    outimgcarousel:outputimgcarousel,
                    roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                    // processedimage: _userimageurl,
                    // furnishmaskoptions:maskimgoption,
                    showingadvancedresult:true,
                    showoutputimagediv:true,
                    advancetransformactive:true,
                    originalimagewidth:outwidth
                });  
                $('.preview-zone').addClass('hidden');
                // this.toggleadvanceclass(true);
                // this.togglefurnitureclass(true);
                // setTimeout(() => {
                //     document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
                //         el.style.display = 'none';
                //     });  
                // }, 2000);
                if (ismobile()) {
                    setTimeout(() => {
                        document.getElementsByClassName('scrollview')[0].scrollIntoView();
                    }, 2000);
                }
                if (this.state.outimgcarousel.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
                this.loadCounterDataInner();
            }
            else{
                self.customAlertMessage('error','Try again, Something went wrong.');
                if (this.state.outimgcarousel.length > 0) {
                    this.hideoverlayone();
                }
                else{
                    this.hideoverlay();
                }
            }
            if (this.state.outimgcarousel.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }

        })
        .catch((error) => {
            console.log(error);
            if (this.state.outimgcarousel.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }
            self.customAlertMessage('error', 'Please try again!');
        });
    }
    else
    {
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.hideoverlayone();
        }
        else{
            this.hideoverlay();
        }
        //Cookies.set('allowCurrentSubscription', true);
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        //console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var lPType = parseInt(parsedRes.PlanType);
        var lPLevel = parseInt(parsedRes.PlanLevel);
        if(lPType > 0){
            document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
        }				
        document.getElementById('btnUpgradeModelLimit').click();
    }
})
.catch((error) => {
    this.customAlertMessage('error', 'Please try again!');
    console.log(error);
  });


    }

    regeneratewithmaskarch = (inputimageurl) => {
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var self = this;
        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            self.showoverlayone();
        }
        else{
            self.showoverlay();
        }

        let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
          axios.request(uconfig)
		  .then((response) => {
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
        var archtype = document.getElementById('archtype').value;
        var filteredmask = []
        var _processidentity = "";
        if (this.state.Showingselectedpreview == true) {
            _processidentity = this.state.processidentityselected;
            filteredmask = this.state.masklistselected.filter(function (entry) { return entry.MaskName === archtype;});
        }
        else{
            _processidentity = this.state.processidentity;
            filteredmask = this.state.masklist.filter(function (entry) { return entry.MaskName === archtype;});
        }
        if (filteredmask.length <= 0) {
            this.customAlertMessage('error','Please select mask to generate new image');
            return;
        }
        var selectedmask = filteredmask[0].Maskid;        
        var prompttext = this.getarchprompt();
        data.append('masklist', selectedmask.toString());
        data.append('dirname', _processidentity);
        data.append('prompt', prompttext);
        // data.append('imgurl', this.state.userimageurl);
        data.append('imgurl',inputimageurl);
        data.append('emptyspace', 'false');
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://uatai.chocolatechips.ai/recreatedesign',  
        data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(response.data);
            if (response.data != undefined && response.data != "error") {
                var resultdata = response.data;
                setTimeout(() => {
                    this.updateuserlog(UserID,resultdata.orignalImg,JSON.stringify(resultdata),prompttext);
                }, 2000);
                // var resultdataarr = resultdata.Result;
                var outputarrtemp = []
                var outputimages = resultdata.outputImg;
                var outputimagearr = [];
                var outputimgcarousel =[];
                for (let i = 0; i < outputimages.length; i++) {
                    var elem = {};
                    elem.identity = uuidv4();
                    elem.outimg = outputimages[i]+'?v='+Date.now();
                    outputimagearr.push(elem);
                    elem.original = false;
                    outputimgcarousel.push(elem);
                }
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                var outwidth = resultdata.outputImgWidth;
                self.setState({ 
                    outputImg: outputimagearr,
                    userimageurl:_userimageurl,
                    outimgcarouseladvanced:outputimgcarousel,
                    showingadvancedresult:true,
                    showoutputimagediv:true,
                    advancetransformactive:true,
                    advanceresultgenerated:true,
                    selectedimagewidth:outwidth,
                    roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                });  
                if (self.state.outimgcarousel.length <= 0 || self.state.automateresultgenerated == false) {
                    self.setState({outimgcarousel:outputimgcarousel,originalimagewidth:outwidth});
                }
                $('.preview-zone').addClass('hidden');
                if (ismobile()) {
                    setTimeout(() => {
                        document.getElementsByClassName('scrollview')[0].scrollIntoView();
                    }, 2000);
                }
                // this.toggleadvanceclass(true);
                // this.togglefurnitureclass(true);
                // setTimeout(() => {
                //     document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
                //         el.style.display = 'none';
                //     });  
                // }, 2000);
                // document.getElementById('generatebtnadvance').style.display = 'inline-block';
                // document.getElementById('generatebtnautomate').style.display = 'none';
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
                this.loadCounterDataInner();
            }
            else{
                self.customAlertMessage('error','Try again, Something went wrong.');
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
            }
            if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayone();
            }
            else{
                self.hideoverlay();
            }

        })
        .catch((error) => {
            console.log(error);
            if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayone();
            }
            else{
                self.hideoverlay();
            }
            self.customAlertMessage('error', 'Please try again!');
        });

    }
    else
    {
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.hideoverlayone();
        }
        else{
            this.hideoverlay();
        }
        //Cookies.set('allowCurrentSubscription', true);
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        //console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var lPType = parseInt(parsedRes.PlanType);
        var lPLevel = parseInt(parsedRes.PlanLevel);
        if(lPType > 0){
            document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
        }				
        document.getElementById('btnUpgradeModelLimit').click();
    }
})
.catch((error) => {
    this.customAlertMessage('error', 'Please try again!');
    console.log(error);
  });

    }

    regeneratewithmaskexternalimage = (inputimageurl) => {
        if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
            this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
            document.getElementById('roomtypemask').className += ' errordrop';
            this.focusfieldonerror();
            return;
        }
        if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
            this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
            document.getElementById('roomthemetextarea').classList.add('errordrop');
            this.focusfieldonerror();
            return;
        }
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var self = this;
        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            self.showoverlayone();
        }
        else{
            self.showoverlay();
        }

        let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
          axios.request(uconfig)
		  .then((response) => {
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
                var selectedmask = [];
                if (this.state.Showingselectedpreview == true) {                    
                    if (self.state.selectedmasksforselectedpreview.length > 0) {
                        var selmask = self.state.selectedmasksforselectedpreview;
                        for (let i = 0; i < selmask.length; i++) {
                            const element = selmask[i];
                            selectedmask.push(element.value);
                        }
                    }
                    else{
                        var selmask = self.state.selectedfurnishmaskoptions;
                        for (let i = 0; i < selmask.length; i++) {
                            const element = selmask[i];
                            selectedmask.push(element.value);
                        }
                    }
                }
                else{
                    if (self.state.selectedmasks.length > 0) {
                        var selmask = self.state.selectedmasks;
                        for (let i = 0; i < selmask.length; i++) {
                            const element = selmask[i];
                            selectedmask.push(element.value);
                        }
                    }
                    else{
                        var selmask = self.state.furnishmaskoptions;
                        for (let i = 0; i < selmask.length; i++) {
                            const element = selmask[i];
                            selectedmask.push(element.value);
                        }
                    }
                }


        var _processidentity = "";
        if (this.state.Showingselectedpreview == true) {
            _processidentity = this.state.processidentityselected;
        }
        else{
            _processidentity = this.state.processidentity;
        }
        var prompttext = this.getprompt();
        data.append('masklist', selectedmask.toString());
        data.append('dirname', _processidentity);
        data.append('prompt', prompttext);
        // data.append('imgurl', this.state.userimageurl);
        data.append('imgurl',inputimageurl);
        data.append('emptyspace', self.state.isemptyRoom);
        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://uatai.chocolatechips.ai/recreatedesign',  
        data : data
        };

        axios.request(config)
        .then((response) => {
            console.log(response.data);
            if (response.data != undefined && response.data != "error") {
                var resultdata = response.data;
                setTimeout(() => {
                    this.updateuserlog(UserID,resultdata.orignalImg,JSON.stringify(resultdata),prompttext);
                }, 2000);
                // var resultdataarr = resultdata.Result;
                var outputarrtemp = []
                var outputimages = resultdata.outputImg;
                var outputimagearr = [];
                var outputimgcarousel =[];
                for (let i = 0; i < outputimages.length; i++) {
                    var elem = {};
                    elem.identity = uuidv4();
                    elem.outimg = outputimages[i]+'?v='+Date.now();
                    outputimagearr.push(elem);
                    elem.original = false;
                    outputimgcarousel.push(elem);
                }
                var _processidentity = resultdata.processId;
                var _userimageurl = resultdata.orignalImg;
                var outwidth = resultdata.outputImgWidth;
                self.setState({ 
                    outputImg: outputimagearr,
                    userimageurl:_userimageurl,
                    outimgcarouseladvanced:outputimgcarousel,
                    showingadvancedresult:true,
                    showoutputimagediv:true,
                    advancetransformactive:true,
                    advanceresultgenerated:true,
                    selectedimagewidth:outwidth,
                    roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                });  
                if (self.state.outimgcarousel.length <= 0 || self.state.automateresultgenerated == false) {
                    self.setState({outimgcarousel:outputimgcarousel,originalimagewidth:outwidth});
                }
                $('.preview-zone').addClass('hidden');
                if (ismobile()) {
                    setTimeout(() => {
                        document.getElementsByClassName('scrollview')[0].scrollIntoView();
                    }, 2000);
                }
                // this.toggleadvanceclass(true);
                // this.togglefurnitureclass(true);
                // setTimeout(() => {
                //     document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
                //         el.style.display = 'none';
                //     });  
                // }, 2000);
                // document.getElementById('generatebtnadvance').style.display = 'inline-block';
                // document.getElementById('generatebtnautomate').style.display = 'none';
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
                this.loadCounterDataInner();
            }
            else{
                self.customAlertMessage('error','Try again, Something went wrong.');
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
            }
            if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayone();
            }
            else{
                self.hideoverlay();
            }

        })
        .catch((error) => {
            console.log(error);
            if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                self.hideoverlayone();
            }
            else{
                self.hideoverlay();
            }
            self.customAlertMessage('error', 'Please try again!');
        });

    }
    else
    {
        if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            this.hideoverlayone();
        }
        else{
            this.hideoverlay();
        }
        //Cookies.set('allowCurrentSubscription', true);
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        //console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var lPType = parseInt(parsedRes.PlanType);
        var lPLevel = parseInt(parsedRes.PlanLevel);
        if(lPType > 0){
            document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
        }				
        document.getElementById('btnUpgradeModelLimit').click();
    }
})
.catch((error) => {
    this.customAlertMessage('error', 'Please try again!');
    console.log(error);
  });

    }

    getarchprompt = () => {
        var prompt = '';
        var archtype = document.getElementById('archtype').value;
        prompt += archtype;
        if (archtype == 'floor') {
            var floorpref = document.getElementById('floorpref').value;            
            if (floorpref == '0') {
                prompt += ", " + document.getElementById('materialpref').value; 
            }
            else{
                prompt += ", " + floorpref; 
            }
        }
        prompt += ", " + document.getElementById('colorpref').value;
        return prompt;
    }

    regeneratewithmaskfromoutputimage = (imgurl) =>{        
        var self = this;
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        var prompt = this.getprompt();
        data.append('file', imgurl);
        data.append('prompt', prompt);
        data.append('uid', UserID);
        data.append('companyid', Companyid);
        data.append('IsEmptyRoom',this.state.isemptyRoom);
        if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
            self.showoverlayone();
        }
        else{
            self.showoverlay();
        }


        let uconfig = {
			method: 'get',
			maxBodyLength: Infinity,
			url: 'https://api.aelo.ai/api/AIUserManager/GetUserCounters?UserId='+UserID+'&CounterType=UsedImagesCount'
			
		  };
          axios.request(uconfig)
		  .then((response) => {
			if(parseInt(JSON.stringify(response.data.Data))>0)
			{
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.aelo.ai/api/ArtificialIntelligence/propertyrecreatewithurl',
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data != undefined && response.data.Data != "error") {
                    var resultdata = JSON.parse(response.data.Data);
                    setTimeout(() => {
                        this.updateuserlog(UserID,resultdata.orignalImg,response.data.Data,prompt)
                    }, 2000);
                    var resultdataarr = resultdata.Result;
            		var outputimages = resultdata.outputImg;
                    var outputimagearr = [];
                    var outputimgcarousel = [];
                    for (let i = 0; i < outputimages.length; i++) {
                        var elem = {};
                        elem.identity = uuidv4();
                        elem.outimg = outputimages[i]+'?v='+Date.now();
                        outputimagearr.push(elem);
                        elem.original = false;
                        outputimgcarousel.push(elem);
                    }
                    // var maskimgoption = [];
                    // for (let j = 0; j < resultdataarr.length; j++) {
                    //     const d = resultdataarr[j];
                    //     if (d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                    //     && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building') {
                    //         var maskopt = {};
                    //         maskopt.value = d.Maskid;
                    //         maskopt.label = d.MaskName;
                    //         maskopt.selected = true;
                    //         maskimgoption.push(maskopt);    
                    //     }
                    // }
                    var _processidentity = resultdata.processId;
                    var _userimageurl = resultdata.orignalImg;
                    var outwidth = resultdata.outputImgWidth;
                    // this.selectInputRef.current.clearValue();
                    if (self.state.Showingselectedpreview == true) {
                        self.setState({processidentityselected:_processidentity});
                    }
                    else{
                        self.setState({processidentity:_processidentity});
                    }
            		self.setState({ 
                        masklistselected: resultdataarr,
                        outputImg:outputimagearr,
                        // processidentity: _processidentity
                        userimageurl:_userimageurl,
                        // processedimage:_userimageurl,
                        outimgcarouseladvanced:outputimgcarousel,
                        // furnishmaskoptions:maskimgoption,
                        showingadvancedresult:true,
                        showoutputimagediv:true,
                        selectedpreviewimage:_userimageurl,
                        advancetransformactive:true,
                        advanceresultgenerated:true,
                        selectedimagewidth:outwidth,
                        roomtypemasktext:document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText,
                    });
                    if (self.state.outimgcarousel.length <= 0 || self.state.automateresultgenerated == false) {
                        self.setState({outimgcarousel:outputimgcarousel,originalimagewidth:outwidth});
                    }
                        // setTimeout(() => {
                    //     this.showselectedpreview();
                    // }, 2000);
                    $('.preview-zone').addClass('hidden');
                    // this.toggleadvanceclass(true);
                    // this.togglefurnitureclass(true);
                    setTimeout(() => {
                        document.querySelectorAll('.maskedImage img.arch').forEach(function (el) {
                            el.style.display = 'none';
                        });  
                        document.querySelectorAll('.maskedImageoutput img.arch').forEach(function (el) {
                            el.style.display = 'none';
                        });  
                    }, 2000);
                    if (ismobile()) {
                        setTimeout(() => {
                            document.getElementsByClassName('scrollview')[0].scrollIntoView();
                        }, 2000);
                    }
                    if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                        self.hideoverlayone();
                    }
                    else{
                        self.hideoverlay();
                    }

                }
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
                this.loadCounterDataInner();
            })
            .catch((error) => {
                console.log(error);
                if (self.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                    self.hideoverlayone();
                }
                else{
                    self.hideoverlay();
                }
                this.customAlertMessage('error', 'Please try again!');
            });

        }
        else
        {
            if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                this.hideoverlayone();
            }
            else{
                this.hideoverlay();
            }
            //Cookies.set('allowCurrentSubscription', true);
            var userI = getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            //console.log(decodedUser);
            var parsedRes = JSON.parse(decodedUser);
            var lPType = parseInt(parsedRes.PlanType);
            var lPLevel = parseInt(parsedRes.PlanLevel);
            if(lPType > 0){
                document.getElementById('ptextSubD').innerText = "You resources have been exhausted. Please upgrade to a higher plan subscription";
            }				
            document.getElementById('btnUpgradeModelLimit').click();
        }
    })
    .catch((error) => {
        this.customAlertMessage('error', 'Please try again!');
        console.log(error);
      });
    }


    historygoback = () => {
        window.history.back();
    }

    showoriginalpreview =() => {           
        // document.getElementById('originalpreviewhref').classList.remove("inactiveanchor");
        // document.getElementById('originalpreviewhref').classList.add("activeanchor");
        // document.getElementById('selectedpreviewhref').classList.remove("activeanchor");
        // document.getElementById('selectedpreviewhref').classList.add("inactiveanchor");
        if (this.state.advancetransformactive == true && this.state.furnishingactive == false) {
            if(this.state.masklist.length > 0){
                this.setState(
                    {Showingselectedpreview:false},() => {
                        this.loadarchmaskforselectionddl();
                    });
            }
            else{
                this.Creeatemaskonupload();
            }            
        }
        else if (this.state.advancetransformactive == true && this.state.isoutdoorStruct == true){
            if(this.state.masklist.length > 0){
                this.setState(
                    {Showingselectedpreview:false},() => {
                        this.handleChangeexternal();
                    });
            }
            else{
                this.Creeatemaskonupload();
            }  
        }
        this.setState({Showingselectedpreview:false});
    }
    showselectedpreview =()=>{
            // document.getElementById('selectedpreviewhref').classList.remove("inactiveanchor");
            // document.getElementById('selectedpreviewhref').classList.add("activeanchor");
            // document.getElementById('originalpreviewhref').classList.remove("activeanchor");
            // document.getElementById('originalpreviewhref').classList.add("inactiveanchor");
            if (this.state.advancetransformactive == true && this.state.furnishingactive == false) {
                this.setState(
                    {Showingselectedpreview:true},() => {
                        this.loadarchmaskforselectionddl();
                    });
            }
            else if (this.state.advancetransformactive == true && this.state.isoutdoorStruct == true){
                if(this.state.masklist.length > 0){
                    this.setState(
                        {Showingselectedpreview:true},() => {
                            this.handleChangeexternal();
                        });
                }
                else{
                    this.Creeatemaskonupload();
                }  
            }
            this.setState({Showingselectedpreview:true});
    }
    toggleoptions = (e) => {
        var linktype = e.currentTarget.dataset.linktype;
        if(linktype == "architect"){
            if (this.state.uploadedfile == "" || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
                this.customAlertMessageone();
                return;
            }
        }        
        if(linktype == "architect"){
            this.setState({furnishmaskvisible: false,furnishingactive:false});
            if (this.state.masklist.length <= 0 && document.getElementById('original').style.display == 'block') {
                this.Creeatemaskonuploadwithoutvalidation();
            }
            else if (this.state.masklistselected.length <= 0 && document.getElementById('selected').style.display == 'block'){
                this.Creeatemaskforoutputimage();
            }
            else{
                this.loadarchmaskforselectionddl();
            }             
        }
        else{
            this.setState({showoutputimagediv:true,furnishingactive:true});
        }
        document.getElementById('colorpref').value = "";
        // document.getElementById('roomthemetextarea').value = "";
    }

    togglemaintab = (e)=>{
        var linktype = e.currentTarget.dataset.linktype;
        if (linktype  == "automate" && document.getElementById("automatelink").className == "tab-active") {
            return;
        }
        if (linktype  == "advance" && document.getElementById("advancelink").className == "tab-active") {
            return;            
        }
        if(linktype=="advance")
        {
            var userI = getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            //console.log(decodedUser);
            var parsedRes = JSON.parse(decodedUser);
            var lPType = parseInt(parsedRes.PlanType);
            var lPLevel = parseInt(parsedRes.PlanLevel);
            if(lPType < 2){
               document.getElementById('btnUpgradeModel').click();
               return;	
            }
           			
        }
        // if(document.getElementById("automatelink").className == "tab-unactive")
        //     document.getElementById("automatelink").className = "tab-active";
        // else
        //     document.getElementById("automatelink").className = "tab-unactive";

        // if(document.getElementById("advancelink").className == "tab-unactive")
        //     document.getElementById("advancelink").className = "tab-active";
        // else
        //     document.getElementById("advancelink").className = "tab-unactive";

        if (linktype == "automate") {
            var furnishactive = (document.getElementById('furnishlink').className == "tab-active");
            this.setState({
                showingadvancedresult:false,
                advancetransformactive:false,
                furnishingactive:furnishactive
            },() => {
                if (this.state.isoutdoorStruct == false) {
                    this.registerautocomplete();
                }                
            });
            document.querySelectorAll('.maskedImageoutput img.arch').forEach(function (el) {
                el.style.display = 'none';
            });
            document.querySelectorAll('.maskedImage img.arch').forEach(function (el) {
                el.style.display = 'none';
            });

        }
        else {
            var furnishactive = (document.getElementById('furnishlink').className == "tab-active");
            this.setState({
                showingadvancedresult:true,
                advancetransformactive:true,
                furnishingactive:furnishactive
            },()=> {                
                if (this.state.masklist.length <= 0) {                    
                    this.Creeatemaskonuploadbackend();
                }
                else if(this.state.masklistselected.length <= 0){
                    this.Creeatemaskforoutputimagebackend();
                }
                if (this.state.isoutdoorStruct == false) {
                    this.registerautocomplete();
                }
                // if (this.state.masklistselected.length <= 0) {
                //     this.Creeatemaskforoutputimagebackend();
                // }
                // if (this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0) {
                //     this.hideoverlayonemask(true);
                // }
                // else{
                //     this.hideoverlaymask(true);
                // }
            });
            // if (this.state.masklist.length <= 0 && furnishactive == false) {
            //     if (document.getElementById('selected').style.display == 'block') {
            //         this.Creeatemaskforoutputimage();
            //     }
            //     else{
            //         this.Creeatemaskonupload();
            //     }
            // }
            
            
            // if (this.state.masklist.length <= 0 && this.state.isoutdoorStruct == true) {
            //     if (document.getElementById('selected').style.display == 'block') {
            //         this.Creeatemaskforoutputimage();
            //     }
            //     else{
            //         this.Creeatemaskonupload();
            //     }
            // }
            // else if(this.state.masklist.length > 0 && this.state.isoutdoorStruct == true){
            //     if (document.getElementById('selected').style.display == 'block') {
                    
            //     }
            //     else{
            //         this.setState(
            //             {Showingselectedpreview:false},() => {
            //                 this.handleChangeexternal();
            //             });
            //     }       
            // }
        }
        document.getElementById('colorpref').value = "";
        document.getElementById('roomthemetextarea').classList.remove('errordrop');
        // document.getElementById('roomthemetextarea').value = "";
    }
    
    setIsemaptyRoom=()=>
    {  
        this.setState({ isemptyRoom:!this.state.isemptyRoom  },()=>{
            if (this.state.isemptyRoom == true) {
                this.customAlertMessageforemptyspace();
            }
        }); 
    }
    setArchitectureintact=()=>{
        this.setState({
            keeparchitectureintact: !this.state.keeparchitectureintact
        },()=>{console.log(this.state.keeparchitectureintact)}
        )
    }
    processImagegeneratenew = ()=>{
        if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
            this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
            document.getElementById('roomtypemask').className += ' errordrop';
            this.focusfieldonerror();
            return;
        }
        if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
            this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
            document.getElementById('roomthemetextarea').classList.add('errordrop');
            this.focusfieldonerror();
            return;
        }
        if (document.getElementById('selected').style.display == 'block') {
            this.processImagewithurlforselectedtransform(document.getElementById('selected').dataset.fileurl);
        }
        else{
            this.processImage();
        }        

        // var checkedlength = document.querySelectorAll('input[class=outputimagecheckbox]:checked').length;            
        // if (checkedlength > 1) {
        //     this.customAlertMessage('error','Please select one image to generate new design.')
        //     return;
        // }
        // if (checkedlength == 1) {
        //     this.processImagewithurl();
        // }
        // else if (this.state.selectedpreviewimage != "" && document.getElementById('originalpreviewhref').className == "activeanchor") {
        //     this.processImage();
        // }
        // else if(this.state.selectedpreviewimage != "" && document.getElementById('selectedpreviewhref').className == "activeanchor"){
        //     this.processImagewithurlforselectedtransform(this.state.selectedpreviewimage);
        // }
        // else{
        //     this.processImage();
        // }       
    }

    loadarchmaskforselectionddl = () =>{
        var archtype = document.getElementById('archtype').value;      
        if (archtype == 'floor') {
         document.getElementById('floorprefdiv').style.display = 'block';
         this.floorprefchange()
        }
        else{
         document.getElementById('floorprefdiv').style.display = 'none';
         document.getElementById('materialprefdiv').style.display = 'none';
        }
        var filteredmask = [];
        // if (document.getElementById('originalpreviewhref').className == "activeanchor") {
        if (this.state.Showingselectedpreview == false) {
            filteredmask = this.state.masklist.filter(function (entry) { return entry.MaskName === archtype;});
        }
        else{
            filteredmask = this.state.masklistselected.filter(function (entry) { return entry.MaskName === archtype;});
        }        
        if (filteredmask.length > 0) {
          var maskidentity = filteredmask[0].Maskid;
          this.setState({selectedarchitecturemask:maskidentity});
        //   try {                    
        //     document.querySelectorAll('.maskedImage img.arch').forEach(function (el) {
        //         el.style.display = 'none';
        //     });
        //     if (document.getElementById('originalpreviewhref').className == "activeanchor") {
        //         document.getElementById('maskcanvasarch'+ maskidentity).style.display = 'block';
        //     }            
        //   } catch (error) {
            
        //   }
        //   try {
        //     document.querySelectorAll('.maskedImageoutput img.arch').forEach(function (el) {
        //         el.style.display = 'none';
        //     });          
        //     if (document.getElementById('selectedpreviewhref').className == "activeanchor") {
        //         document.getElementById('maskcanvasarchoutput'+ maskidentity).style.display = 'block';   
        //     }       
        //   } catch (error) {
            
        //   }
          
        }
        document.getElementById('colorpref').value = "";
    }

    showsegregatedmaskarch = (e) => {
        this.setState({showoutputimagediv:false});
        this.loadarchmaskforselectionddl();
        // document.getElementById('showmaskoptions').style.display = 'block';
        // document.getElementById('outputimagediv').style.display = 'none';
        // document.getElementById('maskedimagefurnish').style.display = 'none';
        // document.getElementById('maskedimagearch').style.display = 'block';
        // document.getElementById('showoutputlink').style.display = 'block';
        // document.getElementById('maskarch').style.display = 'none';
    }
    
    showsegregatedmask = (e) => {
        this.setState({showoutputimagediv:false});
        //  document.getElementById('showmaskoptions').style.display = 'block';
        //  document.getElementById('outputimagediv').style.display = 'none';
        //  document.getElementById('maskedimagefurnish').style.display = 'block';
        //  document.getElementById('maskedimagearch').style.display = 'none';
        //  document.getElementById('showoutputlink').style.display = 'block';
        //  document.getElementById('maskfurnish').style.display = 'none';
    }

    handleChange = (options,event)=>{
        if (this.state.Showingselectedpreview == true) {
            if (event.action === "select-option" && event.option.value === "*") {
                this.setState({
                    selectedmasksforselectedpreview: (this.state.furnishingactive == true && this.state.isoutdoorStruct == false) ? this.state.selectedfurnishmaskoptions.filter(function (d) { 
                        return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                         && d.label != 'fence' && d.label != 'stairs' &&  d.label != 'sky')
                    }) : this.state.selectedfurnishmaskoptions,
                    Allpreviewmaskselected:true
                });
              } 
              else if (event.action === "deselect-option" && event.option.value === "*")
              {
                this.setState({selectedmasksforselectedpreview:[],Allpreviewmaskselected:false});
              }
              else
              {
                this.setState({selectedmasksforselectedpreview:options,Allpreviewmaskselected:false});
              }
            // document.querySelectorAll('.maskedImageoutput img.furnish').forEach(function (el) {
            //     el.style.display = 'none';
            // });
            // document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
            //     el.style.display = 'none';
            // });
            // for (let i = 0; i < options.length; i++) {
            //     const element = options[i];
            //     document.getElementById('maskcanvasdup'+ element.value).style.display = 'block';
            // }
        }
        else
        {
            if (event.action === "select-option" && event.option.value === "*") {
                this.setState({
                    selectedmasks: (this.state.furnishingactive == true && this.state.isoutdoorStruct == false) ? this.state.furnishmaskoptions.filter(function (d) { 
                        return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                         && d.label != 'fence' && d.label != 'stairs'  && d.label != 'sky' )
                    }) : this.state.furnishmaskoptions
                ,allmaskselected:true});
              } 
              else if (event.action === "deselect-option" && event.option.value === "*")
              {
                this.setState({selectedmasks:[],allmaskselected:false});
              }
              else
              {
                this.setState({selectedmasks:options,allmaskselected:false});
              }
            // document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
            //     el.style.display = 'none';
            // });
            // document.querySelectorAll('.maskedImageoutput img.furnish').forEach(function (el) {
            //     el.style.display = 'none';
            // });
            // for (let i = 0; i < options.length; i++) {
            //     const element = options[i];
            //     document.getElementById('maskcanvas'+ element.value).style.display = 'block';
            // }
        }
        
    }
    handleChangeexternal = ()=>{
        // setTimeout(() => {
        //     if (this.state.Showingselectedpreview == true) {
        //         var options = this.state.selectedmasksforselectedpreview;
        //         document.querySelectorAll('.maskedImageoutput img.furnish').forEach(function (el) {
        //             el.style.display = 'none';
        //         });
        //         document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
        //             el.style.display = 'none';
        //         });
        //         for (let i = 0; i < options.length; i++) {
        //             const element = options[i];
        //             document.getElementById('maskcanvasdup'+ element.value).style.display = 'block';
        //         }
        //     }
        //     else
        //     {
        //         var options = this.state.selectedmasks;
        //         document.querySelectorAll('.maskedImage img.furnish').forEach(function (el) {
        //             el.style.display = 'none';
        //         });
        //         document.querySelectorAll('.maskedImageoutput img.furnish').forEach(function (el) {
        //             el.style.display = 'none';
        //         });
        //         for (let i = 0; i < options.length; i++) {
        //             const element = options[i];
        //             document.getElementById('maskcanvas'+ element.value).style.display = 'block';
        //         }
        //     }
        // }, 2000);        
    }
    showoutputimages=()=>{
        this.setState({showoutputimagediv:true});
        // document.getElementById('showoutputlink').style.display = 'none';
        // document.getElementById('maskfurnish').style.display = 'block';
        // document.getElementById('outputimagediv').style.display = 'block';
        // document.getElementById('showmaskoptions').style.display = 'none';
    }
    //Advanced generate button
    Checkmaskandtype =()=>{
        if (this.state.isoutdoorStruct == true) {
            if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
                this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
                document.getElementById('roomtypemask').className += ' errordrop';
                this.focusfieldonerror();
                return;
            }
            if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
                this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
                document.getElementById('roomthemetextarea').classList.add('errordrop');
                this.focusfieldonerror();
                return;
            }
            if (document.getElementById('selected').style.display == 'block') {
                var _fileurl = document.getElementById('selected').dataset.fileurl;
                this.regeneratewithmaskexternalimage(_fileurl);
            }
            else{
                var _fileurl = document.getElementById('original').dataset.fileurl;
                this.regeneratewithmaskexternalimage(_fileurl);
            }
        }
        else{
            if (document.getElementById('furnishlink').className == "tab-active") {
                if (document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0] == undefined || document.getElementById('roomtypemask').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText == "Select") {
                    this.customAlertMessage('error', 'Please select the Space Type from the drop-down menu.');
                    document.getElementById('roomtypemask').className += ' errordrop';
                    this.focusfieldonerror();
                    return;
                }
                if (document.getElementById('roomthemetextarea').value == "" && this.state.isoutdoorStruct == false && this.state.advancetransformactive == false) {
                    this.customAlertMessage('error', 'Please select a Lifestyle from the drop-down menu.');
                    document.getElementById('roomthemetextarea').classList.add('errordrop');
                    this.focusfieldonerror();
                    return;
                }
                if (document.getElementById('selected').style.display == 'block') {
                    var _fileurl = document.getElementById('selected').dataset.fileurl;
                    //this.regeneratewithmaskfromoutputimage(_fileurl);
                    this.regeneratewithmaskexternalimage(_fileurl);
                }
                else{
                    var _fileurl = document.getElementById('original').dataset.fileurl;
                    this.regeneratewithmaskexternalimage(_fileurl);
                    // this.surprisemeimage();
                }
            }
            else{
                if (document.getElementById('selected').style.display == 'block') {
                    var _fileurl = document.getElementById('selected').dataset.fileurl;
                    this.regeneratewithmaskarch(_fileurl);
                }
                else{
                    var _fileurl = document.getElementById('original').dataset.fileurl;
                    this.regeneratewithmaskarch(_fileurl);
                }
            }
        }
        
    }

    handlechangeroomtype =(e)=>{
        if (e == undefined || e == null) {
            return;
        }
        var selectedvalue = e.value;
        // var filteredarr = groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == selectedvalue));
        //setDataprop(groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == selectedvalue))[0]);
        var furnishcheckboxdiv = document.getElementById('furnishcheckboxdiv');
        var advancelink = document.getElementById("advancelink");
        if (furnishcheckboxdiv && advancelink && advancelink.className == "tab-active") {
          var filterarr = groupedOptions[1].options.filter( element => element.value == selectedvalue);
          if(filterarr.length > 0){
            if (selectedvalue == "exterior house architecture") {
                this.registerautocompleteexteriorarchitecture();
            }
            else if(selectedvalue == "front yard"){
                this.registerautocompleteexteriorfrontyard();
            }
            else{
                this.registerautocompleteexterior();
            }
            // furnishcheckboxdiv.style.display = 'none';
            this.setState({isoutdoorStruct:true,roomthemeoptions:themeexterioroptions,furnishingactive:true,isemptyRoom:false});            
            
            if (this.state.masklist.length <= 0 && this.state.maskgenerataedforexterior == false && this.state.advancetransformactive == true) {
                // setTimeout(() => {
                    this.Creeatemaskonuploadwithoutvalidation();
                // }, 2000); 
                this.setState({maskgenerataedforexterior:true,maskgenerataedforarchitecture:false});   
            }
          } 
          else{
            // furnishcheckboxdiv.style.display = 'block';
            this.setState({isoutdoorStruct:false,roomthemeoptions:themeinterioroptions});
            this.registerautocomplete();
          }
          
        }
        else{
            var filterarr = groupedOptions[1].options.filter( element => element.value == selectedvalue);
          if(filterarr.length > 0){
            this.setState({isoutdoorStruct:true,roomthemeoptions:themeexterioroptions,furnishingactive:true,isemptyRoom:false});
            if (selectedvalue == "exterior house architecture") {
                this.registerautocompleteexteriorarchitecture();
            }
            else if(selectedvalue == "front yard"){
                this.registerautocompleteexteriorfrontyard();
            }
            else{
                this.registerautocompleteexterior();
            }
            if (this.state.masklist.length <= 0 && this.state.maskgenerataedforexterior == false && this.state.advancetransformactive == true) {
                // setTimeout(() => {
                    this.Creeatemaskonuploadwithoutvalidation();
                // }, 2000); 
                this.setState({maskgenerataedforexterior:true,maskgenerataedforarchitecture:false});   
            }
          } 
          else{
            this.setState({isoutdoorStruct:false,roomthemeoptions:themeinterioroptions});
            this.registerautocomplete();
          }
          
        }
        if (selectedvalue == '-1') {
            document.getElementById('roomtypemask').classList.add('errordrop');            
        }
        else{
            document.getElementById('roomtypemask').classList.remove('errordrop');
        }
        // document.getElementById('colorpref').value = "";
        // document.getElementById('roomthemetextarea').value = "";
        // document.getElementById('clearauto').style.display = "none";
        document.getElementById('roomthemetextarea').classList.remove('errordrop');
    }

    removeselectedpreviewimage =()=>{
        this.setState({selectedpreviewimage:'',selectedoutputidentity:''});
        this.showoriginalpreview();
        // document.getElementById('originalpreview').style.display = 'block';
        // document.getElementById('originalpreviewh4').style.display = 'block';
        // document.getElementById('originalpreviewicon').style.display = 'block';
        document.getElementById('selected').style.display = 'none';
        document.getElementById('original').style.display = 'block';
    }

    updateuserlog = (UserID,inputimage,jsonresponse,finalPrompt)=>{
        if (inputimage == "") {
            inputimage = "Dummy.jpg";
        }
        var _jsonresponse = JSON.parse(jsonresponse);

        const axios = require('axios');
        var config = {
            method: 'post',
            "url": "https://api.aelo.ai/api/AIUserManager/UpdateDreamplanResponse?UserId="+UserID+"&inputfile=" + inputimage + "&outputfiles="+JSON.stringify(_jsonresponse.outputImg)+"&prompt="+finalPrompt,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        axios.request(config)
        .then((response) => {
            console.log("inside userupdate");
        })
        .catch((error) => {
            console.log(error);
        });
    }

    automatedradiochage =(e)=>{
        var fileurl = e.currentTarget.dataset.fileurl;
        var selectedrefid = e.currentTarget.dataset.selrefid;
        this.setState({
            selectedpreviewimage: fileurl,
            userimageurl:fileurl,
            selectedoutputidentity:selectedrefid,
            masklistselected:[]
        });  
        // var checkboxes = document.getElementsByClassName('faCheckicon');
        // for (let i = 0; i < checkboxes.length; i++) {
        //     const element = checkboxes[i];
        //     element.style.display = 'none';            
        // }
        // e.currentTarget.getElementsByClassName('faCheckicon')[0].style.display = 'block';       
        document.getElementById('selected').dataset.fileurl = fileurl; 
        document.getElementById('selected').style.display = 'block';
        document.getElementById('original').style.display = 'none';
        this.showselectedpreview();
        // this.Creeatemaskforoutputimage();
    }
    advancedradiochage = (e) => {
        var fileurl = e.currentTarget.dataset.fileurl;
        var selectedrefid = e.currentTarget.dataset.selrefid;
        this.setState({selectedpreviewimage: fileurl,userimageurl:fileurl,selectedoutputidentity:selectedrefid});
        // var checkboxes = document.getElementsByClassName('faCheckicon');
        // for (let i = 0; i < checkboxes.length; i++) {
        //     const element = checkboxes[i];
        //     element.style.display = 'none';            
        // }
        // e.currentTarget.getElementsByClassName('faCheckicon')[0].style.display = 'block';
        document.getElementById('selected').dataset.fileurl = fileurl; 
        document.getElementById('selected').style.display = 'block';
        document.getElementById('original').style.display = 'none';
        this.showselectedpreview();
        // if (this.state.isoutdoorStruct == true) {
            this.Creeatemaskforoutputimage();
        // }
        // if (this.state.furnishingactive == false) {
            // call mask generate method
            // this.Creeatemaskforoutputimage();
        // }
    }
    originalradiochage = () =>{
        document.getElementById('selected').style.display = 'none';
        document.getElementById('original').style.display = 'block';
    }

    selectedradiochage = () =>{
        document.getElementById('selected').style.display = 'block';
        document.getElementById('original').style.display = 'none';
    }
    capitalizeFirstLowercaseRest = (str) => {
        return (
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        );
      };

      floorprefchange =(e)=>{
        var fpref = document.getElementById('floorpref');
        if (fpref.value == '0') {
         document.getElementById('materialprefdiv').style.display = 'block';
        }
        else{
         document.getElementById('materialprefdiv').style.display = 'none';
        }
     }

     cleardesigntheme = () =>
     {        
        document.getElementById('roomthemetextarea').value = "";
        document.getElementById('roomthemetextarea').focus();
        document.getElementById('clearauto').style.display="none";

     }

     showclearauto = () =>
     {
        var value = document.getElementById('roomthemetextarea').value;
        if(value == ""){
                document.getElementById('clearauto').style.display="none";

            }
            else{
                document.getElementById('clearauto').style.display="inline-block";
        }
     }
     openMenu = () => {
        if (this.selectInputRef.current != null) {
            this.selectInputRef.current.focus();    
        }
        this.setState({ menuIsOpen: true });
      };
      closeMenu = () => {
        // this.selectInputRef.focus();
        this.setState({ menuIsOpen: false });
      };

      openMenu1 = () => {
        if (this.selectInputRef1.current != null) {
            this.selectInputRef1.current.focus();
        }
        this.setState({ menuIsOpen1: true });
      };
      closeMenu1 = () => {
        // this.selectInputRef.focus();
        this.setState({ menuIsOpen1: false });
      };
    render() {
        return (
            <>
            <div className="main_content_iner overly_inner ">
                <div id="mobileoverlaymask">
                    <div id="loading" style={{ top: "35%" }}>                        
                        <img src={loader} />
                        <h2 id="loaderheading"><span>Generating Mask...</span></h2>
                    </div>
                </div>
                <div id="mobileoverlaysearch">
                    <div id="loading" style={{ top: "35%" }}>                        
                        <img src={loader} />
                        <h2 id="loaderheading"><span>Searching...</span></h2>
                    </div>
                </div>
                <div id="mobileoverlay">
                    <div id="loading">
                        <div className="rotating-text-wrapper-new">
                            <center><img src={loader}/></center>
                            <div class="rotating-text-wrapper" id="randomtext">    
                            {this.state.loadermessages.length  > 0 ? this.state.loadermessages.map((d)=>(
                                <h2>{d}</h2>
                            )
                            ) :
                            <>
                                </>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0 ">
                    <div id="mainpanel" style={{display:(this.state.showproducts == false ? 'block': 'none')}}>
                        <div className='row' id='tabpaneltop'>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <a href='javascript:;' id="automatelink" className={this.state.advancetransformactive == false ? 'tab-active': 'tab-unactive'} data-linktype="automate" onClick={this.togglemaintab} style={{marginRight:"15px"}}>Automated Transforms</a>                                
                                        <a href='javascript:;' id="advancelink" className={this.state.advancetransformactive == true ? 'tab-active': 'tab-unactive'} data-linktype="advance" onClick={this.togglemaintab}>Advanced Transforms</a>
                                    </div>
                                </div>
                                <hr className='border-bottom' style={{marginTop:'0px',borderColor:'#fff'}} />
                            </div>
                            
                        </div>
                        <div id='tabpanelcontent' className={this.state.advancetransformactive == true ? 'row dropheight': 'row'}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-md-4 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-12' style={{display:(this.state.advancetransformactive == true && this.state.isoutdoorStruct == false ? 'block':'none')}}>
                                                <div className='row advanceoption' id='tabpanel'>
                                                    <div className='col-12'>
                                                        <a href='javascript:;' className={this.state.furnishingactive == true ? 'tab-active' : 'tab-unactive'} data-linktype="furnish" onClick={this.toggleoptions} id='furnishlink' style={{marginRight:"15px"}}>Furnishing</a>                                                
                                                        <a href='javascript:;' className={this.state.furnishingactive == false ? 'tab-active' : 'tab-unactive'} data-linktype="architect" onClick={this.toggleoptions} id='architecturallnk'>Architectural</a>
                                                    </div>
                                                </div>
                                                <hr className='border-bottom' style={{marginTop:'0px',borderColor:'#fff'}} />
                                            </div>
                                            <div className='scrollviewleft'>
                                            <div id='interiordiv'>
                                                <div className='col-12 label-info mb-30' style={{display:((this.state.advancetransformactive == true && this.state.furnishingactive == true) || this.state.advancetransformactive == false ? 'block' : 'none')}}>
                                                    <label>Space Type<sup class="red">*</sup></label>
                                                    {this.state.plantype > 0 && this.state.planlevel > 1 ?
                                                    <Select ref={this.roomtypeinputref} menuPortalTarget={document.querySelector('body')} onChange={this.handlechangeroomtype} placeholder="Select" id="roomtypemask" options={groupedOptions} maxMenuHeight={300}/> :
                                                    <Select ref={this.roomtypeinputref} menuPortalTarget={document.querySelector('body')} onChange={this.handlechangeroomtype} placeholder="Select" id="roomtypemask" options={interioroptions} maxMenuHeight={300}/> }
                                                    {/* <Roomtype selval={this.state.roomtypevalue} identity="roomtypemask" /> */}
                                                </div>
                                                <div className='col-12 label-info mb-30' style={{display:(this.state.advancetransformactive == false && this.state.isoutdoorStruct == false ? 'block':'none')}} id="automatefurnishcheckboxdiv">
                                                    <input type='checkbox' className='furnishcheckbox' onChange={this.setArchitectureintact} checked={this.state.keeparchitectureintact}></input>
                                                    <label>Keep walls & windows intact<sup style={{fontWeight:"bold",paddingLeft:"5px"}}>BETA</sup></label>
                                                </div>
                                                <div className='col-12 label-info mb-30' style={{display:(this.state.furnishingactive == true && this.state.advancetransformactive == true && this.state.isoutdoorStruct == false ? 'block':'none')}} id="furnishcheckboxdiv">
                                                    <input type='checkbox' className='furnishcheckbox' onChange={this.setIsemaptyRoom} checked={this.state.isemptyRoom}></input>
                                                    <label>Furnish Empty Space</label>
                                                </div>
                                                <div className='col-12 label-info mb-30' style={{position:"relative",display:((this.state.advancetransformactive == true && this.state.furnishingactive == true) || this.state.advancetransformactive == false ? 'block' : 'none')}}>
                                                    <label>Lifestyle {this.state.isoutdoorStruct == false && this.state.advancetransformactive == false ? <sup class="red">*</sup> : null}</label>
                                                    <input type='text' id='roomthemetextarea' className='form-control' onChange={this.showclearauto} placeholder='Select or enter your lifestyle'></input>
                                                    <a className='clearauto' id="clearauto" href='javascript:;' style={{display:"none"}} onClick={this.cleardesigntheme}><FontAwesomeIcon icon={faRemove}/></a>
                                                    {/* <Select placeholder="Select" id="roomthememask" options={this.state.roomthemeoptions} maxMenuHeight={300}/> */}
                                                    {/* <Roomtheme selval={this.state.roomthemevalue} identity="roomthememask" /> */}
                                                </div>                                            
                                            </div>
                                            <div id='architexturaldiv' style={{display:(this.state.advancetransformactive == true && this.state.furnishingactive == false) ? 'block':'none'}}>
                                                <div className='col-12 label-info mb-30'>
                                                    <label>Select the Architectural Element</label>
                                                    <select id="archtype" className='form-control' onChange={this.loadarchmaskforselectionddl}>                                              
                                                        { this.state.Showingselectedpreview == true ?
                                                        (this.state.masklistselected.length > 0 ? this.state.masklistselected.sort( function( a, b ) {
                                                            return a.MaskName < b.MaskName ? -1 : a.MaskName > b.MaskName ? 1 : 0;
                                                        }).map((d) => ( d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'windowpane' || d.MaskName == 'fireplace' || d.MaskName == 'door'
                                                            || d.MaskName == 'fence' || d.MaskName == 'stairs'  || d.MaskName == 'sky'  || d.MaskName == 'stairway'
                                                            || d.MaskName == 'staircase' || d.MaskName == 'rail' ?
                                                            <option value={d.MaskName}>{this.capitalizeFirstLowercaseRest(d.MaskName)}</option>           
                                                            : null
                                                        )) : null)
                                                        : this.state.masklist.length > 0 ? this.state.masklist.sort( function( a, b ) {
                                                            return a.MaskName < b.MaskName ? -1 : a.MaskName > b.MaskName ? 1 : 0;
                                                        }).map((d) => (
                                                            d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'windowpane' || d.MaskName == 'fireplace' || d.MaskName == 'door'
                                                            || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'sky'  || d.MaskName == 'stairway'
                                                            || d.MaskName == 'staircase' || d.MaskName == 'rail' ?
                                                            <option value={d.MaskName}>{this.capitalizeFirstLowercaseRest(d.MaskName)}</option>
                                                            : null
                                                    )) : null}
                                                    </select>
                                                    
                                                </div>
                                                <div className='col-12 label-info mb-30' id='floorprefdiv' style={{display:'none'}}>
                                                    <label>Select material preferences</label>
                                                    {/* <Archtype selval={this.state.roomtypevalue} dataopt="1,2,3" /> */}
                                                    <select id="floorpref" class="form-control" onChange={this.floorprefchange}>
                                                        <option value="Ceramic Tile">Ceramic Tile</option>
                                                        <option value="0">Enter manually</option>
                                                        <option value="Granite flooring">Granite flooring</option>
                                                        <option value="Grey Carpet flooring">Grey Carpet flooring</option>
                                                        <option value="Hardwood Flooring">Hardwood Flooring</option>
                                                        <option value="Laminate wooden flooring">Laminate wooden flooring</option>
                                                        <option value="Marble flooring">Marble flooring</option>
                                                        <option value="Oak wood flooring">Oak wood flooring</option>
                                                        <option value="Porcelain Tile">Porcelain Tile</option>
                                                        <option value="Vinyl flooring">Vinyl flooring</option>
                                                    </select>
                                                </div>
                                                <div className='col-12 label-info mb-30' id='materialprefdiv' style={{display:'none'}}>
                                                    <label>Enter Material Preferences</label>
                                                    <textarea className='common-textarea' id='materialpref'></textarea>
                                                </div>
                                            </div>
                                            {
                                            this.state.isoutdoorStruct == true ? 
                                                <div className='col-12 label-info mb-30'>
                                                    <label>Enter Design Preferences</label>
                                                    <textarea id='colorpref' className='common-textarea' style={{ minHeight: "50px" }} placeholder='Example: Add water fountain'></textarea>
                                                </div> :
                                                <div className='col-12 label-info mb-30'>
                                                    <label>Enter Color and Pattern Preferences</label>
                                                    <textarea id='colorpref' className='common-textarea' style={{ minHeight: "50px" }} placeholder='Example: Blue color floral print'></textarea>
                                                </div>
                                            }
                                            
                                        
                                            <div className='col-12 label-info mb-30 furniturediv' id="furnituremaskdivddl" style={{display:((this.state.isoutdoorStruct == true || this.state.furnishingactive == true) && this.state.isemptyRoom == false && this.state.advancetransformactive == true && (this.state.furnishmaskoptions.length > 0 || this.state.selectedfurnishmaskoptions.length > 0) ? 'block': 'none')}}>
                                                <label>Mask</label>
                                                {
                                                this.state.Showingselectedpreview == true ? 
                                                <Select id="ddlfurnishmask" menuIsOpen={this.state.menuIsOpen} onFocus={this.openMenu} onBlur={this.closeMenu} ref={this.selectInputRef} isMulti="true" menuPlacement="top" menuPortalTarget={document.querySelector('body')} value={this.state.selectedmasksforselectedpreview} options={[{ label: "All", value: "*" }, ...(this.state.furnishingactive == true && this.state.isoutdoorStruct == false) ? this.state.selectedfurnishmaskoptions.filter(function (d) { 
                                                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                                                    && d.label != 'fence' && d.label != 'stairs'  && d.label != 'sky' )
                                                }) : this.state.selectedfurnishmaskoptions]} maxMenuHeight={200} onChange={this.handleChange} />
                                                : <Select id="ddlfurnishmask" menuIsOpen={this.state.menuIsOpen1} onFocus={this.openMenu1} onBlur={this.closeMenu1}  ref={this.selectInputRef1} isMulti="true" menuPlacement="top" menuPortalTarget={document.querySelector('body')} value={this.state.selectedmasks} options={[{ label: "All", value: "*" } , ...(this.state.furnishingactive == true && this.state.isoutdoorStruct == false) ? this.state.furnishmaskoptions.filter(function (d) { 
                                                    return (d.label != 'ceiling' && d.label != 'wall' && d.label != 'floor' && d.label != 'window' && d.label != 'windowpane' && d.label != 'fireplace' && d.label != 'door' 
                                                    && d.label != 'fence' && d.label != 'stairs'  && d.label != 'sky' )
                                                }) : this.state.furnishmaskoptions]} maxMenuHeight={200} onChange={this.handleChange} />
                                                }                                            
                                            </div>
                                            {/* <div className='col-12 label-info mb-30 furniturediv' id="furnituremaskdivddl" style={{display:(this.state.isoutdoorStruct == true && this.state.advancetransformactive == true && (this.state.furnishmaskoptions.length > 0 || this.state.selectedfurnishmaskoptions.length > 0) ? 'block': 'none')}}>
                                                <label>Mask</label>
                                                {
                                                this.state.Showingselectedpreview == true ? 
                                                <Select id="ddlfurnishmask" ref={this.selectInputRef} isMulti="true" menuPlacement="top" menuPortalTarget={document.querySelector('body')} value={this.state.selectedmasksforselectedpreview} options={this.state.selectedfurnishmaskoptions} maxMenuHeight={200} onChange={this.handleChange} />
                                                : <Select id="ddlfurnishmask" ref={this.selectInputRef1} isMulti="true" menuPlacement="top" menuPortalTarget={document.querySelector('body')} value={this.state.selectedmasks} options={this.state.furnishmaskoptions} maxMenuHeight={200} onChange={this.handleChange} />
                                                }
                                            </div> */}
                                            <div className='col-12 label-info mb-30' style={{display: (this.state.selectedpreviewimage != "" && this.state.showoutputimagediv == true ? 'block':'none')}}>
                                                <a href='javascript:;' id="originalpreviewhref" className={this.state.Showingselectedpreview == false ? 'activeanchor' : 'inactiveanchor'} onClick={this.showoriginalpreview}>Original Image</a>
                                                <a href='javascript:;' id="selectedpreviewhref" className={this.state.Showingselectedpreview == true ? 'activeanchor' : 'inactiveanchor'} onClick={this.showselectedpreview}>Selected Transform</a>
                                                <hr className='border-bottom' style={{marginTop:'0px',borderColor:'#fff'}} />
                                            </div>
                                            <div className='col-12 mb-30'>
                                                <center>
                                                        <div className='leftoriginalwrap' style={{display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false ? 'block':'none')}}>
                                                            <h4 className='left-original' style={{display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false && this.state.selectedpreviewimage == "" ? 'block':'none')}} id="originalpreviewh4">Original Image</h4>                                                        
                                                            <div className='positionRelative'>
                                                                <div className='iconholder' style={{zIndex:19999,display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false ? 'block':'none'), top:"15px"}}>
                                                                    {
                                                                    Features.googlecatalog == true ?
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Shop" flow="left">
                                                                            <a href='javascript:;' onClick={this.searchimagefromuploadedfile}>
                                                                                <FontAwesomeIcon icon={faDollarSign} />
                                                                            </a>
                                                                        </span> 
                                                                        : null
                                                                    }
                                                                    <span id="originalpreviewicon" className="float-end tooltip1 iconholdercircle" tooltip="Select to Transform" flow="left"  data-fileurl={this.state.processedimage} onClick={this.originalradiochage}>
                                                                        <div className='check-whitebox'>
                                                                            <FontAwesomeIcon id="original" icon={faCheck} className='faCheckicontransform' style={{display:'none'}} />
                                                                        </div>
                                                                    </span>                                                               
                                                                </div>
                                                                <span className='uploadedImage'>
                                                                    <img id="originalpreview" src={this.state.processedimage} className='originalleftimage' style={{display:(this.state.Showingselectedpreview == false ? 'block':'none')}} />
                                                                </span>
                                                                <span className='maskedImage' id='maskedimagearch' style={{display:(this.state.advancetransformactive == true ? 'block':'none')}}>
                                                                    <center>
                                                                        { (this.state.isoutdoorStruct == true || this.state.furnishingactive == true) && this.state.advancetransformactive == true && this.state.isemptyRoom == false ? (this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                    // d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                                                                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building' ?
                                                                        <img id={'maskcanvas' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='furnish' 
                                                                        style={{display:(this.state.selectedmasks.filter(function (entry) { return entry.label === d.MaskName;}).length > 0 && this.state.Showingselectedpreview == false ?'block':'none')}} /> 
                                                                        // : null
                                                                )) 
                                                                : null
                                                                ) :
                                                                        (this.state.masklist.length > 0 && this.state.advancetransformactive == true && this.state.furnishingactive == false && this.state.Showingselectedpreview == false ? this.state.masklist.map((d) => (
                                                                            // d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'door' || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'house'
                                                                            //  || d.MaskName == 'sky' || d.MaskName == 'building' ?
                                                                                <img id={'maskcanvasarch' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='arch' style={{display:(d.Maskid == this.state.selectedarchitecturemask ? 'block': 'none')}} /> 
                                                                                // : null
                                                                        )) : null)
                                                                        }                                                            
                                                                    </center>
                                                                </span>  
                                                            </div>
                                                        </div>
                                                        {/* <div className='leftoriginalwrap' style={{display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false ? 'block':'none')}}>
                                                            <h4 className='left-original' style={{display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false && this.state.selectedpreviewimage == "" ? 'block':'none')}} id="originalpreviewh4">Original Image</h4>                                                        
                                                            <div className='positionRelative'>
                                                                <div className='iconholder' style={{zIndex:19999,display:(this.state.processedimage != "" && this.state.Showingselectedpreview == false ? 'block':'none'), top:"15px"}}>
                                                                    <span id="originalpreviewicon" className="float-end tooltip1 iconholdercircle" tooltip="Select to Transform" flow="left"  data-fileurl={this.state.processedimage} onClick={this.originalradiochage}>
                                                                        <div className='check-whitebox'>
                                                                            <FontAwesomeIcon id="original" icon={faCheck} className='faCheckicontransform' style={{display:'none'}} />
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span className='uploadedImage'>
                                                                    <img id="originalpreview" src={this.state.processedimage} className='originalleftimage' style={{display:(this.state.Showingselectedpreview == false ? 'block':'none')}} />
                                                                </span>
                                                                <span className='maskedImage' id='maskedimagearch' style={{display:(this.state.furnishingactive == false || this.state.isoutdoorStruct == true ? 'block':'none')}}>
                                                                    <center>
                                                                        { this.state.isoutdoorStruct == true && this.state.advancetransformactive == true ? (this.state.masklist.length > 0 ? this.state.masklist.map((d) => (
                                                                    // d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                                                                    // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building' ?
                                                                        <img id={'maskcanvas' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='furnish' 
                                                                        style={{display:(this.state.selectedmasks.filter(function (entry) { return entry.label === d.MaskName;}).length > 0 && this.state.Showingselectedpreview == false ?'block':'none')}} /> 
                                                                        // : null
                                                                )) 
                                                                : null
                                                                ) :
                                                                        (this.state.masklist.length > 0 && this.state.advancetransformactive == true && this.state.furnishingactive == false && this.state.Showingselectedpreview == false ? this.state.masklist.map((d) => (
                                                                            // d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'door' || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'house'
                                                                            //  || d.MaskName == 'sky' || d.MaskName == 'building' ?
                                                                                <img id={'maskcanvasarch' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='arch' style={{display:(d.Maskid == this.state.selectedarchitecturemask ? 'block': 'none')}} /> 
                                                                                // : null
                                                                        )) : null)
                                                                        }                                                            
                                                                    </center>
                                                                </span>  
                                                            </div>
                                                        </div> */}
                                                        <div className='selectoriginalwrap' style={{display:(this.state.selectedpreviewimage != "" && this.state.Showingselectedpreview == true ? 'block': 'none')}}>                                                    
                                                            <div className='positionRelative'>
                                                                <div className='iconholder' id="seelctediconholder" style={{zIndex:19999}}>
                                                                {
                                                                    Features.googlecatalog == true ?
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Shop" flow="left">
                                                                            <a href='javascript:;' data-fileurl={this.state.selectedpreviewimage} onClick={this.searchimage}>
                                                                                <FontAwesomeIcon icon={faDollarSign} />
                                                                            </a>
                                                                        </span> 
                                                                        : null
                                                                    }
                                                                    <span className="float-end tooltip1 iconholdercircle" tooltip="Remove" flow="left" style={{padding:"3px 10px"}} onClick={this.removeselectedpreviewimage} ><FontAwesomeIcon icon={faClose}></FontAwesomeIcon></span>                                                        
                                                                    <span className="float-end tooltip1 iconholdercircle" tooltip="Select to Transform" flow="left"  data-fileurl={this.state.selectedpreviewimage} onClick={this.selectedradiochage}>
                                                                        <div className='check-whitebox'>
                                                                            <FontAwesomeIcon id="selected" icon={faCheck} className='faCheckicontransform' style={{display:'none'}} />
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <span className='uploadedImage'>
                                                                    <img id="selectedpreview" src={this.state.selectedpreviewimage} className='originalleftimage' style={{display:(this.state.selectedpreviewimage != "" && this.state.Showingselectedpreview == true ? 'block': 'none')}} />
                                                                </span>
                                                                <span className='maskedImageoutput' id='maskedimagearchforoutput' style={{display:(this.state.furnishingactive == true || this.state.isoutdoorStruct == true || this.state.Showingselectedpreview == true ? 'block':'none')}}>
                                                                    <center>
                                                                        {
                                                                            (this.state.furnishingactive == true || this.state.isoutdoorStruct == true) && this.state.advancetransformactive == true && this.state.isemptyRoom == false ? (this.state.masklistselected.length > 0 ? this.state.masklistselected.map((d) => (
                                                                                // d.MaskName != 'ceiling' && d.MaskName != 'wall' && d.MaskName != 'floor' && d.MaskName != 'window' && d.MaskName != 'door' 
                                                                                // && d.MaskName != 'fence' && d.MaskName != 'stairs' && d.MaskName != 'house' && d.MaskName != 'sky' && d.MaskName != 'building' ?
                                                                                    <img id={'maskcanvasdup' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='furnish' 
                                                                                    style={{display:(this.state.selectedmasksforselectedpreview.filter(function (entry) { return entry.label === d.MaskName;}).length > 0 && this.state.Showingselectedpreview == true ?'block':'none')}} /> 
                                                                                    // : null
                                                                            )) : null) :
                                                                        (this.state.masklistselected.length > 0 && this.state.advancetransformactive == true && this.state.furnishingactive == false && this.state.Showingselectedpreview == true ? this.state.masklistselected.map((d) => (
                                                            //                 d.MaskName == 'ceiling' || d.MaskName == 'wall' || d.MaskName == 'floor' || d.MaskName == 'window' || d.MaskName == 'door'
                                                            // || d.MaskName == 'fence' || d.MaskName == 'stairs' || d.MaskName == 'house' || d.MaskName == 'sky' || d.MaskName == 'building' ?
                                                                                <img id={'maskcanvasarchoutput' + d.Maskid} data-label={d.MaskName} src={d.MaskUrl} className='arch' style={{display:(d.Maskid == this.state.selectedarchitecturemask ? 'block': 'none')}} /> 
                                                                                // : null
                                                                        )) : null)
                                                                        }                                                            
                                                                    </center>
                                                                </span>
                                                            </div>
                                                        </div>
                                                </center>
                                            </div>     
                                            </div>                                   
                                            <div className='col-12 mb-30 furniturediv' style={{display:'none'}} id="showmasklink">
                                                <center>
                                                <a href='javascript:;' id='maskfurnish' onClick={this.showsegregatedmask} style={{ color: "#9333ea" }}>View mask</a>
                                                <a href='javascript:;' id='maskarch' onClick={this.showsegregatedmaskarch} style={{ color: "#9333ea",display:'none' }}>View mask</a>
                                                <a href='javascript:;' id='showoutputlink' onClick={this.showoutputimages} style={{ color: "#9333ea",display:'none' }}>View output</a>
                                                </center>                                            
                                            </div>
                                            {/* <div className='col-12 mb-30'>
                                                <center><a href="javascript:;" className='common-save' style={{textAlign:"center" }} onClick={this.regeneratewithmask} id='btnfurnishredesign'>Generate New Design</a></center>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='col-md-8 col-sm-12'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="dropzone-wrapper mobiletop0" style={{ width: "100%", marginBottom: "40px", marginTop: "15px" }}>
                                                    <div id="overlayiphone">
                                                        <div id="loading" style={{ top: "35%" }}>
                                                            <h2 id="loaderheading">Generating...</h2>
                                                            <img src={loader} />
                                                        </div>
                                                    </div>
                                                    <div className="dropzone-desc">
                                                        <h4>Drag and Drop</h4>
                                                        <p><b>or Click to upload</b></p>
                                                        <FontAwesomeIcon icon={faCloudArrowUp} />
                                                        <p>
                                                            Supported Files: PNG, JPG, HEIF, HEIC.
                                                        </p>
                                                    </div>
                                                    <input type="file" name="img_logo" className="dropzone" title='' accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"></input>
                                                </div>
                                            </div>
                                            <div className='col-12 preview-zone hidden'>
                                                <div className='row' style={{position:"relative"}}>
                                                    <div id="overlaymask">
                                                        <div id="loading" style={{ top: "35%" }}>                                                        
                                                            <img src={loader} />
                                                            <h2 id="loaderheading"><span>Generating Mask...</span></h2>
                                                        </div>
                                                    </div>
                                                    <div id="overlaysearchoriginal">
                                                        <div id="loading" style={{ top: "35%" }}>                                                        
                                                            <img src={loader} />
                                                            <h2 id="loaderheading"><span>Searching...</span></h2>
                                                        </div>
                                                    </div> 
                                                    <div className='col-12 mobilehidden' style={{ marginBottom: "20px" }}>
                                                        <center id="uploadedcenterdiv" style={{margin:"0 auto",maxWidth:'100%',width:(this.state.originalimagewidth)+'px'}}>
                                                            <h5 className="right-header"><b>Original Image</b></h5>
                                                            <div style={{position:"relative"}}>
                                                                <div className='iconholder'><span className="float-end tooltip1 iconholdercircle" tooltip="Shop" flow="left">
                                                                    <a href='javascript:;' onClick={this.searchimagefromuploadedfile}><FontAwesomeIcon icon={faDollarSign} /></a></span>
                                                                </div>
                                                            </div>
                                                            <img className='aelouploaded' id="hideloadimage" src={this.state.processedimage}></img>
                                                        </center>
                                                    </div>

                                                    <div id='overlay' className='newloader'>
                                                        <div id="loading">
                                                            <div className="rotating-text-wrapper-new">
                                                                <center><img src={loader}/></center>
                                                                <div class="rotating-text-wrapper" id="randomtext">    
                                                                {this.state.loadermessages.length  > 0 ? this.state.loadermessages.map((d)=>(
                                                                    <h2>{d}</h2>
                                                                )
                                                                ) :
                                                                <>
                                                                    </>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 text-center'>
                                                        <a href="javascript:;" id="showmebtnautomate" className="common-save" style={{display:(this.state.advancetransformactive == false ? 'inline-block':'none')}} onClick={this.processImage}>Generate New Design</a>
                                                        <a href="javascript:;" id="showmebtnadvance" style={{display:(this.state.advancetransformactive == true ? 'inline-block':'none')}} className="common-save" onClick={this.surprisemeimageparent}>Generate New Design</a>
                                                        <a href='javascript:;' className='remove-preview common-save common-save-grey'>Clear</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-12' id="outputimagediv">
                                                <div className='row' style={{position:"relative"}}>
                                                    {/* <div id="overlayone">
                                                        <div id="loading" style={{ top: "35%" }}>
                                                            <h2 id="loaderheading">Generating...</h2>
                                                            <img src={loader} />
                                                        </div>
                                                    </div>  */}

                                                    <div id="overlayonemask">
                                                        <div id="loading" style={{ top: "35%" }}>
                                                        
                                                            <img src={loader} />
                                                            <h2 id="loaderheading"><span>Generating Mask...</span></h2>
                                                        </div>
                                                    </div> 
                                                    <div id="overlaysearch">
                                                        <div id="loading" style={{ top: "35%" }}>                                                        
                                                            <img src={loader} />
                                                            <h2 id="loaderheading"><span>Searching...</span></h2>
                                                        </div>
                                                    </div> 

                                                    {this.state.outimgcarousel.length > 0 || this.state.outimgcarouseladvanced.length > 0 ?
                                                    <div className='col-12'>
                                                        <center><h5 className="right-header"><b>{this.state.roomtypemasktext}</b></h5></center>
                                                    </div> : null}

                                                    <div id='overlayone' className='newloader'>
                                                        <div id="loading">
                                                            <div className="rotating-text-wrapper-new">
                                                                <center></center><img src={loader}/>
                                                                <div class="rotating-text-wrapper" id="randomtext">    
                                                                {this.state.loadermessages.length  > 0 ? this.state.loadermessages.map((d)=>(
                                                                    <h2>{d}</h2>
                                                                )
                                                                ) :
                                                                <>
                                                                    </>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='scrollview' id="scrollviewhide">
                                                    {
                                                                this.state.advancetransformactive == false ?
                                                    this.state.outimgcarousel.length > 0 ? this.state.outimgcarousel.map((d) => ( 
                                                        <>
                                                        <div className='col-12' style={{ marginBottom: "20px" }}>
                                                            <center className='imgoutcenter' style={{width:(ismobile() ? '100%': this.state.originalimagewidth+'px')}}>
                                                                <div style={{ position: "relative" }}>
                                                                    <div className='iconholder'>
                                                                        {Features.googlecatalog == true ?
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Shop" flow="left">
                                                                            <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.searchimage}>
                                                                                <FontAwesomeIcon icon={faDollarSign} />
                                                                            </a>
                                                                        </span> : null}
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Download" flow="left">
                                                                            <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.handleDownload}>
                                                                                <FontAwesomeIcon icon={faCircleDown} />
                                                                            </a>
                                                                        </span>
                                                                        {/* <span className="float-end tooltip1 iconholdercircle" tooltip="Share" flow="left" style={{display:(navigator.canShare != undefined ? 'block': 'none')}}>
                                                                            <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.externalshare}>
                                                                                <FontAwesomeIcon icon={faShare} />
                                                                            </a>
                                                                        </span> */}
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Share" flow="left">
                                                                                {
                                                                                navigator.canShare != undefined ? 
                                                                                <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.externalshare}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                </a>
                                                                                : <a key={uuidv4()} href={'mailto:?body=' + d.outimg} data-fileurl={d.outimg}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                    </a>
                                                                                }
                                                                        </span>
                                                                        <span className="float-end tooltip1 iconholdercircle" tooltip="Select to Transform" flow="left"  data-fileurl={d.outimg} data-selrefid={d.identity} onClick={this.automatedradiochage}>
                                                                            <div className='check-whitebox'>
                                                                                <FontAwesomeIcon id={uuidv4()} icon={faCheck} className='faCheckicon' style={{display:(this.state.selectedoutputidentity == d.identity ? 'block': 'none')}} />
                                                                            </div>
                                                                            {/* <input id={'radio-'+d.identity} key={uuidv4()} type='radio' name='automateradio' className='outputimagecheckbox' data-fileurl={d.outimg} onChange={this.automatedradiochage} checked />
                                                                            <label for={'radio-'+d.identity} class="radio-custom-label"></label> */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <img key={uuidv4()} className='aelouploaded' src={d.outimg}></img>
                                                            </center>
                                                        </div>
                                                        </>
                                                    ))
                                                    
                                                    : null :
                                                    this.state.outimgcarouseladvanced.length > 0 ? this.state.outimgcarouseladvanced.map((d) => ( 
                                                        <>
                                                            <div className='col-12' style={{ marginBottom: "20px" }}>
                                                                <center className='imgoutcenter' style={{width:(ismobile() ? '100%': this.state.selectedimagewidth+'px')}}>
                                                                    <div style={{ position: "relative" }}>
                                                                        <div className='iconholder'>
                                                                        {Features.googlecatalog == true ?
                                                                            <span className="float-end tooltip1 iconholdercircle" tooltip="Shop" flow="left">
                                                                                <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.searchimage}>
                                                                                    <FontAwesomeIcon icon={faDollarSign} />
                                                                                </a>
                                                                            </span> : null}
                                                                            <span className="float-end tooltip1 iconholdercircle" tooltip="Download" flow="left">
                                                                                <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.handleDownload}>
                                                                                    <FontAwesomeIcon icon={faCircleDown} />
                                                                                </a>
                                                                            </span>
                                                                            {/* <span className="float-end tooltip1 iconholdercircle" tooltip="Share" flow="left" style={{display:(navigator.canShare != undefined ? 'block': 'none')}}>
                                                                                {
                                                                                navigator.canShare != undefined ? 
                                                                                <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.externalshare}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                </a>
                                                                                : <a key={uuidv4()} href={'mailto:?body=' + d.outimg} data-fileurl={d.outimg}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                    </a>
                                                                                }
                                                                            </span> */}
                                                                            <span className="float-end tooltip1 iconholdercircle" tooltip="Share" flow="left">
                                                                                {
                                                                                navigator.canShare != undefined ? 
                                                                                <a key={uuidv4()} href='javascript:;' data-fileurl={d.outimg} onClick={this.externalshare}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                </a>
                                                                                : <a key={uuidv4()} href={'mailto:?body=' + d.outimg} data-fileurl={d.outimg}>
                                                                                    <FontAwesomeIcon icon={faShare} />
                                                                                    </a>
                                                                                }
                                                                            </span>
                                                                            <span className="float-end tooltip1 iconholdercircle" tooltip="Select to Transform" flow="left" data-fileurl={d.outimg} data-selrefid={d.identity} onClick={this.advancedradiochage}>
                                                                                {/* <input id={'radio-'+d.identity} key={uuidv4()} type='radio' name='advancedradio' className='outputimagecheckbox radio-custom' data-fileurl={d.outimg} onChange={this.advancedradiochage} />
                                                                                <label for={'radio-'+d.identity} class="radio-custom-label"></label> */}
                                                                                <div className='check-whitebox'>
                                                                                    <FontAwesomeIcon icon={faCheck} className='faCheckicon' style={{display:(this.state.selectedoutputidentity == d.identity ? 'block': 'none')}} />
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <img key={uuidv4()} className='aelouploaded' src={d.outimg}></img>
                                                                </center>
                                                            </div>
                                                        </>
                                                    ))
                                                    : null
                                                    }
                                                    </div>
                                                    {this.state.outimgcarousel.length > 0 ?
                                                    <div className='col-12 text-center'>
                                                        
                                                        {this.state.showingadvancedresult == false ?
                                                            <>
                                                                <p className='quotetext'>Select an image to transform further. Move selected image from Automated to Advanced Transforms.
                                                                    <span className="tooltip1" tooltip="You can select one of the transformed images to use it as the base image to create more images. You can take the selected image from Automated Transforms to Advanced Transforms and vice versa to create more images." flow="left">
                                                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                                                    </span>
                                                                </p>                                               
                                                                <a href="javascript:;" id="generatebtnautomate" className="common-save" onClick={this.processImagegeneratenew}>Generate New Design</a>
                                                            </>
                                                        :
                                                        <>
                                                        <p className='quotetext'>Select an image to transform further. Move selected image from Advanced to Automated Transforms.
                                                            <span className="tooltip1" tooltip="You can select one of the transformed images to use it as the base image to create more images. You can take the selected image from Advanced Transforms to Automated Transforms and vice versa to create more images." flow="left">
                                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                                            </span>
                                                        </p>
                                                        <a href="javascript:;" id="generatebtnadvance" className="common-save" onClick={this.Checkmaskandtype}>Generate New Design</a>
                                                        </>
                                                        }
                                                        {/* <a href="javascript:;" id="showmebtn" className="common-save">Show Me</a> */}
                                                        <a href='javascript:;' className='common-save common-save-grey' onClick={this.customAlertMessageclear}>Clear</a>
                                                    </div>
                                                    : null}
                                                </div>
                                            </div>                                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               <div className='row' id='productpanel' style={{display:(this.state.showproducts == true ? 'flex': 'none')}}>
                        <div className="col-12">
                            <div className="title-card1">
                                <span className="float-start">
                                <h4>
                                    <span
                                    style={{ cursor: "pointer" }}
                                    onClick={this.hideshowproducts}
                                    >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    Back
                                    </span>
                                </h4>
                                </span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div><p className='alert alert-info'><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>&nbsp;&nbsp; Please click on any object to search for similar items in our catalog.</p></div>
                                    <div className="gallery-results">
                                        <div className="result-image">
                                        {/* <img src="https://demo.restb.ai/images/demo/demo-1.jpg" /> */}
                                        <img src={this.state.oriinalimageforproduct} />
                                        </div>
                                        <div className="result-mask">
                                        {this.state.listtopopulaate.length > 0 ? this.state.listtopopulaate.map((d) => (
                                        <img src={d.maskimg} id={'mask'+d.id} style={{display:'none'}}></img>
                                        )) : null}
                                        
                                        </div>
                                        {this.state.listtopopulaate.length > 0 ? this.state.listtopopulaate.map((d) => (
                                        <div className="result-tag" id={d.id} style={{top:(d.y_axis != '' ? d.y_axis : '0%'), left: (d.x_axis != ''? d.x_axis : '0%')}}
                                         onMouseEnter={this.handlemouseentermask} onMouseLeave={this.handlemouseleavemask} onClick={this.getproducts} data-objtgt={d.id} 
                                         data-maskname={d.dname} data-clipimageurl={d.maskimg}>

                                            <label>{d.dname}</label>
                                        </div>
                                        )) : null}
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12" style={{display:'block',position:'relative'}}>
                                <div id="overlaysearchone">
                                    <div id="loading" style={{ top: "35%" }}>                                                        
                                        <img src={loader} />
                                        <h2 id="loaderheading"><span>Searching...</span></h2>
                                    </div>
                                </div> 
                                    <div className="product-catalog">
                                    {/* <div id='overlaytwo' className='newloader'>
                                                        <div id="loading">
                                                            <div className="rotating-text-wrapper-new">
                                                                <center></center><img src={loader}/>
                                                                <div class="rotating-text-wrapper" id="randomtext">    
                                                                {this.state.loadermessages.length  > 0 ? this.state.loadermessages.map((d)=>(
                                                                    <h2>{d}</h2>
                                                                )
                                                                ) :
                                                                <>
                                                                    </>
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                */}
                                                    
                                    {this.state.productdatafromvision.length > 0 ? this.state.productdatafromvision.map((d) => (
                                        <div className='item-wrapper'>
                                            <a href={d.productUrl} target='_blank' style={{textDecoration:'none'}}>
                                        <img src={d.imgUrl} />
                                        {/* <p>{d.score}</p> */}
                                        <h4>{d.vendor}</h4>
                                        <h4><b>{d.price}</b></h4>
                                        </a>
                                    </div>
                                        )) : <p style={{color:'#fff'}}>No Results</p>}
                                        {/* <div className='item-wrapper'> 
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20211124/13f58396-6928-4af5-86cb-75fd515d375a.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20211224/140b07e9-3b41-4416-ba0b-5e49bf769ec7.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20200703/d306b801856c4537ae71f74d97a825cd.jpeg" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20211112/54bdfb99-190c-4003-933c-d1a00f14cce5.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20220222/1785213f-8206-406b-8de0-ef006f7cbcc0.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20211108/c5ac61af-9479-4b2e-a4f3-5151333da5f1.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20200710/587b08e9765f4399b8304239a9b1e548.jpeg" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div>
                                        <div className='item-wrapper'>
                                            <img src="https://ds29pce2v80x4.cloudfront.net/20211227/4563912f-625a-4cf2-a6fd-bb55d9432471.png" />
                                            <p>Jaipur Living</p>
                                            <h4>PEYKAN - PEY03 18 INCH POLYESTER</h4>
                                            <h4><b>$89.00</b></h4>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {/* <br />
                            <a href='javascript:;' onClick={this.hideshowproducts} className='btn btn-primary'>Back</a> */}
                        </div>
                    </div>     
                </div>
            </div>

            <button type="button" id="btnUpgradeModel" style={{display:"none"}} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
				Launch demo modal
				</button>

				<div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
					<div className="modal-body">
						<p style={{textAlign:"center",fontSize:"18px",lineHeight:"30px",paddingTop:"25px"}}>
							Upgrade to the premium plan to use this feature.
						</p>
					</div>
					<div className="modal-footer">
						<Link to="/upgradeplan" className="common-save" style={{padding:"7px 15px"}}>
							Proceed
						</Link>
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{padding:"7px 15px",borderRadius:"50px"}}>Cancel</button>						
					</div>
					</div>
				</div>
				</div>

				{/* limit reached popup */}
				<button type="button" id="btnUpgradeModelLimit" style={{display:"none"}} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalLimit">
				Launch demo modal
				</button>

				<div className="modal fade" id="exampleModalLimit" tabIndex={-1} aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content">
					<div className="modal-body">
						<p id='ptextSubD' style={{textAlign:"center",fontSize:"18px",lineHeight:"30px",paddingTop:"25px"}}>
							Your trial resources have been exhausted. Please upgrade to a paid subscription.
						</p>
					</div>
					<div className="modal-footer">
						<Link to="/upgradeplan" className="common-save" style={{padding:"7px 15px"}}>
							Proceed
						</Link>
						<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{padding:"7px 15px",borderRadius:"50px"}}>Cancel</button>						
					</div>
					</div>
				</div>
				</div>
</>
        )
    }
};
export default Aeloadvancednew;