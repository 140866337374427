import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import Cookies from 'js-cookie'
import {UserID} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import TeamsNew from './ManageTeamsNew';

class ManageTeams extends React.Component {

  constructor(props) {
		super(props);
		this.state = { 
      tabledata:[],		
      tabledataDetails: [],
      tableNonTeamUsers: []  
	  }
	}
componentDidMount (){
      //   Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
      //   el.classList.remove('active');
      // });
      // document.getElementById('0').classList.remove("active");
      //debugger;
      //this.loadTeams();
      this.displayTeams(false);
    }
    historygoback = () => {
        window.history.back();
      };

      customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      showoverlay = () => {
        document.getElementById("overlay").style.display = "block";
        $("body").addClass("loader");
      };
      hideoverlay = () => {
        document.getElementById("overlay").style.display = "none";
        $("body").removeClass("loader");
      };

      

      loadTeams = () =>{
        var self = this;
        self.displayTeams(false);
        var self = this;

        var txtTeamName = document.getElementById('txtTeamName');
        //TeamId	TeamName	MasterId	CreatedOn
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeams?UserId="+UserID,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //team created load  all teams
                        self.setState({tabledata:response.Data});
                        console.log(response);
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
                self.hideoverlay();
            });
      }

      deleteTeam = (teamId) =>{
        var self = this;        
        var result = window.confirm("Are you sure you want to delete this team?");
        if (!result) {
            return;
        }
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteTeam?TeamId="+teamId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null && response.Data != "") {
                        //team created load  all teams
                        self.customAlertMessage('success', "Team deleted successfully");
                        self.loadTeams()
                    }
                    else {                        
                        self.customAlertMessage('error', "Failed to delete team, Please try again.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
      }
      // delete team end

      displayTeams = (isDetails) => {
          if(isDetails){
            document.getElementById('divMainTeams').style.display="none";
            document.getElementById('divTeamDetails').style.display="block";
            document.getElementById('spanBack').style.display="block";
          }
          else {
            document.getElementById('divMainTeams').style.display="block";
            document.getElementById('divTeamDetails').style.display="none";
            document.getElementById('spanBack').style.display="none";
          }
      }

      

    render(){
    return (
    <div className="main_content_iner overly_inner dashboard-page">
        <div className="container-fluid p-0 ">
          <div className="row">	  
                <div id="overlay">
                    <div id="loading">
                        <h2 id="loaderheading">Loading...</h2>
                        <img src={loader} />
                    </div>
                </div> 
          
                <div className="col-12">
                    <div className="title-card1">
                        <span className="float-start">
                            <h4>
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={this.historygoback}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                                Teams
                            </span>
                            </h4>
                        </span>
                        <span id='spanLog' className="float-end mt-20">                          
                            <Link to="/teamslog" className='common-save'>
                                Team Log
                            </Link>                          
                        </span>
                        <span id="spanBack" className="float-end mt-20">
                          <a href='javascript:;' style={{display:"none"}} tabIndex={-1} onClick={() => this.displayTeams(false)} className="common-back"><u>Back</u></a>
                        </span>
                    </div>
                </div>

                <div id="divMainTeams" className="col-12">	                                               
                            <TeamsNew></TeamsNew>                    
                            {/* <table className="common-table">
                              <thead>
                                <tr>                                  
                                  <th className="width-inc">Team Name</th>
                                  <th className="width-inc">Created On</th>
                                  <th className="width-inc"> </th>
                                </tr>
                              </thead>
                              <tbody>
                                            {this.state.tabledata.length > 0 ? this.state.tabledata.map((d,index) => (
                                                <tr key={d.TeamId} data-item={d.TeamId}>
                                  <td style={{cursor:"pointer"}}><a href='javascript:;' onClick={ () => this.showTeamDetails(d.TeamId,d.TeamName)} >{d.TeamName}</a></td>
                                  <td>{moment(d.CreatedOn).format('MMM DD YYYY h:mm A')}</td>
                                  <td> <a href='javascript:;' onClick={() => this.deleteTeam(d.TeamId)} >Delete</a> </td>
                                </tr>

                                            )) : <tr>
                                                <td colSpan={3} className="text-center">No data found</td>
                                                </tr>
                                                }
                              </tbody>							
                            </table> */}                          

                </div>

                <div id="divTeamDetails" className="col-12 rightside-bar">	
                      
                      {/* <input type="hidden" id="hfDetailTeamId"></input>
                      <div className='row'>
                          <div className="col-12">
                            <b>Team Name:</b> <label id="lblTeamName"></label>
                          </div>
                      </div>
                      
                      <div className="row">
                          <div className="col-12" style={{overflowY:"auto"}}>                        
                            <table className="common-table">
                              <thead>
                                <tr>                                  
                                  <th className="width-inc">First Name</th>
                                  <th className="width-inc">Last Name</th>
                                  <th className="width-inc">Email</th>
                                  <th className="width-inc">Added On</th>
                                  <th className="width-inc"> </th>
                                </tr>
                              </thead>
                              <tbody>
                                            {this.state.tabledataDetails.length > 0 ? this.state.tabledataDetails.map((d,index) => (
                                                <tr key={d.UserId} data-item={d.UserId}>
                                  <td>{d.FirstName}</td>
                                  <td>{d.LastName}</td>
                                  <td>{d.EmailId}</td>
                                  <td>{moment(d.AddedOn).format('MMM DD YYYY h:mm A')}</td>
                                  <td> <a href='javascript:;' onClick={() => this.deleteTeamUser(d.TeamId,d.UserId)} >Remove</a> </td>
                                </tr>

                                            )) : <tr>
                                                <td colSpan={3} className="text-center">No data found</td>
                                                </tr>
                                                }
                              </tbody>							
                            </table>
                          </div>
                        </div>    

                        <div className='row'>
                          <div className="col-12">
                              <br/>
                              <b>Add Users to Team:</b>
                          </div>
                        </div>                           
                                              
                        <div className="row">                          
                          <div className="col-12">                        
                            <table className="common-table">
                              <thead>
                                <tr>
                                  <th className="width-inc">First Name</th>
                                  <th className="width-inc">Last Name</th>
                                  <th className="width-inc">Email</th>
                                  <th className="width-inc"> </th>
                                </tr>
                              </thead>
                              <tbody>
                                            {this.state.tableNonTeamUsers.length > 0 ? this.state.tableNonTeamUsers.map((d,index) => (
                                                <tr key={d.UserId} data-item={d.UserId}>
                                  <td>{d.FirstName}</td>
                                  <td>{d.LastName}</td>
                                  <td>{d.EmailId}</td>
                                  <td> <a href='javascript:;' onClick={() => this.addUserToTeam(d.UserId)} >Add To Team</a> </td>
                                </tr>

                                            )) : <tr>
                                                <td colSpan={3} className="text-center">No data found</td>
                                                </tr>
                                                }
                              </tbody>							
                            </table>
                          </div>
                        </div>     */}

                </div>
          </div>

          

        </div>
    </div>
    )
}
};
export default ManageTeams;