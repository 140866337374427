import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft, faMicrochip, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React from 'react';
import {UserID,PaymentCustomerId} from "../User";
import loader from "../img/sample.gif";
import $, { data } from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';
import AiVideosNew from './AiVideosNew'
import * as aicss from '../css/aivideo.css'

export const getAccessToken = () => Cookies.get('userInfo');

class AIVideosData extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     AiVideos: this.getUserAvatars(),
        // }
    };

    componentDidMount(){
        // var userINew = getAccessToken();
        // var decodedUserNew = decodeURIComponent(userINew);
        // //console.log(decodedUserNew);
        // var parsedResNew = JSON.parse(decodedUserNew);
        // var uname = parsedResNew.FirstName;
        // var userId = parsedResNew.UserId;
          //  this.getUserAvatars();
     //     alert('in');
    }

    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: icon, //'success',
            title: message, // 'Signed in successfully'
        });
    };

    historygoback = () => {
        //window.history.back();
        document.getElementById('hrefBTD').click();
    };

     stopVideoPlay = () => {
        try {
            document.getElementById('videoAvatarSrc').pause();
        } catch (error) {
            
        }
    }

    render(){
        return (
            <>
            <div className="main_content_iner overly_inner dashboard-page">
                <div className="container-fluid p-0 ">
                    <div className="row">

                        {/* main c start */}
                        <div id="overlay">
                            <div id="loading">
                                <h2 id="loaderheading"></h2>
                                <img src={loader} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="title-card1">
                                <h4>
                                <span style={{ cursor: "pointer" }} onClick={this.historygoback}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    AI Videos
                                </span>
                                <Link id='hrefBTD' style={{display:"none"}} to="/dashboard"></Link>
                                </h4>
                            </div>
                            <div className='float-right' style={{textAlign:"right"}}>
                                <a href={"https://aivideos.chocolatechips.ai/?CC=1&UserId="+UserID +"&nid="+ Math.random().toString(16).slice(2)} id="hrefCreateVideo" className="headerbtn"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>&nbsp;&nbsp;Create Video</a>
                            </div>
                        </div>
                        <div className='col-12'>
                                <div className="row">
                                <AiVideosNew></AiVideosNew>                                                                           
                                </div>
                            </div>
                        </div>

                        {/* main c end */}
                    </div>
                </div>     

                {/* <!-- Modal for more voice --> */}
    <a href="javascript:;" style={{display:"none"}} className="more-voice" id="hrefShowPlay" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FontAwesomeIcon icon={faMicrochip}></FontAwesomeIcon>More Voice</a>
    <div className="modal fade voicepopup" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Video</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>this.stopVideoPlay()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className="col-md-12">
                    {/* <%--<h2 class="h2header">Video Avatar</h2>--%> */}
                    <video id="videoAvatarSrc" width="100%" controls className="avatarvideo">
                        Your browser does not support the video tag.
                        </video>
                </div>
            </div>
        </div>
        </div>
    </div>        
        
            </>
        )
    }
};
export default AIVideosData;    