import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import logo from "./img/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCrosshairs, faRemove, faRotate, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import "./css/login.css";
import facebookicon from "./img/social/1.png";
import instaicon from "./img/social/2.png";
import twittericon from "./img/social/3.png";
import tiktokicon from "./img/social/4.png";
import pinicon from "./img/social/5.png";
import mailicon from "./img/social/6.png";
import socialiconregistergif from "./img/register.gif"

import {passwordregex6digits,passwordregexLowercase,passwordregexUppercase,passwordregexNumber,passwordRegexSpecial,passwordRegexAll} from "./passwordstrengthutil" 

export const getAccessToken = () => Cookies.get('userInfo')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

class Register extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showpassword:true
        }
    }

    showHidePassword = () => {
        var password = document.getElementById('PwdEmail');
        var toggler = document.getElementById('toggler');  
        if (password.type == 'password') {
            this.setState(({showpassword: false}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:false});
            password.setAttribute('type', 'text');
      
        } else {
            this.setState(({showpassword: true}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:true});
            password.setAttribute('type', 'password');
      
        }
      
    }

componentDidMount(){
   this.registerclick();
   document.getElementById('toggler').addEventListener('click', this.showHidePassword);
}
    registerclick = () => {
        document.addEventListener("click", function(){
            document.getElementsByClassName('tooltip')[0].style.display = "none";
        });
    }
    callonSuccess = (res) =>{
        console.log(res);
        this.updateUserDataToServer(res.credential);
    }

    callonError=(error) =>{
        console.log(error);
    }
    
    validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    updateUserDataToServer = (resData) =>{
    
        if (resData != "") {
            this.showoverlay();
            var tokenId ="";
            var accountType="2";
            var thirdToken = "";
            var uname = "";
            var pass = "";
            var PwdFN = ""
            var PwdLN = "";
            var PwdMobile = ""
            var PwdCompanyName = "";

            if (document.getElementById("rbtnIndividual").checked) {
            }
            else if (document.getElementById("rbtnCompany").checked) {
                accountType = "1";
            }

            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CreateUserOAuth?accountType="+accountType+"&CompanyName=" + PwdCompanyName 
                    + "&CompId=0"+"&JwtToken="+resData,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
    
            axios(config)
                .then((resp) => {
                    var response = (resp.data);
                    if(response !=null && response.Status == "200"){
                        var responseData = response.Data;
                        Cookies.set('access_token', response.Data.UserId);
                        const serializedState = JSON.stringify(responseData);
                        //var a = localStorage.setItem('userInfo', serializedState);
                        Cookies.set('userInfo', serializedState); 
                        if(response.Data.PaymentCustomerId != "")   {
                            Cookies.set('takepayment', false);
                        }
                        else{
                            Cookies.set('takepayment', true);
                        }
                        window.location.href = window.location.origin;
                    }
                    else
                    {
                        this.customAlertMessage('error', 'Something went wrong, Try Again.');
                    }
                    this.hideoverlay();
                })
                .catch((err) => {
                    this.hideoverlay();
                     console.log(err);
                     this.customAlertMessage('error', 'Something went wrong, Try Again.');
                 });
            }
    }    

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }
    myFunc = () => {
        //PwdCompanyName  divCompany rbtnIndividual rbtnCompany
        if (document.getElementById("rbtnIndividual").checked) {
            document.getElementById('lblCN').style.display = "none";
            document.getElementById('PwdCompanyName').style.display = "none";
        }
        else if (document.getElementById("rbtnCompany").checked) {
            document.getElementById('lblCN').style.display = "block";
            document.getElementById('PwdCompanyName').style.display = "block";
        }
    }
    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    verifyOtpData =() =>{
        var txtOtp = document.getElementById('txtOtp').value;

        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        //console.log(decodedUser); VerifyEmailVerificationOtp(string EmailId, string UserOtp)
        var parsedRes = JSON.parse(decodedUser);
        var uname = parsedRes.FirstName;
        var userId = parsedRes.UserId;
        var emailId = parsedRes.EmailId

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/VerifyEmailVerificationOtp?EmailId="+emailId+"&UserOtp=" + txtOtp,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then((resp) => {
                var response = (resp.data);
                if(response !=null && response.Status == "200"){
                    
                    if(response.Data == "true")   {
                        // on success
                        document.getElementById('divPlanOfUse').style.display = "block";
                        document.getElementById('divVerifyCode').style.display = "none";
                        document.getElementById('divMainLeft').style.display = "none";
                        document.getElementById('divMainRight').style.display = "none";
                    }
                    else{
                        this.customAlertMessage('error', 'Invalid OTP.');
                    }
                }
                else
                {
                    this.customAlertMessage('error', 'Something went wrong, Try Again.');
                }
                this.hideoverlay();
            })
            .catch((err) => {
                this.hideoverlay();
                 console.log(err);
                 this.customAlertMessage('error', 'Something went wrong, Try Again.');
            });        
                        //window.location.href = window.location.origin;
    }

    toggleCSSSel(ele){
        document.getElementById(ele).classList.toggle('active');
    }

    removeCurrentSession = () =>{
        Cookies.remove("userInfo");
        Cookies.remove("refresh_token");
        window.location.reload();
    }

    addCompanyInfo = () => {

    }

    beforeAccountCreate = () => {

    }

    finalSubmit = () =>{
        window.location.href = window.location.origin
    }

    createAccount = () => {
        var uname = document.getElementById('Email').value;
        var pass = document.getElementById('PwdEmail').value;
        var PwdFN = document.getElementById('PwdFN').value;
        var PwdLN = document.getElementById('PwdLN').value;
        var PwdMobile = "";//document.getElementById('PwdMobile').value;
        var PwdCompanyName = document.getElementById('PwdCompanyName').value;
        if (uname.toLowerCase().trim() == "" || pass.toLowerCase().trim() == "") {
            this.customAlertMessage("error", "Please enter email and password.");
            return;
        }

        if (uname.toLowerCase().trim() != ""){
            var isValidEmail = this.validateEmail(uname);
            if(!isValidEmail){
                this.customAlertMessage("error", "Please enter valid email id.");
            }
        }
        
        if (PwdFN == "" || PwdLN == "") {
            this.customAlertMessage("error", "Please enter firstname and lastname.");
            return;
        }
        var accountType = "1";
        var PwdCompanyName = document.getElementById('PwdCompanyName').value;
        if (document.getElementById("rbtnIndividual").checked) {
            //document.getElementById('divCompany').style.display = "none";
            accountType = "2";
            PwdCompanyName = "";
        }
        else if (document.getElementById("rbtnCompany").checked) {
            //document.getElementById('divCompany').style.display = "block";
            accountType = "1";
            if (PwdCompanyName.trim() == "") {
                this.customAlertMessage("error", "Please enter company name.");
                return;
            }
        }

        if(pass !=""){
            
        }
        var thisPassword = document.getElementById('PwdEmail').value;
        if(!this.ValidatePassword(thisPassword)){            
            this.customAlertMessage("error", "Please choose a strong password.");
            document.getElementsByClassName('tooltip')[0].style.display = "block";
            return;
        }
        var self = this;
        this.showoverlay();



        //string firstName,string lastName, string EmailId, string Passw,string phoneNumber string accountType,string CompanyName,string CompId
        
        var data = JSON.stringify({
            "firstName": PwdFN,
            "lastName": PwdLN,
            "EmailId": uname,
            "Passw": pass,
            "phoneNumber": PwdMobile
        });

        pass = escape(pass);

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CreateUser?firstName=" + PwdFN + "&lastName=" + PwdLN + "&EmailId=" + uname + "&Passw="
                + pass + "&phoneNumber=" + PwdMobile + "&accountType=" + accountType + "&CompanyName=" + PwdCompanyName + "&CompId=0",
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //Cookies.set('access_token', response.Data.userInfo.UserId, { expires: inOneHour })
                        var responseData = response.Data;
                        Cookies.set('refresh_token', response.Data.UserId);
                        const serializedState = JSON.stringify(responseData);
                        //var a = localStorage.setItem('userInfo', serializedState);
                        Cookies.set('userInfo', serializedState);
                        Cookies.set('takepayment', true);
                        //console.log("A:", a);  
                        //self.customAlertMessage('info', "Account created Successfully.");
                        if(responseData.EmailVerifySent == "true"){
                            document.getElementById('divPlanOfUse').style.display = "none";
                            document.getElementById('divVerifyCode').style.display = "block";
                            document.getElementById('divMainLeft').style.display = "none";
                            document.getElementById('divMainRight').style.display = "none";
                            //window.location.href = window.location.origin;
                            document.getElementById('emailToVerify').innerHTML = uname;
                        }
                        else
                            self.customAlertMessage('error', "Something went wrong in email verification.");
                    }
                    else {
                        self.customAlertMessage('error', "User not present.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); 
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }
    checkPassword = (e) => {
        var thisPassword = e.target.value
        if(!this.ValidatePassword(thisPassword)){
            document.getElementsByClassName('tooltip')[0].style.display = "block";
            //fadeIn(document.getElementsByClassName('tooltip')[0],500);            
            // $(".tooltip").fadeIn('fast');
            return;
        }
    }
    ValidatePassword = (thisPassword) => {
        var checked = true;
        //var thisPassword = e.target.value
        if (passwordregex6digits.test(thisPassword)) {
            document.getElementsByClassName("passfailSix")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailSixchk")[0].style.display = "block";
            // $(".passfailSix").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
        } else {
            document.getElementsByClassName("passfailSix")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailSixchk")[0].style.display = "none";
            // $(".passfailSix").html('<span class="fail">Fail</span>');
            checked = false;
        }
    
        if (passwordregexLowercase.test(thisPassword)) {
            document.getElementsByClassName("passfailLower")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailLowerchk")[0].style.display = "block";
            // $(".passfailLower").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
        } else {
            document.getElementsByClassName("passfailLower")[0].innerHTML = '<span class="fail">Fail</span>';
            //document.getElementsByClassName("passfailLowerchk")[0].style.display = "none";
            // $(".passfailLower").html('<span class="fail">Fail</span>');
            // $(".checkmark").hide();
            checked = false;
        }
    
        if (passwordregexUppercase.test(thisPassword)) {
            document.getElementsByClassName("passfailUpper")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailUpperchk")[0].style.display = "block";
            // $(".passfailUpper").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
        } else {
            document.getElementsByClassName("passfailUpper")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailUpperchk")[0].style.display = "none";
            // $(".passfailUpper").html('<span class="fail">Fail</span>');
            // $(".checkmark").hide();
            checked = false;
        }
    
        if (passwordregexNumber.test(thisPassword)) {
            document.getElementsByClassName("passfailNum")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailNumchk")[0].style.display = "block";
            // $(".passfailNum").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
        } else {
            document.getElementsByClassName("passfailNum")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailNumchk")[0].style.display = "none";
            // $(".passfailNum").html('<span class="fail">Fail</span>');
            // $(".checkmark").hide();
            checked = false;
        }
    
        if (passwordRegexSpecial.test(thisPassword)) {
            document.getElementsByClassName("passfailSpecial")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailSpecialchk")[0].style.display = "block";
            // $(".passfailSpecial").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
        } else {
            document.getElementsByClassName("passfailSpecial")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailSpecialchk")[0].style.display = "none";
            // $(".passfailSpecial").html('<span class="fail">Fail</span>');
            // $(".checkmark").hide();
            checked = false;
        }
        if (passwordRegexAll.test(thisPassword)) {
            document.getElementsByClassName("passfailAll")[0].innerHTML = '<span class="pass">Pass</span>';
            //document.getElementsByClassName("passfailAllchk")[0].style.display = "block";            
            // $(".passfailAll").html('<span class="pass">Pass</span>');
            // $(".checkmark").show();
    
        } else {
            document.getElementsByClassName("passfailAll")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailAllchk")[0].style.display = "none";
            // $(".passfailAll").html('<span class="fail">Fail</span>');
            // $(".checkmark").hide();
            checked = false;
        }
        return checked;
    }

    render() {
        return (
            <div className="container-fluid">
                <div className='row'>

                    <div id="divMainLeft" className='col-4'>
                        <div className='d-flex justify-content-center align-items-center app-height loginstyle'>
                        <div className='show-login'>
                            <div id="overlay">
                                <div id="loading">
                                    <h2 id="loaderheading">Loading...</h2>
                                    <img src={loader} />
                                </div>
                            </div>  
                            <img src={logo} className="login-logo" />
                            <h2 style={{marginBottom:"10px"}}>Create your account</h2>
                            <h5 style={{marginBottom:"20px"}}>You'll be up & running in 2 minutes</h5>
                            <GoogleOAuthProvider clientId="541732705369-opjt766r4um81jm4n686igo4ano89lei.apps.googleusercontent.com"> 
                                <GoogleLogin
                                        onSuccess= {this.callonSuccess} 
                                        onError={this.callonError} />
                            </GoogleOAuthProvider>
                            <h4>Or sign up with your email</h4>
                            <div className='row'>
                                <div className='col-6'>
                                    <label>First Name</label>                    
                                    <input id="PwdFN" type="text" className='common-input' required/>
                                </div>
                                <div className='col-6'>
                                    <label>Last Name</label>                    
                                    <input  id="PwdLN" type="text" className='common-input' required/>
                                </div>
                            </div>
                            <label style={{marginTop:"15px"}}>Email Address</label>                    
                            <input id="Email" type="text" className='common-input' required/>
                            <div className='pwordContainer'>
                            <label style={{marginTop:"15px"}}>Password</label>
                            <div className='pwdcontainer'>
                                <input id="PwdEmail" type="password" className='common-input' onChange={this.checkPassword} required/>
                                { (this.state.showpassword) ?
                                    <FontAwesomeIcon icon={faEye} id='toggler' className='registereyeicon'/>
                                :
                                    <FontAwesomeIcon icon={faEyeSlash} id='toggler' className='registereyeicon'/>
                                }
                                <div className="tooltip">
                                            <div>
                                                <b>6 Characters:</b> <span className="passfail passfailSix"><span className="fail">Fail</span></span>
                                                <span className="checkmark passfailSixchk">&#x2714;</span>

                                            </div>
                                            <div>
                                                <b>1 LowerCase:</b> <span className="passfail passfailLower"><span className="fail">Fail</span></span>
                                                <span className="checkmark passfailLowerchk">&#x2714;</span>

                                            </div>
                                            <div>
                                                <b>1 Uppercase:</b> <span className="passfail passfailUpper"><span className="fail">Fail</span></span>
                                                <span className="checkmark passfailUpperchk">&#x2714;</span>
                                            </div>
                                            <div>
                                                <b>1 Number:</b> <span className="passfail passfailNum"><span className="fail">Fail</span></span>
                                                <span className="checkmark passfailNumchk">&#x2714;</span>
                                            </div>
                                            <div>
                                                <b>1 Special Character:</b> <span className="passfail passfailSpecial"><span className="fail">Fail</span></span>
                                                <span className="checkmark passfailSpecialchk">&#x2714;</span>
                                            </div>
                                            <hr style={{borderBottom:" 1px solid #eee",margin:"5px 0px"}} />
                                            <div className="alltogCont">
                                                <b>Strong Password:</b> <span className="passfail passfailAll"><span className="fail">Fail </span></span><span className="checkmark passfailAllchk">&#x2714;</span>
                                            </div>
                                </div>  
                            </div>     
                            </div>
                            
                            <div style={{display:"none"}}>
                            <label style={{marginTop:"15px"}}>Account Type:&nbsp;&nbsp;&nbsp;</label>
                            <input type="radio" id="rbtnIndividual" value="0" onChange={this.myFunc} defaultChecked name="accountType" /> Individual  &nbsp;
                            <input type="radio" id="rbtnCompany" value="1" onChange={this.myFunc} name="accountType" /> Organisation
                            </div>
                            <label id='lblCN' style={{marginTop:"10px",display:"none"}}>Company Name</label>                    
                            <input id='PwdCompanyName' style={{display:"none"}} type="text" className='common-input' required/>
                            <a href='javascript:;' tabIndex={-1} onClick={this.createAccount} className='common-btnnew'> Continue <FontAwesomeIcon icon={faArrowRightLong} style={{marginLeft:"5px"}}/></a>
                            <h5>Already have an account?&nbsp;
                                <Link to="/Login">
                                    Login
                                </Link>
                            </h5>     
                                            
                        </div>
                        </div>
                    </div>
                
                    <div id="divMainRight" className='col-8' style={{padding:"0"}}>
                        <div className='d-flex justify-content-center align-items-center app-height registerbackground'>
                            <h2> Create Social Media Posts, Email Campaign Contents using <br/>AI with Lightening Speed. </h2>
                            {/* <h2>Syndicate Content, Everywhere.</h2> */}
                            <img src={socialiconregistergif} className="socialiconregister" />
                            <span>
                                <img src={facebookicon} className="socialiconset" />
                                <img src={instaicon} className="socialiconset" />
                                <img src={twittericon} className="socialiconset" />
                                <img src={tiktokicon} className="socialiconset" />
                                <img src={pinicon} className="socialiconset" />
                                <img src={mailicon} className="socialiconset" />
                            </span>
                        </div>
                    </div>

                    <div id='divVerifyCode' style={{display:"none"}} className='col-12'>
                        <div className='d-flex justify-content-center align-items-center app-height loginstyle'>
                            <div className='text-start w-24'>
                                <div className='show-password'>
                                    <img src={logo} className="login-logo" />
                                    <h2>Verify your email</h2>   
                                    <h5>Please check your inbox for a verification code to confirm your identity.</h5>         
                                    <div className='password-box'>
                                        <h5>Code sent to <span id="emailToVerify"></span></h5>
                                        <input id="txtOtp" type="text" className="common-input" />
                                        <a href='javascript:;' tabIndex={-1} onClick={this.verifyOtpData} className='verfify-btn'>Verify <FontAwesomeIcon icon={faArrowRightLong} style={{marginLeft:"5px"}}/></a>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 text-center new-anchor'>
                                            {/* <a href="#"><FontAwesomeIcon icon={faRotate} />Send a new code</a> */}
                                            <a href='/register' tabIndex={-1} onClick={this.removeCurrentSession}><FontAwesomeIcon icon={faRemove} />Try with another email</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* verify code end */}                    
                    <div id='divPlanOfUse' className='col-12' style={{display:"none"}}>
                        <div className='d-flex justify-content-center align-items-center app-height headerstyle'>
                            <h2>How do you plan to use chocolatechips.ai?</h2>
                            <div className='w-40 selectbusiness text-center'>                                
                                <a id="sel1" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel1')}>Blog Posts</a>
                                <a id="sel2" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel2')}>Social Posts</a>
                                <a id="sel3" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel3')}>Email</a>
                                <a id="sel4" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel4')}>Website Content</a>
                                <a id="sel5" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel5')}>Ads</a>
                                <a id="sel6" href="javascript:;" onClick={this.toggleCSSSel.bind(this,'sel6')}>Others</a>
                                <a href='javascript:;' onClick={this.finalSubmit} className='verfify-btn'> Next <FontAwesomeIcon icon={faArrowRightLong} style={{marginLeft:"5px"}}/></a>
                            </div>
                        </div>
                    </div>
                    {/* Plan of use end */}
                    <div className="col-12 text-center">
                        <small>Copyright © planetRE. All Rights Reserved.</small>
                    </div>
                </div> 
                {/* row end */}

            </div>
        )
    }
};

export default Register;
