import Cookies from 'js-cookie'
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import logo from "./img/logo.png";
import {passwordregex6digits,passwordregexLowercase,passwordregexUppercase,passwordregexNumber,passwordRegexSpecial,passwordRegexAll} from "./passwordstrengthutil" 
import queryString from "query-string";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faCrosshairs, faRemove, faRotate, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class ResetPassword extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showpassword:true
        }
    }

    

    showHidePassword = () => {
        var password = document.getElementById('txtNewPass');
        var toggler = document.getElementById('toggler');  
        if (password.type == 'password') {
            this.setState(({showpassword: false}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:false});
            password.setAttribute('type', 'text');
      
        } else {
            this.setState(({showpassword: true}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:true});
            password.setAttribute('type', 'password');
      
        }
      
    }

    componentDidMount(){
        this.registerclick();
        document.getElementById('toggler').addEventListener('click', this.showHidePassword);
        const query = queryString.parse(window.location.search);
        const token = query.userId;
        console.log('userid: '+ token);
        if (token != undefined && token != "") {        
            document.getElementById('hfUserId').value = token;
        } else {
            this.customAlertMessage("failure","User not found.");
        }
    }

    registerclick = () => {
        document.addEventListener("click", function(){
            document.getElementsByClassName('tooltip')[0].style.display = "none";
        });
    }

    

    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }
    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }

    resetUserPassword = () => {
        //txtOtp txtNewPass txtOldPass
        var txtNewPass = document.getElementById('txtNewPass').value;
        if (txtNewPass.trim() == "") {
            this.customAlertMessage("error", "Please enter new password.");
            return;
        }
        var txtOldPass = document.getElementById('txtOldPass').value;
        if (txtOldPass.trim() == "") {
            this.customAlertMessage("error", "Please enter confirm password.");
            return;
        }
        if (txtOldPass.trim() != txtNewPass.trim()) {
            this.customAlertMessage("error", "New password and confirm password should match.");
            return;
        }
        var thisPassword = document.getElementById('txtNewPass').value;
        if(!this.ValidatePassword(thisPassword)){            
            this.customAlertMessage("error", "Please choose a strong password.");
            document.getElementsByClassName('tooltip')[0].style.display = "block";
            return;
        }
        var emailId = document.getElementById("hfUserEmail").value;
        var userId = document.getElementById("hfUserId").value;
        var self = this;
        this.showoverlay();

        var axios = require('axios');

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/ResetPassword?NewPassword=" + txtNewPass +"&UserId="+userId,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        //divResetSuccess txtOtp txtNewPass txtOldPass divResetSuccess divOTPReset confinedemail
        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data == true) {
                        //enable otp enter textbox and ask for new password reset
                        document.getElementById("divOTPReset").style.display = "none";
                        document.getElementById("divResetSuccess").style.display = "block";   
                        document.getElementById("divFooter").style.display = "block";   
                        document.getElementById('subHeading').innerHTML="";                   
                    }
                    else {
                        self.customAlertMessage('error', resp.Message);
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    } // rest pass end

    checkPassword = (e) => {
        var thisPassword = e.target.value
        if(!this.ValidatePassword(thisPassword)){
            document.getElementsByClassName('tooltip')[0].style.display = "block";
            return;
        }
    }
    ValidatePassword = (thisPassword) => {
        var checked = true;
        if (passwordregex6digits.test(thisPassword)) {
            document.getElementsByClassName("passfailSix")[0].innerHTML = '<span class="pass">Pass</span>';
        } else {
            document.getElementsByClassName("passfailSix")[0].innerHTML = '<span class="fail">Fail</span>';
            document.getElementsByClassName("passfailSixchk")[0].style.display = "none";
            checked = false;
        }
    
        if (passwordregexLowercase.test(thisPassword)) {
            document.getElementsByClassName("passfailLower")[0].innerHTML = '<span class="pass">Pass</span>';
        } else {
            document.getElementsByClassName("passfailLower")[0].innerHTML = '<span class="fail">Fail</span>';
            checked = false;
        }
    
        if (passwordregexUppercase.test(thisPassword)) {
            document.getElementsByClassName("passfailUpper")[0].innerHTML = '<span class="pass">Pass</span>';
        } else {
            document.getElementsByClassName("passfailUpper")[0].innerHTML = '<span class="fail">Fail</span>';
            checked = false;
        }
    
        if (passwordregexNumber.test(thisPassword)) {
            document.getElementsByClassName("passfailNum")[0].innerHTML = '<span class="pass">Pass</span>';
        } else {
            document.getElementsByClassName("passfailNum")[0].innerHTML = '<span class="fail">Fail</span>';
            checked = false;
        }
    
        if (passwordRegexSpecial.test(thisPassword)) {
            document.getElementsByClassName("passfailSpecial")[0].innerHTML = '<span class="pass">Pass</span>';
        } else {
            document.getElementsByClassName("passfailSpecial")[0].innerHTML = '<span class="fail">Fail</span>';
            checked = false;
        }
        if (passwordRegexAll.test(thisPassword)) {
            document.getElementsByClassName("passfailAll")[0].innerHTML = '<span class="pass">Pass</span>';
    
        } else {
            document.getElementsByClassName("passfailAll")[0].innerHTML = '<span class="fail">Fail</span>';
            checked = false;
        }
        return checked;
    }

    render() {
        return (
            <div className="container-fluid mt-5 pt-4">
                <div id="overlay">
                    <div id="loading">
                        <h2 id="loaderheading">Loading...</h2>
                        <img src={loader} />
                    </div>
                </div>  
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {/* <img src="https://www.planetre.net/Image/Tlogo.jpg" style="height:40px;" /> */}
                        <a className="large_logo"><img src={logo} style={{maxWidth:"240px",marginBottom:"10px",marginTop:"20px"}}/></a>
                        <h3 id="mainHeading" style={{ fontWeight: "400", fontSize: "23.5px", marginTop: "15px" }}>Reset Password</h3>
                        <h4 id="subHeading" style={{ fontWeight: "400", fontSize: "20.5px", marginTop: "15px" }}>Please create the new password to sign in to your account</h4>
                    </div>
                </div>
                <div className="confined loginstyle">
                    <form>                        
                        <div className="row" id="divOTPReset" style={{ display: "block" }}>
                            <input type="hidden" id="hfUserId" value=""/>
                            <input type="hidden" id="hfUserEmail" value=""/>
                            <div className="col-sm-12 pwordContainer">
                                <label>New Password</label>
                                <div className='pwdcontainer'>
                                <input id="txtNewPass" type="password" className="common-input" name="" onChange={this.checkPassword} />
                                { (this.state.showpassword) ?
                                    <FontAwesomeIcon icon={faEye} id='toggler'/>
                                :
                                    <FontAwesomeIcon icon={faEyeSlash} id='toggler'/>
                                }
                                <span id="txtNewPassVailidation" style={{ color: "red", fontWeight: "500" }}></span>
                                <div className="tooltip">
                                        <div>
                                            <b>6 Characters:</b> <span className="passfail passfailSix"><span className="fail">Fail</span></span>
                                            <span className="checkmark passfailSixchk">&#x2714;</span>

                                        </div>
                                        <div>
                                            <b>1 LowerCase:</b> <span className="passfail passfailLower"><span className="fail">Fail</span></span>
                                            <span className="checkmark passfailLowerchk">&#x2714;</span>

                                        </div>
                                        <div>
                                            <b>1 Uppercase:</b> <span className="passfail passfailUpper"><span className="fail">Fail</span></span>
                                            <span className="checkmark passfailUpperchk">&#x2714;</span>
                                        </div>
                                        <div>
                                            <b>1 Number:</b> <span className="passfail passfailNum"><span className="fail">Fail</span></span>
                                            <span className="checkmark passfailNumchk">&#x2714;</span>
                                        </div>
                                        <div>
                                            <b>1 Special Character:</b> <span className="passfail passfailSpecial"><span className="fail">Fail</span></span>
                                            <span className="checkmark passfailSpecialchk">&#x2714;</span>
                                        </div>
                                        <hr style={{borderBottom:" 1px solid #eee",margin:"5px 0px"}} />
                                        <div className="alltogCont">
                                            <b>Strong Password:</b> <span className="passfail passfailAll"><span className="fail">Fail </span></span><span className="checkmark passfailAllchk">&#x2714;</span>
                                        </div>
                                    </div>  
                                </div>   
                            </div>
                            <div className="col-sm-12">
                                <label style={{marginTop:"15px"}}>Confirm Password</label>
                                <input id="txtOldPass" type="password" className="common-input" name="" />
                                <span id="txtOldPassVailidation" style={{ color: "red", fontWeight: "500" }}></span>
                            </div>
                            <div className="col-sm-12 mt-3 text-center">
                                <a href='javascript:;' tabIndex={-1} onClick={this.resetUserPassword} className="verfify-btn">Reset</a>
                            </div> <br />    
                        </div>

                        <div className="row" id="divResetSuccess" style={{ display: "none" }}>
                            <div className="col-sm-12">
                                <label>Password set successfully. Click on below Login link and proceed</label>                                
                            </div>
                        </div>

                        <div className="row" id="divFooter" style={{display: "none"}}>
                            <div className="col-sm-12 text-center mt-2 anchor-blue">
                                <Link to="/Login">
                                    Go to Login
                                </Link>
                            </div>
                            <br />
                            <div className="col-sm-12 text-center mt-2">

                            </div> <br />
                            <div className="col-sm-12 text-center mt-5">
                            <small>Copyright © planetRE. All Rights Reserved.</small>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        )
    }

};

export default ResetPassword;