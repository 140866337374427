import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faMicrophone,faInfo,faInfoCircle, faRotateRight, faShield, faCloudArrowUp, faCircleDown, faFile, faTimes, faCopy, faCircleXmark, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import AudioToText from "./AudioToText";
import { faFacebookF,faFacebook} from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";
import React from 'react';
import $ from 'jquery';
// import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4,NIL as NIL_UUID } from 'uuid';
import {duplicateresultcontent,duplicateresultparacontenttemplate} from "../email/templateBase"
import copy from 'copy-to-clipboard';
// import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import {UserID,Name,Companyid,Role,Rolename, ProfileImg, MasterId, SSOUser} from "../User";
import addsvg from "../img/add.svg";
import deletesvg from "../img/delete.svg";
import Cookies from 'js-cookie';

const headersamplewith_desc_tone_example = `Generate an advertisement heading for Company named [PHcompanyanddesc] in a [PHtone] tone.
Create advertisement heading in following formats:\n[PHsamples]`;
const headersample = `Generate an advertisement heading for Company named [PHcompanyanddesc] in a [PHtone] tone.
Create advertisement heading in following formats:\n[PHsamples]`;

export const getAccessToken = () => Cookies.get('userInfo');

class Facebook extends React.Component {
    constructor(props) {
		super(props);
		this.state = { 
		  keyworddisabled: false,
		  tonedisabled:false,
		  copySuccess: '',
		  wordcount: 0,
		  Tonecount:0,
          Primarytextcount:0,
		  generatedcontent: [],
		  emailguid: NIL_UUID,
		  emailhtml: "",
		  defaultcontenterased: false,
          exampleinputcount : [{id:1,text:""}],
          copyscapeavailable:false,
          zapurl:'',
          plagiarismmodalbody:''
	  }
	  }
	  componentDidMount(){
		this.setState({emailguid: uuidv4()});
		this.jquerycode();
        this.checkcopyscape();
        this.checkzapier();
        document.addEventListener('copy', function(e) {
            const text_only = document.getSelection().toString();
            const clipdata = e.clipboardData || window.clipboardData;  
            clipdata.setData('text/plain', text_only);  
            e.preventDefault();
          });
	  }
// const Emails = () => {
    checkcopyscape = () =>{
        const axios = require('axios');
        var self = this;
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
        };
      
        axios.request(config)
        .then((response) => {
          if (response.data != null && response.data.Data !="") {
            self.state.copyscapeavailable = response.data.Data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
jquerycode = () =>{
	var self = this;
	Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('4').classList.add("active");
// 	setTimeout(function(){ 
// 		var emailhtml = $('iframe.stripo-preview-frame').contentWindow.document.body.innerHTML;
// 		if(emailhtml != self.state.emailhtml){

// 		}
// }, 6000);
}
	setwordcount = () => {
        var text = document.getElementById('keywordinput').value;
        if(text.trim() == "")
        {
            this.setState({wordcount: 0});
        }
        else{
            const words = text.trim().split(',');
            if(words.length > 50)
            {   
				this.customAlertMessage("warning","Keywords limit exceeded.");
                var trimmedwords = text.trim().split(',').slice(0, 3).join(',');
                document.getElementById('keywordinput').value = trimmedwords;
                this.setState({wordcount: 3});
            }
            else{
                this.setState({wordcount: words.length});
            }           
        }        
    }

    togglekeywords() {
      this.setState({keyworddisabled: !this.state.keyworddisabled});
    }

	toggletone(){
		this.setState({tonedisabled: !this.state.tonedisabled});
	}

	updatetonecount = (e) => {
		this.setState({Tonecount:e.currentTarget.value.length});
	}
    updateprimarytextcount = (e) => {
		this.setState({Primarytextcount:e.currentTarget.value.length});
	}
	toggletemplatediv = () => {
		$('#templatesavediv').toggle();
		$('#btnsavetemplate').toggle();
	if ($('#templatetogglelink').text() == "Save As Template"){
		$('#templatetogglelink').text("Hide template save");

	}       
    else
	{
		$('#templatetogglelink').text("Save As Template");
	}
       
	}
	customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
	geteleValue = (objectid) => {
		var text = "";
		if(document.getElementById(objectid).value != "-1"){
		 text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
		}
		return text;
	 }
	 gettexteleValue = (objectid,prefixtext) => {
		 var text = "";
		 if(document.getElementById(objectid).value.trim() != ""){
		  text = prefixtext +": "+ document.getElementById(objectid).value + " , ";
		 }
		 return text;
	  }

      gettexteleValueexampleold = (objectclass,prefixtext) => {
        var text = "";
        var elems = document.getElementsByClassName(objectclass);
        for (var i = 0; i < elems.length; i++) {
            var elem = elems[i];
            var newline = "\n";
            if (i == 0) {
                newline = " ";
            }
            text += newline + prefixtext +": "+ elem.value.trim() + ".";
        }
        return text;
     }
     gettexteleValueexample = (objectclass,prefixtext) => {
        var text = "";
        var elems = document.getElementsByClassName(objectclass);
        for (var i = 0; i < elems.length; i++) {
            var elem = elems[i];
            var newline = "\n";
            if (i == 0) {
                text += " "+ prefixtext +": ";
            }
             //text += newline + (i+1) + ". "+ elem.value.trim() + ".";
            text += newline + elem.value.trim() + ".";
        }
        return text;
     }
	
     generateaicontentprimarytext = () =>{
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter Description/Brief.");
			document.getElementById('textareaoutput').focus();
            return;
		}
        
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice'," Tone");
        var company = this.gettexteleValue('companyname',"Company");
        var prompttext = company + prompt + toneofvoice + " Create a facebook post."; //+ " Generate short advertisement text for facebook post.";
        
        const axios = require('axios');
        const FormData = require('form-data');
        var tokens = 200;
        let data = new FormData();
        data.append('prompt', prompttext);
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);
        data.append('token', tokens);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/posttextmessage',       
        data : data
        };

        axios.request(config)
        .then((resp) => {
            // console.log(JSON.stringify(resp.Data));
            // var response = resp.Data;
            if (resp != null && resp.data != "") {
                var resultcount = document.getElementById("facebookchatgptcontent").getElementsByClassName('slider').length;
                var jsr = {};
                jsr.id = resultcount +1;
                jsr.text = resp.data.Data;
                self.state.generatedcontent.push(jsr);
                var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedcontent: joined });
            }
            self.hideoverlay();
        })
        .catch((error) => {
            console.log(error);
            self.hideoverlay();
            self.customAlertMessage('error','Something went wrong, please try again.')
        });

      }

      generateaicontentheadline = () =>{
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter Description/Brief.");
			document.getElementById('textareaoutput').focus();
            return;
		}
        
        var companyname = document.getElementById('companyname').value;
		if(companyname.trim() == ""){
			this.customAlertMessage("error","Please enter Name/Team Name.");
			document.getElementById('companyname').focus();
            return;
		}
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice'," Tone");
		 var company = this.gettexteleValue('companyname',"Company");
         var fbexample = this.gettexteleValueexample('exampleinputs','Sample headlines');
        //  var prompttext = company + prompt + toneofvoice + fbexample + " Generate advertisement title";
        //var prompttext = "Generate advertisement headline for " + company + prompt + toneofvoice + fbexample;
        
        var compnayname = document.getElementById('companyname').value;
        var tone = document.getElementById('toneofvoice').value;
        var prompttext = this.generatepromptfromtemplate(compnayname,prompt,tone);
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if(!format.test(prompttext.slice(-1)))
        {
            prompttext += ".";
        }
        
        const axios = require('axios');
        const FormData = require('form-data');
        var tokens = 200;
        let data = new FormData();
        data.append('prompt', prompttext);
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);
        data.append('token', tokens);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/posttextmessage',       
        data : data
        };

        axios.request(config)
        .then((resp) => {
            // console.log(JSON.stringify(resp.Data));
            // var response = resp.Data;
            if (resp != null && resp.data != "") {
                var resultcount = document.getElementById("facebookchatgptcontent").getElementsByClassName('slider').length;
                var jsr = {};
                jsr.id = resultcount +1;
                jsr.text = resp.data.Data;
                self.state.generatedcontent.push(jsr);
                var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedcontent: joined });
            }
            self.hideoverlay();
        })
        .catch((error) => {
            console.log(error);
            self.hideoverlay();
            self.customAlertMessage('error','Something went wrong, please try again.')
        });

      }

    generateaicontentprimarytextold = () => {
		
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter Description/Brief.");
			document.getElementById('textareaoutput').focus();
            return;
		}        
        
        // var companynameel = document.getElementById('companyname').value;
		// if(companynameel.trim() == ""){
		// 	this.customAlertMessage("error","Please enter Name/Team Name.");
		// 	document.getElementById('companyname').focus();
        //     return;
		// }
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice'," Tone");
		 var company = this.gettexteleValue('companyname',"Company");
         var prompttext = company + prompt + toneofvoice + " Generate advertisement text";

		var axios = require('axios');
var data = JSON.stringify({
//   "model": "text-davinci-003",
  "prompt": prompttext,
  "temperature": 1,
  "max_tokens": 300
});

var config = {
  method: 'post',
  url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
  headers: { 
    'Content-Type': 'application/json', 
    "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
  },
  data : data
};

axios(config)
.then(function (resp) {
  console.log(JSON.stringify(resp.data));
  var response = resp.data;
  if (response.choices != null && response.choices.length > 0) {
    var resultcount = document.getElementById("facebookchatgptcontent").getElementsByClassName('slider').length;
    var jsr = {};
    jsr.id = resultcount +1;
    jsr.text = response.choices[0].text;
    self.state.generatedcontent.push(jsr);
    var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
    self.setState({ generatedcontent: joined });

    // var joined = self.state.generatedcontent.concat(response.choices[0].text);
    // self.setState({ generatedcontent: joined });
    try {
        var totalwords = response.choices[0].text + " " + prompttext;
        var wordcount = totalwords.match(/(\w+)/g).length;
        self.savegptresponse(
          response.usage.total_tokens,
          wordcount
        );
      } catch (error) {}
  }
  self.hideoverlay();
})
.catch(function (error) {
  console.log(error);
  self.hideoverlay();
  self.customAlertMessage('error','Something went wrong, please try again.')
});

	}

    generatepromptfromtemplate = (companytext,description,tone) =>{
        var sample = "Generate an advertisement heading for Company named";// headersample;
        if (companytext.trim() != "") {
            sample += " "+ companytext
        }
        if (description.trim() != "") {
            sample += ", "+ description
        }
        if (tone.trim() != "") {
            sample += " in a "+ tone + " tone."
        }
        var examples = document.getElementsByClassName('exampleinputs');
        var teststring = "";
        for (var i = 0; i < examples.length; i++) {
            teststring += examples[i].value.trim();
        }
        if (teststring.trim() != "") {
            var text = "Create advertisement heading in following formats:";
            var elems = document.getElementsByClassName('exampleinputs');
            for (var i = 0; i < elems.length; i++) {
                var elem = elems[i];
                var newline = "\n";
                text += newline + elem.value.trim();
            }
            var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if(!format.test(sample.slice(-1)))
            {
                sample += ".";
            }
            sample += text;
        }        
        return sample;
    }

    generateaicontentheadlineold = () => {
		
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter Description/Brief.");
			document.getElementById('textareaoutput').focus();
            return;
		}
        
        var companyname = document.getElementById('companyname').value;
		if(companyname.trim() == ""){
			this.customAlertMessage("error","Please enter Name/Team Name.");
			document.getElementById('companyname').focus();
            return;
		}
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice'," Tone");
		 var company = this.gettexteleValue('companyname',"Company");
         var fbexample = this.gettexteleValueexample('exampleinputs','Sample headlines');
        //  var prompttext = company + prompt + toneofvoice + fbexample + " Generate advertisement title";
        //var prompttext = "Generate advertisement headline for " + company + prompt + toneofvoice + fbexample;
        
        var compnayname = document.getElementById('companyname').value;
        var tone = document.getElementById('toneofvoice').value;
        var prompttext = this.generatepromptfromtemplate(compnayname,prompt,tone);
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if(!format.test(prompttext.slice(-1)))
        {
            prompttext += ".";
        }

		var axios = require('axios');
var data = JSON.stringify({
//   "model": "text-davinci-003",
  "prompt": prompttext,
  "temperature": 1,
  "max_tokens": 300
});

var config = {
  method: 'post',
  url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
  headers: { 
    'Content-Type': 'application/json', 
    "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
  },
  data : data
};

axios(config)
.then(function (resp) {
  console.log(JSON.stringify(resp.data));
  var response = resp.data;
  if (response.choices != null && response.choices.length > 0) {
    var resultcount = document.getElementById("facebookchatgptcontent").getElementsByClassName('slider').length;
    var jsr = {};
    jsr.id = resultcount +1;
    jsr.text = response.choices[0].text;
    self.state.generatedcontent.push(jsr);
    var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
    self.setState({ generatedcontent: joined });

    // var joined = self.state.generatedcontent.concat(response.choices[0].text);
    // self.setState({ generatedcontent: joined });
    try {
        var totalwords = response.choices[0].text + " " + prompttext;
        var wordcount = totalwords.match(/(\w+)/g).length;
        self.savegptresponse(
          response.usage.total_tokens,
          wordcount
        );
      } catch (error) {}
  }
  self.hideoverlay();
})
.catch(function (error) {
  console.log(error);
  self.hideoverlay();
  self.customAlertMessage('error','Something went wrong, please try again.')
});

	}

	Checkplagiarism = (e) => {
        var plagiarismtext = e.currentTarget.parentElement.parentElement.getElementsByTagName('p')[0].innerText;
       	
		var self = this;
		this.showoverlay();
		
		var axios = require('axios');
		var FormData = require('form-data');
		// var fs = require('fs');
		var data = new FormData();
		// data.append('postdata', window.getSelection().anchorNode.wholeText);
        data.append('postdata', plagiarismtext);
        data.append("uid",(Role == 3 ? MasterId : UserID));
        data.append("companyid",Companyid);
		
		
		var config = {
		  method: 'post',
		  "url": `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
		  data : data
		};
		
		axios(config)
		.then(function (resp) {
		  console.log(JSON.stringify(resp.data));
		  var response = (resp.data);
		  if (response.Data != null) {
			if (response.Data.count != null && response.Data.count === 0) {
				self.customAlertMessage('success',"No plagiarism found");
                document.getElementById("plagiarisminfo").style.display = "none";
                self.setState({plagiarismmodalbody:""});
                // document.getElementById("plagiarismmodalbody").innerHTML = "";
			}
            else if(response.Message == "small content"){
				self.customAlertMessage('info',response.Data);
			}
			else {
				
                document.getElementById("plagiarisminfo").innerText = response.Data.count + " copies found"
                document.getElementById("plagiarisminfo").style.display = "inline-block";
				//Duplicate content found
				// $(elem).find('.checkmark').hide();
				// $(elem).find('.ban').show();
				var htmlnew = duplicateresultcontent;
				htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
				htmlnew = htmlnew.replace("[PHCSquerywords]", response.Data.querywords);
				var duplicateresultparagraphlist = "";
				for (var i = 0; i < response.Data.count; i++) {
					var result = response.Data.result[i];                       
					var duplicateresultparacontent = duplicateresultparacontenttemplate;
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCSurl]", result.url);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCSurltext]", result.url);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCStitle]", result.title);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCShtmlsnippet]", result.textsnippet);
					duplicateresultparagraphlist = duplicateresultparagraphlist + duplicateresultparacontent;
				}
				htmlnew = htmlnew.replace("[PHCSparagraphlist]", duplicateresultparagraphlist);
                self.setState({plagiarismmodalbody:htmlnew});
                // document.getElementById("plagiarismmodalbody").innerHTML=htmlnew;
                self.customAlertMessage('error',"Plagiarism found");
				//$(elem).parent().parent().append(htmlnew);
				// $(elem).attr('tooltip', "plagiarism found.");
				// $(elem).attr('onclick', "return false;");
			}
		}
		else {
			self.customAlertMessage('error', 'Error occured, Please try again.'); // 'success','failure','info'
		}
		   self.hideoverlay();
		})
		.catch(function (error) {
		  console.log(error);
		  self.customAlertMessage('error', 'Something went wrong, Try Again.');
		   self.hideoverlay();
		});
	}
	copyToClipboard = (e) => {
        copy(e.currentTarget.parentElement.parentElement.getElementsByTagName('p')[0].innerText,{format:"text/plain"});
        // this.setState({ copySuccess: 'Copied!' });      
        this.customAlertMessage('success','Copied!');  
    };
    headlineoption = (e) => {
        document.getElementById("headlineexample").style.display = "block";
        document.getElementById("primarylink").classList.remove("active");
        document.getElementById("headlinelink").classList.add("active");
        document.getElementById("headlineexamplebtn").style.display = "inline-block";
        document.getElementById("primaryexamplebtn").style.display = "none";
    }
    primarytext = (e) => {
        document.getElementById("headlineexample").style.display = "none";
        document.getElementById("primarylink").classList.add("active");
        document.getElementById("headlinelink").classList.remove("active");
        document.getElementById("headlineexamplebtn").style.display = "none";
        document.getElementById("primaryexamplebtn").style.display = "inline-block";
    }
    historygoback = () => {
        window.history.back();
    }
    savegptresponse = (result, totaltoken) => {
        var axios = require("axios");
        var FormData = require("form-data");
        var data = new FormData();
        data.append("prompt", document.getElementById("textareaoutput").value);
        data.append("userid", (Role == 3 ? MasterId : UserID));
        data.append("companyid", Companyid);
        data.append("result", result);
        data.append("token", totaltoken);
    
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
          data: data,
        };
    
        axios(config)
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            //console.log(error);
          });
      };
      deletesingle = (e) => {
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.generatedcontent;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                imagelist.splice(i, 1);
                break;
            }
          }
        this.setState({generatedcontent: imagelist});
        this.customAlertMessage('success','Item removed successfully');
    }
    delateall = () => {
        this.setState({generatedcontent: []});
        this.customAlertMessage('success','Removed successfully');
    }
    deleteexamplefield = (e) => {
        if (this.state.exampleinputcount.length <= 1) {
            this.customAlertMessage('error',"Cannot remove the single Highlights field.");
            return;
        }
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.exampleinputcount;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                imagelist.splice(i, 1);
                break;
            }
          }
        this.setState({exampleinputcount: imagelist});
        // this.customAlertMessage('success','Example removed successfully.');
    }
    addexamplefield = (e) => {
        if (this.state.exampleinputcount.length >= 5) {
            this.customAlertMessage('error','Max 5 Highlights allowed.');
            return;
        }
        var tarr = this.state.exampleinputcount.sort(function(a,b) {return b.id - a.id;})[0];       
        var ei = {};
        ei.id = tarr.id +1;
        ei.text = "";
        this.state.exampleinputcount.push(ei);
        var joined = this.state.exampleinputcount.sort(function(a,b) {return a.id - b.id;});  
        this.setState({ exampleinputcount: joined });
    }
    textchanged = (e) => {
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.exampleinputcount;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                image.text = e.currentTarget.value;
                break;
            }
          }
        this.setState({exampleinputcount: imagelist});
    }

    posttozapier = (e) => {
var message = e.currentTarget.parentElement.parentElement.getElementsByTagName('p')[0].innerText;
        const axios = require('axios');
const FormData = require('form-data');
let data = new FormData();
data.append('userid', Role == 3 ? MasterId : UserID);
data.append('message', message);
data.append('zapid', '2');

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/Pushtozapier',
  data : data
};

axios.request(config)
.then((response) => {
//   console.log(JSON.stringify(response.data));
if (response.data.Status == "200") {
    this.customAlertMessage('success','Message pushed to Zapier successfully.');
  }
  else{
    this.customAlertMessage('error', 'Something went wrong, Try Again.');
  }
})
.catch((error) => {
  console.log(error);
});

    }
    checkcounters = (e) => {
        var self = this;
        var targettype = e.currentTarget.dataset.fbtype;
        const axios = require('axios');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` +'/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedWordsCount',
          headers: { }
        };
      
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data != undefined) {
            if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
            var availblecounter = response.data.Data;
            var lengthcontroller = 300;
            if (availblecounter >= lengthcontroller) {
                if (targettype == "headline") {
                    this.generateaicontentheadline();
                }
                else if(targettype == "primarytext"){
                    this.generateaicontentprimarytext();
                }
            }
            else if (availblecounter < lengthcontroller) {
                var userI = getAccessToken();
                var decodedUser1 = decodeURIComponent(userI);
                var parsedRes1 = JSON.parse(decodedUser1); 
                var PlanType = parsedRes1.PlanType;  
                console.log('PlanType' +PlanType) ;
                console.log('availblecounter' + availblecounter);
                if (PlanType < 1) {
                if (availblecounter == -9999) {
                    this.customAlertMessage('error', 'You have exhausted the usage limit for words.');
                }
                else{
                    document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
                    // document.getElementById('You do not have sufficient tokens to complete this request');
                    document.getElementById('hrefsubscription').click();
                }
                }        
                else{
                    //Show popup to upgrade subscription
                document.getElementById('psubtext').innerText = 'You resources have been exhausted. Please upgrade to a higher subscription plan level.';
                // document.getElementById('You do not have sufficient tokens to complete this request');
                document.getElementById('hrefsubscription').click();
                }
                return;
            }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      checkzapier = () =>{
        var self = this;
        const axios = require('axios');
        let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/Getzapierfacebookurl?userid='+(Role == 3 ? MasterId : UserID),
        };

        axios.request(config)
        .then((response) => {
            if (response.data != null && response.data.Data !="") {
                self.setState({zapurl:response.data.Data});
              }
        })
        .catch((error) => {
            console.log(error);
        });
      }

      proceedOnUpgrade = () =>{
        document.getElementById('btncancelTTI').click();
        if (SSOUser) {      
          document.getElementById('lnkSSOUpgradeTTI').click();
        }
        else{
          document.getElementById('lnkNormalUpgradeTTI').click();
        }
      }
    render(){
    return(
        <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
            <div className="row">	 
                <div className="col-12">
                        <div className="title-card1">
							<span className="float-start">
                            <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Meta </span>-<i className="small-title">Create Meta (Facebook) posts and ads using chatGPT.</i></h4>
								{/* <h4 style={{display:"inline-block"}}><FontAwesomeIcon icon={faChevronLeft} />Facebook</h4> */}
							</span>
						</div>
                </div>
                
                <div className="col-12">
                    <div className='row'>
                        <div className='col-4'>
                            <div className='lefside-bar'>
                                <div className='row'>
                                    <div className='col-12 mb-20'>
                                        <div className='row'>
                                            <div className='col-6 output-length'>
                                                <a id="headlinelink" href="javascript:;" className="active" onClick={this.headlineoption}>Headline</a>
                                            </div>
                                            <div className='col-6 output-length'>
                                                <a id="primarylink" href="javascript:;" onClick={this.primarytext}>Post Text</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-12 label-info">
                                                <label>Name/Team Name</label>
                                                <input id="companyname" maxLength={80} className="common-input" onChange={this.updateprimarytextcount} />
										        <span className="character-limit">{this.state.Primarytextcount}/80</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-12 label-info">
                                                <label>Description/Brief</label>
                                            </div>
                                            <React.StrictMode>
                                                <AudioToText />                                          
                                            </React.StrictMode>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                    <div className="row">
                                        <div className="col-9 label-info">
                                            <label>Tone of Voice<span tooltip="Try using different combinations of words like witty, friendly, disappointed, polite, creative, professional or add a well-known personality such as Adelle." flow="right"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                        </div>
                                        {/* <div className="col-3 switch-toggle">
                                            <input type="checkbox" id="switch1" onChange={this.toggletone.bind(this)} /><label htmlFor="switch1">Toggle</label>
                                        </div> */}
                                        <div className="col-12">
                                            <input id="toneofvoice" type="text" maxLength={60} className="common-input" onKeyUp={this.updatetonecount} disabled={(this.state.tonedisabled)? "disabled" : ""} placeholder="Professional, Friendly"/>
                                            <span className="character-limit">{this.state.Tonecount}/60</span>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col-12 mb-30" id="headlineexample">
                                        <div className="row">
                                            <div className="col-9 label-info">
                                                <label>Highlights<span tooltip="What would you like to highlight or include in the headline?" flow="right"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                            </div>
                                            <div className="col-12">
                                                {/* <div className='row'>
                                                     <div className='col-1'>
                                                        <span className='ex-count'>1</span>
                                                    </div>
                                                    <div className='col-12 output-length1'>
                                                        <input id="fbheadlineexample" type="text" className='common-input' placeholder="Save money and be efficient"></input>
                                                    </div>
                                                     <div className='col-3'>
                                                        <FontAwesomeIcon icon={faCircleXmark} />
                                                        <FontAwesomeIcon icon={faCirclePlus} />
                                                    </div> 
                                                </div>    */}
                                                {this.state.exampleinputcount ? this.state.exampleinputcount.map((d) => (
                                                <div className='row' style={{marginTop:'10px'}}>
                                                {/* <div className='col-2'>
                                                    <span className='ex-count'>{d}</span>
                                                </div> */}
                                                <div className='col-9 output-length1'>
                                                    <input type="text" className='exampleinputs' placeholder="Highlight or include in headline" style={{width:"100%"}} value={d.text} data-fileurl={d.id} onChange={this.textchanged}></input>
                                                </div>
                                                <div className='col-3'>
                                                    <img src={deletesvg} style={{height:'20px',marginLeft:'-10px'}} onClick={this.deleteexamplefield}  data-fileurl={d.id}></img>
                                                    <img src={addsvg} style={{height:'20px',marginLeft:'5px'}} onClick={this.addexamplefield}  data-fileurl={d.id}></img>
                                                </div>
                                                </div>
                                            )): null}                                          
                                            </div>
                                       

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-8'>
                            
                            {/* style={{display: this.state.generatedcontent.count > 0 ? "inline-block":"none" }} */}
                                <div className='row'>
                                    <div className='col-6' style={{display: this.state.generatedcontent.length > 0 ? "block":"none" }}>
                                        <h5 className="right-header">Results: {this.state.generatedcontent.length}</h5>
                                    </div>
                                    <div className="col-6 text-end chech-right" style={{display: this.state.generatedcontent.length > 0 ? "block":"none" }}>
                                {/* <div className="col-12 text-end chech-right"> */}
                                    {/* <span><FontAwesomeIcon icon={faRotateRight} /></span> */}
                                    {/* <span onClick={this.Checkplagiarism} ><FontAwesomeIcon icon={faShield} /></span> */}
                                    <span id="plagiarisminfo" data-bs-toggle="modal" data-bs-target="#exampleModal" className="text-count" style={{backgroundColor:"#f44336",color:"#fff",display:"none"}}>5 copies found</span>
                                    <span onClick={this.delateall} className="clearnew-btn" style={{marginLeft:'10px',cursor:'pointer'}}>Clear All</span>
                                </div>
                                </div>
                                <div className='row'>
                                
                                    <div className='col-12' style={{position:"relative"}}>
                                    <div id="overlay">
                                <div id="loading">
                                    <h2 id="loaderheading">Generating...</h2>
                                    <img src={loader} />  
                                </div>
                            </div>  
                                    <div className='rightside-bar'>
                                    <div id="facebookchatgptcontent">
                                    {this.state.generatedcontent ? this.state.generatedcontent.map((d) => (
                                        <div className="output-wrap slider">
                                            <div className='out-content'>
                                                <p>
                                                {d.text}
                                                </p>
                                            </div>
                                            <div className='out-icons'>  
                                            { this.state.zapurl == "" ? 
                                            <a href="https://www.facebook.com" target='_blank'>
                                                <FontAwesomeIcon icon={faFacebook} />
                                            </a> : 
                                            <a href="javascript:;" onClick={this.posttozapier}>
                                            <FontAwesomeIcon icon={faFacebook} />
                                            </a> }
                                            {this.state.copyscapeavailable == true ? 
                                            <span onClick={this.Checkplagiarism} tooltip="Check plagiarism" flow="left"><FontAwesomeIcon icon={faShield} /></span> : null }
                                                <span tooltip="Copy" flow="left" onClick={this.copyToClipboard}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                                <span tooltip="Delete" flow="left" onClick={this.deletesingle} data-fileurl={d.id}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>                                            
                                            </div>
                                        </div>
                                    )) : null }
                                    </div>
                                    </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className='bottom-fixed'>
		      <div className="row">
			      <div className="col-4">
						<div className="text-center">
                        <a id="headlineexamplebtn" href="javascript:;" className="common-save" data-fbtype="headline" onClick={this.checkcounters}>Generate</a>
                        <a id="primaryexamplebtn" style={{display:"none"}} href="javascript:;" className="common-save" data-fbtype="primarytext" onClick={this.checkcounters}>Generate</a>
						</div>
				  </div>
			  </div>
		  </div>

            


        </div>

        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Plagiarism</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body" id="plagiarismmodalbody">     
                    {this.state.plagiarismmodalbody}
                </div>
                </div>
            </div>
        </div>
        <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
 <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Upgrade Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                <div className="col-12">
                  <p id="psubtext"></p>
                  <div className="row" style={{marginBottom:"10px",display:"none"}}>
                      <div className='col-3'>
                          <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                          <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                      </div>
                  </div>
                  {/* {SSOUser == true ?
                  <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                 <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>} */}
                </div>
              </div>
              <div className="modal-footer">   
              <button type="button" style={{display:"block"}} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>                        
              <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
              </div>
          </div>
      </div>
      </div>
    </div>
    
    
    


    )}};

    export default Facebook;