import React from "react";
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { Link } from 'react-router-dom';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#303238",
            fontSize: "16px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#CFD7DF"
            }
            
        },
        invalid: {
            color: "#e5424d",
            ":focus": {
                color: "#303238"
            }
        }
    }
};

function CardSection() {
    return (
        <div className="col-sm-12">

            <div className="form-container">
                <div className="row">
                    <h4 id="h4SuccessHeader">Please authorize your credit card by providing following details.</h4>
                    <div className="col-12">
                        <label>Card Number</label>
                        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                    <div className="col-6">
                        <label>Expiration Date</label>
                        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                    <div className="col-6">
                        <label>CVC</label>
                        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                </div>
            </div>
            
            {/* <label>Enter Credit Card details</label>
            Card Number: <CardNumberElement options={CARD_ELEMENT_OPTIONS} /> <br/>
            Expiry: <CardExpiryElement options={CARD_ELEMENT_OPTIONS} /> &nbsp; CVC: <CardCvcElement options={CARD_ELEMENT_OPTIONS} /> */}

        </div>
    );
}

export default CardSection;