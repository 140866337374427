import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faChevronLeft,
  faMicrophone,
  faInfo,
  faInfoCircle,
  faRotateRight,
  faShield,
} from "@fortawesome/free-solid-svg-icons";
import { json, Link } from "react-router-dom";
import React, { useState } from "react";
import AudioToText from "./AudioToText";
import $ from "jquery";
// import Emailstripo from "../email/stripoapp";
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import {
  duplicateresultcontent,
  duplicateresultparacontenttemplate,
  ChatGPTemailcontent,
  truidraggable,
} from "../email/templateBase";
import queryString from "query-string";
// import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import {
  CompositeDecorator,
  ContentState,
  EditorState,
  SelectionState,
  Modifier,
  convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Editor } from "react-draft-wysiwyg";
import "/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";
import Cookies from 'js-cookie';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg, TeamId, MasterId} from "../User";
import cleareditorbtn from "../img/broom.png";
import AudioToTextforEditor from "./AudioToTextforEditor";
import draftToHtml from 'draftjs-to-html';
import {SSE} from "sse.js"


class Emails extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      keyworddisabled: false,
      tonedisabled: false,
      copySuccess: "",
      wordcount: 0,
      Tonecount: 0,
      generatedimages: [],
      emailguid: NIL_UUID,
      emailhtml: "",
      emailhtmlcount: 0,
      defaultcontenterased: false,
      editorState: EditorState.createEmpty(),
      lastEmailtext: "",
      plagiarismmodalbody:{ __html: '' }
    };
  }
  componentDidMount() {
    this.jquerycode();
    // setInterval(() => {
    //   this.checkandupdatecontent();      
    // }, 10000);
    const query = queryString.parse(window.location.search);
    const token = query.id;
    //console.log('Token set: '+ token);
    //console.log('before set: '+this.state.emailguid);
    if (token != undefined) {
      this.setState({ emailguid: token });
      this.loadtemplate(token);
    } else {
      this.setState({ emailguid: uuidv4() });
    }
    //console.log('After set: '+this.state.emailguid);
    //console.log(this.state.emailguid);
  }

  requestGetTemplateFinal = (method, url, data, callback) => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (req.readyState === 4 && req.status === 200) {
        callback(req.responseText);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.error(
          "Can not complete request. Please enter valid auth token"
        );
      }
    };
    req.open(method, url, true);
    if (method !== "GET") {
      req.setRequestHeader("content-type", "application/json");
      req.setRequestHeader(
        "Header",
        "ES-PLUGIN-AUTH: Bearer " + document.getElementById("hfAuthToken").value
      );
    }
    req.send(data);
  };

  loadDemoTemplate = (cssurl, htmlurl) => {
    var self = this;
    self.requestGetTemplateFinal("GET", htmlurl, null, function (html) {
      //Set html to the editor
      var el = document.createElement("html");
      el.innerHTML = html; // "<html><head><title>titleTest</title></head><body><a href='test0'>test01</a><a href='test1'>test02</a><a href='test2'>test03</a></body></html>";

      el.getElementsByTagName("body"); // Live NodeList of your anchor elements
      self.appendhtmltoeditor(el.getElementsByTagName("body")[0].innerHTML);
      // self.requestGetTemplateFinal('GET', cssurl, null, function (css) {

      // });
    });
  };
  // const Emails = () => {
  loadtemplate = (token) => {
    var self = this;
    var axios = require("axios");
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/gettemplate?id=" +
        token,
      headers: {},
    };

    axios(config)
      .then(function (resp) {
        // console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data.length > 0) {
          var htmlurl = response.Data[0].htmlurl + "?v=" + uuidv4();
          var cssurl = response.Data[0].cssurl + "?v=" + uuidv4();
          var title = response.Data[0].title;
          var description = response.Data[0].description;
          document.getElementById("templatetitle").value = title;
          document.getElementById("templatedescription").value = description;
          self.loadDemoTemplate(cssurl, htmlurl);
          if(title.trim() != "No title")
          {
            self.toggletemplatediv();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  storeeditorplaintext = () => {
    this.setState({
      lastEmailtext: this.state.editorState
        .getCurrentContent()
        .getPlainText("\u0001"),
    });
  };

  // geteditorquestion = () => {
  //   var blocks = this.state.editorState.getCurrentContent().getBlocksAsArray();
  //   var html = stateToHTML(this.state.editorState.getCurrentContent());
    
  //   for (var i = blocks.length - 1; i >= 0; i--) {
  //     var block = blocks[i];
  //     var txxxx = block.text;
  //     if (txxxx.trim() != "") {
  //       console.log(txxxx);
  //       break;
  //     }
  //   }
  //   return;
  //   blocks
  //     .slice()
  //     .reverse()
  //     .forEach((block) => {
  //       var txxxx = block.text;
  //       if (txxxx.trim() != "") {
  //         console.log(txxxx);
  //         return false;
  //       }
  //     });
  //   var oldtext = this.state.lastEmailtext;
  //   var currenttext = this.state.editorState
  //     .getCurrentContent()
  //     .getPlainText("\u0001");
  //   var question = currenttext.replace(oldtext, "");
  //   console.log(question);
  //   //return question;
  // };

  updateeditorstate = () => {
    const data = "";
    let { contentBlocks, entityMap } = htmlToDraft(data);
    let contentState = Modifier.replaceWithFragment(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    this.setState({ editorState: EditorState.createWithContent(contentState) });
  };

  appendhtmltoeditor = (gptresponse) => {
    const data = gptresponse.replace(/(<\/?)figure((?:\s+.*?)?>)/g, ''); // "<p>"+gptresponse+"</p>";
    let { contentBlocks, entityMap } = htmlToDraft(data);
    const currentContent = this.state.editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
   
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    let contentState = Modifier.replaceWithFragment(
      this.state.editorState.getCurrentContent(),
      selection,
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    var editorWithInsert = EditorState.push(
      this.state.editorState,
      contentState,
      "insert-fragment"
    );
    this.setState({ editorState: EditorState.createWithContent(contentState) });
    // const newEditorState = EditorState.moveFocusToEnd(
    //   editorWithInsert,
    //   contentState.getSelectionAfter()
    // );
    //  EditorState.forceSelection(editorWithInsert,contentState.getSelectionAfter());
    this.state.editorState.getSelection();
    
    
    //this.storeeditorplaintext();
    // this.setState({editorState:newEditorState })
  };
  appendtexttoeditor = (gptresponse) => {
    // get current editor state
    const currentContent = this.state.editorState.getCurrentContent();
    const data = gptresponse.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
    // create new selection state where focus is at the end
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });

    //insert text at the selection created above
    const textWithInsert = Modifier.insertText(
      currentContent,
      selection,
      data,
      null
    );
    const editorWithInsert = EditorState.push(
      this.state.editorState,
      textWithInsert,
      "insert-characters"
    );

    //also focuses cursor at the end of the editor
    const newEditorState = EditorState.moveSelectionToEnd(
      editorWithInsert,
      textWithInsert.getSelectionAfter()
    );
    this.setState({ editorState: newEditorState });
    //    setEditorState(newEditorState);
  };

  onChangeeditor = (editorState) => {
    this.setState({
      editorState: editorState,
    });
    this.checkandupdatecontent();
  };
  jquerycode = () => {
    var self = this;
    Array.from(
      document.getElementById("sidebar_menu").querySelectorAll("li")
    ).forEach(function (el) {
      el.classList.remove("active");
    });
    document.getElementById("1").classList.add("active");    

    setTimeout(() => {
      document.getElementsByClassName('DraftEditor-editorContainer')[0].classList.add('rightside-bar-editor');
    }, 1500);
    
  };
  setwordcount = () => {
    var text = document.getElementById("keywordinput").value;
    if (text.trim() == "") {
      this.setState({ wordcount: 0 });
    } else {
      const words = text.trim().split(",");
      if (words.length > 50) {
        this.customAlertMessage("warning", "Keywords limit exceeded.");
        var trimmedwords = text.trim().split(",").slice(0, 3).join(",");
        document.getElementById("keywordinput").value = trimmedwords;
        this.setState({ wordcount: 3 });
      } else {
        this.setState({ wordcount: words.length });
      }
    }
  };

  togglekeywords() {
    this.setState({ keyworddisabled: !this.state.keyworddisabled });
  }

  toggletone() {
    this.setState({ tonedisabled: !this.state.tonedisabled });
  }

  updatetonecount = (e) => {
    this.setState({ Tonecount: e.currentTarget.value.length });
  };
  toggletemplatediv = () => {
    $("#templatesavediv").toggle();
    $("#btnsavetemplate").toggle();
    if ($("#templatetogglelink").text() == "Save As Template") {
      $("#templatetogglelink").text("Hide save as");
    } else {
      $("#templatetogglelink").text("Save As Template");
    }
  };
  customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };
  showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  geteleValue = (objectid) => {
    var text = "";
    if (document.getElementById(objectid).value != "-1") {
      text =
        document.getElementById(objectid).options[
          document.getElementById(objectid).selectedIndex
        ].text + " , ";
    }
    return text;
  };
  gettexteleValue = (objectid, prefixtext) => {
    var text = "";
    if (document.getElementById(objectid).value.trim() != "") {
      text =
        prefixtext + ": " + document.getElementById(objectid).value + " , ";
    }
    return text;
  };

  
//   generateaicontent = () => {
//     var self = this;
//     var prompt = "";
//     var blocks = this.state.editorState.getCurrentContent().getBlocksAsArray();
//     for (var i = blocks.length - 1; i >= 0; i--) {
//       var block = blocks[i];
//       var txxxx = block.text;
//       if (txxxx.trim() != "") {
//         prompt = txxxx.trim();
//         break;
//       }
//     }
//     var txtarea = document.getElementById('textareaoutput').value;
//     if (prompt.trim() == "" && txtarea.trim() == "") {
//       this.customAlertMessage("error", "Please enter text.");
//       //document.getElementById("emailinputprompt").focus();
//       return;
//     }
//     prompt = this.state.editorState.getCurrentContent().getPlainText("\u0001").trim();
//     if (prompt.trim() == "") {
//       prompt = txtarea.trim();
//     }
//     // var prompt = document.getElementById('textareaoutput').value;
//     // if(prompt.trim() == ""){
//     // 	this.customAlertMessage("error","Please enter text.");
//     // 	document.getElementById('textareaoutput').focus();
//     // 	return;
//     // }
// var lengthcontroller = this.getselectedcontentlength();
//     var Supplement = this.gettexteleValue("textareaoutput", "Supplement");
//     var toneofvoice = this.gettexteleValue("toneofvoice", "Tone");
//     var keywords = this.gettexteleValue("keywordinput", "Keyword");
//     var prompttext = ("Topic: "+ prompt + " " + toneofvoice + keywords + Supplement + " "+ lengthcontroller);
//     self.showoverlay();
//     var axios = require("axios");
//     var data = JSON.stringify({
//       model: "text-davinci-003",
//       prompt: prompttext.trim(),
//       temperature: 1,
//       max_tokens: parseInt(lengthcontroller),
//       top_p: 0.95,
//       stream: true,
//       // n: 1
//     });
//     var url = "https://api.openai.com/v1/completions";
//     var eventSourceInitDict = {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization:
//           "Bearer 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",
//       },
//       method: "POST",
//       payload: JSON.stringify(data),
//     };

//     let source = new SSE(url, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer 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`,
//       },
//       method: "POST",
//       payload: (data),
//     });    

//     // var config = {
//     //   method: "post",
//     //   url: "https://api.openai.com/v1/completions",
//     //   headers: {
//     //     "Content-Type": "application/json",
//     //     Authorization:
//     //       "Bearer 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",
//     //   },
//     //   data: data,
//     // };

//     source.addEventListener("message", (e) => {
//       if (e.data != "[DONE]") {
//         self.hideoverlay();
//         let payload = JSON.parse(e.data);
//         let text = payload.choices[0].text;
//         if (text != "\n") {
//           console.log("Text: " + text);
//           // resultRef.current = resultRef.current + text;
//           // console.log("ResultRef.current: " + resultRef.current);
//           // setResult(resultRef.current);
//           setTimeout(() => {
//             self.appendhtmltoeditor(text + " ");
//           }, 1000);
          
//         }
//       } else {
//         source.close();
//       }
//     });

//     source.addEventListener("readystatechange", (e) => {
//       if (e.readyState >= 2) {
//         // setIsLoading(false);
//       }
//     });

//     source.stream(); 

//     // axios(config)
//     //   .then(function (resp) {
//     //     //console.log(JSON.stringify(resp.data));
//     //     var response = resp.data;
//     //     console.log(response.choices[0].text);
       
//     //     if (response.choices != null && response.choices.length > 0) {
//     //       var datalist = response.choices[0].text.split('\n');
//     //       var finalData="";
//     //       for(var i=0;i<datalist.length;i++)
//     //       {
//     //           if(datalist[i]!="")
//     //           {
//     //             finalData = finalData+"<p style='background-color:palegreen;'>" + datalist[i] + "</p>";
//     //           }
//     //       }
//     //       self.appendhtmltoeditor(finalData + "<p style='background-color:none;'>&nbsp</p>");
//     //       try {
//     //         this.checkandupdatecontent();
//     //       } catch (error) {
            
//     //       }
//     //       try {
//     //         self.savegptresponse(
//     //           JSON.stringify(resp.data),
//     //           response.usage.total_tokens
//     //         );
//     //       } catch (error) {}
//     //     }
//     //     self.hideoverlay();
//     //   })
//     //   .catch(function (error) {
//     //     //console.log(error);
//     //     self.hideoverlay();
//     //     self.customAlertMessage(
//     //       "error",
//     //       "Something went wrong, please try again."
//     //     );
//     //   });
//   };



  generateaicontent = () => {
    var self = this;
    var prompt = "";
    var blocks = this.state.editorState.getCurrentContent().getBlocksAsArray();
    for (var i = blocks.length - 1; i >= 0; i--) {
      var block = blocks[i];
      var txxxx = block.text;
      if (txxxx.trim() != "") {
        prompt = txxxx.trim();
        break;
      }
    }
    var txtarea = document.getElementById('textareaoutput').value;
    if (prompt.trim() == "" && txtarea.trim() == "") {
      this.customAlertMessage("error", "Please enter text.");
      //document.getElementById("emailinputprompt").focus();
      return;
    }
    prompt = this.state.editorState.getCurrentContent().getPlainText().trim();
    if (prompt.trim() == "") {
      prompt = txtarea.trim();
    }
    // var prompt = document.getElementById('textareaoutput').value;
    // if(prompt.trim() == ""){
    // 	this.customAlertMessage("error","Please enter text.");
    // 	document.getElementById('textareaoutput').focus();
    // 	return;
    // }
var lengthcontroller = this.getselectedcontentlength();
    var Supplement = this.gettexteleValue("textareaoutput", "Supplement");
    var toneofvoice = this.gettexteleValue("toneofvoice", "Tone");
    var keywords = this.gettexteleValue("keywordinput", "Keyword");
    var prompttext = ("Topic: "+ prompt + " " + toneofvoice + keywords + Supplement + " "+ lengthcontroller);
    self.showoverlay();
    var axios = require("axios");
    var data = JSON.stringify({
      model: "text-davinci-003",
      prompt: prompttext.trim(),
      temperature: 1,
      max_tokens: parseInt(lengthcontroller),
    });

    var config = {
      method: "post",
      url: "https://api.openai.com/v1/completions",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer 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",
      },
      data: data,
    };

    axios(config)
      .then(function (resp) {
        //console.log(JSON.stringify(resp.data));
        var response = resp.data;
        console.log(response.choices[0].text);
       
        if (response.choices != null && response.choices.length > 0) {
          var datalist = response.choices[0].text.split('\n');
          var finalData="";
          for(var i=0;i<datalist.length;i++)
          {
              if(datalist[i]!="")
              {
                finalData = finalData+"<p>" + datalist[i] + "</p>";
              }
          }
          self.appendhtmltoeditor("<p></p>"+ finalData);

          
          try {
            self.savegptresponse(
              JSON.stringify(resp.data),
              response.usage.total_tokens
            );
          } catch (error) {}
          try {
            setTimeout(() => {
              self.checkandupdatecontent();
            }, 2000);
            
          } catch (error) {
            
          }
        }
        self.hideoverlay();
      })
      .catch(function (error) {
        //console.log(error);
        self.hideoverlay();
        self.customAlertMessage(
          "error",
          "Something went wrong, please try again."
        );
      });
  };


  // 	generateaicontent = () => {

  //         var self = this;

  // 		var prompt = document.getElementById('emailinputprompt').value;
  // 		if(prompt.trim() == ""){
  // 			this.customAlertMessage("error","Please enter text.");
  // 			document.getElementById('emailinputprompt').focus();
  // 			return;
  // 		}
  //         // var prompt = document.getElementById('textareaoutput').value;
  // 		// if(prompt.trim() == ""){
  // 		// 	this.customAlertMessage("error","Please enter text.");
  // 		// 	document.getElementById('textareaoutput').focus();
  // 		// 	return;
  // 		// }

  // 		var Supplement = this.gettexteleValue('textareaoutput',"Supplement");
  // 		var toneofvoice = this.gettexteleValue('toneofvoice',"Tone");
  // 		 var keywords = this.gettexteleValue('keywordinput',"Keyword");
  //          var prompttext = prompt + " " + toneofvoice + keywords + Supplement;
  // 		 self.showoverlay();
  // 		var axios = require('axios');
  // 		var data = JSON.stringify({
  // 		"model": "text-davinci-003",
  // 		"prompt": prompttext,
  // 		"temperature": 1,
  // 		"max_tokens": 1000
  // 		});

  // 		var config = {
  // 		method: 'post',
  // 		url: 'https://api.openai.com/v1/completions',
  // 		headers: {
  // 			'Content-Type': 'application/json',
  // 			'Authorization': 'Bearer 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'
  // 		},
  // 		data : data
  // 		};

  // axios(config)
  // .then(function (resp) {
  //   //console.log(JSON.stringify(resp.data));
  //   var response = resp.data;
  //   if (response.choices != null && response.choices.length > 0) {
  // 	var htmlnew = truidraggable;
  // 	htmlnew = htmlnew.replace("[PHchatgptnewpara]", response.choices[0].text);
  // 	//if(self.state.defaultcontenterased)
  // 	{
  // 		document.getElementsByTagName('iframe')[0].contentWindow.document.querySelectorAll('.esd-container-frame  tbody.ui-droppable')[0].innerHTML += htmlnew;
  // 		//document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').innerText += response.choices[0].text;
  // 		// $('#chatGPTcontent .ui-droppable').append(htmlnew);
  // 		//document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText;
  // 	}
  // 	// else{
  // 	// 	document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').getElementsByClassName('ui-droppable')[0].innerHTML = htmlnew;
  // 	// 	//$('iframe.stripo-preview-frame').contentWindow.document.getElementById('chatGPTcontent').getElementsByClassName('ui-droppable')[0].innerHTML = htmlnew;
  // 	// 	// $('#chatGPTcontent .ui-droppable').innerHTML = htmlnew;
  // 	// 	self.setState({defaultcontenterased:true});
  // 	// }
  // 	try {
  // 		self.savegptresponse(JSON.stringify(resp.data),response.usage.total_tokens);
  // 	} catch (error) {

  // 	}
  //   }
  //   self.hideoverlay();
  // })
  // .catch(function (error) {
  //   //console.log(error);
  //   self.hideoverlay();
  //   self.customAlertMessage('error','Something went wrong, please try again.')
  // });

  // 	}

  Checkplagiarism = () => {
    try {
      if (
        this.state.editorState
          .getCurrentContent()
          .getPlainText("\u0001")
          .trim() == ""
      ) {
        this.customAlertMessage(
          "warning",
          "cannot check plagiarism for empty content."
        );
        return;
      }

      // if(document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerText.trim() == ""){
      // 	this.customAlertMessage('warning',"cannot check plagiarism for empty content.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').textContent.trim() == ""){
      // 	this.customAlertMessage('warning',"cannot check plagiarism for empty content.");
      // 	return;
      // }

      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode == null){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.nodeName != "#text"){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText == ""){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
    } catch (error) {
      this.customAlertMessage("error", "Error occured, Please try again.");
      return;
    }

    var self = this;
    this.showoverlay();

    var axios = require("axios");
    var FormData = require("form-data");
    // var fs = require('fs');
    var data = new FormData();
    // data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText);
    //data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').textContent.trim());
    //data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerText.trim());
    data.append(
      "postdata",
      this.state.editorState.getCurrentContent().getPlainText("\u0001").trim()
    );
    data.append("uid",UserID);
    data.append("companyid",Companyid);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (resp) {
        //console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data != null) {
          if (response.Data.count != null && response.Data.count === 0) {
            self.customAlertMessage("success", "No plagiarism found");
            document.getElementById("plagiarisminfo").style.display = "none";
            // document.getElementById("plagiarismmodalbody").innerHTML = "";
            self.setState({plagiarismmodalbody:{ __html: '' }})
            // $(elem).find('.checkmark').show();
            // $(elem).find('.ban').hide();
            // $(elem).attr('tooltip', "No plagiarism found.");
            // $(elem).attr('onclick', "return false;");
          } else if (response.Message == "small content") {
            self.customAlertMessage("info", response.Data);
          } else {
            document.getElementById("plagiarisminfo").innerText =
              response.Data.count + " copies found";
            document.getElementById("plagiarisminfo").style.display =
              "inline-block";
            var htmlnew = duplicateresultcontent;
            htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
            htmlnew = htmlnew.replace(
              "[PHCSquerywords]",
              response.Data.querywords
            );
            var duplicateresultparagraphlist = "";
            for (var i = 0; i < response.Data.count; i++) {
              var result = response.Data.result[i];
              var duplicateresultparacontent =
                duplicateresultparacontenttemplate;
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCSurl]",
                result.url
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCSurltext]",
                result.url
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCStitle]",
                result.title
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCShtmlsnippet]",
                result.textsnippet
              );
              duplicateresultparagraphlist =
                duplicateresultparagraphlist + duplicateresultparacontent;
            }
            htmlnew = htmlnew.replace(
              "[PHCSparagraphlist]",
              duplicateresultparagraphlist
            );
            // document.getElementById("plagiarismmodalbody").innerHTML = this.sanitizeHTML(htmlnew);
            self.setState({plagiarismmodalbody:{ __html: htmlnew }})
            self.customAlertMessage("error", "Plagiarism found");
          }
        } else {
          self.customAlertMessage("error", "Error occured, Please try again."); // 'success','failure','info'
        }
        self.hideoverlay();
      })
      .catch(function (error) {
        //console.log(error);
        self.customAlertMessage("error", "Something went wrong, Try Again.");
        self.hideoverlay();
      });
  };
  sanitizeHTML = (value) => {
    const div = document.createElement('div');
    div.innerText = value;
    return div.innerHTML;
  }
  historygoback = () => {
    window.history.back();
  };
  checkandupdatecontent = () => {
    // if (this.state.editorState.getUndoStack().size <= 0) return;
    const oldcontent = this.state.emailhtml;
    const htmlcount = this.state.emailhtmlcount;
    // if(!document.getElementsByTagName('iframe').length > 0)
    // return;

    // const currentcontent = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph');
    // const currentcontent = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent');
    // if(currentcontent == null)
    // return;

    // if(currentcontent.textContent.trim() == "Type your content here")
    // if(currentcontent.innerText.trim() == "Type your content here")
    // return;
    var contenthtml = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));// stateToHTML(this.state.editorState.getCurrentContent());
    var currentcontentlength = contenthtml.length;
    // if(oldcontent != currentcontent.textContent.trim())
    if (currentcontentlength != htmlcount) {
      //document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerHTML.length
      //console.log('changed content');
      this.setState({
        emailhtml: contenthtml,
        emailhtmlcount: currentcontentlength,
      });
      //console.log(this.state.emailhtml);
      this.gettemplate();
    } else {
      //console.log('no change');
    }
  };
  gettemplate = () => {
    var pltext = this.state.editorState
      .getCurrentContent()
      .getPlainText("\u0001");
    var html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));// stateToHTML(this.state.editorState.getCurrentContent());
    var css = html;
    this.autosavehtml(html, css, false);
    // $(".rightside-bar-editor div").prop("contenteditable", "true");
    // $(".rightside-bar-editor div span").prop("contenteditable", "true");
    // var self = this;
    // window.StripoApi.getTemplate(function (html, css) {
    // 	var rhtml = html.substring(html.indexOf("<html"));
    // 	self.autosavehtml(rhtml,css,false);
    // });
  };
  gettemplatebtn = () => {
    var html = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));// stateToHTML(this.state.editorState.getCurrentContent());
    var css = html;
    this.autosavehtml(html, css, true);
    // var self = this;
    // window.StripoApi.getTemplate(function (html, css) {
    // 	var rhtml = html.substring(html.indexOf("<html"));
    // 	self.autosavehtml(rhtml,css,true);
    // });
  };
  requestGetTemplateFinal = (method, url, data, callback) => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (req.readyState === 4 && req.status === 200) {
        //save data
        //console.log(req.responseText);
        callback(req.responseText);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.error(
          "Can not complete request. Please enter valid auth token"
        );
      }
    };
    req.open(method, url, true);
    if (method !== "GET") {
      req.setRequestHeader("content-type", "application/json");
      req.setRequestHeader(
        "Header",
        "ES-PLUGIN-AUTH: Bearer " + document.getElementById("hfAuthToken").value
      );
    }
    req.send(data);
  };

  autosavehtml = (html, css, frombtn) => {
    var self = this;
    var titledefault = document.getElementById("templatetitle").value;
    if (titledefault.trim() == "") {
      titledefault = "No title";
    }
    var descriptiondefault = document
      .getElementById("templatedescription")
      .value.trim();
    var emailidentity =
      queryString.parse(window.location.search).id == undefined
        ? this.state.emailguid
        : queryString.parse(window.location.search).id;
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    console.log("before API: " + emailidentity);
    data.append("tempid", emailidentity);
    data.append("userid", UserID);
    data.append("companyid", Companyid);
    data.append("html", html);
    data.append("css", css);
    data.append("title", titledefault);
    data.append("description", descriptiondefault);
    data.append("teamid", TeamId);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/autosavetemplate",
      data: data,
    };

    axios(config)
      .then(function (resp) {
        //   console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Status == "200" && frombtn) {
          self.customAlertMessage("success", "Template saved successfully.");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  savegptresponse = (result, totaltoken) => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("prompt", document.getElementById("textareaoutput").value);
    data.append("userid", (Role == 3 ? MasterId : UserID));
    data.append("companyid", Companyid);
    data.append("result", result);
    data.append("token", totaltoken);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  getselectedcontentlength =() => {
    var rdbtnlength = document.getElementsByName('radioforlength');
    var selectedtext = "";
    var outputtext = "";
    for (let i = 0; i < rdbtnlength.length; i++) {
      if(rdbtnlength[i].checked){
        selectedtext = rdbtnlength[i].dataset.text;
        break;
      }
    }
    
    // switch (selectedtext) {
    //   case "short":
    //     outputtext = "Write an short abstract for above topic";
    //     break;
    //     case "medium":
    //     outputtext = "Explain in detail for above topic";
    //     break;
    //     case "long":
    //     outputtext = "Write an blog post on above topic";
    //     break;    
    //   default:
    //     outputtext = "";
    //     break;
    // }
    switch (selectedtext) {
      case "short":
        outputtext = "400";
        break;
        case "medium":
        outputtext = "1000";
        break;
        case "long":
        outputtext = "2000";
        break;    
      default:
        outputtext = "2000";
        break;
    }
    return outputtext;
  }

  cleareditor =()=>{
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
  }
  testhtmlappend = () =>{
    this.appendhtmltoeditor("<GPT>this is test data</GPT>");
  }

  render() {
    return (
      <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">
            <input type="hidden" id="hfAuthToken"></input>

            <div className="col-12">
              <div className="title-card1">
                <h4>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.historygoback}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Email
                  </span>
                </h4>
                {/* {this.state.emailguid} */}
              </div>
            </div>

            <div className="col-12">
			
              <div className="row">
                <div className="col-4">
                  <div class="lefside-bar">
                    <div className="row">
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-12 label-info">
                            <label>
                              Content Description/Brief
                              <span
                                tooltip="Tell chocolatechips.ai what you are writing about."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          <React.StrictMode>
                            <AudioToText />
                          </React.StrictMode>
                        </div>
                      </div>
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-9 label-info">
                            <label>
                              Tone of Voice
                              <span
                                tooltip="Try using different combinations of words like witty, friendly, disappointed, polite, creative, professional or add a well-known personality such as Adelle."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          {/* <div className="col-3 switch-toggle">
										<input type="checkbox" id="switch1" onChange={this.toggletone.bind(this)} /><label htmlFor="switch1">Toggle</label>
									</div> */}
                          <div className="col-12">
                              <input
                                id="toneofvoice"
                                type="text"
                                maxLength={60}
                                className="common-input"
                                onKeyUp={this.updatetonecount}
                                disabled={
                                  this.state.tonedisabled ? "disabled" : ""
                                }
                                placeholder="Professional, Friendly"
                              />

                            <span className="character-limit">
                              {this.state.Tonecount}/60
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-9 label-info">
                            <label>
                              Keywords
                              <span
                                tooltip="Write keywords separated by commas."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          {/* <div className="col-3 switch-toggle">
									<input type="checkbox" id="switch2" onChange={this.togglekeywords.bind(this)} /><label for="switch2">Toggle</label>
									</div> */}
                          <div className="col-12">
                              <input
                                id="keywordinput"
                                type="text"
                                className="common-input"
                                onChange={this.setwordcount}
                                disabled={
                                  this.state.keyworddisabled ? "disabled" : ""
                                }
                                placeholder="Behavior, Vision"
                              />

                            <span className="character-limit">
                              {this.state.wordcount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-20">
                        <div className="row">
                          <div className="col-12 label-info">
                            <label>Output Length</label>
                          </div>
                          <div className="col-12 output-length">
                              <div className="container-radio">
                                      <div className="radio_container">
                                          <input type="radio" name="radioforlength" id="one" data-text="short" />
                                          <label htmlFor="one">Short</label>
                                          <input type="radio" name="radioforlength" id="two" data-text="medium"/>
                                          <label htmlFor="two">Medium</label>
                                          <input type="radio" name="radioforlength" id="three" data-text="long"/>
                                          <label htmlFor="three">Long</label>
                                      </div>
                                    </div>
                          </div>
                        </div>
                      </div> 
                      <div className="col-12 mb-30">
                        <div className="row">
                          {/* <div className="col-12 text-center">
												<a href="javascript:;" className="common-save" onClick={this.generateaicontent}>Generate</a>
											</div> */}
                          <div className="col-12">
                            <a
                              id="templatetogglelink"
                              href="javascript:;"
                              className="float-end save-temp"
                              onClick={this.toggletemplatediv}
                            >
                              Save As Template
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="templatesavediv" style={{ display: "none" }}>
                        <div className="col-12 mb-30">
                          <div className="row">
                            <div className="col-9 label-info">
                              <label>
                                Title
                                <span
                                  tooltip="Enter a title to save as template and you can continue where you left off."
                                  flow="right"
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              </label>
                            </div>
                            {/* <div className="col-3 switch-toggle">
											<input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
										</div> */}
                            <div className="col-12">
                                <input
                                  id="templatetitle"
                                  type="text"
                                  className="common-input"
                                />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-30">
                          <div className="row">
                            <div className="col-9 label-info">
                              <label>
                                Description
                                <span
                                  tooltip="A slight note to help you identify what the topic you were working on."
                                  flow="right"
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              </label>
                            </div>
                            {/* <div className="col-3 switch-toggle">
											<input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
										</div> */}
                            <div className="col-12">
                                <input
                                  id="templatedescription"
                                  type="text"
                                  className="common-input"
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-8">
                  <div class="">
                    <div className="row">
                      <div className="col-12 text-end chech-right" style={{marginBottom:"10px"}}>
                        {/* <span><FontAwesomeIcon icon={faRotateRight} /></span> */}
                        <span onClick={this.Checkplagiarism}>
                          <FontAwesomeIcon icon={faShield} />
                        </span>
                        <span
                          id="plagiarisminfo"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}
                        >
                          5 copies found
                        </span>
                        {/* <span className="text-count">5 words</span> */}
                      </div>
                    </div>
                    <div className="row">                  
                      {/* <Editor editorState={this.state.editorState} onChange={this.onChangeeditor} /> */}

                      <div className="col-12" style={{position:"relative"}}>
                      <div id="overlay" className="email-overlay">
                        <div id="loading">
                          <h2 id="loaderheading">Generating...</h2>
                          <img src={loader} />
                        </div>
                      </div>
                      <div className="editorstt">
                      <AudioToTextforEditor parentCallback={this.appendtexttoeditor}></AudioToTextforEditor>
                      </div>
                      <Editor
                        editorState={this.state.editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onChangeeditor}
                        spellCheck={true} 
                        ref="editor"
                        placeholder="Start writing or speaking your command here. For example, Write an email for my database promoting [your new product], then click on the generate button."
                      
                      />
                      </div>
                      {/* <GrammarlyEditorPlugin clientId="client_6p2htBMuCTkuR6ceUjonzU">
							<Emailstripo />
						</GrammarlyEditorPlugin> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-fixed">
            <div className="row">
              <div className="col-4">
                <div
                  className="text-center"
                  id="btnsavetemplate"
                  style={{ display: "none" }}
                >
                  <a
                    href="javascript:;"
                    onClick={this.gettemplatebtn}
                    className="common-save"
                  >
                    Save
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div className="text-center">
                  {/* <input
                    type="text"
                    id="emailinputprompt"
                    className="generateinput"
                    placeholder="Ask me anything about..."
                  /> */}
                  
                  <a
                    href="javascript:;"
                    className="common-save"
                    onClick={this.generateaicontent}
                  >
                    Generate
                  </a>  
                  <a
                    href="javascript:;"
                    className="common-save common-save-grey"
                    onClick={this.cleareditor}
                    style={{marginRight:"10px"}} 
                  >Clear&nbsp;
                    <img src={cleareditorbtn} width={20} height={20}></img>
                  </a>     
                  {/* <a href="javascript:;" onClick={this.testhtmlappend}>test</a>            */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Plagiarism
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" id="plagiarismmodalbody" dangerouslySetInnerHTML={this.state.plagiarismmodalbody}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Emails;
