import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faChevronLeft,
    faMicrophone,
    faInfo,
    faInfoCircle,
    faRotateRight,
    faShield,
    faCloudArrowUp,
    faCircleDown,
    faFile,
    faTimes,
    faCopy,
    faImage,
    faCheck,
    faCross,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import copy from 'copy-to-clipboard';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg, MasterId, SSOUser } from "../User";
import fileDownload from "js-file-download";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import loader from "../img/loader1.gif"; // "../img/sample.gif";
import loadernew from "../img/loader1.gif";
import Propertytype from "./propertytype";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import * as axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';
import { ToggleButton } from "react-bootstrap";

import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import {
    duplicateresultcontent,
    duplicateresultparacontenttemplate,
    ChatGPTemailcontent,
    truidraggable,
} from "../email/templateBase";

export const getAccessToken = () => Cookies.get('userInfo');

const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;


let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, updateLocation) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address", "place_id", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, updateLocation)
    );
}

function copyToClipboard() {
    copy(document.getElementById('divGeneratedContent').innerText,{format:"text/plain"});
    customAlertMessage('success', 'Copied!');
};

function copyToClipboardEdit() {
    if (document.getElementById('hrefEditContent').innerText == "Update") {
        document.getElementById('divGeneratedContent').contentEditable = "false";
        document.getElementById('hrefEditContent').innerText = "Edit";
    }
    else {
        document.getElementById('divGeneratedContent').contentEditable = "true";
        document.getElementById('hrefEditContent').innerText = "Update";
    }
}

function reloadpage() {
    window.location.reload();
}

function addCommaTo() {
    var txtArea = document.getElementById('txtArea');
    txtArea.value = txtArea.value.replace(/[^0-9\,]/, '');
    var num = getNumber(txtArea.value);
    if (num == 0) {
        txtArea.value = "";
    } else {
        txtArea.value = num.toLocaleString();
    }
}

function getNumber(_str) {
    var arr = _str.split('');
    var out = new Array();
    for (var cnt = 0; cnt < arr.length; cnt++) {
        if (isNaN(arr[cnt]) == false) {
            out.push(arr[cnt]);
        }
    }
    return Number(out.join(''));
}

function clearAllDataNew() {

}

function onTabClick() {
    // show content generation div
}

function removeClassFun(cname) {
    var divele = document.getElementsByClassName(cname);
    while (divele.length) {
        divele[0].classList.remove(cname);
    }
}

async function handlePlaceSelect(updateQuery, UpdateLocation) {
    //debugger;
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng()
    console.log(latitude + "," + longitude);
    UpdateLocation(latitude + "," + longitude);
    document.getElementById("hfLocationId").value = latitude + "," + longitude;
    //onAddressSelection(latitude,longitude);
}

function historygoback() {
    window.history.back();
};

function onAddressSelection(funlat, funlng) {
    //alert('inside address selection');
    var locationIdValue = document.getElementById("hfLocationId").value
    //console.log('address location id '+ locationIdValue);    
    showoverlay();
    setTimeout(() => {
        //generatemarketreport
    }, 3000);

}

function sleep(seconds) {
    var e = new Date().getTime() + (seconds * 1000);
    while (new Date().getTime() <= e) { }
}

function componentDidMount() {
    // load initial data
    document.addEventListener('copy', function(e) {
        const text_only = document.getSelection().toString();
        const clipdata = e.clipboardData || window.clipboardData;  
        clipdata.setData('text/plain', text_only);  
        e.preventDefault();
      });
};

function validatePropertyAddress() {
    var txtPropAddress = document.getElementById('txtPropAddress').value;
    if (txtPropAddress != undefined && txtPropAddress != "") {
        return true;
    }
    customAlertMessage('error', 'Please enter property address');
    return false;
}

function customAlertMessage(icon, message) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,//'success',
        title: message// 'Signed in successfully'
    })
}

function showoverlay() {
    document.getElementById('overlay').style.display = "block";
    $('body').addClass("loader");
}
function hideoverlay() {
    document.getElementById('overlay').style.display = "none";
    $('body').removeClass("loader");
}

function showoverlay4() {
    document.getElementById('overlayloader4').style.display = "block";
    $('body').addClass("loader");
}
function hideoverlay4() {
    document.getElementById('overlayloader4').style.display = "none";
    $('body').removeClass("loader");
}

function showoverlayprop() {
    document.getElementById('newloaderprop').style.display = "flex";
    document.getElementById('generatepropnew').style.display = "none";
    $('body').addClass("loader");
}
function hideoverlayprop() {
    document.getElementById('newloaderprop').style.display = "none";
    document.getElementById('generatepropnew').style.display = "flex";
    $('body').removeClass("loader");
}

function clearPropAddress() {
    document.getElementById('txtPropAddress').value = "";
}

var t = 0;
function typeWriter(datalist) {
    var speed = 10;
    // if(t == 0){
    //     document.getElementById("divGeneratedContent").innerHTML ="<p>";
    // }
    if (t < datalist.length) {
        var textLst = datalist.charAt(t);
        document.getElementById("divGeneratedContent").innerHTML += textLst === "\n" ? "<p></p>" : textLst;
        t++;
        document.getElementById("lblCcount").innerHTML = t;
        setTimeout(function () { typeWriter(datalist) }, speed);
        var elementsDiv = document.getElementById("divGeneratedContent");
        elementsDiv.scrollTop = elementsDiv.scrollHeight;
        //"<p>" + datalist[i] + "</p>";
    }
    if(t + 1 ==  datalist.length){
        console.log('inside final');
        document.getElementById("divOutputIcons").style.display = "block";
        // var prompttextlocal =  document.getElementById('divGeneratedContent').innerText;               
        // var tokenused = prompttextlocal.match(/(\w+)/g).length; //Math.ceil(newcharacters / 4);   
        // console.log("tokenused "+ tokenused);       
        // savegptresponse(0,tokenused);
    }
}


function toggletemplatediv() {
    $('#templatesavediv').toggle();
    $('#btnsavetemplate').toggle();
    if ($('#templatetogglelink').text() == "Save As Template"){
        $('#templatetogglelink').text("Hide template save");

    }       
    else
    {
        $('#templatetogglelink').text("Save As Template");
    }   
}

function gettexteleValue(objectid,prefixtext){
    var text = "";
    if(document.getElementById(objectid).value.trim() != ""){
       text = prefixtext +": "+ document.getElementById(objectid).value + " , ";
    }
    return text;
 }

function clearOutputD(){
    document.getElementById("divGeneratedContent").innerHTML = "";
    document.getElementById("divOutputIcons").style.display = "none";
    document.getElementById("spanCharCount").style.display = "none";
}

function generateaicontentchatgpt() {
    clearOutputD();
    document.getElementById("divGeneratedContent").innerHTML = "description start";
    try {
        document.getElementById("plagiarisminfo").style.display = "none";
    } catch (error) {

    }
    document.getElementById("lblCcount").innerHTML="";
    document.getElementById("divGeneratedContent").innerHTML = "";
    document.getElementById("spanCharCount").style.display = "none";
            document.getElementById("divPlagarism").style.display="none"; 
    showoverlay4();
    var prompt = "";
    var txtPropAddress = document.getElementById('txtPropAddress').value;

    // 1. Show Market comps and Transactions around <property address entered>
    // 2. Real Estate Report for  <property address entered>
    // 3. Show me property value changes around  <property address entered> over the past year
    // 4. Current and Recent Events around <property address entered>
    // 5. Macro-political and Economic Trends around <property address entered>
    // 6. Events and Other Current Situations Specific to the Property Location: <property address entered>

    if (txtPropAddress.trim() == "") {
        customAlertMessage("error", "Please enter property address.");
        hideoverlay4();
        return;
    }

    prompt = "Show Market comps and Transactions around " + txtPropAddress + ".";    

    prompt +=  " Real Estate Report for " + txtPropAddress + ".";  

    prompt +=  " Show me property value changes around " + txtPropAddress + ".";  

    prompt +=  " Include Current and Recent Events around " + txtPropAddress + ".";  

    prompt +=  " Macro-political and Economic Trends around " + txtPropAddress + ".";  

    prompt +=  " Events and Other Current Situations Specific to the Property Location " + txtPropAddress + ".";  

    prompt += " Show headline as Market report for "+ txtPropAddress + "."; 

    //prompt += " Show headings in bold."

    // for headline and call to action
    //prompt += " Also include headline note on header and call to action at the end of the description.";
    //
    
    //prompt += " Make description paragraph wise on each."
    
    var lengthcontroller = "2000";//this.getselectedcontentlength();
    var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
    var toneofvoice = gettexteleValue("toneofvoice", "Tone").trimEnd(",").trim();;    
    var keywords = gettexteleValue("keywordinput", "Keyword");
    var prompttext = ("Topic: " + prompt + toneofvoice + keywords);
    console.log(prompttext);
    var axios = require("axios");

    const FormData = require('form-data');
    var tokens = 4000;
    let data = new FormData();
    data.append('prompt', prompttext.trim());
    data.append('uid', (Role == 3 ? MasterId : UserID));
    data.append('cid', Companyid);
    data.append('token', tokens);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/posttextmessagenew',
        data : data
    };

    axios(config)
        .then(function (resp) {
            console.log(resp);
            var response = resp.data;
            console.log(response.Data);

            if (resp != null && response != "" && response.Data !="") {
                var datalist = response.Data.split('\n');

                var finalData = "";
                for (var i = 0; i < datalist.length; i++) {
                    if (datalist[i] != "") {
                        //finalData = finalData+"<p>" + datalist[i] + "</p>";
                        finalData = finalData + datalist[i] + "\n";
                    }
                }
                //self.appendhtmltoeditor("<p></p>"+ finalData);
                //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
                //setgeneratedcontent(datatoAdd);  
                hideoverlay();
                t = 0;
                finalData = finalData.replace("Headline: ", "").replace("headline: ", "").replace("Paragraph 1:", "").replace("Paragraph 2:", "").replace("Paragraph 3:", "").replace("Paragraph 4:", "").replace("Paragraph 5:", "").replace("Paragraph 6:", "");
                finalData = finalData.replace("Call to Action: ", "").replace("Headline note:", "").replace("Headline Note:", "").replace("1. ", "").replace("2. ", "").replace("3. ", "").replace("4. ", "").replace("5. ", "").replace("6. ", "");
                document.getElementById("spanCharCount").style.display = "block";
                document.getElementById("divPlagarism").style.display="block";
                typeWriter(finalData);
                //document.getElementById("lblCcount").innerHTML= finalData.length;
                //document.getElementById("divGeneratedContent").innerHTML=finalData;
                //document.getElementById("divOutputIcons").style.display = "block"; moved to type writer
                //window.scrollTo(0, document.body.scrollHeight);
                document.getElementById('divGeneratedContent').contentEditable = "true";                
            }
            hideoverlay4();
        })

        .catch(function (error) {
            console.log(error);
            hideoverlay4();          
            document.getElementById("spanCharCount").style.display = "none";
            document.getElementById("divPlagarism").style.display="none";  
            customAlertMessage(
                "error",
                "Something went wrong, please try again."
            );
        });
}

function generateaicontentOld() {
    clearOutputD();
    document.getElementById("divGeneratedContent").innerHTML = "description start";
    try {
        document.getElementById("plagiarisminfo").style.display = "none";
    } catch (error) {

    }
    document.getElementById("lblCcount").innerHTML="";
    document.getElementById("divGeneratedContent").innerHTML = "";
    document.getElementById("spanCharCount").style.display = "none";
            document.getElementById("divPlagarism").style.display="none"; 
    showoverlay4();
    var prompt = "";
    var txtPropAddress = document.getElementById('txtPropAddress').value;

    // 1. Show Market comps and Transactions around <property address entered>
    // 2. Real Estate Report for  <property address entered>
    // 3. Show me property value changes around  <property address entered> over the past year
    // 4. Current and Recent Events around <property address entered>
    // 5. Macro-political and Economic Trends around <property address entered>
    // 6. Events and Other Current Situations Specific to the Property Location: <property address entered>

    if (txtPropAddress.trim() == "") {
        customAlertMessage("error", "Please enter property address.");
        hideoverlay4();
        return;
    }

    prompt = "Show Market comps and Transactions around " + txtPropAddress + ".";    

    prompt +=  " Real Estate Report for " + txtPropAddress + ".";  

    prompt +=  " Also Show me property value changes around " + txtPropAddress + ".";  

    prompt +=  " Include Current and Recent Events around " + txtPropAddress + ".";  

    prompt +=  " Macro-political and Economic Trends around " + txtPropAddress + ".";  

    prompt +=  " Events and Other Current Situations Specific to the Property Location " + txtPropAddress + ".";  

    // for headline and call to action
    //prompt += " Also include headline note on header and call to action at the end of the description.";
    //
    
    prompt += " Make description paragraph wise on each."
    
    var lengthcontroller = "2000";//this.getselectedcontentlength();
    var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
    var toneofvoice = gettexteleValue("toneofvoice", "Tone").trimEnd(",").trim();;    
    var keywords = gettexteleValue("keywordinput", "Keyword");
    var prompttext = ("Topic: " + prompt + toneofvoice + keywords);
    console.log(prompttext);
    var axios = require("axios");
    var data = JSON.stringify({
        //model: "text-davinci-003",
        prompt: prompttext.trim(),
        temperature: 1,
        max_tokens: parseInt(lengthcontroller),
    });

    var config = {
        method: "post",
        url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
        headers: {
            "Content-Type": "application/json",
            "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
        },
        data: data,
    };

    axios(config)
        .then(function (resp) {
            //console.log(JSON.stringify(resp.data));
            var response = resp.data;
            //console.log(response.choices[0].text);

            if (response.choices != null && response.choices.length > 0) {
                var datalist = response.choices[0].text.split('\n');

                var finalData = "";
                for (var i = 0; i < datalist.length; i++) {
                    if (datalist[i] != "") {
                        //finalData = finalData+"<p>" + datalist[i] + "</p>";
                        finalData = finalData + datalist[i] + "\n";
                    }
                }
                //self.appendhtmltoeditor("<p></p>"+ finalData);
                //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
                //setgeneratedcontent(datatoAdd);  
                hideoverlay();
                t = 0;
                finalData = finalData.replace("Headline: ", "").replace("headline: ", "").replace("Paragraph 1:", "").replace("Paragraph 2:", "").replace("Paragraph 3:", "").replace("Paragraph 4:", "").replace("Paragraph 5:", "").replace("Paragraph 6:", "");
                finalData = finalData.replace("Call to Action: ", "").replace("Headline note:", "").replace("Headline Note:", "");
                document.getElementById("spanCharCount").style.display = "block";
                document.getElementById("divPlagarism").style.display="block";
                typeWriter(finalData);
                //document.getElementById("lblCcount").innerHTML= finalData.length;
                //document.getElementById("divGeneratedContent").innerHTML=finalData;
                document.getElementById("divOutputIcons").style.display = "block";
                //window.scrollTo(0, document.body.scrollHeight);
                document.getElementById('divGeneratedContent').contentEditable = "true";
                //update counter
                var prompttextlocal = prompttext ;//+ " " + document.getElementById('divGeneratedContent').innerText;               
                var tokenused = prompttextlocal.match(/(\w+)/g).length; //Math.ceil(newcharacters / 4);   
                console.log("tokenused "+ tokenused);       
                savegptresponse(0,tokenused);
                // counter end
            }
            hideoverlay4();
        })

        .catch(function (error) {
            console.log(error);
            hideoverlay4();          
            document.getElementById("spanCharCount").style.display = "none";
            document.getElementById("divPlagarism").style.display="none";  
            customAlertMessage(
                "error",
                "Something went wrong, please try again."
            );
        });
}

function proceedOnUpgrade(){
    document.getElementById('btncancelTTI').click();
    if (SSOUser) {      
      document.getElementById('lnkSSOUpgradeTTI').click();
    }
    else{
      document.getElementById('lnkNormalUpgradeTTI').click();
    }
  }

function checkCountersLimit(){
    var userI = getAccessToken();
    var decodedUser1 = decodeURIComponent(userI);
    var parsedRes1 = JSON.parse(decodedUser1); 
    var PlanType = parsedRes1.PlanType;  
    if (PlanType < 1) { // free trial
        const axios = require('axios');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedMarketReport',
          headers: { }
        };
      
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data != undefined) {
            if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
                var availblecounter = response.data.Data;
                if (availblecounter <= 0) {
                    document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
                    document.getElementById('hrefsubscription').click();
                    return;
                }
                else{
                    generateaicontentCounters();
                }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
        // end of free trial
    }else {
        generateaicontentCounters();
    }
}

function generateaicontent(){
    checkCountersLimit();
}

    function generateaicontentCounters() {
    clearOutputD();
    document.getElementById("divGeneratedContent").innerHTML = "description start";
    try {
        document.getElementById("plagiarisminfo").style.display = "none";
    } catch (error) {

    }
    document.getElementById("lblCcount").innerHTML="";
    document.getElementById("divGeneratedContent").innerHTML = "";
    document.getElementById("spanCharCount").style.display = "none";
    document.getElementById("divPlagarism").style.display="none";     

    showoverlay4();
    var prompt = "";
    var txtPropAddress = document.getElementById('txtPropAddress').value;

    // 1. Show Market comps and Transactions around <property address entered>
    // 2. Real Estate Report for  <property address entered>
    // 3. Show me property value changes around  <property address entered> over the past year
    // 4. Current and Recent Events around <property address entered>
    // 5. Macro-political and Economic Trends around <property address entered>
    // 6. Events and Other Current Situations Specific to the Property Location: <property address entered>

    if (txtPropAddress.trim() == "") {
        customAlertMessage("error", "Please enter property address.");
        hideoverlay4();
        return;
    }

    var headingLine = "";// "Market report for "+ txtPropAddress + ".\n";        

    prompt = "Show Market comps and Transactions around " + txtPropAddress + ".";     

    prompt +=  " Real Estate Report for " + txtPropAddress + ".";  

    prompt +=  " Show me property value changes around " + txtPropAddress + ".";         

    prompt +=  " Include Current and Recent Events around " + txtPropAddress + ".";  

    prompt +=  " Macro-political and Economic Trends around " + txtPropAddress + ".";  

    prompt +=  " Events and Other Current Situations Specific to the Property Location " + txtPropAddress + ".";  
    
    //prompt += " Make description paragraph wise."
    prompt += " Show headline as Market report for " + txtPropAddress + ".";  

    // for headline and call to action
    //prompt += " Also include headline note on header and call to action at the end of the description.";
    //    
    
    var lengthcontroller = "2000";//this.getselectedcontentlength();
    var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
    var toneofvoice = ""; // "Assistant: Make description paragraph wise."; // gettexteleValue("toneofvoice", "Assistant").trimEnd(",").trim();;    
    var keywords = "";//gettexteleValue("keywordinput", "Assistant");
    var prompttext = ( prompt + toneofvoice + keywords);
    console.log(prompttext);
    var axios = require("axios");

    const FormData = require('form-data');
    var tokens = 3000;
    let data = new FormData();
    data.append('prompt', prompttext.trim());
    data.append('uid', (Role == 3 ? MasterId : UserID));
    data.append('cid', Companyid);
    data.append('token', tokens);

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/GetGroqResponse',       // 'https://localhost:44316/api/AIUserManager/getLlamaText', // 
        data : data
    };

    axios(config)
        .then(function (resp) {
            console.log(resp);
            var response = resp.data;
            console.log(response.Data);
//debugger;
            if (resp != null && response != "" && response.Data !="" && response.Data !=" " && resp.Status != "404") {
                var datalist = (headingLine + response.Data ).split('\n');

                var finalData = headingLine + response.Data;
                // for (var i = 0; i < datalist.length; i++) {
                //     if (datalist[i] != "") {
                //         //finalData = finalData+"<p>" + datalist[i] + "</p>";
                //         finalData = finalData + datalist[i] + "\n";
                //     }
                // }
                //self.appendhtmltoeditor("<p></p>"+ finalData);
                //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
                //setgeneratedcontent(datatoAdd);  
                hideoverlay();
                if(finalData != ""){                
                    t = 0;
                    finalData = finalData.replace("Headline: ", "").replace("headline: ", "").replace("Paragraph 1:", "").replace("Paragraph 2:", "").replace("Paragraph 3:", "").replace("Paragraph 4:", "").replace("Paragraph 5:", "").replace("Paragraph 6:", "");
                    finalData = finalData.replace("Call to Action: ", "").replace("Headline note:", "").replace("Headline Note:", "").replace("1. ", "").replace("2. ", "").replace("3. ", "").replace("4. ", "").replace("5. ", "").replace("6. ", "").replace("Answer: ","");
                    document.getElementById("spanCharCount").style.display = "block";
                    document.getElementById("divPlagarism").style.display="block";
                    //typeWriter(finalData);
                    document.getElementById("lblCcount").innerHTML= finalData.length;
                    finalData = finalData.replace(/\n/g,"<p class='word-break:break-word;'></p>");
                    document.getElementById("divGeneratedContent").innerHTML=finalData;
                    document.getElementById("divOutputIcons").style.display = "block"; //moved to type writer
                    //window.scrollTo(0, document.body.scrollHeight);
                    document.getElementById('divGeneratedContent').contentEditable = "true";    
                }
                else{
                    customAlertMessage(
                        "error",
                        "Please try again by clicking on 'Generate' button."
                    );
                }            
            }
            else if(resp !=null && resp.data.Status == "404") {
                customAlertMessage(
                    "error",
                    "Please try again by clicking on 'Generate' button."
                );
            }
            hideoverlay4();
        })

        .catch(function (error) {
            console.log(error);
            hideoverlay4();          
            document.getElementById("spanCharCount").style.display = "none";
            document.getElementById("divPlagarism").style.display="none";  
            customAlertMessage(
                "error",
                "Something went wrong, please try again."
            );
        });
}


    function savegptresponse(result, totaltoken) {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("prompt", "prompt");
    data.append("userid", Role == 3 ? MasterId : UserID);
    data.append("companyid", Companyid);
    data.append("result", result);
    data.append("token", totaltoken);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        //console.log(error);
      });
  };


function MarketReportNew() {

    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    const [locationId, setLocationId] = useState("");
    const [Tonecount, setToneCount] = useState(0);
    const [wordcount, setWordCount] = useState(0);
    const [copySacpeAvailable, setDataprop] = useState(false);
    const [grammarlyKeyData, setgrammarlyKeyData] = useState("");
    const [keyworddisabled, setkeyworddisabled] = useState(false);
    const [tonedisabled, settonedisabled] = useState(false);
    const [plagiarismmodalbody,setplagiarismmodalbody] = useState({ __html: '' });

    useEffect(() => {
        componentDidMount();
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxpx25xmwMAYNW3Diz0TjL3N8CtTURTK0&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef, setLocationId)
        );
        checkcopyscape();
        var localgrammarlyKeyData = (Cookies.get('g_key') != undefined ? Cookies.get('g_key') : '');
        setgrammarlyKeyData(localgrammarlyKeyData);
    }, []);    

    function checkcopyscape() {
        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
        };

        axios.request(config)
            .then((response) => {
                if (response.data != null && response.data.Data != "") {
                    var resd = response.data.Data;
                    setDataprop(resd);
                }
            })
            .catch((error) => {
                console.log(error);
                setDataprop(false);
            });
    }

    function updateWordCount () {
        var text = document.getElementById('keywordinput').value;
        if(text.trim() == "")
        {
            setWordCount(0);
        }
        else{
            const words = text.trim().split(',');
            if(words.length > 50)
            {   
                customAlertMessage("warning","Keywords limit exceeded.");
                var trimmedwords = text.trim().split(',').slice(0, 3).join(',');
                document.getElementById('keywordinput').value = trimmedwords;
                setWordCount(3);
            }
            else{
                setWordCount(words.length);
            }           
        }        
    }
    
    function togglekeywords() {
      setkeyworddisabled(!keyworddisabled)
    }
    
    function toggletone(){
        settonedisabled(!tonedisabled);
    }
    
    function updatetonecount (e) {
        setToneCount(e.currentTarget.value.length)
    }

    function Checkplagiarism() {
        try {
            if (document.getElementById("divGeneratedContent").innerText == "") {
                customAlertMessage(
                    "warning",
                    "cannot check plagiarism for empty content."
                );
                return;
            }
        } catch (error) {
            customAlertMessage("error", "Error occured, Please try again.");
            return;
        }
    
        showoverlay4();
    
        var axios = require("axios");
        var FormData = require("form-data");
        var data = new FormData();
        data.append(
            "postdata",
            document.getElementById("divGeneratedContent").innerText.trim()
        );
        data.append("uid", (Role == 3 ? MasterId : UserID));
        data.append("companyid", Companyid);
    
        var config = {
            method: "post",
            url: `${process.env.REACT_APP_API_URL}` + "/api/ArtificialIntelligence/checkduplicatebyformdata",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
    
        axios(config)
            .then(function (resp) {
                var response = resp.data;
                if (response.Data != null) {
                    if (response.Data.count != null && response.Data.count === 0) {
                        customAlertMessage("success", "No plagiarism found");
                        document.getElementById("plagiarisminfo").style.display = "none";
                        setplagiarismmodalbody({ __html: '' });
                        // document.getElementById("plagiarismmodalbody").innerHTML = "";
                        // $(elem).find('.checkmark').show();
                        // $(elem).find('.ban').hide();
                        // $(elem).attr('tooltip', "No plagiarism found.");
                        // $(elem).attr('onclick', "return false;");
                    } else if (response.Message == "small content") {
                        customAlertMessage("info", response.Data);
                    } else {
                        document.getElementById("plagiarisminfo").innerText =
                            response.Data.count + " copies found";
                        document.getElementById("plagiarisminfo").style.display =
                            "inline-block";
                        var htmlnew = duplicateresultcontent;
                        htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
                        htmlnew = htmlnew.replace(
                            "[PHCSquerywords]",
                            response.Data.querywords
                        );
                        var duplicateresultparagraphlist = "";
                        for (var i = 0; i < response.Data.count; i++) {
                            var result = response.Data.result[i];
                            var duplicateresultparacontent =
                                duplicateresultparacontenttemplate;
                            duplicateresultparacontent = duplicateresultparacontent.replace(
                                "[PHCSurl]",
                                result.url
                            );
                            duplicateresultparacontent = duplicateresultparacontent.replace(
                                "[PHCSurltext]",
                                result.url
                            );
                            duplicateresultparacontent = duplicateresultparacontent.replace(
                                "[PHCStitle]",
                                result.title
                            );
                            duplicateresultparacontent = duplicateresultparacontent.replace(
                                "[PHCShtmlsnippet]",
                                result.textsnippet
                            );
                            duplicateresultparagraphlist =
                                duplicateresultparagraphlist + duplicateresultparacontent;
                        }
                        htmlnew = htmlnew.replace(
                            "[PHCSparagraphlist]",
                            duplicateresultparagraphlist
                        );
                        setplagiarismmodalbody({ __html:htmlnew});
                        // document.getElementById("plagiarismmodalbody").innerHTML = htmlnew;
                        customAlertMessage("error", "Plagiarism found");
                    }
                } else {
                    customAlertMessage("error", "Error occured, Please try again."); // 'success','failure','info'
                }
                hideoverlay4();
            })
            .catch(function (error) {
                //console.log(error);
                customAlertMessage("error", "Something went wrong, Try Again.");
                hideoverlay4();
            });
    };

    return (
        <div className="main_content_iner overly_inner ">
            <div className="container-fluid p-0 ">
                <div className="row">
                    <div className="col-12">
                        <div className="title-card1">
                            <span className="float-start">
                                <h4 style={{margin:"5px 0"}}>
                                    <span
                                        style={{ cursor: "pointer" }}
                                        onClick={historygoback}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        Market Report
                                    </span>
                                </h4>
                            </span>
                        </div>
                        <input type="hidden" id="hfCurrentTab" value="1"></input>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-12" style={{ position: "relative" }}>
                                <div id="overlay" className="email-overlay" style={{ minHeight: "50vh" }}>
                                    <div id="loading">
                                        <h2 id="loaderheading">Searching property...</h2>
                                        <img src={loader} style={{ maxWidth: "75px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row">

                            <div className="col-4">
                                <div className="lefside-bar">
                                    <div className="row" style={{paddingTop:"15px"}}>
                                        <div className="col-12 mb-30">
                                            <div className="row" style={{ position: "relative" }}>
                                                <div className="col-12 label-info">
                                                    <label>Property Address</label>
                                                </div>
                                                <div className="col-12 search-location-input">
                                                    <input id="txtPropAddress"
                                                        ref={autoCompleteRef} className="common-input"
                                                        onChange={event => setQuery(event.target.value)}
                                                        placeholder="Enter your address"
                                                        value={query}
                                                    />
                                                    <FontAwesomeIcon className="clearProp" onClick={() => clearPropAddress()} icon={faXmark} />
                                                    <input id="hfLocationId" value={locationId} type="hidden"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30" style={{paddingTop:"15px",display:"none"}}>
                                            <div className="row">
                                                <div className="col-9 label-info">
                                                    <label>Tone of Voice<span tooltip="Try using different combinations of words like witty, friendly, disappointed, polite, creative, professional or add a well-known personality such as Adelle." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                                </div>
                                                <div className="col-12">
                                                    <input id="toneofvoice" type="text" maxLength={60} className="common-input" onKeyUp={updatetonecount} disabled={(tonedisabled) ? "disabled" : ""} placeholder="Professional, Friendly" />
                                                    <span className="character-limit">{Tonecount}/60</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30" style={{paddingTop:"15px"}}>
                                            <div className="row">
                                                <div className="col-9 label-info">
                                                    <label>Keywords<span tooltip="Write keywords separated by commas." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                                </div>
                                                <div className="col-12">

                                                    <input id="keywordinput" type="text" className="common-input" onChange={updateWordCount} disabled={(keyworddisabled) ? "disabled" : ""} placeholder="Behavior, Vision" />

                                                    <span className="character-limit">{wordcount}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30">
                                            <div className="row">
                                            </div>
                                        </div>
                                        <div id="templatesavediv" style={{ display: "none" }}>
                                            <div className="col-12 mb-30">
                                                <div className="row">
                                                    <div className="col-9 label-info">
                                                        <label>Title<span tooltip="Text" flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                                    </div>
                                                    <div className="col-12">

                                                        <input type="text" className="common-input" />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mb-30">
                                                <div className="row">
                                                    <div className="col-9 label-info">
                                                        <label>Description<span tooltip="Text" flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                                    </div>
                                                    <div className="col-12">

                                                        <input type="text" className="common-input" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-8">
                                <div className="row">
                                    {/* Tab desc Content start */}
                                    <div className="col-12" id="divdescription" style={{ display: "block" }}>

                                        {/* <div className="row" id="newloaderprop" style={{display:"none"}}>
                                            <div className="col-12">
                                                <div className="rotating-text-wrapper-new">
                                                    <center></center><img src={loadernew} className="loadernewprop"/>
                                                    <div class="rotating-text-wrapper">                                                
                                                        <h2>Hold tight, chocolatechips.ai is writing the description for you!</h2>
                                                        <h2>Adding a powerful headline....</h2>
                                                        <h2>Almost done....</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="row" id="generatepropnew">
                                            
                                            <div className="col-12">
                                                <h4 className="prop-top-title">Generated Report</h4>
                                            </div>
                                            <div className="col-12 mb-30">
                                                <div className="row" id="divPlagarism" style={{display:"none"}}>
                                                    <div className="col-12 text-end chech-right" style={{ marginBottom: "10px" }}>
                                                        {copySacpeAvailable == true ?
                                                            <span onClick={Checkplagiarism} tooltip="Check plagiarism" flow="left">
                                                                <FontAwesomeIcon icon={faShield} style={{ fontSize: '24px', cursor: "pointer", color: '#9333ea' }} />
                                                            </span> : null}
                                                        <span
                                                            id="plagiarisminfo"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            className="text-count"
                                                            style={{
                                                                backgroundColor: "#f44336",
                                                                color: "#fff",
                                                                display: "none",
                                                            }}
                                                        >
                                                            5 copies found
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">

                                                        <div className="output-wrap slider" style={{ position: "relative" }}>
                                                            <div id="overlayloader4" className="email-overlay" style={{ display: "none" }}>
                                                                <div id="loading">
                                                                    <h2 id="loaderheading">...</h2>
                                                                    <img src={loader} style={{maxWidth:"75px"}} />
                                                                </div>
                                                            </div>
                                                            <span className="float-end" id="spanCharCount" style={{ fontSize: "13px", display: "none" }}>Character Count: <b id="lblCcount"></b></span>
                                                            {(grammarlyKeyData != "" ?
                                                                <GrammarlyEditorPlugin clientId={Cookies.get('g_key')}>
                                                                    <div id="divGeneratedContent" className='out-content market-scroll'>

                                                                    </div>
                                                                </GrammarlyEditorPlugin>
                                                                :
                                                                <div id="divGeneratedContent" className='out-content market-scroll'>

                                                                </div>
                                                            )}
                                                            <div id="divOutputIcons" style={{ display: "none", position: "relative",paddingBottom:"50px",zIndex:"39999" }} className="out-icons">
                                                                <span className="float-end">
                                                                    <a href="javascript:;" onClick={() => copyToClipboard()} className="common-border-btn" style={{display:"inline-block"}}>Copy</a>
                                                                    {/* <a href="javascript:;" id="hrefEditContent" onClick={() => copyToClipboardEdit()} className="common-border-btn">Update</a> */}
                                                                    <a href="javascript:;" onClick={() => generateaicontent()} className="common-border-btn">Rewrite</a>
                                                                    <a href="javascript:;" onClick={() => clearOutputD()} className="common-border-btn">Clear</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab desc Content end */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* generate button start */}
                <div className='bottom-fixed'>
                    <div className="row">
                        <div className="col-4">
                                <div className="text-center">
                                <a href="javascript:;" className="common-save" onClick={generateaicontent}>Generate</a>
                                </div>
                        </div>
                    </div>
                </div>
                {/* generate button end */}
            </div>

            {/* plagarism start*/}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                Plagiarism
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body" id="plagiarismmodalbody" dangerouslySetInnerHTML={plagiarismmodalbody} ></div>
                    </div>
                </div>
            </div>
            {/* plagarism end */}

            <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
 <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Upgrade Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                <div className="col-12">
                  <p id="psubtext"></p>
                  <div className="row" style={{marginBottom:"10px",display:"none"}}>
                      <div className='col-3'>
                          <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                          <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                      </div>
                  </div>
                  {/* {SSOUser == true ?
                  <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                 <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>} */}
                </div>
              </div>
              <div className="modal-footer">     
                <button type="button" style={{display:"block"}} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>                                  
                <a href='javascript:;' id='btnUpgrade' onClick={proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
              </div>
          </div>
      </div>
      </div>

        </div>
    );
}

export default MarketReportNew;
