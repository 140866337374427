import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name} from "../User";
import { faCaretSquareRight, faCopy, faDownload, faEllipsis, faEllipsisH, faMailBulk, faPencilAlt, faPlayCircle, faTrash, faTrashAlt, faVideo } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
import $ from 'jquery';

const AiVideosNew = () => {
    const [aivideos, setAiVideos] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    useEffect(() => {
       const getAvatars = () =>{
        showLoader();  
        var data = new FormData();
        data.append("UserId", UserID);
        var config = {
            method: 'post',
            //https://cinnamon.aelo.ai/api/RecipeManager
            "url": "https://cinnamon.aelo.ai/api/RecipeManager/GetCCAvatars",
            // headers: {
            //    'Content-Type': 'application/json'
            // },
            maxBodyLength: Infinity,
            data: data
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                //var videosList = document.getElementById('divVideosList');
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var parsedResNew = response.Data;
                        console.log(parsedResNew);
                        if (parsedResNew !=null && parsedResNew.length > 0) {
                            for (let index = 0; index < parsedResNew.length; index++) {
                                // var avtarVideoId = parsedResNew[index].Id;
                                // var title = parsedResNew[index].Title;
                                // var statusId = parsedResNew[index].StatusId;
                                // var createdDate = parsedResNew[index].createdOn;
                                // var videoUrl = parsedResNew[index].VideoUrl;
                                // var videoThumnUrl = parsedResNew[index].VideoThumbUrl;
                                // var draftSceneData = parsedResNew[index].draftScene;
                                //Select Id,Title,UserId,StatusId,createdOn,UpdatedOn,VideoUrl,videoId,VideoThumbUrl,draftScene
                            }
                            setAiVideos(parsedResNew);
                            hideLoader();
                        }
                        else{
                            //videosList.innerText="No result Found"
                            hideLoader();
                        }                        
                    }
                    else {
                        console.log('else error')
                        //videosList.innerText="No result Found";
                        hideLoader();
                    }
                }
                else {
                    console.log('error '+response.Message);
                    //videosList.innerText="No result Found"
                    hideLoader();
                }
                hideLoader();
            })
            .catch(function (error) {
                hideLoader();
            });
      }

        getAvatars();
    }, []);

    

    const forceDownload = (url,fileName) =>{
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        }
        xhr.send();
        return false;
    }

    const myFunction = (id)=>{
        var copyText = document.getElementById("myInput"+id);

        // Select the text field
        copyText.select();
        copyText.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(copyText.value);
        //alert('Copied!');
        return false;
    }
    const deleteFunction = (avtarId)=>{
        var isConfirm = window.confirm("Are you sure you want to delete it?")
        if (isConfirm) {
            newDelete(avtarId);
        }
    }

    const newDelete=(id)=>{
        showLoader();  
        var data = new FormData();
        data.append("Id", id);
        var config = {
            method: 'post',
            "url": "https://cinnamon.aelo.ai/api/RecipeManager/DeleteAIVideoDraft",
            // headers: {
            //    'Content-Type': 'application/json'
            // },
            maxBodyLength: Infinity,
            data: data
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                //var videosList = document.getElementById('divVideosList');
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //var parsedResNew = response.Data;
                        getUserAvatars()
                        customAlertMessage('success','Deleted Successfully');                     
                    }
                    else {
                        console.log('else error')
                        //videosList.innerText="No result Found";
                        //hideLoader();
                    }
                }
                else {
                    console.log('error '+response.Message);
                    //videosList.innerText="No result Found"                    
                    //hideLoader();
                    customAlertMessage('error','Something went wrong.');       
                }
                hideLoader();
            })
            .catch(function (error) {
                hideLoader();
                customAlertMessage('error','Something went wrong.');       
            });
    }

    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };

    const playVideoData=(videoUrl)=>{
        $("#videoAvatarSrc").html('<source src="' + videoUrl + '" type="video/mp4"></source>');
        document.getElementById("videoAvatarSrc").load();
        document.getElementById("videoAvatarSrc").play();
        $('#hrefShowPlay')[0].click();
    }

    const getUserAvatars = () =>{
        showLoader()
        
        var data = new FormData();
        data.append("UserId", UserID);
        var config = {
            method: 'post',
            //https://cinnamon.aelo.ai/api/RecipeManager
            "url": "https://cinnamon.aelo.ai/api/RecipeManager/GetCCAvatars",
            // headers: {
            //    'Content-Type': 'application/json'
            // },
            maxBodyLength: Infinity,
            data: data
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                //var videosList = document.getElementById('divVideosList');
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var parsedResNew = response.Data;
                        console.log(parsedResNew);
                        if (parsedResNew !=null && parsedResNew.length > 0) {                            
                            setAiVideos(parsedResNew);
                        }
                        else{
                            //videosList.innerText="No result Found"
                            //return [];
                        }                        
                    }
                    else {
                        console.log('else error')
                        //videosList.innerText="No result Found";
                        //return [];
                    }
                    hideLoader();
                }
                else {
                    console.log('error '+response.Message);
                    //videosList.innerText="No result Found"
                    //return [];
                }
                hideLoader();
            })
            .catch(function (error) {
                hideLoader();
            });
      }

    
        return (
            <>            
                            {/* <div className='row headers'>
Id,Title,UserId,StatusId,createdOn,UpdatedOn,VideoUrl,videoId,VideoThumbUrl,draftScene
                            </div> */}
                            {/* <div className='row' > */}

                            {(aivideos !=null && aivideos.length > 0) ? aivideos.map((aiVideo,indexer) => (
   
                                <div className='col-md-3' id='divVideosList'>
                                     {(aiVideo.StatusId == 2) ?                                     
                                        <div className='border-box-create'>
                                            <div className='saved-image-box' style={{position:"relative"}}>
                                                <img src={aiVideo.VideoThumbUrl == "" ? "https://cinnamonapp.s3.us-west-1.amazonaws.com/Images/Novideo.png": aiVideo.VideoThumbUrl} className='created-avatar-image'></img>
                                                <div className='dropdown customdropdown-avatar dropstart'>
                                                    <button className='btn btn-secondary dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
                                                        <FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>
                                                    </button><input type='text' style={{display:"none"}} value={aiVideo.VideoUrl} id={"myInput" + indexer }></input>
                                                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                                                    {(aiVideo.draftScene != "" ? 
                                                        <li><a className='dropdown-item' href={"https://aivideos.chocolatechips.ai/?CC=1&New=1&Scene=" + aiVideo.draftScene + "&UserId="+ UserID + "&EditId=" + aiVideo.DraftId+ "&Title=" + aiVideo.Title +"&nid="+ Math.random().toString(16).slice(2) }><FontAwesomeIcon icon={faCaretSquareRight}></FontAwesomeIcon>Create New</a></li> : ""
                                                    )}
                                                    <li><a className='dropdown-item' href='javascript:;' onClick={()=>forceDownload(aiVideo.VideoUrl,aiVideo.Title)}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>Download</a></li>
                                                    <li><a className='dropdown-item' href='javascript:;' onClick={()=>myFunction(indexer)}><FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>Copy Video URL</a></li>
                                                        <li><hr className='dropdown-divider'></hr> </li>
                                                        <li><a className='dropdown-item' href='javascript:;' onClick={()=>deleteFunction(aiVideo.Id)}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> Delete</a></li>
                                                    </ul>
                                                </div>
                                                <a href='javascript:;' onClick={()=>playVideoData(aiVideo.VideoUrl)} className='playbutton-new'><FontAwesomeIcon icon={faPlayCircle}></FontAwesomeIcon></a>
                                            </div>
                                            <div className='icon-text'>
                                                <div>
                                                    <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                                </div>
                                                <div>
                                                    <h4>{aiVideo.Title}</h4>
                                                    <p>{moment.utc(aiVideo.createdOn).local().format('MM-DD-YYYY h:mm A')}</p>
                                                </div>
                                            </div>                                         
                                        </div>
                                
                                    :
                                    (aiVideo.StatusId == 1)?
                                    <div>Processing...</div>
                                    :
                                    (aiVideo.StatusId == 4)?                                    
                                    <div className='border-box-create'>
                                        <div className='saved-image-box' style={{position:"relative"}}><label className='label-draft'>Draft</label>
                                        <img src={aiVideo.VideoThumbUrl == "" ? "https://cinnamonapp.s3.us-west-1.amazonaws.com/Images/Novideo.png": aiVideo.VideoThumbUrl} className='created-avatar-image'></img>
                                        <div className='dropdown customdropdown-avatar dropstart'>
 <button className='btn btn-secondary dropdown-toggle' type='button' id='dropdownMenuButton1' data-bs-toggle='dropdown' aria-expanded='false'>
		                                            <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
	                                            </button>
	                                            <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>

                                                <li><a className='dropdown-item' href={"https://aivideos.chocolatechips.ai/?CC=1&Scene=" + aiVideo.draftScene + "&UserId="+ UserID + "&EditId=" + aiVideo.Id+ "&Title=" + aiVideo.Title +"&nid="+ Math.random().toString(16).slice(2) }><FontAwesomeIcon icon={faPencilAlt}></FontAwesomeIcon>Edit</a></li>
                                                <li><hr className='dropdown-divider'></hr></li>
                                                <li><a className='dropdown-item' href='javascript:;' onClick={()=>deleteFunction(aiVideo.Id)}><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon> Delete</a></li>
                                                </ul>
                                            </div></div>
                                            <div className='icon-text'>
                                            <div>
                                                <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                            </div>
                                            <div>
                                                <h4>{aiVideo.Title}</h4>
                                                <p>{moment.utc(aiVideo.createdOn).local().format('MM-DD-YYYY h:mm A')}</p>
                                            </div>
                                        </div> 
                                    </div>                            
                                    :                                    
                                    <div className='border-box-create'>
                                        <div className='saved-image-box' style={{position:"relative"}}>
                                           <label className="label-failed">Failed to process.</label>
                                        </div>
                                        <div className='icon-text'>
                                            <div>
                                                <FontAwesomeIcon icon={faVideo}></FontAwesomeIcon>
                                            </div>
                                            <div>
                                            <h4>{aiVideo.Title}</h4>
                                            <p>{moment.utc(aiVideo.createdOn).local().format('MM-DD-YYYY h:mm A')}</p>
                                            </div>
                                        </div>  
                                    </div>
                                     }
                                </div>
                                )
                                ): 
                                <div style={{display:"none"}} className='col-md-12'>
                                    NO records found.
                                </div>
                                }
                           
        
            </>        
    );
};
export default AiVideosNew;