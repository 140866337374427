import { Outlet, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=6';
import '../css/colors/default.css?v=5';
// import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faBarsStaggered, faMailReply, faEnvelope, faUpload, faImages, faVideoCamera, faChalkboard } from '@fortawesome/free-solid-svg-icons'

import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap.bundle';
import React from "react";
import loader from "../img/sample.gif";
import { faFacebook, faInstagram, faPinterest, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "../index"
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logosmall from "../img/logosmall.png";
import {UserID,Name,Companyid,Role,Rolename, ProfileImg,PlanLevel, SSOUser, Features} from "../User";
import TagManager from 'react-gtm-module';
import sherlockaiicon from '../img/sherlockicon.png';
import sherlockuploadicon from '../img/uploadimage.png';
import sherlocksetupicon from '../img/sherlockset.png';
import sherlockacticon from '../img/sherlockactivity.png';

export const getAccessToken = () => Cookies.get('userInfo');
const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GA}`
}
const TRACKING_ID = `${process.env.REACT_APP_GA}`

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      SherlockaiF: (Features.SherlockAI == undefined ? false : Features.SherlockAI),
      DashboardF: (Features.Dashboard == undefined ? false : Features.Dashboard),
      LiveTrackF: (Features.LiveTrack == undefined ? false : Features.LiveTrack),
      ActivityF: (Features.Activity == undefined ? false : Features.Activity),
    };
    // this.state = {
    //   DashboardF: (Features.Dashboard == undefined ? false : Features.Dashboard),
    // };
  }
  componentDidMount(){
    this.documentinit();    
    // ReactGA.initialize(TRACKING_ID);
    TagManager.initialize(tagManagerArgs);    
  }
  documentinit = () => {
    $(".open_miniSide").on("click",function () {
      $(".sidebar").toggleClass("mini_sidebar");
      $(".main_content ").toggleClass("full_main_content");
      $(".footer_part ").toggleClass("full_footer");
      //window.getSelection().anchorNode.wholeText => plagiarism
      console.log('inside doc init');
      // console.log(this.state.DashboardF);
      // console.log(this.state.SherlockaiF);
      // if(this.state.DashboardF == false && this.state.SherlockaiF == true){
      //   document.getElementById('lnkToSherlockAI').click();
      // }
  });

  try {
    this.toggleUsersTeams();
  } catch (error) {
    
  }  
  try {
    if(Role == "3"){
      document.getElementById('13').style.display ="none";
   }
  } catch (error) {
    
  }
  //this.checkSubStatus();
  }

  checkSubStatus = () =>{
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser); 
    var subState = parsedRes.SubscriptionState;   
    var pId = parsedRes.PaymentCustomerId; 
    var pType = parsedRes.PlanType  
    if (pId == "" && subState > 0) {      
      switch (subState) {
        case 1:
          //
          document.getElementById('upgradeModelText').innerHTML="You have cancelled the subscription. Please upgrade the subscription plan to start using chocolatechips.ai.";
          break;
        case 2:
          if (pType < 1) {
            document.getElementById('upgradeModelText').innerHTML="Your trial period is over. Please upgrade to a paid subscription.";
          }
          else{
            document.getElementById('upgradeModelText').innerHTML="Your subscription had expired. Please upgrade the subscription plan to start using chocolatechips.ai";
          }                    
          break;    
        default:
          break;
      }
      this.showUpgradePrompt();
    }
    
  }

  toggleUsersTeams = () =>{
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser); 
    var pLevel = parsedRes.PlanLevel;           
    if(Role == "1"){
        document.getElementById('lnkManageUsers').style.display ="block";
        document.getElementById('lnkManageTeams').style.display ="block";
        document.getElementById('lnkManagePayment').style.display ="block";
        if (pLevel > 1) {
          document.getElementById('lnkintegration').style.display = "block";
          document.getElementById('lnkintegrationP').style.display = "none";
        }      
        else{
          document.getElementById('lnkintegration').style.display = "none";
          document.getElementById('lnkintegrationP').style.display = "block";
        }
    }
    else if(Role == "2"){      
      document.getElementById('lnkManagePayment').style.display ="block";
      document.getElementById('lnkManageUsers').style.display ="none";
      document.getElementById('lnkManageTeams').style.display ="none";

      document.getElementById('lnkManageUsersP').style.display ="block";
      document.getElementById('lnkManageTeamsP').style.display ="block";
      if (pLevel > 1) {
        document.getElementById('lnkintegration').style.display = "block";
        document.getElementById('lnkintegrationP').style.display = "none";
      }      
      else{
        document.getElementById('lnkintegration').style.display = "none";
        document.getElementById('lnkintegrationP').style.display = "block";
      }  
    }    
    else{
        document.getElementById('lnkManageUsers').style.display ="none";
        document.getElementById('lnkManageTeams').style.display ="none";
        document.getElementById('lnkManagePayment').style.display ="none";
        document.getElementById('lnkintegration').style.display = "none";
    }    
    // when sherlock enabled disable below
    //document.getElementById('lnkManageUsers').style.display ="none";
    //document.getElementById('lnkManageTeams').style.display ="none";
    //document.getElementById('lnkManagePayment').style.display ="none";
    //document.getElementById('lnkintegration').style.display = "none";
  }

  handlemenuClick = (event) => {
    this.setState({active: event.target.closest("li").id});    
  }
  logout = () =>{
    Cookies.remove("userInfo");
    Cookies.remove("refresh_token");
    Cookies.remove("g_key");
    window.location.href = window.location.origin;
  }

  showUpgradePrompt = () =>{
    document.getElementById('upgradeModelText').innerHTML="Please upgrade your plan to higher level to use this feature.";
    document.getElementById('hrefSubLayout').click();
  }

  showUpgradePromptTeam = () =>{
    document.getElementById('upgradeModelText').innerHTML="Please upgrade your plan to Team plan to use this feature.";
    document.getElementById('hrefSubLayout').click();
  }

  proceedOnUpgrade = () =>{
    document.getElementById('btncancelUpgradeLayout').click();
    if (SSOUser) {      
      document.getElementById('lnkSSOUpgrade').click();
    }
    else{
      document.getElementById('lnkNormalUpgrade').click();
    }
  }

  render(){
  return (
    <>
    <div className="crm_body_bg">
    <nav className="sidebar no-print">
      <div className="logo d-flex justify-content-center">
        {/* <a className="large_logo" style={{textDecoration:"none"}}><b style={{fontSize:"24px",color:"#000"}}>AI Assistant</b></a>
        <a className="small_logo" style={{textDecoration:"none"}}><b style={{fontSize:"24px",color:"#000"}}>AI</b></a> */}
        <Link to="/" className="large_logo">
            <img src={logo} />
        </Link>
        {/* <a className="large_logo"><img src={logo} /></a> */}
        <Link to="/" className="small_logo" style={{textAlign:"center"}}>
            <img src={logosmall} style={{width:"100%"}}/>
        </Link>
        {/* <a className="small_logo" style={{textAlign:"center"}}><img src={logosmall} style={{width:"50%"}}/></a> */}

        <div className="sidebar_close_icon d-lg-none">        
          <i className="ti-close"></i>
        </div>
      </div>
      <ul id="sidebar_menu">
      {
        this.state.DashboardF == true ?
        <>
        <li id="0" className={this.state.active == "0" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/dashboard">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faHouse} />
            </div>        
            <div className="nav_title">
              <span>Dashboard </span>
            </div>  
          </Link>
          </li>
          </>
           : null }
          <li id="1" style={{display:"none"}} className={this.state.active == "1" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/emailtemplate">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="nav_title">
              <span>Email </span>
            </div>
          </Link>
          </li>
          <li id="2" style={{display:"none"}} className={this.state.active == "2" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/texttoimage">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faImages} />
            </div>
            <div className="nav_title">
              <span>Text to Image </span>
            </div>
          </Link>
          </li>
          <li id="3" style={{display:"none"}} className={this.state.active == "3" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/uploadimage">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faUpload} />
            </div>
            <div className="nav_title">
              <span>Upload Image </span>
            </div>
          </Link>
          </li>

          <li id="4" style={{display:"none"}} className={this.state.active == "4" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/facebook">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faFacebook} />
            </div>
            <div className="nav_title">
              <span>Facebook</span>
            </div>
          </Link>
          </li>

          <li id="5" style={{display:"none"}} className={this.state.active == "5" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/instagram">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faInstagram} />
            </div>
            <div className="nav_title">
              <span>Instagram</span>
            </div>
          </Link>
          </li>

          <li id="6" style={{display:"none"}} className={this.state.active == "6" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/twitter">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faTwitter} />
            </div>
            <div className="nav_title">
              <span>Twitter</span>
            </div>
          </Link>
          </li>

          <li id="7" style={{display:"none"}} className={this.state.active == "7" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/tiktok">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faTiktok} />
            </div>
            <div className="nav_title">
              <span>TikTok</span>
            </div>
          </Link>
          </li>

          <li id="8" style={{display:"none"}} className={this.state.active == "8" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/pinterest">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faPinterest} />
            </div>
            <div className="nav_title">
              <span>Pinterest</span>
            </div>
          </Link>
          </li>


          <li style={{display:"none"}} id="9" className={this.state.active == "9" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/aiavatar">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faVideoCamera} />
            </div>
            <div className="nav_title">
              <span>AI Avatar </span>
            </div>
          </Link>
        </li>
        <li id="10" style={{display:"none"}} className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/imagechat">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faChalkboard} />
            </div>
            <div className="nav_title">
              <span>Image Chat<sup style={{color:"#599bdb",fontSize:"12px",marginLeft:"5px"}}>Beta</sup></span>
            </div>
          </Link>
        </li>

        {
        this.state.SherlockaiF == true ?
                    <>
        <li id="11" className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link id="lnkToSherlockAI" to="/sherlock">
            <div className="nav_icon_small sherlockmenuiconplace">
            <center><img src={sherlockaiicon} /></center>
            </div>
            <div className="nav_title">
              <span>Sherlock AI</span>
            </div>
          </Link>
        </li>        
</> 
: null}
{
  this.state.LiveTrackF == true ?
  <li id="12" className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
    <Link to="livetrack">
      <div className="nav_icon_small sherlockmenuiconplace">
        <center><img src={sherlockuploadicon} /></center>
      </div>
      <div className="nav_title">
        <span>Live Track</span>
      </div>
    </Link>
  </li>
: null        
}

{this.state.SherlockaiF == true ?
        <li id="13" className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/sherlocksetup">
            <div className="nav_icon_small sherlockmenuiconplace">
            <center><img src={sherlocksetupicon} /></center>
            </div>
            <div className="nav_title">
              <span>Setup</span>
            </div>
          </Link>
        </li>
        : null        
        }

{
        this.state.ActivityF == true ?
        <li id="14" style={{display:"block"}} className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/sherlockactivity">
            <div className="nav_icon_small sherlockmenuiconplace">
            <center><img src={sherlockacticon} /></center>
            </div>
            <div className="nav_title">
              <span>Activity</span>
            </div>
          </Link>
        </li>
        : null        
        }
      </ul>
      <div className="footer_part">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer_iner text-center">
                <p>Copyright © planetRE.<br/> All Rights Reserved.</p>
                <p style={{padding:"0"}}><a href="https://www.chocolatechips.ai/terms-use.html" target="blank">Terms of Use</a></p>
                <p style={{padding:"0"}}><a href="https://www.planetre.com/privacy-policy.html" target="blank">Privacy Policy</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <section className="main_content dashboard_part large_header_bg">
      <div className="container-fluid g-0 no-print">
        <div className="row">
          <div className="col-lg-12 p-0 ">
            <div className="header_iner d-flex justify-content-between align-items-center">
              <div className="sidebar_icon d-lg-none">
                <i className="ti-menu"></i>
              </div>
              <div className="line_icon open_miniSide d-none d-lg-block" style={{cursor:"pointer"}}>                
                    <FontAwesomeIcon icon={faBarsStaggered} />
              </div>
              <div className="header_right d-flex justify-content-between align-items-center">
                <div className="profile_info">
                  <img src={ProfileImg} alt="#" />
                  <div className="profile_info_iner">
                    <div className="profile_author_name">
                      <p>{Rolename}</p>
                      <h5>{Name}</h5>
                    </div>
                    <div className="profile_info_details">
                      <Link to="/profile">
                        Profile
                      </Link>                     
{ 
this.state.SherlockaiF == false || this.state.DashboardF == true ?
<>

                      <Link id="lnkintegration"  to="/integration">
                        Integrations
                      </Link>      
                      <Link id="lnkManagePayment" to="/paymentupdate">
                        Manage Payment
                      </Link>                
                      <Link id="lnkManageUsers" to="/manageusers">
                        Manage Users
                      </Link>
                      <Link id="lnkManageTeams" to="/manageteams">
                        Manage Teams
                      </Link>
                      <a href="javascript:;" id="lnkintegrationP" style={{display:"none"}} onClick={this.showUpgradePrompt}>Integrations </a>
                      <a href="javascript:;" id="lnkManageUsersP" style={{display:"none"}} onClick={this.showUpgradePromptTeam}>Manage Users </a>
                      <a href="javascript:;" id="lnkManageTeamsP" style={{display:"none"}} onClick={this.showUpgradePromptTeam}>Manage Team </a>                      
</>
        : null        
        }
                      <a href="javascript:;" onClick={this.logout}>Log Out </a>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
      
      
    </section>
    <a href='javascript:;' id='hrefSubLayout' data-bs-toggle="modal"
                          data-bs-target="#upgradeModelLayout" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
    </div>
    {/* price model start */}
    <div className="modal fade" id="upgradeModelLayout" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id=""><b>Upgrade Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p id="upgradeModelText" style={{color:"#000"}}>Please upgrade your plan to higher level to use this feature.</p>
                                    </div>
                                    <div className='col-12' style={{display:"none"}}>
                                        <div className="row" style={{marginBottom:"10px"}}>
                                            <div className='col-3'>
                                                <Link id="lnkNormalUpgrade" to="/upgradeplan">Upgrade</Link>
                                                <Link id="lnkSSOUpgrade" to="/upgradeplansso">Upgrade</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btncancelUpgradeLayout' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
                        </div>
                    </div>
                </div>                
            </div>
            {/* price model end */}
    </>
  )
    }
};

export default Layout;