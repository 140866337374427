import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faMicrophone,faInfo,faInfoCircle, faRotateRight, faShield, faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import {faInstagram, faTiktok} from '@fortawesome/free-brands-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import { v4 as uuidv4,NIL as NIL_UUID } from 'uuid';
import {duplicateresultcontent,duplicateresultparacontenttemplate} from "../email/templateBase"
import copy from 'copy-to-clipboard';
import loader from "../img/sample.gif";
// import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import {UserID,Name,Companyid,Role,Rolename, ProfileImg, MasterId, SSOUser} from "../User";
import Cookies from 'js-cookie';

export const getAccessToken = () => Cookies.get('userInfo');

class TikTok extends React.Component {
    constructor(props) {
		super(props);
		this.state = { 
		  keyworddisabled: false,
		  tonedisabled:false,
		  copySuccess: '',
		  wordcount: 0,
		  Tonecount:0,
		  generatedcontent: [],
		  emailguid: NIL_UUID,
		  emailhtml: "",
		  defaultcontenterased: false,
          copyscapeavailable:false,
          plagiarismmodalbody:{ __html: '' }
	  }
	  }
	  componentDidMount(){
		this.setState({emailguid: uuidv4()});
		this.jquerycode();
        this.checkcopyscape();
        document.addEventListener('copy', function(e) {
            const text_only = document.getSelection().toString();
            const clipdata = e.clipboardData || window.clipboardData;  
            clipdata.setData('text/plain', text_only);  
            e.preventDefault();
          });
	  }
// const Emails = () => {
    checkcopyscape = () =>{
        const axios = require('axios');
        var self = this;
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
        };
      
        axios.request(config)
        .then((response) => {
          if (response.data != null && response.data.Data !="") {
            self.state.copyscapeavailable = response.data.Data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
jquerycode = () =>{
	var self = this;
	Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('7').classList.add("active");
// 	setTimeout(function(){ 
// 		var emailhtml = $('iframe.stripo-preview-frame').contentWindow.document.body.innerHTML;
// 		if(emailhtml != self.state.emailhtml){

// 		}
// }, 6000);
}
	setwordcount = () => {
        var text = document.getElementById('keywordinput').value;
        if(text.trim() == "")
        {
            this.setState({wordcount: 0});
        }
        else{
            const words = text.trim().split(',');
            if(words.length > 50)
            {   
				this.customAlertMessage("warning","Keywords limit exceeded.");
                var trimmedwords = text.trim().split(',').slice(0, 3).join(',');
                document.getElementById('keywordinput').value = trimmedwords;
                this.setState({wordcount: 3});
            }
            else{
                this.setState({wordcount: words.length});
            }           
        }        
    }

    togglekeywords() {
      this.setState({keyworddisabled: !this.state.keyworddisabled});
    }

	toggletone(){
		this.setState({tonedisabled: !this.state.tonedisabled});
	}

	updatetonecount = (e) => {
		this.setState({Tonecount:e.currentTarget.value.length});
	}
	toggletemplatediv = () => {
		$('#templatesavediv').toggle();
		$('#btnsavetemplate').toggle();
	if ($('#templatetogglelink').text() == "Save As Template"){
		$('#templatetogglelink').text("Hide template save");

	}       
    else
	{
		$('#templatetogglelink').text("Save As Template");
	}
       
	}
	customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
	geteleValue = (objectid) => {
		var text = "";
		if(document.getElementById(objectid).value != "-1"){
		 text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
		}
		return text;
	 }
	 gettexteleValue = (objectid,prefixtext) => {
		 var text = "";
		 if(document.getElementById(objectid).value.trim() != ""){
            text = prefixtext +": "+ document.getElementById(objectid).value + " , ";
		 }
		 return text;
	  }
      generateaicontent = () =>{
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter text.");
			document.getElementById('textareaoutput').focus();
            return;
		}
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice',"Tone");
        var keywords = this.gettexteleValue('keywordinput',"Keyword");
        var prompttext = "Create a shart TikTok post on "+ prompt + toneofvoice + keywords;

        const axios = require('axios');
        const FormData = require('form-data');
        var tokens = 200;
        let data = new FormData();
        data.append('prompt', prompttext);
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);
        data.append('token', tokens);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/posttextmessage',       
        data : data
        };

        axios.request(config)
        .then((resp) => {
            // console.log(JSON.stringify(resp.Data));
            // var response = resp.Data;
            if (resp != null && resp.data != "") {
                var resultcount = document.getElementById("tiktokchatgptcontent").getElementsByClassName('slider').length;
                var jsr = {};
                jsr.id = resultcount +1;
                jsr.text = resp.data.Data;
                self.state.generatedcontent.push(jsr);
                var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedcontent: joined });
            }
            self.hideoverlay();
        })
        .catch((error) => {
            console.log(error);
            self.hideoverlay();
            self.customAlertMessage('error','Something went wrong, please try again.')
        });

      }
	generateaicontentold = () => {
		
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
		if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter text.");
			document.getElementById('textareaoutput').focus();
            return;
		}
        this.showoverlay();
		var toneofvoice = this.gettexteleValue('toneofvoice',"Tone");
		 var keywords = this.gettexteleValue('keywordinput',"Keyword");
         var prompttext = "Create a TikTok post on "+ prompt + toneofvoice + keywords;

		var axios = require('axios');
var data = JSON.stringify({
//   "model": "text-davinci-003",
  "prompt": prompttext.trim().trim(',').trim(),
  "temperature": 1,
  "max_tokens": 300
});

var config = {
  method: 'post',
  url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
  headers: { 
    'Content-Type': 'application/json', 
    "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
  },
  data : data
};

axios(config)
.then(function (resp) {
  console.log(JSON.stringify(resp.data));
  var response = resp.data;
  if (response.choices != null && response.choices.length > 0) {
    var resultcount = document.getElementById("tiktokchatgptcontent").getElementsByClassName('slider').length;
    var jsr = {};
    jsr.id = resultcount +1;
    jsr.text = response.choices[0].text;
    self.state.generatedcontent.push(jsr);
    var joined = self.state.generatedcontent.sort(function(a,b) {return b.id - a.id;});
    self.setState({ generatedcontent: joined });

    // var joined = self.state.generatedcontent.concat(response.choices[0].text);
    // self.setState({ generatedcontent: joined });
    try {
        var totalwords = response.choices[0].text + " " + prompttext;
        var wordcount = totalwords.match(/(\w+)/g).length;
        self.savegptresponse(
          response.usage.total_tokens,
          wordcount
        );
      } catch (error) {}
  }
  
  self.hideoverlay();
})
.catch(function (error) {
  console.log(error);
  self.hideoverlay();
  self.customAlertMessage('error','Something went wrong, please try again.')
});

	}

	Checkplagiarism = (e) => {	
        var plagiarismtext = e.currentTarget.parentElement.parentElement.getElementsByTagName('p')[0].innerText;
      
		var self = this;
		this.showoverlay();
		
		var axios = require('axios');
		var FormData = require('form-data');
		// var fs = require('fs');
		var data = new FormData();
		data.append('postdata', plagiarismtext);
		data.append("uid",(Role == 3 ? MasterId : UserID));
        data.append("companyid",Companyid);
		
		var config = {
		  method: 'post',
		  "url": `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
		  data : data
		};
		
		axios(config)
		.then(function (resp) {
		  console.log(JSON.stringify(resp.data));
		  var response = (resp.data);
		  if (response.Data != null) {
			if (response.Data.count != null && response.Data.count === 0) {
				self.customAlertMessage('success',"No plagiarism found");
                document.getElementById("plagiarisminfo").style.display = "none";
                // document.getElementById("plagiarismmodalbody").innerHTML = "";
                self.setState({plagiarismmodalbody:{ __html: '' }})
			}
            else if(response.Message == "small content"){
				self.customAlertMessage('info',response.Data);
			}
			else {
				
                document.getElementById("plagiarisminfo").innerText = response.Data.count + " copies found"
                document.getElementById("plagiarisminfo").style.display = "inline-block";
				//Duplicate content found
				// $(elem).find('.checkmark').hide();
				// $(elem).find('.ban').show();
				var htmlnew = duplicateresultcontent;
				htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
				htmlnew = htmlnew.replace("[PHCSquerywords]", response.Data.querywords);
				var duplicateresultparagraphlist = "";
				for (var i = 0; i < response.Data.count; i++) {
					var result = response.Data.result[i];                       
					var duplicateresultparacontent = duplicateresultparacontenttemplate;
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCSurl]", result.url);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCSurltext]", result.url);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCStitle]", result.title);
					duplicateresultparacontent = duplicateresultparacontent.replace("[PHCShtmlsnippet]", result.textsnippet);
					duplicateresultparagraphlist = duplicateresultparagraphlist + duplicateresultparacontent;
				}
				htmlnew = htmlnew.replace("[PHCSparagraphlist]", duplicateresultparagraphlist);
                // document.getElementById("plagiarismmodalbody").innerHTML=htmlnew;
                self.setState({plagiarismmodalbody:{ __html: htmlnew }})
                self.customAlertMessage('error',"Plagiarism found");
				//$(elem).parent().parent().append(htmlnew);
				// $(elem).attr('tooltip', "plagiarism found.");
				// $(elem).attr('onclick', "return false;");
			}
		}
		else {
			self.customAlertMessage('error', 'Error occured, Please try again.'); // 'success','failure','info'
		}
		   self.hideoverlay();
		})
		.catch(function (error) {
		  console.log(error);
		  self.customAlertMessage('error', 'Something went wrong, Try Again.');
		   self.hideoverlay();
		});
	}
	copyToClipboard = (e) => {
        copy(e.currentTarget.parentElement.parentElement.getElementsByTagName('p')[0].innerText,{format:"text/plain"});
        this.setState({ copySuccess: 'Copied!' });      
        this.customAlertMessage('success','Copied!');  
    };
    historygoback = () => {
        window.history.back();
    }
    savegptresponse = (result, totaltoken) => {
        var axios = require("axios");
        var FormData = require("form-data");
        var data = new FormData();
        data.append("prompt", document.getElementById("textareaoutput").value);
        data.append("userid", (Role == 3 ? MasterId : UserID));
        data.append("companyid", Companyid);
        data.append("result", result);
        data.append("token", totaltoken);
    
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
          data: data,
        };
    
        axios(config)
          .then(function (response) {
            //console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            //console.log(error);
          });
      };
      deletesingle = (e) => {
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.generatedcontent;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                imagelist.splice(i, 1);
                break;
            }
          }
        this.setState({generatedcontent: imagelist});
        this.customAlertMessage('success','Item removed successfully');
    }
    delateall = () => {
        this.setState({generatedcontent: []});
        this.customAlertMessage('success','Removed successfully');
    }
    checkcounters = (e) => {
        var self = this;
        const axios = require('axios');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedWordsCount',
          headers: { }
        };
      
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data != undefined) {
            if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
            var availblecounter = response.data.Data;
            var lengthcontroller = 300;
            if (availblecounter >= lengthcontroller) {
                    this.generateaicontent();
            }
            else if (availblecounter < lengthcontroller) {
                var userI = getAccessToken();
                var decodedUser1 = decodeURIComponent(userI);
                var parsedRes1 = JSON.parse(decodedUser1); 
                var PlanType = parsedRes1.PlanType;  
                console.log('PlanType' +PlanType) ;
                console.log('availblecounter' + availblecounter);
                if (PlanType < 1) {
                if (availblecounter == -9999) {
                    this.customAlertMessage('error', 'You have exhausted the usage limit for words.');
                }
                else{
                    document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
                    // document.getElementById('You do not have sufficient tokens to complete this request');
                    document.getElementById('hrefsubscription').click();
                }
                }        
                else{
                    //Show popup to upgrade subscription
                document.getElementById('psubtext').innerText = 'You resources have been exhausted. Please upgrade to a higher subscription plan level.';
                // document.getElementById('You do not have sufficient tokens to complete this request');
                document.getElementById('hrefsubscription').click();
                }
                return;
            }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      proceedOnUpgrade = () =>{
        document.getElementById('btncancelTTI').click();
        if (SSOUser) {      
          document.getElementById('lnkSSOUpgradeTTI').click();
        }
        else{
          document.getElementById('lnkNormalUpgradeTTI').click();
        }
      }

    render(){
    return(
        <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
            <div className="row">	 
                <div className="col-12">
                    <div className="title-card1">
                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />TikTok </span>-<i className="small-title">Create TikTok post description using chatGPT. </i></h4>
                        
                    </div>
                </div>
                {/* Add your Content here */}
                <div className="col-12">
                    <div className="row">
                        
                        
                        <div className="col-4">
                        <div className="lefside-bar">
                            <div className="row">
                                <div className="col-12 mb-30">
                                    <div className="row">
                                        <div className="col-12 label-info">
                                            <label>What is your post about?<span tooltip="Tell chocolatechips.ai what your TikTok post is about." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                        </div>						
                                        <React.StrictMode>
                                                <AudioToText />
                                            </React.StrictMode>
                                    </div>
                                </div>
                                <div className="col-12 mb-30">
                                    <div className="row">
                                        <div className="col-9 label-info">
                                            <label>Tone of Voice<span tooltip="Try using different combinations of words like witty, friendly, disappointed, polite, creative, professional or add a well-known personality such as Adelle." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                        </div>
                                        {/* <div className="col-3 switch-toggle">
                                            <input type="checkbox" id="switch1" onChange={this.toggletone.bind(this)} /><label htmlFor="switch1">Toggle</label>
                                        </div> */}
                                        <div className="col-12">
                                            <input id="toneofvoice" type="text" maxLength={60} className="common-input" onKeyUp={this.updatetonecount} disabled={(this.state.tonedisabled)? "disabled" : ""} placeholder="Professional, Friendly"/>
                                            <span className="character-limit">{this.state.Tonecount}/60</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-30">
                                    <div className="row">
                                        <div className="col-9 label-info">
                                            <label>Keywords<span tooltip="Write keywords separated by commas." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                        </div>
                                        {/* <div className="col-3 switch-toggle">
                                        <input type="checkbox" id="switch2" onChange={this.togglekeywords.bind(this)} /><label for="switch2">Toggle</label>
                                        </div> */}
                                        <div className="col-12">
                                            <input id="keywordinput" type="text" className="common-input" onChange={this.setwordcount} disabled = {(this.state.keyworddisabled)? "disabled" : ""} placeholder="Behavior, Vision"/>
                                            <span className="character-limit">{this.state.wordcount}</span>
                                    </div>
                                    </div>
                                </div>
                                {/* <div className="col-12 mb-20">
                                    <div className="row">
                                        <div className="col-12 label-info">
                                            <label>Output Length</label>
                                        </div>
                                        <div className="col-12 output-length">
                                            <a href="javascript:;">Short</a>
                                            <a href="javascript:;">Medium</a>
                                            <a href="javascript:;">Long</a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-12 mb-30">
                                    <div className="row">
                                    </div>
                                </div>
                                <div id="templatesavediv" style={{display:"none"}}>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-9 label-info">
                                                <label>Title<span tooltip="Text" flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                            </div>
                                            {/* <div className="col-3 switch-toggle">
                                                <input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
                                            </div> */}
                                            <div className="col-12">
                                                <input type="text" className="common-input"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-9 label-info">
                                                <label>Description<span tooltip="Text" flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                            </div>
                                            {/* <div className="col-3 switch-toggle">
                                                <input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
                                            </div> */}
                                            <div className="col-12">
                                                <input type="text" className="common-input"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>							
                            </div>
                        </div>
                        </div>
                        
                        
                        <div className="col-8">
                       
                        <div className="row">
                        <div className='col-6' style={{display: this.state.generatedcontent.length > 0 ? "block":"none" }}>
                                        <h5 className="right-header">Results: {this.state.generatedcontent.length}</h5>
                                    </div>
                            <div className="col-6 text-end chech-right" style={{display: this.state.generatedcontent.length > 0 ? "block":"none" }}>
                                {/* <div className="col-12 text-end chech-right"> */}
                                    {/* <span><FontAwesomeIcon icon={faRotateRight} /></span> */}
                                    {/* <span onClick={this.Checkplagiarism} ><FontAwesomeIcon icon={faShield} /></span> */}
                                    <span id="plagiarisminfo" data-bs-toggle="modal" data-bs-target="#exampleModal" className="text-count" style={{backgroundColor:"#f44336",color:"#fff",display:"none"}}>5 copies found</span>
                                    <span onClick={this.delateall} className="clearnew-btn" style={{marginLeft:'10px',cursor:'pointer'}}>Clear All</span>
                                </div>
                            </div>
                            <div className='row'>
                            <div className='col-12' style={{position:"relative"}}>
                            <div id="overlay">
                                <div id="loading">
                                    <h2 id="loaderheading">Generating...</h2>
                                    <img src={loader} />  
                                </div>
                            </div> 
                            <div className="rightside-bar">
                                <div id="tiktokchatgptcontent">
                                    {this.state.generatedcontent ? this.state.generatedcontent.map((d) => (
                                        <div className="output-wrap slider">
                                            <div className='out-content'>
                                                <p>
                                                {d.text}
                                                </p>
                                            </div>
                                            <div className='out-icons'>  
                                            <a href="https://www.tiktok.com/" target="_blank">
                                                <FontAwesomeIcon icon={faTiktok} />
                                            </a>
                                            {this.state.copyscapeavailable == true ? 
                                            <span onClick={this.Checkplagiarism} tooltip="Check plagiarism" flow="left"><FontAwesomeIcon icon={faShield} /></span> : null }
                                                <span tooltip="Copy" flow="left" onClick={this.copyToClipboard}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                                <span tooltip="Delete" flow="left" onClick={this.deletesingle} data-fileurl={d.id}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </span>
                                            </div>
                                        </div>
                                    )) : null }

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className='bottom-fixed'>
		      <div className="row">
			      <div className="col-4">
						<div className="text-center">
                        <a href="javascript:;" className="common-save" onClick={this.checkcounters}>Generate</a>
						</div>
				  </div>
			  </div>
		  </div>
        </div>


        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Plagiarism</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body" id="plagiarismmodalbody" dangerouslySetInnerHTML={this.state.plagiarismmodalbody}>
                    </div>
                </div>
            </div>
        </div>
        <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
 <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Upgrade Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                <div className="col-12">
                  <p id="psubtext"></p>
                  <div className="row" style={{marginBottom:"10px",display:"none"}}>
                      <div className='col-3'>
                          <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                          <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                      </div>
                  </div>
                  {/* {SSOUser == true ?
                  <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                 <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>} */}
                </div>
              </div>
              <div className="modal-footer">   
                    <button type="button" style={{display:"block"}} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>                                               
                    <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
              </div>
          </div>
      </div>
      </div>
    </div>
    )}};

    export default TikTok;