import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faChevronLeft,
    faMicrophone,
    faInfo,
    faInfoCircle,
    faRotateRight,
    faShield,
    faCloudArrowUp,
    faCircleDown,
    faFile,
    faTimes,
    faCopy,
    faImage,
    faCheck,
    faCross,
    faXmark,
    faRemove
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import copy from 'copy-to-clipboard';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg, MasterId,SSOUser} from "../User";
import fileDownload from "js-file-download";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import loadernew from "../img/loader1.gif";
// import withReactContent from 'sweetalert2-react-content'
import Propertytype from "./propertytype";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
//import SearchLocationInput from "./SearchLocationInput";
import * as axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';
import { ToggleButton } from "react-bootstrap";

import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import {
    duplicateresultcontent,
    duplicateresultparacontenttemplate,
    ChatGPTemailcontent,
    truidraggable,
  } from "../email/templateBase";
  import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
  import heic2any from "heic2any";
  import { n2d } from "n2d";
  export const getAccessToken = () => Cookies.get('userInfo');

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;


let autoComplete;
let createnewcontent = false;
const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
    script.onreadystatechange = function() {
        if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
        }
    };
    } else {
    script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};   

function handleScriptLoad(updateQuery, autoCompleteRef,updateLocation) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields(["address_components", "formatted_address","place_id","geometry"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery,updateLocation)
    );
  }

  function copyToClipboard() {
    copy(document.getElementById('divGeneratedContent').innerText,{format:"text/plain"});    
    customAlertMessage('success','Copied!');  
};

function copyToClipboardEdit(){
    if(document.getElementById('hrefEditContent').innerText=="Update"){
        document.getElementById('divGeneratedContent').contentEditable ="false";
        document.getElementById('hrefEditContent').innerText="Edit";
    }
    else{
        document.getElementById('divGeneratedContent').contentEditable ="true";
        document.getElementById('hrefEditContent').innerText="Update";
    }    
}

function reloadpage(){
    window.location.reload();
}

function addCommaTo(){
    var txtArea = document.getElementById('txtArea');
    txtArea.value=txtArea.value.replace(/[^0-9\,]/, '');
    var num = getNumber(txtArea.value);
    if(num==0){
        txtArea.value="";
    }else{
        txtArea.value = num.toLocaleString();
    }
}

function getNumber(_str){
    var arr = _str.split('');
    var out = new Array();
    for(var cnt=0;cnt<arr.length;cnt++){
        if(isNaN(arr[cnt])==false){
        out.push(arr[cnt]);
        }
    }
    return Number(out.join(''));
}

function moveToNextFloor(cTab){
    //debugger;
    var content = document.getElementById("divFloorwiseFeatures");
    var kbButtons = content.getElementsByTagName("button")[0].id;
    kbButtons = kbButtons.replace("tab-1","");
    var ddlFloors = document.getElementById("ddlFloors").value;
    var intFloors = parseInt(ddlFloors);
    if (cTab == intFloors) {
        cTab = 5;
    }
    switch (cTab) {
        case 1:
            document.getElementById(kbButtons +'tab-2').click();
            break;
        case 2:
            document.getElementById(kbButtons +'tab-3').click();
            break;
        case 3:
            document.getElementById(kbButtons +'tab-4').click();
            break;
        case 4:
            document.getElementById(kbButtons +'tab-5').click();
            break;
        case 5:
            document.getElementById(kbButtons +'tab-6').click();
            break;
    
        default:
            break;
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
}

function moveToNextGeneral(cTab){
    //debugger;
    var content = document.getElementById("divGeneralFeatures");
    var kbButtons = content.getElementsByTagName("button")[0].id;
    kbButtons = kbButtons.replace("tab-first","");
    document.getElementById(kbButtons +'tab-second').click();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
}

// function clearAllDataNew(){
//     // debugger;
//     // var resclear = prompt ("Start new will clear all data. Are you sure you want to proceed.");
//     // if(resclear == false){
//     //     return;
//     // }
//     showoverlay4();
//     clearPropAddress();
//     try {
//         // setuploadedfile([]);
//         // setprocessedimage([]);
//         // setorderedprocessedimage([]);
//         // setdropdownvalues([]);
//         // setvideocreatedformdata({});
//         document.getElementById("divGeneratedContent").innerHTML="";
//         document.getElementById("ddlPropertyType").value = "select";
//         document.getElementById("ddlBedrooms").value ="select";
//         document.getElementById("ddlBathrooms").value = "select";
//         document.getElementById("ddlFloors").value = "select";
//         document.getElementById("txtArea").value = "";
//         document.getElementById("ddlGaragetype").value = "select";
//         document.getElementById("ddlGaragespaces").value = "select";
//         document.getElementById("txtMostSalable").value = ""; 
//         //--Salableselected
//         removeClassFun("Salableselected");
//         document.getElementById("txtGPBR").value = ""; 
//         //--HIMBselected
//         removeClassFun("HIMBselected");
//         document.getElementById("txtGLR").value = ""; 
//         //--HILivingRoomselected
//         removeClassFun("HILivingRoomselected");
//         document.getElementById("txtGKitchen").value = ""; 
//         //-- HIKitchenselected
//         removeClassFun("HIKitchenselected");
//         document.getElementById("txtGbathroom").value = ""; 
//         //-- HIBathroomselected
//         removeClassFun("HIBathroomselected");
//         document.getElementById("txtGHomeExterior").value = ""; 
//         //--GHEselected
//         removeClassFun("GHEselected");
//         document.getElementById("txtGNeighborhood").value = ""; 
//         //-- GNeighborhoodselected
//         removeClassFun("GNeighborhoodselected");
//         document.getElementById("txtGSmartFeatures").value = ""; 
//         //-- GSmartFeaturesselected
//         removeClassFun("GSmartFeaturesselected");
//         document.getElementById("txtGAddRooms").value = ""; 
//         //-- GAddRoomsselected
//         removeClassFun("GAddRoomsselected");

//         // floor wise clearing
//         for (let index = 1; index <= 5; index++) {
//             document.getElementById("txtGPBR"+index).value = ""; 
//             //--HIMBselected
//             removeClassFun("HIMBselected"+index);
//             document.getElementById("txtGLR"+index).value = ""; 
//             //--HILivingRoomselected
//             removeClassFun("HILivingRoomselected"+index);
//             document.getElementById("txtGKitchen"+index).value = ""; 
//             //-- HIKitchenselected
//             removeClassFun("HIKitchenselected"+index);
//             document.getElementById("txtGbathroom"+index).value = ""; 
//             //-- HIBathroomselected
//             removeClassFun("HIBathroomselected"+index);
//         }
//         document.getElementById("txtFWHomeExterior").value = ""; 
//         //--GHEselected
//         removeClassFun("FWHEselected");
//         document.getElementById("txtFWNeighborhood").value = ""; 
//         //-- GNeighborhoodselected
//         removeClassFun("FWNeighborhoodselected");
//         document.getElementById("txtFWSmartFeatures").value = ""; 
//         //-- GSmartFeaturesselected
//         removeClassFun("FWSmartFeaturesselected");
//         document.getElementById("txtFWAddRooms").value = ""; 
//         //-- GAddRoomsselected
//         removeClassFun("FWAddRoomsselected");
//         //floor wise clearing end

//     } catch (error) {
        
//     }
//     onTabClick(1);
//     hideoverlay4();
// }

function removeClassFun(cname) {
   var divele = document.getElementsByClassName(cname);
    while (divele.length) {
        divele[0].classList.remove(cname);
    }
  }
  
  async function handlePlaceSelect(updateQuery,UpdateLocation) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng()
    console.log(latitude + "," + longitude);
    UpdateLocation(latitude + "," + longitude);
    document.getElementById("hfLocationId").value = latitude + "," + longitude;
    onAddressSelection(latitude,longitude);
    debugger;
    getBKIPropertyInfo(query);
  } 
  
 function historygoback () {
    window.history.back();
};

function onAddressSelection (funlat,funlng){
    //alert('inside address selection');
    var locationIdValue = document.getElementById("hfLocationId").value
    //console.log('address location id '+ locationIdValue);    
    showoverlay();
    setTimeout(() => {
        loadAmenities(funlat,funlng);    
    }, 3000);
    
}

function getBKIPropertyInfo(addressProp){
    debugger;
    addressProp = encodeURI(addressProp);
    const axios = require('axios');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetBKIPropertyInfo?UserId=userid&PropertyAddress='+ addressProp
    };
  
    axios.request(config)
    .then((response) => {
      if (response.data != null && response.data.Status == "200" && response.data.Data !=null && response.data.Data !="") {
        var resd = response.data.Data;        
        console.log("Property found");
        try {
            // bind data
        //     "Basement": null,
        // "Baths": null, -- ddlBathrooms -- DONE
        // "Bedrooms": "0", // ddlBedrooms -- DONE
        // "Fireplace": null, // txtGLR -- DONE
        // "FullBaths": "0",
        // "GarageNumCars": "2", // ddlGaragespaces -- DONE
        // "LotSize": "2", txtArea
        // "PartialBaths": "0",
        // "Pool": null, DONE -- DONE
        // "PropertyType" : "Residential",  -- ddlPropertyType or UseCodeDescription  -- DONE
        // "Stories": "1 Story", // ddlFloors --
        // "YearBuilt": "1997" // 
            
            if (resd.Baths !=null && resd.Baths != undefined && resd.Baths != "") {
                var tBaths = parseInt(resd.Baths);
                if (tBaths > 0 && tBaths < 11) {
                     document.getElementById("ddlBathrooms").value = tBaths;
                }
            }
            if (resd.Bedrooms !=null && resd.Bedrooms != undefined && resd.Bedrooms != "") {
                var tbeds = parseInt(resd.Bedrooms);
                if (tbeds > 0 && tbeds < 11) {
                     document.getElementById("ddlBedrooms").value = tbeds;
                }
            }
            if (resd.GarageNumCars !=null && resd.GarageNumCars != undefined && resd.GarageNumCars != "") {
                var tGarageNumCars = parseInt(resd.GarageNumCars);
                if (tGarageNumCars > 0 && tGarageNumCars < 11) {
                     document.getElementById("ddlGaragespaces").value = tGarageNumCars;
                }
            }
            if (resd.PropertyType !=null && resd.PropertyType != undefined && resd.PropertyType != "") {
                //// "PropertyType" : "Residential",  -- ddlPropertyType or UseCodeDescription
                var mainPropTypeValue = "singlefamily";
                var propSubType="";
                if (resd.UseCodeDescription !=null && resd.UseCodeDescription != undefined && resd.UseCodeDescription != "") {
                    propSubType = resd.UseCodeDescription;
                    if (propSubType.includes("single") || propSubType.includes("Single")) {
                        mainPropTypeValue = "singlefamily";
                    } 
                    else if (propSubType.includes("multi") || propSubType.includes("Multi")) {
                        mainPropTypeValue = "multifamily";
                    }
                    else if (propSubType.includes("condo") || propSubType.includes("Condo")) {
                        mainPropTypeValue = "condo";
                    }
                    else if (propSubType.includes("villa") || propSubType.includes("Villa")) {
                        mainPropTypeValue = "villa";
                    }
                    else if (propSubType.includes("town") || propSubType.includes("Town")) {
                        mainPropTypeValue = "townhouse";
                    }
                    else if (propSubType.includes("apartment") || propSubType.includes("Apartment")) {
                        mainPropTypeValue = "apartment";
                    }
                    else if (propSubType.includes("resident") || propSubType.includes("Resident") || propSubType.includes("Resi")) {
                        mainPropTypeValue = "residential";
                    }
                    else if (propSubType.includes("land") || propSubType.includes("Land")) {
                        mainPropTypeValue = "land";
                    }
                    else if (propSubType.includes("commercial") || propSubType.includes("Commercial") || propSubType.includes("Comm")) {
                        mainPropTypeValue = "commercial";
                    }
                    //commercial
                } else {
                    propSubType = resd.PropertyType;
                    if (propSubType.includes("resident") || propSubType.includes("Resident") || propSubType.includes("Resi")) {
                        mainPropTypeValue = "residential";
                    }
                    else if (propSubType.includes("land") || propSubType.includes("Land")) {
                        mainPropTypeValue = "land";
                    }
                    else if (propSubType.includes("commercial") || propSubType.includes("Comm") || propSubType.includes("Commercial")) {
                        mainPropTypeValue = "commercial";
                    }
                }
                document.getElementById("ddlPropertyType").value = mainPropTypeValue;
            } // end of property type
            if (resd.Fireplace !=null && resd.Fireplace != undefined && resd.Fireplace != "") {
                document.getElementById("txtMostSalable").value = "FirePlace " + resd.Fireplace;
            }
            if (resd.Pool !=null && resd.Pool != undefined && resd.Pool != "") {
                var txtGLR = document.getElementById("txtMostSalable");
                if (txtGLR.value !="") {
                    txtGLR.value = ", Pool " + resd.Pool;
                } else {
                    txtGLR.value = "Pool " + resd.Pool;
                }                
            }
            if (resd.LotSize !=null && resd.LotSize != undefined && resd.LotSize != "") {
                var tLotSize = parseFloat(resd.LotSize);
                if (tLotSize > 0) {
                    document.getElementById("txtArea").value = tLotSize.toString();
                }
            }
            if (resd.Stories !=null && resd.Stories != undefined && resd.Stories != "") {
                var tStories = resd.Stories;
                var stories =0;
                if (tStories.includes("1")) {
                    stories=1;
                }
                else if (tStories.includes("2")) {
                    stories=2;
                }
                else if (tStories.includes("3")) {
                    stories=3;
                }
                else if (tStories.includes("4")) {
                    stories=4;
                }
                else if (tStories.includes("5")) {
                    stories=5;
                }

                if (tStories > 0 && tStories < 6) {
                    document.getElementById("ddlFloors").value = stories;
                }
            }
        } catch (error) {
            console.log("error in Property binding");
        }
      }
    })
    .catch((error) => {
      console.log("Property not found");
    });
}

function sleep(seconds) {
    var e = new Date().getTime() + (seconds * 1000);
    while (new Date().getTime() <= e) { }
}

function toggleSalableClass(ele){
    var anchorEle = document.getElementById(ele);
    anchorEle.classList.toggle("Salableselected");
    var txtMostSalable = document.getElementById("txtMostSalable").value;
    if(anchorEle.classList.contains('Salableselected')) {
        document.getElementById("txtMostSalable").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
    }    
    else{
        document.getElementById("txtMostSalable").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
    }
}

// function toggleGHIClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("HIMBselected");
//     var txtMostSalable = document.getElementById("txtGPBR").value;
//     if(anchorEle.classList.contains('HIMBselected')) {
//         document.getElementById("txtGPBR").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGPBR").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGLRClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("HILivingRoomselected");
//     var txtMostSalable = document.getElementById("txtGLR").value;
//     if(anchorEle.classList.contains('HILivingRoomselected')) {
//         document.getElementById("txtGLR").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGLR").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGKitchenClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("HIKitchenselected");
//     var txtMostSalable = document.getElementById("txtGKitchen").value;
//     if(anchorEle.classList.contains('HIKitchenselected')) {
//         document.getElementById("txtGKitchen").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGKitchen").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGbathroomClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("HIBathroomselected");
//     var txtMostSalable = document.getElementById("txtGbathroom").value;
//     if(anchorEle.classList.contains('HIBathroomselected')) {
//         document.getElementById("txtGbathroom").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGbathroom").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGHomeExteriorClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("GHEselected");
//     var txtMostSalable = document.getElementById("txtGHomeExterior").value;
//     if(anchorEle.classList.contains('GHEselected')) {
//         document.getElementById("txtGHomeExterior").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGHomeExterior").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGNeighborhoodClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("GNeighborhoodselected");
//     var txtMostSalable = document.getElementById("txtGNeighborhood").value;
//     if(anchorEle.classList.contains('GNeighborhoodselected')) {
//         document.getElementById("txtGNeighborhood").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGNeighborhood").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGSmartFeaturesClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("GSmartFeaturesselected");
//     var txtMostSalable = document.getElementById("txtGSmartFeatures").value;
//     if(anchorEle.classList.contains('GSmartFeaturesselected')) {
//         document.getElementById("txtGSmartFeatures").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGSmartFeatures").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleGAddRoomsClass(ele){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle("GAddRoomsselected");
//     var txtMostSalable = document.getElementById("txtGAddRooms").value;
//     if(anchorEle.classList.contains('GAddRoomsselected')) {
//         document.getElementById("txtGAddRooms").value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGAddRooms").value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleFWGClass(ele,classN,textPId){
//     var anchorEle = document.getElementById(ele);
//     anchorEle.classList.toggle(classN);
//     var txtMostSalable = document.getElementById(textPId).value;
//     if(anchorEle.classList.contains(classN)) {
//         document.getElementById(textPId).value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById(textPId).value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// // floorwise start
// function toggleFWHIClass(ele,Ids){
//     var anchorEle = document.getElementById(ele+Ids);
//     anchorEle.classList.toggle("HIMBselected"+Ids);
//     var txtMostSalable = document.getElementById("txtGPBR"+Ids).value;
//     if(anchorEle.classList.contains('HIMBselected'+Ids)) {
//         document.getElementById("txtGPBR"+Ids).value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGPBR"+Ids).value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleFWLRClass(ele,Ids){
//     //debugger;
//     var anchorEle = document.getElementById(ele+Ids);
//     anchorEle.classList.toggle("HILivingRoomselected"+Ids);
//     var txtMostSalable = document.getElementById("txtGLR"+Ids).value;
//     if(anchorEle.classList.contains('HILivingRoomselected'+Ids)) {
//         document.getElementById("txtGLR"+Ids).value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGLR"+Ids).value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }

// function toggleFWKitchenClass(ele,Ids){
//     var anchorEle = document.getElementById(ele+Ids);
//     anchorEle.classList.toggle("HIKitchenselected"+Ids);
//     var txtMostSalable = document.getElementById("txtGKitchen"+Ids).value;
//     if(anchorEle.classList.contains('HIKitchenselected'+Ids)) {
//         document.getElementById("txtGKitchen"+Ids).value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGKitchen"+Ids).value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }
// function toggleFWbathroomClass(ele,Ids){
//     var anchorEle = document.getElementById(ele+Ids);
//     anchorEle.classList.toggle("HIBathroomselected"+Ids);
//     var txtMostSalable = document.getElementById("txtGbathroom"+Ids).value;
//     if(anchorEle.classList.contains('HIBathroomselected'+Ids)) {
//         document.getElementById("txtGbathroom"+Ids).value = txtMostSalable !=""? txtMostSalable + ", " +anchorEle.text:anchorEle.text;
//     }    
//     else{
//         document.getElementById("txtGbathroom"+Ids).value = txtMostSalable.replace(", "+anchorEle.text,"").replace(anchorEle.text,"").replace(/^,+|,+$/g, '').trim();
//     }
// }
//floorwise end

function loadAmenities(placelat,placeng){    
    //sleep(3);
    var serviceData = new window.google.maps.places.PlacesService(document.createElement('div1Result'));
    var pyrmont = new window.google.maps.LatLng(placelat,placeng);
    const request = {
        location: pyrmont,
        fields: ["name"],
        radius: '8033',
        rankby:'distance',
        type: ['school']
      };
      serviceData.nearbySearch(request, callbackSchool);
    //   >hospital
    //   >park
    //   >supermarket
    const requesthospitals = {
        location: pyrmont,
        fields: ["name"],
        radius: '8033',
        rankby:'distance',
        type: ['hospital']
      };
      serviceData.nearbySearch(requesthospitals, callbackHospitals);

      const requestpark = {
        location: pyrmont,
        fields: ["name"],
        radius: '8033',
        rankby:'distance',
        type: ['park']
      };
      serviceData.nearbySearch(requestpark, callbackParks);

      const requestsupermarket = {
        location: pyrmont,
        fields: ["name"],
        radius: '8033',
        rankby:'distance',
        type: ['supermarket']
      };
      serviceData.nearbySearch(requestsupermarket, callbackSupermarkets);

      document.getElementById("div1Result").style.display="block";      
}

function callbackSchool(results, status){
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        var divSchoolsData = document.getElementById('divSchoolsData');
        divSchoolsData.style.display="block";
        if (results !=undefined && results.length > 0) {            
            var schoolsText="<ul class='checkbox-category'>";
            for (var i = 0; i < results.length; i++) {
                // create list of schools
                //console.log('schools data '+i + " " + results[i].name);                
                schoolsText +=  "<li><input class='chkSchoolAmenities' id='chkschoolAmenity"+i+"' name='schoolAmenity' type='checkbox' />&nbsp;<label id='lblschoolAmenity"+i+"'>"+results[i].name+"</label></li>";     
                if(i==9){
                    break;
                }           
            }
            schoolsText = schoolsText + "</ul>";   
            divSchoolsData.innerHTML =schoolsText;
        }
        else{
            divSchoolsData.innerText ="No data found.";
        }      
    }
    else{
        document.getElementById('divSchoolsData').innerText ="No data found.";
    }
    hideoverlay();
  }

  function callbackHospitals(results, status){
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        var divSchoolsData = document.getElementById('divHospitalsData');
        divSchoolsData.style.display="block";
        if (results !=undefined && results.length > 0) {            
            var schoolsText="<ul class='checkbox-category'>";
            for (var i = 0; i < results.length; i++) {
                // create list of schools
                //console.log('schools data '+i + " " + results[i].name);                
                schoolsText +=  "<li><input class='chkHospitalAmenities' id='chkhospitalAmenity"+i+"' name='hospitalAmenity' type='checkbox' />&nbsp;<label id='lblhospitalAmenity"+i+"'>"+results[i].name+"</label></li>";     
                if(i==9){
                    break;
                }           
            }
            schoolsText = schoolsText + "</ul>";   
            divSchoolsData.innerHTML =schoolsText;
        }
        else{
            divSchoolsData.innerHTML = "<p>No data found.</p>";
        }
    }
    else{
        document.getElementById('divHospitalsData').innerText ="No data found.";
    }
    hideoverlay();
  }

  function callbackParks(results, status){
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        var divSchoolsData = document.getElementById('divParksData');
        divSchoolsData.style.display="block";
        if (results !=undefined && results.length > 0) {            
            var schoolsText="<ul class='checkbox-category'>";
            for (var i = 0; i < results.length; i++) {
                // create list of schools
                //console.log('schools data '+i + " " + results[i].name);                
                schoolsText +=  "<li><input class='chkParkAmenities' id='chkparkAmenity"+i+"' name='parkAmenity' type='checkbox' />&nbsp;<label id='lblparkAmenity"+i+"'>"+results[i].name+"</label></li>";     
                if(i==9){
                    break;
                }           
            }
            schoolsText = schoolsText + "</ul>";   
            divSchoolsData.innerHTML =schoolsText;
        }
        else{
            divSchoolsData.innerText ="No data found.";
        }
    }
    else{
        document.getElementById('divParksData').innerText ="No data found.";
    }
    hideoverlay();
  }

  function callbackSupermarkets(results, status){
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        var divSchoolsData = document.getElementById('divSsupermarketsData');
        divSchoolsData.style.display="block";
        if (results !=undefined && results.length > 0) {            
            var schoolsText="<ul class='checkbox-category'>";
            for (var i = 0; i < results.length; i++) {
                // create list of schools
                //console.log('schools data '+i + " " + results[i].name);                
                schoolsText +=  "<li><input class='chkSupermarketAmenities' id='chksupermarketAmenity"+i+"' name='supermarketAmenity' type='checkbox' />&nbsp;<label id='lblsupermarketAmenity"+i+"'>"+results[i].name+"</label></li>";     
                if(i==9){
                    break;
                }           
            }
            schoolsText = schoolsText + "</ul>";   
            divSchoolsData.innerHTML =schoolsText;
        }
        else{
            divSchoolsData.innerText ="No data found.";
        }
    }
    else{
        document.getElementById('divSsupermarketsData').innerText ="No data found.";
    }
    hideoverlay();
  }
  

// function onTabClick  (selectedTI)  {  
//     var ctab = document.getElementById("hfCurrentTab").value;  
//     switch (selectedTI) {
//         case 1:
//             document.getElementById('divpropertyaddress').style.display = "block";
//             document.getElementById('divpropertydetails').style.display = "none";
//             document.getElementById('divpropertyfeatures').style.display = "none";
//             document.getElementById('divdescription').style.display = "none";
//             document.getElementById("div1").classList.remove("inactive");
//             document.getElementById("div2").classList.remove("inactive");
//             document.getElementById("div2").classList.add("inactive");
//             document.getElementById("div3").classList.remove("inactive");
//             document.getElementById("div3").classList.add("inactive");
//             document.getElementById("div4").classList.remove("inactive");
//             document.getElementById("div4").classList.add("inactive");
//             break;
//         case 2:            
//             if(ctab == "1"){
//                 var valres = validatePropertyAddress();
//                 if(valres == false){
//                     return;
//                 }
//             }
//             document.getElementById('divpropertyaddress').style.display = "none";
//             document.getElementById('divpropertydetails').style.display = "block";
//             document.getElementById('divpropertyfeatures').style.display = "none";
//             document.getElementById('divdescription').style.display = "none";
//             document.getElementById("div1").classList.remove("inactive");
//             document.getElementById("div1").classList.add("inactive");
//             document.getElementById("div2").classList.remove("inactive");            
//             document.getElementById("div3").classList.remove("inactive");
//             document.getElementById("div3").classList.add("inactive");
//             document.getElementById("div4").classList.remove("inactive");
//             document.getElementById("div4").classList.add("inactive");
//             document.getElementById('countno1').style.display = "none";
//             document.getElementById('checkmarkvalid1').style.display = "block";
//             document.body.scrollTop = document.documentElement.scrollTop = 0;
//             break;
//         case 3:
//             //validateNext(2);
//             if(ctab == "2"){
//                 var valres = validatePropertyDetails();
//                 if(valres == false){
//                     return;
//                 }
//             }
//             if(ctab == "1"){
//                 var valres = validatePropertyAddress();
//                 if(valres == false){
//                     return;
//                 }
//                 valres = validatePropertyDetailsFromTab1();
//                 if(valres == false){
//                     return;
//                 }
//             }
//             // rbtnGeneral // rbtnFloorwise
//             var rbtnFloorwise = document.getElementById("rbtnFloorwise").checked;
//             if(rbtnFloorwise){
//                 //divGeneralFeatures divFloorwiseFeatures
//                 // document.getElementById('divGeneralFeatures').style.display = "none";
//                 document.getElementById('divFloorwiseFeatures').style.display = "block";
//                 //tabFloor2
//                 var ddlFloors = document.getElementById("ddlFloors").value;
//                 var intFloors = parseInt(ddlFloors);
//                 var content = document.getElementById("divFloorwiseFeatures");
//                 var kbButtons = content.getElementsByTagName("button")[0].id;
//                 kbButtons = kbButtons.replace("tab-1","");
//                 //alert(kbButtons);
//                 for (let index = 2; index < 6; index++) {
//                     if(index <= intFloors ){
//                         document.getElementById(kbButtons +'tab-'+ index).style.display = "block";
//                     }
//                     else{
//                         document.getElementById(kbButtons +'tab-'+ index).style.display = "none";
//                     }
//                 }

//             }
//             else{
//                 // document.getElementById('divGeneralFeatures').style.display = "block";
//                 // document.getElementById('divFloorwiseFeatures').style.display = "none";
//             }
//             document.getElementById('divpropertyaddress').style.display = "none";
//             document.getElementById('divpropertydetails').style.display = "none";
//             document.getElementById('divpropertyfeatures').style.display = "block";
//             document.getElementById('divdescription').style.display = "none";
//             document.getElementById("div1").classList.remove("inactive");
//             document.getElementById("div1").classList.add("inactive");
//             document.getElementById("div2").classList.remove("inactive");   
//             document.getElementById("div2").classList.add("inactive");         
//             document.getElementById("div3").classList.remove("inactive");            
//             document.getElementById("div4").classList.remove("inactive");
//             document.getElementById("div4").classList.add("inactive");
//             document.getElementById('countno2').style.display = "none";
//             document.getElementById('checkmarkvalid2').style.display = "block";
//             document.body.scrollTop = document.documentElement.scrollTop = 0;
//             loaddropdownfromstate();
//             break;
//         case 4:
//             var content = document.getElementById("divGeneratedContent").innerText;
//             if (content == "") {
//                 customAlertMessage('error', 'Not generated any description yet.');
//                 return;
//             }
//             document.getElementById('divpropertyaddress').style.display = "none";
//             document.getElementById('divpropertydetails').style.display = "none";
//             document.getElementById('divpropertyfeatures').style.display = "none";
//             document.getElementById('divdescription').style.display = "block";
//             document.getElementById("div1").classList.remove("inactive");
//             document.getElementById("div1").classList.add("inactive");
//             document.getElementById("div2").classList.remove("inactive");   
//             document.getElementById("div2").classList.add("inactive");         
//             document.getElementById("div3").classList.remove("inactive");                        
//             document.getElementById("div3").classList.add("inactive");
//             document.getElementById("div4").classList.remove("inactive");
//             document.getElementById('countno3').style.display = "none";
//             document.getElementById('countno4').style.display = "none";
//             document.getElementById('checkmarkvalid3').style.display = "block";
//             document.getElementById('checkmarkvalid4').style.display = "block";
//             document.body.scrollTop = document.documentElement.scrollTop = 0;
//             break;

//         default:
//             document.getElementById('divpropertyaddress').style.display = "block";
//             document.getElementById('divpropertydetails').style.display = "none";
//             document.getElementById('divpropertyfeatures').style.display = "none";
//             document.getElementById('divdescription').style.display = "none";
//             document.getElementById("div1").classList.remove("inactive");
//             document.getElementById("div2").classList.remove("inactive");
//             document.getElementById("div2").classList.add("inactive");
//             document.getElementById("div3").classList.remove("inactive");
//             document.getElementById("div3").classList.add("inactive");
//             document.getElementById("div4").classList.remove("inactive");
//             document.getElementById("div4").classList.add("inactive");
//             break;
//     }
//     document.getElementById("hfCurrentTab").value=selectedTI;
// };

// function componentDidMount  ()  {
//     // load initial data
//     onTabClick(1);
//     document.addEventListener('copy', function(e) {
//         const text_only = document.getSelection().toString();
//         const clipdata = e.clipboardData || window.clipboardData;  
//         clipdata.setData('text/plain', text_only);  
//         e.preventDefault();
//       });
// };

// function validateNext (currentTab) {
//     switch (currentTab) {
//         case 1:
//             var res = validatePropertyAddress();
//             if (res) {
//               onTabClick(2);
//             }
//             else {
//                 //alert
//             }
//             break;
//         case 2:
//             var res = validatePropertyDetails();
//             if (res) {
//                 onTabClick(3);
//             }
//             else {
//                 //alert
//             }
//             break;
//         case 3:
//             var res = validatePropertyFeatures();
//             if (res) {
//                 generateaicontent();                              
//             }
//             else {
//                 //alert
//             }
//             break;
//         default:
//             break;
//     }
// }

function validatePropertyAddress  ()  {
    var txtPropAddress = document.getElementById('txtPropAddress').value;
    if(txtPropAddress !=undefined && txtPropAddress !=""){
        return true;
    }
    customAlertMessage('error', 'Please enter property address');
    return false;
}

function validatePropertyDetails  ()  {
    var ddlBedrooms = document.getElementById("ddlBedrooms").value;
    if(ddlBedrooms == "select"){
        customAlertMessage('error', 'Number of bedrooms is required.');
        return false;
    }
    var ddlBathrooms = document.getElementById("ddlBathrooms").value;
    if(ddlBathrooms == "select"){
        customAlertMessage('error', 'Number of bathrooms is required.');
        return false;
    }
    var ddlFloors = document.getElementById("ddlFloors").value;
    if(ddlFloors == "select"){
        customAlertMessage('error', 'Number of floors is required.');
        return false;
    }
    var txtArea = document.getElementById("txtArea").value;
    if(txtArea == ""){
        customAlertMessage('error', 'Floor area is required.');
        return false;
    }
    var txtMostSalable = document.getElementById("txtMostSalable").value;
    if(txtMostSalable == ""){
        customAlertMessage('error', 'The most saleable features include is required.');
        return false;
    }
    return true;
}

function validatePropertyDetailsFromTab1  ()  {
    var ddlBedrooms = document.getElementById("ddlBedrooms").value;
    if(ddlBedrooms == "select"){
        customAlertMessage('error', 'Number of bedrooms is required in property details tab.');
        return false;
    }
    var ddlBathrooms = document.getElementById("ddlBathrooms").value;
    if(ddlBathrooms == "select"){
        customAlertMessage('error', 'Number of bathrooms is required in property details tab.');
        return false;
    }
    var ddlFloors = document.getElementById("ddlFloors").value;
    if(ddlFloors == "select"){
        customAlertMessage('error', 'Number of floors is required in property details tab.');
        return false;
    }
    var txtArea = document.getElementById("txtArea").value;
    if(txtArea == ""){
        customAlertMessage('error', 'Floor area is required in property details tab.');
        return false;
    }
    var txtMostSalable = document.getElementById("txtMostSalable").value;
    if(txtMostSalable == ""){
        customAlertMessage('error', 'The most saleable features include is required in property details tab.');
        return false;
    }
    return true;
}

function validatePropertyFeatures  ()  {
    return true;
}    

function customAlertMessage(icon, message) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,//'success',
        title: message// 'Signed in successfully'
    })
}

function showoverlay() {
    document.getElementById('overlay').style.display = "block";
    $('body').addClass("loader");
}
function hideoverlay(){
    document.getElementById('overlay').style.display = "none";
    $('body').removeClass("loader");
}

function showoverlay4() {
    document.getElementById('overlayloader4').style.display = "block";
    $('body').addClass("loader");
}
function hideoverlay4(){
    document.getElementById('overlayloader4').style.display = "none";
    $('body').removeClass("loader");
}

function showoverlayprop() {
    document.getElementById('newloaderprop').style.display = "flex";
    document.getElementById('generatepropnew').style.display = "none";
    $('body').addClass("loader");
}
function hideoverlayprop(){
    document.getElementById('newloaderprop').style.display = "none";
    document.getElementById('generatepropnew').style.display = "flex";
    $('body').removeClass("loader");
}

function clearPropAddress(){    
    document.getElementById('txtPropAddress').value ="";
    document.getElementById("div1Result").style.display="none";    
}

var t = 0;
// function typeWriter(datalist,Dataobjects) {    
//     var speed = 10;
//     if (t < datalist.length) {        
//         var textLst = datalist.charAt(t);
//         document.getElementById("divGeneratedContent").innerHTML += textLst=== "\n" ? "<p></p>": textLst;
//         t++;
//         document.getElementById("lblCcount").innerHTML= t;
//         setTimeout(function(){typeWriter(datalist)}, speed);
//         //window.scrollTo(0, document.body.scrollHeight);
//         var elementsDiv1 = document.getElementById("divGeneratedContent");
//         elementsDiv1.scrollTop = elementsDiv1.scrollHeight;               
//     }
//     if(t + 1 ==  datalist.length){
//         console.log('inside final');
//         document.getElementById("divOutputIcons").style.display = "block";
//         Highlightkeys(Dataobjects);

//     }
// }

function proceedOnUpgrade(){
    document.getElementById('btncancelTTI').click();
    if (SSOUser) {      
      document.getElementById('lnkSSOUpgradeTTI').click();
    }
    else{
      document.getElementById('lnkNormalUpgradeTTI').click();
    }
  }

// function checkCountersLimit(){
//     var userI = getAccessToken();
//     var decodedUser1 = decodeURIComponent(userI);
//     var parsedRes1 = JSON.parse(decodedUser1); 
//     var PlanType = parsedRes1.PlanType;  
//     if (PlanType < 1) { // free trial
//         const axios = require('axios');
//         let config = {
//           method: 'get',
//           maxBodyLength: Infinity,
//           url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedPropertyDescription',
//           headers: { }
//         };
      
//         axios.request(config)
//         .then((response) => {
//           console.log(JSON.stringify(response.data));
//           if (response.data != undefined) {
//             if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
//                 var availblecounter = response.data.Data;
//                 if (availblecounter <= 0) {
//                         document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
//                         document.getElementById('hrefsubscription').click();
//                         return;
//                 }
//                 else{
//                     generateaicontentCounters();
//                 }
//             }
//           }
//         })
//         .catch((error) => {
//           console.log(error);
//         });
//         // end of free trial
//     }else {
//         generateaicontentCounters();
//     }
// }

// function generateaicontent(){
//     checkCountersLimit();
// }

    // function generateaicontentCounters()  {        
    //     document.getElementById("divGeneratedContent").innerHTML="description start";
    //     try {
    //         document.getElementById("plagiarisminfo").style.display = "none";
    //     } catch (error) {
            
    //     }    
    //     onTabClick(4);
    //     document.getElementById("divGeneratedContent").innerHTML="";
    //     document.getElementById("h4Title").innerHTML="";
    //     document.getElementById("spanCharCount").style.display = "none";
    //     document.getElementById("divOutputIcons").style.display = "none";
    //     //document.getElementById("newloaderprop").style.display="block";
    //     //hideoverlay4();
    //     //var limitReached = checkCountersLimit();       
    //     showoverlayprop();
    //     var prompt = "";        
    //     var txtPropAddress = document.getElementById('txtPropAddress').value;
    //     prompt = "Generate detailed property listing description for "+txtPropAddress;
    //     if (prompt.trim() == "") {
    //       customAlertMessage("error", "Please enter property address.");
    //       return;
    //     }    
    
    //     // step 2 start ==>Property details
    //     var propTypeSel = document.getElementById("ddlPropertyType").value;
    //     if (propTypeSel != "select") {
    //         prompt += " with " + propTypeSel +" propertytype.";
    //     }
    //     else{
    //         prompt +=".";
    //     }
    //     var ddlBedroomsSel = document.getElementById("ddlBedrooms").value;
    //     if (ddlBedroomsSel != "select") {
    //         prompt += " It has "+ ddlBedroomsSel + " number of bedrooms and " ;
    //     }
    //     var ddlBathroomsSel = document.getElementById("ddlBathrooms").value;
    //     if (ddlBathroomsSel != "select") {
    //         prompt +=  ddlBathroomsSel + " number of bathrooms." ;
    //     }
    //     var ddlFloorsSel = document.getElementById("ddlFloors").value;
    //     if (ddlFloorsSel != "select") {
    //         prompt += " It is a " + ddlFloorsSel + " story home." ; 
    //     }
    //     var txtAreaSel = document.getElementById("txtArea").value;
    //     if (txtAreaSel != "") {
    //         prompt += "It is having an area of "+txtAreaSel+ " sqft.";
    //     }
    //     // lot size comented
    //     // var txtAreaSel = document.getElementById("txtArea").value; 
    //     // if (txtAreaSel != "") {
    //     //     prompt += "and "+txtAreaSel+ " lot size.";
    //     // }  
    //     var ddlGaragetypeSel = document.getElementById("ddlGaragetype").value;
    //     var ddlGaragespacesSel = document.getElementById("ddlGaragespaces").value;
    //     if (ddlGaragetypeSel !="select" && ddlGaragespacesSel != "select") {
    //         prompt += " It has " + ddlGaragetypeSel + " garage with "+ ddlGaragespacesSel + " spaces."; 
    //     }
    //     else if (ddlGaragetypeSel != "select") {
    //         prompt += " It has " + ddlGaragetypeSel + " garage." ; 
    //     }
    //     else if (ddlGaragespacesSel != "select") {
    //         prompt += " It has " + ddlGaragespacesSel + " garage." ; 
    //     }
    //     prompt += "The property most Saleable features include ";
    //     var txtMostSalableSel = document.getElementById("txtMostSalable").value;
    //     if (txtMostSalableSel != "") {
    //         prompt += txtMostSalableSel + ".";
    //     }
    //     // Step 2 end
    
    //     // step 1 start ==>Amenitinies
        
    //     //school amenity 
    //     var markedCheckbox = document.getElementsByName('schoolAmenity');  
    //     var schoolAmenitiesel = "";
    //     for (var checkbox of markedCheckbox) {  
    //         if (checkbox.checked)  {
    //             var lblIds = checkbox.id.replace("chkschool","lblschool");
    //             schoolAmenitiesel += document.getElementById(lblIds).innerText + ', ';
    //         }
    //     } 
    //     if (schoolAmenitiesel !="") {
    //         prompt += "The property has near by schools including " + schoolAmenitiesel + ".";
    //     }
    
    //     var markedCheckboxHosp = document.getElementsByName('hospitalAmenity');  
    //     var hospitalAmenitiesel = "";
    //     for (var checkbox of markedCheckboxHosp) {  
    //         if (checkbox.checked)  {
    //             var lblIdshospital = checkbox.id.replace("chkhospital","lblhospital");
    //             hospitalAmenitiesel += document.getElementById(lblIdshospital).innerText + ', ';
    //         }
    //     } 
    //     if (hospitalAmenitiesel !="") {
    //         prompt += "The property has near by hospitals including " + hospitalAmenitiesel + ".";
    //     }
    
    //     var markedCheckboxpark = document.getElementsByName('parkAmenity');  
    //     var parkAmenitiesel = "";
    //     for (var checkbox of markedCheckboxpark) {  
    //         if (checkbox.checked)  {
    //             var lblIdspark = checkbox.id.replace("chkpark","lblpark");
    //             parkAmenitiesel += document.getElementById(lblIdspark).innerText + ', ';
    //         }
    //     } 
    //     if (parkAmenitiesel !="") {
    //         prompt += "The property has near by parks including " + parkAmenitiesel + ".";
    //     }
    
    //     var markedCheckboxsupermarket = document.getElementsByName('supermarketAmenity');  
    //     var supermarketAmenitiesel = "";
    //     for (var checkbox of markedCheckboxsupermarket) {  
    //         if (checkbox.checked)  {
    //             var lblIdssupermarket = checkbox.id.replace("chksupermarket","lblsupermarket");
    //             supermarketAmenitiesel += document.getElementById(lblIdssupermarket).innerText + ', ';
    //         }
    //     } 
    //     if (supermarketAmenitiesel !="") {
    //         prompt += "The property has near by supermarkets including " + supermarketAmenitiesel + ".";
    //     }
    
    //     // Step 1 end
    
    //     // step 3 start ==>property features
    //     // var rbtnFloorwise = document.getElementById("rbtnFloorwise").checked;
    //     // if(rbtnFloorwise){
    //     //     //Floorwise Home Interior & Exterior
    //     //     // home interior start
    //     //     var ddlFloors = document.getElementById("ddlFloors").value;
    //     //     var intFloors = parseInt(ddlFloors);
    //     //     //alert(kbButtons);
    //     //     var floorDesc = "first floor";
    //     //     for (let index = 1; index <= intFloors; index++) {
    //     //         switch (index) {
    //     //             case 1:
    //     //                 floorDesc = "first floor";
    //     //                 break;     
    //     //             case 2:
    //     //             floorDesc = "second floor";
    //     //             break; 
    //     //             case 3:
    //     //             floorDesc = "third floor";
    //     //             break; 
    //     //             case 4:
    //     //             floorDesc = "fourth floor";
    //     //             break; 
    //     //             case 5:
    //     //             floorDesc = "fifth floor";
    //     //             break;        
    //     //             default:
    //     //                 break;
    //     //         }
    //     //         var txtGPBR = document.getElementById('txtGPBR'+index).value;
    //     //         if (txtGPBR !="") {
    //     //             prompt += "The " + floorDesc + " master bedroom has features like " + txtGPBR + ".";
    //     //         }
    //     //         var txtGLR = document.getElementById('txtGLR'+index).value;
    //     //         if (txtGLR !="") {
    //     //             prompt += "The " + floorDesc + " living room has features like " + txtGLR + ".";
    //     //         }
    //     //         var txtGKitchen = document.getElementById('txtGKitchen'+index).value;
    //     //         if (txtGKitchen !="") {
    //     //             prompt += "The " + floorDesc + " kitchen has features like " + txtGKitchen + ".";
    //     //         }
    //     //         var txtGbathroom = document.getElementById('txtGbathroom'+index).value;
    //     //         if (txtGbathroom !="") {
    //     //             prompt += "The " + floorDesc + " bathroom has features like " + txtGbathroom + ".";
    //     //         }
    //     //     }
    //     //     // home exterior end
    
    //     //     //home exterior start
    //     //     var txtFWHomeExterior = document.getElementById('txtFWHomeExterior').value;
    //     //     if (txtFWHomeExterior !="") {
    //     //         prompt += "The home exterior has features like " + txtFWHomeExterior + ".";
    //     //     }
    //     //     var txtFWNeighborhood = document.getElementById('txtFWNeighborhood').value;
    //     //     if (txtFWNeighborhood !="") {
    //     //         prompt += "Neighborhood includes " + txtFWNeighborhood + ".";
    //     //     }
    //     //     var txtFWSmartFeatures = document.getElementById('txtFWSmartFeatures').value;
    //     //     if (txtFWSmartFeatures !="") {
    //     //         prompt += "The smart features includes " + txtFWSmartFeatures + ".";
    //     //     }
    //     //     var txtFWAddRooms = document.getElementById('txtFWAddRooms').value;
    //     //     if (txtFWAddRooms !="") {
    //     //         prompt += "The property also has " + txtFWAddRooms + ".";
    //     //     }
    //     //     // floorwise end
    //     // }
    //     // else{
    //     //     //General Home Interior & Exterior
    //     //     //Home interior
    //     //     var txtGPBR = document.getElementById('txtGPBR').value;
    //     //     if (txtGPBR !="") {
    //     //         prompt += "The master bedroom has features like " + txtGPBR + ".";
    //     //     }
    //     //     var txtGLR = document.getElementById('txtGLR').value;
    //     //     if (txtGLR !="") {
    //     //         prompt += "The living room has features like " + txtGLR + ".";
    //     //     }
    //     //     var txtGKitchen = document.getElementById('txtGKitchen').value;
    //     //     if (txtGKitchen !="") {
    //     //         prompt += "The kitchen has features like " + txtGKitchen + ".";
    //     //     }
    //     //     var txtGbathroom = document.getElementById('txtGbathroom').value;
    //     //     if (txtGbathroom !="") {
    //     //         prompt += "The bathroom has features like " + txtGbathroom + ".";
    //     //     }
    //     //     //Home exterior
    //     //     var txtGHomeExterior = document.getElementById('txtGHomeExterior').value;
    //     //     if (txtGHomeExterior !="") {
    //     //         prompt += "The home exterior has features like " + txtGHomeExterior + ".";
    //     //     }
    //     //     var txtGNeighborhood = document.getElementById('txtGNeighborhood').value;
    //     //     if (txtGNeighborhood !="") {
    //     //         prompt += "Neighborhood includes " + txtGNeighborhood + ".";
    //     //     }
    //     //     var txtGSmartFeatures = document.getElementById('txtGSmartFeatures').value;
    //     //     if (txtGSmartFeatures !="") {
    //     //         prompt += "The smart features includes " + txtGSmartFeatures + ".";
    //     //     }
    //     //     var txtGAddRooms = document.getElementById('txtGAddRooms').value;
    //     //     if (txtGAddRooms !="") {
    //     //         prompt += "The property also has " + txtGAddRooms + ".";
    //     //     }
    //     // }


        
    //     // Step 3 end        
        
    //     // for headline and call to action
    //     prompt += " Also include headline note on header and call to action at the end of the description.";
    //     //
    
    //     //
    //     prompt +=" Make description paragraph wise."
    
    //     console.log(prompt);
    //     var lengthcontroller = "2000";//this.getselectedcontentlength();
    //     var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
    //     var toneofvoice = "";//this.gettexteleValue("toneofvoice", "Tone");
    //     var keywords = "";//this.gettexteleValue("keywordinput", "Keyword");
    //     var prompttext = ("Topic: "+ prompt);    
    //     var axios = require("axios");
        
    //     const FormData = require('form-data');
    //     var tokens = 4000;
    //     let data = new FormData();
    //     data.append('prompt', prompttext.trim());
    //     data.append('uid', (Role == 3 ? MasterId : UserID));
    //     data.append('cid', Companyid);
    //     data.append('token', tokens);
    
    //     let config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/posttextmessagenew',       
    //         data : data
    //     };
    
    //     axios(config)
    //       .then(function (resp) {
    //         //console.log(JSON.stringify(resp.data));
    //         var response = resp.data;
    //         console.log(response.Data);
           
    //         if (resp != null && response != "" && response.Data !="") {
    //           var datalist = response.Data.split('\n');
              
    //           var finalData="";
    //           for(var i=0;i<datalist.length;i++)
    //           {
    //               if(datalist[i]!="")
    //               {
    //                 //finalData = finalData+"<p>" + datalist[i] + "</p>";
    //                 finalData = finalData +datalist[i] +"\n";
    //               }
    //           }
    //           //self.appendhtmltoeditor("<p></p>"+ finalData);
    //           //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
    //           //setgeneratedcontent(datatoAdd);  
    //           hideoverlay();      
    //           //window.scrollTop = document.body.scrollHeight;
    //           $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
    //           t = 0;  
    //           finalData = finalData.replace("Headline: ","").replace("headline: ","").replace("Paragraph 1:","").replace("Paragraph 2:","").replace("Paragraph 3:","").replace("Paragraph 4:","").replace("Paragraph 5:","").replace("Paragraph 6:","");
    //           finalData = finalData.replace("Call to Action: ","").replace("Headline note:","").replace("Headline Note:","");       
    //           var divOuterContent = document.getElementById('divOuterContent');
    //           divOuterContent.scrollTop =  divOuterContent.scrollHeight; 
    //           typeWriter(finalData);
    //           //document.getElementById("lblCcount").innerHTML= finalData.length;
    //           //document.getElementById("divGeneratedContent").innerHTML=finalData;
    //           //document.getElementById("divOutputIcons").style.display="block"; moved totypewriter
    //           document.getElementById('divGeneratedContent').contentEditable ="true";
    //           document.getElementById("spanCharCount").style.display = "block";
    //           document.getElementById("h4Title").innerHTML="Property description for " + txtPropAddress;              
    //           //window.scrollTo(0, divOuterContent.scrollHeight);   
    
    //            // document.addEventListener("DOMContentLoaded", typeWriter);
    
    
    //         //   try {
    //         //     self.savegptresponse(
    //         //       JSON.stringify(resp.data),
    //         //       response.usage.total_tokens
    //         //     );
    //         //   } catch (error) {}
              
    //         }        
    //         hideoverlayprop();
    //         //document.getElementById("newloaderprop").style.display="none";
    //       })
          
    //       .catch(function (error) {
    //         console.log(error);
    //         hideoverlayprop();
    //         //document.getElementById("newloaderprop").style.display="none";
    //         customAlertMessage(
    //           "error",
    //           "Something went wrong, please try again."
    //         );
    //       });
    //   }

    
// function generateaicontentOld()  {
//     document.getElementById("divGeneratedContent").innerHTML="description start";
//     try {
//         document.getElementById("plagiarisminfo").style.display = "none";
//     } catch (error) {
        
//     }    
//     onTabClick(4);
//     document.getElementById("divGeneratedContent").innerHTML="";
//     //document.getElementById("newloaderprop").style.display="block";
//     //hideoverlay4();
//     showoverlayprop();
//     var prompt = "";        
//     var txtPropAddress = document.getElementById('txtPropAddress').value;
//     prompt = "Generate detailed property listing description for "+txtPropAddress;
//     if (prompt.trim() == "") {
//       customAlertMessage("error", "Please enter property address.");
//       return;
//     }    

//     // step 2 start ==>Property details
//     var propTypeSel = document.getElementById("ddlPropertyType").value;
//     if (propTypeSel != "select") {
//         prompt += " with " + propTypeSel +" propertytype.";
//     }
//     else{
//         prompt +=".";
//     }
//     var ddlBedroomsSel = document.getElementById("ddlBedrooms").value;
//     if (ddlBedroomsSel != "select") {
//         prompt += " It has "+ ddlBedroomsSel + " number of bedrooms and " ;
//     }
//     var ddlBathroomsSel = document.getElementById("ddlBathrooms").value;
//     if (ddlBathroomsSel != "select") {
//         prompt +=  ddlBathroomsSel + " number of bathrooms." ;
//     }
//     var ddlFloorsSel = document.getElementById("ddlFloors").value;
//     if (ddlFloorsSel != "select") {
//         prompt += " It is a " + ddlFloorsSel + " story home." ; 
//     }
//     var txtAreaSel = document.getElementById("txtArea").value;
//     if (txtAreaSel != "") {
//         prompt += "It is having an area of "+txtAreaSel+ " sqft.";
//     }
//     // lot size comented
//     // var txtAreaSel = document.getElementById("txtArea").value; 
//     // if (txtAreaSel != "") {
//     //     prompt += "and "+txtAreaSel+ " lot size.";
//     // }  
//     var ddlGaragetypeSel = document.getElementById("ddlGaragetype").value;
//     var ddlGaragespacesSel = document.getElementById("ddlGaragespaces").value;
//     if (ddlGaragetypeSel !="select" && ddlGaragespacesSel != "select") {
//         prompt += " It has " + ddlGaragetypeSel + " garage with "+ ddlGaragespacesSel + " spaces."; 
//     }
//     else if (ddlGaragetypeSel != "select") {
//         prompt += " It has " + ddlGaragetypeSel + " garage." ; 
//     }
//     else if (ddlGaragespacesSel != "select") {
//         prompt += " It has " + ddlGaragespacesSel + " garage." ; 
//     }
//     prompt += "The property most Saleable features include ";
//     var txtMostSalableSel = document.getElementById("txtMostSalable").value;
//     if (txtMostSalableSel != "") {
//         prompt += txtMostSalableSel + ".";
//     }
//     // Step 2 end

//     // step 1 start ==>Amenitinies
    
//     //school amenity 
//     var markedCheckbox = document.getElementsByName('schoolAmenity');  
//     var schoolAmenitiesel = "";
//     for (var checkbox of markedCheckbox) {  
//         if (checkbox.checked)  {
//             var lblIds = checkbox.id.replace("chkschool","lblschool");
//             schoolAmenitiesel += document.getElementById(lblIds).innerText + ', ';
//         }
//     } 
//     if (schoolAmenitiesel !="") {
//         prompt += "The property has near by schools including " + schoolAmenitiesel + ".";
//     }

//     var markedCheckboxHosp = document.getElementsByName('hospitalAmenity');  
//     var hospitalAmenitiesel = "";
//     for (var checkbox of markedCheckboxHosp) {  
//         if (checkbox.checked)  {
//             var lblIdshospital = checkbox.id.replace("chkhospital","lblhospital");
//             hospitalAmenitiesel += document.getElementById(lblIdshospital).innerText + ', ';
//         }
//     } 
//     if (hospitalAmenitiesel !="") {
//         prompt += "The property has near by hospitals including " + hospitalAmenitiesel + ".";
//     }

//     var markedCheckboxpark = document.getElementsByName('parkAmenity');  
//     var parkAmenitiesel = "";
//     for (var checkbox of markedCheckboxpark) {  
//         if (checkbox.checked)  {
//             var lblIdspark = checkbox.id.replace("chkpark","lblpark");
//             parkAmenitiesel += document.getElementById(lblIdspark).innerText + ', ';
//         }
//     } 
//     if (parkAmenitiesel !="") {
//         prompt += "The property has near by parks including " + parkAmenitiesel + ".";
//     }

//     var markedCheckboxsupermarket = document.getElementsByName('supermarketAmenity');  
//     var supermarketAmenitiesel = "";
//     for (var checkbox of markedCheckboxsupermarket) {  
//         if (checkbox.checked)  {
//             var lblIdssupermarket = checkbox.id.replace("chksupermarket","lblsupermarket");
//             supermarketAmenitiesel += document.getElementById(lblIdssupermarket).innerText + ', ';
//         }
//     } 
//     if (supermarketAmenitiesel !="") {
//         prompt += "The property has near by supermarkets including " + supermarketAmenitiesel + ".";
//     }

//     // Step 1 end

//     // step 3 start ==>property features
//     var rbtnFloorwise = document.getElementById("rbtnFloorwise").checked;
//     if(rbtnFloorwise){
//         //Floorwise Home Interior & Exterior
//         // home interior start
//         var ddlFloors = document.getElementById("ddlFloors").value;
//         var intFloors = parseInt(ddlFloors);
//         //alert(kbButtons);
//         var floorDesc = "first floor";
//         for (let index = 1; index <= intFloors; index++) {
//             switch (index) {
//                 case 1:
//                     floorDesc = "first floor";
//                     break;     
//                 case 2:
//                 floorDesc = "second floor";
//                 break; 
//                 case 3:
//                 floorDesc = "third floor";
//                 break; 
//                 case 4:
//                 floorDesc = "fourth floor";
//                 break; 
//                 case 5:
//                 floorDesc = "fifth floor";
//                 break;        
//                 default:
//                     break;
//             }
//             var txtGPBR = document.getElementById('txtGPBR'+index).value;
//             if (txtGPBR !="") {
//                 prompt += "The " + floorDesc + " master bedroom has features like " + txtGPBR + ".";
//             }
//             var txtGLR = document.getElementById('txtGLR'+index).value;
//             if (txtGLR !="") {
//                 prompt += "The " + floorDesc + " living room has features like " + txtGLR + ".";
//             }
//             var txtGKitchen = document.getElementById('txtGKitchen'+index).value;
//             if (txtGKitchen !="") {
//                 prompt += "The " + floorDesc + " kitchen has features like " + txtGKitchen + ".";
//             }
//             var txtGbathroom = document.getElementById('txtGbathroom'+index).value;
//             if (txtGbathroom !="") {
//                 prompt += "The " + floorDesc + " bathroom has features like " + txtGbathroom + ".";
//             }
//         }
//         // home exterior end

//         //home exterior start
//         var txtFWHomeExterior = document.getElementById('txtFWHomeExterior').value;
//         if (txtFWHomeExterior !="") {
//             prompt += "The home exterior has features like " + txtFWHomeExterior + ".";
//         }
//         var txtFWNeighborhood = document.getElementById('txtFWNeighborhood').value;
//         if (txtFWNeighborhood !="") {
//             prompt += "Neighborhood includes " + txtFWNeighborhood + ".";
//         }
//         var txtFWSmartFeatures = document.getElementById('txtFWSmartFeatures').value;
//         if (txtFWSmartFeatures !="") {
//             prompt += "The smart features includes " + txtFWSmartFeatures + ".";
//         }
//         var txtFWAddRooms = document.getElementById('txtFWAddRooms').value;
//         if (txtFWAddRooms !="") {
//             prompt += "The property also has " + txtFWAddRooms + ".";
//         }
//         // floorwise end
//     }
//     else{
//         //General Home Interior & Exterior
//         //Home interior
//         var txtGPBR = document.getElementById('txtGPBR').value;
//         if (txtGPBR !="") {
//             prompt += "The master bedroom has features like " + txtGPBR + ".";
//         }
//         var txtGLR = document.getElementById('txtGLR').value;
//         if (txtGLR !="") {
//             prompt += "The living room has features like " + txtGLR + ".";
//         }
//         var txtGKitchen = document.getElementById('txtGKitchen').value;
//         if (txtGKitchen !="") {
//             prompt += "The kitchen has features like " + txtGKitchen + ".";
//         }
//         var txtGbathroom = document.getElementById('txtGbathroom').value;
//         if (txtGbathroom !="") {
//             prompt += "The bathroom has features like " + txtGbathroom + ".";
//         }
//         //Home exterior
//         var txtGHomeExterior = document.getElementById('txtGHomeExterior').value;
//         if (txtGHomeExterior !="") {
//             prompt += "The home exterior has features like " + txtGHomeExterior + ".";
//         }
//         var txtGNeighborhood = document.getElementById('txtGNeighborhood').value;
//         if (txtGNeighborhood !="") {
//             prompt += "Neighborhood includes " + txtGNeighborhood + ".";
//         }
//         var txtGSmartFeatures = document.getElementById('txtGSmartFeatures').value;
//         if (txtGSmartFeatures !="") {
//             prompt += "The smart features includes " + txtGSmartFeatures + ".";
//         }
//         var txtGAddRooms = document.getElementById('txtGAddRooms').value;
//         if (txtGAddRooms !="") {
//             prompt += "The property also has " + txtGAddRooms + ".";
//         }
//     }
    
//     // Step 3 end        
    
//     // for headline and call to action
//     prompt += " Also include headline note on header and call to action at the end of the description.";
//     //

//     //
//     prompt +=" Make description paragraph wise."

//     console.log(prompt);
//     var lengthcontroller = "2000";//this.getselectedcontentlength();
//     var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
//     var toneofvoice = "";//this.gettexteleValue("toneofvoice", "Tone");
//     var keywords = "";//this.gettexteleValue("keywordinput", "Keyword");
//     var prompttext = ("Topic: "+ prompt);    
//     var axios = require("axios");
//     var data = JSON.stringify({
//       //model: "text-davinci-003",
//       prompt: prompttext.trim(),
//       temperature: 1,
//       max_tokens: parseInt(lengthcontroller),
//     });

//     var config = {
//       method: "post",
//       url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
//       headers: {
//         "Content-Type": "application/json",
//         "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
//       },
//       data: data,
//     };

//     axios(config)
//       .then(function (resp) {
//         //console.log(JSON.stringify(resp.data));
//         var response = resp.data;
//         console.log(response.choices[0].text);
       
//         if (response.choices != null && response.choices.length > 0) {
//           var datalist = response.choices[0].text.split('\n');
          
//           var finalData="";
//           for(var i=0;i<datalist.length;i++)
//           {
//               if(datalist[i]!="")
//               {
//                 //finalData = finalData+"<p>" + datalist[i] + "</p>";
//                 finalData = finalData +datalist[i] +"\n";
//               }
//           }
//           //self.appendhtmltoeditor("<p></p>"+ finalData);
//           //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
//           //setgeneratedcontent(datatoAdd);  
//           hideoverlay();      
//           t = 0;  
//           finalData = finalData.replace("Headline: ","").replace("headline: ","").replace("Paragraph 1:","").replace("Paragraph 2:","").replace("Paragraph 3:","").replace("Paragraph 4:","").replace("Paragraph 5:","").replace("Paragraph 6:","");
//           finalData = finalData.replace("Call to Action: ","").replace("Headline note:","").replace("Headline Note:","");          
//           window.scrollTo(0, document.body.scrollHeight);
//           var divOuterContent = document.getElementById('divOuterContent');
//           divOuterContent.scrollTop =  divOuterContent.scrollHeight; 
//           typeWriter(finalData);
//           //document.getElementById("lblCcount").innerHTML= finalData.length;
//           //document.getElementById("divGeneratedContent").innerHTML=finalData;
//           document.getElementById("divOutputIcons").style.display="block";          
//           document.getElementById('divGeneratedContent').contentEditable ="true";          

            

//            // document.addEventListener("DOMContentLoaded", typeWriter);


//         //   try {
//         //     self.savegptresponse(
//         //       JSON.stringify(resp.data),
//         //       response.usage.total_tokens
//         //     );
//         //   } catch (error) {}
          
//         }        
//         hideoverlayprop();
//         //document.getElementById("newloaderprop").style.display="none";
//       })
      
//       .catch(function (error) {
//         console.log(error);
//         hideoverlayprop();
//         //document.getElementById("newloaderprop").style.display="none";
//         customAlertMessage(
//           "error",
//           "Something went wrong, please try again."
//         );
//       });
//   }

  //let grammarlyKeyData = (Cookies.get('g_key') != undefined ? Cookies.get('g_key') : '');  

  //let copySacpeAvailable;// = checkcopyscape();
    

//class Propertydescription extends React.Component {
    function Listingdescription() {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    const [locationId, setLocationId] = useState("");
    const [generatedcontent, setgeneratedcontent] = useState("");
    const [copySacpeAvailable, setDataprop] = useState(false);
    const [grammarlyKeyData, setgrammarlyKeyData] = useState("");
    const [plagiarismmodalbody,setplagiarismmodalbody] = useState({ __html: '' });
    const [uploadedfile,setuploadedfile] = useState([]);
    const [processedimage, setprocessedimage] = useState([]);
    const [orderedprocessedimage, setorderedprocessedimage] = useState([]);
    const [dropdownvalues, setdropdownvalues] = useState([]);
    const [videocreatedformdata, setvideocreatedformdata] = useState({});

    useEffect(() => {
        componentDidMount();
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxpx25xmwMAYNW3Diz0TjL3N8CtTURTK0&libraries=places`,
          () => handleScriptLoad(setQuery, autoCompleteRef,setLocationId)
        );
        checkcopyscape();
        var localgrammarlyKeyData = (Cookies.get('g_key') != undefined ? Cookies.get('g_key') : ''); 
        setgrammarlyKeyData(localgrammarlyKeyData);
      }, []);

      const typeWriter =(datalist,Dataobjects)=> {    
        var speed = 10;
        if (t < datalist.length) {        
            var textLst = datalist.charAt(t);
            document.getElementById("divGeneratedContent").innerHTML += textLst=== "\n" ? "<p></p>": textLst;
            t++;
            document.getElementById("lblCcount").innerHTML= t;
            setTimeout(function(){typeWriter(datalist,Dataobjects)}, speed);
            //window.scrollTo(0, document.body.scrollHeight);
            var elementsDiv1 = document.getElementById("divGeneratedContent");
            elementsDiv1.scrollTop = elementsDiv1.scrollHeight;               
        }
        if(t + 1 ==  datalist.length){
            console.log('inside final');
            document.getElementById("divOutputIcons").style.display = "block";
            Highlightkeys(Dataobjects);
    
        }
    }
      const checkCountersLimit=()=>{
        var userI = getAccessToken();
        var decodedUser1 = decodeURIComponent(userI);
        var parsedRes1 = JSON.parse(decodedUser1); 
        var PlanType = parsedRes1.PlanType;  
        if (PlanType < 1) { // free trial
            const axios = require('axios');
            let config = {
              method: 'get',
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedPropertyDescription',
              headers: { }
            };
          
            axios.request(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data != undefined) {
                if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
                    var availblecounter = response.data.Data;
                    if (availblecounter <= 0) {
                            document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
                            document.getElementById('hrefsubscription').click();
                            return;
                    }
                    else{
                        // generateaicontentCounters();
                        movetoordering();
                    }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
            // end of free trial
        }else {
            // generateaicontentCounters();
            movetoordering();
        }
    }
    
    const generateaicontent=()=>{
        createnewcontent = true;
        showoverlayprop();
        setTimeout(() => {
            checkCountersLimit();    
        }, 3000);        
    }

      const generateaicontentCounters=()=>{        
        document.getElementById("divGeneratedContent").innerHTML="description start";
        try {
            document.getElementById("plagiarisminfo").style.display = "none";
        } catch (error) {
            
        }    
        onTabClick(4);
        document.getElementById("divGeneratedContent").innerHTML="";
        document.getElementById("h4Title").innerHTML="";
        document.getElementById("spanCharCount").style.display = "none";
        document.getElementById("divOutputIcons").style.display = "none";
        //document.getElementById("newloaderprop").style.display="block";
        //hideoverlay4();
        //var limitReached = checkCountersLimit();       
        showoverlayprop();
        var prompt = "";        
        var txtPropAddress = document.getElementById('txtPropAddress').value;
        prompt = "Generate detailed property listing description for "+txtPropAddress;
        if (prompt.trim() == "") {
          customAlertMessage("error", "Please enter property address.");
          return;
        }    
    
        // step 2 start ==>Property details
        var propTypeSel = document.getElementById("ddlPropertyType").value;
        if (propTypeSel != "select") {
            prompt += " with " + propTypeSel +" propertytype.";
        }
        else{
            prompt +=".";
        }
        var ddlBedroomsSel = document.getElementById("ddlBedrooms").value;
        if (ddlBedroomsSel != "select") {
            prompt += " It has "+ ddlBedroomsSel + " number of bedrooms and " ;
        }
        var ddlBathroomsSel = document.getElementById("ddlBathrooms").value;
        if (ddlBathroomsSel != "select") {
            prompt +=  ddlBathroomsSel + " number of bathrooms." ;
        }
        var ddlFloorsSel = document.getElementById("ddlFloors").value;
        if (ddlFloorsSel != "select") {
            prompt += " It is a " + ddlFloorsSel + " story home." ; 
        }
        var txtAreaSel = document.getElementById("txtArea").value;
        if (txtAreaSel != "") {
            prompt += "It is having an area of "+txtAreaSel+ " sqft.";
        }
        // lot size comented
        // var txtAreaSel = document.getElementById("txtArea").value; 
        // if (txtAreaSel != "") {
        //     prompt += "and "+txtAreaSel+ " lot size.";
        // }  
        var ddlGaragetypeSel = document.getElementById("ddlGaragetype").value;
        var ddlGaragespacesSel = document.getElementById("ddlGaragespaces").value;
        if (ddlGaragetypeSel !="select" && ddlGaragespacesSel != "select") {
            prompt += " It has " + ddlGaragetypeSel + " garage with "+ ddlGaragespacesSel + " spaces."; 
        }
        else if (ddlGaragetypeSel != "select") {
            prompt += " It has " + ddlGaragetypeSel + " garage." ; 
        }
        else if (ddlGaragespacesSel != "select") {
            prompt += " It has " + ddlGaragespacesSel + " garage." ; 
        }
        prompt += "The property most Saleable features include ";
        var txtMostSalableSel = document.getElementById("txtMostSalable").value;
        if (txtMostSalableSel != "") {
            prompt += txtMostSalableSel + ".";
        }
        // Step 2 end
    
        // step 1 start ==>Amenitinies
        
        //school amenity 
        var markedCheckbox = document.getElementsByName('schoolAmenity');  
        var schoolAmenitiesel = "";
        for (var checkbox of markedCheckbox) {  
            if (checkbox.checked)  {
                var lblIds = checkbox.id.replace("chkschool","lblschool");
                schoolAmenitiesel += document.getElementById(lblIds).innerText + ', ';
            }
        } 
        if (schoolAmenitiesel !="") {
            prompt += "The property has near by schools including " + schoolAmenitiesel + ".";
        }
    
        var markedCheckboxHosp = document.getElementsByName('hospitalAmenity');  
        var hospitalAmenitiesel = "";
        for (var checkbox of markedCheckboxHosp) {  
            if (checkbox.checked)  {
                var lblIdshospital = checkbox.id.replace("chkhospital","lblhospital");
                hospitalAmenitiesel += document.getElementById(lblIdshospital).innerText + ', ';
            }
        } 
        if (hospitalAmenitiesel !="") {
            prompt += "The property has near by hospitals including " + hospitalAmenitiesel + ".";
        }
    
        var markedCheckboxpark = document.getElementsByName('parkAmenity');  
        var parkAmenitiesel = "";
        for (var checkbox of markedCheckboxpark) {  
            if (checkbox.checked)  {
                var lblIdspark = checkbox.id.replace("chkpark","lblpark");
                parkAmenitiesel += document.getElementById(lblIdspark).innerText + ', ';
            }
        } 
        if (parkAmenitiesel !="") {
            prompt += "The property has near by parks including " + parkAmenitiesel + ".";
        }
    
        var markedCheckboxsupermarket = document.getElementsByName('supermarketAmenity');  
        var supermarketAmenitiesel = "";
        for (var checkbox of markedCheckboxsupermarket) {  
            if (checkbox.checked)  {
                var lblIdssupermarket = checkbox.id.replace("chksupermarket","lblsupermarket");
                supermarketAmenitiesel += document.getElementById(lblIdssupermarket).innerText + ', ';
            }
        } 
        if (supermarketAmenitiesel !="") {
            prompt += "The property has near by supermarkets including " + supermarketAmenitiesel + ".";
        }
    
        // Step 1 end
    
        // step 3 start ==>property features
        // var rbtnFloorwise = document.getElementById("rbtnFloorwise").checked;
        // if(rbtnFloorwise){
        //     //Floorwise Home Interior & Exterior
        //     // home interior start
        //     var ddlFloors = document.getElementById("ddlFloors").value;
        //     var intFloors = parseInt(ddlFloors);
        //     //alert(kbButtons);
        //     var floorDesc = "first floor";
        //     for (let index = 1; index <= intFloors; index++) {
        //         switch (index) {
        //             case 1:
        //                 floorDesc = "first floor";
        //                 break;     
        //             case 2:
        //             floorDesc = "second floor";
        //             break; 
        //             case 3:
        //             floorDesc = "third floor";
        //             break; 
        //             case 4:
        //             floorDesc = "fourth floor";
        //             break; 
        //             case 5:
        //             floorDesc = "fifth floor";
        //             break;        
        //             default:
        //                 break;
        //         }
        //         var txtGPBR = document.getElementById('txtGPBR'+index).value;
        //         if (txtGPBR !="") {
        //             prompt += "The " + floorDesc + " master bedroom has features like " + txtGPBR + ".";
        //         }
        //         var txtGLR = document.getElementById('txtGLR'+index).value;
        //         if (txtGLR !="") {
        //             prompt += "The " + floorDesc + " living room has features like " + txtGLR + ".";
        //         }
        //         var txtGKitchen = document.getElementById('txtGKitchen'+index).value;
        //         if (txtGKitchen !="") {
        //             prompt += "The " + floorDesc + " kitchen has features like " + txtGKitchen + ".";
        //         }
        //         var txtGbathroom = document.getElementById('txtGbathroom'+index).value;
        //         if (txtGbathroom !="") {
        //             prompt += "The " + floorDesc + " bathroom has features like " + txtGbathroom + ".";
        //         }
        //     }
        //     // home exterior end
    
        //     //home exterior start
        //     var txtFWHomeExterior = document.getElementById('txtFWHomeExterior').value;
        //     if (txtFWHomeExterior !="") {
        //         prompt += "The home exterior has features like " + txtFWHomeExterior + ".";
        //     }
        //     var txtFWNeighborhood = document.getElementById('txtFWNeighborhood').value;
        //     if (txtFWNeighborhood !="") {
        //         prompt += "Neighborhood includes " + txtFWNeighborhood + ".";
        //     }
        //     var txtFWSmartFeatures = document.getElementById('txtFWSmartFeatures').value;
        //     if (txtFWSmartFeatures !="") {
        //         prompt += "The smart features includes " + txtFWSmartFeatures + ".";
        //     }
        //     var txtFWAddRooms = document.getElementById('txtFWAddRooms').value;
        //     if (txtFWAddRooms !="") {
        //         prompt += "The property also has " + txtFWAddRooms + ".";
        //     }
        //     // floorwise end
        // }
        // else{
        //     //General Home Interior & Exterior
        //     //Home interior
        //     var txtGPBR = document.getElementById('txtGPBR').value;
        //     if (txtGPBR !="") {
        //         prompt += "The master bedroom has features like " + txtGPBR + ".";
        //     }
        //     var txtGLR = document.getElementById('txtGLR').value;
        //     if (txtGLR !="") {
        //         prompt += "The living room has features like " + txtGLR + ".";
        //     }
        //     var txtGKitchen = document.getElementById('txtGKitchen').value;
        //     if (txtGKitchen !="") {
        //         prompt += "The kitchen has features like " + txtGKitchen + ".";
        //     }
        //     var txtGbathroom = document.getElementById('txtGbathroom').value;
        //     if (txtGbathroom !="") {
        //         prompt += "The bathroom has features like " + txtGbathroom + ".";
        //     }
        //     //Home exterior
        //     var txtGHomeExterior = document.getElementById('txtGHomeExterior').value;
        //     if (txtGHomeExterior !="") {
        //         prompt += "The home exterior has features like " + txtGHomeExterior + ".";
        //     }
        //     var txtGNeighborhood = document.getElementById('txtGNeighborhood').value;
        //     if (txtGNeighborhood !="") {
        //         prompt += "Neighborhood includes " + txtGNeighborhood + ".";
        //     }
        //     var txtGSmartFeatures = document.getElementById('txtGSmartFeatures').value;
        //     if (txtGSmartFeatures !="") {
        //         prompt += "The smart features includes " + txtGSmartFeatures + ".";
        //     }
        //     var txtGAddRooms = document.getElementById('txtGAddRooms').value;
        //     if (txtGAddRooms !="") {
        //         prompt += "The property also has " + txtGAddRooms + ".";
        //     }
        // }


        
        // Step 3 end        
        
        // for headline and call to action
        prompt += " Also include headline note on header and call to action at the end of the description.";
        //
    
        //
        prompt +=" Make description paragraph wise."
    
        console.log(prompt);
        var lengthcontroller = "2000";//this.getselectedcontentlength();
        var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
        var toneofvoice = "";//this.gettexteleValue("toneofvoice", "Tone");
        var keywords = "";//this.gettexteleValue("keywordinput", "Keyword");
        var prompttext = (prompt);    
        var axios = require("axios");
        
        const FormData = require('form-data');
        var tokens = 4000;
        let data = new FormData();
        data.append('prompt', prompttext.trim());
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);
        data.append('token', tokens);
    
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/GetGroqResponse',       
            data : data
        };
    
        axios(config)
          .then(function (resp) {
            //console.log(JSON.stringify(resp.data));
            var response = resp.data;
            console.log(response.Data);
           
            if (resp != null && response != "" && response.Data !="") {
              var datalist = response.Data.split('\n');
              
              var finalData="";
              for(var i=0;i<datalist.length;i++)
              {
                  if(datalist[i]!="")
                  {
                    //finalData = finalData+"<p>" + datalist[i] + "</p>";
                    finalData = finalData +datalist[i] +"\n";
                  }
              }
              //self.appendhtmltoeditor("<p></p>"+ finalData);
              //const datatoAdd = finalData.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
              //setgeneratedcontent(datatoAdd);  
              hideoverlay();      
              //window.scrollTop = document.body.scrollHeight;
              $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
              t = 0;  
              finalData = finalData.replace("Headline: ","").replace("headline: ","").replace("Paragraph 1:","").replace("Paragraph 2:","").replace("Paragraph 3:","").replace("Paragraph 4:","").replace("Paragraph 5:","").replace("Paragraph 6:","");
              finalData = finalData.replace("Call to Action: ","").replace("Headline note:","").replace("Headline Note:","");       
              var divOuterContent = document.getElementById('divOuterContent');
              divOuterContent.scrollTop =  divOuterContent.scrollHeight; 
              typeWriter(finalData);
              //document.getElementById("lblCcount").innerHTML= finalData.length;
              //document.getElementById("divGeneratedContent").innerHTML=finalData;
              //document.getElementById("divOutputIcons").style.display="block"; moved totypewriter
              document.getElementById('divGeneratedContent').contentEditable ="true";
              document.getElementById("spanCharCount").style.display = "block";
              document.getElementById("h4Title").innerHTML="Property description for " + txtPropAddress;              
              //window.scrollTo(0, divOuterContent.scrollHeight);   
    
               // document.addEventListener("DOMContentLoaded", typeWriter);
    
    
            //   try {
            //     self.savegptresponse(
            //       JSON.stringify(resp.data),
            //       response.usage.total_tokens
            //     );
            //   } catch (error) {}
              
            }        
            hideoverlayprop();
            //document.getElementById("newloaderprop").style.display="none";
          })
          
          .catch(function (error) {
            console.log(error);
            hideoverlayprop();
            //document.getElementById("newloaderprop").style.display="none";
            customAlertMessage(
              "error",
              "Something went wrong, please try again."
            );
          });
      }

      const componentDidMount=()=>{
        // load initial data
        onTabClick(1);
        document.addEventListener('copy', function(e) {
            const text_only = document.getSelection().toString();
            const clipdata = e.clipboardData || window.clipboardData;  
            clipdata.setData('text/plain', text_only);  
            e.preventDefault();
          });
    };
    
    const validateNext =(currentTab)=> {
        switch (currentTab) {
            case 1:
                var res = validatePropertyAddress();
                if (res) {
                  onTabClick(2);
                }
                else {
                    //alert
                }
                break;
            case 2:
                var res = validatePropertyDetails();
                if (res) {
                    onTabClick(3);
                }
                else {
                    //alert
                }
                break;
            case 3:
                var res = validatePropertyFeatures();
                if (res) {
                    generateaicontent();                              
                }
                else {
                    //alert
                }
                break;
            default:
                break;
        }
    }

      const clearAllDataNew=()=>{
        // debugger;
        // var resclear = prompt ("Start new will clear all data. Are you sure you want to proceed.");
        // if(resclear == false){
        //     return;
        // }
        showoverlay4();
        clearPropAddress();
        try {
            setuploadedfile([]);
            setprocessedimage([]);
            setorderedprocessedimage([]);
            setdropdownvalues([]);
            setvideocreatedformdata({});
            document.getElementById("divGeneratedContent").innerHTML="";
            document.getElementById("ddlPropertyType").value = "select";
            document.getElementById("ddlBedrooms").value ="select";
            document.getElementById("ddlBathrooms").value = "select";
            document.getElementById("ddlFloors").value = "select";
            document.getElementById("txtArea").value = "";
            document.getElementById("ddlGaragetype").value = "select";
            document.getElementById("ddlGaragespaces").value = "select";
            document.getElementById("txtMostSalable").value = ""; 
            //--Salableselected
            removeClassFun("Salableselected");
            document.getElementById("txtGPBR").value = ""; 
            //--HIMBselected
            removeClassFun("HIMBselected");
            document.getElementById("txtGLR").value = ""; 
            //--HILivingRoomselected
            removeClassFun("HILivingRoomselected");
            document.getElementById("txtGKitchen").value = ""; 
            //-- HIKitchenselected
            removeClassFun("HIKitchenselected");
            document.getElementById("txtGbathroom").value = ""; 
            //-- HIBathroomselected
            removeClassFun("HIBathroomselected");
            document.getElementById("txtGHomeExterior").value = ""; 
            //--GHEselected
            removeClassFun("GHEselected");
            document.getElementById("txtGNeighborhood").value = ""; 
            //-- GNeighborhoodselected
            removeClassFun("GNeighborhoodselected");
            document.getElementById("txtGSmartFeatures").value = ""; 
            //-- GSmartFeaturesselected
            removeClassFun("GSmartFeaturesselected");
            document.getElementById("txtGAddRooms").value = ""; 
            //-- GAddRoomsselected
            removeClassFun("GAddRoomsselected");
    
            // floor wise clearing
            for (let index = 1; index <= 5; index++) {
                document.getElementById("txtGPBR"+index).value = ""; 
                //--HIMBselected
                removeClassFun("HIMBselected"+index);
                document.getElementById("txtGLR"+index).value = ""; 
                //--HILivingRoomselected
                removeClassFun("HILivingRoomselected"+index);
                document.getElementById("txtGKitchen"+index).value = ""; 
                //-- HIKitchenselected
                removeClassFun("HIKitchenselected"+index);
                document.getElementById("txtGbathroom"+index).value = ""; 
                //-- HIBathroomselected
                removeClassFun("HIBathroomselected"+index);
            }
            document.getElementById("txtFWHomeExterior").value = ""; 
            //--GHEselected
            removeClassFun("FWHEselected");
            document.getElementById("txtFWNeighborhood").value = ""; 
            //-- GNeighborhoodselected
            removeClassFun("FWNeighborhoodselected");
            document.getElementById("txtFWSmartFeatures").value = ""; 
            //-- GSmartFeaturesselected
            removeClassFun("FWSmartFeaturesselected");
            document.getElementById("txtFWAddRooms").value = ""; 
            //-- GAddRoomsselected
            removeClassFun("FWAddRoomsselected");
            //floor wise clearing end
    
        } catch (error) {
            
        }
        onTabClick(1);
        hideoverlay4();
    }

      const onTabClick = (selectedTI) => {  
        var ctab = document.getElementById("hfCurrentTab").value;  
        switch (selectedTI) {
            case 1:
                document.getElementById('divpropertyaddress').style.display = "block";
                document.getElementById('divpropertydetails').style.display = "none";
                document.getElementById('divpropertyfeatures').style.display = "none";
                document.getElementById('divdescription').style.display = "none";
                document.getElementById("div1").classList.remove("inactive");
                document.getElementById("div2").classList.remove("inactive");
                document.getElementById("div2").classList.add("inactive");
                document.getElementById("div3").classList.remove("inactive");
                document.getElementById("div3").classList.add("inactive");
                document.getElementById("div4").classList.remove("inactive");
                document.getElementById("div4").classList.add("inactive");
                break;
            case 2:            
                if(ctab == "1"){
                    var valres = validatePropertyAddress();
                    if(valres == false){
                        return;
                    }
                }
                document.getElementById('divpropertyaddress').style.display = "none";
                document.getElementById('divpropertydetails').style.display = "block";
                document.getElementById('divpropertyfeatures').style.display = "none";
                document.getElementById('divdescription').style.display = "none";
                document.getElementById("div1").classList.remove("inactive");
                document.getElementById("div1").classList.add("inactive");
                document.getElementById("div2").classList.remove("inactive");            
                document.getElementById("div3").classList.remove("inactive");
                document.getElementById("div3").classList.add("inactive");
                document.getElementById("div4").classList.remove("inactive");
                document.getElementById("div4").classList.add("inactive");
                document.getElementById('countno1').style.display = "none";
                document.getElementById('checkmarkvalid1').style.display = "block";
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                break;
            case 3:
                //validateNext(2);
                if(ctab == "2"){
                    var valres = validatePropertyDetails();
                    if(valres == false){
                        return;
                    }
                }
                if(ctab == "1"){
                    var valres = validatePropertyAddress();
                    if(valres == false){
                        return;
                    }
                    valres = validatePropertyDetailsFromTab1();
                    if(valres == false){
                        return;
                    }
                }
                // rbtnGeneral // rbtnFloorwise
                var rbtnFloorwise = document.getElementById("rbtnFloorwise").checked;
                if(rbtnFloorwise){
                    //divGeneralFeatures divFloorwiseFeatures
                    // document.getElementById('divGeneralFeatures').style.display = "none";
                    document.getElementById('divFloorwiseFeatures').style.display = "block";
                    //tabFloor2
                    var ddlFloors = document.getElementById("ddlFloors").value;
                    var intFloors = parseInt(ddlFloors);
                    var content = document.getElementById("divFloorwiseFeatures");
                    var kbButtons = content.getElementsByTagName("button")[0].id;
                    kbButtons = kbButtons.replace("tab-1","");
                    //alert(kbButtons);
                    for (let index = 2; index < 6; index++) {
                        if(index <= intFloors ){
                            document.getElementById(kbButtons +'tab-'+ index).style.display = "block";
                        }
                        else{
                            document.getElementById(kbButtons +'tab-'+ index).style.display = "none";
                        }
                    }
    
                }
                else{
                    // document.getElementById('divGeneralFeatures').style.display = "block";
                    // document.getElementById('divFloorwiseFeatures').style.display = "none";
                }
                document.getElementById('divpropertyaddress').style.display = "none";
                document.getElementById('divpropertydetails').style.display = "none";
                document.getElementById('divpropertyfeatures').style.display = "block";
                document.getElementById('divdescription').style.display = "none";
                document.getElementById("div1").classList.remove("inactive");
                document.getElementById("div1").classList.add("inactive");
                document.getElementById("div2").classList.remove("inactive");   
                document.getElementById("div2").classList.add("inactive");         
                document.getElementById("div3").classList.remove("inactive");            
                document.getElementById("div4").classList.remove("inactive");
                document.getElementById("div4").classList.add("inactive");
                document.getElementById('countno2').style.display = "none";
                document.getElementById('checkmarkvalid2').style.display = "block";
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                loaddropdownfromstate();
                break;
            case 4:
                var content = document.getElementById("divGeneratedContent").innerText;
                if (content == "") {
                    customAlertMessage('error', 'Not generated any description yet.');
                    return;
                }
                document.getElementById('divpropertyaddress').style.display = "none";
                document.getElementById('divpropertydetails').style.display = "none";
                document.getElementById('divpropertyfeatures').style.display = "none";
                document.getElementById('divdescription').style.display = "block";
                document.getElementById("div1").classList.remove("inactive");
                document.getElementById("div1").classList.add("inactive");
                document.getElementById("div2").classList.remove("inactive");   
                document.getElementById("div2").classList.add("inactive");         
                document.getElementById("div3").classList.remove("inactive");                        
                document.getElementById("div3").classList.add("inactive");
                document.getElementById("div4").classList.remove("inactive");
                document.getElementById('countno3').style.display = "none";
                document.getElementById('countno4').style.display = "none";
                document.getElementById('checkmarkvalid3').style.display = "block";
                document.getElementById('checkmarkvalid4').style.display = "block";
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                break;
    
            default:
                document.getElementById('divpropertyaddress').style.display = "block";
                document.getElementById('divpropertydetails').style.display = "none";
                document.getElementById('divpropertyfeatures').style.display = "none";
                document.getElementById('divdescription').style.display = "none";
                document.getElementById("div1").classList.remove("inactive");
                document.getElementById("div2").classList.remove("inactive");
                document.getElementById("div2").classList.add("inactive");
                document.getElementById("div3").classList.remove("inactive");
                document.getElementById("div3").classList.add("inactive");
                document.getElementById("div4").classList.remove("inactive");
                document.getElementById("div4").classList.add("inactive");
                break;
        }
        document.getElementById("hfCurrentTab").value=selectedTI;
    };
      //setgeneratedcontent("");

      const optionsBedrroms = [
        '1', '2', '3','4','5','6','7','8','9','10'
      ];  
      const optionsBathroms = [
        '1','1.5', '2', ,'2.5','3','3.5','4','4.5','5','5.5','6','6.5','7','7.5','8','8.5','9','9.5','10'
      ];  
      const optionsFloors = [
        '1', '2', '3','4','5'
      ];  
      const optionsGarage=[
        '1 car', '2 cars', '3 cars','4 cars','5 cars'
      ]
      const optionspropertyType =[
        { value: "Single-family", label: "Single-family" },    
        { value: "Multi-family", label: "Multi-family" },
        { value: "Condo",  label: "Condo" },    
        { value: "Villa", label: "Villa" },
        { value: "Townhouse", label: "Townhouse" },
        { value: "Apartment", label: "Apartment" },
        { value: "Bungalow", label: "Bungalow" },
      ]

     const setPropertyType = (ee) =>{
        //debugger;
        var anchorEle = document.getElementById("ddlPropertyType");
//console.log(anchorEle);
        document.getElementById('hfPropertyType').value=anchorEle.value;
    }
    
    function checkcopyscape(){
        const axios = require('axios');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
        };
      
        axios.request(config)
        .then((response) => {
          if (response.data != null && response.data.Data !="") {
            var resd = response.data.Data;
            setDataprop(resd);
          }
        })
        .catch((error) => {
          console.log(error);
          setDataprop(false);
        });
      }

      function Checkplagiarism() {
        try {
          if (document.getElementById("divGeneratedContent").innerText == "") { 
            this.customAlertMessage(
              "warning",
              "cannot check plagiarism for empty content."
            );
            return;
          }
        } catch (error) {
          this.customAlertMessage("error", "Error occured, Please try again.");
          return;
        }
    
        showoverlay4();
    
        var axios = require("axios");
        var FormData = require("form-data");
        var data = new FormData();
        data.append(
          "postdata",
          document.getElementById("divGeneratedContent").innerText.trim()
        );
        data.append("uid",(Role == 3 ? MasterId : UserID));
        data.append("companyid",Companyid);
    
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
    
        axios(config)
          .then(function (resp) {
            var response = resp.data;
            if (response.Data != null) {
              if (response.Data.count != null && response.Data.count === 0) {
                customAlertMessage("success", "No plagiarism found");
                document.getElementById("plagiarisminfo").style.display = "none";
                // document.getElementById("plagiarismmodalbody").innerHTML = "";
                setplagiarismmodalbody({ __html: '' });
                // $(elem).find('.checkmark').show();
                // $(elem).find('.ban').hide();
                // $(elem).attr('tooltip', "No plagiarism found.");
                // $(elem).attr('onclick', "return false;");
              } else if (response.Message == "small content") {
                customAlertMessage("info", response.Data);
              } else {
                document.getElementById("plagiarisminfo").innerText =
                  response.Data.count + " copies found";
                document.getElementById("plagiarisminfo").style.display =
                  "inline-block";
                var htmlnew = duplicateresultcontent;
                htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
                htmlnew = htmlnew.replace(
                  "[PHCSquerywords]",
                  response.Data.querywords
                );
                var duplicateresultparagraphlist = "";
                for (var i = 0; i < response.Data.count; i++) {
                  var result = response.Data.result[i];
                  var duplicateresultparacontent =
                    duplicateresultparacontenttemplate;
                  duplicateresultparacontent = duplicateresultparacontent.replace(
                    "[PHCSurl]",
                    result.url
                  );
                  duplicateresultparacontent = duplicateresultparacontent.replace(
                    "[PHCSurltext]",
                    result.url
                  );
                  duplicateresultparacontent = duplicateresultparacontent.replace(
                    "[PHCStitle]",
                    result.title
                  );
                  duplicateresultparacontent = duplicateresultparacontent.replace(
                    "[PHCShtmlsnippet]",
                    result.textsnippet
                  );
                  duplicateresultparagraphlist =
                    duplicateresultparagraphlist + duplicateresultparacontent;
                }
                htmlnew = htmlnew.replace(
                  "[PHCSparagraphlist]",
                  duplicateresultparagraphlist
                );
                // document.getElementById("plagiarismmodalbody").innerHTML = htmlnew;
                setplagiarismmodalbody({ __html:htmlnew});
                customAlertMessage("error", "Plagiarism found");
              }
            } else {
              customAlertMessage("error", "Error occured, Please try again."); // 'success','failure','info'
            }
            hideoverlay4();
          })
          .catch(function (error) {
            //console.log(error);
            customAlertMessage("error", "Something went wrong, Try Again.");
            hideoverlay4();
          });
      };
      const dropzonechange = (e)=> {
        var tempfilesarr = e.target.files;// $(".dropzone")[0].files;
        if (tempfilesarr.length <= 0) {
            return;
        }
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 10485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                    // if (alreadyuploadedfile.length > 0) {
                    //     continue;
                    // }
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);

                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                    console.log('file added: '+ element.name);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                                // if (alreadyuploadedfile.length <= 0) {
                                //     continue;
                                // }
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);

                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                console.log('file added iphone: '+ file.name);
                                console.log('files added : '+ tempfileaftervalidation.length);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    console.log('fired to load inside iphone')
                                    setTimeout(() => {
                                        readFile(tempfileaftervalidation);
                                        setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);    
                                    }, 2000);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);  
                                }
                            });
                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFile(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
            console.log('fired to load outside iphone')
        }
    };
    const dropzonedragenter = (e) => {
        e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').addClass('dragover');
    }
    const dropzonedragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').addClass('dragover');
    };
    const dropzonedragleave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').removeClass('dragover');
    };
    const dropzonefiledrop = (event) => {
        event.preventDefault();
        var tempfilesarr = event.dataTransfer.files;
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 10485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);

                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);

                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFileondrop(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            });

                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFileondrop(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
        }
        // $(".dropzone")[0].value = "";
    };

    const readFileondrop = (inputfiles) => {
        var singletimearray = []; // processedimage;
        var propertyorderedlist = [];// [...orderedprocessedimage];
        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id = timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                if (orderedprocessedimage.length > 0) {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                     var processedimagenew = singletimearray.sort(function (a, b) { return a.id - b.id; });                  
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                }

            };
            reader.readAsDataURL(element);
        }
    }
    const showoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "block";
        $("body").addClass("loader");
    };
    const hideoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "none";
        $("body").removeClass("loader");
    };
    const loaddropdownfromstate = () => {
        if (dropdownvalues.length > 0) {
            var selectddl = document.getElementsByClassName('imageoption');
            for (let i = 0; i < selectddl.length; i++) {
                const element = selectddl[i];
                var refid = element.dataset.filerefid;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    element.value = selectedoption[0].text;
                }
            }
            hideoverlaytwo();
        }
        else {
            hideoverlaytwo();
        }
    }
    const readFile = (inputfiles) => {
        // var input = $('.dropzone')[0];
        // var inputfiles = input.files;
        var singletimearray = [];// [...processedimage];
        var propertyorderedlist = [];// [...orderedprocessedimage];
        console.log('inputfiles.length',inputfiles.length);
        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            console.log('reading inputfiles : ',element.name);
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id =timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                if (orderedprocessedimage.length > 0) {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                    var processedimagenew = singletimearray.sort(function (a, b) { return a.id - b.id; });
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                }
            };
            reader.readAsDataURL(element);
        }
    }
    const removerequiredfield = (e) => {
        var vallll = e.currentTarget.value;
        var element = e.currentTarget;
        var tempdropdownvalues = dropdownvalues;
        if (vallll != "-1") {
            element.classList.remove("requirederror");
        }
        else {
            element.classList.add("requirederror");
        }
        var refid = element.dataset.filerefid;
        var selectedoption = tempdropdownvalues.filter(function (entry) { return entry.refid == refid; });
        if (selectedoption.length > 0) {
            selectedoption[0].text = vallll;
        }
        else {
            var sitem = {};
            sitem.refid = refid;
            sitem.text = vallll;
            tempdropdownvalues.push(sitem);
        }
        setdropdownvalues(tempdropdownvalues);
    }

    const movetoordering = () => {
        if (processedimage.length < 0) {
            // customAlertMessage('error', 'Minimum of 5 images required to generate the video');
            customAlertMessage('error', 'Minimum of 1 image required to generate the video');
            return false;
        }
        if (processedimage.length > 10) {
            customAlertMessage('error', 'We can process maximum of 10 images, please remove few image and click  on next.');
            return false;
        }
        var selectddl = document.getElementsByClassName('imageoption');
        var roomtypeselectedforall = true;
        for (let i = 0; i < selectddl.length; i++) {
            const element = selectddl[i];
            var refid = element.dataset.filerefid;
            var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
            if (selectedoption.length <= 0 || selectedoption[0].text == "-1") {
                roomtypeselectedforall = false;
                element.classList.add("requirederror");
            }
        }
        if (roomtypeselectedforall == false) {
            customAlertMessage('error', 'Please select room type for all images.');
            return false;
        }
        
        //var reorderedimages = document.querySelectorAll('.draggablediv .image-drag');
        // var thirdstepimage = document.querySelectorAll('.processedimagediv .image-drag')
        // const bundl = require('n2d');
        var fileandinfo = [];
        for (let j = 0; j < selectddl.length; j++) {
            var sfileinfo = {};
            const element = selectddl[j];
            var refid = element.dataset.filerefid;
            // var filebase64 = draggableelement.getElementsByTagName('img')[0].src;
            const numberstring = n2d.convert((j + 1)).trim();

             var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.identity == refid; });
             var filteredprocessedimage = processedimage.filter(function (entry) { return entry.refid == refid; });
            //var filtereduploadedfile = orderedprocessedimage.filter(function (entry) { return entry.refid == refid; });
            if (filtereduploadedfile.length > 0) {
                var filterbinaryfile = filtereduploadedfile[0].binaryfile;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else if (filteredprocessedimage.length > 0) {
                var filterbinaryfile = dataURLtoFile(filteredprocessedimage[0].image);
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else{
                customAlertMessage('error','uploaded file missing');
            }
        }
        //PROPERTY DESCRIPTION
        var prompt = "";        
        var txtPropAddress = document.getElementById('txtPropAddress').value;
        prompt = "Generate detailed property listing description for "+txtPropAddress;
        if (prompt.trim() == "") {
          customAlertMessage("error", "Please enter property address.");
          return;
        }    
    
        // step 2 start ==>Property details
        var propTypeSel = document.getElementById("ddlPropertyType").value;
        if (propTypeSel != "select") {
            prompt += " with " + propTypeSel +" propertytype.";
        }
        else{
            prompt +=".";
        }
        var ddlBedroomsSel = document.getElementById("ddlBedrooms").value;
        if (ddlBedroomsSel != "select") {
            prompt += " It has "+ ddlBedroomsSel + " number of bedrooms and " ;
        }
        var ddlBathroomsSel = document.getElementById("ddlBathrooms").value;
        if (ddlBathroomsSel != "select") {
            prompt +=  ddlBathroomsSel + " number of bathrooms." ;
        }
        var ddlFloorsSel = document.getElementById("ddlFloors").value;
        if (ddlFloorsSel != "select") {
            prompt += " It is a " + ddlFloorsSel + " story home." ; 
        }
        var txtAreaSel = document.getElementById("txtArea").value;
        if (txtAreaSel != "") {
            prompt += "It is having an area of "+txtAreaSel+ " sqft.";
        }
        // lot size comented
        // var txtAreaSel = document.getElementById("txtArea").value; 
        // if (txtAreaSel != "") {
        //     prompt += "and "+txtAreaSel+ " lot size.";
        // }  
        var ddlGaragetypeSel = document.getElementById("ddlGaragetype").value;
        var ddlGaragespacesSel = document.getElementById("ddlGaragespaces").value;
        if (ddlGaragetypeSel !="select" && ddlGaragespacesSel != "select") {
            prompt += " It has " + ddlGaragetypeSel + " garage with "+ ddlGaragespacesSel + " spaces."; 
        }
        else if (ddlGaragetypeSel != "select") {
            prompt += " It has " + ddlGaragetypeSel + " garage." ; 
        }
        else if (ddlGaragespacesSel != "select") {
            prompt += " It has " + ddlGaragespacesSel + " garage." ; 
        }
        prompt += "The property most Saleable features include ";
        var txtMostSalableSel = document.getElementById("txtMostSalable").value;
        if (txtMostSalableSel != "") {
            prompt += txtMostSalableSel + ".";
        }
        // Step 2 end
    
        // step 1 start ==>Amenitinies
        
        //school amenity 
        var markedCheckbox = document.getElementsByName('schoolAmenity');  
        var schoolAmenitiesel = "";
        for (var checkbox of markedCheckbox) {  
            if (checkbox.checked)  {
                var lblIds = checkbox.id.replace("chkschool","lblschool");
                schoolAmenitiesel += document.getElementById(lblIds).innerText + ', ';
            }
        } 
        if (schoolAmenitiesel !="") {
            prompt += "The property has near by schools including " + schoolAmenitiesel + ".";
        }
    
        var markedCheckboxHosp = document.getElementsByName('hospitalAmenity');  
        var hospitalAmenitiesel = "";
        for (var checkbox of markedCheckboxHosp) {  
            if (checkbox.checked)  {
                var lblIdshospital = checkbox.id.replace("chkhospital","lblhospital");
                hospitalAmenitiesel += document.getElementById(lblIdshospital).innerText + ', ';
            }
        } 
        if (hospitalAmenitiesel !="") {
            prompt += "The property has near by hospitals including " + hospitalAmenitiesel + ".";
        }
    
        var markedCheckboxpark = document.getElementsByName('parkAmenity');  
        var parkAmenitiesel = "";
        for (var checkbox of markedCheckboxpark) {  
            if (checkbox.checked)  {
                var lblIdspark = checkbox.id.replace("chkpark","lblpark");
                parkAmenitiesel += document.getElementById(lblIdspark).innerText + ', ';
            }
        } 
        if (parkAmenitiesel !="") {
            prompt += "The property has near by parks including " + parkAmenitiesel + ".";
        }
    
        var markedCheckboxsupermarket = document.getElementsByName('supermarketAmenity');  
        var supermarketAmenitiesel = "";
        for (var checkbox of markedCheckboxsupermarket) {  
            if (checkbox.checked)  {
                var lblIdssupermarket = checkbox.id.replace("chksupermarket","lblsupermarket");
                supermarketAmenitiesel += document.getElementById(lblIdssupermarket).innerText + ', ';
            }
        } 
        if (supermarketAmenitiesel !="") {
            prompt += "The property has near by supermarkets including " + supermarketAmenitiesel + ".";
        }
    
        createlistingdescription(fileandinfo, prompt);
    }
    //AJAX CALL
    const createlistingdescription = (fileinfoarray, propdescriptionarray) => {
        // document.getElementById("divGeneratedContent").innerHTML="description start";
        // try {
        //     document.getElementById("plagiarisminfo").style.display = "none";
        // } catch (error) {
            
        // }    
        // onTabClick(4);
        // document.getElementById("divGeneratedContent").innerHTML="";
        // document.getElementById("h4Title").innerHTML="";
        // document.getElementById("spanCharCount").style.display = "none";
        // document.getElementById("divOutputIcons").style.display = "none";

        // showoverlayprop();
        var mapjson = {}
        const axios = require('axios');
        const FormData = require('form-data');
        let data = new FormData();
        for (let i = 0; i < fileinfoarray.length; i++) {
            const farr = fileinfoarray[i];
            data.append(farr.intstring, farr.file);
            mapjson[farr.intstring] = farr.type;
        }
        data.append('map', JSON.stringify(mapjson));
        data.append('propertydesc', (propdescriptionarray));
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            //url: 'https://localhost:44372/api/ArtificialIntelligence/getpropertyfeatures',
            url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/getpropertyfeatures',
            data: data
        };
        compareFormData(data).then(result => {
            if (result == false || createnewcontent == true) {
                //customAlertMessage("success","values changed new video will be created");
                // setfinalvideo("");
                document.getElementById("divGeneratedContent").innerHTML="description start";
                try {
                    document.getElementById("plagiarisminfo").style.display = "none";
                } catch (error) {
                    
                }    
                onTabClick(4);
                document.getElementById("divGeneratedContent").innerHTML="";
                document.getElementById("h4Title").innerHTML="";
                document.getElementById("spanCharCount").style.display = "none";
                document.getElementById("divOutputIcons").style.display = "none";

                showoverlayprop();
                showoverlay();
                axios.request(config)
                    .then((resp) => {
                        var response = resp.data;
                        // console.log(response.Data);                    
                        if (resp != null && response != "" && response.Data !="" && response.data != 'error') {
                        var datalist = response.Data.description.split('\n');
                        
                        var finalData="";
                        for(var i=0;i<datalist.length;i++)
                        {
                            if(datalist[i]!="")
                            {
                                finalData = finalData +datalist[i] +"\n";
                            }
                        }
                        hideoverlay();
                        $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
                        t = 0;  
                        finalData = finalData.replace("Headline: ","").replace("headline: ","").replace("Paragraph 1:","").replace("Paragraph 2:","").replace("Paragraph 3:","").replace("Paragraph 4:","").replace("Paragraph 5:","").replace("Paragraph 6:","");
                        finalData = finalData.replace("Call to Action: ","").replace("Headline note:","").replace("Headline Note:","");       
                        var divOuterContent = document.getElementById('divOuterContent');
                        divOuterContent.scrollTop =  divOuterContent.scrollHeight; 
                        var dataobjects = response.Data.objects;
                        typeWriter(finalData,dataobjects);
                        document.getElementById('divGeneratedContent').contentEditable ="true";
                        document.getElementById("spanCharCount").style.display = "block";
                        var txtPropAddress = document.getElementById('txtPropAddress').value;
                        document.getElementById("h4Title").innerHTML="Property description for " + txtPropAddress;
                        createnewcontent = false;
                        setTimeout(() => {
                            try {
                                hideoverlay();
                                storeFormData(data);
                            } catch (error) {
                            }
                        }, 2000);
                        // setTimeout(() => {
                        //     try {
                        //         debugger;
                        //         Highlightkeys(response.Data.objects);
                        //     } catch (error) {
                        //     }
                        // }, 4000);
                        }
                        else {
                            customAlertMessage('error', 'Something went wrong, please try again.')
                            hideoverlay();
                        }
                        hideoverlayprop();
                      
                    })
                    .catch((error) => {
                        customAlertMessage('error', 'Something went wrong, please try again.')
                        console.log(error);
                        hideoverlay();
                        hideoverlayprop();
                    });
            }
            else{
                //customAlertMessage("success","No values changed for video creation");
                onTabClick(4);
                hideoverlay();
                hideoverlayprop();
            }
        });
    }
    const Highlightkeys=(featurelist)=>{
        var listofkeys = featurelist.split(',');
        var replacedhtml = document.getElementById('divGeneratedContent').innerHTML;
        for (let i = 0; i < listofkeys.length; i++) {
            var element = listofkeys[i];
            if (element.trim() !== "") {
                if (element.trim().toLowerCase().indexOf('flooring') != -1) {
                    element = element.trim().toLowerCase().replace('flooring','').trim();
                    replacedhtml = replacedhtml.replace(element.trim(),"<span>"+element.trim()+"</span>"); 
                }else{
                    replacedhtml = replacedhtml.replace(element.trim(),"<span>"+element.trim()+"</span>");
                }
            }            
        }
        $('#divGeneratedContent').html(replacedhtml);
        
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        if (filename == "") {
            if (mime.toLowerCase().indexOf('png')) {
                filename = uuidv4() + ".png";
            }
            else{
                filename = uuidv4() + ".jpg";
            }
        }
        return new File([u8arr], filename, { type: mime });
    }
    async function storeFormData(formData) {
        let obj = {};
        for (let [key, value] of formData.entries()) {
            // if (value instanceof File) {
            //     value = await fileToDataURL(value);
            // }
            obj[key] = value;
        }
        setvideocreatedformdata(obj);
        // localStorage.setItem('formData', JSON.stringify(obj));
    }
    async function compareFormData(formData) {
        let obj = {};
        for (let [key, value] of formData.entries()) {
            // if (value instanceof File) {
            //     value = await fileToDataURL(value);
            // }
            obj[key] = value;
        }

        let storedData = videocreatedformdata;// JSON.parse(localStorage.getItem('formData'));
        if (storedData != null) {
            for (let key in obj) {
                if (obj[key] !== storedData[key]) {
                    return false;
                }
            }

            for (let key in storedData) {
                if (obj[key] !== storedData[key]) {
                    return false;
                }
            }
        }
        else {
            return false;
        }
        return true;
    }
    const removefromlist = (e) => {
        var referenceid = e.currentTarget.dataset.filerefid;
        var filteredprocessimage = processedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setprocessedimage([...filteredprocessimage]);
        var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.refid !== referenceid; });
        setuploadedfile([...filtereduploadedfile]);
        var  filterorderedprocessedimage = orderedprocessedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setorderedprocessedimage([...filterorderedprocessedimage]);
        // const dt = new DataTransfer();
        // var input = $(".dropzone")[0];
        // for (let file of input.files)
        //     if (file !== filtereduploadedfile.binaryfile) 
        //         dt.items.add(file)
        showoverlaytwo();
        setTimeout(() => {
            loaddropdownfromstate();
        }, 2000);
    }
        return (
            <div className="main_content_iner overly_inner ">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div className="col-12">
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4>
                                        <span
                                            style={{ cursor: "pointer" }}
                                            onClick={historygoback}
                                        >
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                            Property Description Generator
                                        </span>
                                    </h4>
                                </span>
                            </div>
                            <input type="hidden" id="hfCurrentTab" value="1"></input>
                        </div>

                        

                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="stepper-count-wrap">
                                        <div className="pd-stepper-count">
                                            <div className="step-count-div" onClick={() =>onTabClick(1)}>
                                                <div className="step-wrap">
                                                    <div id="div1" className="step-count">
                                                        <span id="countno1">1</span>
                                                        <FontAwesomeIcon icon={faCheck} id="checkmarkvalid1" style={{display:"none"}}/>
                                                    </div>
                                                </div>
                                                <div className="step-title">Address & nearby amenities</div>
                                            </div>
                                            <div className="step-count-div" onClick={() =>onTabClick(2)}>
                                                <div className="step-wrap">
                                                    <div id="div2" className="step-count inactive">
                                                    <span id="countno2">2</span>
                                                        <FontAwesomeIcon icon={faCheck} id="checkmarkvalid2" style={{display:"none"}}/>
                                                    </div>
                                                </div>
                                                <div className="step-title">Property details</div>
                                            </div>
                                            <div className="step-count-div" onClick={() =>onTabClick(3)}>
                                                <div className="step-wrap">
                                                    <div id="div3" className="step-count inactive">
                                                    <span id="countno3">3</span>
                                                        <FontAwesomeIcon icon={faCheck} id="checkmarkvalid3" style={{display:"none"}}/>
                                                    </div>
                                                </div>
                                                <div className="step-title">Upload Image</div>
                                            </div>
                                            <div className="step-count-div" onClick={() =>onTabClick(4)}>
                                                <div className="step-wrap">
                                                    <div id="div4" className="step-count inactive">
                                                    <span id="countno4">4</span>
                                                        <FontAwesomeIcon icon={faCheck} id="checkmarkvalid4" style={{display:"none"}}/>
                                                    </div>
                                                </div>
                                                <div className="step-title">Description</div>
                                            </div>
                                            <div className="stepper-bar"><div className="step-bar"></div></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Tab 1 Content */}

                                <div className="col-12" id="divpropertyaddress">
                                    <div className="row">
                                        <div className="col-9" style={{position:"relative"}}>
                                            <label>
                                                Property address
                                            </label>
                                            {/* <input type="text" className="common-input"></input> */}
                                            {/* <SearchLocationInput onChange={() => null} /> */}
                                            <div className="search-location-input">
                                                <input id="txtPropAddress"
                                                    ref={autoCompleteRef} className="common-input"
                                                    onChange={event => setQuery(event.target.value)}
                                                    placeholder="Enter your address"
                                                    value={query}
                                                />
                                                <FontAwesomeIcon className="clearProp" onClick={()=> clearPropAddress()} icon={faXmark} />
                                                <input id="hfLocationId" value={locationId} type="hidden"></input>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-12" style={{position:"relative"}}>
                                        <div id="overlay" className="email-overlay" style={{minHeight:"50vh"}}>
                                                <div id="loading">
                                                    <h2 id="loaderheading">Searching amenities...</h2>
                                                    <img src={loader} />
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row" id="div1Result" style={{display:"none"}}>                                        
                                        <div className="col-12">
                                            <div className="prop-title">Nearby amenities <span style={{ fontSize: "12px", lineHeight: "28px" }}>(found within the radius of 5 miles)</span></div>
                                            <div style={{ fontSize: "12px" }}>Select the nearby amenities to be included in the property description.</div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-3">
                                                    <div className="category-title">School/University</div>
                                                    <div id="divSchoolsData">

                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="category-title">Healthcare</div>
                                                    <div id="divHospitalsData">

                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="category-title">Parks</div>
                                                    <div id="divParksData">

                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="category-title">Supermarket</div>
                                                    <div id="divSsupermarketsData">

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                
                                            </div> */}
                                        </div>
                                        <div className="col-12">
                                            <span className="float-end">
                                                <a href="javascript:;" style={{margin:"15px 0 25px"}} className="common-save" onClick={() =>validateNext(1)}>Next</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* Tab 2 Content */}

                                <div className="col-12" id="divpropertydetails" style={{display:"none"}}>
                                    <div className="row">
                                        <div className="col-12">
                                            <h4 className="prop-top-title">Property Details</h4>
                                        </div>
                                        <div className="col-12 mb-30">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label className="prop-lab-title">1. Property type</label>
                                                    {/* <Propertytype /> */}
                                                    {/* <Dropdown id="ddlPropertyType" onChange={() => setPropertyType()} options={optionspropertyType} placeholder="Select" /> */}
                                                    <input type="hidden" id="hfPropertyType"></input>
                                                    <select id="ddlPropertyType" onChange={() => setPropertyType()}>
                                                        <option value="select">Select</option>
                                                        <option value="singlefamily">Single-family</option>
                                                        <option value="multifamily">Multi-family</option>
                                                        <option value="condo">Condo</option>
                                                        <option value="townhouse">Townhouse</option>
                                                        <option value="villa">Villa</option>
                                                        <option value="apartment">Apartment</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30">
                                            <div className="row">
                                                <div className="col-3">
                                                    <label className="prop-lab-title">2. Property attributes</label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Number of bedrooms<span style={{color:"red"}}><b>*</b></span></label>
                                                    {/* <Dropdown id="ddlBedrooms" options={optionsBedrroms} placeholder="Select" /> */}
                                                    <select id="ddlBedrooms">
                                                        <option value="select">Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Number of bathrooms<span style={{color:"red"}}><b>*</b></span></label>
                                                    {/* <Dropdown id="ddlBathrooms" options={optionsBathroms} placeholder="Select" /> */}
                                                    <select id="ddlBathrooms">
                                                        <option value="select">Select</option>
                                                        <option value="1">1</option>
                                                        <option value="1.5">1.5</option>
                                                        <option value="2">2</option>
                                                        <option value="2.5">2.5</option>
                                                        <option value="3">3</option>
                                                        <option value="3.5">3.5</option>
                                                        <option value="4">4</option>
                                                        <option value="4.5">4.5</option>
                                                        <option value="5">5</option>
                                                        <option value="5.5">5.5</option>
                                                        <option value="6">6</option>
                                                        <option value="6.5">6.5</option>
                                                        <option value="7">7</option>
                                                        <option value="7.5">7.5</option>
                                                        <option value="8">8</option>
                                                        <option value="8.5">8.5</option>
                                                        <option value="9">9</option>
                                                        <option value="9.5">9.5</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Floors<span style={{color:"red"}}><b>*</b></span></label>
                                                    {/* <Dropdown id="ddlFloors" options={optionsFloors} placeholder="Select" /> */}
                                                     <select id="ddlFloors">
                                                        <option value="select">Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Floor Area(sqft)<span style={{color:"red"}}><b>*</b></span></label>
                                                    <input id="txtArea" onChange={()=> addCommaTo()} type="text" className="common-input" />
                                                </div>
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Garage type</label>
                                                    <select id="ddlGaragetype">
                                                    <option value="select">Select</option>
                                                        <option value="attached">attached</option>
                                                        <option value="detached">detached</option>
                                                    </select>
                                                </div>
                                                <div className="col-2">
                                                    <label className="prop-lab-title1">Garage spaces</label>
                                                    {/* <Dropdown id="ddlGaragespaces" options={optionsGarage} placeholder="No of cars" /> */}
                                                    <select id="ddlGaragespaces">
                                                        <option value="select">Select</option>
                                                        <option value="1">1 car</option>
                                                        <option value="2">2 cars</option>
                                                        <option value="3">3 cars</option>
                                                        <option value="4">4 cars</option>
                                                        <option value="5">5 cars</option>
                                                        <option value="6">6 cars</option>
                                                        <option value="7">7 cars</option>
                                                        <option value="8">8 cars</option>
                                                        <option value="9">9 cars</option>
                                                        <option value="10">10 cars</option>
                                                    </select>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="prop-lab-title">3. What are the most saleable features about the property?<span style={{color:"red"}}><b>*</b></span></label>
                                                </div>
                                                <div className="col-12">
                                                    <div className="prop-border">
                                                        <div className="row">
                                                            <label>
                                                                <span>The most saleable features include</span>
                                                                <input id="txtMostSalable" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                            </label>
                                                        </div>
                                                        {/* <div className="row"> */}
                                                            <a href="javascript:;" id="floorsalable" onClick={() => toggleSalableClass('floorsalable')}>open floor plan</a>
                                                            <a href="javascript:;" id="facingroofsalable" onClick={() => toggleSalableClass('facingroofsalable')}>south facing roof</a>
                                                            <a href="javascript:;" id="facingwindowsalable" onClick={() => toggleSalableClass('facingwindowsalable')}>south facing windows</a>
                                                            <a href="javascript:;" id="largebackyardsalable" onClick={() => toggleSalableClass('largebackyardsalable')}>large backyard</a>
                                                            <a href="javascript:;" id="jacuzzisalable" onClick={() => toggleSalableClass('jacuzzisalable')}>jacuzzi</a>
                                                            <a href="javascript:;" id="balconyprimarysalable" onClick={() => toggleSalableClass('balconyprimarysalable')}>balcony in the primary bedroom</a>
                                                            <a href="javascript:;" id="solarpanelsalable" onClick={() => toggleSalableClass('solarpanelsalable')}>solar panel</a>
                                                            <a href="javascript:;" id="highceilingsalable" onClick={() => toggleSalableClass('highceilingsalable')}>high ceiling</a>
                                                            <a href="javascript:;" id="newroofsalable" onClick={() => toggleSalableClass('newroofsalable')}>new roof</a>
                                                            <a href="javascript:;" id="lakeroofsalable" onClick={() => toggleSalableClass('lakeroofsalable')}>lake view</a>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-30" style={{display:'none'}}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <label className="prop-lab-title">4. I want a</label>
                                                </div>
                                                <div className="col-12">
                                                    <label style={{ marginRight: "25px" }}><input type="radio" id="rbtnGeneral" checked="checked" className="prop-radio" name="rbtnWant" />General comprehensive description</label>
                                                    <label><input type="radio" className="prop-radio" id="rbtnFloorwise"  name="rbtnWant" />Floor-wise description</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <span className="float-end">
                                                <a href="javascript:;"  style={{margin:"15px 0 25px"}} className="common-save" onClick={() =>validateNext(2)}>Next</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>


                                {/* Tab 3 Content */}

                                
                                <div className="col-12" id="divpropertyfeatures" style={{display:"none"}}>
                                    <div className="row">
                                        {/* <div className="col-12">
                                            <div className="title-card1">
                                                <span className="float-start" data-stepbackid={1} onClick={gotostep}>
                                                    <h4>
                                                        <span
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronLeft} />
                                                            Upload Images
                                                        </span>
                                                    </h4>
                                                </span>
                                            </div>
                                        </div> */}
                                        <div className="col-12">
                                            <div className="box-greynew">
                                                <div className="row">
                                                    <div className="col-4 upload-center">
                                                        {/* <label>Event</label>
                                                        <h4>{eventtypename}</h4> */}
                                                    </div>
                                                    <div className="col-4 upload-center">
                                                        <div className="border-left">
                                                            {/* <label>Template</label>
                                                            <h4>{templatename}</h4> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-4 upload-center">
                                                        <div className="border-left">
                                                            <label>Required Images</label>
                                                            <h4>5 to 10</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dropzone-wrapper" style={{ width: "100%", marginBottom: "40px", marginTop: "5px" }} onDragEnter={dropzonedragenter} onDragOver={dropzonedragover} onDragLeave={dropzonedragleave} onDrop={dropzonefiledrop} >
                                            <div className="dropzone-desc">
                                                <h4>Upload between 5 to 10 images.</h4>
                                                <h4>Drag and Drop</h4>
                                                <p><b>or Click to upload</b></p>
                                                <FontAwesomeIcon icon={faCloudArrowUp} />
                                                <p>
                                                    Supported Files: PNG, JPG, HEIC, HEIF.
                                                </p>
                                            </div>
                                            <input type="file" name="img_logo" className="dropzone" onChange={dropzonechange} accept="image/png, image/jpeg, image/jpg, image/heif, image/heic" multiple></input>
                                        </div>

                                        <div className="col-12 processedimagediv" style={{ position: 'relative' }}>
                                            <div id="overlaytwo">
                                                <div id="loading" style={{ top: "30%" }}>
                                                    <h2 id="loaderheading">Generating...</h2>
                                                    <img src={loader} />
                                                </div>
                                            </div>
                                            {processedimage.length > 0 ? processedimage.map((d) => (
                                                <div className="image-drag" key={uuidv4()} style={{ position: "relative" }}>
                                                    <img src={d.image} alt="Property image" />
                                                    <FontAwesomeIcon icon={faRemove} className="removeimage" data-filerefid={d.refid} onClick={removefromlist}></FontAwesomeIcon>
                                                    <center>
                                                        <select className="commonselectNew imageoption" onChange={removerequiredfield} data-filerefid={d.refid}>
                                                            <option value="-1">Select room type*</option>
                                                            <option value="backyard">Backyard</option>
                                                            <option value="balcony">Balcony</option>
                                                            <option value="bathroom">Bathroom</option>
                                                            <option value="bedroom">Bedroom</option>
                                                            <option value="dining_room">Dining room</option>
                                                            <option value="drone_view">Drone View</option>
                                                            <option value="exterior_view">Exterior View</option>
                                                            <option value="family_room">Family room</option>
                                                            <option value="garden">Garden</option>
                                                            <option value="kidsroom">Kidsroom</option>
                                                            <option value="kitchen">Kitchen</option>
                                                            <option value="living_room">Living room</option>
                                                            <option value="nursery">Nursery</option>
                                                            <option value="office_study">Office/Study</option>
                                                            <option value="patio">Patio</option>
                                                            <option value="porch">Porch</option>
                                                            <option value="studio">Studio</option>
                                                            <option value="swimming_pool">Swimming pool</option>
                                                        </select>
                                                    </center>
                                                </div>
                                            )) : null}
                                        </div>

                                        {/* <div className="col-12 upload-part">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4>Upload between 5 to 10 images.</h4>
                                                        <p>(allowed file types: .jpg, .jpeg, .png, .gif)</p>
                                                    </div>

                                                    <div className="col-12">
                                                        <input type="file" id="additionalfiles" name="files[]" multiple onChange={additionalfileschange} />
                                                    </div>

                                                </div>
                                            </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="float-end">
                                                <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={movetoordering}>Next</a>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <h4 className="prop-top-title">Property Features</h4>
                                            <p>Almost done, one last step! We recommend you to fill these details for a better description generation. You can skip the details that don’t apply.</p>
                                        </div>

                                        
                                        <div id="divGeneralFeatures" className="col-12 tab-custom">
                                            <Tabs defaultActiveKey="first">
                                                <Tab id="tabGHI" eventKey="first" title="Home Interior">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB" onClick={() => toggleGHIClass('closetsGMB')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB" onClick={() => toggleGHIClass('carpetflooringGMB')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB" onClick={() => toggleGHIClass('ensuitebathroomGMB')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB" onClick={() => toggleGHIClass('walkinclosetGMB')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB" onClick={() => toggleGHIClass('woodenfloorGMB')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB" onClick={() => toggleGHIClass('naturallightGMB')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB" onClick={() => toggleGHIClass('fireplaceGMB')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB" onClick={() => toggleGHIClass('heighceilingGMB')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB" onClick={() => toggleGHIClass('lwGMB')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB" onClick={() => toggleGHIClass('balconyGMB')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB" onClick={() => toggleGHIClass('valtedceilingGMB')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR" onClick={() => toggleGLRClass('lwGLR')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR" onClick={() => toggleGLRClass('mlGLR')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR" onClick={() => toggleGLRClass('hwfGLR')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR" onClick={() => toggleGLRClass('tfGLR')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR" onClick={() => toggleGLRClass('fpGLR')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR" onClick={() => toggleGLRClass('hcGLR')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR" onClick={() => toggleGLRClass('nlGLR')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR" onClick={() => toggleGLRClass('vcGLR')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR" onClick={() => toggleGLRClass('datcGLR')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR" onClick={() => toggleGLRClass('slGLR')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen" onClick={() => toggleGKitchenClass('islandsGKitchen')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen" onClick={() => toggleGKitchenClass('saGKitchen')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen" onClick={() => toggleGKitchenClass('GCGKitchen')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen" onClick={() => toggleGKitchenClass('baGKitchen')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen" onClick={() => toggleGKitchenClass('msGKitchen')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen" onClick={() => toggleGKitchenClass('mcGKitchen')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen" onClick={() => toggleGKitchenClass('dbGKitchen')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen" onClick={() => toggleGKitchenClass('wcGKitchen')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen" onClick={() => toggleGKitchenClass('ecGKitchen')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath" onClick={() => toggleGbathroomClass('gcGBath')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath" onClick={() => toggleGbathroomClass('mvGBath')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath" onClick={() => toggleGbathroomClass('dsGBath')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath" onClick={() => toggleGbathroomClass('btGBath')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath" onClick={() => toggleGbathroomClass('bisGBath')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath" onClick={() => toggleGbathroomClass('rsGBath')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath" onClick={() => toggleGbathroomClass('gdGBath')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath" onClick={() => toggleGbathroomClass('rfhGBath')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath" onClick={() => toggleGbathroomClass('saunaGBath')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath" onClick={() => toggleGbathroomClass('mfGBath')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextGeneral(1)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabGHE" eventKey="second" title="General Features">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Home Exterior</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The home exterior has features like</span>
                                                                        <input id="txtGHomeExterior" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="boGHE" onClick={() => toggleGHomeExteriorClass('boGHE')}>backyard oasis</a>
                                                                <a href="javascript:;" id="llGHE" onClick={() => toggleGHomeExteriorClass('llGHE')}>lush landscaping</a>
                                                                <a href="javascript:;" id="ppGHE" onClick={() => toggleGHomeExteriorClass('ppGHE')}>private patio</a>
                                                                <a href="javascript:;" id="fpGHE" onClick={() => toggleGHomeExteriorClass('fpGHE')}>front porch</a>
                                                                <a href="javascript:;" id="gpGHE" onClick={() => toggleGHomeExteriorClass('gpGHE')}>gated pool</a>
                                                                <a href="javascript:;" id="fpaintGHE" onClick={() => toggleGHomeExteriorClass('fpaintGHE')}>fresh paint</a>
                                                                <a href="javascript:;" id="capGHE" onClick={() => toggleGHomeExteriorClass('capGHE')}>curb appeal</a>
                                                                <a href="javascript:;" id="fyGHE" onClick={() => toggleGHomeExteriorClass('fyGHE')}>front yard</a>
                                                                <a href="javascript:;" id="bbqGHE" onClick={() => toggleGHomeExteriorClass('bbqGHE')}>barbeque</a>
                                                                <a href="javascript:;" id="okGHE" onClick={() => toggleGHomeExteriorClass('okGHE')}>outdoor kitchen</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Neighborhood</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The neighborhood has features like</span>
                                                                        <input id="txtGNeighborhood" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="swGNH" onClick={() => toggleGNeighborhoodClass('swGNH')}>sidewalks</a>
                                                                <a href="javascript:;" id="eosGNH" onClick={() => toggleGNeighborhoodClass('eosGNH')}>end of street</a>
                                                                <a href="javascript:;" id="scwGNH" onClick={() => toggleGNeighborhoodClass('scwGNH')}>safe crosswalks</a>
                                                                <a href="javascript:;" id="spGNH" onClick={() => toggleGNeighborhoodClass('spGNH')}>street parking</a>
                                                                <a href="javascript:;" id="nptGNH" onClick={() => toggleGNeighborhoodClass('nptGNH')}>nearby public transportation</a>
                                                                <a href="javascript:;" id="nrGNH" onClick={() => toggleGNeighborhoodClass('nrGNH')}>nearby restaurants</a>
                                                                <a href="javascript:;" id="nhGNH" onClick={() => toggleGNeighborhoodClass('nhGNH')}>nearby hospitals</a>
                                                                <a href="javascript:;" id="tlGNH" onClick={() => toggleGNeighborhoodClass('tlGNH')}>tree lined</a>
                                                                <a href="javascript:;" id="wmrGNH" onClick={() => toggleGNeighborhoodClass('wmrGNH')}>well maintained roads</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Smart Features</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The smart features includes</span>
                                                                        <input id="txtGSmartFeatures" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="slsGSFs" onClick={() => toggleGSmartFeaturesClass('slsGSFs')}>smart lighting system</a>
                                                                <a href="javascript:;" id="sdGSFs" onClick={() => toggleGSmartFeaturesClass('sdGSFs')}>smart doorbells</a>
                                                                <a href="javascript:;" id="sssGSFs" onClick={() => toggleGSmartFeaturesClass('sssGSFs')}>smart sound system</a>
                                                                <a href="javascript:;" id="soGSFs" onClick={() => toggleGSmartFeaturesClass('soGSFs')}>smart outlet</a>
                                                                <a href="javascript:;" id="spGSFs" onClick={() => toggleGSmartFeaturesClass('spGSFs')}>smart sprinklers</a>
                                                                <a href="javascript:;" id="slGSFs" onClick={() => toggleGSmartFeaturesClass('slGSFs')}>smart locks</a>
                                                                <a href="javascript:;" id="sscGSFs" onClick={() => toggleGSmartFeaturesClass('sscGSFs')}>smart security cameras</a>
                                                                <a href="javascript:;" id="ssdGSFs" onClick={() => toggleGSmartFeaturesClass('ssdGSFs')}>smart smoke detectors</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Additional Rooms</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The property also has</span>
                                                                        <input id="txtGAddRooms" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="grGARs" onClick={() => toggleGAddRoomsClass('grGARs')}>guest room</a>
                                                                <a href="javascript:;" id="lrGARs" onClick={() => toggleGAddRoomsClass('lrGARs')}>laundry room</a>
                                                                <a href="javascript:;" id="gymrGARs" onClick={() => toggleGAddRoomsClass('gymrGARs')}>gym room</a>
                                                                <a href="javascript:;" id="basementGARs" onClick={() => toggleGAddRoomsClass('basementGARs')}>basement</a>
                                                                <a href="javascript:;" id="mediarGARs" onClick={() => toggleGAddRoomsClass('mediarGARs')}>media room</a>
                                                                <a href="javascript:;" id="storerGARs" onClick={() => toggleGAddRoomsClass('storerGARs')}>store room</a>
                                                                <a href="javascript:;" id="atticGARs" onClick={() => toggleGAddRoomsClass('atticGARs')}>attic</a>
                                                                <a href="javascript:;" id="studyrGARs" onClick={() => toggleGAddRoomsClass('studyrGARs')}>study room</a>
                                                                <a href="javascript:;" id="mudrGARs" onClick={() => toggleGAddRoomsClass('mudrGARs')}>mud room</a>
                                                                <a href="javascript:;" id="horGARs" onClick={() => toggleGAddRoomsClass('horGARs')}>home office room</a>
                                                                <a href="javascript:;" id="sunrGARs" onClick={() => toggleGAddRoomsClass('sunrGARs')}>sun room</a>
                                                                <a href="javascript:;" id="sfrGARs" onClick={() => toggleGAddRoomsClass('sfrGARs')}>seperate family room</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>validateNext(3)}>Generate Description</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                        <div id="divFloorwiseFeatures" style={{display:"none"}} className="col-12 tab-custom">
                                            <Tabs defaultActiveKey="1">
                                                <Tab id="tabFloor1" eventKey="1" title="First Floor">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR1" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB1" onClick={() => toggleFWHIClass('closetsGMB','1')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB1" onClick={() => toggleFWHIClass('carpetflooringGMB','1')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB1" onClick={() => toggleFWHIClass('ensuitebathroomGMB','1')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB1" onClick={() => toggleFWHIClass('walkinclosetGMB','1')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB1" onClick={() => toggleFWHIClass('woodenfloorGMB','1')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB1" onClick={() => toggleFWHIClass('naturallightGMB','1')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB1" onClick={() => toggleFWHIClass('fireplaceGMB','1')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB1" onClick={() => toggleFWHIClass('heighceilingGMB','1')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB1" onClick={() => toggleFWHIClass('lwGMB','1')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB1" onClick={() => toggleFWHIClass('balconyGMB','1')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB1" onClick={() => toggleFWHIClass('valtedceilingGMB','1')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR1" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR1" onClick={() => toggleFWLRClass('lwGLR','1')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR1" onClick={() => toggleFWLRClass('mlGLR','1')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR1" onClick={() => toggleFWLRClass('hwfGLR','1')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR1" onClick={() => toggleFWLRClass('tfGLR','1')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR1" onClick={() => toggleFWLRClass('fpGLR','1')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR1" onClick={() => toggleFWLRClass('hcGLR','1')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR1" onClick={() => toggleFWLRClass('nlGLR','1')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR1" onClick={() => toggleFWLRClass('vcGLR','1')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR1" onClick={() => toggleFWLRClass('datcGLR','1')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR1" onClick={() => toggleFWLRClass('slGLR','1')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen1" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen1" onClick={() => toggleFWKitchenClass('islandsGKitchen','1')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen1" onClick={() => toggleFWKitchenClass('saGKitchen','1')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen1" onClick={() => toggleFWKitchenClass('GCGKitchen','1')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen1" onClick={() => toggleFWKitchenClass('baGKitchen','1')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen1" onClick={() => toggleFWKitchenClass('msGKitchen','1')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen1" onClick={() => toggleFWKitchenClass('mcGKitchen','1')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen1" onClick={() => toggleFWKitchenClass('dbGKitchen','1')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen1" onClick={() => toggleFWKitchenClass('wcGKitchen','1')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen1" onClick={() => toggleFWKitchenClass('ecGKitchen','1')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom1" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath1" onClick={() => toggleFWbathroomClass('gcGBath','1')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath1" onClick={() => toggleFWbathroomClass('mvGBath','1')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath1" onClick={() => toggleFWbathroomClass('dsGBath','1')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath1" onClick={() => toggleFWbathroomClass('btGBath','1')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath1" onClick={() => toggleFWbathroomClass('bisGBath','1')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath1" onClick={() => toggleFWbathroomClass('rsGBath','1')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath1" onClick={() => toggleFWbathroomClass('gdGBath','1')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath1" onClick={() => toggleFWbathroomClass('rfhGBath','1')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath1" onClick={() => toggleFWbathroomClass('saunaGBath','1')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath1" onClick={() => toggleFWbathroomClass('mfGBath','1')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextFloor(1)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabFloor2" eventKey="2" title="Second Floor">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR2" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB2" onClick={() => toggleFWHIClass('closetsGMB','2')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB2" onClick={() => toggleFWHIClass('carpetflooringGMB','2')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB2" onClick={() => toggleFWHIClass('ensuitebathroomGMB','2')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB2" onClick={() => toggleFWHIClass('walkinclosetGMB','2')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB2" onClick={() => toggleFWHIClass('woodenfloorGMB','2')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB2" onClick={() => toggleFWHIClass('naturallightGMB','2')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB2" onClick={() => toggleFWHIClass('fireplaceGMB','2')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB2" onClick={() => toggleFWHIClass('heighceilingGMB','2')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB2" onClick={() => toggleFWHIClass('lwGMB','2')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB2" onClick={() => toggleFWHIClass('balconyGMB','2')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB2" onClick={() => toggleFWHIClass('valtedceilingGMB','2')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR2" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR2" onClick={() => toggleFWLRClass('lwGLR','2')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR2" onClick={() => toggleFWLRClass('mlGLR','2')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR2" onClick={() => toggleFWLRClass('hwfGLR','2')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR2" onClick={() => toggleFWLRClass('tfGLR','2')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR2" onClick={() => toggleFWLRClass('fpGLR','2')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR2" onClick={() => toggleFWLRClass('hcGLR','2')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR2" onClick={() => toggleFWLRClass('nlGLR','2')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR2" onClick={() => toggleFWLRClass('vcGLR','2')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR2" onClick={() => toggleFWLRClass('datcGLR','2')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR2" onClick={() => toggleFWLRClass('slGLR','2')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen2" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen2" onClick={() => toggleFWKitchenClass('islandsGKitchen','2')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen2" onClick={() => toggleFWKitchenClass('saGKitchen','2')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen2" onClick={() => toggleFWKitchenClass('GCGKitchen','2')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen2" onClick={() => toggleFWKitchenClass('baGKitchen','2')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen2" onClick={() => toggleFWKitchenClass('msGKitchen','2')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen2" onClick={() => toggleFWKitchenClass('mcGKitchen','2')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen2" onClick={() => toggleFWKitchenClass('dbGKitchen','2')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen2" onClick={() => toggleFWKitchenClass('wcGKitchen','2')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen2" onClick={() => toggleFWKitchenClass('ecGKitchen','2')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom2" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath2" onClick={() => toggleFWbathroomClass('gcGBath','2')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath2" onClick={() => toggleFWbathroomClass('mvGBath','2')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath2" onClick={() => toggleFWbathroomClass('dsGBath','2')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath2" onClick={() => toggleFWbathroomClass('btGBath','2')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath2" onClick={() => toggleFWbathroomClass('bisGBath','2')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath2" onClick={() => toggleFWbathroomClass('rsGBath','2')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath2" onClick={() => toggleFWbathroomClass('gdGBath','2')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath2" onClick={() => toggleFWbathroomClass('rfhGBath','2')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath2" onClick={() => toggleFWbathroomClass('saunaGBath','2')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath2" onClick={() => toggleFWbathroomClass('mfGBath','2')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextFloor(2)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabFloor3" eventKey="3" title="Third Floor">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR3" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB3" onClick={() => toggleFWHIClass('closetsGMB','3')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB3" onClick={() => toggleFWHIClass('carpetflooringGMB','3')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB3" onClick={() => toggleFWHIClass('ensuitebathroomGMB','3')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB3" onClick={() => toggleFWHIClass('walkinclosetGMB','3')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB3" onClick={() => toggleFWHIClass('woodenfloorGMB','3')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB3" onClick={() => toggleFWHIClass('naturallightGMB','3')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB3" onClick={() => toggleFWHIClass('fireplaceGMB','3')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB3" onClick={() => toggleFWHIClass('heighceilingGMB','3')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB3" onClick={() => toggleFWHIClass('lwGMB','3')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB3" onClick={() => toggleFWHIClass('balconyGMB','3')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB3" onClick={() => toggleFWHIClass('valtedceilingGMB','3')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR3" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR3" onClick={() => toggleFWLRClass('lwGLR','3')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR3" onClick={() => toggleFWLRClass('mlGLR','3')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR3" onClick={() => toggleFWLRClass('hwfGLR','3')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR3" onClick={() => toggleFWLRClass('tfGLR','3')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR3" onClick={() => toggleFWLRClass('fpGLR','3')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR3" onClick={() => toggleFWLRClass('hcGLR','3')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR3" onClick={() => toggleFWLRClass('nlGLR','3')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR3" onClick={() => toggleFWLRClass('vcGLR','3')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR3" onClick={() => toggleFWLRClass('datcGLR','3')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR3" onClick={() => toggleFWLRClass('slGLR','3')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen3" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen3" onClick={() => toggleFWKitchenClass('islandsGKitchen','3')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen3" onClick={() => toggleFWKitchenClass('saGKitchen','3')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen3" onClick={() => toggleFWKitchenClass('GCGKitchen','3')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen3" onClick={() => toggleFWKitchenClass('baGKitchen','3')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen3" onClick={() => toggleFWKitchenClass('msGKitchen','3')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen3" onClick={() => toggleFWKitchenClass('mcGKitchen','3')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen3" onClick={() => toggleFWKitchenClass('dbGKitchen','3')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen3" onClick={() => toggleFWKitchenClass('wcGKitchen','3')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen3" onClick={() => toggleFWKitchenClass('ecGKitchen','3')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom3" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath3" onClick={() => toggleFWbathroomClass('gcGBath','3')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath3" onClick={() => toggleFWbathroomClass('mvGBath','3')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath3" onClick={() => toggleFWbathroomClass('dsGBath','3')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath3" onClick={() => toggleFWbathroomClass('btGBath','3')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath3" onClick={() => toggleFWbathroomClass('bisGBath','3')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath3" onClick={() => toggleFWbathroomClass('rsGBath','3')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath3" onClick={() => toggleFWbathroomClass('gdGBath','3')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath3" onClick={() => toggleFWbathroomClass('rfhGBath','3')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath3" onClick={() => toggleFWbathroomClass('saunaGBath','3')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath3" onClick={() => toggleFWbathroomClass('mfGBath','3')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextFloor(3)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabFloor4" eventKey="4" title="Fourth Floor">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR4" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB4" onClick={() => toggleFWHIClass('closetsGMB','4')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB4" onClick={() => toggleFWHIClass('carpetflooringGMB','4')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB4" onClick={() => toggleFWHIClass('ensuitebathroomGMB','4')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB4" onClick={() => toggleFWHIClass('walkinclosetGMB','4')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB4" onClick={() => toggleFWHIClass('woodenfloorGMB','4')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB4" onClick={() => toggleFWHIClass('naturallightGMB','4')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB4" onClick={() => toggleFWHIClass('fireplaceGMB','4')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB4" onClick={() => toggleFWHIClass('heighceilingGMB','4')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB4" onClick={() => toggleFWHIClass('lwGMB','4')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB4" onClick={() => toggleFWHIClass('balconyGMB','4')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB4" onClick={() => toggleFWHIClass('valtedceilingGMB','4')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR4" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR4" onClick={() => toggleFWLRClass('lwGLR','4')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR4" onClick={() => toggleFWLRClass('mlGLR','4')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR4" onClick={() => toggleFWLRClass('hwfGLR','4')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR4" onClick={() => toggleFWLRClass('tfGLR','4')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR4" onClick={() => toggleFWLRClass('fpGLR','4')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR4" onClick={() => toggleFWLRClass('hcGLR','4')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR4" onClick={() => toggleFWLRClass('nlGLR','4')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR4" onClick={() => toggleFWLRClass('vcGLR','4')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR4" onClick={() => toggleFWLRClass('datcGLR','4')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR4" onClick={() => toggleFWLRClass('slGLR','4')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen4" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen4" onClick={() => toggleFWKitchenClass('islandsGKitchen','4')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen4" onClick={() => toggleFWKitchenClass('saGKitchen','4')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen4" onClick={() => toggleFWKitchenClass('GCGKitchen','4')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen4" onClick={() => toggleFWKitchenClass('baGKitchen','4')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen4" onClick={() => toggleFWKitchenClass('msGKitchen','4')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen4" onClick={() => toggleFWKitchenClass('mcGKitchen','4')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen4" onClick={() => toggleFWKitchenClass('dbGKitchen','4')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen4" onClick={() => toggleFWKitchenClass('wcGKitchen','4')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen4" onClick={() => toggleFWKitchenClass('ecGKitchen','4')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom4" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath4" onClick={() => toggleFWbathroomClass('gcGBath','4')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath4" onClick={() => toggleFWbathroomClass('mvGBath','4')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath4" onClick={() => toggleFWbathroomClass('dsGBath','4')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath4" onClick={() => toggleFWbathroomClass('btGBath','4')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath4" onClick={() => toggleFWbathroomClass('bisGBath','4')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath4" onClick={() => toggleFWbathroomClass('rsGBath','4')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath4" onClick={() => toggleFWbathroomClass('gdGBath','4')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath4" onClick={() => toggleFWbathroomClass('rfhGBath','4')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath4" onClick={() => toggleFWbathroomClass('saunaGBath','4')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath4" onClick={() => toggleFWbathroomClass('mfGBath','4')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextFloor(4)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabFloor5" eventKey="5" title="Fifth Floor">
                                                <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Master Bedroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The master bedroom has features like</span>
                                                                        <input id="txtGPBR5" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="closetsGMB5" onClick={() => toggleFWHIClass('closetsGMB','5')}>built-in closets</a>
                                                                <a href="javascript:;" id="carpetflooringGMB5" onClick={() => toggleFWHIClass('carpetflooringGMB','5')}>carpeted flooring</a>
                                                                <a href="javascript:;" id="ensuitebathroomGMB5" onClick={() => toggleFWHIClass('ensuitebathroomGMB','5')}>en-suite bathroom</a>
                                                                <a href="javascript:;" id="walkinclosetGMB5" onClick={() => toggleFWHIClass('walkinclosetGMB','5')}>walk-in closet</a>
                                                                <a href="javascript:;" id="woodenfloorGMB5" onClick={() => toggleFWHIClass('woodenfloorGMB','5')}>wooden flooring</a>
                                                                <a href="javascript:;" id="naturallightGMB5" onClick={() => toggleFWHIClass('naturallightGMB','5')}>natural light</a>
                                                                <a href="javascript:;" id="fireplaceGMB5" onClick={() => toggleFWHIClass('fireplaceGMB','5')}>fire place</a>
                                                                <a href="javascript:;" id="heighceilingGMB5" onClick={() => toggleFWHIClass('heighceilingGMB','5')}>high ceiling</a>
                                                                <a href="javascript:;" id="lwGMB5" onClick={() => toggleFWHIClass('lwGMB','5')}>large windows</a>
                                                                <a href="javascript:;" id="balconyGMB5" onClick={() => toggleFWHIClass('balconyGMB','5')}>balcony</a>
                                                                <a href="javascript:;" id="valtedceilingGMB5" onClick={() => toggleFWHIClass('valtedceilingGMB','5')}>vaulted ceiling</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Living Room</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The living room has features like</span>
                                                                        <input id="txtGLR5" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="lwGLR5" onClick={() => toggleFWLRClass('lwGLR','5')}>large window</a>
                                                                <a href="javascript:;" id="mlGLR5" onClick={() => toggleFWLRClass('mlGLR','5')}>marble flooring</a>
                                                                <a href="javascript:;" id="hwfGLR5" onClick={() => toggleFWLRClass('hwfGLR','5')}>hardwood flooring</a>
                                                                <a href="javascript:;" id="tfGLR5" onClick={() => toggleFWLRClass('tfGLR','5')}>tiled flooring</a>
                                                                <a href="javascript:;" id="fpGLR5" onClick={() => toggleFWLRClass('fpGLR','5')}>fire place</a>
                                                                <a href="javascript:;" id="hcGLR5" onClick={() => toggleFWLRClass('hcGLR','5')}>high ceiling</a>
                                                                <a href="javascript:;" id="nlGLR5" onClick={() => toggleFWLRClass('nlGLR','5')}>natural light</a>
                                                                <a href="javascript:;" id="vcGLR5" onClick={() => toggleFWLRClass('vcGLR','5')}>vaulted ceiling</a>
                                                                <a href="javascript:;" id="datcGLR5" onClick={() => toggleFWLRClass('datcGLR','5')}>deck attached</a>
                                                                <a href="javascript:;" id="slGLR5" onClick={() => toggleFWLRClass('slGLR','5')}>sky light</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Kitchen</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The kitchen has features like</span>
                                                                        <input id="txtGKitchen5" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="islandsGKitchen5" onClick={() => toggleFWKitchenClass('islandsGKitchen','5')}>islands</a>
                                                                <a href="javascript:;" id="saGKitchen5" onClick={() => toggleFWKitchenClass('saGKitchen','5')}>steel appliances</a>
                                                                <a href="javascript:;" id="GCGKitchen5" onClick={() => toggleFWKitchenClass('GCGKitchen','5')}>granite countertop</a>
                                                                <a href="javascript:;" id="baGKitchen5" onClick={() => toggleFWKitchenClass('baGKitchen','5')}>bar area</a>
                                                                <a href="javascript:;" id="msGKitchen5" onClick={() => toggleFWKitchenClass('msGKitchen','5')}>multiple sink</a>
                                                                <a href="javascript:;" id="mcGKitchen5" onClick={() => toggleFWKitchenClass('mcGKitchen','5')}>marble countertop</a>
                                                                <a href="javascript:;" id="dbGKitchen5" onClick={() => toggleFWKitchenClass('dbGKitchen','5')}>decorative backsplash</a>
                                                                <a href="javascript:;" id="wcGKitchen5" onClick={() => toggleFWKitchenClass('wcGKitchen','5')}>wooden cabinet</a>
                                                                <a href="javascript:;" id="ecGKitchen5" onClick={() => toggleFWKitchenClass('ecGKitchen','5')}>eat-in counter</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Bathroom</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The bathroom has features like</span>
                                                                        <input id="txtGbathroom5" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="gcGBath5" onClick={() => toggleFWbathroomClass('gcGBath','5')}>granite countertop</a>
                                                                <a href="javascript:;" id="mvGBath5" onClick={() => toggleFWbathroomClass('mvGBath','5')}>modern vanity</a>
                                                                <a href="javascript:;" id="dsGBath5" onClick={() => toggleFWbathroomClass('dsGBath','5')}>double sink</a>
                                                                <a href="javascript:;" id="btGBath5" onClick={() => toggleFWbathroomClass('btGBath','5')}>bathtub</a>
                                                                <a href="javascript:;" id="bisGBath5" onClick={() => toggleFWbathroomClass('bisGBath','5')}>built-in shelving</a>
                                                                <a href="javascript:;" id="rsGBath5" onClick={() => toggleFWbathroomClass('rsGBath','5')}>rain showerhead</a>
                                                                <a href="javascript:;" id="gdGBath5" onClick={() => toggleFWbathroomClass('gdGBath','5')}>glass door</a>
                                                                <a href="javascript:;" id="rfhGBath5" onClick={() => toggleFWbathroomClass('rfhGBath','5')}>radiant floor heating</a>
                                                                <a href="javascript:;" id="saunaGBath5" onClick={() => toggleFWbathroomClass('saunaGBath','5')}>sauna</a>
                                                                <a href="javascript:;" id="mfGBath5" onClick={() => toggleFWbathroomClass('mfGBath','5')}>marble floor</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>moveToNextFloor(5)}>Next</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab id="tabFWHE" eventKey="6" title="General Features">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Home Exterior</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The home exterior has features like</span>
                                                                        <input id="txtFWHomeExterior" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="boFWHE" onClick={() => toggleFWGClass('boFWHE','FWHEselected','txtFWHomeExterior')}>backyard oasis</a>
                                                                <a href="javascript:;" id="llFWHE" onClick={() => toggleFWGClass('llFWHE','FWHEselected','txtFWHomeExterior')}>lush landscaping</a>
                                                                <a href="javascript:;" id="ppFWHE" onClick={() => toggleFWGClass('ppFWHE','FWHEselected','txtFWHomeExterior')}>private patio</a>
                                                                <a href="javascript:;" id="fpFWHE" onClick={() => toggleFWGClass('fpFWHE','FWHEselected','txtFWHomeExterior')}>front porch</a>
                                                                <a href="javascript:;" id="gpFWHE" onClick={() => toggleFWGClass('gpFWHE','FWHEselected','txtFWHomeExterior')}>gated pool</a>
                                                                <a href="javascript:;" id="fpaintFWHE" onClick={() => toggleFWGClass('fpaintFWHE','FWHEselected','txtFWHomeExterior')}>fresh paint</a>
                                                                <a href="javascript:;" id="capFWHE" onClick={() => toggleFWGClass('capFWHE','FWHEselected','txtFWHomeExterior')}>curb appeal</a>
                                                                <a href="javascript:;" id="fyFWHE" onClick={() => toggleFWGClass('fyFWHE','FWHEselected','txtFWHomeExterior')}>front yard</a>
                                                                <a href="javascript:;" id="bbqFWHE" onClick={() => toggleFWGClass('bbqFWHE','FWHEselected','txtFWHomeExterior')}>barbeque</a>
                                                                <a href="javascript:;" id="okFWHE" onClick={() => toggleFWGClass('okFWHE','FWHEselected','txtFWHomeExterior')}>outdoor kitchen</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Neighborhood</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The neighborhood has features like</span>
                                                                        <input id="txtFWNeighborhood" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="swFWNH" onClick={() => toggleFWGClass('swFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>sidewalks</a>
                                                                <a href="javascript:;" id="eosFWNH" onClick={() => toggleFWGClass('eosFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>end of street</a>
                                                                <a href="javascript:;" id="scwFWNH" onClick={() => toggleFWGClass('scwFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>safe crosswalks</a>
                                                                <a href="javascript:;" id="spFWNH" onClick={() => toggleFWGClass('spFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>street parking</a>
                                                                <a href="javascript:;" id="nptFWNH" onClick={() => toggleFWGClass('nptFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>nearby public transportation</a>
                                                                <a href="javascript:;" id="nrFWNH" onClick={() => toggleFWGClass('nrFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>nearby restaurants</a>
                                                                <a href="javascript:;" id="nhFWNH" onClick={() => toggleFWGClass('nhFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>nearby hospitals</a>
                                                                <a href="javascript:;" id="tlFWNH" onClick={() => toggleFWGClass('tlFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>tree lined</a>
                                                                <a href="javascript:;" id="wmrFWNH" onClick={() => toggleFWGClass('wmrFWNH','FWNeighborhoodselected','txtFWNeighborhood')}>well maintained roads</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Smart Features</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The smart features includes</span>
                                                                        <input id="txtFWSmartFeatures" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="slsFWSFs" onClick={() => toggleFWGClass('slsFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart lighting system</a>
                                                                <a href="javascript:;" id="sdFWSFs" onClick={() => toggleFWGClass('sdFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart doorbells</a>
                                                                <a href="javascript:;" id="sssFWSFs" onClick={() => toggleFWGClass('sssFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart sound system</a>
                                                                <a href="javascript:;" id="soFWSFs" onClick={() => toggleFWGClass('soFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart outlet</a>
                                                                <a href="javascript:;" id="spFWSFs" onClick={() => toggleFWGClass('spFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart sprinklers</a>
                                                                <a href="javascript:;" id="slFWSFs" onClick={() => toggleFWGClass('slFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart locks</a>
                                                                <a href="javascript:;" id="sscFWSFs" onClick={() => toggleFWGClass('sscFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart security cameras</a>
                                                                <a href="javascript:;" id="ssdFWSFs" onClick={() => toggleFWGClass('ssdFWSFs','FWSmartFeaturesselected','txtFWSmartFeatures')}>smart smoke detectors</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="prop-border">
                                                                <h4>Additional Rooms</h4>
                                                                <div className="row">
                                                                    <label>
                                                                        <span>The property also has</span>
                                                                        <input id="txtFWAddRooms" type="text" className="common-input-new pac-target-input" placeholder="Select from the options below or write comma seperated features by your own."></input>                                                                 
                                                                    </label>
                                                                </div>
                                                                <a href="javascript:;" id="grFWARs" onClick={() => toggleFWGClass('grFWARs','FWAddRoomsselected','txtFWAddRooms')}>guest room</a>
                                                                <a href="javascript:;" id="lrFWARs" onClick={() => toggleFWGClass('lrFWARs','FWAddRoomsselected','txtFWAddRooms')}>laundry room</a>
                                                                <a href="javascript:;" id="gymrFWARs" onClick={() => toggleFWGClass('gymrFWARs','FWAddRoomsselected','txtFWAddRooms')}>gym room</a>
                                                                <a href="javascript:;" id="basementFWARs" onClick={() => toggleFWGClass('basementFWARs','FWAddRoomsselected','txtFWAddRooms')}>basement</a>
                                                                <a href="javascript:;" id="mediarFWARs" onClick={() => toggleFWGClass('mediarFWARs','FWAddRoomsselected','txtFWAddRooms')}>media room</a>
                                                                <a href="javascript:;" id="storerFWARs" onClick={() => toggleFWGClass('storerFWARs','FWAddRoomsselected','txtFWAddRooms')}>store room</a>
                                                                <a href="javascript:;" id="atticFWARs" onClick={() => toggleFWGClass('atticFWARs','FWAddRoomsselected','txtFWAddRooms')}>attic</a>
                                                                <a href="javascript:;" id="studyrFWARs" onClick={() => toggleFWGClass('studyrFWARs','FWAddRoomsselected','txtFWAddRooms')}>study room</a>
                                                                <a href="javascript:;" id="mudrFWARs" onClick={() => toggleFWGClass('mudrFWARs','FWAddRoomsselected','txtFWAddRooms')}>mud room</a>
                                                                <a href="javascript:;" id="horFWARs" onClick={() => toggleFWGClass('horFWARs','FWAddRoomsselected','txtFWAddRooms')}>home office room</a>
                                                                <a href="javascript:;" id="sunrFWARs" onClick={() => toggleFWGClass('sunrFWARs','FWAddRoomsselected','txtFWAddRooms')}>sun room</a>
                                                                <a href="javascript:;" id="sfrFWARs" onClick={() => toggleFWGClass('sfrFWARs','FWAddRoomsselected','txtFWAddRooms')}>seperate family room</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="float-end">
                                                                <a href="javascript:;" style={{margin:"15px 0px 25px"}} className="common-save" onClick={() =>validateNext(3)}>Generate Description</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>                                        
                                    </div> */}
                                </div>



                                {/* Tab 4 Content */}

                                <div className="col-12" id="divdescription" style={{display:"none"}}>
                                    
                                    {/* <div className="row">
                                        <div className="col-12" style={{position:"relative"}}>
                                        
                                        </div>
                                    </div> */}

                                    {/* Loader animations new*/}

                                    <div className="row" id="newloaderprop" style={{display:"none"}}>
                                        <div className="col-12">
                                            <div className="rotating-text-wrapper-new">
                                                <center></center><img src={loadernew} className="loadernewprop"/>
                                                <div class="rotating-text-wrapper">                                                
                                                    <h2>Hold tight, chocolatechips.ai is writing the description for you!</h2>
                                                    <h2>Adding a powerful headline....</h2>
                                                    <h2>Almost done....</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" id="generatepropnew">
                                    <div className="col-12" style={{position:"relative"}}>
                                        <div id="overlayloader4" className="email-overlay" style={{display:"none"}}>
                                                <div id="loading">
                                                    <h2 id="loaderheading">...</h2>
                                                    <img src={loader} />
                                                </div>
                                        </div>
                                            <div className="row float-end">
                                                {/* <Link to="/propertydescription">Start New</Link> */}
                                                <a href="javascript:;" className="newstart-btn common-save" onClick={() => clearAllDataNew()}>Start New</a>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <h4 id="h4Title" className="prop-top-title">Property Description for </h4>
                                        </div>                                        
                                        <div className="col-12 mb-30">
                                            <div className="row">
                                            <div className="col-12 text-end chech-right" style={{marginBottom:"10px"}}>
                                                    {/* <span><FontAwesomeIcon icon={faCopy} onClick={this.copyToClipboard} style={{marginLeft:'0px'}} /></span> */}
                                                    {copySacpeAvailable == true ? 
                                                    <span onClick={Checkplagiarism} tooltip="Check plagiarism" flow="left">
                                                    <FontAwesomeIcon icon={faShield} style={{fontSize:'24px',cursor:"pointer",color:'#9333ea'}} />
                                                    </span> : null }
                                                    <span
                                                    id="plagiarisminfo"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                    className="text-count"
                                                    style={{
                                                        backgroundColor: "#f44336",
                                                        color: "#fff",
                                                        display: "none",
                                                    }}
                                                    >
                                                    5 copies found
                                                    </span>
                                                    {/* <span className="text-count">5 words</span> */}
                                                </div>
                                            </div>
                                            <div className="row">                                                                                                
                                                 {/* {generatedcontent !=null && generatedcontent !="" ? generatedcontent.map((d) => (  */}
                                                    <div className="col-12">
                                                            
                                                        <div id="divOuterContent" className="output-wrap slider"> 
                                                        <span className="float-end" id="spanCharCount" style={{fontSize:"13px",display:"block"}}>Character Count: <b id="lblCcount">125</b></span>                                                           
                                                        {(grammarlyKeyData != "" ? 
                                                        <GrammarlyEditorPlugin clientId={Cookies.get('g_key')}>
                                                            <div id="divGeneratedContent" style={{minHeight:"55vh"}} className='out-content market-scroll'>
                                                                
                                                            </div>
                                                        </GrammarlyEditorPlugin>     
                                                        :
                                                        <div id="divGeneratedContent" style={{minHeight:"55vh"}} className='out-content market-scroll'>
                                                                
                                                        </div>
                                                        )}
                                                            <div id="divOutputIcons" style={{display:"none", position: "relative",paddingBottom:"50px",zIndex:"39999"}} className="out-icons">
                                                                <span className="float-end">
                                                                    <a href="javascript:;" onClick={()=>copyToClipboard()} className="common-border-btn">Copy</a>
                                                                    <a href="javascript:;" id="hrefEditContent" onClick={()=>copyToClipboardEdit()} className="common-border-btn">Update</a>
                                                                    {/* <a href="javascript:;" id="hrefEditContent" onClick={()=>Highlightkeys('dropped or false ceiling, flooring parquet')} className="common-border-btn">replace</a> */}
                                                                    <a href="javascript:;" onClick={()=> generateaicontent()} className="common-border-btn">Rewrite</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                 {/* )) : null }   */}
                                            </div>
                                        </div>
                                        <div className="col-12" style={{display:"none"}}>                                            
                                            <span className="float-end"><a href="javascript:;" className="common-save">View previously generated descriptions</a></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            {/* plagarism */}
            <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Plagiarism
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" id="plagiarismmodalbody" dangerouslySetInnerHTML={plagiarismmodalbody}></div>
            </div>
          </div>
        </div>
            {/* plagarism end */}

            <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
 <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Upgrade Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                <div className="col-12">
                  <p id="psubtext"></p>
                  <div className="row" style={{marginBottom:"10px",display:"none"}}>
                      <div className='col-3'>
                          <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                          <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                      </div>
                  </div>
                  {/* {SSOUser == true ?
                  <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                 <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>} */}
                </div>
              </div>
              <div className="modal-footer">     
                <button type="button" style={{display:"block"}} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>                                  
                <a href='javascript:;' id='btnUpgrade' onClick={proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
              </div>
          </div>
      </div>
      </div>

            </div>
        );    
    }

export default Listingdescription;
