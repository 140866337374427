import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, { useState } from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader, { List } from "react-content-loader";
import * as axios from 'axios';
import Cookies from 'js-cookie';


// const MyLoader = () => <List />
const MyLoader = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

const MyLoader1 = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

class whitelisthistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyworddisabled: true,
            tonedisabled: true,
            copySuccess: '',
            wordcount: 0,
            Tonecount: 0,
            generatedimages: [],
            emailguid: NIL_UUID,
            emailhtml: "",
            defaultcontenterased: false,
            tabledata: [],
            dashtotalcount: -1,
            dashrecentimagescount: -1,
            dashauthorizedcount: -1,
            dashunauthorizedcount: -1,
            dashlastupdated: ""


        }
    }
    componentDidMount() {
        //this.loadtabledata();
        // this.setState({emailguid: uuidv4()});
        this.jquerycode();
       // this.gettoppaneldata();
        // setInterval(() => {			
        // 	this.checkandupdatecontent();
        //   }, 10000);
        this.loadWhitelistHistory();
    }


    jquerycode = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('1').classList.add("active");
    }


    historygoback = () => {
        window.history.back();
    }

    gettoppaneldata = () => {
        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` + '/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.Status == "200") {
                    var data = response.data.Data;
                    this.setState({
                        dashtotalcount: data.Totalcount,
                        dashrecentimagescount: data.Recentimagescount,
                        dashauthorizedcount: data.Authorizedcount,
                        dashunauthorizedcount: data.Unauthorizedcount,
                        dashlastupdated: new Date(data.Lastupdated).toString()
                    })
                }
                else {

                }

            })
            .catch((error) => {
                console.log(error);
            });
    }
    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }
    loadWhitelistHistory = () =>{
        var userI = Cookies.get('userInfo');// getAccessToken();
          var decodedUser = decodeURIComponent(userI);
          // console.log(decodedUser);
          var parsedRes = JSON.parse(decodedUser);
          var compId = parsedRes.CompanyId;

        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetWhitelistHistory?CompanyId='+compId,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.Status == "200") {
                    var data = response.data.Data;
                    //console.log(data);
                    if (data != null && data.length > 0) {                        
                        //loop through data table
                        document.getElementById('divData').style.display="block";
                        document.getElementById('divNoData').style.display="none";
                        //ulHistory
                        var innerData = "";
                        for (var i = 0; i < data.length; i++) {

                            var dateT = data[i].UserDate;
                            var domain = data[i].DomainName.trim();
                            var addedOrDel = data[i].Type == 1 ? " was removed from the whitelist ":" was added to the whitelist ";
                            var IpAddres = data[i].IpAddres;
                            var domainurl = domain;
                            if (domain.indexOf("https://") == -1 && domain.indexOf("http://") == -1) {
                                domainurl = ("https://" + domain);
                            }
                            //February 25, 2024 at 4:25 PM
                            var d = new Date(dateT).toLocaleString('en-US', { month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'long' });
                            //console.log(date.toDateString());
                            //Do something Id: 2, DomainName: 'microsoft.com', UserDate: '2024-03-08T06:54:49.86', IpAddres: '34.87.1.0', Type: 1
                            innerData += "<li>" + d +": Domain&nbsp;<a href='"+domainurl+"' target='_blank'>"+new URL(domainurl).host +"</a>&nbsp;"+addedOrDel+"("+IpAddres+")</li>";
                        }
                        document.getElementById('ulHistory').innerHTML=innerData;
                    } else {
                        document.getElementById('divData').style.display="none";
                        document.getElementById('divNoData').style.display="block";
                    }                    
                }
                else {
                    document.getElementById('divData').style.display="none";
                    document.getElementById('divNoData').style.display="block";
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById('divData').style.display="none";
                document.getElementById('divNoData').style.display="block";
            });
    }
    customAlertMessage = (icon,message) =>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end', 
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
  
        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }
    render() {
        return (
            <>
            <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div>
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4><span style={{ cursor: "pointer" }} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} /><u>Sherlock Setup:</u> Whitelisted Domain History</span></h4>                                    
                                </span>
                            </div>
                        </div>

                        <div id='divData' className='col-12' style={{ marginBottom: "20px" }}>
                            <ul id="ulHistory" className='whitelisthistoryul'>                               
                            </ul>                            
                        </div>
                        <div id='divNoData'>
                            No data found.
                        </div>



                    </div>
                </div>
            </div>
            </>
        )
    }

}

export default whitelisthistory;