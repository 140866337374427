import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React from 'react';
import {UserID,PaymentCustomerId,EmailId,Role} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';

export const getAccessToken = () => Cookies.get('userInfo');

class ImageTransformSub extends React.Component {

    componentDidMount(){
        this.showHideSub();
    }

    historygoback = () => {
        window.history.back();
    };

    showHideSub = () =>{
        if(Role == "3"){
            document.getElementById('divMessage').style.display ="block";
            document.getElementById('divSubscribe').style.display ="none";
            document.getElementById('divSubscribeHead').style.display ="none";
        }
        else{
            document.getElementById('divMessage').style.display ="none";
            document.getElementById('divSubscribe').style.display ="block";
            document.getElementById('divSubscribeHead').style.display ="block";
        }
    }
    
    fillPriceDetails = () =>{
        
        var spanPrice = document.getElementById('spanPrice');
        var spanPFee = document.getElementById('spanPFee');
        var spanTotal = document.getElementById('spanTotal');
        
        var priceMain = parseInt(5);
        spanPrice.innerHTML = '$' + priceMain;
        var pf = this.calculateProcFee(priceMain);
        var t = parseFloat(priceMain) + pf;
        spanPFee.innerHTML = '$' +pf;
        spanTotal.innerHTML = '$' + t;
    }

    calculateProcFee = (Input) => {
        var outputFloat = 0.00;
        var inputFloatstring = parseFloat(Input).toFixed(2);
        var inputFloat = parseFloat(inputFloatstring)

        try {
            var fee = 0.00;
            fee = ((inputFloat / 100) * 2.9) + 0.30;

            var outputFloatstring = parseFloat(fee).toFixed(2);
            outputFloat = parseFloat(outputFloatstring);

        } catch (e) {

        }
        return outputFloat;
    }

    showPricingToUser = () => {
        this.fillPriceDetails();
        this.openPriceModel();
        return;
    }

    proccedOnPrice = () =>{        
        this.subscribeImageTransformation();
    }   

    openPriceModel = () =>{
        document.getElementById('hrefPriceModal').click();
    }
    closePriceModel = () =>{
        document.getElementById('btnCancelPriceModal').click();
    }

    subscribeImageTransformation = () => {
        //SubscribeTexToImage(string MasterId,string SubOpt
        var self = this;
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/SubscribeImageTransform?MasterId="+UserID+"&SubOpt=1&EmailId="+EmailId ,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    var userI = getAccessToken();
                    var decodedUser = decodeURIComponent(userI);
                    var parsedRes = JSON.parse(decodedUser);
                    parsedRes.ImageTransformSub = true;
                    const serializedState2 = JSON.stringify(parsedRes);
                    Cookies.set('userInfo', serializedState2); 
                    self.customAlertMessage('success', "Subscription added successfully");                    
                    setTimeout(() => {
                        window.location.href = window.location.origin;
                        }, 3000);   
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }

    render(){
        return (
            <>
            <div className="main_content_iner overly_inner dashboard-page">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div id="overlay">
                            <div id="loading">
                                <h2 id="loaderheading"></h2>
                                <img src={loader} />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="title-card1">
                                <h4>
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={this.historygoback}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                    Manage Image Transform Subscription
                                </span>
                                </h4>
                            </div>
                        </div>

                        <div id="divSubscribeHead" className="col-12">
                            <div className="row">
                                <div className="col-12 text-center plansui">
                                {/* <h3>Text To Image</h3> */}
                                    <label id="h4CurrentPlan">Unlimited Image Transformation</label> 
                                </div>
                                <div className="col-12 text-center plansui">      
                                    <label>$5/month - Billed as per your main subscription billing cycle</label>     
                                    <h4></h4>            
                                </div>
                            </div>
                        </div>
                        <div id="divSubscribe" className='col-12'>
                            <div className="row">
                                <div className='col-4'></div>
                                <div className='col-5'>
                                    <button type="button" onClick={this.showPricingToUser} className="btn btn-primary">Subscribe</button>
                                    &nbsp;<Link to="/" id='btnDiscard' className="common-clear">Back</Link>
                                </div>
                                <div className='col-3'></div>
                            </div>
                        </div>
                        <div id="divMessage" style={{display:"none"}} classname='col-12'>
                            <div className="row">
                            <div className='col-3'></div>
                            <div className='col-6'>
                               <center><span style={{color:"red"}}>Please contact your admin for the Image Transform subscription.</span></center>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
                <a href='javascript:;' id='hrefPriceModal' data-bs-toggle="modal"
                          data-bs-target="#pricingmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
            </div>


            {/* price model start */}
            <div className="modal fade" id="pricingmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id="lblPricingTitle"><b>Billing Details</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Price:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanPrice'></span>
                                    </div>
                                </div>          
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Processing fee:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanPFee'></span>
                                    </div>
                                </div>        
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Total:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanTotal'></span>
                                    </div>
                                </div>                                             
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btnCancelPriceModal' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnCancelSub' onClick={this.proccedOnPrice} className="common-save float-start">Proceed</a>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* price model end */}

            </>
        )
    }
};
export default ImageTransformSub;    