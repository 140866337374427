import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faChevronLeft,
    faMicrophone,
    faInfo,
    faInfoCircle,
    faRotateRight,
    faShield,
    faCloudArrowUp,
    faCircleDown,
    faFile,
    faTimes,
    faCopy,
    faImage,
    faCheck,
    faCross,
    faXmark,
    faRemove
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import copy from 'copy-to-clipboard';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg, MasterId } from "../User";
import fileDownload from "js-file-download";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import loadernew from "../img/loader1.gif";
// import withReactContent from 'sweetalert2-react-content'
import Propertytype from "./propertytype";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
//import SearchLocationInput from "./SearchLocationInput";
import * as axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';
import { ToggleButton } from "react-bootstrap";
import PropDescImg from '../img/19.jpg';
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import { Draggable } from "react-drag-reorder";
import Sortable from 'sortablejs';
import heic2any from "heic2any";
import { n2d } from "n2d";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Cropper from 'react-easy-crop'
import getCroppedImg from '../pages/cropimage'


// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

    const loadermessages = [
        "Lights, camera, action! Your video is in the making. Sit tight!",
        "The video magic is happening behind the scenes. It'll be worth the wait!",
        "Your video is like a blockbuster in the making. Grab some popcorn and get ready!",
        "Lights are flashing, cameras are rolling, and your video is on its way!",
        "Good things come to those who wait, and a fantastic video is coming your way!",
        "The countdown has begun! Your video will be ready before you can say 'action!'",
        "Please stand by for an explosive video experience. It's going to blow you away!",
        "While the video comes to life, here's a fun fact: Did you know laughter can burn calories?",    
        "We're assembling pixels and frames to create a video that will leave you in stitches. Thanks for your patience!",
        "Sit back, relax, and get ready to hit the play button on a video that will make your day!",
        "The video generator is working its magic. Hold on tight!",
        "Your video is being crafted with care and thought. It won't be long now!",
        "The video gods are sprinkling design to every second. Your patience is greatly appreciated!"
      ]
      const finalmessagearr = [
        "Hold on a tad longer, we're almost ready to dazzle you.",
        "Your video is being brewed. It'll be served shortly!",
        "We're adding the final touches to your video masterpiece.",
        "The video-making elves are working tirelessly. Stay tuned!",
        "Your video is currently in the editing room. It'll be ready soon!",
        "Your video is almost ready to hit the big screen! Get ready to press play and enjoy the show!"
      ];

const Propertyvideo = (props) => {
    const [eventtypeid, seteventtypeid] = useState(1);
    const [eventtypename, seteventtypename] = useState("Just Listed");
    const [templateid, settemplateid] = useState(1);
    const [templatename, settemplatename] = useState("Vibrant Orange");
    const [processedimage, setprocessedimage] = useState([]);
    const [uploadedfile, setuploadedfile] = useState([]);
    const [activestep, setactivestep] = useState(1);
    const [stepscompleted, setstepscompleted] = useState(0);
    const [uploadedfileprofilepicture, setuploadedfileprofilepicture] = useState("");
    const [processedimageprofilepicture, setprocessedimageprofilepicture] = useState("");
    const [propertydescription, setpropertydescription] = useState({});
    const [agentdetails, setagentdetails] = useState({});
    const [dropdownvalues, setdropdownvalues] = useState([]);
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    const [locationId, setLocationId] = useState("");
    const [finalvideo, setfinalvideo] = useState("");
    const [previousvideodata, setpreviousvideodata] = useState("");
    const [loadermessages, finalmessagearr] = useState("");
    const [orderedprocessedimage, setorderedprocessedimage] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [videocreatedformdata, setvideocreatedformdata] = useState({});

    const [cropSize,setcropSize] = useState({width: 200, height: 200})
    const [crop,setcrop] = useState({ x: 0, y: 0 });
    const [zoom,setzoom] = useState(1);    
    const [aspect,setaspect] = useState(1);
    const [rotation, setrotation] = useState(0);
    const [croppedimage,setcroppedimage] = useState(null)
    const [croppedAreaPixels,setcroppedAreaPixels] = useState(null);
    const [croppedArea,setcroppedArea] = useState(null)
    const [localbloburl,setlocalbloburl] = useState("");
            
            // croppedimage:profileiamge(),
            // croppedAreaPixels:null,
            // croppedArea:null,

            const onCropChange = (crop) => {
                setcrop(crop);
              }

            const onCropComplete = (croppedArea, croppedAreaPixels) => {
                setcroppedArea(croppedArea);
                setcroppedAreaPixels(croppedAreaPixels);
                cropimage();
              }

            const onZoomChange = (zoom) => {
                setzoom(zoom);
              }

            const cropimage = async() => {
                const _croppedImage = await getCroppedImg(processedimageprofilepicture, croppedAreaPixels,rotation);
                 console.log('donee', { _croppedImage })
                document.getElementById("croppedimagehdn").value = _croppedImage;
                // console.log("fdsad: "+ document.getElementById("croppedimagehdn").value);
                setcroppedimage(_croppedImage);
                setlocalbloburl(_croppedImage);
                // this.setState({croppedimage:_croppedImage,localbloburl:_croppedImage});
                 uploadimagetoserver();
              }
              const uploadimagetoserver = () => {
                var filepathsrc = document.getElementById("croppedimagehdn").value;// document.getElementsByClassName('reactEasyCrop_Container')[0].getElementsByTagName('img')[0].src; 
                var self = this;
                showoverlayone();
                var requestOptions = {
                  method: 'GET',
                  redirect: 'follow'
                };
                
                fetch(filepathsrc, requestOptions)
                  .then(response => response.blob())
                  .then(result => {
                    let metadata = {
                      type: 'image/jpeg'
                    };
                    let file = new File([result], "test.jpg", metadata);
                    setuploadedfileprofilepicture(file);
                    hideoverlayone();
                  })
                  .catch(error => 
                    {
                      console.log('error', error);
                      self.customAlertMessage('error', 'Something went wrong, Try Again.');
                      hideoverlayone();
                    });
              }
            const blobToFile = (theBlob, fileName) =>{
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            }
            const createFile = async(bloburl) => {
                let response = await fetch(bloburl);
                let data = await response.blob();
                let metadata = {
                  type: 'image/jpeg'
                };
                let file = new File([data], "test.jpg", metadata);
                return file;
                // ... do something with the file or return it
              }

    let autoComplete;

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    const usetemplate = (e) => {
        var templateid = e.currentTarget.dataset.templateid;
        var templatename = e.currentTarget.dataset.templatename;
        var eventelem = document.getElementById('ddleventtype');
        var eventtypename = eventelem.options[eventelem.selectedIndex].text;
        var eventtypeid = eventelem.value;
        seteventtypeid(eventtypeid);
        seteventtypename(eventtypename);
        settemplateid(templateid);
        settemplatename(templatename);
        setactivestep(2);
        setstepscompleted(1);
        setTimeout(() => {
            loaddropdownfromstate()
        }, 2000);
        // this.setState({
        //     eventtypename:eventtypename,
        //     eventtypeid:eventtypeid,
        //     templateid:templateid,
        //     templatename:templatename,
        //     uploadedfile:[]
        // });
        // document.getElementById('uploadimages').style.display = "block";
        // document.getElementById('selecttemplate').style.display = "none";
        // document.getElementById('div2').classList.remove('inactive');
    }
    // const additionalfileschange = (e) => {
    //     var tempfilesarr = e.target.files;
    //     var tempfileaftervalidation = [];
    //     var uploadedfilearray = [];
    //     for (let i = 0; i < tempfilesarr.length; i++) {
    //         const element = tempfilesarr[i];
    //         var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
    //         if (!validfile) {
    //             customAlertMessage('error', 'File type not supported files removed.');
    //             return;
    //         }
    //         if (element.size < 10485760) {
    //             var uploadedfile_json = {};
    //             var tempfileaftervalidation_json = {};
    //             var uniqueid = uuidv4();
    //             uploadedfile_json.identity = uniqueid;
    //             uploadedfile_json.binaryfile = element;
    //             uploadedfilearray.push(uploadedfile_json);

    //             tempfileaftervalidation_json.identity = uniqueid;
    //             tempfileaftervalidation_json.binaryfile = element;
    //             tempfileaftervalidation.push(tempfileaftervalidation_json);
    //             // tempfileaftervalidation.push(element);
    //             // readFileadditional();
    //         }
    //         else {
    //             customAlertMessage('error', 'Maximum file size exceeded.');
    //             return;
    //         }
    //     }
    //     readFileadditional(tempfileaftervalidation);
    //     setuploadedfile([...uploadedfilearray]);
    //     document.getElementById('additionalfiles').value = "";
    // }

    const readiphoneFileondropprofilepic = (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            showoverlayone();
            reader.onload = function (e) {
                fetch(e.target.result)
                    .then((res) => res.blob())
                    .then((blob) => heic2any({ blob }))
                    .then((conversionResult) => {
                        // conversionResult is a BLOB
                        // of the PNG formatted image
                        var url = URL.createObjectURL(conversionResult);
                        var file = new File([conversionResult], uuidv4() + ".png");
                        setuploadedfileprofilepicture(file);
                        setprocessedimageprofilepicture(url);
                        // self.setState({ processedimage: url,uploadedfile:file,uploadedbase64:url });
                        // var wrapperZone = $('.dropzone-wrapperprofilepic');
                        // var previewZone = $('.preview-zone');                
                        // wrapperZone.removeClass('dragover');
                        // previewZone.removeClass('hidden');
                        // $('.dropzone-wrapperprofilepic').addClass('hidden');                
                        hideoverlayone();
                    })
                    .catch((e) => {
                        // see error handling section
                        customAlertMessage('error', 'Error occured, please try again.');
                        hideoverlayone();
                    });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const readFileondropprofilepic = (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            // self.showoverlayiphone();
            reader.onload = function (e) {
                setprocessedimageprofilepicture(e.target.result);
                // var wrapperZone = $('.dropzone-wrapper');
                // var previewZone = $('.preview-zone');
                // wrapperZone.removeClass('dragover');
                // previewZone.removeClass('hidden');
                // //boxZone.empty();
                // //boxZone.append(htmlPreview);
                // $('.dropzone-wrapper').addClass('hidden');
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const readiphoneFile = () => {
        var input = $('.dropzoneprofilepic')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            // self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                    .then((res) => res.blob())
                    .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                    .then((conversionResult) => {
                        // conversionResult is a BLOB
                        // of the PNG formatted image
                        var url = URL.createObjectURL(conversionResult);
                        var file = new File([conversionResult], uuidv4() + ".png");
                        setuploadedfileprofilepicture(file);
                        setprocessedimageprofilepicture(url);
                        // self.setState({ processedimage: url,uploadedfile:file,uploadedbase64:url });
                        // var wrapperZone = $(input).parent();
                        // var previewZone = $(input).parent().parent().find('.preview-zone');
                        // wrapperZone.removeClass('dragover');
                        // previewZone.removeClass('hidden');
                        // $('.dropzone-wrapper').addClass('hidden');
                        // self.hideoverlayiphone();
                    })
                    .catch((e) => {
                        // see error handling section
                        customAlertMessage('error', 'Error occured, please try again.');
                        // self.hideoverlayiphone();
                    });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const readiphoneFileprofilepicture = () => {
        var input = $('.dropzoneprofilepic')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            showoverlayone();
            reader.onload = function (e) {
                fetch(e.target.result)
                    .then((res) => res.blob())
                    .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                    .then((conversionResult) => {
                        // conversionResult is a BLOB
                        // of the PNG formatted image
                        var url = URL.createObjectURL(conversionResult);
                        var file = new File([conversionResult], uuidv4() + ".png");
                        setuploadedfileprofilepicture(file);
                        setprocessedimageprofilepicture(url);
                        hideoverlayone();
                        // self.setState({ processedimage: url,uploadedfile:file,uploadedbase64:url });
                    })
                    .catch((e) => {
                        // see error handling section
                        customAlertMessage('error', 'Error occured, please try again.');
                        $('.dropzoneprofilepic')[0].value = "";
                        hideoverlayone();
                    });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const readFileprofilepicture = () => {
        var input = $('.dropzoneprofilepic')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setprocessedimageprofilepicture(e.target.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    const dropzonechange = (e)=> {
        var tempfilesarr = e.target.files;// $(".dropzone")[0].files;
        if (tempfilesarr.length <= 0) {
            return;
        }
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 10485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                    // if (alreadyuploadedfile.length > 0) {
                    //     continue;
                    // }
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);

                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                    console.log('file added: '+ element.name);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                                // if (alreadyuploadedfile.length <= 0) {
                                //     continue;
                                // }
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);

                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                console.log('file added iphone: '+ file.name);
                                console.log('files added : '+ tempfileaftervalidation.length);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    console.log('fired to load inside iphone')
                                    setTimeout(() => {
                                        readFile(tempfileaftervalidation);
                                        setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);    
                                    }, 2000);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);  
                                }
                            });

                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFile(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
            console.log('fired to load outside iphone')
        }
    };
    const dropzonedragenter = (e) => {
        e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').addClass('dragover');
    }
    const dropzonedragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').addClass('dragover');
    };
    const dropzonedragleave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').removeClass('dragover');
    };
    const dropzonefiledrop = (event) => {
        event.preventDefault();
        var tempfilesarr = event.dataTransfer.files;
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 10485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);

                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);

                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFileondrop(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            });

                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFileondrop(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
        }
        // $(".dropzone")[0].value = "";
    };

    useEffect(() => {
        $(".dropzoneprofilepic").on("change", function () {
            var tempfile = $(".dropzoneprofilepic")[0].files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported.');
                return;
            }
            if (tempfile.size < 10485760) {
                setuploadedfileprofilepicture(tempfile);
                // self.state.uploadedfile = tempfile;
                if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    readiphoneFileprofilepicture();
                }
                else {
                    readFileprofilepicture();
                }
            }
            else {
                this.customAlertMessage('error', 'Maximum file size exceeded.');
                return;
            }
        });

        $('.dropzone-wrapperprofilepic').on('dragover', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapperprofilepic').addClass('dragover');
        });

        $('.dropzone-wrapperprofilepic').on('dragleave', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapperprofilepic').removeClass('dragover');
        });
        document.querySelector('.dropzone-wrapperprofilepic').addEventListener("drop", (event) => {
            event.preventDefault();
            var tempfile = event.dataTransfer.files[0];
            var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
            if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported.');
                return;
            }
            setuploadedfileprofilepicture(tempfile);
            if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                readiphoneFileondropprofilepic(event.dataTransfer);
            }
            else {
                readFileondropprofilepic(event.dataTransfer);
            }
        });

        // $(".dropzone").on("change", function (e) {
        //     var tempfilesarr = e.target.files;// $(".dropzone")[0].files;
        //     if (tempfilesarr.length <= 0) {
        //         return;
        //     }
        //     var tempfileaftervalidation = [];
        //     var uploadedfilearray = [];// uploadedfile;
        //     var containsiphonefiles = false;
        //     var invalidfilecount = 0;
        //     debugger;
        //     for (let i = 0; i < tempfilesarr.length; i++) {
        //         const element = tempfilesarr[i];
        //         var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png")
        //         if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
        //             customAlertMessage('error', 'File type not supported files removed.');
        //             invalidfilecount += 1;
        //             continue;
        //         }
        //         if (element.size < 10485760) {
        //             var uploadedfile_json = {};
        //             var tempfileaftervalidation_json = {};
        //             if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
        //                 // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
        //                 // if (alreadyuploadedfile.length > 0) {
        //                 //     continue;
        //                 // }
        //                 var uniqueid = uuidv4();
        //                 uploadedfile_json.identity = uniqueid;
        //                 uploadedfile_json.binaryfile = element;
        //                 uploadedfilearray.push(uploadedfile_json);

        //                 tempfileaftervalidation_json.identity = uniqueid;
        //                 tempfileaftervalidation_json.binaryfile = element;
        //                 tempfileaftervalidation.push(tempfileaftervalidation_json);
        //                 console.log('file added: '+ element.name);
        //             }
        //             else {
        //                 containsiphonefiles = true
        //             }
        //         }
        //         else {
        //             customAlertMessage('error', 'Maximum file size exceeded images removed.');
        //             invalidfilecount += 1;
        //         }
        //     }
        //     if (containsiphonefiles) {
        //         for (let i = 0; i < tempfilesarr.length; i++) {
        //             const element = tempfilesarr[i];
        //             if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
        //                 var reader = new FileReader();
        //                 reader.onload = function (e) {
        //                     showoverlaytwo();
        //                     fetch(e.target.result)
        //                         .then((res) => res.blob())
        //                         .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
        //                         .then((conversionResult) => {
        //                             // conversionResult is a BLOB
        //                             // of the PNG formatted image
        //                             var url = URL.createObjectURL(conversionResult);
        //                             var file = new File([conversionResult], uuidv4() + ".jpg");
        //                             // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
        //                             // if (alreadyuploadedfile.length <= 0) {
        //                             //     continue;
        //                             // }
        //                             var uploadedfile_json = {};
        //                             var tempfileaftervalidation_json = {};
        //                             var uniqueid = uuidv4();
        //                             uploadedfile_json.identity = uniqueid;
        //                             uploadedfile_json.binaryfile = file;
        //                             uploadedfilearray.push(uploadedfile_json);

        //                             tempfileaftervalidation_json.identity = uniqueid;
        //                             tempfileaftervalidation_json.binaryfile = file;
        //                             tempfileaftervalidation.push(tempfileaftervalidation_json);
        //                             console.log('file added iphone: '+ file.name);
        //                             console.log('files added : '+ tempfileaftervalidation.length);
        //                             if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
        //                                 console.log('fired to load inside iphone')
        //                                 setTimeout(() => {
        //                                     readFile(tempfileaftervalidation);
        //                                     setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);    
        //                                 }, 2000);
        //                             }
        //                         })
        //                         .catch((e) => {
        //                             // see error handling section
        //                             //customAlertMessage('error','Error occured while processing an image, please try again.'); 
        //                             // hideoverlaytwo();
        //                             invalidfilecount += 1;
        //                             if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
        //                                 readFile(tempfileaftervalidation);
        //                                 setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);  
        //                             }
        //                         });

        //                 }
        //                 reader.readAsDataURL(element);
        //             }
        //         }
        //     }
        //     else {
        //         readFile(tempfileaftervalidation);
        //         setuploadedfile([...uploadedfilearray]);
        //         console.log('fired to load outside iphone')
        //     }


        //     // var tempfile = $(".dropzone")[0].files[0];
        //     // var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
        //     // if (!validfile) {
        //     //     customAlertMessage('error', 'File type not supported.');
        //     //     return;
        //     // }
        //     // if (tempfile.size < 10485760) {
        //     //     setuploadedfile($(".dropzone")[0].files);
        //     //     readFile();
        //     // }
        //     // else {
        //     //     customAlertMessage('error', 'Maximum file size exceeded.');
        //     //     return;
        //     // }
        //     //self.readFile();
        //     // $(".dropzone")[0].value = "";
        // });       
        console.log('loadScript called');
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxpx25xmwMAYNW3Diz0TjL3N8CtTURTK0&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef, setLocationId)
        );
        document.getElementById('txtagentphone').addEventListener('input', function (e) {
            e.target.value = e.target.value.replace(/\D/g, '').replace(/^(\d{3})(\d{3})(\d+)$/, '($1) $2-$3');
        });
        // var videos = document.querySelectorAll('video');
        // for(var i=0; i<videos.length; i++)
        //     videos[i].addEventListener('play', function(){pauseAll(this)}, true);
    }, [])

    function pauseAll(elem) {
        var videos = document.querySelectorAll('video');
        for (var i = 0; i < videos.length; i++) {
            //Is this the one we want to play?
            if (videos[i] == elem.currentTarget) continue;
            //Have we already played it && is it already paused?
            if (videos[i].played.length > 0 && !videos[i].paused) {
                // Then pause it now
                videos[i].pause();
            }
        }
    }


    function handleScriptLoad(updateQuery, autoCompleteRef, updateLocation) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { types: ["address"], componentRestrictions: { country: "us" } }
        );
        autoComplete.setFields(["address_components", "formatted_address", "place_id", "geometry"]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery, updateLocation)
        );
    }
    async function handlePlaceSelect(updateQuery, UpdateLocation) {
        const addressObject = autoComplete.getPlace();
        const query = addressObject.formatted_address;
        updateQuery(query);
        // console.log(addressObject);
        const latitude = addressObject.geometry.location.lat();
        const longitude = addressObject.geometry.location.lng()
        // console.log(latitude + "," + longitude);
        UpdateLocation(latitude + "," + longitude);
        document.getElementById("hfLocationId").value = latitude + "," + longitude;
        onAddressSelection(latitude, longitude);
    }

    function onAddressSelection(funlat, funlng) {
        //alert('inside address selection');
        var locationIdValue = document.getElementById("hfLocationId").value
        //console.log('address location id '+ locationIdValue);    
        // showoverlay();
        // setTimeout(() => {
        //     loadAmenities(funlat,funlng);    
        // }, 3000);

    }

    const onTabClick = (e) => {
        var selectedTI = parseInt(e.currentTarget.dataset.tabid);
        var ctab = document.getElementById("hfCurrentTab").value;
        if (activestep == 3) {
            saveimageorfering();
        }
        if (selectedTI > activestep) {
            if (selectedTI > 2){ // (activestep == 2 && selectedTI !=1 && selectedTI != 2) {
                var validationfailed = movetoorderingontabclick();
                if (validationfailed == false) {
                    return;
                }
            }
            if (activestep > 4) {
                var cannavigate = validatedetailsontabclick();
                if(cannavigate == false){
                    return;
                }
            }
        }
        
        switch (selectedTI) {
            case 1:
                setactivestep(1);
                break;
            case 2:
                if (stepscompleted >= 1) {
                    setactivestep(2);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                }
                break;
            case 3:
                if (stepscompleted >= 2) {
                    setactivestep(3);
                    // loaddropdownfromstate();
                }
                break;
            case 4:
                if (stepscompleted >= 3) {
                    setactivestep(4);
                    document.getElementById("txtpropaddress").setAttribute("autocomplete", "Nope");
                }
                break;
            case 5:
                if (stepscompleted >= 4) {                    
                    // cannavigate = validatedetailsontabclick();
                    // if(cannavigate == true)
                    {
                        firecreatevideo();
                        setactivestep(5);                        
                    }                    
                }
                break;
            case 6:
                if (stepscompleted >= 5) {                    
                    setactivestep(6);                    
                }
                break;
            case 7:
                if (stepscompleted >= 6) {
                    setactivestep(7);
                }
                break;
        }
        document.getElementById('outputvideo').pause();
        document.getElementById("hfCurrentTab").value = selectedTI;
    };
    const loaddropdownfromstate = () => {
        if (dropdownvalues.length > 0) {
            var selectddl = document.getElementsByClassName('imageoption');
            for (let i = 0; i < selectddl.length; i++) {
                const element = selectddl[i];
                var refid = element.dataset.filerefid;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    element.value = selectedoption[0].text;
                }
            }
            hideoverlaytwo();
        }
        else {
            hideoverlaytwo();
        }
    }
    const eventtypechanged = () => {
        var ddleventid = document.getElementById('ddleventtype').value;
        seteventtypeid(ddleventid);
        // console.log(ddleventid);
        // var videos = document.querySelectorAll('video');
        // for(var i=0; i<videos.length; i++)
        //     videos[i].addEventListener('play', function(){pauseAll(this)}, true);
        //this.customAlertMessage('info','alert changed')
    }
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: icon, //'success',
            title: message, // 'Signed in successfully'
        });
    };

    const readFile = (inputfiles) => {
        // var input = $('.dropzone')[0];
        // var inputfiles = input.files;
        var singletimearray = [];// [...processedimage];
        var propertyorderedlist = [];// [...orderedprocessedimage];
        console.log('inputfiles.length',inputfiles.length);
        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            console.log('reading inputfiles : ',element.name);
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id =timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                if (orderedprocessedimage.length > 0) {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                    var processedimagenew = singletimearray.sort(function (a, b) { return a.id - b.id; });
                    // var processedimagenew = [...singletimearray];//.splice(0);
                    // console.log(processedimagenew.length);
                    console.log([...processedimagenew]);
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                    // var previewZone = $('.preview-zone');
                    // previewZone.removeClass('hidden');
                    // $('.dropzone-wrapper').addClass('hidden');
                }
            };
            reader.readAsDataURL(element);
        }
    }

    // const readFileadditional = (inputfiles) => {
    //     // var input = $('#files');
    //     // var inputfiles = input.files;
    //     var singletimearray = processedimage;
    //     for (let i = 0; i < inputfiles.length; i++) {
    //         const element = inputfiles[i].binaryfile;
    //         var reader = new FileReader();
    //         reader.onload = function (e) {
    //             var uniqimg = {};
    //             uniqimg.refid = inputfiles[i].identity;
    //             uniqimg.id = moment().valueOf();//singletimearray.length + i;
    //             uniqimg.image = e.target.result;
    //             singletimearray.push(uniqimg);
    //             if (i == (inputfiles.length - 1)) {
    //                 var _processedimage = singletimearray.sort(function (a, b) { return a.id - b.id; });
    //                 console.log(_processedimage);
    //                 setprocessedimage([..._processedimage]);
    //                 showoverlaytwo();
    //                 setTimeout(() => {
    //                     loaddropdownfromstate();
    //                 }, 2000);
    //             }
    //         };
    //         reader.readAsDataURL(element);

    //     }
    // }


    const readFileondrop = (inputfiles) => {
        //var input = $('.dropzone')[0];
        // var inputfiles = input.files;
        var singletimearray = []; // processedimage;
        var propertyorderedlist = [];// [...orderedprocessedimage];
        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id = timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                if (orderedprocessedimage.length > 0) {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                     var processedimagenew = singletimearray.sort(function (a, b) { return a.id - b.id; });
                    // console.log(_processedimage);
                    // var processedimagenew = [...singletimearray];
                    // setprocessedimage([...processedimagenew]);
                    // setorderedprocessedimage([...propertyorderedlist]);
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                    // var wrapperZone = $('.dropzone-wrapper');
                    // var previewZone = $('.preview-zone');
                    // wrapperZone.removeClass('dragover');
                    // previewZone.removeClass('hidden');
                    // $('.dropzone-wrapper').addClass('hidden');
                }
                // var _processedimage = singletimearray.sort(function(a,b) {return b.id - a.id;});
                // setprocessedimage(_processedimage);

            };
            reader.readAsDataURL(element);
        }
    }
const movetoorderingontabclick = () => {
    if (processedimage.length < 5) {
        customAlertMessage('error', 'Minimum of 5 images required to generate the video');
        return false;
    }
    if (processedimage.length > 10) {
        customAlertMessage('error', 'We can process maximum of 10 images, please remove few image and click  on next.');
        return false;
    }
    var selectddl = document.getElementsByClassName('imageoption');
    var roomtypeselectedforall = true;
    for (let i = 0; i < selectddl.length; i++) {
        const element = selectddl[i];
        var refid = element.dataset.filerefid;
        var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
        if (selectedoption.length <= 0 || selectedoption[0].text == "-1") {
            roomtypeselectedforall = false;
            element.classList.add("requirederror");
        }
        // if (element.value == "-1") {
        //     roomtypeselectedforall = false;
        //     element.classList.add("requirederror");
        // }
    }
    if (roomtypeselectedforall == false) {
        customAlertMessage('error', 'Please select room type for all images.');
        return false;
    }
}
    const movetoordering = (e) => {
        if (processedimage.length < 5) {
            customAlertMessage('error', 'Minimum of 5 images required to generate the video');
            return false;
        }
        if (processedimage.length > 10) {
            customAlertMessage('error', 'We can process maximum of 10 images, please remove few image and click  on next.');
            return false;
        }
        var selectddl = document.getElementsByClassName('imageoption');
        var roomtypeselectedforall = true;
        for (let i = 0; i < selectddl.length; i++) {
            const element = selectddl[i];
            var refid = element.dataset.filerefid;
            var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
            if (selectedoption.length <= 0 || selectedoption[0].text == "-1") {
                roomtypeselectedforall = false;
                element.classList.add("requirederror");
            }
            // if (element.value == "-1") {
            //     roomtypeselectedforall = false;
            //     element.classList.add("requirederror");
            // }
        }
        if (roomtypeselectedforall == false) {
            customAlertMessage('error', 'Please select room type for all images.');
            return false;
        }
        setactivestep(3);
        setstepscompleted(2);
        var el = document.getElementsByClassName('draggablediv')[0];
        var sortable = new Sortable(el, {
            animation: 150,
            ghostClass: 'blue-background-class'
        });
    }
    const movetoadddetails = (e) => {
        document.getElementById("txtpropaddress").setAttribute("autocomplete", "Nope");
        setactivestep(4);
        setstepscompleted(3);
        saveimageorfering();
    }
    const saveimageorfering =()=>{
        var imagedragdiv = document.querySelectorAll('.draggablediv .image-drag');
        var propertyorderedlist = [];        
        for (let i = 0; i < imagedragdiv.length; i++) {
            const element = imagedragdiv[i];
            console.log(element.dataset.fileidentity);
            console.log(element.dataset.filerefid);
            var selem = {};
            selem.orderid = i;
            selem.id = element.dataset.fileidentity;
            selem.refid = element.dataset.filerefid;
            selem.image = element.firstElementChild.src;
            selem.binaryfile = element;
            propertyorderedlist.push(selem);
        }
        console.log([...propertyorderedlist])
        setorderedprocessedimage([...propertyorderedlist]);
    }
    const clearprofileimage = (e) => {
        setprocessedimageprofilepicture("");
        setuploadedfileprofilepicture("");
        document.getElementsByClassName('dropzoneprofilepic')[0].value = "";
    }
    const validatedetails = () => {
        if (document.getElementById('txtpropname').value == "") {
            customAlertMessage('error', 'Please enter property name');
            return;
        }
        if (document.getElementById('txtpropaddress').value == "") {
            customAlertMessage('error', 'Please enter property address');
            return;
        }
        if (document.getElementById('ddlBedrooms').value == "select") {
            customAlertMessage('error', 'Please select number of beds');
            return;
        }
        if (document.getElementById('ddlBathrooms').value == "select") {
            customAlertMessage('error', 'Please select number of baths');
            return;
        }
        if (document.getElementById('ddlFloors').value == "select") {
            customAlertMessage('error', 'Please select number of garage');
            return;
        }
        if (document.getElementById('txtproparea').value == "") {
            customAlertMessage('error', 'Please enter property area (Sqft)');
            return;
        }
        if (document.getElementById('txtpropprice').value == "") {
            customAlertMessage('error', 'Please enter property price');
            return;
        }

        if (document.getElementById('txtagentname').value == "") {
            customAlertMessage('error', 'Please enter agent name');
            return;
        }
        if (document.getElementById('txtagentemail').value == "") {
            customAlertMessage('error', 'Please enter agent email.');
            return;
        }
        if (validateEmail(document.getElementById('txtagentemail').value) == false) {
            customAlertMessage('error', 'Please enter valid email.');
            return;
        }
        if (document.getElementById('txtagentphone').value == "") {
            customAlertMessage('error', 'Please enter agent phone');
            return;
        }

        if (document.getElementById('txtopenhousedate').value == "" && eventtypeid == 3) {
            customAlertMessage('error', 'Please select date for open house.');
            return;
        }
        if (document.getElementById('txtopenhousetime').value == "" && eventtypeid == 3) {
            customAlertMessage('error', 'Please enter time for open house.');
            return;
        }
        var propertydesc_json = {};
        propertydesc_json.beds = document.getElementById('ddlBedrooms').value;
        propertydesc_json.bath = document.getElementById('ddlBathrooms').value;
        propertydesc_json.garage = document.getElementById('ddlFloors').value;
        propertydesc_json.area = document.getElementById('txtproparea').value;
        propertydesc_json.price = document.getElementById('txtpropprice').value;
        propertydesc_json.address = document.getElementById('txtpropaddress').value;
        //{"beds":"3","bath":"3","garage":"2","area":"1600","price":"$5,999,000","address":"610 South Barranca Avenue, Glendora, CA 91740"}
        var agentdetails_json = {};
        agentdetails_json.name = document.getElementById('txtagentname').value;
        agentdetails_json.phone = document.getElementById('txtagentphone').value;
        agentdetails_json.email = document.getElementById('txtagentemail').value;
        //{"name":"Ragunath Venkatesan","phone":"8792151103","email":"ragunath@planetre.us"}
        setpropertydescription(propertydesc_json);
        setagentdetails(agentdetails_json);
        setactivestep(5);
        setstepscompleted(4);
        var fileandinfo = [];
        // var selectddl = document.getElementsByClassName('imageoption');
        var reorderedimages = document.querySelectorAll('.draggablediv .image-drag');
        // var thirdstepimage = document.querySelectorAll('.processedimagediv .image-drag')
        // const bundl = require('n2d');
        for (let j = 0; j < reorderedimages.length; j++) {
            var sfileinfo = {};
            const draggableelement = reorderedimages[j];
            var refid = draggableelement.dataset.filerefid;
            var filebase64 = draggableelement.getElementsByTagName('img')[0].src;
            const numberstring = n2d.convert((j + 1)).trim();

            // selem.orderid = orderedprocessedimage.length + (i+1);
            // selem.id = timingidentity;
            // selem.refid = inputfiles[i].identity;
            // selem.image = e.target.result;
            // selem.binaryfile = element;
            // propertyorderedlist.push(selem);

             var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.identity == refid; });
             var filteredprocessedimage = processedimage.filter(function (entry) { return entry.refid == refid; });
            //var filtereduploadedfile = orderedprocessedimage.filter(function (entry) { return entry.refid == refid; });
            if (filtereduploadedfile.length > 0) {
                var filterbinaryfile = filtereduploadedfile[0].binaryfile;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    // sfileinfo.file =(filebase64);
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else if (filteredprocessedimage.length > 0) {
                var filterbinaryfile = dataURLtoFile(filteredprocessedimage[0].image);
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    // sfileinfo.file =(filebase64);
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else{
                customAlertMessage('error','uploaded file missing');
            }
        }
        console.log(fileandinfo);
        createvideo(fileandinfo, propertydesc_json, agentdetails_json);
    }

    const validatedetailsontabclick = () => {
        if (document.getElementById('txtpropname').value == "") {
            customAlertMessage('error', 'Please enter property name');
            return false;
        }
        if (document.getElementById('txtpropaddress').value == "") {
            customAlertMessage('error', 'Please enter property address');
            return false;
        }
        if (document.getElementById('ddlBedrooms').value == "select") {
            customAlertMessage('error', 'Please select number of beds');
            return false;
        }
        if (document.getElementById('ddlBathrooms').value == "select") {
            customAlertMessage('error', 'Please select number of baths');
            return false;
        }
        if (document.getElementById('ddlFloors').value == "select") {
            customAlertMessage('error', 'Please select number of garage');
            return false;
        }
        if (document.getElementById('txtproparea').value == "") {
            customAlertMessage('error', 'Please enter property area (Sqft)');
            return false;
        }
        if (document.getElementById('txtpropprice').value == "") {
            customAlertMessage('error', 'Please enter property price');
            return false;
        }

        if (document.getElementById('txtagentname').value == "") {
            customAlertMessage('error', 'Please enter agent name');
            return false;
        }
        if (document.getElementById('txtagentemail').value == "") {
            customAlertMessage('error', 'Please enter agent email.');
            return false;
        }
        if (validateEmail(document.getElementById('txtagentemail').value) == false) {
            customAlertMessage('error', 'Please enter valid email.');
            return false;
        }
        if (document.getElementById('txtagentphone').value == "") {
            customAlertMessage('error', 'Please enter agent phone');
            return false;
        }

        if (document.getElementById('txtopenhousedate').value == "" && eventtypeid == 3) {
            customAlertMessage('error', 'Please select date for open house.');
            return false;
        }
        if (document.getElementById('txtopenhousetime').value == "" && eventtypeid == 3) {
            customAlertMessage('error', 'Please enter time for open house.');
            return false;
        }
    }

    const firecreatevideo = ()=>{
        var propertydesc_json = {};
        propertydesc_json.beds = document.getElementById('ddlBedrooms').value;
        propertydesc_json.bath = document.getElementById('ddlBathrooms').value;
        propertydesc_json.garage = document.getElementById('ddlFloors').value;
        propertydesc_json.area = document.getElementById('txtproparea').value;
        propertydesc_json.price = document.getElementById('txtpropprice').value;
        propertydesc_json.address = document.getElementById('txtpropaddress').value;
        //{"beds":"3","bath":"3","garage":"2","area":"1600","price":"$5,999,000","address":"610 South Barranca Avenue, Glendora, CA 91740"}
        var agentdetails_json = {};
        agentdetails_json.name = document.getElementById('txtagentname').value;
        agentdetails_json.phone = document.getElementById('txtagentphone').value;
        agentdetails_json.email = document.getElementById('txtagentemail').value;
        //{"name":"Ragunath Venkatesan","phone":"8792151103","email":"ragunath@planetre.us"}
        setpropertydescription(propertydesc_json);
        setagentdetails(agentdetails_json);
        setactivestep(5);
        setstepscompleted(4);
        var fileandinfo = [];
        // var selectddl = document.getElementsByClassName('imageoption');
        var reorderedimages = document.querySelectorAll('.draggablediv .image-drag');
        // var thirdstepimage = document.querySelectorAll('.processedimagediv .image-drag')
        // const bundl = require('n2d');
        for (let j = 0; j < reorderedimages.length; j++) {
            var sfileinfo = {};
            const draggableelement = reorderedimages[j];
            var refid = draggableelement.dataset.filerefid;
            // var filebase64 = draggableelement.getElementsByTagName('img')[0].src;
            const numberstring = n2d.convert((j + 1)).trim();
            var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.identity == refid; });
            var filteredprocessedimage = processedimage.filter(function (entry) { return entry.identity == refid; });
            // var filtereduploadedfile = orderedprocessedimage.filter(function (entry) { return entry.refid == refid; });
            if (filtereduploadedfile.length > 0) {
                var filterbinaryfile = filtereduploadedfile[0].binaryfile;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    // sfileinfo.file =(filebase64);
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else if (filteredprocessedimage.length > 0) {
                var filterbinaryfile = filteredprocessedimage[0].binaryfile;
                var selectedoption = dropdownvalues.filter(function (entry) { return entry.refid == refid; });
                if (selectedoption.length > 0) {
                    var imagetype = selectedoption[0].text;
                    sfileinfo.intstring = numberstring;
                    sfileinfo.file = filterbinaryfile;
                    // sfileinfo.file =(filebase64);
                    sfileinfo.type = imagetype;
                    sfileinfo.name = filterbinaryfile.name
                    fileandinfo.push(sfileinfo);
                }
            }
            else{
                customAlertMessage('error','uploaded file missing');
            }
        }
        console.log(fileandinfo);
        createvideo(fileandinfo, propertydesc_json, agentdetails_json);
    }

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        if (filename == "") {
            if (mime.toLowerCase().indexOf('png')) {
                filename = uuidv4() + ".png";
            }
            else{
                filename = uuidv4() + ".jpg";
            }
        }
        return new File([u8arr], filename, { type: mime });
    }


    const createvideo = (fileinfoarray, propdescriptionarray, agentinfoarray) => {
        var mapjson = {}
        const axios = require('axios');
        const FormData = require('form-data');
        var date = document.getElementById('txtopenhousedate').value;
        var time = document.getElementById('txtopenhousetime').value;
        if (time == "") {
            time = "10 AM - 11 AM";
        }
        // const fs = require('fs');
        let data = new FormData();
        for (let i = 0; i < fileinfoarray.length; i++) {
            const farr = fileinfoarray[i];
            // data.append(farr.intstring, dataURLtoFile(farr.file,farr.name));
            data.append(farr.intstring, farr.file);
            mapjson[farr.intstring] = farr.type;
        }
        // data.append('two', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/bed.jpg'));
        // data.append('three', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/bath.jpg'));
        // data.append('four', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/kitchen.jpg'));
        // data.append('five', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/dining.jpg'));
        // data.append('six', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/porch.jpg'));
        // data.append('seven', fs.createReadStream('/C:/Users/ragu2/Downloads/Property images for NFT 2/Property images for NFT 2/4.jpg'));
        // data.append('map', '{"one":"living","two":"bed","three":"bath","four":"kitchen","five":"dining","six":"porch","seven":"patio"}');
        data.append('map', JSON.stringify(mapjson));
        data.append('agent', JSON.stringify(agentinfoarray));
        if (uploadedfileprofilepicture != "") {
            data.append('profile', uploadedfileprofilepicture);
        }
        // data.append('propertydesc', '{"beds":"3","bath":"3","garage":"2","area":"1600","price":"$5,999,000","address":"610 South Barranca Avenue, Glendora, CA 91740"}');
        data.append('propertydesc', JSON.stringify(propdescriptionarray));
        data.append('category', eventtypeid);
        data.append('template', templateid);
        data.append('opdate', date);
        data.append('optime', time);
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatflask.aelo.ai/indexhblurtest',
            //url: 'http://127.0.0.1:5000/indexhblurtest',
            data: data
        };
        compareFormData(data).then(result => {
            if (result == false) {
                //customAlertMessage("success","values changed new video will be created");
                setfinalvideo("");
                showoverlay();
                axios.request(config)
                    .then((response) => {
                        // console.log((response.data));
                        //output result
                        if (response.data != 'error') {
                            setfinalvideo(response.data);
                            // setpreviousvideodata(JSON.stringify(data));
                            setTimeout(() => {
                                try {
                                    // document.getElementById('outputvideodownload').load();
                                    document.getElementById('outputvideo').load();
                                    hideoverlay();
                                    storeFormData(data);
                                } catch (error) {
                                }
                            }, 2000);
                            hideoverlay();
                        }
                        else {
                            customAlertMessage('error', 'Something went wrong, please try again.')
                            hideoverlay();
                        }
                    })
                    .catch((error) => {
                        customAlertMessage('error', 'Something went wrong, please try again.')
                        console.log(error);
                        hideoverlay();
                    });
            }
            else{
                //customAlertMessage("success","No values changed for video creation");
            }
        });
    }
    const removerequiredfield = (e) => {
        var vallll = e.currentTarget.value;
        var element = e.currentTarget;
        var tempdropdownvalues = dropdownvalues;
        if (vallll != "-1") {
            element.classList.remove("requirederror");
        }
        else {
            element.classList.add("requirederror");
        }
        var refid = element.dataset.filerefid;
        var selectedoption = tempdropdownvalues.filter(function (entry) { return entry.refid == refid; });
        if (selectedoption.length > 0) {
            selectedoption[0].text = vallll;
        }
        else {
            var sitem = {};
            sitem.refid = refid;
            sitem.text = vallll;
            tempdropdownvalues.push(sitem);
        }
        setdropdownvalues(tempdropdownvalues);
    }

    const removefromlist = (e) => {
        var referenceid = e.currentTarget.dataset.filerefid;
        var filteredprocessimage = processedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setprocessedimage([...filteredprocessimage]);
        var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.refid !== referenceid; });
        setuploadedfile([...filtereduploadedfile]);
        var  filterorderedprocessedimage = orderedprocessedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setorderedprocessedimage([...filterorderedprocessedimage]);
        // const dt = new DataTransfer();
        // var input = $(".dropzone")[0];
        // for (let file of input.files)
        //     if (file !== filtereduploadedfile.binaryfile) 
        //         dt.items.add(file)
        showoverlaytwo();
        setTimeout(() => {
            loaddropdownfromstate();
        }, 2000);
    }

    const showdownload = () => {
        setactivestep(6);
        setstepscompleted(5);
        document.getElementById('outputvideo').pause();
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const showoverlay = () => {
        document.getElementById("overlay").style.display = "block";
        $("body").addClass("loader");
    };
    const hideoverlay = () => {
        document.getElementById("overlay").style.display = "none";
        $("body").removeClass("loader");
    };

    const showoverlayone = () => {
        document.getElementById('overlayone').style.display = "block";
        $('body').addClass("loader");
    }
    const hideoverlayone = () => {
        document.getElementById('overlayone').style.display = "none";
        $('body').removeClass("loader");
    }

    const showoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "block";
        $("body").addClass("loader");
    };
    const hideoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "none";
        $("body").removeClass("loader");
    };

    const loadermessagesrandom = () =>
    {
        var message =[];
        var ddd = Array.from({length: 40}, () => Math.floor(Math.random() * 13));
        var duplicateremoved = ddd.filter((item,index) => ddd.indexOf(item) === index).slice(0,6);
        for (let i = 0; i < 6; i++) {              
            const element = loadermessages[duplicateremoved[i]] == null ? loadermessages[0] :loadermessages[duplicateremoved[i]];
            message.push(element);                
        }
        message.push(finalmessagearr[Math.floor(Math.random() * 6)]);
        this.setState({loadermessages : message});
    }

    function addCommaTo(e) {
        let inputElement = e.currentTarget;
        let inputElementvalue = inputElement.value.replace(/\D/g, '')
        let caretPos = inputElement.selectionStart - inputElementvalue.length;
        // remove all the commas
        let numberValue = inputElementvalue.replace(/,/g, '');
        // add the commas back in the right places
        let formatted = numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        inputElement.value = formatted;
        inputElement.selectionEnd = caretPos + formatted.length;
        // var txtArea = e.currentTarget;
        // txtArea.value=txtArea.value.replace(/[^0-9\,]/, '');
        // var num = getNumber(txtArea.value);
        // if(num==0){
        //     txtArea.value="";
        // }else{
        //     txtArea.value = num.toLocaleString();
        // }
    }
    function getNumber(_str) {
        var arr = _str.split('');
        var out = new Array();
        for (var cnt = 0; cnt < arr.length; cnt++) {
            if (isNaN(arr[cnt]) == false) {
                out.push(arr[cnt]);
            }
        }
        return Number(out.join(''));
    }

    function fileToDataURL(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    // Store FormData
    async function storeFormData(formData) {
        let obj = {};
        for (let [key, value] of formData.entries()) {
            // if (value instanceof File) {
            //     value = await fileToDataURL(value);
            // }
            obj[key] = value;
        }
        setvideocreatedformdata(obj);
        // localStorage.setItem('formData', JSON.stringify(obj));
    }

    // Compare FormData
    async function compareFormData(formData) {
        let obj = {};
        for (let [key, value] of formData.entries()) {
            // if (value instanceof File) {
            //     value = await fileToDataURL(value);
            // }
            obj[key] = value;
        }

        let storedData = videocreatedformdata;// JSON.parse(localStorage.getItem('formData'));
        if (storedData != null) {
            for (let key in obj) {
                if (obj[key] !== storedData[key]) {
                    return false;
                }
            }

            for (let key in storedData) {
                if (obj[key] !== storedData[key]) {
                    return false;
                }
            }
        }
        else {
            return false;
        }
        return true;
    }

    const historygoback = () => {
        window.history.back();
    };

    const gotostep = (e) => {
        var stepid = parseInt(e.currentTarget.dataset.stepbackid);
        if (activestep == 3) {
            saveimageorfering();
        }
        if (stepid > activestep) {
            if (activestep == 2 && stepid !=1 && stepid != 2) {
                var validationfailed = movetoorderingontabclick();
                if (validationfailed == false) {
                    return;
                }
            }
            if (activestep == 4) {
                var cannavigate = validatedetailsontabclick();
                if(cannavigate == false){
                    return;
                }
            }
        }
        
        switch (stepid) {
            case 1:
                setactivestep(1);
                break;
            case 2:
                if (stepscompleted >= 1) {
                    setactivestep(2);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 2000);
                }
                break;
            case 3:
                if (stepscompleted >= 2) {
                    setactivestep(3);
                    // loaddropdownfromstate();
                }
                break;
            case 4:
                if (stepscompleted >= 3) {
                    setactivestep(4);
                    document.getElementById("txtpropaddress").setAttribute("autocomplete", "Nope");
                }
                break;
            case 5:
                if (stepscompleted >= 4) {
                    setactivestep(5);
                }
                break;
            case 6:
                if (stepscompleted >= 5) {
                    setactivestep(6);
                }
                break;
            case 7:
                if (stepscompleted >= 6) {
                    setactivestep(7);
                }
                break;
        }
    }


    // render() {

    return (
        <div className="main_content_iner overly_inner">
            <div className="container-fluid p-0 ">
                <div className="row">

                    <div className="col-12">
                        <div className="title-card1">
                            <span className="float-start" onClick={historygoback}>
                                <h4>
                                    <span
                                        style={{ cursor: "pointer" }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        Property Video Generator
                                    </span>
                                </h4>
                            </span>
                        </div>
                        <input type="hidden" id="hfCurrentTab" value="1"></input>
                    </div>


                    <div className="col-12">
                        <div className="row">


                            <div className="col-12">
                                <div className="stepper-count-wrap">
                                    <div className="pd-stepper-count">
                                        <div className="step-count-div" data-tabid="1" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div1" className="step-count">
                                                    <span id="countno1">1</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid1" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Select Template</div>
                                        </div>
                                        <div className="step-count-div" data-tabid="2" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div2" className={stepscompleted >= 1 ? "step-count" : "step-count inactive"}>
                                                    <span id="countno2">2</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid2" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Upload Images</div>
                                        </div>
                                        <div className="step-count-div" data-tabid="3" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div3" className={stepscompleted >= 2 ? "step-count" : "step-count inactive"}>
                                                    <span id="countno3">3</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid3" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Ordering Images</div>
                                        </div>
                                        <div className="step-count-div" data-tabid="4" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div4" className={stepscompleted >= 3 ? "step-count" : "step-count inactive"}>
                                                    <span id="countno4">4</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid4" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Add Details</div>
                                        </div>
                                        <div className="step-count-div" data-tabid="5" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div5" className={stepscompleted >= 4 ? "step-count" : "step-count inactive"}>
                                                    <span id="countno5">5</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid5" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Preview Video</div>
                                        </div>
                                        {/* <div className="step-count-div" data-tabid="6" onClick={onTabClick}>
                                            <div className="step-wrap">
                                                <div id="div6" className={stepscompleted >= 5 ? "step-count" : "step-count inactive"}>
                                                    <span id="countno6">6</span>
                                                    <FontAwesomeIcon icon={faCheck} id="checkmarkvalid6" style={{ display: "none" }} />
                                                </div>
                                            </div>
                                            <div className="step-title">Download Video</div>
                                        </div> */}
                                        <div className="stepper-bar"><div className="step-bar"></div></div>
                                    </div>
                                </div>
                            </div>


                            {/* Tab content 1 */}

                            <div className="col-12" id="selecttemplate" style={{ display: (activestep == 1 ? 'block' : 'none') }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start">
                                                <h4>
                                                    <span>
                                                        Select Video Template
                                                    </span>
                                                </h4>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <p>Please choose event type from the dropdown, then select preferred template for your video.</p>
                                    </div>
                                    <div className="col-4">
                                        <label className="prop-lab-title"><b>Choose Event Type</b><span style={{ color: "red" }}><b>*</b></span></label>
                                        <select id="ddleventtype" className="commonselectNew" onChange={eventtypechanged}>
                                            <option value="1">Just Listed</option>
                                            <option value="2">Just Sold</option>
                                            <option value="3">Open House</option>
                                            {/*<option value="4">Client testimonial</option> */}
                                        </select>
                                    </div>
                                </div>
                                {eventtypeid == 1 ?
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/fa9ccc45c8204e6187a29f7a5a0b22c2/946f0db280174474acccd6e4f8b5e434.mp4" type="video/mp4" />
                                                </video>
                                                <h4>Vibrant Orange</h4>
                                                <p>
                                                    Create a bold and attention-grabbing visual aesthetic with this video template.
                                                </p>
                                                <a data-templateid="1" data-templatename="Vibrant Orange" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/9d4bc5d58e954cde8a1b5564c7e4de3f/53eea51bb70d43729df917508b6313e4.mp4" type="video/mp4"></source>
                                                </video>
                                                <h4>Purple</h4>
                                                <p>
                                                    The perfect way to show off your home's beauty on social media with this video template.
                                                </p>
                                                <a data-templateid="2" data-templatename="Purple" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/b9af93ec2ae04658856bed38328f00ab/b319ef15053c4b52ac1b5a40eca44179.mp4" type="video/mp4"></source>
                                                </video>
                                                <h4>Classic Grey</h4>
                                                <p>
                                                    Craft a striking and eye-catching visual design using this video template.
                                                </p>
                                                <a data-templateid="3" data-templatename="Classic Grey" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    : null
                                }
                                {eventtypeid == 2 ?
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/389df15fab3d47528122b2b3dd32d8f8/ed20e5fd495744d79f807196cc9aa12b.mp4" type="video/mp4" />
                                                </video>
                                                <h4>Vibrant Orange</h4>
                                                <p>
                                                    Create a bold and attention-grabbing visual aesthetic with this video template.
                                                </p>
                                                <a data-templateid="1" data-templatename="Vibrant Orange" onClick={usetemplate} style={{ cursor: 'pointer' }} >Use this template</a>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/1b0f565e0104408eb9655898043654a2/46ec2a1bc73d4c7f8aa08a36dd41a3f4.mp4" type="video/mp4"></source>
                                                </video>
                                                <h4>Purple</h4>
                                                <p>
                                                    The perfect way to show off your home's beauty on social media with this video template.
                                                </p>
                                                <a data-templateid="2" data-templatename="Purple" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="video-box">
                                                <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                    <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/63ee0d94c9864bdba6d002624a6fc46e/20e2858b2fdb48a4821818a1f4fb0339.mp4" type="video/mp4"></source>
                                                </video>
                                                <h4>Classic Grey</h4>
                                                <p>
                                                    Craft a striking and eye-catching visual design using this video template.
                                                </p>
                                                <a data-templateid="3" data-templatename="Classic Grey" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {
                                    eventtypeid == 3 ?
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="video-box">
                                                    <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                        <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/e0538963ddd64c61be1719f2bf01a9cf/095b25d8bd4f4eaf96326e8ffcd8a0aa.mp4" type="video/mp4" />
                                                    </video>
                                                    <h4>Vibrant Orange</h4>
                                                    <p>
                                                        Create a bold and attention-grabbing visual aesthetic with this video template.
                                                    </p>
                                                    <a data-templateid="1" data-templatename="Vibrant Orange" onClick={usetemplate} style={{ cursor: 'pointer' }} >Use this template</a>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="video-box">
                                                    <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                        <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/409139213b8b4cdcbd80975785362c4e/4537c7b497f140bf81191f68387a72d7.mp4" type="video/mp4"></source>
                                                    </video>
                                                    <h4>Purple</h4>
                                                    <p>
                                                        The perfect way to show off your home's beauty on social media with this video template.
                                                    </p>
                                                    <a data-templateid="2" data-templatename="Purple" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="video-box">
                                                    <video className="template-video" onPlay={pauseAll} controls controlsList="nodownload" >
                                                        <source src="https://chocochipsvideo.s3.us-west-1.amazonaws.com/1c6a01876071465494d4dab9d77f27b7/b7f3ffaa8dd649f1812f2298c2be6e28.mp4" type="video/mp4"></source>
                                                    </video>
                                                    <h4>Classic Grey</h4>
                                                    <p>
                                                        Craft a striking and eye-catching visual design using this video template.
                                                    </p>
                                                    <a data-templateid="3" data-templatename="Classic Grey" onClick={usetemplate} style={{ cursor: 'pointer' }}>Use this template</a>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }


                            </div>

                            {/* Tab content 2 */}

                            <div className="col-12" id="uploadimages" style={{ display: (activestep == 2 ? 'block' : 'none') }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start" data-stepbackid={1} onClick={gotostep}>
                                                <h4>
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                        Upload Images
                                                    </span>
                                                </h4>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="box-greynew">
                                            <div className="row">
                                                <div className="col-4 upload-center">
                                                    <label>Event</label>
                                                    <h4>{eventtypename}</h4>
                                                </div>
                                                <div className="col-4 upload-center">
                                                    <div className="border-left">
                                                        <label>Template</label>
                                                        <h4>{templatename}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-4 upload-center">
                                                    <div className="border-left">
                                                        <label>Required Images</label>
                                                        <h4>5 to 10</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropzone-wrapper" style={{ width: "100%", marginBottom: "40px", marginTop: "5px" }} onDragEnter={dropzonedragenter} onDragOver={dropzonedragover} onDragLeave={dropzonedragleave} onDrop={dropzonefiledrop} >
                                        <div className="dropzone-desc">
                                            <h4>Upload between 5 to 10 images.</h4>
                                            <h4>Drag and Drop</h4>
                                            <p><b>or Click to upload</b></p>
                                            <FontAwesomeIcon icon={faCloudArrowUp} />
                                            <p>
                                                Supported Files: PNG, JPG, HEIC, HEIF.
                                            </p>
                                        </div>
                                        <input type="file" name="img_logo" className="dropzone" onChange={dropzonechange} accept="image/png, image/jpeg, image/jpg, image/heif, image/heic" multiple></input>
                                    </div>

                                    <div className="col-12 processedimagediv" style={{ position: 'relative' }}>
                                        <div id="overlaytwo">
                                            <div id="loading" style={{ top: "30%" }}>
                                                <h2 id="loaderheading">Generating...</h2>
                                                <img src={loader} />
                                            </div>
                                        </div>
                                        {processedimage.length > 0 ? processedimage.map((d) => (
                                            <div className="image-drag" key={uuidv4()} style={{ position: "relative" }}>
                                                <img src={d.image} alt="Property image" />
                                                <FontAwesomeIcon icon={faRemove} className="removeimage" data-filerefid={d.refid} onClick={removefromlist}></FontAwesomeIcon>
                                                <center>
                                                    <select className="commonselectNew imageoption" onChange={removerequiredfield} data-filerefid={d.refid}>
                                                        <option value="-1">Select room type*</option>
                                                        <option value="backyard">Backyard</option>
                                                        <option value="balcony">Balcony</option>
                                                        <option value="bathroom">Bathroom</option>
                                                        <option value="bedroom">Bedroom</option>
                                                        <option value="dining_room">Dining room</option>
                                                        <option value="drone_view">Drone View</option>
                                                        <option value="exterior_view">Exterior View</option>
                                                        <option value="family_room">Family room</option>
                                                        <option value="garden">Garden</option>
                                                        <option value="kidsroom">Kidsroom</option>
                                                        <option value="kitchen">Kitchen</option>
                                                        <option value="living_room">Living room</option>
                                                        <option value="nursery">Nursery</option>
                                                        <option value="office_study">Office/Study</option>
                                                        <option value="patio">Patio</option>
                                                        <option value="porch">Porch</option>
                                                        <option value="studio">Studio</option>
                                                        <option value="swimming_pool">Swimming pool</option>
                                                    </select>
                                                </center>
                                            </div>
                                        )) : null}
                                    </div>

                                    {/* <div className="col-12 upload-part">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Upload between 5 to 10 images.</h4>
                                                    <p>(allowed file types: .jpg, .jpeg, .png, .gif)</p>
                                                </div>

                                                <div className="col-12">
                                                    <input type="file" id="additionalfiles" name="files[]" multiple onChange={additionalfileschange} />
                                                </div>

                                            </div>
                                        </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="float-end">
                                            <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={movetoordering}>Next</a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Tab content 3*/}

                            <div className="col-12" id="orderingimages" style={{ display: (activestep == 3 ? 'block' : 'none') }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start" data-stepbackid={2} onClick={gotostep}>
                                                <h4>
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                        Set Image Order
                                                    </span>
                                                </h4>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="box-greynew">
                                            <div className="row">
                                                <div className="col-4 upload-center">
                                                    <label>Event</label>
                                                    <h4>{eventtypename}</h4>
                                                </div>
                                                <div className="col-4 upload-center">
                                                    <div className="border-left">
                                                        <label>Template</label>
                                                        <h4>{templatename}</h4>
                                                    </div>
                                                </div>
                                                <div className="col-4 upload-center">
                                                    <div className="border-left">
                                                        <label>Total images</label>
                                                        <h4>{processedimage.length}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <h4>Move images to set the image order</h4>
                                        <div className="draggablediv">
                                            {orderedprocessedimage.length > 0 ? orderedprocessedimage.map((d) => (
                                                <div key={uuidv4()} className="image-drag" data-fileidentity={d.id} data-filerefid={d.refid}>
                                                    <img src={d.image} alt="Property image" />
                                                </div>
                                            )) : processedimage.map((d) => (
                                                <div key={uuidv4()} className="image-drag" data-fileidentity={d.id} data-filerefid={d.refid}>
                                                    <img src={d.image} alt="Property image" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="float-end">
                                            <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={movetoadddetails}>Next</a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Tab content 4 */}

                            <div className="col-12" id="adddetails" style={{ display: (activestep == 4 ? 'block' : 'none') }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start" data-stepbackid={3} onClick={gotostep}>
                                                <h4>
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                        Add Details
                                                    </span>
                                                </h4>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="prop-lab-title" style={{ marginBottom: "20px" }}>Add property details</label>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-6">
                                                <label className="prop-lab-title1">Property Name<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtpropname" type="text" className="common-input" />
                                            </div>
                                            <div className="col-6">
                                                <input id="hfLocationId" value={locationId} type="hidden"></input>
                                                <label className="prop-lab-title1">Property address <span style={{ color: "red" }}><b>*</b></span></label>                                                
                                                <input id="txtpropaddress" type="text" className="common-input" autoComplete="Nope"
                                                    ref={autoCompleteRef}
                                                    onChange={event => setQuery(event.target.value)}
                                                    placeholder="Enter your address"
                                                    value={query}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-2">
                                                <label className="prop-lab-title1">Number Of Bedrooms<span style={{ color: "red" }}><b>*</b></span></label>
                                                {/* <Dropdown id="ddlBedrooms" options={optionsBedrroms} placeholder="Select" /> */}
                                                <select id="ddlBedrooms" className="commonselectNew">
                                                    <option value="select">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <label className="prop-lab-title1">Number Of Bathrooms<span style={{ color: "red" }}><b>*</b></span></label>
                                                {/* <Dropdown id="ddlBathrooms" options={optionsBathroms} placeholder="Select" /> */}
                                                <select id="ddlBathrooms" className="commonselectNew">
                                                    <option value="select">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="1.5">1.5</option>
                                                    <option value="2">2</option>
                                                    <option value="2.5">2.5</option>
                                                    <option value="3">3</option>
                                                    <option value="3.5">3.5</option>
                                                    <option value="4">4</option>
                                                    <option value="4.5">4.5</option>
                                                    <option value="5">5</option>
                                                    <option value="5.5">5.5</option>
                                                    <option value="6">6</option>
                                                    <option value="6.5">6.5</option>
                                                    <option value="7">7</option>
                                                    <option value="7.5">7.5</option>
                                                    <option value="8">8</option>
                                                    <option value="8.5">8.5</option>
                                                    <option value="9">9</option>
                                                    <option value="9.5">9.5</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <label className="prop-lab-title1">Number Of Garages<span style={{ color: "red" }}><b>*</b></span></label>
                                                {/* <Dropdown id="ddlFloors" options={optionsFloors} placeholder="Select" /> */}
                                                <select id="ddlFloors" className="commonselectNew">
                                                    <option value="select">Select</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <label className="prop-lab-title1">Area(sqft)<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtproparea" type="text" className="common-input" onKeyUp={addCommaTo} />
                                            </div>
                                            <div className="col-3">
                                                <label className="prop-lab-title1">Price* (USD)<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtpropprice" type="text" className="common-input" onKeyUp={addCommaTo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-30">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="prop-lab-title" style={{ marginBottom: "20px" }}>Add personal details</label>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-4">
                                                <label className="prop-lab-title1">Name<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtagentname" type="text" className="common-input" />
                                            </div>
                                            <div className="col-4">
                                                <label className="prop-lab-title1">Email<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtagentemail" type="email" className="common-input" />
                                            </div>
                                            <div className="col-4">
                                                <label className="prop-lab-title1">Phone<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtagentphone" type="text" className="common-input" />
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: (eventtypeid == 3 ? 'flex' : 'none') }}>
                                            <div className="col-3">
                                                <label className="prop-lab-title" style={{ marginBottom: "20px" }}>Open House Details</label>
                                            </div>
                                        </div>
                                        <div className="row mb-30" style={{ display: (eventtypeid == 3 ? 'flex' : 'none') }}>
                                            <div className="col-4">
                                                <label className="prop-lab-title1" style={{marginBottom:'13px'}}>Date<span style={{ color: "red" }}><b>*</b></span></label>
                                                <DatePicker id="txtopenhousedate" className="form-control" dateFormat="MM/dd/yyyy" selected={startDate} showIcon isClearable onChange={(date) => setStartDate(date)} /> 
                                                {/* <input id="txtopenhousedate" type="date" className="common-input form-control" data-date-format="MM-DD-YYYY" /> */}
                                            </div>
                                            <div className="col-4">
                                                <label className="prop-lab-title1">Time<span style={{ color: "red" }}><b>*</b></span></label>
                                                <input id="txtopenhousetime" type="text" className="common-input" maxLength={13} placeholder="10 AM - 11 AM" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ position: 'relative' }}>
                                    <div className="col-12">
                                        <label className="prop-lab-title" style={{ marginBottom: "20px" }}>Add Profile Picture</label>
                                    </div>
                                    <div id="overlayone">
                                        <div id="loading" style={{ top: "34%" }}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div>
                                    <div className="col-6 upload-part">
                                        <div className="dropzone-wrapperprofilepic" style={{ width: "100%", marginBottom: "40px", marginTop: "5px" }}>
                                            <div className="dropzone-descprofilepic">
                                                <h4>Upload Profile Picture</h4>
                                                <h4>Drag and Drop</h4>
                                                <p><b>or Click to upload</b></p>
                                                <FontAwesomeIcon icon={faCloudArrowUp} />
                                                <p>
                                                    Supported Files: PNG, JPG, HEIC, HEIF.
                                                </p>
                                            </div>
                                            <input type="file" name="img_logo" className="dropzoneprofilepic" accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"></input>
                                        </div>
                                    </div>
                                    <div className="col-6 uploaded-image" style={{ display: (processedimageprofilepicture == "" ? 'none' : 'block') }}>
                                    <div className="preview-zone">
                      <div className="box box-solid">
                        <div className="grey-box">
                          <div className="row">
                            <div className="col-12 top-box">
                                    <div className="row">
                                        <div className="col-12">
                                        <span className="float-start">
                                            <h4 className="input-head">
                                            <FontAwesomeIcon icon={faImage} />
                                            Uploaded Profile Image
                                            </h4>
                                        </span>
                                        <span
                                            className="float-end"
                                            tooltip="Remove"
                                            flow="left"
                                        >
                                            <a href="javascript:;" className="btn btn-danger btn-xs remove-preview" onClick={clearprofileimage}>
                                            <FontAwesomeIcon icon={faTimes} />
                                            </a>
                                        </span>
                                        </div>
                                    </div>
                                        {/* <label className="prop-lab-title" style={{ marginBottom: "20px", display: "block" }}>Uploaded Profile Picture</label> */}
                                        <div className="row">
                                        <div className="col-12 cropdiv">
                                            <div className="crop-container">
                                                <Cropper
                                                    image={processedimageprofilepicture}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={aspect}
                                                    cropShape="rect"
                                                    cropSize={cropSize}
                                                    onCropChange={onCropChange}
                                                    onCropComplete={onCropComplete}
                                                    onZoomChange={onZoomChange} />
                                            </div>    
                                            
                                        </div>
                                        </div>
                                        {/* <img src={processedimageprofilepicture} style={{ maxWidth: "360px", display: "block" }} /> */}
                                        
</div>
</div>
</div>
</div>
</div>
<div className="row">
    <div className="col-12">
    <input type={'hidden'} id="croppedimagehdn"></input>
    {/* <a href='javascript:;' tabIndex={-1} onClick={cropimage} className="common-save">Save Image</a> */}
                                        {/* <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={clearprofileimage}>Clear</a> */}
    </div>
</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="float-end">
                                            <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={validatedetails}>Next</a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {/* Tab content 5 */}

                            <div className="col-12" id="previewvideo" style={{ position: 'relative', display: (activestep == 5 ? 'block' : 'none') }}>
                                {/* <div id="overlay">
                                        <div id="loading" style={{top:"30%"}}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div> */}
                                <div id='overlay' className='newloader'>
                                    <div id="loading" style={{top:"0"}}>
                                        <div className="rotating-text-wrapper-new">
                                            <center><img src={loader} /></center>
                                            <div class="rotating-text-wrapper-newmesg" id="randomtext">
                                                <h2>Lights, camera, action! Your video is in the making. Sit tight!</h2>
                                                <h2>The video magic is happening behind the scenes. It'll be worth the wait!</h2>
                                                <h2>Your video is like a blockbuster in the making. Grab some popcorn and get ready!</h2>
                                                <h2>Please hold onto your excitement! Your video is just a few frames away.</h2>
                                                <h2>Lights are flashing, cameras are rolling, and your video is on its way!</h2>
                                                <h2>Your video is being brewed. It'll be served shortly!</h2>
                                                <h2>We're adding the final touches to your video masterpiece.</h2>
                                                {/*{this.state.loadermessages.length  > 0 ? this.state.loadermessages.map((d)=>(
                                                        <h2>{d}</h2>
                                                    )
                                                    ) :
                                                    <>
                                                    </>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start" data-stepbackid={4} onClick={gotostep}>
                                                <h4>
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                        Preview Video
                                                    </span>
                                                </h4>
                                                {/* <p>Play to preview the video and share it for free. To download the video, please choose PRO plan and make the payment.</p> */}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-12" style={{ display: (finalvideo == "" ? 'none' : 'block') }}>
                                        <center>
                                            <video className="template-video" controls controlsList="nodownload" style={{ maxWidth: "600px" }} id="outputvideo">
                                                <source src={finalvideo} type="video/mp4" />
                                            </video>
                                        </center>
                                    </div>
                                    <div className="col-12">
                                        <center><a href={finalvideo} download={true} style={{ margin: "15px 0 25px" }} className="common-save">Download Video</a></center>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {/* <span className="float-end" style={{ display: (finalvideo == "" ? 'none' : 'block') }}>
                                            <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={showdownload}>Next</a>
                                        </span> */}
                                    </div>
                                </div>
                            </div>


                            {/* Tab content 6 */}

                            <div className="col-12" id="downloadvideo" style={{ display: (activestep == 6 ? 'block' : 'none') }}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="title-card1">
                                            <span className="float-start" data-stepbackid={5} onClick={gotostep}>
                                                <h4>
                                                    <span
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                        Download Video
                                                    </span>
                                                </h4>
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div className="col-12">
                                            <center>
                                                <video className="template-video" controls style={{maxWidth:"600px"}}  id="outputvideodownload">
                                                        <source src={finalvideo} type="video/mp4" />
                                                </video>
                                            </center>
                                        </div> */}
                                    <div className="col-12">
                                        <center><a href={finalvideo} download={true} style={{ margin: "15px 0 25px" }} className="common-save">Download Video</a></center>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
// }
export default Propertyvideo;
