import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import Cookies from 'js-cookie'
import {UserID,Companyid,CompanyName,Name} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import UsersLogNew from './UsersLogNew';

class UsersLog extends React.Component {

    componentDidMount(){
        //this.toggleUsersCreation(false);
    //this.loadUsersData();
    }
    historygoback = () => {
        window.history.back();
    };

    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: icon, //'success',
            title: message, // 'Signed in successfully'
        });
    };


render(){
    return (
        <div className="main_content_iner overly_inner dashboard-page">
            <div className="container-fluid p-0 ">

                <div className="row">
                    <div className="col-12">
                        <div className="title-card1">
                            <span className="float-start">
                            <h4>
                            <span style={{ cursor: "pointer" }} onClick={this.historygoback}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                Users Log
                            </span>
                            </h4>
                            </span>
                        </div>                  
                    </div>
                    <div className="col-12">
                        <br></br>
                    </div>
                </div>

                <UsersLogNew></UsersLogNew>

            </div>
        </div>        
    )
}

};
export default UsersLog;