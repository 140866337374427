import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import React, { useRef,useImperativeHandle, useEffect, useMemo, useState } from "react";

const Roomtypemask = (props) => {
  const selectInputRef = useRef();
  const interioroptions = [
    { value: "-1", label: "Select" },
    { value: "balcony", label:"Balcony"},
    { value: "bar", label:"Bar"},    
    { value: "bathroom", label:"Bathroom/Ensuite"},
    { value: "bedroom", label:"Bedroom"},
    { value: "dining", label:"Dining"},
    { value: "foyer", label:"Foyer"},
    { value: "gameroom", label:"Game Room/Rumpus Room"},
    { value: "hobbyroom", label:"Hobby Room/Craft Room"},
    { value: "kidsroom", label:"Kids Room"},
    { value: "kitchen", label:"Kitchen"},
    { value: "laundry", label:"Laundry"},
    { value: "livingroom", label:"Living Room/Family Room/Lounge"},
    { value: "mediaroom", label:"Media Room"},
    { value: "nursery", label:"Nursery"},
    { value: "pantry", label:"Pantry"},
    { value: "singleroom", label:"Single Room Studio/Unit"},
    { value: "study", label:"Study"},
    { value: "sunroom", label:"Sunroom"}
  ];
  const exterioroptions = [
    //{ value: "-1", label: "Select" },
    { value: "backyard", label:"Backyard"},
    { value: "deck_patio", label:"Deck/Patio"},
    { value: "garden", label:"Garden/Landscaping"},
    { value: "outdoor_living", label:"Outdoor Living"},
    { value: "poolside", label:"Poolside"},
   
   
  ];

  const groupedOptions = [
    {
      label: "Interior",
      options: interioroptions
    },
    {
      label: "Exterior",
      options: exterioroptions
    }
  ];

  const [dataprop, setDataprop] = useState(interioroptions[0])
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  useEffect(()=>{
    console.log(props.selval);
    // var filterarr = groupedOptions[0].options.filter( element => element.label == props.selval);
    // if (filterarr.length <= 0) {
    //   filterarr = groupedOptions[1].options.filter( element => element.label == props.selval);
    // }
    //debugger;
    // const filteredarr = groupedOptions.flatMap((multitype) =>
    //       multitype.options.filter(element => element.label == props.selval));
    setDataprop(groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == props.selval))[0]);
  },[props.selval])
const handlechange =(e)=>{
var selectedvalue = e.value;
// var filteredarr = groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == selectedvalue));
setDataprop(groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == selectedvalue))[0]);

}
  // useMemo(() => {
  //   console.log(props.selval);
  // },[props.selval]);
  return (
    // <Select options={options} styles={customStyles} />
    <>
      <a href="javascript:;" id="roomtypeclear" onClick={onClear} style={{display:'none'}}>Clear</a>
    <Select ref={selectInputRef} value={dataprop} onChange={handlechange} defaultValue={dataprop} placeholder="Select" id={props.identity} options={groupedOptions} maxMenuHeight={300}/>
    </>
  );
};

export default Roomtypemask;