import React from "react";
import { initStripo } from "../email/stripo.helpers"


export default function Stripo(props) {
    const { html, css, pluginId, secretKey } = props;
    React.useEffect(() => {
        initStripo({ html, css, pluginId, secretKey });
    }, [html, css, pluginId, secretKey]);
    return (React.createElement("div", { className: "stripo" },
        React.createElement("div", { className: "stripo__header" },
            React.createElement("button", { id: "codeEditor" }, "code")),
        React.createElement("div", { className: "stripo__content" },
            React.createElement("div", { id: "stripoSettingsContainer" }, "Loading..."),
            React.createElement("div", { id: "stripoPreviewContainer" }, "Loading..."))));
}