import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name,Role} from "../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
import CsvDownloader from 'react-csv-downloader';
import { faArrowDown, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faMailBulk, faMessage, faRemove } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import ContentLoader, { List } from "react-content-loader";
// import ExternalInfo from "components/ExternalInfo";

const MyListLoader = () => <List speed={1} backgroundColor="#808080" foregroundColor="#ecebeb" />;

const Sherlockunauthorizedcombined = ({onClick}) => {
    const [tabledata, settabledata] = useState([]);
    const [tabledatacsv, settabledatacsv] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [gridlistcount,setgridlistcount] = useState(-1);

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "UNAUTHORIZED DOMAINS", field: "domainname", sortable: true },
        { name: "IMAGE COUNT", field: "domaincount", sortable: true },
        { name: "Discovered On", field: "lastupdated", sortable: true }
    ];

    const columns = [
        {
          id: 'domainname',
          displayName: 'UNAUTHORIZED DOMAINS',
        },
        {
          id: 'domaincount',
          displayName: 'IMAGE COUNT',
        },
        {
            id: 'lastupdated',
            displayName: 'Discovered On',
          }
      ];
      
      useEffect(() => {
       getData();
    }, []); // griddata is added as a dependency
    const getData = () => {
        showLoader();
        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            // url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/getsavedtemplate?userid='+ UserID + '&companyid='+Companyid+'&userrole='+Role
            url:`${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/getunauthorizedlist?companyid=&mlsname='
          };
    
        axios(config)
            .then(function (resp) {                    
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        settabledata(JSON.parse(response.Data));
                        const modifiedJsonArray = JSON.parse(response.Data).map(obj => {
                            return {
                                ...obj,
                                lastupdated: moment.utc(obj.lastupdated).local().format('MM-DD-YYYY h:mm A')
                            };
                        });
                        settabledatacsv(modifiedJsonArray);
                        // settabledatacsv(JSON.parse(response.Data));
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const tableData = useMemo(() => {
        let computeddata = tabledata;

        if (search) {

            computeddata = computeddata.filter(
                tdata =>
                tdata.domainname.toLowerCase().startsWith(search.toLowerCase())
            );
        }

        setTotalItems(computeddata.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            if (sorting.field == 'domaincount') {
                if (reversed == -1) {
                    computeddata = computeddata.sort((a, b) => b[sorting.field] - a[sorting.field]);
                }
                else{
                    computeddata = computeddata.sort((a, b) => a[sorting.field] - b[sorting.field])
                }
            }
            else{
                computeddata = computeddata.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
            
        }

        //Current Page slice
        return computeddata.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tabledata, currentPage, search, sorting]);

    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      const comingsoon =()=>{
        customAlertMessage('info','We are working on it, Coming Soon.');
      }

      const triggeremailpopup=()=>{
        document.getElementById('btnshareemail').dataset.emailtype = "2";
        document.getElementById('btnshareemail').dataset.subject = "Sherlock Unauthorized Domain CSV Report";
        document.getElementById('exampleModalLabel').innerText = "Email Unauthorized Domains";
        document.getElementById('btnshareemail').dataset.emaildomain = "";
      }
    return (
        <>
            {/* <Header title="Building a data table in react" /> */}
            {/* <ExternalInfo page="datatable" /> */}

            <div className='row'>
                            <div className='col-12' style={{background:"#373737",padding:"10px"}}>
                                <span className='float-start'>
                                    <b>Total Unauthorized Domain Matches: {totalItems > 0 ? totalItems : "0"}</b>
                                </span>
                                <span className='float-end'>
                                    {/* <span className="tooltip1" tooltip="Add to whitelist" flow="down" style={{float:"left"}} >
                                        <FontAwesomeIcon icon={faRemove} className='iconcircle-sherlock' style={{padding:"2px 5px"}}></FontAwesomeIcon>
                                    </span>                                     */}
                                    <span className="tooltip1" tooltip="Share in Email" flow="down" style={{float:"left"}} onClick={triggeremailpopup}>
                                        <FontAwesomeIcon icon={faEnvelope} className='iconcircle-sherlock'  style={{padding:"4px 5px",fontSize:"12px"}} data-bs-toggle="modal" data-bs-target="#shareemail"></FontAwesomeIcon>
                                    </span>
                                    <span className="tooltip1" tooltip="Download Domain Report in CSV format" flow="down" style={{float:"left"}}>
                                    <CsvDownloader datas={tabledatacsv} columns={columns} filename={new Date().toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) + "_Unauthorized"}>
                                    <FontAwesomeIcon icon={faArrowDown} className='iconcircle-sherlock' style={{padding:"2px 5px"}}></FontAwesomeIcon>
                                    </CsvDownloader>
                                    </span>
                                </span>
                            </div>
                        </div>

            <div className="row w-100" style={{marginTop:"15px"}}>
                <div className="col mb-3 col-12 text-center" style={{paddingRight:"0"}}>
                    <div className="row">                      
                        <div className="col-md-12 d-flex flex-row-reverse" style={{paddingRight:"0"}}>
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                   <div className="row">
                   <div className="rightside-bar pr5 nominheight" style={{minHeight:'unset'}}>
                    <table className="table table-hover common-table sherlocktable">
                        <Header
                            headers={headers}
                            defaultsort="lastupdated" 
                            defaultsortorder="desc" 
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
<tbody>
{tableData.length > 0 ? tableData.map((d) => (
                                <tr style={{textAlign:'left'}}>
									{/* <td className="center"><input type="checkbox"/></td> */}
									{/* <td style={{cursor:"pointer"}}><a href='javascript:;' onClick={this.openemaildetails} data-to={"/email?id="+d.id}>{d.title}</a> </td> */}
                                    <td style={{cursor:"pointer"}}><a href='javascript:;' data-domainname={d.domainname} onClick={onClick}>{d.domainname}</a> </td>
									{/* <td style={{cursor:"pointer"}}><Link to={"/unauthdetails?id="+d.domainname} >{d.domainname}</Link></td> */}
									<td>{d.domaincount.toString()}</td>
									<td>{moment.utc(d.lastupdated).local().format('MM-DD-YYYY h:mm A')}</td>
								</tr>

                            )) : <tr>
                                <td colSpan={3} className="text-center">                                 
                                No data found                                 
                                </td>
                                </tr>
                                }
</tbody>                        
                    </table>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 sherlocktable">
                        <center>
                        <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </center>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
            
            {/* <div class="modal fade shareemailui" id="shareemail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Share Email</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label>To:</label>
                                <input type="text" placeholder="Enter Email"/>
                            </div>
                            <div className="col-12">
                                <label>CC:</label>
                                <input type="text" placeholder="Enter Email"/>
                            </div>
                            <div className="col-12">
                                <label>BCC:</label>
                                <input type="text" placeholder="Enter Email"/>
                            </div>
                            <div className="col-12">
                                <label>Message:</label>
                                <textarea placeholder="Enter Message"></textarea>
                            </div>
                            <div className="col-12">
                                <center>
                                    <a href="javascript:;" tabindex="-1" class="common-save">Send</a>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div> */}
        </>
        
    );
};

export default Sherlockunauthorizedcombined;