import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React from 'react';
import {UserID,PaymentCustomerId,TextToImageSub,ImageTransformSub,EmailId,SSOUser} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';

export const getAccessToken = () => Cookies.get('userInfo')

class ManagePayment extends React.Component {
    componentDidMount(){
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) {
			el.classList.remove('active');
		});
		// document.getElementById('0').classList.remove("active");
        if(document.getElementById('0') != null){
            document.getElementById('0').classList.add("active");
        }
        console.log('SSOUSER '+ SSOUser);
        if (SSOUser) {
            document.getElementById('btnUpgradeSub').style.display = "none";
            document.getElementById('btnUpgradeSubSSO').style.display = "inline-block";
        }
        else{
            document.getElementById('btnUpgradeSub').style.display = "inline-block";
            document.getElementById('btnUpgradeSubSSO').style.display = "none";
        }
        this.getCardInfo();
    }
    historygoback = () => {
        //window.history.back();
        window.location.href = "/";
    };

    getPlanWords = (NewPT,NewPL) =>{
        debugger;
        var kecase = NewPT.toString() + "" + NewPL.toString();
        var words = "";
        switch (kecase) {
            case "11":
              words = "20K";
              break;
            case "12":
                if(SSOUser)
                words = "100K";  
                else
                words = "40K";  

              break;
            case "13":
            words = "80K";
            break;
            case "14":
            words = "100K";
            break;
            case "15":
                words = "120K";
                break;
            case "16":
                words = "160K";  
            break;
            case "17":
                words = "250K";
                break;
            case "18":
                words = "350K";
                break;
            case "21":
              words = "80K";
              break;
            case "22":
                words = "100K";  
              break;
              case "23":
                words = "320K";
                break;
              case "24":
                words = "750K";
                break;
            default:
                words = "3K";
                break;
        }
        return words;
    }

      getCardInfo = () =>{
        var self = this;
        //this.showoverlay(); getCardDetails(string UserId,string CustomerId)
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/getCardDetails?UserId="+UserID+"&CustomerId="+parsedRes.PaymentCustomerId,
            headers: {
               'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {

                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        console.log(responseData);
                        // NextRenewalDate: "03/01/2023"
                        // brand: "Visa"
                        // expiryDate: "12"
                        // expiryYear: "2034"
                        // last4 : "4242"
                        //h4Brand h5Expires h6NextRenewal
                        //debugger;
                        var planLevelLoc = parseInt(responseData.PlanLevel);
                        var planLevelName = "";
                        switch (planLevelLoc) {
                            case 1:
                                var planLevelName = "Base";
                                break;
                            case 2:
                                var planLevelName = "Premium";
                                break;
                            default:
                                //custom
                                planLevelName = "Custom";
                                break;
                        }
                        var everyMonthWords = self.getPlanWords(responseData.PlanType,responseData.PlanLevel);
                        if(responseData.Cancelled == "1"){
                            document.getElementById('h6NextRenewal').textContent="Canceled on " + moment(responseData.CancelledDate).format("MMM D, YYYY");
                            document.getElementById('h4Brand').textContent="";
                            document.getElementById('h5Expires').textContent="";
                            document.getElementById('hSavedCards').style.display="none";
                            document.getElementById('btnUpdateCard').style.display="none";
                            document.getElementById('btnCancelSub').style.display="none";
                            //
                            document.getElementById('btnCancelSub').style.display="none";
                            document.getElementById('h6AccountExpiresOn').textContent = "Your account will be active until " + moment(responseData.NextRenewalDate).format("MMM D, YYYY");
                            document.getElementById('h6CurrentPlan').textContent="";
                            document.getElementById('h6PlanWords').textContent="";
                            document.getElementById('h6PlanWordsCurrent').textContent="";
                            document.getElementById('btnUpgradeSub').style.display = "none";
                            document.getElementById('btnUpgradeSubSSO').style.display = "none";

                            // current plan
                            var intWords = parseInt(responseData.TotalWordsCount);
                            intWords = intWords/1000;                            
                            if(responseData.PlanType == "0"){
                                document.getElementById('h6CurrentPlan').textContent="Trial";
                                document.getElementById('h6PlanWords').textContent= intWords +"K words & 5 image credits";
                                if(SSOUser){
                                    document.getElementById('h6NextRenewal').textContent="Trial period expires on " + moment(responseData.NextRenewalDate).format("MMM D, YYYY") ;;
                                    document.getElementById('h4Brand').textContent="";
                                    document.getElementById('h5Expires').textContent="";
                                    document.getElementById('hSavedCards').style.display="none";
                                }
                            }
                            else if(responseData.PlanType == "1"){
                                var intAmt = parseInt(responseData.Amount)/100;

                                document.getElementById('h6CurrentPlan').textContent="Single User - "+planLevelName+" - $"+intAmt+"/month";
                                document.getElementById('h6PlanWords').textContent= everyMonthWords +" words/month";                                                                
                            }
                            else if(responseData.PlanType == "2"){
                                var intAmt1 = parseInt(responseData.Amount)/100;
                                document.getElementById('h6CurrentPlan').textContent="Team - "+planLevelName+" - $"+intAmt1 +"/month";
                                document.getElementById('h6PlanWords').textContent= everyMonthWords +" words/month";
                            }
                            //
                            if (everyMonthWords != intWords.toString()+"K") {
                                document.getElementById('h6PlanWordsCurrent').textContent="Current Month - "+ intWords.toString()+"K words";
                            }
                            else{
                                document.getElementById('h6PlanWordsCurrent').textContent="";
                            }
                        }
                        else{
                            document.getElementById('h6NextRenewal').textContent="Autopay on " + moment(responseData.NextRenewalDate).format("MMM D, YYYY") ;
                            document.getElementById('btnUpdateCard').style.display="inline-block";
                            document.getElementById('btnCancelSub').style.display="inline-block";
                            document.getElementById('hSavedCards').style.display="block";
                            document.getElementById('h6AccountExpiresOn').textContent = "";
                            document.getElementById('h4Brand').textContent=responseData.brand +" **** "+responseData.last4;
                            document.getElementById('h5Expires').textContent="Expires on "+responseData.expiryDate + "/"+responseData.expiryYear;
                            //PlanType PlanLevel TotalWordsCount TotalImagesCount UsedWordsCount UsedImagesCount Amount
                            var intWords = parseInt(responseData.TotalWordsCount);
                            intWords = intWords/1000;
                            if(responseData.PlanType == "0"){
                                document.getElementById('h6CurrentPlan').textContent="Trial";
                                document.getElementById('h6PlanWords').textContent= intWords +"K words & 5 image credits";
                                if(SSOUser){
                                    document.getElementById('h6NextRenewal').textContent="Trial period expires on " + moment(responseData.NextRenewalDate).format("MMM D, YYYY") ;;
                                    document.getElementById('h4Brand').textContent="";
                                    document.getElementById('h5Expires').textContent="";
                                    document.getElementById('hSavedCards').style.display="none";
                                }
                            }
                            else if(responseData.PlanType == "1"){
                                var intAmt = parseInt(responseData.Amount)/100;
                                document.getElementById('h6CurrentPlan').textContent="Single User - "+planLevelName+" - $"+intAmt+"/month";
                                document.getElementById('h6PlanWords').textContent= everyMonthWords +" words/month";
                            }
                            else if(responseData.PlanType == "2"){
                                var intAmt1 = parseInt(responseData.Amount)/100;
                                document.getElementById('h6CurrentPlan').textContent="Team - "+planLevelName+" - $"+intAmt1 +"/month";
                                document.getElementById('h6PlanWords').textContent= everyMonthWords +" words/month";
                            }
                            
                            if (everyMonthWords != intWords.toString()+"K") {
                                document.getElementById('h6PlanWordsCurrent').textContent="Current Month - "+ intWords.toString()+"K words";
                            }
                            else{
                                document.getElementById('h6PlanWordsCurrent').textContent="";
                            }

                            if(responseData.TextToImageSub){
                                self.loadPriceValues();
                                document.getElementById("divTextToImageSub").style.display = "block"
                            }
                            else{
                                document.getElementById("divTextToImageSub").style.display = "none"
                            }
                            if(responseData.ImageTransformSub){
                                document.getElementById("divImageTransformSub").style.display = "block"
                            }
                            else{
                                document.getElementById("divImageTransformSub").style.display = "none"
                            }
                            if (SSOUser) {
                                //AeloImageSub
                                document.getElementById("divAeloImageSub").style.display = "block";
                                var lblAeloImg = document.getElementById("lblAeloImageSub");
                                var totalAeloImg = parseInt(responseData.TotalAeloImages);
                                var usedAeloImg = parseInt(responseData.UsedAeloImages);
                                if (totalAeloImg > 0 && usedAeloImg < totalAeloImg) {
                                    var remainingAeloImg = totalAeloImg - usedAeloImg;
                                    lblAeloImg.innerText="Remaining "+remainingAeloImg+" Unused Aelo Transform Credit";
                                } else {
                                    lblAeloImg.innerText ="";
                                }
                            } else {
                                document.getElementById("divAeloImageSub").style.display = "none";
                                document.getElementById("lblAeloImageSub").innerText="";
                            }
                            // HomedecorSub
                        }
                    }
                    else {
                        console.log('else error')
                        self.customAlertMessage('error', "No details present.");
                    }
                }
                else {
                    console.log('error '+response.Message);
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.hideoverlay();
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
            });
      }

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
            document.getElementById('overlay').style.display = "none";
            $('body').removeClass("loader");
    }
    customAlertMessage = (icon,message) =>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    updateCard = () =>{
        var self =this;
    };

    cancelTextToImageSubscription = () =>{
        var self = this;
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/SubscribeTexToImage?MasterId="+UserID+"&SubOpt=0&EmailId="+EmailId ,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    var userI = getAccessToken();
                    var decodedUser = decodeURIComponent(userI);
                    //console.log(decodedUser);
                    var parsedRes = JSON.parse(decodedUser);
                    parsedRes.TextToImageSub = false;
                    const serializedState2 = JSON.stringify(parsedRes);
                    Cookies.set('userInfo', serializedState2); 
                    self.customAlertMessage('success', "Subscription canceled successfully");   
                    setTimeout(() => {
                        window.location.href = window.location.origin;
                        }, 3000);                                    
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    };

    cancelImageTransformSubscription = () =>{
        var self = this;
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/SubscribeImageTransform?MasterId="+UserID+"&SubOpt=0&EmailId="+EmailId ,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    var userI = getAccessToken();
                    var decodedUser = decodeURIComponent(userI);
                    //console.log(decodedUser);
                    var parsedRes = JSON.parse(decodedUser);
                    parsedRes.ImageTransformSub = false;
                    const serializedState2 = JSON.stringify(parsedRes);
                    Cookies.set('userInfo', serializedState2);
                    self.customAlertMessage('success', "Subscription canceled successfully");
                    setTimeout(() => {
                        window.location.href = window.location.origin;
                        }, 3000);     
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    };

    logoutCS = () =>{
        Cookies.remove("userInfo");
        Cookies.remove("refresh_token");
        Cookies.remove("g_key");
        window.location.href = window.location.origin;
      }
    
    openPriceModel = () =>{
        document.getElementById('txtConfirmText').value="";
        document.getElementById('hrefPriceModal').click();
    }
    closePriceModel = () =>{
        document.getElementById('btnCancelPriceModal').click();
    }
    showCancelPrompt = () =>{
        this.openPriceModel();
    }

    showCancelPromptTTI = () =>{
        document.getElementById('txtConfirmTextTTI').value="";
        document.getElementById('hrefCancelSub').click();
    }

    showCancelPromptImgTransform = () =>{
        document.getElementById('txtConfirmTextTTIImgT').value="";
        document.getElementById('hrefCancelSubImgTransform').click();
    }

    validatecancelSubscription = () =>{
        var txtConfirmText = document.getElementById('txtConfirmText').value;
        if (txtConfirmText == "CANCEL") {
            this.closePriceModel();
            this.cancelSubscription();
        }
        else{
            this.customAlertMessage('error','Please enter correct text.')
        }
    }

    validateTTIcancelSubscription = () =>{
        var txtConfirmText = document.getElementById('txtConfirmTextTTI').value;
        if (txtConfirmText == "CANCEL") {
            document.getElementById('btnCancelPriceModalCancel').click();
            this.cancelTextToImageSubscription();
        }
        else{
            this.customAlertMessage('error','Please enter correct text.')
        }
    }

    validateTransformImagecancelSubscription = () =>{
        var txtConfirmText = document.getElementById('txtConfirmTextTTIImgT').value;
        if (txtConfirmText == "CANCEL") {
            document.getElementById('btnCancelPriceModalCancelImgTransform').click();
            this.cancelImageTransformSubscription();
        }
        else{
            this.customAlertMessage('error','Please enter correct text.')
        }
    }

    cancelSubscription = () =>{
        var self = this;
        //this.showoverlay(); getCardDetails(string UserId,string CustomerId)

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CancelSubscription?UserId="+UserID+"&CustomerId="+PaymentCustomerId,
            headers: {
               'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {

                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        console.log(responseData);
                        // if(responseData.Cancelled == "1"){
                        //     document.getElementById('h6NextRenewal').innerHTML="Canceled on " + responseData.CancelledDate;
                        //     //Disablebuttons
                        //     document.getElementById('btnUpdateCard').style.display="none";
                        //     document.getElementById('btnCancelSub').style.display="none";
                        // }
                        // else{
                        //     document.getElementById('h6NextRenewal').innerHTML="Next Renewal on " + responseData.NextRenewalDate;
                        //     document.getElementById('btnUpdateCard').style.display="block";
                        //     document.getElementById('btnCancelSub').style.display="block";
                        // }
                        self.customAlertMessage('success', "Subscription canceled successfully.");
                        // setTimeout(() => {
                        //     self.logoutCS();
                        // }, 2000);

                        if(responseData.Cancelled == "1"){
                            document.getElementById('h6NextRenewal').textContent="Canceled on " + moment.utc(responseData.CancelledDate).local().format("MMM D, YYYY");
                            document.getElementById('h4Brand').textContent="";
                            document.getElementById('h5Expires').textContent="";
                            document.getElementById('hSavedCards').style.display="none";
                            document.getElementById('btnUpdateCard').style.display="none";
                            document.getElementById('btnCancelSub').style.display="none";
                            document.getElementById('h6AccountExpiresOn').textContent = "Your account will be active until " + moment.utc(responseData.NextRenewalDate).local().format("MMM D, YYYY");
                            document.getElementById('btnUpgradeSub').style.display = "none";
                            document.getElementById('btnUpgradeSubSSO').style.display = "none";
                        }
                        else{
                            document.getElementById('h6NextRenewal').textContent="Autopay on " + moment.utc(responseData.NextRenewalDate).local().format("MMM D, YYYY") ;
                            document.getElementById('btnUpdateCard').style.display="inline-block";
                            document.getElementById('btnCancelSub').style.display="inline-block";
                            document.getElementById('hSavedCards').style.display="block";
                            document.getElementById('h6AccountExpiresOn').textContent = "";
                            document.getElementById('h4Brand').textContent=responseData.brand +" **** "+responseData.last4;
                            document.getElementById('h5Expires').textContent="Expires on "+responseData.expiryDate + "/"+responseData.expiryYear;
                        }
                    }
                    else {
                        console.log('else error')
                        self.customAlertMessage('error', "No details present.");
                    }
                }
                else {
                    console.log('error '+response.Message);
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.hideoverlay();
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
            });
    };

    loadPriceValues = () =>{
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        console.log(decodedUser);
        var parsedResNew = JSON.parse(decodedUser);
        var planTypeCurrent = parseInt(parsedResNew.PlanType);
        var planLevelCurrent = parseInt(parsedResNew.PlanLevel);
        var isSSOUserLPP = SSOUser ? "SSO":"";
        var kecase = planTypeCurrent.toString() + "" + planLevelCurrent.toString() + isSSOUserLPP;
        var words = "30";
        switch (kecase) {
            case "11":
              words = "30";
              break;
            case "12":
                words = "50";  
              break;
            case "11SSO":
            words = "7";
            break;
            case "12SSO":
                words = "18";  
            break;
            case "13":
            words = "75";
            break;
            case "14":
            words = "99";
            break;
            case "15":
                words = "129";
                break;
            case "16":
                words = "199";  
            break;
            case "17":
                words = "249";
                break;
            case "18":
                words = "299";
                break;
            case "21":
              words = "75";
              break;
            case "22":
                words = "99";  
              break;
            case "21SSO":
            words = "33";
            break;
            case "22SSO":
                words = "68";  
            break;
              case "23":
                words = "129";
                break;
              case "24":
                words = "199";
                break;
            default:
                words = "30";
                break;
        }
        document.getElementById('lblPriceValue').textContent = words;
        return words
    };

    render(){
return (
    <>
<div className="main_content_iner overly_inner dashboard-page">
        <div className="container-fluid p-0 ">
          <div className="row">

          <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading"></h2>
                    <img src={loader} />
                </div>
            </div>

            <div className="col-12">
                <div className="title-card1">
                    <h4>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={this.historygoback}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Manage Payment
                    </span>
                    </h4>
                </div>
            </div>

            <div className='col-12 dashboard-scroll'>
                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-12 mb-30'>
                                <h4 id="hSavedCards" style={{fontSize:"18px",marginTop:"20px"}}>Saved Credit Card</h4>
                                <div className='cardhold'>
                                    <h4 id="h4Brand"></h4>
                                    <h5 id="h5Expires"></h5>
                                    <h6 id="h6NextRenewal"></h6>
                                    <h6 id="h6AccountExpiresOn"></h6><br/>
                                    <h5 style={{color:"#000"}}><b><u>Current Plan</u></b></h5><h6 id="h6CurrentPlan"></h6>
                                    <h6 id='h6PlanWords'></h6><br/>
                                    <h6 id='h6PlanWordsCurrent'></h6><br/>

                                    <div id="divTextToImageSub" style={{display:"none"}} className='row'>
                                        <h6><b><u>Text To Image Subscription</u></b></h6>
                                        <span>Unlimited Images - $<label id="lblPriceValue"></label>/month</span>
                                        <center><a href='javascript:;' id='btnCancelSubTTI' onClick={this.showCancelPromptTTI} className="common-clear-new" style={{width:"fit-content"}}>Cancel  Text To Image</a> </center><br/><br/>
                                    </div>
                                    <div id="divImageTransformSub" style={{display:"none"}} className='row'>
                                        <h6><b><u>Image Transform Subscription</u></b></h6>
                                        <label>Unlimited Trasformations - $5/month</label>  
                                        <center><a href='javascript:;' id='btnCancelSubImgTransorm' onClick={this.showCancelPromptImgTransform} className="common-clear-new" style={{width:"fit-content"}}>Cancel Image Transform</a> </center>
                                    </div>
                                    <div id="divAeloImageSub" style={{display:"none"}} className='row'>
                                        <h6 id='lblAeloImageSub'></h6>
                                    </div>
                                </div>                           
                            </div>
                            <div className='col-12 mb-30'>
                                <center>
                                    <Link to="/updatecard" id='btnUpdateCard' className="common-save">Update Credit Card</Link> &nbsp;
                                    {/* <a href='/updatecard' id='btnUpdateCard' onClick={this.updateCard} className="common-save float-start"></a> */}
                                    <Link to="/upgradeplan" id='btnUpgradeSub' className="common-save" style={{marginLeft:"10px"}}>Manage Subscription</Link>
                                    <Link to="/upgradeplansso" id='btnUpgradeSubSSO' className="common-save" style={{marginLeft:"10px",display:"none"}}>Manage Subscription</Link>             
                                </center>
                            </div>
                            <div className='col-12 mb-30'>                               
                                <center><a href='javascript:;' id='btnCancelSub' onClick={this.showCancelPrompt} className="common-clear-new">Cancel Subscription</a></center> 
                            </div>
                        </div>
                        
                        
                    </div>


                        


                </div>
            </div>





          </div>
        </div>
        <a href='javascript:;' id='hrefPriceModal' data-bs-toggle="modal"
                          data-bs-target="#pricingmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
        <a href='javascript:;' id='hrefCancelSub' data-bs-toggle="modal"
                          data-bs-target="#pricingmodalCancel" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
        <a href='javascript:;' id='hrefCancelSubImgTransform' data-bs-toggle="modal"
                          data-bs-target="#pricingmodalCancelImgTransform" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
      </div>

            {/* price model start */}
            <div className="modal fade" id="pricingmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id="lblPricingTitle"><b>Cancel Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p style={{color:"#000"}}>Oh Oh!</p>
                                        <p style={{color:"#000"}}>You seem to be cancelling your subscription.</p>
                                        <p style={{color:"#000"}}>To confirm cancellation, please type CANCEL in the following box.</p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="row" style={{marginBottom:"10px"}}>
                                            <div className='col-3'>
                                                <input type='text' id='txtConfirmText' className='common-input'></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btnCancelPriceModal' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnCancelSubvali' onClick={this.validatecancelSubscription} className="common-save float-start">Proceed</a>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* price model end */}

            {/* Image cancel model start */}
            <div className="modal fade" id="pricingmodalCancel" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id="lblPricingTitle"><b>Cancel Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p style={{color:"#000"}}>You look to be canceling your Text To Image subscription.</p>
                                        <p style={{color:"#000"}}>Please note page will be refreshed after cancellation.</p>
                                        <p style={{color:"#000"}}>To confirm cancellation, please type CANCEL on below field.</p>                                        
                                    </div>
                                    <div className='col-12'>
                                        <div className="row" style={{marginBottom:"10px"}}>
                                            <div className='col-3'>
                                                <input type='text' id='txtConfirmTextTTI' className='common-input'></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btnCancelPriceModalCancel' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnCancelSub' onClick={this.validateTTIcancelSubscription} className="common-save float-start">Proceed</a>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* Image cancel model end */}

                        {/* Image transform cancel model start */}
                        <div className="modal fade" id="pricingmodalCancelImgTransform" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id="lblPricingTitleImgTransform"><b>Cancel Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p style={{color:"#000"}}>You look to be canceling your Image Transform subscription.</p>
                                        <p style={{color:"#000"}}>Please note page will be refreshed after cancellation.</p>
                                        <p style={{color:"#000"}}>To confirm cancellation, please type CANCEL on below field.</p>                                        
                                    </div>
                                    <div className='col-12'>
                                        <div className="row" style={{marginBottom:"10px"}}>
                                            <div className='col-3'>
                                                <input type='text' id='txtConfirmTextTTIImgT' className='common-input'></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btnCancelPriceModalCancelImgTransform' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnCancelSubImgTransform' onClick={this.validateTransformImagecancelSubscription} className="common-save float-start">Proceed</a>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* Image transform cancel model end */}
    </>
)
}
};
export default ManagePayment;