import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import React, { useRef,useImperativeHandle } from "react";

const Imgsample = () => {
  const selectInputRef = useRef();
  const options = [
    { value: "1", label: "1" },    
    { value: "2", label: "4" },
   
  ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };
  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  return (
    // <Select options={options} styles={customStyles} />
    <>
      <a href="javascript:;" id="imgsampleclear" onClick={onClear} style={{display:'none'}}>Clear</a>
      <Select ref={selectInputRef} defaultValue={options[1]} placeholder="Select" id="imgsample" options={options}/>
    </>
    
  );
};

export default Imgsample;