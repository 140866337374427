import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import React, { useRef,useImperativeHandle } from "react";

const Roomtheme = () => {
  const selectInputRef = useRef();
  const options = [
    { value: "-1", label: "Select" }
    ,{ value: "1", label:"1970s Revival"}
    ,{ value: "2", label:"Art Deco"}
    ,{ value: "3", label:"Art Nouveau"}
    ,{ value: "4", label:"Bohemian"}
    ,{ value: "5", label:"Boho Chic"}
    ,{ value: "6", label:"Californian"}
    ,{ value: "7", label:"Coastal"}
    ,{ value: "8", label:"Contemporary"}
    ,{ value: "9", label:"Dessert Modern"}
    ,{ value: "10", label:"Eclectic"}
    ,{ value: "11", label:"English Countryside Chic"}
    ,{ value: "12", label:"Farmhouse"}
    ,{ value: "13", label:"French Country"}
    ,{ value: "14", label:"Glam"}
    ,{ value: "15", label:"Grandmillennial"}
    ,{ value: "16", label:"Hollywood Regency"}
    ,{ value: "17", label:"Industrial"}
    ,{ value: "18", label:"Mediterranean"}
    ,{ value: "19", label:"Memphis"}
    ,{ value: "20", label:"Mid-Century Modern"}
    ,{ value: "21", label:"Millennial Maximalist"}
    ,{ value: "22", label:"Minimalist"}
    ,{ value: "23", label:"Modern"}
    ,{ value: "24", label:"Modern Farmhouse"}
    ,{ value: "25", label:"Naturalist"}
    ,{ value: "26", label:"Neo-Industrial"}
    ,{ value: "27", label:"New Traditional"}
    ,{ value: "28", label:"Organic Minimalist"}
    ,{ value: "29", label:"Retro"}
    ,{ value: "30", label:"Rustic"}
    ,{ value: "31", label:"Scandinavian"}
    ,{ value: "32", label:"Shabby Chic"}
    ,{ value: "33", label:"Southwestern"}
    ,{ value: "34", label:"Traditional"}
    ,{ value: "35", label:"Transitional"}
  ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };
  const onClear = () => {
    selectInputRef.current.clearValue();
  };

  return (
    // <Select options={options} styles={customStyles} />
    <>
      <a href="javascript:;" id="roomthemeclear" onClick={onClear} style={{display:'none'}}>Clear</a>
      <Select ref={selectInputRef} defaultValue={options[0]} placeholder="Select" id="roomtheme" options={options}  maxMenuHeight={300}/>
    </>
    
  );
};

export default Roomtheme;