import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
// import emailimage from 'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/1.png'
// import texttoimage from 'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/2.png'
// import uploadimage from 'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/3.png'
// import aiimage from 'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/4.png'
// import fbimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/5.png')
// import instaimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/6.png')
// import twitterimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/7.png')
// import tiktokimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/8.png')
// import pinimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/9.png')
// import imagechatimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/11.png')
// import voicechatimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/12.png')
// import roomgptimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/13.jpg')
// import texttovideo from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/14.png')
// import Agingimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/15.png')

// import Aeloplus from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/18.jpg')

// import Restorationimage from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/16.jpg')
// import Addcolourstoimg from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/17.jpg')
// import PropDescImg from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/19.jpg')
// import Transmogrify from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/20.png')
// import OldPhoto from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/21.png')
// import MarketRImg from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/mr22.png')
// import Videoimg from require('https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/22.png')

import React from 'react';
import {UserID,EulaValue,Features,Role,TextToImageSub,ImageTransformSub, MasterId, SSOUser,Trail} from "../User";
import * as axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import loader from "../img/sample.gif";
import $ from 'jquery';
import MarketReportNew from './MarketReportNew';
export const getAccessToken = () => Cookies.get('userInfo')


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textchat:Features.TextChat,
            voicechat:Features.VoiceChat,
            texttoimage:Features.TextToImage,
            imagetransform:Features.ImageTransform,
            virtualstaging:false,
            HomeDecor : Features.Homedocor,
            Funsection: Features.Funsection == undefined ? false : Features.Funsection,
            Aeloplus:Features.Aeloplus == undefined ? false : Features.Aeloplus,
            PropertyDescGenerator: Features.PropertyDescriptionGen == undefined ? false : Features.PropertyDescriptionGen,
            MarketReportF: Features.MarketReport ==  undefined ? false : Features.MarketReport,
            Labsection: (Features.Labsection == undefined ? false : Features.Labsection),
            IsTrial:  this.getTrailPeriodValue(),
            Propertyvideoenabled: Features.Propertyvideo,
            ListingDescriptionAdvanced : Features.ListingDescriptionAdvanced == undefined ? false: Features.ListingDescriptionAdvanced,
            SSOuser: this.GetSSOuser(),
            PhotoCompliance: Features.PhotoCompliance == undefined ? false : Features.PhotoCompliance,
            ImageDescription: Features.ImageDescription == undefined ? false : Features.ImageDescription,
            ImageSearch: Features.ImageSearch == undefined ? false : Features.ImageSearch,
            SherlockAI: Features.SherlockAI == undefined ? false : Features.SherlockAI,
            AivideoUrl: "https://aivideos.chocolatechips.ai/?CC=1&UserId="+UserID,
            AiVideoEditor:  Features.Aivideo == undefined ? false : Features.Aivideo,
            DashboardFN: Features.Dashboard == undefined ? false : Features.Dashboard,
        }
    }

    getTrailPeriodValue = () =>{
        var userI1 = getAccessToken();
        var decodedUser1 = decodeURIComponent(userI1);
        var parsedRes1 = JSON.parse(decodedUser1); 
        var istrial = parseInt(parsedRes1.PlanType);
         console.log(parsedRes1);
        // alert(istrial);
        // if(istrial){
        //     alert('Yes it is true');
        // }
        return istrial;
    }

    GetSSOuser = () => {
        var userI1 = getAccessToken();
        var decodedUser1 = decodeURIComponent(userI1);
        var parsedRes1 = JSON.parse(decodedUser1); 
        var ssouser = (parsedRes1.SSOUser);
        return ssouser;        
    }

    componentDidMount(){
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
			el.classList.remove('active');
		});
        if(document.getElementById('0') != null){
		document.getElementById('0').classList.add("active");
    }
        this.documentinit();
        // this.checkFeaturesEnable();
        this.checkgrammarly();
        if (Features.Labsection == undefined) {
            this.logout();
        }
        this.checkSubStatusDashboard();      
        // Cookies.set('logintime', new Date().getTime());  
    }

    checkUpgradePlanDashboard = () =>{
        var appendUL ="";
        try {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var upgradeplan = url.searchParams.get("upgradeplan");
            //console.log(upgradeplan);
            if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "Y") {
                //appendUL ="?upgradeplan=Y";   
                Cookies.set("NAVTOUPGRADE", "true");          
            }
        } catch (error) {
            
        }
        return appendUL;        
    }

    checkAiVideoDashboard = () =>{
        var appendUL ="";
        try {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var upgradeplan = url.searchParams.get("AIE");
            //console.log(upgradeplan);
            if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "1") {
                //appendUL ="?upgradeplan=Y";   
                Cookies.set("NAVTOAIVIDEO", "true");          
            }
        } catch (error) {
            
        }
        return appendUL;        
    }

    checkUpgradePlan = () =>{
        try {
            this.checkUpgradePlanDashboard();
            var localSSOUser = SSOUser;
            setTimeout(() => {                              
                var url_string = window.location.href;
                var url = new URL(url_string);
                var upgradeplan = Cookies.get("NAVTOUPGRADE");
                console.log(upgradeplan);
                if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "true") {
                    Cookies.remove("NAVTOUPGRADE");
                    if (localSSOUser) {
                        document.getElementById('hrefSSOUP').click();
                    } else {
                        document.getElementById('hrefNUP').click();
                    }                    
                }
            }, 2000);
        } catch (error) {
            
        }        
    }
    //NAVTOAIVIDEO
    checkAIVideo= () =>{
        return;
        try {
            this.checkAiVideoDashboard();
            setTimeout(() => {                              
                // var url_string = window.location.href;
                // var url = new URL(url_string);
                var upgradeplan = Cookies.get("NAVTOAIVIDEO");
                console.log(upgradeplan);
                if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "true") {
                    Cookies.remove("NAVTOAIVIDEO");
                        document.getElementById('hrefAiVideo').click();                
                }
            }, 2000);
        } catch (error) {
            
        }        
    }

    proceedOnUpgrade = () =>{
        document.getElementById('btncancelUpgradeLayoutdashboard').click();
        if (SSOUser) {      
          document.getElementById('lnkSSOUpgrade').click();
        }
        else{
          document.getElementById('lnkNormalUpgrade').click();
        }
      }

    checkSubStatusDashboard = () =>{
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        var parsedRes = JSON.parse(decodedUser); 
        var subState = parsedRes.SubscriptionState;   
        var pId = parsedRes.PaymentCustomerId; 
        var pType = parsedRes.PlanType    
        if (pId == "" && subState > 0) {      
          switch (subState) {
            case 1:
              //
              document.getElementById('upgradeModelTextdashboard').innerHTML="You have cancelled the subscription. Please upgrade the subscription plan to start using chocolatechips.ai.";
              break;
            case 2:
              if (pType < 1) {
                document.getElementById('upgradeModelTextdashboard').innerHTML="Your trial period is over. Please upgrade to a paid subscription.";
              }
              else{
                document.getElementById('upgradeModelTextdashboard').innerHTML="Your subscription had expired. Please upgrade the subscription plan to start using chocolatechips.ai";
              }   
              break;    
            default:                
              break;
          }
          this.showUpgradePromptdash();
        }
        else{
            this.checkUpgradePlan();
            this.checkAIVideo();
        }
        
      }

      showUpgradePromptdash = () =>{
        document.getElementById('hrefSubLayoutdashboard').click();
      }

    logout = () =>{
        Cookies.remove("userInfo");
        Cookies.remove("refresh_token");
        Cookies.remove("g_key");
        window.location.href = window.location.origin;
      }
    documentinit = () => {        
        this.checkEulaStatus();
    }

    // checkFeaturesEnable = () =>{
    //     console.log('TextChat ' + Features.TextChat);
    //     console.log('VoiceChat ' +Features.VoiceChat);
    //     //TextChat//VoiceChat
    //     if(Features.TextChat == true){
    //         document.getElementById('divImageChat').style.display ="block"; 
    //         document.getElementById('RoomGPT').style.display ="block"; 
                               
    //     }
    //     else{
    //         document.getElementById('divImageChat').style.display ="none";  
    //         document.getElementById('RoomGPT').style.display ="none"; 
    //     }
    //     //document.getElementById('divVoiceChat').style.display ="block";   
    //     if(Features.VoiceChat == true){
    //         document.getElementById('divVoiceChat').style.display ="block";                    
    //     }
    //     else{
    //         document.getElementById('divVoiceChat').style.display ="none";  
    //     }
    // }

    checkEulaStatus = () =>{
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        var parsedRes = JSON.parse(decodedUser);
        var internalEulaValue = parsedRes.IsEULAUpdated;
           
        // console.log(internalEulaValue);
        // console.log(EulaValue);
            if(internalEulaValue == false){
                document.getElementById('hrefEula').click();
            }
    }

    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }

    checkgrammarly = () =>{
        const axios = require('axios');
        var self = this;
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/Getgrammarlykey?userid=' + (Role == 3 ? MasterId : UserID),
          headers: { }
        };
      
        axios.request(config)
        .then((response) => {
          if (response.data != null && response.data.Data !="") {
            Cookies.set('g_key',response.data.Data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

    updateEulaStatus = () =>{
        var self = this;

        var chkIAgreeEula = document.getElementById('chkIAgreeEula');
        if (!chkIAgreeEula.checked) {
            self.customAlertMessage('error', "Scroll to end of agreement, select 'I Agree' checkbox and click on 'Proceed'");
            return;
        }
        //CreateTeam(string TName,string UserId)
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/InsertUserEULA?userid="+UserID,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    document.getElementById('btnCloseEula').click();
                    var userI = getAccessToken();
                    var decodedUser = decodeURIComponent(userI);
                    var parsedRes = JSON.parse(decodedUser);
                    parsedRes.IsEULAUpdated = true;
                    const serializedState1 = JSON.stringify(parsedRes);
                    Cookies.set('userInfo', serializedState1); 
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
      }

    render(){
return (
    <>
<div className="main_content_iner overly_inner dashboard-page">
        <div className="container-fluid p-0 ">
          <div className="row">	  
          <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div> 
			
            
			
			<div className="col-12 dashboard-scroll">
				<div className="row">
                    <div className="col-12">
                        <div className="title-card">
                            <h4>
                            <FontAwesomeIcon icon={faAnglesRight} />chocolatechips.ai</h4>
                        </div>
                    </div>
                    {this.state.DashboardFN == true ? 
					<div className="col-4">
                        <Link to="/email">
                            <div className="image-box">
                                <img src="https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/1.png" alt="Email"/>
                                <h4>Email</h4>
                            </div>
                        </Link>
					</div>
                    :null}
                    {this.state.texttoimage == true ?
					<div className="col-4">
                        <Link to="/texttoimage">
						<div className="image-box">
							<img src="https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/2.png" alt="Text to Image"/>
							<h4>Text to Image</h4>
						</div>
                        </Link>
					</div> : null }
                    {(this.state.imagetransform == true && this.state.DashboardFN == true && this.state.IsTrial > 0) ?
					<div className="col-4">
                        <Link to="/uploadimage">
                            <div className="image-box">
                                <img src="https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/3.png" alt="Upload Image"/>
                                <h4>Image Transform</h4>
                            </div>
                        </Link>						
					</div> : (this.state.DashboardFN == true) ?
                    <div className="col-4">
                        <a href='javascript:;' onClick={this.showUpgradePromptdash}>
                            <div className="image-box">
                                <img src="https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/3.png" alt="Upload Image"/>
                                <h4>Image Transform</h4>
                            </div>
                            </a>					
                    </div>:null
                    }
					{/* <div className="col-4">
                        <Link to="/aiavatar">
                            <div className="image-box">
                                <img src={aiimage} alt="AI Avatar"/>
                                <h4>AI Avatar</h4>
                            </div>
                        </Link>						
</div> */}
{this.state.DashboardFN == true ? 
<>
					<div className="col-4">
                        <Link to="/meta">
                            <div className="image-box">
                                <img src="https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/5.png" alt="Facebook"/>
                                <h4>Meta (Facebook)</h4>
                            </div>
                        </Link>						
					</div>
					<div className="col-4">
                        <Link to="/instagram">
                            <div className="image-box">
                                <img src='https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/6.png' alt="Instagram"/>
                                <h4>Instagram</h4>
                            </div>
                        </Link>						
					</div>
                    <div className="col-4">
                        <Link to="/twitter">
                            <div className="image-box">
                                <img src='https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/7.png?v=1' alt="Instagram"/>
                                <h4>Twitter</h4>
                            </div>
                        </Link>						
					</div>
                    <div className="col-4">
                        <Link to="/tiktok">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/8.png'} alt="Instagram"/>
                                <h4>TikTok</h4>
                            </div>
                        </Link>						
					</div>
                    <div className="col-4">
                        <Link to="/pinterest">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/9.png'} alt="Instagram"/>
                                <h4>Pinterest</h4>
                            </div>
                        </Link>						
					</div></>                   
                 :null
                 }
                    {this.state.HomeDecor == true || this.state.SimplifiedAelo == true ? 
                    <div id="RoomGPT" className="col-4">
                        <Link to="/ccaelo">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/13.jpg'} alt="Room Decor"/>
                                <h4>aelo.ai</h4>
                            </div>
                        </Link>						
					</div> 
                    : null}
                    {this.state.PropertyDescGenerator == true ? 
                    <>
                    <div id="divPropertyDescGen" className="col-4">
                        <Link to="/propertydescription">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/19.jpg'} alt="Property Description Generator"/>
                                <h4>Property Description Generator (Basic)</h4>
                            </div>
                        </Link>						
					</div>
                    </> : null}
                    {/* {this.state.ListingDescriptionAdvanced == true ?
                           <div id="divPropertyDescGenadvanced" className="col-4">
                           <Link to="/listingdescription">
                               <div className="image-box">
                                   <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/19.jpg'} alt="Property Description Generator"/>
                                   <h4>Listing Description Generator &nbsp;<sup>Advanced</sup></h4>
                               </div>
                           </Link>						
                       </div>
                    : null}             */}
             
                    {this.state.MarketReportF == true ? 
                    <div id="divMarketReport" className="col-4">
                        <Link to="/marketreport">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/mr22.png'} alt="Market Report"/>
                                <h4>Market Report</h4>
                            </div>
                        </Link>						
					</div> : null}

                    {this.state.AiVideoEditor == true ? 
                    <div id="divAIVideo" className="col-4">
                        <Link to="/aivideo?v=123">
                            <div className="image-box">
                                <img id='imgAIVideo' src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/aivideoeditor.png'} alt="AI Video"/>
                                <h4>AI Video</h4>
                            </div>
                        </Link>						
					</div> : null}

                    {(this.state.PhotoCompliance == true || this.state.ImageDescription == true || this.state.ImageSearch == true || this.state.SherlockAI == true) ?
                        <div className="col-12">
                            <div className="title-card">
                                <h4>
                                <FontAwesomeIcon icon={faAnglesRight} />By Sherlock</h4>
                            </div>
                        </div>
                    : null
                    }
                    {
                    this.state.PhotoCompliance == true ?
                    <>
                        <div id="imageclassification" className="col-4">
                            <Link to="/mls">
                                <div className="image-box">
                                    <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/photo-complaince.png'} alt="Age Travel"/>
                                    <h4>Photo Compliance</h4>
                                </div>
                            </Link>						
                        </div>
                    </>
                     : null 
                    }
                     {this.state.SherlockAI == true ?   
                    <>
                        <div id="piracydetector" className="col-4">
                            <Link to="/sherlock">
                                <div className="image-box">
                                    <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/sherlock_logo.png'} alt="Image Piracy"/>
                                    <h4>Image Piracy</h4>
                                </div>
                            </Link>						
                        </div>
                    </>
                    : null
                    }
                    {
                    this.state.ImageDescription == true ?
                    <div id="divImageDescription" className="col-4">
                        <Link to="https://app.aelo.ai/imagecaption.html" target='_blank'>
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/cc_21.jpg'} alt="Image Description"/>
                                <h4>Image Description</h4>
                            </div>
                        </Link>						
                    </div> : null 
                    }
                    {
                    this.state.ImageSearch == true ?
                    <div id="divImageSearch" className="col-4">
                        <Link to="https://app.aelo.ai/DemoSearch.html?v=123" target='_blank'>
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/cc_20.jpg'} alt="Image Search"/>
                                <h4>Image Search</h4>
                            </div>
                        </Link>						
                    </div> : null 
                    }

                    {this.state.Funsection == true ?
                    <>
                    <div className="col-12">
                        <div className="title-card">
                            <h4>
                            <FontAwesomeIcon icon={faAnglesRight} />Fun</h4>
                        </div>
                    </div>
                     <div id="funaging" className="col-4">
                        <Link to="/imageaging">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/15.png'} alt="Time Travel"/>
                                <h4>Time Travel</h4>
                            </div>
                        </Link>						
					</div>
                    <div id="funaging1" className="col-4">
                        <Link to="/Restorationimage">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/16.jpg'} alt="Super Resolution Image"/>
                                <h4>Super Resolution Image</h4>
                            </div>
                        </Link>						
					</div>
                    {this.state.textchat ?
                    <div id="divImageChat" className="col-4">
                        <Link to="/imagechat">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/11.png'} alt="Instagram"/>
                                <h4>Image Chat</h4>
                            </div>
                        </Link>						
					</div> : null}
                    <div id="funtransformation" className="col-4" style={{display:'none'}}>
                        <Link to="/transformation">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/20.png'} alt="Transformation"/>
                                <h4>HomeGPT</h4>
                            </div>
                        </Link>						
					</div>
                                

                   
                    <div id="funaging2" className="col-4">
                        <Link to="/BringOldImgToBack">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/21.png'} alt="Bringing Old Photos Back to Life"/>
                                <h4>Bringing Old Photos Back to Life</h4>
                            </div>
                        </Link>						
					</div>
                    <div id="funaging3" className="col-4">
                        <Link to="/Addcolourstoimg">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/17.jpg'} alt="Add colours to old images"/>
                                <h4>Add colours to old images</h4>
                            </div>
                        </Link>						
					</div> 
                      {/* <div id="imageclassification" className="col-4">
                        <Link to="/imageclassification">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/cc-17.jpg'} alt="Age Travel"/>
                                <h4>Image classification</h4>
                            </div>
                        </Link>						
					</div> */}
                    </>
                    : null}

                    {/* {this.state.Labsection == true ?
                    <>
                        <div className="col-12">
                            <div className="title-card">
                                <h4>
                                <FontAwesomeIcon icon={faAnglesRight} />Lab</h4>
                            </div>
                        </div>                   
                        {this.state.Aeloplus == true ? 
                        <div id="RoomGPTplus" className="col-4" style={{display:'none'}}>
                            <Link to="/Aeloadvancednew">
                                <div className="image-box">
                                    <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/18.jpg'} alt="Room Decor"/>
                                    <h4>aelo.ai<sup className='sup-plus'>+</sup></h4>
                                </div>
                            </Link>						
                        </div> 
                        : null}
                        {this.state.voicechat == true ?
                        <div id="divVoiceChat" className="col-4">
                            <Link to="/gptvoice">
                                <div className="image-box">
                                    <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/12.png'} alt="Voice chat"/>
                                    <h4>Voice Chat</h4>
                                </div>
                            </Link>						
                        </div> : null}
                        {this.state.HomeDecor == true ? 
                        <div id="TexttoVideo" className="col-4">
                            <Link to="/texttovideo">
                                <div className="image-box">
                                    <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/14.png'} alt="Text To Video"/>
                                    <h4>Text to Video</h4>
                                </div>
                            </Link>						
                        </div> : null}
                    </>
                    : null} */}

                    {this.state.Propertyvideoenabled == true ?
                    <div id="TexttoVideorrr" className="col-4">

                        <Link to="/propertyvideo">
                            <div className="image-box">
                                <img src={'https://openaiimages.s3.us-west-1.amazonaws.com/cc_assets/22.png'} alt="Text To Video"/>
                                <h4>Property Video</h4>
                            </div>
                        </Link>						
					</div>
                    : null}
                    
                   
                   

				</div>
			</div>

			
			
          </div>          
        </div>
        <a href='javascript:;' id='hrefEula' data-bs-toggle="modal"
                          data-bs-target="#eulamodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>
         <a href='javascript:;' id='hrefSubLayoutdashboard' data-bs-toggle="modal"
                          data-bs-target="#upgradeModelLayoutdashboard" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>    
        {/* <a href='/upgradeplan' id='hrefNUP' style={{display:"none"}}    ></a>
        <a href='/upgradeplansso' id='hrefSSOUP' style={{display:"none"}}    ></a> */}
        <Link id="hrefNUP" style={{display:"none"}} to="/upgradeplan">Upgrade</Link>
        <Link id="hrefSSOUP" style={{display:"none"}} to="/upgradeplansso">Upgrade</Link>  
        <Link id="hrefAiVideo" style={{display:"none"}} to="aivideo">Ai Video</Link>        
      </div>
      <div className="modal fade" id="eulamodal" tabIndex={-1} aria-labelledby="listeningModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="listeningModalLabel"><b>AI Disclosure:</b></h5>
                  <button style={{display:"none"}} id="btnCloseEula" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
              <div className="col-12">
                  <p>
                  We expect you to use AI (ChatGPT and image generation tools) AS IS.
                  </p>  
                  <p><b>Be aware of the limits of ChatGPT:</b></p>
                  <ul>
                      <li>If you provide minimum effort prompts, you will get low quality results. You will need to refine your prompts in order to get good outcomes. This will take work.</li>        
                      <li>Don't trust anything it says. If it gives you a number or fact, assume it is wrong unless you either know the answer or can check in with another source. You will be responsible for any errors or omissions provided by the tool. It works best for topics you understand.</li>
                      <li>AI is a tool, but one that you need to acknowledge using.</li>
                      <li>Be thoughtful about when this tool is useful. Don't use it if it isn't appropriate for the case or circumstance.</li>
                  </ul>

                  <p>Review <a href='https://www.chocolatechips.ai/terms-use.html' target='_blank' style={{textDecoration:"none"}}>"Terms of Use"</a> and <a href='https://planetre.com/privacy-policy.html' target='_blank' style={{textDecoration:"none"}}>"Privacy Policy"</a>.</p>
                  
              </div>
              </div>
              <div className="modal-footer">
                <label><input id='chkIAgreeEula' type="checkbox" className='agreecheck'/><b style={{paddingLeft:"10px"}}>I Agree</b></label>
                  <button type="button" style={{display:"none"}} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" onClick={this.updateEulaStatus} className="btn btn-primary">Proceed</button>
              </div>
          </div>
      </div>
      </div>

      {/* price model start */}
    <div className="modal fade" id="upgradeModelLayoutdashboard" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id=""><b>Upgrade Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p id="upgradeModelTextdashboard" style={{color:"#000"}}>Please upgrade your plan to higher level to use this feature.</p>
                                    </div>
                                    <div className='col-12' style={{display:"none"}}>
                                        <div className="row" style={{marginBottom:"10px"}}>
                                            <div className='col-3'>
                                                <Link id="lnkNormalUpgrade" to="/upgradeplan">Upgrade</Link>
                                                <Link id="lnkSSOUpgrade" to="/upgradeplansso">Upgrade</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"none"}} id='btncancelUpgradeLayoutdashboard' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
                        </div>
                    </div>
                </div>                
            </div>
            {/* price model end */}
      </>
)
}
};
export default Dashboard;