import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { json, Link } from "react-router-dom";
import React from 'react';
import Cookies from 'js-cookie'
import {UserID,StreetAddress,ProfileImg} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import {
    faAnglesRight,
    faChevronLeft,
    faMicrophone,
    faInfo,
    faInfoCircle,
    faRotateRight,
    faShield,
    faCloudArrowUp,
    faCircleDown,
    faFile,
    faTimes,
    faCopy,
    faImage,
  } from "@fortawesome/free-solid-svg-icons";
  import Cropper from 'react-easy-crop'
  import getCroppedImg from '../pages/cropimage'
  import nopicture from "../img/nopic.png"
//   import * as fs from 'fs'
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import heic2any from "heic2any";


export const getAccessToken = () => Cookies.get('userInfo');
const profileiamge = () => {
  var decodedUser = decodeURIComponent(Cookies.get('userInfo'));
  var parsedRes = JSON.parse(decodedUser);
  return parsedRes.ProfileImg == "" ? nopicture : parsedRes.ProfileImg;
}
class Profile extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            uploadedbase64 : "",
            crop: { x: 0, y: 0 },
            zoom: 1,
            // aspect: 4 / 3,
            aspect:1,
            rotation:0,
            croppedimage:profileiamge(),
            croppedAreaPixels:null,
            croppedArea:null,
            localbloburl:""

        }
    }
    componentDidMount(){
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) {
			el.classList.remove('active');
		});
		//document.getElementById('0').classList.remove("active");
     if(document.getElementById('0') != null){
		document.getElementById('0').classList.add("active");
    }
        this.loadUser();
		this.documentinit();
    // setTimeout(() => {
    //   document.getElementsByClassName('preview-zone')[0].classList.add("hidden");
    // }, 3000);
    
	}

    historygoback = () => {
        window.history.back();
      };

      loadUser = () =>{
        var userI = Cookies.get('userInfo');// getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        // console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var uname = parsedRes.FirstName;
        var lName = parsedRes.LastName;
        var userId = parsedRes.UserId;
        var compName = parsedRes.CompanyName;
        var phone = parsedRes.phonenumber;
        var address = parsedRes.StreenAddress;
        var picurl = parsedRes.ProfileImg;
        var txtFN = document.getElementById('txtFN'); txtFN.value = uname;
        var txtLN = document.getElementById('txtLN');txtLN.value = lName;
        var txtMN = document.getElementById('txtMN');txtMN.value=phone;
        var txtCN = document.getElementById('txtCN');txtCN.value= (compName != null && compName != 'null') ? compName : "";
        var txtAddress = document.getElementById('txtAddress');
        txtAddress.value=address;
        var Email = document.getElementById('txtemail');
        Email.value = parsedRes.EmailId;
        // var profilepic = document.getElementById('txtAddress');
        // profilepic.src=address;
      }

      customAlertMessage = (icon,message) =>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: false,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: icon,//'success',
                title: message// 'Signed in successfully'
            })
        }
        showoverlay = () => {
            document.getElementById('overlay').style.display = "block";
            $('body').addClass("loader");
        }
        hideoverlay = () => {
            document.getElementById('overlay').style.display = "none";
            $('body').removeClass("loader");
        }

      updateUserInfo = () =>{
        var self = this;
        var txtFN = document.getElementById('txtFN');
        if(txtFN == ""){
            self.customAlertMessage("error", "Please enter firstname.");
            return;
        }
        var txtLN = document.getElementById('txtLN');
        var txtMN = document.getElementById('txtMN');
        var txtCN = document.getElementById('txtCN');
        var txtAddress = document.getElementById('txtAddress');
        //UpdateUserInfo
        var FormData = require('form-data');
        //var fs = require('fs');
        var data = new FormData();
        data.append("FirstName", txtFN.value);
        data.append("LastName", txtLN.value);
        data.append("MobileNumber", txtMN.value);
        data.append("CompanyName", txtCN.value);
        data.append("Address", txtAddress.value);
        data.append("UserId", UserID);

        self.showoverlay();

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateUserInfo",
            //headers: {
            //    'Content-Type': 'application/json'
            //},
            data: data
        };

        axios(config)
            .then(function (resp) {

                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        Cookies.set('access_token', response.Data.UserId);
                        const serializedState = JSON.stringify(responseData);
                        Cookies.set('userInfo', serializedState);
                        self.loadUser();
                        self.customAlertMessage('success', "Updated successfully.");
                    }
                    else {
                      self.customAlertMessage('success', "Updated successfully.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
              self.customAlertMessage('success', "Updated successfully.");
                self.hideoverlay();
            });
      }

      documentinit = () => {
		var self = this;
		// Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) {
		// 	el.classList.remove('active');
		// });
		// document.getElementById('10').classList.add("active");
		$(".dropzone").on("change",function () {
			//var tempfile = $(".dropzone")[0].files[0];
			//if (tempfile.size < 5242880) {
				//self.state.uploadedfile = tempfile;
        // self.setState(
        //   (state) => ({uploadedfile: tempfile}),
        //   () => { console.log(self.state.uploadedfile)}
        //   );
        //self.setState(({uploadedfile: tempfile}), () => { console.log(self.state.uploadedfile)});
				//self.readFile();
			//}
			//else {
			   // self.customAlertMessage('error', 'Maximum file size exceeded.');
			    //return;
			//}
			//self.readFile();
      var tempfile = $(".dropzone")[0].files[0];
			var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
					self.readFile();
				}
			}
			else {
				this.customAlertMessage('error', 'Maximum file size exceeded.');
				return;
			}
		  });

		  $('.dropzone-wrapper').on('dragover', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').addClass('dragover');
		  });

		  $('.dropzone-wrapper').on('dragleave', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').removeClass('dragover');
		  });
		  document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {
			event.preventDefault();
			var tempfile = event.dataTransfer.files[0];
      var validfile = (tempfile.type === 'image/jpg' || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			self.state.uploadedfile = tempfile;
			if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
				self.readFileondrop(event.dataTransfer);
			}
			//if (tempfile.size < 5242880) {
				// self.state.uploadedfile = tempfile;
        //self.setState(({uploadedfile: tempfile}), () => { console.log(self.state.uploadedfile)});
				//self.readFileondrop(event.dataTransfer);
			//}
			//else {
				//self.customAlertMessage('error', 'Maximum file size exceeded.');
				//return;
			//}
		});

		  $('.remove-preview').on('click', function () {
			var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
			var previewZone = $('.remove-preview').parents('.preview-zone');
			//var dropzone = $('.remove-preview').parents('.form-group').find('.dropzone');
			//$('#originalimagediv').hide();
			// boxZone.empty();
			previewZone.addClass('hidden');
			$('.dropzone-wrapper').removeClass('hidden');
      document.getElementsByClassName("dropzone")[0].value = "";
			//self.reset(dropzone);
		  });

		//   $(".filterslist").on('click', function (event) {
		// 	event.stopPropagation();
		// 	event.stopImmediatePropagation();
		// 	//(... rest of your JS code)
		// 	var stylecode = event.target.dataset.stylecode;
		// 	self.applyfilter(self.state.uploadedfile, stylecode);
		// 	$(".filterslist").removeClass("active");
		// 	event.currentTarget.classList.add("active");
		// });
	}
  readiphoneFile = () => {
    var self = this;
    var input = $('.dropzone')[0];
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        self.showoverlayiphone();
        reader.onload = function (e) {                
            fetch(e.target.result)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
        .then((conversionResult) => {
            // conversionResult is a BLOB
            // of the PNG formatted image
            var url = URL.createObjectURL(conversionResult);
            var file = new File([conversionResult],  uuidv4() + ".png");
            self.setState({ processedimage: url,uploadedfile:file,uploadedbase64:url });
            var wrapperZone = $(input).parent();
            var previewZone = $(input).parent().parent().find('.preview-zone');
            wrapperZone.removeClass('dragover');
            previewZone.removeClass('hidden');
            $('.dropzone-wrapper').addClass('hidden');
            self.hideoverlayiphone();
        })
        .catch((e) => {
            // see error handling section
            self.customAlertMessage('error','Error occured, please try again.'); 
            self.hideoverlayiphone();
        });
        };
        reader.readAsDataURL(input.files[0]);
    }
}

readiphoneFileondrop = (input) => {
    var self = this;
    
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        self.showoverlayiphone();
        reader.onload = function (e) {
            fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file,uploadedbase64:url });
                var wrapperZone = $('.dropzone-wrapper');
                var previewZone = $('.preview-zone');                
                wrapperZone.removeClass('dragover');
                previewZone.removeClass('hidden');
                $('.dropzone-wrapper').addClass('hidden');                
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
        };
        reader.readAsDataURL(input.files[0]);
    }
}

showoverlayiphone = () => {
    document.getElementById('overlayiphone').style.display = "block";
    $('body').addClass("loader");
}
hideoverlayiphone = () => {
    document.getElementById('overlayiphone').style.display = "none";
    $('body').removeClass("loader");
}
			readFile = () => {
				var self = this;
				 var input = $('.dropzone')[0];
				if (input.files && input.files[0]) {
				  var reader = new FileReader();
				  reader.onload = function (e) {
					// self.setState({uploadedbase64: e.target.result});
          self.setState(({uploadedbase64: e.target.result}), () => { console.log(self.state.uploadedbase64)});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $(input).parent();
					var previewZone = $(input).parent().parent().find('.preview-zone');
					var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');

					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };

				  reader.readAsDataURL(input.files[0]);
				}
			}

			readFileondrop = (input) => {
				var self = this;
				 //var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();

				  reader.onload = function (e) {
					// self.setState({uploadedbase64: e.target.result});
          self.setState(({uploadedbase64: e.target.result}), () => { console.log(self.state.uploadedbase64)});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					//var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');

					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };

				  reader.readAsDataURL(input.files[0]);
				}
			}
            onCropChange = (crop) => {
                this.setState({ crop })
              }

              onCropComplete = (croppedArea, croppedAreaPixels) => {
                this.setState({croppedArea:croppedArea,croppedAreaPixels:croppedAreaPixels});
              }

              onZoomChange = (zoom) => {
                this.setState({ zoom })
              }

              cropimage = async() => {
                const _croppedImage = await getCroppedImg(this.state.uploadedbase64, this.state.croppedAreaPixels,this.state.rotation);
                // console.log('donee', { _croppedImage })
                document.getElementById("croppedimagehdn").value = _croppedImage;
                console.log("fdsad: "+ document.getElementById("croppedimagehdn").value);
                this.setState({croppedimage:_croppedImage,localbloburl:_croppedImage});
                this.uploadimagetoserver();
              }
              blobToFile = (theBlob, fileName) =>{
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            }
            createFile = async(bloburl) => {
                let response = await fetch(bloburl);
                let data = await response.blob();
                let metadata = {
                  type: 'image/jpeg'
                };
                let file = new File([data], "test.jpg", metadata);
                return file;
                // ... do something with the file or return it
              }
              uploadimagetoserverold = async() => {
                var filepathsrc = document.getElementById("croppedimagehdn").value;// document.getElementsByClassName('reactEasyCrop_Container')[0].getElementsByTagName('img')[0].src; 
                let file = await this.createFile(filepathsrc);//this.state.localbloburl);// new File([blobdata], "test.png", metadata);
                var self = this;
                var axios = require('axios');
                // var fs = require('fs');
                var FormData = require('form-data');
                var data = new FormData();
                data.append('file',file);// this.blobToFile(this.state.croppedimage, "filename"));
                // data.append('file',fs.createReadStream(this.state.croppedimage));
                data.append('uid', UserID);

                var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/Saveprofilepic',
                data : data
                };

                axios(config)
                .then(function (resp) {
                    //console.log(JSON.stringify(response.data));

                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            var responseData = response.Data;
                            document.getElementsByClassName('profile_info')[0].getElementsByTagName('img')[0].src = responseData;
                            self.customAlertMessage('success', 'Profile image updated successfully.');
                            var userI = Cookies.get('userInfo');// getAccessToken();
                            var decodedUser = decodeURIComponent(userI);
                            // console.log(decodedUser);
                            var parsedRes = JSON.parse(decodedUser);
                            parsedRes.ProfileImg = responseData;
                            var serializeddata = JSON.stringify(parsedRes);
                            Cookies.set('userInfo', serializeddata);
                            $('.remove-preview').trigger("click");

                        }
                    }
                    else{
                        self.customAlertMessage('error', 'Something went wrong, Try Again.');
                    }
                })
                .catch(function (error) {
                console.log(error);
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                });

              }

              uploadimagetoserver = () => {
                var filepathsrc = document.getElementById("croppedimagehdn").value;// document.getElementsByClassName('reactEasyCrop_Container')[0].getElementsByTagName('img')[0].src; 
                var self = this;
                self.showoverlay();
                var requestOptions = {
                  method: 'GET',
                  redirect: 'follow'
                };
                
                fetch(filepathsrc, requestOptions)
                  .then(response => response.blob())
                  .then(result => {
                    // console.log(result);
                    let metadata = {
                      type: 'image/jpeg'
                    };
                    let file = new File([result], "test.jpg", metadata);

                    var axios = require('axios');
                    // var fs = require('fs');
                    var FormData = require('form-data');
                    var data = new FormData();
                    data.append('file',file);// this.blobToFile(this.state.croppedimage, "filename"));
                    // data.append('file',fs.createReadStream(this.state.croppedimage));
                    data.append('uid', UserID);
    
                    var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/Saveprofilepic',
                    data : data
                    };
    
                    axios(config)
                    .then(function (resp) {
                        //console.log(JSON.stringify(response.data));    
                        var response = (resp.data);
                        if (response.Status == "200") {
                            if (response.Data != null) {
                                var responseData = response.Data;
                                document.getElementsByClassName('profile_info')[0].getElementsByTagName('img')[0].src = responseData;
                                self.customAlertMessage('success', 'Profile image updated successfully.');
                                var userI = Cookies.get('userInfo');// getAccessToken();
                                var decodedUser = decodeURIComponent(userI);
                                console.log(decodedUser);
                                var parsedRes = JSON.parse(decodedUser);
                                parsedRes.ProfileImg = responseData;
                                var serializeddata = JSON.stringify(parsedRes);
                                Cookies.set('userInfo', serializeddata);
                                self.hideoverlay();
                                $('.remove-preview').trigger("click");
    
                            }
                        }
                        else{
                            self.customAlertMessage('error', 'Something went wrong, Try Again.');
                            self.hideoverlay();
                        }
                    })
                    .catch(function (error) {
                    console.log(error);
                    self.customAlertMessage('error', 'Something went wrong, Try Again.');
                    self.hideoverlay();
                    });
                  })
                  .catch(error => 
                    {
                      console.log('error', error);
                      self.customAlertMessage('error', 'Something went wrong, Try Again.');
                      self.hideoverlay();
                    });
                
                //let file = await this.createFile(filepathsrc);//this.state.localbloburl);// new File([blobdata], "test.png", metadata);
                

              }

    render(){
return (
<div className="main_content_iner overly_inner dashboard-page">
<div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div>
        <div className="container-fluid p-0 ">
          <div className="row">

    

            <div className="col-12">
                <div className="title-card1">
                    <h4>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={this.historygoback}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Profile
                    </span>
                    </h4>
                </div>
            </div>

            <div className='col-12 profile-bottom'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                      <div className='lefside-bar'>
                    <div className='col-12 mb-30'>
                            <label>Image</label>
                            <br />
                            <input type={'hidden'} id="croppedimagehdn"></input>
                            <img src={this.state.croppedimage} id="profilepic" width={150} height={150}></img>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>First Name</label>
                            <input id="txtFN" type="text" className='common-input'/>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>Last Name</label>
                            <input id="txtLN" type="text" className='common-input'/>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>Mobile Phone</label>
                            <input id="txtMN" type="text" className='common-input'/>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>Email</label>
                            <input id="txtemail" type="text" className='common-input' disabled style={{backgroundColor:'Gray'}}/>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>Company Name</label>
                            <input id="txtCN" type="text" className='common-input'/>
                        </div>
                        <div className='col-12 mb-30'>
                            <label>Address</label>
                            <input id="txtAddress" type="text" className='common-input'/>
                        </div> 
                        </div>                      
                    </div>
                    <div className='col-md-8 col-sm-12'>         
                      <div className='rightside-bar'>
                    {/* <h2>Profile Image Update</h2> */}
                    <div className="dropzone-wrapper dropzone-wrapper-new">
                      <h4 className="input-head">
                        <FontAwesomeIcon icon={faImage} />
                        Update Profile Image
                      </h4>
                      <div id="overlayiphone">
                        <div id="loading" style={{ top: "35%" }}>
                          <h2 id="loaderheading">Generating...</h2>
                          <img src={loader} />
                        </div>
                      </div>
                      <div className="dropzone-desc">
                        <h4>Drag and Drop</h4>
                        <p>
                          <b>or Click to upload</b>
                        </p>
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                        <p>Supported Files: PNG, JPG, HEIF, HEIC.</p>
                        <p>Size: up to 5 MB</p>
                      </div>
                      <input
                        type="file"
                        name="img_logo"
                        className="dropzone"
                        accept="image/png, image/jpeg, image/jpg, image/heif, image/heic" 
                      ></input>
                    </div>

                    <div className="preview-zone hidden">
                      <div className="box box-solid">
                        <div className="grey-box">
                          <div className="row">
                            <div
                              className="col-12 top-box"
                              id="originalimagediv"
                            >
                              <div className="row">
                                <div className="col-12">
                                  <span className="float-start">
                                    <h4 className="input-head">
                                      <FontAwesomeIcon icon={faImage} />
                                      Uploaded Profile Image
                                    </h4>
                                  </span>
                                  <span
                                    className="float-end"
                                    tooltip="Remove"
                                    flow="left"
                                  >
                                    <a
                                      href="javascript:;"
                                      className="btn btn-danger btn-xs remove-preview"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </a>
                                  </span>
                                  {/* <div
                                    id="actionitemsdiv"
                                    style={{ display: "none" }}
                                  >
                                    <span
                                      className="float-end"
                                      data-fileurl={this.state.processedimage}
                                      onClick={this.copyToClipboard}
                                      tooltip="Copy Image"
                                      flow="down"
                                    >
                                      <FontAwesomeIcon icon={faCopy} />
                                    </span>
                                    <span
                                      className="float-end"
                                      tooltip="Download"
                                      flow="down"
                                    >
                                      <a
                                        href="javascript:;"
                                        onClick={this.handleDownload}
                                        data-fileurl={this.state.processedimage}
                                      >
                                        <FontAwesomeIcon icon={faCircleDown} />
                                      </a>
                                    </span>
                                  </div> */}

                                  {/* {this.state.copySuccess} */}
                                </div>
                              </div>
                              <div className="row">
                              <div className="col-12 cropdiv">
                                <div className="crop-container">
                                <Cropper
                                    image={this.state.uploadedbase64}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={this.state.aspect}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.onZoomChange}
                                />
                                </div>
       
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>

                    <div className='col-12 mb-30'>
                            {/* <a href='javascript:;' tabIndex={-1} onClick={this.updateUserInfo} className="common-save float-start">Update</a> */}
                            
                        </div>

                </div>
            </div>





          </div>
          <div className='bottom-fixed'>
		      <div className="row">
			      <div className="col-4">
						<div className="text-center">
                        <a href='javascript:;' tabIndex={-1} onClick={this.updateUserInfo} className="common-save">Update</a>
						</div>
				  </div>
                  <div className='col-8'>
                  <div className="text-center">
                  <a href='javascript:;' tabIndex={-1} onClick={this.cropimage} className="common-save">Save Image</a>
                  </div>
                  </div>
			  </div>
		  </div>
        </div>
      </div>
)
}
};
export default Profile;