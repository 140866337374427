import React, { useEffect, useState, useMemo,useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faChevronLeft,
  faMicrophone,
  faInfo,
  faInfoCircle,
  faRotateRight,
  faShield,
  faCloudArrowUp,
  faCircleDown,
  faFile,
  faTimes,
  faCopy,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
// import copy from 'copy-to-clipboard';
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard } from "copy-image-clipboard";
import loader from "../img/sample.gif";
import Avatar from "../img/avatar_new.png";
// import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

import errorimage from '../img/error.png'
import { Companyid, MasterId, Role, UserID } from "../User";
import DID_API from '../worklets/api.json'

const Gptvoice = () => {
    var isInitialload = useRef(true);
    const [history, setHistory] = useState([]);
//   const DID_API = {
//     key: "c3RvcmFnZUBwbGFuZXRyZS51cw:Zq6OfgKjhoUswYqZvySy0",
//     url: "https://api.d-id.com",
//   };
  const RTCPeerConnection = (
    window.RTCPeerConnection ||
    window.webkitRTCPeerConnection ||
    window.mozRTCPeerConnection
  ).bind(window);
  let peerConnection;
  let streamId;
  let sessionId;
  let sessionClientAnswer;

  const customAlertMessage = (icon,message) =>{
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    
    Toast.fire({
        icon: icon,//'success',
        title: message// 'Signed in successfully'
    })
}
const showoverlay = () => {
    document.getElementById('overlay').style.display = "block";
    $('body').addClass("loader");
}
const hideoverlay = () => {
    document.getElementById('overlay').style.display = "none";
    $('body').removeClass("loader");
}
const savegptresponse = (result, totaltoken) => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("prompt", document.getElementById("prompttextbox").value);
    data.append("userid", (Role == 3 ? MasterId : UserID));
    data.append("companyid", Companyid);
    data.append("result", result);
    data.append("token", totaltoken);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  const generateaicontent = () => {
    
    var prompt = document.getElementById('prompttextbox').value;
    if(prompt.trim() == ""){
        customAlertMessage("error","Please enter text.");
        document.getElementById('prompttextbox').focus();
        return;
    }
    showoverlay();
    var tarrinput = history.length > 0 ? history.sort(function(a,b) {return a.id - b.id;})[0].id : 0;
    var jsr = {};
    jsr.id = tarrinput +1;
    jsr.text = prompt.trim();
    jsr.type = "in";
    history.push(jsr);
    var joined = history.sort(function(a,b) {return a.id - b.id;});
    setHistory([...joined]);
    console.log(history);
     var prompttext = prompt.trim();

    var axios = require('axios');
var data = JSON.stringify({
// "model": "text-davinci-003",
"prompt": prompttext.trim().trim(',').trim(),
"temperature": 1,
"max_tokens": 1000
});

var config = {
method: 'post',
url: "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT/completions?api-version=2022-12-01",
headers: { 
  'Content-Type': 'application/json', 
  "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
},
data : data
};

axios(config)
.then(function (resp) {
console.log(JSON.stringify(resp.data));
var response = resp.data;
if (response.choices != null && response.choices.length > 0) {
    var responsetext = response.choices[0].text;
var tarr = history.length > 0 ? history.sort(function(a,b) {return a.id - b.id;})[0].id : 0;
var jsr = {};
jsr.id = tarr +1;
jsr.text = responsetext;
jsr.type = "out";
history.push(jsr);
var joined = history.sort(function(a,b) {return a.id - b.id;});
setHistory([...joined]);
document.getElementById('talk-video').style.display = "block";
document.getElementById('talk-image').style.display = "none";
Talktext(responsetext);
// var joined = self.state.generatedcontent.concat(response.choices[0].text);
// self.setState({ generatedcontent: joined });
try {
    savegptresponse(
      JSON.stringify(resp.data),
      response.usage.total_tokens
    );
  } catch (error) {}
}

hideoverlay();
document.getElementById('prompttextbox').value = "";
setTimeout(() => {
    document.getElementById('gptvoiceinputs').scroll(
        { top: document.getElementById('gptvoiceinputs').scrollHeight, 
        behavior: 'smooth' });
}, 2000);
})
.catch(function (error) {
console.log(error);
hideoverlay();
customAlertMessage('error','Something went wrong, please try again.')
});

}
  const historygoback = () => {
    window.history.back();
  };
  const connectButton = async () => {
    if (peerConnection && peerConnection.connectionState === "connected") {
      return;
    }

    stopAllStreams();
    closePC();

    const sessionResponse = await fetch(`${DID_API.url}/talks/streams`, {
      method: "POST",
      headers: {
        Authorization: `Basic ${DID_API.key}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        source_url: "https://openaiimages.s3.us-west-1.amazonaws.com/avatarhead/avatar_new.png",
      }),
    });

    const {
      id: newStreamId,
      offer,
      ice_servers: iceServers,
      session_id: newSessionId,
    } = await sessionResponse.json();
    streamId = newStreamId;
    sessionId = newSessionId;

    try {
      sessionClientAnswer = await createPeerConnection(offer, iceServers);
    } catch (e) {
      console.log("error during streaming setup", e);
      stopAllStreams();
      closePC();
      return;
    }

    const sdpResponse = await fetch(
      `${DID_API.url}/talks/streams/${streamId}/sdp`,
      {
        method: "POST",
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answer: sessionClientAnswer,
          session_id: sessionId,
        }),
      }
    );
  };
  const Talktext = async (inputextfeed) => {
    // connectionState not supported in firefox
    var text = "this is testing ChatGPT output";// e.target;
    if(peerConnection == undefined){
        await connectButton();
    }
    document.getElementById('stopdiv').style.display = "block";
    if (
      peerConnection?.signalingState === "stable" ||
      peerConnection?.iceConnectionState === "connected"
    ) {
      const talkResponse = await fetch(
        `${DID_API.url}/talks/streams/${streamId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${DID_API.key}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            script: {
                "type": "text",
                "input": inputextfeed
            },
            driver_url: "bank://lively/",
            config: {
              stitch: true,
            },
            session_id: sessionId,
          }),
        }
      );
    }
  };
  const Talk = async (e) => {
    // connectionState not supported in firefox
var text = "this is testing ChatGPT output";// e.target;
    if (
      peerConnection?.signalingState === "stable" ||
      peerConnection?.iceConnectionState === "connected"
    ) {
      const talkResponse = await fetch(
        `${DID_API.url}/talks/streams/${streamId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Basic ${DID_API.key}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            script: {
              type: "audio",
              audio_url:
                "https://d-id-public-bucket.s3.us-west-2.amazonaws.com/webrtc.mp3",
            },
            driver_url: "bank://lively/",
            config: {
              stitch: true,
            },
            session_id: sessionId,
          }),
        }
      );
    }
  };


  const destroytalk = async () => {
    await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${DID_API.key}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ session_id: sessionId }),
    });

    stopAllStreams();
    closePC();
  };

  useEffect(() => {
    if (isInitialload.current) {
        const talkVideo = document.getElementById("talk-video");
        talkVideo.setAttribute("playsinline", "");
        connectButton();
        isInitialload.current = false;
    }
   
    console.log("useEffect history: "+ history.length);
    // const peerStatusLabel = document.getElementById('peer-status-label');
    // const iceStatusLabel = document.getElementById('ice-status-label');
    // const iceGatheringStatusLabel = document.getElementById('ice-gathering-status-label');
    // const signalingStatusLabel = document.getElementById('signaling-status-label');

    // const connectButton = document.getElementById("connect-button");
    // connectButton.onclick = async () => {
    //   if (peerConnection && peerConnection.connectionState === "connected") {
    //     return;
    //   }

    //   stopAllStreams();
    //   closePC();

    //   const sessionResponse = await fetch(`${DID_API.url}/talks/streams`, {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Basic ${DID_API.key}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       source_url:
    //         "https://d-id-public-bucket.s3.amazonaws.com/or-roman.jpg",
    //     }),
    //   });

    //   const {
    //     id: newStreamId,
    //     offer,
    //     ice_servers: iceServers,
    //     session_id: newSessionId,
    //   } = await sessionResponse.json();
    //   streamId = newStreamId;
    //   sessionId = newSessionId;

    //   try {
    //     sessionClientAnswer = await createPeerConnection(offer, iceServers);
    //   } catch (e) {
    //     console.log("error during streaming setup", e);
    //     stopAllStreams();
    //     closePC();
    //     return;
    //   }

    //   const sdpResponse = await fetch(
    //     `${DID_API.url}/talks/streams/${streamId}/sdp`,
    //     {
    //       method: "POST",
    //       headers: {
    //         Authorization: `Basic ${DID_API.key}`,
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         answer: sessionClientAnswer,
    //         session_id: sessionId,
    //       }),
    //     }
    //   );
    // };

    //const talkButton = document.getElementById("talk-button");
    // talkButton.onclick = async () => {
    //   // connectionState not supported in firefox
    //   if (
    //     peerConnection?.signalingState === "stable" ||
    //     peerConnection?.iceConnectionState === "connected"
    //   ) {
    //     const talkResponse = await fetch(
    //       `${DID_API.url}/talks/streams/${streamId}`,
    //       {
    //         method: "POST",
    //         headers: {
    //           Authorization: `Basic ${DID_API.key}`,
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify({
    //           script: {
    //             type: "audio",
    //             audio_url:
    //               "https://d-id-public-bucket.s3.us-west-2.amazonaws.com/webrtc.mp3",
    //           },
    //           driver_url: "bank://lively/",
    //           config: {
    //             stitch: true,
    //           },
    //           session_id: sessionId,
    //         }),
    //       }
    //     );
    //   }
    // };

    //const destroyButton = document.getElementById("destroy-button");
    // destroyButton.onclick = async () => {
    //   await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
    //     method: "DELETE",
    //     headers: {
    //       Authorization: `Basic ${DID_API.key}`,
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ session_id: sessionId }),
    //   });

    //   stopAllStreams();
    //   closePC();
    // };
  },[history]);

  const onIceGatheringStateChange = () => {
    return;
    const iceGatheringStatusLabel = document.getElementById(
      "ice-gathering-status-label"
    );
    iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
    iceGatheringStatusLabel.className =
      "iceGatheringState-" + peerConnection.iceGatheringState;
  };
  const onIceCandidate = (event) => {
    // console.log("onIceCandidate", event);
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

      fetch(`${DID_API.url}/talks/streams/${streamId}/ice`, {
        method: "POST",
        headers: {
          Authorization: `Basic ${DID_API.key}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionId,
        }),
      });
    }
  };
  const onIceConnectionStateChange = () => {
    // const iceStatusLabel = document.getElementById("ice-status-label");
    // iceStatusLabel.innerText = peerConnection.iceConnectionState;
    // iceStatusLabel.className =
    //   "iceConnectionState-" + peerConnection.iceConnectionState;
    if (
      peerConnection.iceConnectionState === "failed" ||
      peerConnection.iceConnectionState === "closed"
    ) {
      stopAllStreams();
      closePC();
    }
  };
  const onConnectionStateChange = () => {
    return;
    // not supported in firefox
    const peerStatusLabel = document.getElementById("peer-status-label");
    peerStatusLabel.innerText = peerConnection.connectionState;
    peerStatusLabel.className =
      "peerConnectionState-" + peerConnection.connectionState;
  };
  const onSignalingStateChange = () => {
    // const signalingStatusLabel = document.getElementById(
    //   "signaling-status-label"
    // );
    // signalingStatusLabel.innerText = peerConnection.signalingState;
    // signalingStatusLabel.className =
    //   "signalingState-" + peerConnection.signalingState;
  };
  const onTrack = (event) => {
    const remoteStream = event.streams[0];
    setVideoElement(remoteStream);
  };
  const createPeerConnection = async (offer, iceServers) => {
    if (!peerConnection) {
      peerConnection = new RTCPeerConnection({ iceServers });
      peerConnection.addEventListener(
        "icegatheringstatechange",
        onIceGatheringStateChange,
        true
      );
      peerConnection.addEventListener("icecandidate", onIceCandidate, true);
      peerConnection.addEventListener(
        "iceconnectionstatechange",
        onIceConnectionStateChange,
        true
      );
      peerConnection.addEventListener(
        "connectionstatechange",
        onConnectionStateChange,
        true
      );
      peerConnection.addEventListener(
        "signalingstatechange",
        onSignalingStateChange,
        true
      );
      peerConnection.addEventListener("track", onTrack, true);
    }

    await peerConnection.setRemoteDescription(offer);
    console.log("set remote sdp OK");

    const sessionClientAnswer = await peerConnection.createAnswer();
    console.log("create local sdp OK");

    await peerConnection.setLocalDescription(sessionClientAnswer);
    console.log("set local sdp OK");

    return sessionClientAnswer;
  };

  const setVideoElement = (stream) => {
    if (!stream) return;
    const talkVideo = document.getElementById("talk-video");
    talkVideo.srcObject = stream;

    // safari hotfix
    if (talkVideo.paused) {
      talkVideo
        .play()
        .then((_) => {})
        .catch((e) => {});
    }
  };

  const stopAllStreams = () => {
    const talkVideo = document.getElementById("talk-video");
    if (talkVideo.srcObject) {
      console.log("stopping video streams");
      talkVideo.srcObject.getTracks().forEach((track) => track.stop());
      talkVideo.srcObject = null;
    }
    document.getElementById('stopdiv').style.display = "none";
  };

  const closePC = (pc = peerConnection) => {
    if (!pc) return;
    console.log("stopping peer connection");
    pc.close();
    pc.removeEventListener(
      "icegatheringstatechange",
      onIceGatheringStateChange,
      true
    );
    pc.removeEventListener("icecandidate", onIceCandidate, true);
    pc.removeEventListener(
      "iceconnectionstatechange",
      onIceConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "connectionstatechange",
      onConnectionStateChange,
      true
    );
    pc.removeEventListener(
      "signalingstatechange",
      onSignalingStateChange,
      true
    );
    pc.removeEventListener("track", onTrack, true);
    // const peerStatusLabel = document.getElementById("peer-status-label");
    // const iceStatusLabel = document.getElementById("ice-status-label");
    // const iceGatheringStatusLabel = document.getElementById(
    //   "ice-gathering-status-label"
    // );
    // const signalingStatusLabel = document.getElementById(
    //   "signaling-status-label"
    // );
    // iceGatheringStatusLabel.innerText = "";
    // signalingStatusLabel.innerText = "";
    // iceStatusLabel.innerText = "";
    // peerStatusLabel.innerText = "";
    console.log("stopped peer connection");
    if (pc === peerConnection) {
      peerConnection = null;
    }
  };



  return (
    <>
      <div className="main_content_iner overly_inner">
        <div className="container-fluid p-0 ">
          <div className="row">
            <div className="col-12">
              <div className="title-card1">
                <span className="float-start">
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={historygoback}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      Voice Chat
                      <sup
                        style={{
                          color: "#599bdb",
                          fontSize: "16px",
                          marginLeft: "5px",
                        }}
                      >
                        Beta
                      </sup>
                    </span>
                  </h4>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
         
            <div className="col-4">
              <div className="lefside-bar">
                {/* <!-- added "id=video-wrapper" --> */}
                <div id="video-wrapper">
                <video
                      id="talk-video" style={{display:'none'}}                   
                      autoPlay={true}                      
                    ></video>
                    <center>
                        <img id="talk-image" src={Avatar} style={{width:'220px',height:'220px',objectFit:'fill',borderRadius:'50%'}}></img>
                    </center>                    
                </div>
                <div id="stopdiv" style={{display:'none'}}>
                  <center>
<a href="javascript:;" className="common-save pad-reduce" onClick={stopAllStreams}>
                        Stop
                      </a>
                  </center>
                  
                </div>
              </div>
            </div>
            <div className="col-8" style={{border:'1px dashed #9333ea',padding:'10px', position:"relative"}}>   
            <div id="overlay" className="email-overlay">
                <div id="loading">
                    <h2 id="loaderheading">Generating...</h2>
                    <img src={loader} />
                </div>
            </div>    
              <div className="rightside-bar">
                <div id="gptvoiceinputs">
                {history.length > 0
                              ? history.map((d) =>
                                  d.type == "in" ? (
                <div class="counterclass container-input">
                  <div class="arrow arrow-left">
                    <div class="outer"></div>
                    <div class="inner"></div>
                  </div>
                  <div class="message-body" style={{ marginBottom: "5px" }}>
                    <p>{d.text}</p>
                  </div>
                </div>
                                  ) : (
                                    <div class="counterclass container-input container-input-left">
                                    <div class="arrow">
                                      <div class="outer"></div>
                                      <div class="inner"></div>
                                    </div>
                                    <div class="message-body" style={{ marginBottom: "5px" }}>
                                      <p>{d.text}</p>
                                    </div>
                                  </div>
                                  )
                                  )
                                  : null}
         
                </div>
                
              </div>
              
            </div>
          </div>
          <div className="bottom-fixed">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center">
                      <input
                        type={"text"}
                        className="generateinput"
                        id="prompttextbox"
                        placeholder="Ask me anything.."
                      ></input>
                      <a href="javascript:;" className="common-save pad-reduce" onClick={generateaicontent}>
                        Generate
                      </a>
                      <a
                        href="javascript:;"
                        className="common-clear pad-reduce"
                      >
                        Clear
                      </a>
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </>
  );
};

export default Gptvoice;
