import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faCircleDown,
  faDollarSign,
  faChevronLeft,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import roomgptimage from "../img/13.jpg";
import {
  UserID,
  EulaValue,
  Features,
  Role,
  TextToImageSub,
  ImageTransformSub,
  MasterId,
  PlanType,
  Companyid,
} from "../User";
import * as axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import fileDownload from "js-file-download";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

export const getAccessToken = () => Cookies.get("userInfo");

const crazylist = [
  "AI can be like Box of Chocolates - you never know what you will get",
  "AI is a Pandora's box. What am I gonna see now?",
  "Machines can be dumber than humans but they work hard. So sit back and let them work.",
  "Did you know with AI 1+1 can be 4…. Just kidding. Not!",
  "If you find something funny laugh it, find something mediocre try again, find something spectacular grab it.",
  "AI never repeats same images, find something good, grab it or keep trying.",
  "Crazy, Stupid, or Spectacular - you may get any of these from AI Juke box.",
  "Dumb, Dumber, or Ultra Smart - AI can render one of these or shades in between",
  "Four Images or a Funeral - keep trying and have fun till you find what you like",
  "Build it and they will come - Field of AI dreams",
  "AI can be like a Raffle Draw - you never know what will come out!",
  "AI can be like a Magic Trick - you never know what will happen next!",
  "AI is like a Jack-in-the-box - you never know when it'll pop up!",
  "AI is a Pandora's box. Will I get to see what's inside?",
  "AI is a Pandora's box. Is it battle of the bots time?",
  "Did you know with AI, 1 + 1 can be a pizza?? Just kidding. Or am I...?",
  "Laugh at the funny, pass on the meh, snatch up the stellar!",
  "LOL at the funnies, skip the so-so's, go for gold on the greats!",
  "Chuckle at the humorous, take a pass on the moderate, seize the sublime!",
  "AI one-of-a-kinds--snag a gem or keep searching!",
  "AI doesn't repeat--find your fortune or keep fishing!",
  "AI no doubles--grab a pic or keep hunting!",
  "AI Jukebox: Pick between Unexpected, Daffy, or Magnificent!",
  "AI Jukebox: Grab one of these - Whimiscal, Wacky, or Flawless!",
  "AI Jukebox: Choose from Outlandish, Zany, or Unbelievable!",
  "AI can be Dumb, Dumber, or Brainiac - confusing, right?",
  "Brains vs Bots: AI can be any level from Dumb to Ultra Smart",
  "Choose Smartness Level: AI, from Doofus to Genius - you decide!",
  "Picture Search & Perish - Enjoy the Process!",
  "Frame It or Farewell - Have Fun in the Journey!",
  "Construct it and they shall congregate - AI Oasis of false hopes",
  "Fix it up and they shall appear - Promised Land of AI Dreams",
  "Forge it and they will manifest - AI Land of Unrealistic Dreams",
];
const crazylistone = [
  "Love it, Love it not, Love it …. That’s AI.",
  "Keep spinning the wheel  of AI and see the possibilities",
  "You may laugh sometimes, but many times get astounded by what AI is capable of",
  "Share funny outputs with a friend on social media and have a great laugh",
  "About to experience the funny side of AI",
  "Experience great, outrageous, or ugly. That’s AI",
  "AI puts trees outside your high rise condo? Yup! Now you have seen it all.",
  "If you see a barbecue pit put by AI in the “backyard” of your high rise condo, don’t go to Costco to buy steaks.",
  "Have you seen an all-American backyard outside your high rise? Don’t worry, AI will put it there! ?",
  "Love it, Love it not, Love it …. AI's here to stay!",
  "Spin the AI wheel and explore the potential outcomes that arise!",
  "Keep turning the AI wheel to discover the different prospects it reveals.",
  "Keep rotating the wheel of AI and observe the options it generates.",
  "Sometimes you chuckle, but you will usually be surprised by the abilities of AI.",
  "AI can often astound you, even if you find yourself laughing at times.",
  "AI has the capability to amaze you, even though you may be amused at times.",
  "Show your buddy a humorous post on social media and have a grand giggle!",
  "Post a funny creation to social media and crack up alongside your friend!",
  "Spread some witty content with a mate over social media and have a roaring good time!",
  "Preparing for the mirthful AI expedition!",
  "Brace yourself – the funniest AI is almost here!",
  "Ready to embark on the hilarious AI adventure?",
  "AI for an extraordinary, ridiculous, or horrendous experience.",
  "Enjoy a marvelous, crazy, or dreadful adventure with AI.",
  "Get out of your comfort zone with AI and experience the wild, wacky, and crazy.",
  "AI puts trees outside your high rise condo? Believe it or not!",
  "AI is here to bring you an all-American backyard - even outside your high rise.",
];
const loadermessages = [
  "Just a moment, AI is brewing up something special for you.",
  "Hold on tight, AI is in the process of creating the images.",
  "Be patient, AI is on the case to make your dream spaces.",
  "Please bear with AI while it conjures up the perfect space.",
  "Keep calm and let AI do its thing while AI handles your request.",
  "Sit tight, AI is cooking up something awesome for you.",
  "Hold your horses, AI is busy crafting a personalized space.",
  "Stay tuned, AI is fine-tuning your request for maximum awesomeness.",
  "Just a little longer, AI is diligently working on the image design. ",
  "Hang in there, AI is working hard to create your dream space.",
  "Bear with AI, AI is crafting something extraordinary for you.",
  "Don't fret, AI is navigating the complexities of your request.",
  "Your request is in good hands, AI is making progress.",
  "Rest assured, AI is working its magic.",
  "Hang on, AI is concocting something exquisite for you!",
  "Take a moment, AI is concocting something marvelous for you!",
  "Hold up, AI is preparing something extraordinary for you!",
  "Hang on, AI is gettin' busy craftin' up some visual masterpieces!",
  "Ready your grip - AI's about to start painting with pixels!",
  "Brace yourselves - AI's constructing a digital gallery!",
  "Rest easy, AI has your back to create your wildest visions!",
  "Perk up, AI is ready to jumpstart your utopia vision!",
  "Keep chillin', AI is furiously working on your dreamscape!",
  "Hang in there while AI builds the perfect area!",
  "Hold tight while AI cooks up the ideal space!",
  "No need to stress - AI will handle this for you!",
  "Leave it to AI - it got this in the bag!",
  "Hold on - AI is concocting something incredible for you.",
  "Hold tight, AI is stirring up something marvelous for you!",
  "Buckle up, AI is whipping up something spectacular for you.",
  "Ease up, AI's spirit is devotedly devising a tailor-made cosmos.",
  "Hold up, AI is fervently formulating a personalized space!",
  "Hang tight, AI is hard at work cobbling together a unique cosmos.",
  "Buckle up, AI's polishing your query for superior epicness.",
  "Hold tight, AI's optimizing your demand for extreme superbness.",
  "Get ready, AI's tweaking your wish for total greatness.",
  "AI is sticking with it -- nearly done with that image design!",
  "AI is toiling away on the image design, almost done!",
  "Keep the faith, AI is toiling assiduously to construct your desired space.",
  "Don't give up, AI is ceaselessly laboring to make your desired space.",
  "Hold on, AI is laboring hard to fabricate your ideal living space.",
  "Show patience while AI is creating something exceptional for you.",
  "No need to panic, AI is tackling the nuances of your request.",
  "Have no fear - AI is handling the complexities of your request.",
  "Don't stress, AI is managing the intricacies of your request.",
  "AI is chipping away at fulfilling your request.",
  "AI is diligently working to achieve your request.",
  "Believe us, AI is doing its magic.",
  "Have faith, AI is accomplishing its sorcery.",
  "Rely on it, AI is working its magic.",
];
const loadermessagesone = [
  "AI is on it! Your request has AI’s full attention.",
  "AI is in the lab, brewing up the perfect space for you.",
  "Stay put, AI is hammering out the details of the spaces.",
  "Hang tight, AI is working its magic on your envisioned space.",
  "Hang tight while AI spruce up the space design!",
  "AI is getting creative with your space design - stay tuned!",
  "Please wait, while AI gives your space design some extra pizzazz.",
  "Hold on while AI brings your space designs to life!",
  "Sit tight while AI perfects your space designs!",
  "AI is giving your room a makeover - almost there!",
  "AI is giving your space the upgrade it deserves - just a bit longer!",
  "AI got some amazing space designs in store for you - please wait!",
  "AI is creating something special - stay tuned while the magic happens!",
  "Be patient, AI is making your space look fabulous!",
  "Wear your sunglasses. You are going to be bedazzled.",
  "Where are your shades? You may need them soon.",
  "AI has your request in its sights!",
  "AI is taking action on your request.",
  "AI is on board - your request is top priority for AI.",
  "AI has concocted the ideal space in the lab.",
  "AI is in the laboratory, developing the perfect space for you.",
  "Don't move an inch, AI is smashing out the specifics of the chambers.",
  "Camp out, AI is clobbering out the facts of the gaps.",
  "Hold on, AI is bringing the magic to your envisioned space.",
  "Don't let go, AI is transforming your envisioned space like a wizard.",
  "Keep holding, AI is casting a spell on your envisioned space.",
  "Stick around and watch AI turn this joint into a swanky spot!",
  "Keep your cool while AI works its wizardry on the interior design!",
  "AI is gearing up to give your space a wild new look - hang tight!",
  "Get ready for awe-inspiring decor ideas - AI has taken the reins!",
  "Be patient while AI adds some flair to your space design!",
  "Just a sec while AI jazzes up your space design!",
  "Wait a minute while AI gives your space design some extra sizzle!",
  "Get ready for lift-off as AI brings your stellar concepts to life!",
  "Brace yourself as AI revives your intergalactic decor!",
  "Wait patiently while AI turns your architectural dreams into a reality!",
  "Buckle up while AI blasts off with your space designs!",
  "Button up while AI takes your space designs to new heights!",
  "Brace yourself while AI takes your space designs to the next level!",
  "AI is refreshing your space - nearly finished!",
  "AI is sprucing up your space - almost complete!",
  "AI will level up your area - simply have a bit of patience!",
  "AI is sending your area into the future - just a bit more hangin'!",
  "AI is giving your place the makeover it deserves - just a tad bit further!",
  "AI's cosmic collection is wanna-see worthy - hold tight!",
  "AI's happening out of this world - stay tuned!",
  "AI's storing stellar items - be on standby!",
  "AI is working on a surprise for your celestial abode - almost done!",
  "AI is crafting something extra-special for your orbit - nearly finished!",
  "AI is working some magic for your area - almost there!",
  "Take a chill pill, AI is ensuring your place looks smashing!",
  "Relax, AI is transforming your quarters into a thing of beauty!",
  "Hang tight, AI is giving your space a whole new stunning vibe!",
  "Put on your shades and prepare to be dazzled!",
  "Want to be amazed? Slip on your sunglasses.",
  "You better dust off your shades! They may soon be called for.",
  "Break out those sunnies of yours! You might be needing them soon.",
];

const finalmessagearr = [
  "Just a little more time, AI is putting the final pieces together.",
  "Almost there! Your image spaces are almost ready.",
  "AI appreciates your patience as it works on your request.",
  "Hold on a tad longer, AI is almost ready to dazzle you.",
  "Take a deep breath, AI is putting the finishing touches on your request.",
  "AI is putting the final touches on your space designs - please wait!",
  "AI is creating something special for your space - almost done!",
  "AI is crafting your space design masterpiece - just a minute!",
  "Hold on while AI makes your space designs shine!",
  "AI is making your dream space a reality - just a moment!",
  "In the nick of time, AI is arranging the last bits and bobs together.",
  "Not long now, AI is carefully snapping the last bits into place.",
  "Only a notch more time, AI is dovetailing the final fragments together.",
  "Keep calm and carry on, your image spaces are almost ready!",
  "Get ready for launch! Your imaginations are almost complete!",
  "Hold on tight! Your picture paradises are almost primed!",
  "AI thanks you for being so chill as it struggles to fulfill your wish!",
  "Thanks for having AI's back - AI is trying its hardest on your task!",
  "AI is stoked you have the patience for it to sort out your order.",
  "Stop tapping your foot, AI is almost prepared to wow you!",
  "Don't give up now, AI is about to boggle your mind!",
  "Inhale deeply, AI has almost perfected your request.",
  "Breathe in slowly, AI is completing your request.",
  "Relax and let the AI take care of your request, take a deep breath.",
  "AI is spiffing up your space designs - hang tight!",
  "AI is jazzing up your space designs - stand by!",
  "AI has something up its sleeve - get ready for a surprise!",
  "Buckle up for a wild ride - AI has something brewing!",
  "AI is concocting something special - anticipate the unexpected!",
  "AI is inventing your interstellar design sensation - hold on tight!",
  "AI is beavering away on your stellar design gem - it won't take long!",
  "AI is spinning out your cosmic design work of art - stand by!",
  "Let AI make your space designs sparkle as you wait!",
  "AI is making your wildest space dreams come true - boom!",
  "AI is turning your space into a dream come true - quick now!",
];

function Items({ currentItems }) {
  const [listtopopulaate, setlisttopopulaate] = useState([]);
  const [showproducts, setshowproducts] = useState(false);
  const [oriinalimageforproduct, setoriinalimageforproduct] = useState("");
  const [productdatafromvision, setproductdatafromvision] = useState([]);
  const [loadermessages, setloadermessages] = useState([]);
  const checkEulaStatus = () => {
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser);
    var internalEulaValue = parsedRes.IsEULAUpdated;

    // console.log(internalEulaValue);
    // console.log(EulaValue);
    if (internalEulaValue == false) {
      document.getElementById("hrefEula").click();
    }
  };

  const customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };

  const showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  const showoverlaytwo = () => {
    document.getElementById("overlaytwo").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlaytwo = () => {
    document.getElementById("overlaytwo").style.display = "none";
    $("body").removeClass("loader");
  };

  const checkgrammarly = () => {
    const axios = require("axios");
    var self = this;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        "https://api.aelo.ai/api/ArtificialIntelligence/Getgrammarlykey?userid=" +
        (Role == 3 ? MasterId : UserID),
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data != null && response.data.Data != "") {
          Cookies.set("g_key", response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDownload = (e) => {
    var self = this;
    var url = e.currentTarget.dataset.fileurl;
    showoverlaytwo();
    const axios = require("axios");
    const FormData = require("form-data");
    let data = new FormData();
    data.append("imgurl", url);
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    //console.log(decodedUser);
    var parsedRes = JSON.parse(decodedUser);
    var lPType = parseInt(parsedRes.PlanType);
    if (lPType == 2) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://ai.chocolatechips.ai/watermarkchecker",
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data != undefined) {
            if (response.data.output == true) {
              hideoverlaytwo();
              customAlertMessage(
                "error",
                "Watermarks detected, the download is not allowed."
              );
            } else {
              document.getElementById("hiddenhref").href = url;
              document.getElementById("hiddenhref").click();
              self.hideoverlaytwo();
              // var axiosint = require('axios');
              // axiosint.get(url, {
              //     responseType: 'blob',
              // }).then((res) => {
              //     var filename = uuidv4() + ".png";
              //     hideoverlaytwo();
              //     fileDownload(res.data, filename);
              //     hideoverlaytwo();
              // })
            }
          }
        })
        .catch((error) => {
          console.log(error);
          hideoverlaytwo();
        });
    } else {
      var axiosint = require("axios");
      axiosint
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          var filename = uuidv4() + ".png";
          hideoverlaytwo();
          fileDownload(res.data, filename);
          hideoverlaytwo();
        });
    }
  };

  const searchimage = (e) => {
    // console.log('search clicked');
    var url = e.currentTarget.dataset.fileurl;
    getproductmask(url);
  };
  const searchimagefromuploadedfile = (e) => {
    // var url = e.currentTarget.dataset.fileurl;
    getproductmaskfromimage();
  };
  const getproductmask = (productsourceimageurl) => {
    const axios = require("axios");
    const FormData = require("form-data");

    let data = new FormData();
    data.append("imgurl", productsourceimageurl);
    data.append("dirname", uuidv4());

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://ai.chocolatechips.ai/getobjectList",
      data: data,
    };
    showoverlay();
    axios
      .request(config)
      .then((response) => {
        classifyimage(response.data);
        hideoverlay();
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        hideoverlay();
      });
  };

  const classifyimage = (coordinatedata) => {
    var hassofa = false;
    var sofaobject = {};
    var listtopopulaatetemp = [];
    let imgheight = coordinatedata["inputImgHeight"];
    let imgwidth = coordinatedata["inputImgWidth"];
    if (imgheight == undefined) {
      imgheight = 510;
    }
    for (let i = 0; i < coordinatedata.Result.length; i++) {
      const row = coordinatedata.Result[i];
      let xaxis = row["x_centroid"];
      let yaxis = row["y_centroid"];
      var jsr = {};
      jsr.id = i;
      jsr.x_axis = calcperc(xaxis, imgwidth) + "%";
      jsr.y_axis = calcperc(yaxis, imgheight) + "%";
      jsr.dname = row.MaskName;
      jsr.maskimg = row.MaskUrl;
      listtopopulaatetemp.push(jsr);
      if (row.MaskName.toLowerCase().indexOf("sofa") != -1) {
        hassofa = true;
        sofaobject = jsr;
      }
    }
    setlisttopopulaate(listtopopulaatetemp);
    setoriinalimageforproduct(coordinatedata["orignalImg"]);
    setshowproducts(true);
    document.getElementsByClassName("pagination")[0].style.display = "none";
    // document.getElementById('showtitle').style.display="none";
    document.getElementById("btndiv").style.display = "none";
    if (hassofa == true) {
      getproductcatalog(sofaobject.dname, sofaobject.maskimg);
    }
  };

  const calcperc = (partialValue, totalValue) => {
    return (100 * partialValue) / totalValue;
  };

  const getproductmaskfromimage = () => {
    const axios = require("axios");
    const FormData = require("form-data");
    var productsourceimage = $(".dropzone")[0].files[0];
    let data = new FormData();
    data.append("img", productsourceimage);
    data.append("dirname", uuidv4());

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://ai.chocolatechips.ai/getobjectListOrgImg",
      data: data,
    };
    showoverlaysearchoriginal();
    axios
      .request(config)
      .then((response) => {
        classifyimage(response.data);
        hideoverlaysearchoriginal();
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        hideoverlaysearchoriginal();
      });
  };

  const showoverlaysearch = () => {
    if (ismobile()) {
      showoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearch").style.display = "block";
      $("body").addClass("loader");
    }
  };
  const hideoverlaysearch = () => {
    if (ismobile()) {
      hideoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearch").style.display = "none";
      $("body").removeClass("loader");
    }
  };

  const hideshowproducts = () => {
    setlisttopopulaate([]);
    setoriinalimageforproduct("");
    setshowproducts(false);
    document.getElementsByClassName("pagination")[0].style.display = "flex";
    // document.getElementById('showtitle').style.display="block";
    document.getElementById("btndiv").style.display = "block";
  };

  const getproductcatalog = (maskname, clipimageurl) => {
    console.log("clicked product");
    // var maskname = e.currentTarget.dataset.maskname;
    // var clipimageurl = e.currentTarget.dataset.clipimageurl;
    const axios = require("axios");
    const FormData = require("form-data");
    let data = new FormData();
    data.append("clipimageurl", clipimageurl);
    data.append("maskname", maskname);
    data.append("uid", UserID);
    data.append("companyid", Companyid);
    showoverlaysearchone();
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://uatapi.aelo.ai/api/ArtificialIntelligence/searchproduct",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        var productdata = response.data.Data;
        setproductdatafromvision(productdata);
        hideoverlaysearchone();
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        hideoverlaysearchone();
      });
  };
  const getproducts = (e) => {
    console.log("clicked product");
    var maskname = e.currentTarget.dataset.maskname;
    var clipimageurl = e.currentTarget.dataset.clipimageurl;
    getproductcatalog(maskname, clipimageurl);
  };
  const handlemouseentermask = (e) => {
    var identitytarget = e.currentTarget.dataset.objtgt;
    const elements = document.querySelectorAll(".result-mask img");
    elements.forEach((element) => {
      if (element.id == "mask" + identitytarget) {
        element.style.display = "block"; //.classList.add('highlight');
      } else {
        element.style.display = "none";
      }
    });
  };

  const handlemouseleavemask = (e) => {
    const elements = document.querySelectorAll(".result-mask img");
    elements.forEach((element) => {
      element.style.display = "none";
    });
    //elements.forEach((element) => { element.classList.remove('highlight-off'); });
  };

  const showoverlaysearchone = () => {
    if (ismobile()) {
      showoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearchone").style.display = "block";
      $("body").addClass("loader");
    }
  };
  const hideoverlaysearchone = () => {
    if (ismobile()) {
      hideoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearchone").style.display = "none";
      $("body").removeClass("loader");
    }
  };
  const showoverlaysearchmobiledevice = () => {
    document.getElementById("mobileoverlaysearch").style.display = "block";
    $("body").addClass("loader");
    $("html").addClass("loader");
    // loadermessagesrandom();
  };
  const hideoverlaysearchmobiledevice = () => {
    document.getElementById("mobileoverlaysearch").style.display = "none";
    $("body").removeClass("loader");
    $("html").removeClass("loader");
  };
  const loadermessagesrandom = () => {
    var message = [];
    message.push(crazylist[Math.floor(Math.random() * 33)]);
    var ddd = Array.from({ length: 40 }, () => Math.floor(Math.random() * 50));
    var duplicateremoved = ddd
      .filter((item, index) => ddd.indexOf(item) === index)
      .slice(0, 2);
    for (let i = 0; i < 2; i++) {
      const element =
        loadermessages[duplicateremoved[i]] == null
          ? loadermessages[0]
          : loadermessages[duplicateremoved[i]];
      message.push(element);
    }
    message.push(crazylistone[Math.floor(Math.random() * 27)]);
    var dddd = Array.from({ length: 40 }, () => Math.floor(Math.random() * 57));
    var duplicateremovedd = dddd
      .filter((item, index) => dddd.indexOf(item) === index)
      .slice(0, 2);
    for (let i = 0; i < 2; i++) {
      const element =
        loadermessagesone[duplicateremovedd[i]] == null
          ? loadermessages[0]
          : loadermessages[duplicateremovedd[i]];
      message.push(element);
    }
    message.push(finalmessagearr[Math.floor(Math.random() * 35)]);
    setloadermessages(message);
  };

  const showoverlaysearchoriginal = () => {
    if (ismobile()) {
      showoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearchoriginal").style.display = "block";
      $("body").addClass("loader");
    }
  };
  const hideoverlaysearchoriginal = () => {
    if (ismobile()) {
      hideoverlaysearchmobiledevice();
    } else {
      document.getElementById("overlaysearchoriginal").style.display = "none";
      $("body").removeClass("loader");
    }
  };

  return (
    <>
      <div
        id="itemsmainpanel"
        style={{ display: showproducts == false ? "block" : "none" }}
      >
        <div style={{ display: "none" }}>
          <a href="javascript:;" id="hiddenhref" download={true}>
            hidden
          </a>
        </div>
        {currentItems &&
          currentItems.map((d) => (
            <div className="row justify-content-center dashboard-border">
              <div className="col-md-4 col-sm-12 border-left-new">
                <div className="image-box imgcarouselbox" style={{ position: "relative" }}>
                  <h4>Original Room</h4>
                  <img src={d.InputImg} alt="Room Decor" />
                  {
                  Features.googlecatalog == true ?
                  <span
                    className="float-end tooltip1 download-dashboard"
                    tooltip="Shop"
                    flow="right"
                    style={{ top: "60px", left: "10px" }}
                  >
                    <a
                      href="javascript:;"
                      style={{ color: "#fff" }}
                      data-fileurl={d.InputImg}
                      onClick={searchimage}
                    >
                      <FontAwesomeIcon icon={faDollarSign} />
                    </a>
                  </span>
                  :null
}
                  <span
                    className="float-end tooltip1 tooltip4 download-dashboard"
                    tooltip={d.prompt}
                    flow="right"
                    style={{ top: "60px", left: "10px", zIndex: "1" }}
                  >
                    <a href="javascript:;" style={{ color: "#fff" }}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </span>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 border-right-new">
                <div className="image-box imgcarouselbox">
                  <h4>Generated Room</h4>
                  {/* <img src={roomgptimage} alt="Room Decor"/> */}
                  <Carousel key={uuidv4()}>
                    {d.output.result && d.output.result.length > 0
                      ? d.output.result.map((o) => (
                          <>
                            <div>
                              <img src={o} alt="Room Decor" />
                              {
                              Features.googlecatalog == true ?
                              <span
                                className="float-end tooltip1 download-dashboard"
                                tooltip="Shop"
                                flow="right"
                                style={{ left: "55px" }}
                              >
                                <a
                                  href="javascript:;"
                                  data-fileurl={o}
                                  onClick={searchimage}
                                  style={{ color: "#fff" }}
                                >
                                  <FontAwesomeIcon icon={faDollarSign} />
                                </a>
                              </span>
                              :null
}
                              <span
                                className="float-end tooltip1 download-dashboard"
                                tooltip="Download"
                                flow="right"
                                style={{ left: "10px" }}
                              >
                                {PlanType < 2 ? (
                                  <a
                                    id="download"
                                    data-fileurl={o}
                                    href={o}
                                    download={true}
                                  >
                                    <FontAwesomeIcon icon={faCircleDown} />
                                  </a>
                                ) : (
                                  <a
                                    id="download"
                                    data-fileurl={o}
                                    onClick={handleDownload}
                                  >
                                    <FontAwesomeIcon icon={faCircleDown} />
                                  </a>
                                )}
                                {/* <a id="download" data-fileurl={o} onClick={handleDownload}><FontAwesomeIcon icon={faCircleDown} /></a> */}
                              </span>
                            </div>
                          </>
                        ))
                      : null}
                  </Carousel>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        className="row"
        id="productpanel"
        style={{ display: showproducts == true ? "flex" : "none" }}
      >
        <div className="col-12">
          <div className="title-card1">
            <span className="float-start">
              <h4>
                <span style={{ cursor: "pointer" }} onClick={hideshowproducts}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                  Back
                </span>
              </h4>
            </span>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div>
                <p className="alert alert-info">
                  <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                  &nbsp;&nbsp; Please click on any object to search for similar
                  items in our catalog.
                </p>
              </div>
              <div className="gallery-results">
                <div className="result-image">
                  {/* <img src="https://demo.restb.ai/images/demo/demo-1.jpg" /> */}
                  <img src={oriinalimageforproduct} />
                </div>
                <div className="result-mask">
                  {listtopopulaate.length > 0
                    ? listtopopulaate.map((d) => (
                        <img
                          src={d.maskimg}
                          id={"mask" + d.id}
                          style={{ display: "none" }}
                        ></img>
                      ))
                    : null}
                </div>
                {listtopopulaate.length > 0
                  ? listtopopulaate.map((d) => (
                      <div
                        className="result-tag"
                        id={d.id}
                        style={{
                          top: d.y_axis != "" ? d.y_axis : "0%",
                          left: d.x_axis != "" ? d.x_axis : "0%",
                        }}
                        onMouseEnter={handlemouseentermask}
                        onMouseLeave={handlemouseleavemask}
                        onClick={getproducts}
                        data-objtgt={d.id}
                        data-maskname={d.dname}
                        data-clipimageurl={d.maskimg}
                      >
                        <label>{d.dname}</label>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{ display: "block", position: "relative" }}
            >
              <div id="overlaysearchone">
                <div id="loading" style={{ top: "35%" }}>
                  <img src={loader} />
                  <h2 id="loaderheading">
                    <span>Searching...</span>
                  </h2>
                </div>
              </div>
              <div className="product-catalog">
                {productdatafromvision.length > 0 ? (
                  productdatafromvision.map((d) => (
                    <div className="item-wrapper">
                      <a
                        href={d.productUrl}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <img src={d.imgUrl} />
                        {/* <p>{d.score}</p> */}
                        <h4>{d.vendor}</h4>
                        <h4>
                          <b>{d.price}</b>
                        </h4>
                      </a>
                    </div>
                  ))
                ) : (
                  <p style={{ color: "#fff" }}>No Results</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PaginatedItems() {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [dashboarddata, Setdashboarddata] = useState([]);

  const itemsPerPage = 3;
  const [labsSec, setLabsSectionData] = useState(false);
  const [plantype, setplantype] = useState(0);

  const setLabsSection = () => {
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser);
    var internalLab = parsedRes.Features.Lab;
    console.log(internalLab);
    setLabsSectionData(internalLab);
  };
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = dashboarddata.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(dashboarddata.length / itemsPerPage);
  useEffect(() => {
    loaddashboarddata();
    setLabsSection();
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser);
    setplantype(parseInt(parsedRes.PlanType));
  }, []);
  const loaddashboarddata = () => {
    const axios = require("axios");

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetuserDashBoardData?UserId=" + UserID,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));

        Setdashboarddata(response.data.Data);
        // setState({dashboarddata:response.data.Data});
        // console.log(response.data.Data);
        //console.log(response.data.Data.length);
        // if(response.data.Data !=undefined && response.data.Data.length > 0)
        // {
        //     document.getElementById('showtitle').style.display="none";
        // }
        // else
        // {
        //     document.getElementById('showtitle').style.display="block";
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dashboarddata.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  const checkEulaStatus = () => {
    var userI = getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    var parsedRes = JSON.parse(decodedUser);
    var internalEulaValue = parsedRes.IsEULAUpdated;

    // console.log(internalEulaValue);
    // console.log(EulaValue);
    if (internalEulaValue == false) {
      document.getElementById("hrefEula").click();
    }
  };

  const customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };

  const showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  const showoverlaytwo = () => {
    document.getElementById("overlaytwo").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlaytwo = () => {
    document.getElementById("overlaytwo").style.display = "none";
    $("body").removeClass("loader");
  };

  const updateEulaStatus = () => {
    var chkIAgreeEula = document.getElementById("chkIAgreeEula");
    if (!chkIAgreeEula.checked) {
      customAlertMessage(
        "error",
        "Scroll to end of agreement, select 'I Agree' checkbox and click on 'Proceed'"
      );
      return;
    }
    //CreateTeam(string TName,string UserId)
    showoverlay();
    var config = {
      method: "post",
      url:
        "https://api.aelo.ai/api/AIUserManager/InsertUserEULA?userid=" + UserID,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (resp) {
        var response = resp.data;
        if (response.Status == "200") {
          document.getElementById("btnCloseEula").click();
          var userI = getAccessToken();
          var decodedUser = decodeURIComponent(userI);
          var parsedRes = JSON.parse(decodedUser);
          parsedRes.IsEULAUpdated = true;
          const serializedState1 = JSON.stringify(parsedRes);
          Cookies.set("userInfo", serializedState1);
        } else {
          customAlertMessage("error", response.Message); // 'success','failure','info'
        }
        hideoverlay();
      })
      .catch(function (error) {
        customAlertMessage("error", "Something went wrong, Try Again.");
        hideoverlay();
      });
  };

  return (
    <>
      <div className="main_content_iner overly_inner">
        <div className="container p-0 ">
          <div className="row" id="mainpanel">
            <div id="overlay">
              <div id="loading">
                <h2 id="loaderheading">Loading...</h2>
                <img src={loader} />
              </div>
            </div>
            <div id="mobileoverlaysearch">
              <div id="loading" style={{ top: "35%" }}>
                <img src={loader} />
                <h2 id="loaderheading">
                  <span>Searching...</span>
                </h2>
              </div>
            </div>
            <div id="overlaysearch">
              <div id="loading" style={{ top: "35%" }}>
                <img src={loader} />
                <h2 id="loaderheading">
                  <span>Searching...</span>
                </h2>
              </div>
            </div>
            <div className="col-12">
              <div className="row justify-content-center">
                <div
                  className="col-12"
                  id="showtitle"
                  style={{ display: "none" }}
                >
                  <h2 className="room-title background-gradient">
                    You have no Spaces Generated
                  </h2>
                  <h4 className="room-title1">
                    Click Generate below to start.
                  </h4>
                </div>
                <div className="col-12" id="btndiv">
                  <center>
                    <Link to="/ccaelo" className="btn-grad">
                      Generate
                    </Link>
                    {/* <br/>
                            <Link to="/aelo" className='btn-grad'>
                                New Aelo
                            </Link> */}
                  </center>
                </div>
                <div id="RoomGPT" className="col-md-12 col-sm-12">
                  <div id="overlaytwo" style={{ display: "none" }}>
                    <div id="loading" style={{ top: "47%" }}>
                      <h2 id="loaderheading">Generating...</h2>
                      <img src={loader} />
                    </div>
                  </div>
                  <Items currentItems={currentItems} />
                  <ReactPaginate
                    id="reactpagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <a
          href="javascript:;"
          id="hrefEula"
          data-bs-toggle="modal"
          data-bs-target="#eulamodal"
          className="text-count"
          style={{
            backgroundColor: "#f44336",
            color: "#fff",
            display: "none",
          }}
        ></a>
      </div>
      <div
        className="modal fade"
        id="eulamodal"
        tabIndex={-1}
        aria-labelledby="listeningModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
            <div className="modal-header">
              <h5 className="modal-title" id="listeningModalLabel">
                <b>AI Disclosure:</b>
              </h5>
              <button
                style={{ display: "none" }}
                id="btnCloseEula"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
                maxHeight: "420px",
                overflowY: "auto",
                paddingBottom: "15px",
              }}
            >
              <div className="col-12">
                <p>
                  We expect you to use AI (ChatGPT and image generation tools)
                  AS IS.
                </p>
                <p>
                  <b>Be aware of the limits of ChatGPT:</b>
                </p>
                <ul>
                  <li>
                    If you provide minimum effort prompts, you will get low
                    quality results. You will need to refine your prompts in
                    order to get good outcomes. This will take work.
                  </li>
                  <li>
                    Don't trust anything it says. If it gives you a number or
                    fact, assume it is wrong unless you either know the answer
                    or can check in with another source. You will be responsible
                    for any errors or omissions provided by the tool. It works
                    best for topics you understand.
                  </li>
                  <li>
                    AI is a tool, but one that you need to acknowledge using.
                  </li>
                  <li>
                    Be thoughtful about when this tool is useful. Don't use it
                    if it isn't appropriate for the case or circumstance.
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer">
              <label>
                <input
                  id="chkIAgreeEula"
                  type="checkbox"
                  className="agreecheck"
                />
                <b style={{ paddingLeft: "10px" }}>I Agree</b>
              </label>
              <button
                type="button"
                style={{ display: "none" }}
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={updateEulaStatus}
                className="btn btn-primary"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* {
    (labsSec) ?

        <Link to="/processimage" className='labs-btn'>Labs</Link>
    : null

} */}
    </>
  );
}
export default PaginatedItems;

export const ismobile = () => {
  return window.screen.width <= 600;
};
