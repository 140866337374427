import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { json, Link } from "react-router-dom";
import React, { useState } from "react";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import moment from "moment";
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizeddetailslist from "./sherlockunauthorizeddetailslist";
import queryString from "query-string";
import $ from 'jquery';
import loader from "../img/sample.gif";
import Swal from 'sweetalert2'

export const ismobile =()=>{
  return window.screen.width <= 600;
} 

class Sherlockunauthorizeddetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyworddisabled: true,
      tonedisabled: true,
      copySuccess: "",
      wordcount: 0,
      Tonecount: 0,
      generatedimages: [],
      emailguid: NIL_UUID,
      emailhtml: "",
      defaultcontenterased: false,
      tabledata: [],
    };
  }
  componentDidMount() {
    this.jquerycode();
  }

  showloader=()=>{
    if (ismobile()) {
      this.showoverlaymobile();            
    }
    else{
        document.getElementById('overlaypopup').style.display = "block";
        $('body').addClass("loader");
    }
  }
  hideloader=()=>{
    if (ismobile()) {
      this.hideoverlaymobile();
    }
    else{
        document.getElementById('overlaypopup').style.display = "none";
        $('body').removeClass("loader");
    }
  }
  showoverlaymobile = () => {
    document.getElementById('overlaypopupmobile').style.display = "block";
    $('body').addClass("loader");
    $('html').addClass("loader");
    // loadermessagesrandom();
  }
  hideoverlaymobile = () => {
      document.getElementById('overlaypopupmobile').style.display = "none";
      $('body').removeClass("loader");
      $('html').removeClass("loader");
  }
  jquerycode = () => {
    var self = this;
    Array.from(
      document.getElementById("sidebar_menu").querySelectorAll("li")
    ).forEach(function (el) {
      el.classList.remove("active");
    });
    document.getElementById("1").classList.add("active");
  };

  historygoback = () => {
    window.history.back();
  };
  customAlertMessage(icon, message) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  }
  shareviaemail=(e)=>{
    var type = e.currentTarget.dataset.emailtype;
    var querystringdomainname = queryString.parse(window.location.search).id == undefined
    ? ""
    : queryString.parse(window.location.search).id;
    var domain = querystringdomainname;
    var subject = "";
    var body = document.getElementById('emailbody').value;
    if (body.trim() == "") {
      this.customAlertMessage('error','Please enter email body.');
      return;
    }
    var to = document.getElementById('emailto').value;
    if (to.trim() == "") {
      this.customAlertMessage('error','Please add email address to send.');
      return;
    }
    var cc = document.getElementById('emailcc').value;
    var bcc = document.getElementById('emailbcc').value;
    const formdata = new FormData();
    formdata.append("subject", "Sherlock Unauthorized Domain "+ domain+" CSV Report");
    formdata.append("message", body);
    formdata.append("to", to);
    formdata.append("cc", cc);
    formdata.append("bcc", bcc);
    formdata.append("type", type);
    formdata.append("domain", domain);
    formdata.append("uid", " 3173894c-7b72-4d76-864a-97c835f318de");
    formdata.append("companyid", " 151");
    formdata.append("mlsname", "");
    formdata.append("timezone",Intl.DateTimeFormat().resolvedOptions().timeZone);

    this.showloader();
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    var fetchurl = `${process.env.REACT_APP_API_URL}` + "/api/ArtificialIntelligence/sharedetailsviaemail";
    //var fetchurl = "https://localhost:44372/api/ArtificialIntelligence/sharedetailsviaemail";
    fetch(fetchurl, requestOptions)
      .then((response) => response.text())
      .then((result) =>{
        if (JSON.parse(result).Status == "200") {
          document.getElementById('emailclose').click();
          this.customAlertMessage('success','Report successfully sent.');
        }
        else{
          this.customAlertMessage('error','Something went wrong, please try again.');
        }
        this.hideloader();
      })
      .catch((error) =>{ 
        console.error(error);
        this.hideloader();
      });
  }
  render() {
    return (
      <>
        <div className="main_content_iner overly_inner">
          <div className="container-fluid p-0 ">
            <div className="row">
              <div className="col-12">
                <div className="title-card1">
                  <span className="float-start">
                    <h4>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.historygoback}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Back
                      </span>
                    </h4>
                  </span>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <Sherlockunauthorizeddetailslist></Sherlockunauthorizeddetailslist>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade shareemailui"
          id="shareemail"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
            <div id="overlaypopup">
                <div id="loading" style={{ top: "35%" }}>                                                        
                    <img src={loader} />
                    <h2 id="loaderheading"><span>Processing...</span></h2>
                </div>
            </div> 
            <div id="overlaypopupmobile">
                <div id="loading" style={{ top: "35%" }}>                                                        
                    <img src={loader} />
                    <h2 id="loaderheading"><span>Processing...</span></h2>
                </div>
            </div> 
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                Email Unauthorized Domains
                </h5>
                <button id="emailclose"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="container">
                <div className="row">
                <div className="col-12">
                    <label>To:</label>
                    <input id="emailto" type="email" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>CC:</label>
                    <input id="emailcc" type="email" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>BCC:</label>
                    <input id="emailbcc" type="email" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>Message:</label>
                    <textarea id="emailbody" placeholder="Enter Message"></textarea>
                </div>
                <div className="col-12">
                    <center>
                        <a id="btnshareemail" href="javascript:;" tabindex="-1" class="common-save" data-emaildomain="" data-emailtype="4" onClick={this.shareviaemail}>Send</a>
                    </center>
                </div>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Sherlockunauthorizeddetails;
