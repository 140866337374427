import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, { useState } from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader, { List } from "react-content-loader";
import * as axios from 'axios';
import Cookies from 'js-cookie';


// const MyLoader = () => <List />
const MyLoader = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

const MyLoader1 = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

class addwhitelisteddomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyworddisabled: true,
            tonedisabled: true,
            copySuccess: '',
            wordcount: 0,
            Tonecount: 0,
            generatedimages: [],
            emailguid: NIL_UUID,
            emailhtml: "",
            defaultcontenterased: false,
            tabledata: [],
            dashtotalcount: -1,
            dashrecentimagescount: -1,
            dashauthorizedcount: -1,
            dashunauthorizedcount: -1,
            dashlastupdated: ""


        }
    }
    componentDidMount() {
        //this.loadtabledata();
        // this.setState({emailguid: uuidv4()});
        this.jquerycode();
        //this.gettoppaneldata();
        // setInterval(() => {			
        // 	this.checkandupdatecontent();
        //   }, 10000);
    }


    jquerycode = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('1').classList.add("active");
    }


    historygoback = () => {
        window.history.back();
    }

    gettoppaneldata = () => {
        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` + '/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.Status == "200") {
                    var data = response.data.Data;
                    this.setState({
                        dashtotalcount: data.Totalcount,
                        dashrecentimagescount: data.Recentimagescount,
                        dashauthorizedcount: data.Authorizedcount,
                        dashunauthorizedcount: data.Unauthorizedcount,
                        dashlastupdated: new Date(data.Lastupdated).toString()
                    })
                }
                else {

                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }

    addWhitelistDomainToDB = () =>{
        var self = this;
        var txtDomainName = document.getElementById('txtDomainName');
        // if(txtDomainName.value == ""){
        //     self.customAlertMessage("error", "Please enter domain name.");
        //     return;
        // }
        if (!this.isValidDomain(txtDomainName.value)) {
            self.customAlertMessage("error", "Please enter valid domain name.");
            return;
        }
        var userI = Cookies.get('userInfo');// getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        // console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var compId = parsedRes.CompanyId;
        var FormData = require('form-data');
        //var fs = require('fs');
        var domainval = txtDomainName.value;
        debugger;
        if (domainval.indexOf("https://") != -1 || domainval.indexOf("http://") != -1) {
            domainval = new URL(domainval).host;
        }
        var data = new FormData();
        data.append("Domain", domainval);
        data.append("CompanyId", compId);

        self.showoverlay();

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/AddWhiteListDomain",
            //headers: {
            //    'Content-Type': 'application/json'
            //},
            data: data
        };

        axios(config)
            .then(function (resp) {

                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        if (responseData == "true") {
                            txtDomainName.value="";
                            self.customAlertMessage('success', "Added successfully.");
                        } else {
                            self.customAlertMessage('error', "Failed.");
                        }                        
                    }
                    else {
                        self.customAlertMessage('error', "Failed.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
              self.customAlertMessage('error', "Something went wrong.");
                self.hideoverlay();
            });
}
customAlertMessage = (icon,message) =>{
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,//'success',
        title: message// 'Signed in successfully'
    })
}
isValidDomain=(domain)=> {
    // Regular expression to match a valid domain name
    var regex = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,}$/;
    return regex.test(domain);
}
    render() {
        return (
            <>
            <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div>
            
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4><span style={{ cursor: "pointer" }} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} /><u>Sherlock Setup:</u> Add Whitelisted domain</span></h4>                                    
                                </span>
                            </div>
                        </div>

                        <div className='col-6' style={{ marginBottom: "20px" }}>
                            <div className='row'>                            
                                <div className='col-12 mb-30'>
                                    <label for="basic-url" class="form-label">Domain Name</label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon3">https://</span>
                                        <input type="text" class="form-control" id="txtDomainName" aria-describedby="basic-addon3" />
                                    </div>
                                </div>
                                <div className='col-12 mb-30 text-center'>
                                    <a href="javascript:;" onClick={this.historygoback} className="common-save common-save-grey" style={{marginRight:"10px"}}>Cancel</a>
                                    <a href="javascript:;" onClick={this.addWhitelistDomainToDB} className="common-save">Add</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
            </>
        )
    }

}

export default addwhitelisteddomain;