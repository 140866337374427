import React from 'react';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "../img/sample.gif";
import $ from 'jquery';
import { ElementsConsumer, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import CardSection from "../CardSection";
import * as axios from 'axios';

export const getAccessToken = () => Cookies.get('userInfo')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

class CheckOutUpdateCard extends React.Component {

    componentDidMount(){
        this.getSetupIntentKey();
    }

    getSetupIntentKey = () =>{
        var self = this;
        var config = {
            method: 'get',
            "url": `${process.env.REACT_APP_API_URL}` + "/api/AIUserManager/GetSetUpIntentSecret"  ,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null && response.Data !="") {
                        document.getElementById('hfSetUpIntentUC').value = response.Data;
                    }
                    else {
                        self.customAlertMessage('error', "No setup intent created.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    handleSubmitNew = async event =>{
        event.preventDefault();
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        var setupSecret = document.getElementById('hfSetUpIntentUC').value;
        if (setupSecret !="") {            
            this.showoverlay();
            const card = elements.getElement(CardNumberElement);
            const result = await stripe.handleCardSetup(
                setupSecret, 
                card
            );
            if (result.error) {
                this.hideoverlay();
                console.log(result.error.message);
                this.customAlertMessage('error', result.error.message);
                return;
            } else {      
                this.hideoverlay();      
                console.log(result.setupIntent);
                // create customer in ajax and navigate to dashboard
                this.createAccountSubscription(result.setupIntent.payment_method);
            }
        } 
        else {
            
        }        
    }

    handleSubmit = async event => {
        event.preventDefault();

        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            console.log(result.error.message);
            this.customAlertMessage('failure', result.error.message);
            return;
        } else {            
            console.log(result.token.id);
            // create customer in ajax and navigate to dashboard
           this.createAccountSubscription(result.token.id);
        }
    };

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }
    myFunc = () => {

    }
    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    createAccountSubscription = (tokenId) => {
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var uname = parsedRes.FirstName;
        var userId = parsedRes.UserId;
        var emailId = parsedRes.EmailId
        var phone = parsedRes.phonenumber;
        // var planType = parsedRes.PlanType;
        // var planLevel = parsedRes.PlanLevel;

        var self = this;
        this.showoverlay(); 
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateUserCard?Name=" + uname + "&EmailAddress=" + emailId + "&Phone=" + phone + "&Token="
                + tokenId + "&UserId=" + userId,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var pId = response.Data;
                        parsedRes.PaymentCustomerId = pId;
                        const serializedState2 = JSON.stringify(parsedRes);
                        Cookies.set('userInfo', serializedState2); 
                        //window.location.href = "/paymentupdate";
                        self.customAlertMessage('success','Updated successfully.')
                        setTimeout(() => {
                            $('#lnkPaymentUpdateNav')[0].click();
                        }, 2000);
                        
                    }
                    else {
                        self.customAlertMessage('error', "Card authorization failed. "+response.Message);
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    render() {
        return (
            <div className="form-container1">
                <input type='hidden' id='hfSetUpIntentUC'></input>
                <div className="product-info loginstyle">                    
                    {/* <h2>Update Card</h2>                     */}
                </div>
                <form onSubmit={this.handleSubmitNew}>
                    <CardSection />
                    <button disabled={!this.props.stripe} className="btn-pay">
                        Authorize
                     </button>
                     <img src='https://cdn.brandfolder.io/KGT2DTA4/at/g65qkq94m43qc3c9fqnhh3m/Powered_by_Stripe_-_black.svg' className='stripe-logo'/>
                </form>
            </div>
        );
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckOutUpdateCard stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}