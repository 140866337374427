import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React from 'react';
import {UserID,PaymentCustomerId} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import moment from 'moment';

export const getAccessToken = () => Cookies.get('userInfo');

class UpgradePlan extends React.Component {

    componentDidMount(){

        var userINew = getAccessToken();
        var decodedUserNew = decodeURIComponent(userINew);
        //console.log(decodedUserNew);
        var parsedResNew = JSON.parse(decodedUserNew);
        var uname = parsedResNew.FirstName;
        var userId = parsedResNew.UserId;
        var emailId = parsedResNew.EmailId;
        var phone = parsedResNew.phonenumber;

        var planTypeCurrent = parsedResNew.PlanType;
        var planLevelCurrent = parsedResNew.PlanLevel;
        var totalWordsCurrent = parsedResNew.TotalWords;

        var planLevelName = "";
        switch (parseInt(planLevelCurrent)) {
            case 1:
                var planLevelName = "Base";
                break;
            case 2:
                var planLevelName = "Premium";
                break;
            case 3:
                var planLevelName = "Premium 2";
                break;
            case 4:
                var planLevelName = "Premium 3";
                break;            
            case 5:
                var planLevelName = "Premium 4";
                break;
            case 6:
                var planLevelName = "Premium 5";
                break;
            case 7:
                var planLevelName = "Premium 6";
                break;
            case 8:
                var planLevelName = "Premium 7";
                break;
            default:
                //custom
                planLevelName = "";
                break;
        }

        var h4CurrentPlan = document.getElementById("h4CurrentPlan");
        if(planTypeCurrent == "0"){
            h4CurrentPlan.innerHTML = "Current Plan - Trial";
        }
        else if(planTypeCurrent == "1"){
            h4CurrentPlan.innerHTML = "Current Plan - Single User - "+ planLevelName;
            document.getElementById("singleuserslider").value = planLevelCurrent;
            this.sliderchangedOnLoad(planLevelCurrent);   
            document.getElementById('divsingleStartButton').style.cursor="not-allowed";
            document.getElementById('asingleUserPlanSectionUpdate').style.pointerEvents="none";   
            document.getElementById("hfCurrentPlanLevel").value = "1"+planLevelCurrent;
            document.getElementById('divTUPlan').classList.remove('activeSubscriptionUser');  
            document.getElementById('divSUPlan').classList.remove('activeSubscriptionUser');
            document.getElementById('divSUPlan').classList.add('activeSubscriptionUser');   
        }
        else if(planTypeCurrent == "2"){
            h4CurrentPlan.innerHTML = "Current Plan - Team - "+ planLevelName;
            document.getElementById("teamuserslider").value=planLevelCurrent;
            this.sliderchangedteamuserOnLoad(planLevelCurrent);              
            document.getElementById('divPremiumStartButton').style.cursor="not-allowed";
            document.getElementById('aPremiumUserPlanSectionUpdate').style.pointerEvents="none";
            //document.getElementById("asingleUserPlanSectionUpdate").style.display="none";
            document.getElementById("hfCurrentPlanLevel").value = "2"+planLevelCurrent;
            document.getElementById('divSUPlan').classList.remove('activeSubscriptionUser');  
            document.getElementById('divTUPlan').classList.remove('activeSubscriptionUser');
            document.getElementById('divTUPlan').classList.add('activeSubscriptionUser');   
        }

        let yearlyPlans= false;        
        const DISCOUNT = 0.25; 
        let slider = document.getElementById("singleuserslider");
        let price = document.getElementById("singleuserprice");
        let pageviews = document.getElementById("singleuserpageviews");
        
        // let toggle = document.getElementById("toggle");
        let text = document.getElementById("singleuserperiod");        
        
        var prices = [29, 59,75,99,149,179,269,349];
        //var prices = [29, 59,75];
        
        pageviews.innerHTML = "20K";
        
        // function discount() {
        //   text.innerHTML = "";
        
        //   if (yearlyPlans) {
        //     text.innerHTML = "year";
        //     for (let i = 0; i < prices.length; i++) {
        //       prices[i] = prices[i] - prices[i] * DISCOUNT;
        //     }
        //     listener();
        //   } else { 
        //     text.innerHTML = "month";
        //     prices = [29, 59];
        //     listener();
        //   }
        // }
        
        // var listener = () => {
        //   window.requestAnimationFrame( () => {
        //     switch (slider.value) {
        //       case "1":
        //         price.innerHTML = Number(prices[0]);
        //         pageviews.innerHTML = "22K";
        //         break;
        //       case "2":
        //         price.innerHTML = Number(prices[1]);
        //         pageviews.innerHTML = "37K";
        //         break;
        //     //   case "3":
        //     //     price.innerHTML = Number(prices[2]);
        //     //     pageviews.innerHTML = "63K";
        //     //     break;
        //     //   case "4":
        //     //     price.innerHTML = Number(prices[3]);
        //     //     pageviews.innerHTML = "79K";
        //     //     break;
        //     }        
        //   });
        // };
        
        // slider.addEventListener("mousedown", () => {
        //   listener();
        //   slider.addEventListener("mousemove", listener);
        // });
        // slider.addEventListener("mouseup", () => {
        //   slider.removeEventListener("mousemove", listener);
        // });
        
        // slider.addEventListener("keydown", listener);
        
        // slider.oninput = () => {
        //   var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
        //   slider.style.background =
        //   "linear-gradient(to right, #3368fa 0%, #3368fa " +
        //   value +
        //   "%, #eaeefb " +
        //   value +
        //   "%, #eaeefb 100%)";
        // };     

        //
        let teamuserslider = document.getElementById("teamuserslider");
        let teamuserprice = document.getElementById("teamuserprice");
        let teamuserpageviews = document.getElementById("teamuserpageviews");
        
        // let toggle = document.getElementById("toggle");
        let teamusertext = document.getElementById("teamuserperiod");        
        
        var teamuserprices = [345,495,745,995];
        
        teamuserpageviews.innerHTML = "80K";
        
        // function discount() {
        //     teamusertext.innerHTML = "";
        
        //   if (yearlyPlans) {
        //     teamusertext.innerHTML = "year";
        //     for (let i = 0; i < teamuserprices.length; i++) {
        //         teamuserprices[i] = teamuserprices[i] - teamuserprices[i] * DISCOUNT;
        //     }
        //     teamuserlistener();
        //   } else {
        //     teamusertext.innerHTML = "month";
        //     teamuserprices = [65, 99];
        //     teamuserlistener();
        //   }
        // }
        
        // var teamuserlistener = function () {
        //   window.requestAnimationFrame(function () {
        //     switch (teamuserslider.value) {
        //       case "1":
        //         teamuserprice.innerHTML = Number(teamuserprices[0]);
        //         teamuserpageviews.innerHTML = "55k";
        //         break;
        //       case "2":
        //         teamuserprice.innerHTML = Number(teamuserprices[1]);
        //         teamuserpageviews.innerHTML = "108k";
        //         break;
        //     //   case "3":
        //     //     teamuserprice.innerHTML = Number(teamuserprices[2]);
        //     //     teamuserpageviews.innerHTML = "63K";
        //     //     break;
        //     //   case "4":
        //     //     teamuserprice.innerHTML = Number(teamuserprices[3]);
        //     //     teamuserpageviews.innerHTML = "79K";
        //     //     break;
        //     }        
        //   });
        // };
        
        // teamuserslider.addEventListener("mousedown", function () {
        //     teamuserlistener();
        //   teamuserslider.addEventListener("mousemove", teamuserlistener);
        // });
        // teamuserslider.addEventListener("mouseup", function () {
        //     teamuserslider.removeEventListener("mousemove", teamuserlistener);
        // });
        
        // teamuserslider.addEventListener("keydown", teamuserlistener);
        
        // teamuserslider.oninput = function () {
        //   var value = (this.value - this.min) / (this.max - this.min) * 100;
        //   this.style.background =
        //   "linear-gradient(to right, #3368fa 0%, #3368fa " +
        //   value +
        //   "%, #eaeefb " +
        //   value +
        //   "%, #eaeefb 100%)";
        // }; 
        
    };
    historygoback = () => {
        window.history.back();
    };

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
            document.getElementById('overlay').style.display = "none";
            $('body').removeClass("loader");
    }
    customAlertMessage = (icon,message) =>{
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    sliderchanged = (e) => {
        let slider = document.getElementById("singleuserslider");
        let price = document.getElementById("singleuserprice");
        let pageviews = document.getElementById("singleuserpageviews");
        var slidval = e.target.value;
        var hfCurrentPlanLevel = document.getElementById("hfCurrentPlanLevel").value;
        if(hfCurrentPlanLevel != "1"+slidval){
            document.getElementById('asingleUserPlanSectionUpdate').style.pointerEvents="auto";
            document.getElementById('divsingleStartButton').style.cursor="pointer";
        } 
        else{
            document.getElementById('divsingleStartButton').style.cursor="not-allowed";
            document.getElementById('asingleUserPlanSectionUpdate').style.pointerEvents="none";
        }
        var prices = [29, 59,75,99,149,179,269,349];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            //console.log(slidval);
            document.getElementById('premiumfeaturesul').style.display = 'block';
            var spanSBP = document.getElementById('spanSBP');
          switch (slidval) {
            case "0":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "20K";
              document.getElementById('premiumfeaturesul').style.display = 'none';
              spanSBP.innerHTML = "Base";
              break;
            case "1":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "20K";
              document.getElementById('premiumfeaturesul').style.display = 'none';
              spanSBP.innerHTML = "Base";
              break;
            case "2":
              price.innerHTML = Number(prices[1]);
              pageviews.innerHTML = "40K";
              spanSBP.innerHTML = "Premium";
              break;
            case "3":
            price.innerHTML = Number(prices[2]);
              pageviews.innerHTML = "80K";
              spanSBP.innerHTML = "Premium 2";
              break;
            case "4":
              price.innerHTML = Number(prices[3]);
              pageviews.innerHTML = "100K";
              spanSBP.innerHTML = "Premium 3";
              break;
              case "5":
              price.innerHTML = Number(prices[4]);
              pageviews.innerHTML = "120K";
              spanSBP.innerHTML = "Premium 4";
              break;
              case "6":
              price.innerHTML = Number(prices[5]);
              pageviews.innerHTML = "160K";
              spanSBP.innerHTML = "Premium 5";
              break;
              case "7":
              price.innerHTML = Number(prices[6]);
              pageviews.innerHTML = "250K";
              spanSBP.innerHTML = "Premium 6";
              break;
              case "8":
              price.innerHTML = Number(prices[7]);
              pageviews.innerHTML = "350K";
              spanSBP.innerHTML = "Premium 7";
              break;
          }        
        });
    }

    sliderchangedOnLoad = (eVal) => {
        let slider = document.getElementById("singleuserslider");
        let price = document.getElementById("singleuserprice");
        let pageviews = document.getElementById("singleuserpageviews");
        var slidval = eVal;
        var prices = [29, 59,75,99,149,179,269,349];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            //console.log(slidval);
            document.getElementById('premiumfeaturesul').style.display = 'block';
            var spanSBP = document.getElementById('spanSBP');
          switch (slidval.toString()) {
            case "0":
                price.innerHTML = Number(prices[0]);
                pageviews.innerHTML = "20K";
                document.getElementById('premiumfeaturesul').style.display = 'none';
                spanSBP.innerHTML="Base";
                break;
            case "1":
                price.innerHTML = Number(prices[0]);
                pageviews.innerHTML = "20K";
                document.getElementById('premiumfeaturesul').style.display = 'none';
                spanSBP.innerHTML="Base";
                break;
              case "2":
                price.innerHTML = Number(prices[1]);
                pageviews.innerHTML = "40K";
                spanSBP.innerHTML="Premium";
                break;
              case "3":
              price.innerHTML = Number(prices[2]);
                pageviews.innerHTML = "80K";
                spanSBP.innerHTML="Premium 1";
                break;
              case "4":
                price.innerHTML = Number(prices[3]);
                pageviews.innerHTML = "100K";
                spanSBP.innerHTML="Premium 2";
                break;
                case "5":
                price.innerHTML = Number(prices[4]);
                pageviews.innerHTML = "120K";
                spanSBP.innerHTML="Premium 3";
                break;
                case "6":
                price.innerHTML = Number(prices[5]);
                pageviews.innerHTML = "160K";
                spanSBP.innerHTML="Premium 5";
                break;
                case "7":
                price.innerHTML = Number(prices[6]);
                pageviews.innerHTML = "250K";
                spanSBP.innerHTML="Premium 6";
                break;
                case "8":
                price.innerHTML = Number(prices[7]);
                pageviews.innerHTML = "350K";
                spanSBP.innerHTML="Premium 7";
                break;
          }        
        });
    }
    // listener = (slidval) => {
    //     let slider = document.getElementById("singleuserslider");
    //     let price = document.getElementById("singleuserprice");
    //     let pageviews = document.getElementById("singleuserpageviews");
        
    //     // let toggle = document.getElementById("toggle");
    //     let text = document.getElementById("singleuserperiod");        
        
    //     var prices = [29, 59, 75, 99];
    //     window.requestAnimationFrame(function(){
    //         console.log(slidval);
    //       switch (slidval) {
    //         case "1":
    //           price.innerHTML = Number(prices[0]).toFixed(2);
    //           pageviews.innerHTML = "22K";
    //           break;
    //         case "2":
    //           price.innerHTML = Number(prices[1]).toFixed(2);
    //           pageviews.innerHTML = "37K";
    //           break;
    //         case "3":
    //           price.innerHTML = Number(prices[2]).toFixed(2);
    //           pageviews.innerHTML = "63K";
    //           break;
    //         case "4":
    //           price.innerHTML = Number(prices[3]).toFixed(2);
    //           pageviews.innerHTML = "79K";
    //           break;
    //       }        
    //     });
    //   };

    sliderchangedteamuser = (e) => {
        //document.getElementById("teamuserslider").value
        let slider = document.getElementById("teamuserslider");
        let price = document.getElementById("teamuserprice");
        let pageviews = document.getElementById("teamuserpageviews");
        var hfCurrentPlanLevel = document.getElementById("hfCurrentPlanLevel").value;
        var slidval = e.target.value;
        if(hfCurrentPlanLevel != "2"+slidval){
            document.getElementById('aPremiumUserPlanSectionUpdate').style.pointerEvents="auto";
            document.getElementById('divPremiumStartButton').style.cursor="pointer";
        } 
        else{
            document.getElementById('divPremiumStartButton').style.cursor="not-allowed";
            document.getElementById('aPremiumUserPlanSectionUpdate').style.pointerEvents="none";
        }
        var prices = [345,495,745,995];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            //console.log(slidval);
            document.getElementById('premiumfeaturesulteam').style.display = 'block';
            var spanTBP = document.getElementById('spanTBP');
          switch (slidval) {
            case "1":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "80K";
              document.getElementById('premiumfeaturesulteam').style.display = 'none';
              spanTBP.innerHTML="Base";
              break;
            case "2":
              price.innerHTML = Number(prices[1]);
              pageviews.innerHTML = "100K";              
              spanTBP.innerHTML="Premium";
              break;
              case "3":
                price.innerHTML = Number(prices[2]);
                pageviews.innerHTML = "320K";
                spanTBP.innerHTML="Premium 2";
                break;
              case "4":
                price.innerHTML = Number(prices[3]);
                pageviews.innerHTML = "750K";
                spanTBP.innerHTML="Premium 3";
                break;
          }        
        });
    }

    sliderchangedteamuserOnLoad = (eVar) => {
        //document.getElementById("teamuserslider").value
        let slider = document.getElementById("teamuserslider");
        let price = document.getElementById("teamuserprice");
        let pageviews = document.getElementById("teamuserpageviews");
        var slidval = eVar;
        var prices = [345,495,745,995];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            //console.log(slidval);
            document.getElementById('premiumfeaturesulteam').style.display = 'block';
            var spanTBP = document.getElementById('spanTBP');
          switch (slidval) {
            case "1":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "80K";
              document.getElementById('premiumfeaturesulteam').style.display = 'hide';
              spanTBP.innerHTML = "Base";
              break;
            case "2":
              price.innerHTML = Number(prices[1]);
              pageviews.innerHTML = "100K";
              spanTBP.innerHTML = "Premium";
              break;
              case "3":
                price.innerHTML = Number(prices[2]);
                pageviews.innerHTML = "320K";
                spanTBP.innerHTML = "Premium";
                break;
              case "4":
                price.innerHTML = Number(prices[3]);
                pageviews.innerHTML = "750K";
                spanTBP.innerHTML = "Premium";
                break;
          }        
        });
    }

    openPriceModel = () =>{
        document.getElementById('hrefPriceModal').click();
    }
    closePriceModel = () =>{
        document.getElementById('btnCancelPriceModal').click();
    }

    showPricingToUser = (planType) => {
        document.getElementById('hfClickedPlan').value = planType;
        this.fillPriceDetails();
        this.openPriceModel();
        return;
    }

    fillPriceDetails = () =>{
        var selPtype = document.getElementById('hfClickedPlan').value;
        var spanPrice = document.getElementById('spanPrice');
        var spanPFee = document.getElementById('spanPFee');
        var spanTotal = document.getElementById('spanTotal');
        
        var priceMain = 0;
        switch (selPtype) {
            case "1":
                priceMain = parseInt(document.getElementById("singleuserprice").innerHTML);
                break;
            case "2":
                priceMain = parseInt(document.getElementById("teamuserprice").innerHTML);
                break;        
            default:
                break;
        }
        spanPrice.innerHTML = '$' + priceMain;
        var pf = this.calculateProcFee(priceMain);
        var t = parseFloat(priceMain) + pf;
        spanPFee.innerHTML = '$' +pf;
        spanTotal.innerHTML = '$' + t;
    }

    calculateProcFee = (Input) => {
        var outputFloat = 0.00;
        var inputFloatstring = parseFloat(Input).toFixed(2);
        var inputFloat = parseFloat(inputFloatstring)

        try {
            var fee = 0.00;
            fee = ((inputFloat / 100) * 2.9) + 0.30;

            var outputFloatstring = parseFloat(fee).toFixed(2);
            outputFloat = parseFloat(outputFloatstring);

        } catch (e) {

        }
        return outputFloat;
    }

    proccedOnPrice = () =>{
        var selPtype = document.getElementById('hfClickedPlan').value;
        this.closePriceModel();
        this.updateAccountSubscriptionOneStep(selPtype);
    }

    updateAccountSubscriptionOneStep = (pType) => {
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        //console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var uname = parsedRes.FirstName;
        var userId = parsedRes.UserId;
        var emailId = parsedRes.EmailId
        var phone = parsedRes.phonenumber;

        var planTypeSel = pType;
        var pLevelSel =  "1";
        if(planTypeSel == "1"){
            pLevelSel = document.getElementById("singleuserslider").value;
        }
        else if(planTypeSel == "2"){
            pLevelSel = document.getElementById("teamuserslider").value;
        }

        var self = this;
        this.showoverlay(); 
        //UpgradeUserSubscription(string Name, string EmailAddress, string Phone, string UserId, string PlanType, string PlanLevel
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpgradeUserSubscription?Name=" + uname + "&EmailAddress=" + emailId + "&Phone=" + phone 
            + "&UserId=" + userId + "&PlanType=" + planTypeSel + "&PlanLevel=" + pLevelSel,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                //console.log(resp);
                var response = (resp.data);
                //console.log(response);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var pId = response.Data;
                        parsedRes.PaymentCustomerId = pId;
                        parsedRes.PlanType = planTypeSel;
                        parsedRes.PlanLevel = pLevelSel;
                        var cRole = parsedRes.Role;
                        var loadCompletePage= false;
                        if(planTypeSel == "1"){
                            parsedRes.Role ="2";
                            if (cRole != parsedRes.Role) {
                                loadCompletePage= true;
                            }
                        }
                        else{
                            parsedRes.Role ="1";
                            if (cRole != parsedRes.Role) {
                                loadCompletePage= true;
                            }
                        }
                        const serializedState2 = JSON.stringify(parsedRes);
                        Cookies.set('userInfo', serializedState2); 
                        self.customAlertMessage('success', "Plan updated successfully.");
                        if (loadCompletePage) {
                            setTimeout(() => {
                                window.location.reload();
                            }, 3000);
                        }
                        else{
                            setTimeout(() => {
                                //$('#lnkPaymentUpdateNav')[0].click();
                                window.location.reload();
                            }, 3000);
                        }
                    }
                    else {
                        self.customAlertMessage('error', "Payment failed. "+response.Message);
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    render(){
        return (
            <>
            <div className="main_content_iner overly_inner dashboard-page">
            <div className="container-fluid p-0 ">
              <div className="row">
                <div id="overlay">
                    <div id="loading">
                        <h2 id="loaderheading"></h2>
                        <img src={loader} />
                    </div>
                </div>

                <div className="col-12">
                    <div className="title-card1">
                        <h4>
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={this.historygoback}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                            Manage Subscription
                        </span>
                        </h4>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 text-center plansui">
                            <label id="h4CurrentPlan"></label>  
                            <input type='hidden' id='hfCurrentPlanLevel'></input>     
                            <input type='hidden'  id='hfClickedPlan'></input>                 
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row justify-content-md-center">
                        {/* <div className="col-12">
                        <div className="container-wrap container-wrap1">
                            <div className="toggle-container">
                                Monthly Billing
                                <label className="switch">
                                <input type="checkbox" id="toggle" onclick="discount()">
                                <span className="slide round"></span>
                                </label>
                                <br className="show">
                                Yearly Billing
                            </div>
                        </div>
                        </div> */}
                        <div className="col-4">
                        <div id="divSUPlan" className="container-wrap">
                            <div className="topupper">
                                <h4>Single User</h4>
                                <p>For an individual</p>
                                <p>
                                Lite usage and progressively grow your subscription with increased content needs.
                                </p>
                            </div>
                            <div className="upper">
                                <div className="left">
                                    <span id="singleuserpageviews">20K</span> words/mo
                                    {/* <span id="singleuserimageviews">0</span> Images/mo */}
                                </div>
                                <div className="right">
                                    <span className="price">$<span id="singleuserprice">29</span></span> / <span id="singleuserperiod">month</span>
                                </div>
                            </div>
                            <div className="slider">
                                <input type="range" min="1" max="8" id="singleuserslider" onChange={this.sliderchanged} defaultValue="1"></input>
                                <center><span id="spanSBP">Base</span></center>
                            </div>
                            <div id="divsingleStartButton" className="startbutton">
                                <a id="asingleUserPlanSectionUpdate" onClick={() => this.showPricingToUser(1)} className="start-btn">Update Plan</a>
                            </div>
                            <div className="bottom" style={{display:"block", minHeight:"380px"}}>
                                <h4>FEATURES</h4>
                                <ul id="premiumfeaturesul" style={{display:'none'}}>
                                    <li><span>Grammar Check</span></li>
                                    <li><span>Copyright Check</span></li>
                                    <li></li>
                                </ul>
                                <strong><p style={{fontSize:"14px"}}>Contents with ChatGPT for</p></strong>
                                <ul>
                                    <li><span>Email Campaigns</span></li>
                                    <li><span>Facebook Posts/Ads</span></li>
                                    <li><span>Instagram Posts</span></li>
                                    <li><span>Twitter Posts</span></li>
                                    <li><span>TikTok Posts</span></li>
                                    <li><span>Pinterest Posts</span></li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="col-4">	
                            <div id="divTUPlan" className="container-wrap">
                                <div className="topupper">
                                    <h4>Team</h4>
                                    <p>For teams (up to 5 users)</p>
                                    <p>
                                    Ideal for teams for sharing<br/> and collaboration.
                                    </p>
                                </div>
                                <div className="upper">
                                <div className="left">
                                    <span id="teamuserpageviews"></span> words/mo
                                </div>
                                <div className="right">
                                    <span className="price">$<span id="teamuserprice">345</span></span> / <span id="teamuserperiod">month</span>
                                </div>
                                </div>
                                <div className="slider">
                                <input type="range" min="1" max="4" id="teamuserslider" onChange={this.sliderchangedteamuser} defaultValue="1"></input>
                                <center><span id="spanTBP">Base</span></center>
                                </div>
                        <div id="divPremiumStartButton" className="startbutton">
                            <a id="aPremiumUserPlanSectionUpdate" onClick={() => this.showPricingToUser(2)} className="start-btn">Update Plan</a>
                        </div>
                                <div className="bottom" style={{display:"block", minHeight:"380px"}}>
                                <h4>FEATURES</h4>
                                <ul id="premiumfeaturesulteam" style={{display:'none'}}>
                                    <li><span>Grammar Check</span></li>
                                    <li><span>Copyright Check</span></li>
                                    <li></li>
                                </ul>
                                <strong><p style={{fontSize:"14px"}}>Sharing and Collaboration of<br/> Contents with ChatGPT for</p></strong>
                                <ul>
                                    <li><span>Email Campaigns</span></li>
                                    <li><span>Facebook Posts/Ads</span></li>
                                    <li><span>Instagram Posts</span></li>
                                    <li><span>Twitter Posts</span></li>
                                    <li><span>TikTok Posts</span></li>
                                    <li><span>Pinterest Posts</span></li>
                                </ul>
                                </div>
                            </div>			
                        </div>
                        {/* <div className="col-4">	
                            <div className="container-wrap">
                                <div className="topupper">
                                    <h4>Business</h4>
                                    <p>For growing teams and businesses</p>
                                    <p>
                                        The AI built for teams with the ability to learn your brand voice, and the support you need to scale.
                                    </p>
                                </div>
                                <div className="upper">
                                <div className="left">
                                    <span id="pageviews"></span> words/mo
                                </div>
                                <div className="right">
                                    <span className="price">$<span id="price">16.00</span></span> / <span id="period">month</span>
                                </div>
                                </div>
                                <div className="slider">
                                <input type="range" min="1" max="5" value="3" id="slider">
                                </div>
                        <div className="startbutton">
                            <a className="start-btn">Start Free</a>
                            <h5>5-day free trial with 10k credits</h5>
                        </div>
                                <div className="bottom">
                            <h4>Quick look at features:</h4>
                                <ul>
                                    <li><span>Unlimited websites</span></li>
                                    <li><span>100% data ownership</span></li>
                                    <li><span>Email reports</span></li>
                                </ul>
                                </div>
                            </div>			
                        </div> */}
                    </div>
                </div>
                <div className="row">
                <div className="col-sm-12 text-center mt-2 anchor-blue">
                                <Link id="lnkPaymentUpdateNav" to="/paymentupdate" style={{paddingBottom:"30px", display:"inline-block", paddingTop:"10px"}}>
                                    Back
                                </Link>
                            </div>  
                            </div>     
                </div>     
            </div>
            <a href='javascript:;' id='hrefPriceModal' data-bs-toggle="modal"
                          data-bs-target="#pricingmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a> 
            </div>  

            {/* price model start */}
            <div className="modal fade" id="pricingmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id="lblPricingTitle"><b>Billing details</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Price:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanPrice'></span>
                                    </div>
                                </div>          
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Processing fee:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanPFee'></span>
                                    </div>
                                </div>        
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-3'>
                                        <span style={{color:"#000"}}>Total:</span>
                                    </div>
                                    <div className='col-6'>
                                        <span style={{color:"#000"}} id='spanTotal'></span>
                                    </div>
                                </div>                                             
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btnCancelPriceModal' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnCancelSub' onClick={this.proccedOnPrice} className="common-save float-start">Proceed</a>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* price model end */} 
            </>        
       )
}
};
export default UpgradePlan;