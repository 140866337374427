import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faGlobe, faMusic, faPhotoFilm, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";


const Aiavatar = () => {    
    return(
        <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">	  
			
			
            <div className="col-12">
				<div className="title-card1">
					<span className="float-start">
						<h4 style={{display:"inline-block"}}>AI Avatar</h4>
					</span>
				</div>
            </div>
			
			<div className="col-12">
				<div className="row">
					
					<div className="col-7">
						<div className="video-content">
							<p></p>
							<video controls>							
							  <source src="https://planetre.com/planetrenewweb/video/skyharbor.mp4" type="video/mp4"></source>
							  Your browser does not support HTML video.
							</video>
						</div>
					</div>
					<div className="col-4 thumbnail-views">
						<h4>Video Title</h4>
						<input type="text" className='common-input mb-20'/>
						<h4>Select Avatar, Size, and Alignmnet</h4>
						<div style={{clear:"both"}}>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
							<span>
								<img src="https://cdn.pixabay.com/photo/2013/07/13/10/07/man-156584__340.png"/>
							</span>
						</div>
					</div>
					<div className="col-1 video-options">
						<span className="active">							
							<FontAwesomeIcon icon={faUser} />
							<h4>Avatar</h4>
						</span>
						<span>							
							<FontAwesomeIcon icon={faPhotoFilm} />
							<h4>Background</h4>
						</span>
						<span>							
							<FontAwesomeIcon icon={faMusic} />
							<h4>Music</h4>
						</span>
						<span>							
							<FontAwesomeIcon icon={faGlobe} />
							<h4>Language</h4>
						</span>
					</div>
					
					
				</div>
				
				<div className="row">
					<div className="col-12">
						<div className="chat-content">
						<ul id="listofqa">
								<li className="questionn"><b>how to buy a car</b></li>
								<li className="answer">
									1. Research: Research the car models you are interested in, compare features and prices, and read reviews.
		
									2. Budget: Determine the amount you can realistically spend on a car.

									3. Test drive: Visit the dealership and test drive several cars to decide which one you like best.

									4. Negotiate: Negotiate with the dealer for the best deal, taking into account additional costs such as taxes, title and registration fees, and financing.

									5. Financing: If you are financing, shop for the best rate and determine if you should buy or lease.

									6. Purchase: Buy the car, signing all paperwork and making sure you're clear on all terms and warranties.

									7. Meticulously inspect: Take a minute to inspect your car carefully before you drive away.
									<div><a className="video-btn">Generate Video</a></div>
								</li>								
							</ul>
						</div>
					</div>
				</div>
				
				<div className="row justify-content-center">
					<div className="col-11">
						<input type="text" id="txtidea" name="txtidea" className="form-control" placeholder="Ask anything here!!!"/>                                  
					</div>
					<div className="col-1">
						<Link to="#" className='getBtn'>Get</Link>					
					</div>
                </div>
				
			</div>
			
			
          </div>          
        </div>
      </div>
)
};

export default Aiavatar;