import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name,Role} from "../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
// import ExternalInfo from "components/ExternalInfo";

const Emailtemplatedata = () => {
    const [tabledata, settabledata] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Saved Work", field: "title", sortable: true },
        { name: "Description", field: "description", sortable: false },
        { name: "Created", field: "createddate", sortable: true },
        { name: "Modified", field: "lastupdated", sortable: true },
        { name: "Action"}
    ];

    useEffect(() => {
        const getData = () => {
            showLoader();
            var config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/getsavedtemplate?userid='+ UserID + '&companyid='+Companyid+'&userrole='+Role
              };
        
            axios(config)
                .then(function (resp) {                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            settabledata(response.Data);                        
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });
        };

        getData();
    }, []);

    const tableData = useMemo(() => {
        let computeddata = tabledata;

        if (search) {

            computeddata = computeddata.filter(
                tdata =>
                tdata.title.toLowerCase().includes(search.toLowerCase()) ||
                tdata.description.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computeddata.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computeddata = computeddata.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computeddata.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tabledata, currentPage, search, sorting]);

    const deletetemplate = (e) =>{
        var rfid = e.currentTarget.dataset.refid;        
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        data.append('id', rfid);
        data.append('userid', UserID);

        var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/deletetemplate',
        data : data
        };
        showLoader();
        axios(config)
        .then(function (resp) {
            var response = (resp.data);
            if (response.Status == "200") {
                hideLoader();
                if (response.Data == true) {
                    //deleted successfully       
                    customAlertMessage('success','Deleted successfully.');         
                    var imagelist = tableData;
                    for (let [i, image] of imagelist.entries()) {
                        if (image.id === rfid) {
                            imagelist.splice(i, 1);
                            break;
                        }
                    }
                    settabledata(imagelist);   
                }
                else {                        
                    //template created by team cannot delete
                    customAlertMessage('success','Template created by team member cannot delete.');    
                }
            }
            else {
            console.log('error', response.Message); // 'success','failure','info'
            hideLoader();
            customAlertMessage('error', 'Something went wrong, Try Again.');
            }
        })
        .catch(function (error) {
        console.log(error);
        hideLoader();
        });


    }
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}

            <div className="row w-100" style={{marginTop:"15px"}}>
                <div className="col mb-3 col-12 text-center" style={{paddingRight:"0"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse" style={{paddingRight:"0"}}>
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                   <div className="row">
                   <div className="rightside-bar pr5 nominheight">
                    <table className="table table-hover common-table">
                        <Header
                            headers={headers}
                            defaultsort="lastupdated" 
                            defaultsortorder="desc" 
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
<tbody>
{tableData.length > 0 ? tableData.map((d) => (
                                <tr style={{textAlign:'left'}}>
									{/* <td className="center"><input type="checkbox"/></td> */}
									{/* <td style={{cursor:"pointer"}}><a href='javascript:;' onClick={this.openemaildetails} data-to={"/email?id="+d.id}>{d.title}</a> </td> */}
									<td style={{cursor:"pointer"}}><Link to={"/email?id="+d.id} >{d.title}</Link></td>
									<td>{d.description}</td>
									<td>{moment.utc(d.createddate).local().format('MM-DD-YYYY h:mm A')}</td>
									<td>{moment.utc(d.lastupdated).local().format('MM-DD-YYYY h:mm A')}</td>                                  
                                    <td><span style={{color:'red',cursor:'pointer'}} data-refid={d.id} onClick={deletetemplate}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span></td>
								</tr>

                            )) : <tr>
                                <td colSpan={5} className="text-center">No data found</td>
                                </tr>
                                }
</tbody>                        
                    </table>
                    </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default Emailtemplatedata;