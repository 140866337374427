import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import React, { useRef,useImperativeHandle,useState,useEffect } from "react";

const Roomthememask = (props) => {
  const selectInputRef = useRef();
  const interioroptions = [
    { value: "-1", label: "Select" }
    ,{ value: "1", label:"1970s Revival"}
    ,{ value: "2", label:"Art Deco"}
    ,{ value: "3", label:"Art Nouveau"}
    ,{ value: "4", label:"Bohemian"}
    ,{ value: "5", label:"Boho Chic"}
    ,{ value: "6", label:"Californian"}
    ,{ value: "7", label:"Coastal"}
    ,{ value: "8", label:"Contemporary"}
    ,{ value: "9", label:"Dessert Modern"}
    ,{ value: "10", label:"Eclectic"}
    ,{ value: "11", label:"English Countryside Chic"}
    ,{ value: "12", label:"Farmhouse"}
    ,{ value: "13", label:"French Country"}
    ,{ value: "14", label:"Glam"}
    ,{ value: "15", label:"Grandmillennial"}
    ,{ value: "16", label:"Hollywood Regency"}
    ,{ value: "17", label:"Industrial"}
    ,{ value: "18", label:"Mediterranean"}
    ,{ value: "19", label:"Memphis"}
    ,{ value: "20", label:"Mid-Century Modern"}
    ,{ value: "21", label:"Millennial Maximalist"}
    ,{ value: "22", label:"Minimalist"}
    ,{ value: "23", label:"Modern"}
    ,{ value: "24", label:"Modern Farmhouse"}
    ,{ value: "25", label:"Naturalist"}
    ,{ value: "26", label:"Neo-Industrial"}
    ,{ value: "27", label:"New Traditional"}
    ,{ value: "28", label:"Organic Minimalist"}
    ,{ value: "28", label:"Quiet Luxury "}
    ,{ value: "36", label:"Retro"}
    ,{ value: "30", label:"Rustic"}
    ,{ value: "31", label:"Scandinavian"}
    ,{ value: "32", label:"Shabby Chic"}
    ,{ value: "33", label:"Southwestern"}
    ,{ value: "34", label:"Traditional"}
    ,{ value: "35", label:"Transitional"}
        ];
        const exterioroptions = [
          //{ value: "-1", label: "Select" },
          { value: "beautiful garden", label:"Beautiful garden"},
          { value: "charming playhouse garden landscaping", label:"Charming playhouse garden landscaping"},
          { value: "cottage garden's colorful planting palette", label:"Cottage garden's colorful planting palette"},
          { value: "cozy corner with fire pit and seating", label:"Cozy corner with fire pit and seating"},
          { value: "garden landscaping with gravel landscaping", label:"Garden landscaping with gravel landscaping"},
          { value: "hip california garden landscaping", label:"Hip california garden landscaping"},
          { value: "lush green lawn", label:"Lush green lawn"},
          { value: "mediterranean garden landscaping", label:"Mediterranean garden landscaping"},
          { value: "moss garden", label:"Moss garden"},
          { value: "outdoor dining and sitting area", label:"Outdoor dining and sitting area"},
          { value: "party-ready outdoor space with pool, spa, and fire feature", label:"Party-ready outdoor space with pool, spa, and fire feature"},
          { value: "resort-style landscaping and pool", label:"Resort-style landscaping and pool"},
          { value: "round swimming pool with lawn and pool house", label:"Round swimming pool with lawn and pool house"},
         
         
        ];
        const groupedOptions = [
          {
            label: "Interior",
            options: interioroptions
          },
          {
            label: "Exterior",
            options: exterioroptions
          }
        ];
        const [dataprop, setDataprop] = useState(interioroptions[0])
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  useEffect(()=>{
    console.log(props.selval);
    // setDataprop(groupedOptions.filter( element => element.label == props.selval)[0]);
    setDataprop(groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == props.selval))[0]);
  },[props.selval])
const handlechange =(e)=>{
var selectedvalue = e.value;
// setDataprop(groupedOptions.filter( element => element.label == selectedvalue)[0]);
setDataprop(groupedOptions.flatMap((multitype) => multitype.options.filter(element => element.label == selectedvalue))[0]);

}

  return (
    // <Select options={options} styles={customStyles} />
    <>
      <a href="javascript:;" id="roomthemeclear" onClick={onClear} style={{display:'none'}}>Clear</a>
      <Select ref={selectInputRef} value={dataprop} onChange={handlechange} defaultValue={dataprop} placeholder="Select" id={props.identity} options={groupedOptions}  maxMenuHeight={300}/>
    </>
    
  );
};

export default Roomthememask;