import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faChevronLeft,
  faMicrophone,
  faInfo,
  faInfoCircle,
  faRotateRight,
  faShield,
  faCloudArrowUp,
  faCircleDown,
  faFile,
  faTimes,
  faCopy,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React from "react";
// import copy from 'copy-to-clipboard';
import fileDownload from "js-file-download";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard } from "copy-image-clipboard";
import loader from "../img/sample.gif";
// import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

import errorimage from '../img/error.png'
import { Companyid, UserID } from "../User";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import heic2any from "heic2any";

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;
class ImageChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedfile: null,
      processedimage: "",
      copySuccess: "",
      uploadedbase64: "",
      generatedimages: [],      
    };
  }
  componentDidMount() {
    this.documentinit();
  }

  handleDownload = (e) => {
    var url = e.currentTarget.dataset.fileurl;
    var axios = require("axios");
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        var filename = url.substring(url.lastIndexOf("/") + 1);
        fileDownload(res.data, filename);
      });
  };
  customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };
  showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  documentinit = () => {
    var self = this;
    Array.from(
      document.getElementById("sidebar_menu").querySelectorAll("li")
    ).forEach(function (el) {
      el.classList.remove("active");
    });
    document.getElementById("10").classList.add("active");
    $(".dropzone").on("change", function () {
      var tempfile = $(".dropzone")[0].files[0];
      var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
      if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
        this.customAlertMessage('error', 'File type not supported.');
        return;
      }
      // if (tempfile.size < 512000) {
        if (true) {
        self.state.uploadedfile = tempfile;
        if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
          self.readiphoneFile();
        }
        else{
          self.readFile();
        }
      } else {
        self.customAlertMessage("error", "Maximum file size exceeded.");
        return;
      }
      //self.readFile();
    });

    $(".dropzone-wrapper").on("dragover", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".dropzone-wrapper").addClass("dragover");
    });

    $(".dropzone-wrapper").on("dragleave", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".dropzone-wrapper").removeClass("dragover");
    });
    document
      .querySelector(".dropzone-wrapper")
      .addEventListener("drop", (event) => {
        event.preventDefault();
        var tempfile = event.dataTransfer.files[0];
        var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
        if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
          this.customAlertMessage('error', 'File type not supported.');
          return;
        }
        // if (tempfile.size < 512000) {
          if (true) {
          self.state.uploadedfile = tempfile;
          if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
            self.readiphoneFileondrop(event.dataTransfer);
          }
          else{
            self.readFileondrop(event.dataTransfer);
          }
        } else {
          self.customAlertMessage("error", "Maximum file size exceeded.");
          return;
        }
      });

    $(".remove-preview").on("click", function () {
  //     var boxZone = $(".remove-preview")
  //       .parents(".preview-zone")
  //       .find(".top-box");
  //     var previewZone = $(".remove-preview").parents(".preview-zone");
  //     //var dropzone = $('.remove-preview').parents('.form-group').find('.dropzone');
  //     //$('#originalimagediv').hide();
  //     // boxZone.empty();
  //     previewZone.addClass("hidden");
  //     $(".dropzone-wrapper").removeClass("hidden");
	// $('#orgpreview').hide();
       self.reset();
    });

    $(".filterslist").on("click", function (event) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      //(... rest of your JS code)
      var stylecode = event.target.dataset.stylecode;
      self.applyfilter(self.state.uploadedfile, stylecode);
      $(".filterslist").removeClass("active");
      event.currentTarget.classList.add("active");
    });
  };
  blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

readiphoneFile = () => {
    var self = this;
    var input = $('.dropzone')[0];
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        self.showoverlayiphone();
        reader.onload = function (e) {                
            fetch(e.target.result)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
        .then((conversionResult) => {
            // conversionResult is a BLOB
            // of the PNG formatted image
            var url = URL.createObjectURL(conversionResult);
            var file = new File([conversionResult],  uuidv4() + ".png");
            self.setState({ processedimage: url,uploadedfile:file,uploadedbase64: url });
            var wrapperZone = $(input).parent();
            var previewZone = $(input).parent().parent().find(".preview-zone");
            wrapperZone.removeClass("dragover");
            previewZone.removeClass("hidden");
            $(".dropzone-wrapper").addClass("hidden");
            $('#orgpreview').show();
            self.hideoverlayiphone();
        })
        .catch((e) => {
            // see error handling section
            self.customAlertMessage('error','Error occured, please try again.'); 
            self.hideoverlayiphone();
        });
        };
        reader.readAsDataURL(input.files[0]);
    }
}

readiphoneFileondrop = (input) => {
    var self = this;
    
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        self.showoverlayiphone();
        reader.onload = function (e) {
            fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file,uploadedbase64: url });
                var wrapperZone = $(".dropzone-wrapper");
                var previewZone = $(".preview-zone");
                wrapperZone.removeClass("dragover");
                previewZone.removeClass("hidden");
                $(".dropzone-wrapper").addClass("hidden");
                $('#orgpreview').show();
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
        };
        reader.readAsDataURL(input.files[0]);
    }
}
showoverlayiphone = () => {
  document.getElementById('overlayiphone').style.display = "block";
  $('body').addClass("loader");
}
hideoverlayiphone = () => {
  document.getElementById('overlayiphone').style.display = "none";
  $('body').removeClass("loader");
}
  readFile = () => {
    var self = this;
    var input = $(".dropzone")[0];

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        self.setState({ uploadedbase64: e.target.result });
        //self.state.processedimage = e.target.result;
        //var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
        var wrapperZone = $(input).parent();
        var previewZone = $(input).parent().parent().find(".preview-zone");
        var boxZone = $(input)
          .parent()
          .parent()
          .find(".preview-zone")
          .find(".grey-box")
          .find(".top-box");

        wrapperZone.removeClass("dragover");
        previewZone.removeClass("hidden");
        //boxZone.empty();
        //boxZone.append(htmlPreview);
        $(".dropzone-wrapper").addClass("hidden");
		$('#orgpreview').show();
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  readFileondrop = (input) => {
    var self = this;
    //var input = $('.dropzone')[0];

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        self.setState({ uploadedbase64: e.target.result });
        //self.state.processedimage = e.target.result;
        //var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
        var wrapperZone = $(".dropzone-wrapper");
        var previewZone = $(".preview-zone");
        //var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');

        wrapperZone.removeClass("dragover");
        previewZone.removeClass("hidden");
        //boxZone.empty();
        //boxZone.append(htmlPreview);
        $(".dropzone-wrapper").addClass("hidden");
		$('#orgpreview').show();
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  copyToClipboard = (e) => {
    const canCopy = canCopyImagesToClipboard();
    if (issafari) {
        this.copyimageforsafari(e.currentTarget.dataset.fileurl);
    }
    else if (canCopy) {
        copyImageToClipboard(e.currentTarget.dataset.fileurl)
        .then(() => {
          console.log('Image Copied')
          this.customAlertMessage('success','Copied!');
        })
        .catch((e) => {
          console.log('Error: ', e.message)
          this.customAlertMessage('error','Please try again!');
        })
    }
    else {
        this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
    }        
    // copy(e.currentTarget.dataset.fileurl);     
    e.target.focus();
};

copyimageforsafari = async(imageurl) => {
    try {
        const item = new ClipboardItem({
            'image/png': (async () => {
              const response = await fetch(imageurl) // Reference from your source code.
              return await response.blob()
            })(),
          });
          await navigator.clipboard.write([item])
          this.customAlertMessage('success','Copied!');
    } catch (error) {
        this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
    }
}

  historygoback = () => {
    window.history.back();
  };
  reset = () => {
    document.getElementById("prompttextbox").value = "";
    this.setState({ generatedimages: [] });
    var previewZone = $(".remove-preview").parents(".preview-zone");
    previewZone.addClass("hidden");
    $(".dropzone-wrapper").removeClass("hidden");
    $('#orgpreview').hide();
    document.getElementsByClassName("dropzone")[0].value = "";
  };

  editimageselector = () => {
    var newfilteredArray = this.state.generatedimages.filter(function (el) {
      return el.type == "image" &&
             el.text == "";
    });
    if (newfilteredArray.length > 0) {
      var imagesselector = newfilteredArray.sort(function(a,b) {return b.id - a.id;})[0];
      var finaloutputimage = imagesselector.image;
      this.editimagefromurl(finaloutputimage);
    }
    else{
      this.editimage();
    }
    


  }
editimagefromurl = (fileurl) => {
    var self = this;
    var prompt = document.getElementById("prompttextbox").value;
	// if(self.state.uploadedfile== null){
	// 	self.customAlertMessage("error", "please upload image.");
	// 	return;
	// }
  if (prompt.trim() == "") {
    	self.customAlertMessage("error", "Please enter message.");
		  return;
  }

    this.showoverlay();
    var axios = require("axios");
    var FormData = require("form-data");
    //var fs = require('fs');
    
    var data = new FormData();
    data.append("prompt", prompt);
    data.append("fileurl", fileurl);
    data.append("uid",UserID);
    data.append("cid",Companyid);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/editimagewithurl",
      data: data,
    };
	var resultcount = document
	.getElementById("generatedimageholder")
	.getElementsByClassName("counterclass").length;
  var tarr = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;      
	var jsr = {};
	jsr.id = tarr + 1;
	jsr.text = prompt;
	jsr.type = "text";
	jsr.image = "";
	self.state.generatedimages.push(jsr);
	var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
	self.setState({ generatedimages: joined });

    axios(config)
      .then(function (resp) {
        console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data != "") {
          var resultcount = document
            .getElementById("generatedimageholder")
            .getElementsByClassName("counterclass").length;
            var tarrimage = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
          var jsr = {};
          jsr.id = tarrimage + 1;
		  jsr.text = "";
		  jsr.type = "image";
          jsr.image = response.Data;
          self.state.generatedimages.push(jsr);
		  var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
		  self.setState({ generatedimages: joined });
		  document.getElementById("prompttextbox").value = "";

          //self.setState({ processedimage: response.Data });
          //$('#actionitemsdiv').show();
          // self.customAlertMessage('success', 'Processed successfully.');
        //   document.getElementById("copyimagespan").style.display = "block";
        //   document.getElementById("downloadimagespan").style.display = "block";
        //   document.getElementById("outputimage").style.display = "block";
        //   document.getElementById("outputimage").style.display = "block";
        } else {
          self.customAlertMessage("error", "Something went wrong, Try Again.");

		  var resultcount = document
		  .getElementById("generatedimageholder")
		  .getElementsByClassName("counterclass").length;
      var tarrerrorimage = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
		var jsr = {};
		jsr.id = tarrerrorimage + 1;
		jsr.text = "error";
		jsr.type = "image";
		jsr.image = errorimage;
		self.state.generatedimages.push(jsr);
		var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
		self.setState({ generatedimages: joined });
        }
		setTimeout(() => {
			document.getElementById('generatedimageholder').scroll(
				{ top: document.getElementById('generatedimageholder').scrollHeight, 
				behavior: 'smooth' });
		}, 2000);
		
        self.hideoverlay();
      })
      .catch(function (error) {
        console.log(error);
        self.customAlertMessage("error", "Something went wrong, Try Again.");
        self.hideoverlay();
      });
  };

  editimage = () => {
    var self = this;
	if(self.state.uploadedfile== null){
		self.customAlertMessage("error", "please upload image.");
		return;
	}
  var prompt = document.getElementById("prompttextbox").value;
  if (prompt.trim() == "") {
      this.customAlertMessage("error", "Please enter message.");
      return;
  }
    this.showoverlay();
    var axios = require("axios");
    var FormData = require("form-data");
    //var fs = require('fs');
    var prompt = document.getElementById("prompttextbox").value;
    var data = new FormData();
    data.append("prompt", prompt);
    data.append("file", self.state.uploadedfile);
    data.append("uid",UserID);
    data.append("cid",Companyid);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` + "/api/ArtificialIntelligence/editimage",
      data: data,
    };
	var resultcount = document
	.getElementById("generatedimageholder")
	.getElementsByClassName("counterclass").length;
  var tarr = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
	var jsr = {};
	jsr.id = tarr + 1;
	jsr.text = prompt;
	jsr.type = "text";
	jsr.image = "";
	self.state.generatedimages.push(jsr);
	var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
	self.setState({ generatedimages: joined });

    axios(config)
      .then(function (resp) {
        console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data != "") {
          var resultcount = document
            .getElementById("generatedimageholder")
            .getElementsByClassName("counterclass").length;
            var tarrimage = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
          var jsr = {};
          jsr.id = tarrimage + 1;
		  jsr.text = "";
		  jsr.type = "image";
          jsr.image = response.Data;
          self.state.generatedimages.push(jsr);
		  var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
		  self.setState({ generatedimages: joined });
		  document.getElementById("prompttextbox").value = "";

          //self.setState({ processedimage: response.Data });
          //$('#actionitemsdiv').show();
          // self.customAlertMessage('success', 'Processed successfully.');
        //   document.getElementById("copyimagespan").style.display = "block";
        //   document.getElementById("downloadimagespan").style.display = "block";
        //   document.getElementById("outputimage").style.display = "block";
        //   document.getElementById("outputimage").style.display = "block";
        } else {
          self.customAlertMessage("error", "Something went wrong, Try Again.");

		  var resultcount = document
		  .getElementById("generatedimageholder")
		  .getElementsByClassName("counterclass").length;
      var tarrerrorimage = self.state.generatedimages.length > 0 ? self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
		var jsr = {};
		jsr.id = tarrerrorimage + 1;
		jsr.text = "error";
		jsr.type = "image";
		jsr.image = errorimage;
		self.state.generatedimages.push(jsr);
		var joined = self.state.generatedimages.sort(function(a,b) {return a.id - b.id;});
		self.setState({ generatedimages: joined });
        }
		setTimeout(() => {
			document.getElementById('generatedimageholder').scroll(
				{ top: document.getElementById('generatedimageholder').scrollHeight, 
				behavior: 'smooth' });
		}, 2000);
		
        self.hideoverlay();
      })
      .catch(function (error) {
        console.log(error);
        self.customAlertMessage("error", "Something went wrong, Try Again.");
        self.hideoverlay();
      });
  };
  deletesingle = (e) => {
    var identity = e.currentTarget.dataset.fileurl;
    var imagelist = this.state.generatedimages;
    for (let [i, image] of imagelist.entries()) {
        if (image.id === parseInt(identity)) {
            imagelist.splice(i, 1);
            break;
        }
      }
    this.setState({generatedimages: imagelist});
    this.customAlertMessage('success','Item removed successfully');
}
delateall = () => {
    this.setState({generatedimages: []});
    this.customAlertMessage('success','Removed successfully');
}

  render() {
    return (
      <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">
            <div className="col-12">
              <div className="title-card1">
                <span className="float-start">
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.historygoback}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      Image Chat<sup style={{color:"#599bdb",fontSize:"16px",marginLeft:"5px"}}>Beta</sup>
                    </span>
                  </h4>
                </span>
              </div>
            </div>

            <div className="col-12">
			
              <form action="" method="POST" enctype="multipart/form-data">
                <div className="row">
                  <div className="col-6">
                    <div className="dropzone-wrapper dropzone-wrapper-new">
                      <h4 className="input-head">
                        <FontAwesomeIcon icon={faImage} />
                        Input Image
                      </h4>
                      <div id="overlayiphone">
                          <div id="loading" style={{ top: "35%" }}>
                              <h2 id="loaderheading">Generating...</h2>
                              <img src={loader} />
                          </div>
                      </div> 
                      <div className="dropzone-desc">
                        <h4>Drag and Drop</h4>
                        <p>
                          <b>or Click to upload</b>
                        </p>
                        <FontAwesomeIcon icon={faCloudArrowUp} />
                        <p>Supported Files: PNG, JPG, HEIF, HEIC.</p>
                        {/* <p>Size: up to 500kB</p> */}
                      </div>
                      <input
                        type="file"
                        name="img_logo"
                        className="dropzone"
                        accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"
                      ></input>
                    </div>

                    <div className="preview-zone hidden">
                      <div className="box box-solid">
                        <div className="grey-box">
                          <div className="row">
                            <div
                              className="col-12 top-box"
                              id="originalimagediv"
                            >
                              <div className="row">
                                <div className="col-12">
                                  <span className="float-start">
                                    <h4 className="input-head">
                                      <FontAwesomeIcon icon={faImage} />
                                      Input Image
                                    </h4>
                                  </span>
                                  <span
                                    className="float-end tooltip1"
                                    tooltip="Remove"
                                    flow="down"
                                  >
                                    <a
                                      href="javascript:;"
                                      className="btn btn-danger btn-xs remove-preview"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </a>
                                  </span>
                                  <div
                                    id="actionitemsdiv"
                                    style={{ display: "none" }}
                                  >
                                    <span
                                      className="float-end"
                                      data-fileurl={this.state.processedimage}
                                      onClick={this.copyToClipboard}
                                      tooltip="Copy Image"
                                      flow="down"
                                    >
                                      <FontAwesomeIcon icon={faCopy} />
                                    </span>
                                    <span
                                      className="float-end"
                                      tooltip="Download"
                                      flow="down"
                                    >
                                      <a
                                        href="javascript:;"
                                        onClick={this.handleDownload}
                                        data-fileurl={this.state.processedimage}
                                      >
                                        <FontAwesomeIcon icon={faCircleDown} />
                                      </a>
                                    </span>
                                  </div>

                                  {/* {this.state.copySuccess} */}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12 text-center top-box">
                                  <img
                                    id="originalimage"
                                    width="50%"
                                    src={this.state.uploadedbase64}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="border-wrap">
                      <div className="row">
                        <div className="col-12">
                          <span className="float-start">
                            <h4 className="input-head PL-0">
                              <FontAwesomeIcon icon={faImage} />
                              Edited Image
                            </h4>
                          </span>
                          {/* <span
                            id="copyimagespan"
                            className="float-end"
                            data-fileurl={this.state.processedimage}
                            onClick={this.copyToClipboard}
                            tooltip="Copy Image"
                            flow="down"
                            style={{ display: "none" }}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                          <span
                            id="downloadimagespan"
                            className="float-end"
                            tooltip="Download"
                            flow="down"
                            style={{ display: "none" }}
                          >
                            <a
                              id="downloadimagehref"
                              data-fileurl={this.state.processedimage}
                              href="javascript:;"
                              onClick={this.handleDownload}
                            >
                              <FontAwesomeIcon icon={faCircleDown} />
                            </a>
                          </span> */}
                        </div>
                        <div className="col-12" style={{position:"relative"}}>
                          <div id="overlay">
                              <div id="loading">
                                <h2 id="loaderheading">Generating...</h2>
                                <img
                                  src={loader}
                                />
                              </div>
                            </div>
                          <div id="generatedimageholder" 
                            className="edit-image-box image-chat-scroll"
                            style={{ minHeight: "270px", position: "relative" }}
                          >                            
                            <div className="top-box original-output" id="orgpreview">
                              <img
                                id="originalimage"
                                className="originalimage"
                                width="50%"
                                src={this.state.uploadedbase64}
                              ></img>
                            </div>

                            {this.state.generatedimages
                              ? this.state.generatedimages.map((d) =>
                                  d.type == "text" ? (
                                    <div class="counterclass container-input">
                                      <div class="arrow">
                                        <div class="outer"></div>
                                        <div class="inner"></div>
                                      </div>
                                      <div class="message-body" style={{marginBottom:'5px'}}>
                                        <p>{d.text}</p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="counterclass outputimagegen">
                                      <div style={{float:"right"}}>
                                      <span
                                        id="downloadimagespan"
                                        className="float-start tooltip1"
                                        tooltip="Download"
                                        flow="left"
                                      >
                                        <a
                                          id="downloadimagehref"
                                          data-fileurl={d.image}
                                          href="javascript:;"
                                          onClick={this.handleDownload}
                                        >
                                          <FontAwesomeIcon
                                            icon={faCircleDown}
                                          />
                                        </a>
                                      </span>
                                      <span
                                        id="copyimagespan"
                                        className="float-start tooltip1"
                                        data-fileurl={d.image}
                                        onClick={this.copyToClipboard}
                                        tooltip="Copy Image"
                                        flow="left"
                                      >
                                        <FontAwesomeIcon icon={faCopy} />
                                      </span>
                                      <span                                        
                                        className="float-start tooltip1"
                                        data-fileurl={d.id}
                                        onClick={this.deletesingle}
                                        tooltip="Delete"
                                        flow="left"
                                      >
                                        <FontAwesomeIcon icon={faTimes} style={{color:'#fff',marginLeft:'10px',fontSize:'24px'}} />
                                      </span>
</div>
                                      <img
                                        id="outputimage"
                                        className="ouputimageNew"
                                        src={d.image}
                                      />
                                    </div>
                                  )
                                )
                              : null}
                          </div>
                          <div className="bottom-fixed bottom-fixed1">
                            <div className="row">
                              <div className="col-12">
                                <div className="text-center">
                                  
                                    <input
                                      type={"text"}
                                      className="generateinput"
                                      id="prompttextbox"
                                      placeholder="Add Instructions to Edit.."                                      
                                    ></input>
                                  <a
                                    href="javascript:;"
                                    className="common-save pad-reduce"
                                    onClick={this.editimageselector}
                                  >
                                    Generate
                                  </a>
                                  <a
                                    href="javascript:;"
                                    className="common-clear pad-reduce"
                                    onClick={this.reset}
                                  >
                                    Clear
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageChat;
