import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
 import {UserID,EulaValue,Features,Role} from "../User";
import * as axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import loader from "../img/sample.gif";
import $ from 'jquery';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';


class Superadmincreatecompany extends React.Component {

    componentDidMount(){;
    }

    createCompany = () =>{
        
    }

    render(){
        return (
<>
            <div style={{padding:"20px"}} className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div id="overlay">
                            <div id="loading">
                                <h2 id="loaderheading">Loading...</h2>
                                <img src={loader} />
                            </div>
                        </div> 
                        
                        <div className="col-12">
                            <div className="title-card">
                                <h4>
                                <FontAwesomeIcon icon={faAnglesRight} />chocolatechips.ai</h4>
                            </div>
                        </div>

                        <div className="col-12 adminborder">
                            <div className='row'>
                                <div className='col-3'>
                                    <h4>Create Company</h4>                                    
                                </div>
                                <div className='col-3'>
                                    <Link to="/">Features</Link>
                                </div>
                                <div className='col-3'>
                                    <Link to="/extendFreeTrial">Extend Free Trial</Link>
                                </div>
                                <div className='col-3'>
                                    <a href="javascript:;" onClick={this.logoutSuperadmin} style={{float:"right"}}>Log Out </a>   
                                </div>
                            </div>                        
                        </div>

                        <div className="col-12">
                            <div className='row'>
                                <div className='col-2'>
                                    <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>Company Name:</label> 
                                </div>
                                <div className='col-3'>
                                    <input type='text' id='lblComppanyName' className='' placeholder='Enter company name' ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>First Name:</label> 
                                </div>
                                <div className='col-3'>
                                    <input type='text' id='lblFN' className='' placeholder='Enter First name' ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>Last Name:</label> 
                                </div>
                                <div className='col-3'>
                                    <input type='text' id='lblLN' className='' placeholder='Enter Last name' ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>Email:</label> 
                                </div>
                                <div className='col-3'>
                                    <input type='text' id='lblEmail' className='' placeholder='Enter emailid' ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>
                                    <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>Mobile Pone:</label> 
                                </div>
                                <div className='col-3'>
                                    <input type='text' id='lblMN' className='' placeholder='Enter mobile #' ></input>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-2'>                                    
                                </div>
                                <div className='col-3'>
                                    <a style={{paddingTop:"5px"}} href="javascript:;" className="common-save" onClick={this.createCompany}>Create</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
</>
            )
            }
};
export default Superadmincreatecompany;