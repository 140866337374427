import React, { useState } from "react";

const Search = (props) => {
    const [search, setSearch] = useState("");

    const onInputChange = (e) => {
        setSearch(e.target.value);
        props.onSearch(e.target.value);
    };
    return (
        <input
            type="text"
            className="form-control"
            style={{ width: "240px" }}
            placeholder="Search"
            value={search}
            onChange= {onInputChange}
        />
    );
};

export default Search;