import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faChevronDown,
  faChevronLeft,
  faCrosshairs,
  faEnvelope,
  faMailBulk,
  faMessage,
  faRemove,
} from "@fortawesome/free-solid-svg-icons";
import { json, Link } from "react-router-dom";
import React, { useState } from "react";
import AudioToText from "./AudioToText";
import $ from "jquery";
import Emailstripo from "../email/stripoapp";
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import moment from "moment";
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist";
import Sherlockauthorizedlist from "./sherlockauthorizedlist";
import ContentLoader, { List } from "react-content-loader";
import Sherlockunauthorizeddetailslistcombined from "./sherlockunauthorizeddetailslistcombined";
import Sherlockunauthorizedcombined from "./sherlockunauthorizedcombined";
import Sherlockauthorizeddetailslistcombined from "./sherlockauthorizeddetailslistcombined";
import Sherlockauthorizedlistcombined from "./sherlockauthorizedlistcombined";
// import loader,{showloader,hideloader} from "../hooks/useFullPageLoader";

export const ismobile = () => {
  return window.screen.width <= 600;
};

// const MyLoader = () => <List />
const MyLoader = () => (
  <ContentLoader
    speed={1}
    // width={200}
    // height={20}
    viewBox="0 0 200 20"
    backgroundColor="#808080"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
    {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
    {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
    {/* <circle cx="20" cy="20" r="20" /> */}
  </ContentLoader>
);

const MyLoader1 = () => (
  <ContentLoader
    speed={1}
    width={200}
    height={20}
    viewBox="0 0 200 20"
    backgroundColor="#808080"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
    {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
    {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
    {/* <circle cx="20" cy="20" r="20" /> */}
  </ContentLoader>
);

class Sherlocknew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyworddisabled: true,
      tonedisabled: true,
      copySuccess: "",
      wordcount: 0,
      Tonecount: 0,
      generatedimages: [],
      emailguid: NIL_UUID,
      emailhtml: "",
      defaultcontenterased: false,
      tabledata: [],
      dashtotalcount: -1,
      dashrecentimagescount: -1,
      dashauthorizedcount: -1,
      dashunauthorizedcount: -1,
      dashlastupdated: "",
      unauthorizedlistfordetailsdomain: "",
      authorizedlistfordetailsdomain: "",
    };
  }
  showloader = () => {
    if (ismobile()) {
      this.showoverlaymobile();
    } else {
      document.getElementById("overlaypopup").style.display = "block";
      $("body").addClass("loader");
    }
  };
  hideloader = () => {
    if (ismobile()) {
      this.hideoverlaymobile();
    } else {
      document.getElementById("overlaypopup").style.display = "none";
      $("body").removeClass("loader");
    }
  };
  showoverlaymobile = () => {
    document.getElementById("overlaypopupmobile").style.display = "block";
    $("body").addClass("loader");
    $("html").addClass("loader");
    // loadermessagesrandom();
  };
  hideoverlaymobile = () => {
    document.getElementById("overlaypopupmobile").style.display = "none";
    $("body").removeClass("loader");
    $("html").removeClass("loader");
  };
  componentDidMount() {
    //this.loadtabledata();
    // this.setState({emailguid: uuidv4()});
    this.jquerycode();
    this.gettoppaneldata();
    document
      .getElementById("Sherlockunauthorizedcombineddiv")
      .addEventListener("click", this.getClickPosition, false);
    document
      .getElementById("Sherlockauthorizedlistcombineddiv")
      .addEventListener("click", this.getClickPosition, false);
    // setInterval(() => {
    // 	this.checkandupdatecontent();
    //   }, 10000);
  }

  jquerycode = () => {
    var self = this;
    Array.from(
      document.getElementById("sidebar_menu").querySelectorAll("li")
    ).forEach(function (el) {
      el.classList.remove("active");
    });
    document.getElementById("1").classList.add("active");
  };

  historygoback = () => {
    window.history.back();
  };

  gettoppaneldata = () => {
    const axios = require("axios");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_SHERLOCKAPI_URL}` +
        "/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.Status == "200") {
          var data = response.data.Data;
          this.setState({
            dashtotalcount: data.Totalcount,
            dashrecentimagescount: data.Recentimagescount,
            dashauthorizedcount: data.Authorizedcount,
            dashunauthorizedcount: data.Unauthorizedcount,
            dashlastupdated: new Date(data.Lastupdated).toLocaleString(
              "en-US",
              {
                weekday: "long",
                month: "long",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
                timeZoneName: "long",
              }
            ),
          });
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  customAlertMessage(icon, message) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  }
  shareviaemail = (e) => {
    var type = e.currentTarget.dataset.emailtype;
    var domain = e.currentTarget.dataset.emaildomain;
    var subject = e.currentTarget.dataset.subject;
    var body = document.getElementById("emailbody").value;
    if (body.trim() == "") {
      this.customAlertMessage("error", "Please enter email body.");
      return;
    }
    var to = document.getElementById("emailto").value;
    if (to.trim() == "") {
      this.customAlertMessage("error", "Please add email address to send.");
      return;
    }
    var cc = document.getElementById("emailcc").value;
    var bcc = document.getElementById("emailbcc").value;
    const formdata = new FormData();
    formdata.append("subject", subject);
    formdata.append("message", body);
    formdata.append("to", to);
    formdata.append("cc", cc);
    formdata.append("bcc", bcc);
    formdata.append("type", type);
    formdata.append("domain", domain);
    formdata.append("uid", " 3173894c-7b72-4d76-864a-97c835f318de");
    formdata.append("companyid", " 151");
    formdata.append("mlsname", "");
    formdata.append(
      "timezone",
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    this.showloader();
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    var fetchurl =
      `${process.env.REACT_APP_API_URL}` +
      "/api/ArtificialIntelligence/sharedetailsviaemail";
    //var fetchurl = "https://localhost:44372/api/ArtificialIntelligence/sharedetailsviaemail";
    fetch(fetchurl, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(result);
        if (JSON.parse(result).Status == "200") {
          document.getElementById("emailclose").click();
          this.customAlertMessage("success", "Report successfully sent.");
          this.clearsendemailform();
        } else {
          this.customAlertMessage(
            "error",
            "Something went wrong, please try again."
          );
        }
        this.hideloader();
      })
      .catch((error) => {
        // console.error(error);
        this.hideloader();
      });
  };

  clearsendemailform = () => {
    document.getElementById("emailbody").value = "";
    document.getElementById("emailto").value = "";
    document.getElementById("emailcc").value = "";
    document.getElementById("emailbcc").value = "";
  };

  showmaindiv = () => {
    document.getElementById("main_div").style.display = "flex";
    document.getElementById("details_div").style.display = "none";
    var scrollposx = sessionStorage.getItem("scrollposx");
    if (scrollposx != undefined) {
      try {
        window.scrollTo(0, parseInt(scrollposx));
      } catch (error) {}
    }
    // var posx = sessionStorage.getItem('clickposx');
    // var posy = sessionStorage.getItem('clickposy');
  };
  getClickPosition(e) {
    var xPosition = e.clientX;
    var yPosition = e.clientY;
    sessionStorage.setItem("scrollposx", window.scrollY);
  }
  getPosition = (el) => {
    var xPosition = 0;
    var yPosition = 0;

    while (el) {
      if (el.tagName == "BODY") {
        // deal with browser quirks with body/window/document and page scroll
        var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
        var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

        xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
        yPosition += el.offsetTop - yScrollPos + el.clientTop;
      } else {
        xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
        yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      }

      el = el.offsetParent;
    }
    sessionStorage.setItem("clickposx", xPosition);
    sessionStorage.setItem("clickposy", yPosition);
    return {
      x: xPosition,
      y: yPosition,
    };
  };
  populateunauthorizeddetails = (e) => {
    var domainname = e.currentTarget.dataset.domainname;
    this.setState(
      {
        unauthorizedlistfordetailsdomain: domainname,
      },
      () => {
        document.getElementById("main_div").style.display = "none";
        document.getElementById("details_div").style.display = "flex";
        document.getElementById("details_div_authdetails").style.display =
          "none";
        document.getElementById("details_div_unauthdetails").style.display =
          "block";
      }
    );
  };
  populateauthorizeddetails = (e) => {
    var domainname = e.currentTarget.dataset.domainname;
    this.setState(
      {
        authorizedlistfordetailsdomain: domainname,
      },
      () => {
        document.getElementById("main_div").style.display = "none";
        document.getElementById("details_div").style.display = "flex";
        document.getElementById("details_div_authdetails").style.display =
          "block";
        document.getElementById("details_div_unauthdetails").style.display =
          "none";
      }
    );
  };
  render() {
    return (
      <>
        <div className="main_content_iner overly_inner">
          <div className="container-fluid p-0 ">
            <div className="row" id="main_div">
              <div className="col-12">
                <div className="title-card1">
                  <span className="float-start">
                    <h4>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.historygoback}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Welcome to Sherlock AI
                      </span>
                    </h4>
                    <p>
                      Last Date & Time of Sweep Update:{" "}
                      {this.state.dashlastupdated}
                    </p>
                    {/* <p>System date and time: {new Date().toLocaleString('en-US', { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'short' })}</p> */}
                  </span>
                </div>
              </div>
              <div className="col-12" style={{ marginBottom: "20px" }}>
                <div className="row">
                  <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#e2ffed" }}
                    >
                      <h4>Total Photos</h4>
                      {this.state.dashrecentimagescount == -1 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>
                          {this.state.dashrecentimagescount.toLocaleString()}
                        </h2>
                      )}

                      {/* <h5>Last Date: 12/05/2024</h5> */}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#fbffe0" }}
                    >
                      <h4>Total Web Hits</h4>
                      {this.state.dashtotalcount == -1 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>{this.state.dashtotalcount.toLocaleString()}</h2>
                      )}
                    </div>
                  </div>
                  {/*<div className='col'>
                                <div className='sherlock-box' style={{background:"#eef6ff"}}>
                                    <h4>Total Authorized Matches</h4>
                                    {
                                    this.state.dashauthorizedcount == -1 ? <h2><MyLoader /></h2> :
                                    <h2>{this.state.dashauthorizedcount.toLocaleString()}</h2>
                                    }
                                </div>
                            </div> */}
                  <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#ffefef" }}
                    >
                      <h4>Total Unauthorized Matches</h4>
                      {this.state.dashunauthorizedcount == -1 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>
                          {this.state.dashunauthorizedcount.toLocaleString()}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "20px" }}>
                <div className="row">
                  <div className="col-12" id="Sherlockunauthorizedcombineddiv">
                    <Sherlockunauthorizedcombined
                      onClick={this.populateunauthorizeddetails}
                    ></Sherlockunauthorizedcombined>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "20px" }}>
                <div className="row">
                  <div
                    className="col-12"
                    id="Sherlockauthorizedlistcombineddiv"
                  >
                    <Sherlockauthorizedlistcombined
                      onClick={this.populateauthorizeddetails}
                    ></Sherlockauthorizedlistcombined>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="details_div" style={{ display: "none" }}>
              {/* <div className="row" id="main_sherlockinner_div"> */}
              <div className="col-12">
                <div className="title-card1">
                  <h4>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={this.showmaindiv}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      Back
                    </span>
                  </h4>
                </div>
              </div>
              <div
                className="col-12"
                style={{ marginTop: "20px" }}
                id="details_div_unauthdetails"
              >
                <div className="row">
                  <div className="col-12">
                    <Sherlockunauthorizeddetailslistcombined
                      domainname={this.state.unauthorizedlistfordetailsdomain}
                    ></Sherlockunauthorizeddetailslistcombined>
                  </div>
                </div>
              </div>
              <div
                className="col-12"
                style={{ marginTop: "20px" }}
                id="details_div_authdetails"
              >
                <div className="row">
                  <div className="col-12">
                    <Sherlockauthorizeddetailslistcombined
                      domainname={this.state.authorizedlistfordetailsdomain}
                    ></Sherlockauthorizeddetailslistcombined>
                  </div>
                </div>
              </div>
            </div>

            <a href="javascript:;" id="triggeremail"  style={{display:'none'}} data-bs-toggle="modal" data-bs-target="#shareemail"></a>
          </div>
        </div>
        <div
          class="modal fade shareemailui"
          id="shareemail"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div id="overlaypopup">
                <div id="loading" style={{ top: "35%" }}>
                  <img src={loader} />
                  <h2 id="loaderheading">
                    <span>Processing...</span>
                  </h2>
                </div>
              </div>
              <div id="overlaypopupmobile">
                <div id="loading" style={{ top: "35%" }}>
                  <img src={loader} />
                  <h2 id="loaderheading">
                    <span>Processing...</span>
                  </h2>
                </div>
              </div>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Share Email
                </h5>
                <button
                  id="emailclose"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.clearsendemailform}
                ></button>
              </div>
              <div class="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <label>To:</label>
                      <input
                        id="emailto"
                        type="email"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="col-12">
                      <label>CC:</label>
                      <input
                        id="emailcc"
                        type="email"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="col-12">
                      <label>BCC:</label>
                      <input
                        id="emailbcc"
                        type="email"
                        placeholder="Enter Email"
                      />
                    </div>
                    <div className="col-12">
                      <label>Message:</label>
                      <textarea
                        id="emailbody"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <center>
                      <a href="javascript:;" onClick={this.clearsendemailform} c className="common-save common-save-grey" style={{marginRight:"10px"}}>Clear</a>
                        <a
                          id="btnshareemail"
                          href="javascript:;"
                          tabindex="-1"
                          class="common-save"
                          data-emaildomain=""
                          data-emailtype="1"
                          data-subject=""
                          onClick={this.shareviaemail}
                        >
                          Send
                        </a>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Sherlocknew;
