import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name, Features} from "../User";
import { faMailBulk, faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
// import ExternalInfo from "components/ExternalInfo";

const DataTableUsers = () => {
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        // { name: "No"},
        // { name: "Select"},
        { name: "First Name", field: "FirstName", sortable: true },
        { name: "Last Name", field: "LastName", sortable: true },
        { name: "Email", field: "EmailId", sortable: true },
        { name: "Created On ("+ new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3] + ")", field: "CreatedDate", sortable: true },
        { name: "First Login", field: "FirstLoginDate", sortable: true },
        { name: "Delete User"}
    ];
    useEffect(() => {
        const getData = () => {
            showLoader();

            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAdditionalUsers?companyId="+Companyid,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
        
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //
                            
                            // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                            setComments(response.Data);
                            document.getElementById('hfTotalUsersCount').value = response.Data.length;
                            console.log('Inside show users response');
                            console.log(response);                            
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });
        };

        getData();
    }, []);

    const getUsersData = () => {
        showLoader();

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAdditionalUsers?companyId="+Companyid,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //
                        
                        // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                        setComments(response.Data);
                        document.getElementById('hfTotalUsersCount').value = response.Data.length;
                        console.log('Inside show users response');
                       // console.log(response);                            
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.FirstName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.LastName.toLowerCase().includes(search.toLowerCase()) || 
                    comment.EmailId.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    const deleteAdditionalUser = (e) => {
        var userId = e.currentTarget.dataset.refid;           
        var result = window.confirm("Are you sure you want to delete this user?");
            if (!result) {
                return;
            }
            showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteAdditionalUser?UserId="+userId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                        //team created load  all teams
                        customAlertMessage('success', "User deleted successfully");
                        getUsersData();                    
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }

      
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      
      const sendLoginAdditionalUser = (userUserId,userFirstName,userEmailId) => {       
        showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/SendLoginInfo?AUName="+userFirstName + "&AUEmailId="+userEmailId +
            "&SenderName="+Name + "&UserId="+userUserId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                        //team created load  all teams
                        customAlertMessage('success', "Login link sent to user successfully.");
                    
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }

      const deleteSelectedUsers = () =>{            
            var selectedUsers = "";
            var users = comments.filter(
                tdata => tdata.selected == true
            );
            // var chkboxes = document.querySelectorAll(".multiselectchk:checked");
            for (let i = 0; i < users.length; i++) {
                if (selectedUsers === "") {
                    selectedUsers += users[i].UserId;
                }
                else{
                    selectedUsers += ","+ users[i].UserId;
                }
            }
            if(selectedUsers == ""){
                customAlertMessage('error', "Please select users");
                return;
            }
            var result = window.confirm("Are you sure you want to delete selected user(s)?");
            if (!result) {
                return;
            }
            console.log(selectedUsers);
            showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteSelectedAdditionalUser?UserIds="+selectedUsers,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                        //team created load  all teams
                        customAlertMessage('success', "User deleted successfully");
                        getUsersData();                    
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }

      const deleteAllUsers = () =>{
        var result = window.confirm("Are you sure you want to delete all users?");
            if (!result) {
                return;
            }
            showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteAllAdditionalUser?MasterId="+UserID,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                        //team created load  all teams
                        customAlertMessage('success', "Users deleted successfully");
                        getUsersData();                    
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }
      const chkchage = (e) =>{
        //e.currentTarget.checked = !e.currentTarget.checked;
        var identity = e.currentTarget.dataset.identity;
        var imagelist1 = comments;
        var temparraylist = []
        for (let [i, image1] of imagelist1.entries()) {
            if (image1.UserId === (identity)) {
                image1.selected = !image1.selected;                
            }
            temparraylist.push(image1);
        }
        setComments(temparraylist);
      }
      const checkUsersCreation = () =>{
        if (Features.SherlockAI == undefined ? false : Features.SherlockAI) {
            document.getElementById("lnkCreateUserNav").click();
            return;
        }
        var usersC = document.getElementById('hfTotalUsersCount').value
        if (usersC != undefined && usersC != "") {
          var userscInt = parseInt(usersC);
          if(userscInt >= 4){
            alert('Additional user count limit reached, Please contact info@chocolatechips.com.')
          }
          else{
            document.getElementById("lnkCreateUserNav").click();
          }
        }
        else{      
        }    
      }
    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}

            <div className="row w-100">
                <input type="hidden" id="hfTotalUsersCount"></input>
                <div className="col mb-3 col-12 text-center">
                    <div className="row">
                        {/* <div className="col-md-4">                            
                        </div> */}
                        <div className="col-md-9">
                        <span id='spanCreateNew' className="float-start">
                            <Link to="/userslog" className='common-save'>
                                Users Log
                            </Link> &nbsp;
                            <a href='javascript:;' onClick={() => checkUsersCreation()} className="common-save">Create New User</a>
                            <Link id="lnkCreateUserNav" style={{display:"none"}} to="/createuser" className='common-save'>
                                Create New User
                            </Link>
                        </span>
                            <div style={{display:'none'}}>
                            <a href="javascript:;" tabIndex={-1} onClick={() =>deleteSelectedUsers(true)} className="common-clear pad-reduce">Delete</a>
                        <a href="javascript:;" tabIndex={-1} onClick={() =>deleteAllUsers(true)} className="common-clear pad-reduce">Delete All</a>
                                </div>                            
                        
                        </div>
                        <div className="col-md-3 d-flex flex-row-reverse heightsearch">
                            <Search 
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3" style={{marginTop:'9px',textAlign:"left"}}>
                            <b><label>{totalItems > 0 ? totalItems + " Acitve User(s) Found": ""} <span> </span></label></b>
                        </div>
                    </div>
                    <div className="row">
                    <div className="rightside-bar-editor pr5 nominheight">
                    <table className="table table-hover common-table thcenter">
                        <Header
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                        {commentsData.length > 0 ? commentsData.map((comment,index) => (

                            <tr>
                            {/* <td>{((currentPage - 1) * ITEMS_PER_PAGE) + index+1}</td>
                            <td>                                
                                <input id={index+1} className="multiselectchk" type="checkbox" data-identity={comment.UserId} checked={comment.selected} onChange={chkchage}></input>
                            </td> */}
                            <th scope="row" key={comment.UserId}>
                                {comment.FirstName}
                            </th>
                            <td>{comment.LastName}</td>
                            <td>{comment.EmailId}</td>
                            {/* <td>{moment(comment.CreatedDate).format('MMM DD YYYY h:mm A')}</td> */}
                            <td>{moment.utc(comment.CreatedDate).local().format('MM-DD-YYYY h:mm A')}</td>
                            <td>                            
                                {comment.FirstLoginDate != "1900-01-01T00:00:00" ? moment.utc(comment.FirstLoginDate).local().format('MM-DD-YYYY h:mm A'): "No Activity"}
                            </td>
                            <td>
                                <center>
                                    {/* <span tooltip="Send email" flow="down"  style={{color:'green',cursor:'pointer'}} data-refid={comment.UserId} onClick={() => sendLoginAdditionalUser(comment.UserId,comment.FirstName,comment.EmailId)}><FontAwesomeIcon icon={faMailBulk}></FontAwesomeIcon></span>&nbsp; */}
                                    &nbsp;<span tooltip="Delete" flow="down" style={{color:'red',cursor:'pointer',alignItems:'center'}} data-refid={comment.UserId} onClick={deleteAdditionalUser}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>                                
                                </center>                            
                            </td>
                            </tr>
                        )
                        ): 
                        <tr>
                            <td colSpan={8} style={{textAlign:"center"}}>
                            <label>No records to display.</label>
                            </td>
                        </tr>                        
                        }
                           
                        </tbody>
                    </table>
                    </div>
                    </div>
                    <div className="row">
<div className="col-12">
<center>
<Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
</center>
</div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default DataTableUsers;