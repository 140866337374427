import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "../img/sample.gif";
import $ from 'jquery';
import "../Style.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckOutUpdateCard from "./CheckOutUpdateCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";


const stripePromise = loadStripe(`${process.env.REACT_APP_Stripe_PK}`);

class UpdateCard extends React.Component {
    historygoback = () => {
        window.history.back();
      };
// functions end

    //Html start
    render() {
        return (
            <div className="main_content_iner overly_inner ">
            <div className="container-fluid app-height col-12">
<div className='row'>
<div className="col-12">
              <div className="title-card1">
                <h4>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={this.historygoback}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Update Credit Card
                  </span>
                </h4>
                {/* {this.state.emailguid} */}
              </div>
            </div>
</div>
              
                    
                <div className="row">
                    <div className="col-sm-12 text-center" style={{position:'relative'}}>
                    <div id="overlay">
                        <div id="loading">
                            <h2 id="loaderheading">Loading...</h2>
                            <img src={loader} />
                        </div>
                    </div>
                    <Elements stripe={stripePromise}>
                        <CheckOutUpdateCard />
                        </Elements>
                    </div>
                </div>
                <div className="row">
                <div className="col-sm-12 text-center mt-2 anchor-blue">
                                <Link id="lnkPaymentUpdateNav" to="/paymentupdate" style={{textDecoration:"none"}}>
                                Back
                                </Link>
                            </div>  
                            </div>     
            </div>
            </div>
            )
    }
    //Html end
};
export default UpdateCard;