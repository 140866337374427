import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route,HashRouter } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Dashboard from "./pages/Dashboard.js?v=678";
import Email from "./pages/QuillEmail";
import Texttoimage from "./pages/Texttoimage";
import Uploadimage from "./pages/Uploadimage";
import Aiavatar from "./pages/Aiavatar";
import Facebook from "./pages/Facebook";
import Instagram from "./pages/Instagram";
import Twitter from "./pages/Twitter";
import TikTok from "./pages/TikTok";
import Pinterest from "./pages/Pinterest";
import Login from "./login";
import Loginlayoutayout from "./pages/loginlayout";
import Emailtemplate from "./pages/emailtemplate";
import Imagechat from "./pages/ImageChatNew";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import PaymentGateway from "./PaymentGateway";
import Profile from "./pages/profile";
import PaymentUpdate from "./pages/paymentupdate";
import ManageUsers from "./pages/ManageUsers";
import ManageTeams from "./pages/ManageTeams";
import Cookies from 'js-cookie';
import $ from 'jquery';
import UpdateCard from "./pages/UpdateCard";
import ResetPassword from "./ResetPassword";
import TinymceEmail from "./pages/Email";
import CreateUser from "./pages/CreateUser";
import TeamDetailsMain from "./pages/TeamDetailsMain";
import UsersLog from "./pages/UsersLog";
import TeamLog from "./pages/TeamLog";
import TeamUsersLog from "./pages/TeamUsersLog";
import GptVoice from './pages/Gptvoice';
import Plans from "./Plans";
import UpgradePlan from "./pages/UpgradePlan";
import {UserID,Name,Companyid,Role,Rolename, ProfileImg,Features} from './User';
import Adminlayout from "./pages/adminlayout";
import Superadminhome from "./pages/superadminhome";
import Aeloroomgpt from "./pages/aeloroomgpt";
import Integrations from "./pages/Integrations";
import TextToImageSub from "./pages/TextToImageSub";
import ImageTransformSub from "./pages/ImageTransformSub";
import Texttovideo from "./pages/Texttovideo";
import Imageaging from "./pages/Imageaging";
import Imageclassification from "./pages/imageclassification";

import Processimage from "./pages/processimage"


import Propertydescription from "./pages/propertydescription";
import Transformation from "./pages/transformation";
import BringOldImgToBack from "./pages/bringOldImgToBack"
import Restorationimage from "./pages/restorationimage"
import Addcolourstoimg from "./pages/addcolourstoimg"

import Propertyvideo from "./pages/Propertyvideo";
import MarketReportNew from "./pages/MarketReportNew";
import Superadmincreatecompany from "./pages/superadmincreatecompany";
import UpgradePlanSSO from "./pages/UpgradePlanSSO";
import packageJson from "../package.json";
import { getBuildDate } from "./email/templateBase";
import withClearCache from "./ClearCache";
import Create3dmodel from "./pages/create3dmodel";
import Listingdescription from "./pages/listingdescription";
import Aeloadvancednew from "./pages/aeloadvancednew";
import Aelosimplified from "./pages/aelosimplified";
import PaginatedItems from "./pages/Aelodashboard";

import Mls from "./pages/mls";
import Mlsnew from "./pages/mlsnew";
import Sherlocknew from "./pages/sherlocknew";
import Sherlockunauthorizeddetails from "./pages/sherlockunauthorizeddetails";
import Sherlockauthorizeddetails from "./pages/sherlockauthorizeddetails";
// import Sherlockactivity from "./pages/sherlockactivity";
import Sherlocksetup from "./pages/sherlocksetup";
import Mlsintegration from "./pages/mlsintegration";
import Sherlocklive from "./pages/sherlocklive";
import Sherlockwatermark from "./pages/sherlockwatermark";
import Addwhitelisteddomain from "./pages/addwhitelisteddomain";
import Whitelisthistory from "./pages/whitelisthistory";
import Sherlocklivenew from "./pages/sherlocklivenew";
import Sherlocklivebing from "./pages/sherlocklivebing";
import AIVideosData from "./pages/aivideos.js?v=679";

export const getAccessToken = () => Cookies.get('userInfo')
export const getRefreshToken = () => Cookies.get('refresh_token')
// export const isAuthenticated = () => {
//     var userObj = getUserInfo();
//     return true;
// }
export const isAuthenticated = () => !!getAccessToken()
export const getUserInfo = () => Cookies.get('userInfo')
export const checkPayInfo = () => Cookies.get('takepayment')
export const featuresList = () => getAccessToken.Features;
const ClearCacheComponent = withClearCache(MainApp);

//Cookies.set('takepayment', true);

function MainApp(props) {
  return (
    <div>

    </div>
  );
}

export default function App() {
  if(Role == 11) // superadmin
  {
    return (
      <>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Adminlayout/>} />
            <Route index element={<Superadminhome />} />
            <Route path="createcompany" element={<Superadmincreatecompany/>} />
          </Routes>
      </BrowserRouter>

      <ClearCacheComponent />

      </>
    );
  }
  else if(window.location.href.includes("resetpassword")){
    return (
      <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<loginlayoutayout />} />
          <Route index element={<ResetPassword />} />
          <Route path="Login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>

      <ClearCacheComponent />
      </>

    );
  }
  else if(!isAuthenticated())
  {
    return (
      <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<loginlayoutayout />} />
          <Route index element={<Login />} />
          <Route path="Login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />                
        </Routes>
      </BrowserRouter>

      <ClearCacheComponent />
      </>

    );
  }
  else if(isAuthenticated() && checkPayInfo() == "true"){
    return (
      <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<loginlayoutayout />} />
          <Route index element={<Plans />} />
          <Route path="paymentgateway" element={<PaymentGateway />} />
          <Route path="Login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="plans" element={Plans} ></Route>
        </Routes>
      </BrowserRouter>

      <ClearCacheComponent />
      </>

    );
  }  
  else
  {
    if(Features.SherlockAI == true && Features.Dashboard == false){
      return (    
        <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Sherlocknew />} />                            
              {/* <Route path="dashboard" element={<Dashboard />} />           */}
              <Route path="blogs" element={<Blogs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="email" element={<Email />} />
              <Route path="texttoimage" element={<Texttoimage />} />
              <Route path="uploadimage" element={<Uploadimage />} />
              <Route path="aiavatar" element={<Aiavatar />} />
              <Route path="meta" element={<Facebook />} />
              <Route path="instagram" element={<Instagram />} />          
              <Route path="twitter" element={<Twitter />} />
              <Route path="tiktok" element={<TikTok />} />
              <Route path="pinterest" element={<Pinterest />} />
              <Route path="emailtemplate" element={<Emailtemplate />} />
              <Route path="imagechat" element={<Imagechat />} />
              <Route path="profile" element={<Profile />} />
              <Route path="paymentupdate" element={<PaymentUpdate />} />
              <Route path="manageusers" element={<ManageUsers />} />
              <Route path="manageteams" element={<ManageTeams />} />
              <Route path="updatecard" element={<UpdateCard />} />
              <Route path="tinyemail" element={<TinymceEmail />} />
              <Route path="*" element={<NoPage />} />
              <Route path="createuser" element={<CreateUser />} />
              <Route path="teamdetails" element={<TeamDetailsMain></TeamDetailsMain>} />
              <Route path="userslog" element={<UsersLog></UsersLog>}/>
              <Route path="teamslog" element={<TeamLog></TeamLog>}/>
              <Route path="teamsuserslog" element={<TeamUsersLog></TeamUsersLog>}/>
              <Route path="gptvoice" element={<GptVoice/>} />
              {/* <Route path="plans" element={Plans} ></Route> */}     
              <Route path="upgradeplan" element={<UpgradePlan></UpgradePlan>}></Route>   
              <Route path="generate" element={<Aeloroomgpt />} />    
              <Route path="integration" element={<Integrations />} />
              <Route path="texttoimagesub" element={<TextToImageSub />} />
              <Route path="imagetransformsub" element={<ImageTransformSub />} />
              <Route path="texttovideo" element={<Texttovideo />} />
              <Route path="imageaging" element={<Imageaging/>} />
              <Route path="imageclassification" element={<Imageclassification/>} />
  
              <Route path="processimage" element={<Processimage/>} />
  
              <Route path="propertydescription" element={<Propertydescription/>} />
              <Route path="transformation" element={<Transformation/>} />
  
              <Route path="addcolourstoimg" element={<Addcolourstoimg/>} />
  
              <Route path="bringOldImgToBack" element={<BringOldImgToBack/>} />
              <Route path="restorationimage" element={<Restorationimage/>} />
  
              <Route path="propertyvideo" element={<Propertyvideo/>} />
              <Route path="marketreport" element={<MarketReportNew/>} />
              <Route path="upgradeplansso" element={<UpgradePlanSSO/>} />
              <Route path="3dvideo" element={<Create3dmodel/>}/>
              <Route path="listingdescription" element={<Listingdescription/>} />
              <Route path="aeloadvancednew" element={<Aeloadvancednew/>} />
              <Route path="ccaelo" element={<Aelosimplified/>} />
              <Route path="ccaelohistory" element={<PaginatedItems />} />
              <Route path="mls" element={<Mls />} />
              <Route path="mlsnew" element={<Mlsnew />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="sherlock" element={<Sherlocknew />} />
              <Route path="unauthdetails" element={<Sherlockunauthorizeddetails/>} />
              <Route path="authdetails" element={<Sherlockauthorizeddetails/>} />
              {/* <Route path="sherlockactivity" element={<Sherlockactivity />} /> */}
              <Route path="sherlocksetup" element={<Sherlocksetup />} />
              <Route path="mlsintegration" element={<Mlsintegration />} />
              <Route path="livetrack" element={<Sherlocklive/>} />
              <Route path="sherlockwatermark" element={<Sherlockwatermark />} />
              <Route path="addwhitelisteddomain" element={<Addwhitelisteddomain />} />
              <Route path="whitelisthistory" element={<Whitelisthistory />} />
              <Route path="sherlocklivenew" element={<Sherlocklivenew />} />
              <Route path="binglive" element={<Sherlocklivebing />} />
            </Route>
          </Routes>
        </BrowserRouter>
  
        <ClearCacheComponent />
        </>
      );
    }
    else if(window.location.href.includes("AIE=1") || (Features.SherlockAI == false && Features.Dashboard == false && Features.Aivideo == true)){
      return (    
        <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* <Route index element={<Dashboard />} />     */}
              <Route index element={<AIVideosData/>}/>                        
              {/* <Route path="dashboard" element={<Dashboard />} />           */}
              <Route path="blogs" element={<Blogs />} />
              <Route path="contact" element={<Contact />} />
              <Route path="email" element={<Email />} />
              <Route path="texttoimage" element={<Texttoimage />} />
              <Route path="uploadimage" element={<Uploadimage />} />
              <Route path="aiavatar" element={<Aiavatar />} />
              <Route path="meta" element={<Facebook />} />
              <Route path="instagram" element={<Instagram />} />          
              <Route path="twitter" element={<Twitter />} />
              <Route path="tiktok" element={<TikTok />} />
              <Route path="pinterest" element={<Pinterest />} />
              <Route path="emailtemplate" element={<Emailtemplate />} />
              <Route path="imagechat" element={<Imagechat />} />
              <Route path="profile" element={<Profile />} />
              <Route path="paymentupdate" element={<PaymentUpdate />} />
              <Route path="manageusers" element={<ManageUsers />} />
              <Route path="manageteams" element={<ManageTeams />} />
              <Route path="updatecard" element={<UpdateCard />} />
              <Route path="tinyemail" element={<TinymceEmail />} />
              <Route path="*" element={<NoPage />} />
              <Route path="createuser" element={<CreateUser />} />
              <Route path="teamdetails" element={<TeamDetailsMain></TeamDetailsMain>} />
              <Route path="userslog" element={<UsersLog></UsersLog>}/>
              <Route path="teamslog" element={<TeamLog></TeamLog>}/>
              <Route path="teamsuserslog" element={<TeamUsersLog></TeamUsersLog>}/>
              <Route path="gptvoice" element={<GptVoice/>} />
              {/* <Route path="plans" element={Plans} ></Route> */}     
              <Route path="upgradeplan" element={<UpgradePlan></UpgradePlan>}></Route>   
              <Route path="generate" element={<Aeloroomgpt />} />    
              <Route path="integration" element={<Integrations />} />
              <Route path="texttoimagesub" element={<TextToImageSub />} />
              <Route path="imagetransformsub" element={<ImageTransformSub />} />
              <Route path="texttovideo" element={<Texttovideo />} />
              <Route path="imageaging" element={<Imageaging/>} />
              <Route path="imageclassification" element={<Imageclassification/>} />
  
              <Route path="processimage" element={<Processimage/>} />
  
              <Route path="propertydescription" element={<Propertydescription/>} />
              <Route path="transformation" element={<Transformation/>} />
  
              <Route path="addcolourstoimg" element={<Addcolourstoimg/>} />
  
              <Route path="bringOldImgToBack" element={<BringOldImgToBack/>} />
              <Route path="restorationimage" element={<Restorationimage/>} />
  
              <Route path="propertyvideo" element={<Propertyvideo/>} />
              <Route path="marketreport" element={<MarketReportNew/>} />
              <Route path="upgradeplansso" element={<UpgradePlanSSO/>} />
              <Route path="3dvideo" element={<Create3dmodel/>}/>
              <Route path="listingdescription" element={<Listingdescription/>} />
              <Route path="aeloadvancednew" element={<Aeloadvancednew/>} />
              <Route path="ccaelo" element={<Aelosimplified/>} />
              <Route path="ccaelohistory" element={<PaginatedItems />} />
              <Route path="mls" element={<Mls />} />
              <Route path="mlsnew" element={<Mlsnew />} />
              <Route path="sherlock" element={<Sherlocknew />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="unauthdetails" element={<Sherlockunauthorizeddetails/>} />
              <Route path="authdetails" element={<Sherlockauthorizeddetails/>} />
              {/* <Route path="sherlockactivity" element={<Sherlockactivity />} /> */}
              <Route path="sherlocksetup" element={<Sherlocksetup />} />
              <Route path="mlsintegration" element={<Mlsintegration />} />
              <Route path="livetrack" element={<Sherlocklive/>} />
              <Route path="sherlockwatermark" element={<Sherlockwatermark />} />
              <Route path="addwhitelisteddomain" element={<Addwhitelisteddomain />} />
              <Route path="whitelisthistory" element={<Whitelisthistory />} />
              <Route path="sherlocklivenew" element={<Sherlocklivenew />} />
              <Route path="binglive" element={<Sherlocklivebing />} />
              <Route path="aivideo" element={<AIVideosData/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
  
        <ClearCacheComponent />
        </>
      );
    }

    else{

    
    return (    
      <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />                            
            {/* <Route path="dashboard" element={<Dashboard />} />           */}
            <Route path="blogs" element={<Blogs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="email" element={<Email />} />
            <Route path="texttoimage" element={<Texttoimage />} />
            <Route path="uploadimage" element={<Uploadimage />} />
            <Route path="aiavatar" element={<Aiavatar />} />
            <Route path="meta" element={<Facebook />} />
            <Route path="instagram" element={<Instagram />} />          
            <Route path="twitter" element={<Twitter />} />
            <Route path="tiktok" element={<TikTok />} />
            <Route path="pinterest" element={<Pinterest />} />
            <Route path="emailtemplate" element={<Emailtemplate />} />
            <Route path="imagechat" element={<Imagechat />} />
            <Route path="profile" element={<Profile />} />
            <Route path="paymentupdate" element={<PaymentUpdate />} />
            <Route path="manageusers" element={<ManageUsers />} />
            <Route path="manageteams" element={<ManageTeams />} />
            <Route path="updatecard" element={<UpdateCard />} />
            <Route path="tinyemail" element={<TinymceEmail />} />
            <Route path="*" element={<NoPage />} />
            <Route path="createuser" element={<CreateUser />} />
            <Route path="teamdetails" element={<TeamDetailsMain></TeamDetailsMain>} />
            <Route path="userslog" element={<UsersLog></UsersLog>}/>
            <Route path="teamslog" element={<TeamLog></TeamLog>}/>
            <Route path="teamsuserslog" element={<TeamUsersLog></TeamUsersLog>}/>
            <Route path="gptvoice" element={<GptVoice/>} />
            {/* <Route path="plans" element={Plans} ></Route> */}     
            <Route path="upgradeplan" element={<UpgradePlan></UpgradePlan>}></Route>   
            <Route path="generate" element={<Aeloroomgpt />} />    
            <Route path="integration" element={<Integrations />} />
            <Route path="texttoimagesub" element={<TextToImageSub />} />
            <Route path="imagetransformsub" element={<ImageTransformSub />} />
            <Route path="texttovideo" element={<Texttovideo />} />
            <Route path="imageaging" element={<Imageaging/>} />
            <Route path="imageclassification" element={<Imageclassification/>} />

            <Route path="processimage" element={<Processimage/>} />

            <Route path="propertydescription" element={<Propertydescription/>} />
            <Route path="transformation" element={<Transformation/>} />

            <Route path="addcolourstoimg" element={<Addcolourstoimg/>} />

            <Route path="bringOldImgToBack" element={<BringOldImgToBack/>} />
            <Route path="restorationimage" element={<Restorationimage/>} />

            <Route path="propertyvideo" element={<Propertyvideo/>} />
            <Route path="marketreport" element={<MarketReportNew/>} />
            <Route path="upgradeplansso" element={<UpgradePlanSSO/>} />
            <Route path="3dvideo" element={<Create3dmodel/>}/>
            <Route path="listingdescription" element={<Listingdescription/>} />
            <Route path="aeloadvancednew" element={<Aeloadvancednew/>} />
            <Route path="ccaelo" element={<Aelosimplified/>} />
            <Route path="ccaelohistory" element={<PaginatedItems />} />
            <Route path="mls" element={<Mls />} />
            <Route path="mlsnew" element={<Mlsnew />} />
            <Route path="sherlock" element={<Sherlocknew />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="unauthdetails" element={<Sherlockunauthorizeddetails/>} />
            <Route path="authdetails" element={<Sherlockauthorizeddetails/>} />
            {/* <Route path="sherlockactivity" element={<Sherlockactivity />} /> */}
            <Route path="sherlocksetup" element={<Sherlocksetup />} />
            <Route path="mlsintegration" element={<Mlsintegration />} />
            <Route path="livetrack" element={<Sherlocklive/>} />
            <Route path="sherlockwatermark" element={<Sherlockwatermark />} />
            <Route path="addwhitelisteddomain" element={<Addwhitelisteddomain />} />
            <Route path="whitelisthistory" element={<Whitelisthistory />} />
            <Route path="sherlocklivenew" element={<Sherlocklivenew />} />
            <Route path="binglive" element={<Sherlocklivebing />} />
            <Route path="aivideo" element={<AIVideosData/>}/>
          </Route>
        </Routes>
      </BrowserRouter>

      <ClearCacheComponent />
      </>
    );
          }
  }  
  
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);