import { Outlet, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css';
import '../css/style1.css';
import '../css/colors/default.css';
import "../css/login.css"
// import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faBarsStaggered, faMailReply, faEnvelope, faUpload, faImages, faVideoCamera } from '@fortawesome/free-solid-svg-icons'

import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap.bundle';
import React from "react";
import loader from "../img/sample.gif";
import { faFacebook, faInstagram, faPinterest, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "../index"


class loginlayoutayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
  }
  componentDidMount(){
    //this.documentinit();
  }
//   documentinit = () => {
//     $(".open_miniSide").on("click",function () {
//       $(".sidebar").toggleClass("mini_sidebar");
//       $(".main_content ").toggleClass("full_main_content");
//       $(".footer_part ").toggleClass("full_footer");
//       //window.getSelection().anchorNode.wholeText => plagiarism
//   });
//   }

//   handlemenuClick = (event) => {
//     this.setState({active: event.target.closest("li").id});    
//   }
  render(){
  return (
    <>
    <div className="crm_body_bg">    
    <section className="main_content dashboard_part large_header_bg">
      <Outlet />
    </section>
    </div>
    </>
  )
    }
};

export default loginlayoutayout;