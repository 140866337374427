import Stripo from "../email/stripo";
import { EMPTY_TEMPLATE_CSS, EMPTY_TEMPLATE_HTML } from "../email/templateBase";
import "../email/styles.css";
import React from "react";
import queryString from 'query-string';

import { initStripo } from "../email/stripo.helpers"

 class stripoapps extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
		 Templatecss:EMPTY_TEMPLATE_CSS,
     Templatehtml:EMPTY_TEMPLATE_HTML		  
	  }
	  }
	  componentDidMount(){
		const query = queryString.parse(window.location.search);
		const token = query.id;
		if(token != undefined){
			this.loadtemplate(token);
		}
		

	  }

  requestGetTemplateFinal = (method, url, data, callback) => {
		var req = new XMLHttpRequest();
		req.onreadystatechange = function () {
			if (req.readyState === 4 && req.status === 200) {
				callback(req.responseText);
			} else if (req.readyState === 4 && req.status !== 200) {
				console.error('Can not complete request. Please enter valid auth token');
			}
		};
		req.open(method, url, true);
		if (method !== 'GET') {
			req.setRequestHeader('content-type', 'application/json');
			req.setRequestHeader('Header', 'ES-PLUGIN-AUTH: Bearer ' + document.getElementById('hfAuthToken').value);
		}
		req.send(data);
}

  loadDemoTemplate = (cssurl,htmlurl) => {
		var self = this;
		self.requestGetTemplateFinal('GET', htmlurl, null, function (html) {
			self.requestGetTemplateFinal('GET', cssurl, null, function (css) {
        //self.setState({Templatecss:css,Templatehtml:html});
        var pluginId=`${process.env.REACT_APP_stripo_pluginid}`;
        var secretKey=`${process.env.REACT_APP_stripo_secretkey}`;
        setTimeout(() => {
          initStripo({ html, css, pluginId, secretKey });
        }, 3000);
			});
		});
	}
// const Emails = () => {
loadtemplate = (token) => {
var self = this;
	var axios = require('axios');
var config = {
  method: 'get',
maxBodyLength: Infinity,
  url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/gettemplate?id='+token,
  headers: { }
};

axios(config)
.then(function (resp) {
  // console.log(JSON.stringify(resp.data));
  var response = resp.data;
  if(response.Data.length > 0)
  {
  var htmlurl = response.Data[0].htmlurl;
  var cssurl = response.Data[0].cssurl;
  var title = response.Data[0].title;
  var description = response.Data[0].description;
  document.getElementById('templatetitle').value = title;
  document.getElementById('templatedescription').value = description;
  self.loadDemoTemplate(cssurl,htmlurl);
}
})
.catch(function (error) {
  console.log(error);
});

}
  render(){
  return (
    <div className="App">      
      <Stripo
        pluginId={`${process.env.REACT_APP_stripo_pluginid}`}
        secretKey={`${process.env.REACT_APP_stripo_secretkey}`}
        html={EMPTY_TEMPLATE_HTML}
        css={EMPTY_TEMPLATE_CSS}
      />
    </div>


// html={this.state.Templatehtml}
// css={this.state.Templatecss}
// Prod Keys
// pluginId: '8214798e1d81408f906f09b5785a3c10',
// secretKey: '80e31b86d90d4645a1e6ebfcdb461c3e'

// test keys
// pluginId="c0469138054b4b9f8074274558fcb81e"
// secretKey="99e3f6cfe639440db542dfd2c65df5d6"
  )}
}
export default stripoapps;