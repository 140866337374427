import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faInfo,faMicrophone,faInfoCircle, faCircleInfo, faCircleDown, faFile,faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React, {useState} from 'react';
import AudioToText from "./AudioToText";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import copy from 'copy-to-clipboard';
import $ from 'jquery'
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard,requestClipboardWritePermission   } from 'copy-image-clipboard'
import loader from "../img/sample.gif";
import DDLmood from './ddlmood';
import DDLmedium from './ddlmedium';
import DDLinspiration from './ddlinspiration';
import DDLstyle from './ddlstyle';
// import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg,PlanType,TextToImageSub, MasterId,Trail, SSOUser, Features} from "../User";
import imageprocessing from  "../img/sample.gif";//"../img/imageprocessing.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
// import "jquery-nice-select/css/style.css";
// require("jquery-nice-select");
import Cookies from 'js-cookie';
export const getAccessToken = () => Cookies.get('userInfo');

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;

class Texttoimages extends React.Component {
    constructor(props) {
      super(props);
    //   this.selectRef = React.createRef();
      this.state = { 
        keyworddisabled: false,
        copySuccess: '',
        wordcount: 0,
        generatedimages:[]
        // generatedimages: [
        //     {id:1,image:ProfileImg,processing:false,prompt:"",fetchurl:""},
        //     {id:2,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:3,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:4,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:5,image:imageprocessing,processing:true,prompt:"",fetchurl:"https://stablediffusionapi.com/api/v3/fetch/1746944"}
        // ] 
    }
    }
componentDidMount(){
    Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('2').classList.add("active");
    var self = this;
    //check for subscription
    this.checkSubscription();
    //
    // $(this.selectRef.current).niceSelect();    
    setInterval(function () {
        self.checkforprocessingimages();
    }, 5000);
}

checkSubscription = () =>{
    if(PlanType > 0){
        if(!TextToImageSub){
            //this.customAlertMessage("error","Please subscribe to 'Text To Image' Subscription");
            document.getElementById('hrefsubscription').click();
        }
    }
}

checkforprocessingimages = async() =>{
    var processingimages = this.state.generatedimages.filter(
        d => d.processing == true
    );
    for (let i = 0; i < processingimages.length; i++) {
        const element = processingimages[i];
        await this.callprocessingimage(element.id,element.prompt,element.fetchurl);
        
    }
}

handleDownload = (e) => {
    var self = this;
    var url = e.currentTarget.dataset.fileurl;
    // this.showoverlay();
    // const axios = require('axios');
    // const FormData = require('form-data');
    // let data = new FormData();
    // data.append('imgurl', url);

    // let config = {
    // method: 'post',
    // maxBodyLength: Infinity,
    // url: 'https://ai.chocolatechips.ai/watermarkchecker',
    // data : data
    // };

    // axios.request(config)
    // .then((response) => {
    // console.log(JSON.stringify(response.data));
    // if (response.data != undefined) {
    //     if (response.data.output == true) {
    //         self.hideoverlay();
    //         self.customAlertMessage('error','Download not allowed for this imaage');    
    //     }
    //     else{
    //         var axiosint = require('axios');
    //         axiosint.get(url, {
    //             responseType: 'blob',
    //         }).then((res) => {
    //             var filename = uuidv4() + ".png";
    //             fileDownload(res.data, filename);
    //             self.hideoverlay();
    //         })
    //     }            
    // }
   
    // })
    // .catch((error) => {
    // console.log(error);
    // self.hideoverlay();
    // });
    self.showoverlay();
    var axiosint = require('axios');
    axiosint.get(url, {
        responseType: 'blob',
    }).then((res) => {
        var filename = uuidv4() + ".png";
        fileDownload(res.data, filename);
        self.hideoverlay();
    })
}
	// handleDownload = (e) => {
	// 	var url = e.currentTarget.dataset.fileurl;
	// 	var axios = require('axios');
	// 	axios.get(url, {
	// 	  responseType: 'blob',
	// 	})
	// 	.then((res) => {
    //         // if (url.indexOf("?") != -1) {
    //         //     debugger;
    //         //     url = url.substring(0,url.indexOf("?"));
    //         // }
	// 	var filename = uuidv4() + ".png";//  url.substring(url.lastIndexOf('/')+1);
	// 	fileDownload(res.data, filename);
	// 	})
	// }

    customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
    setwordcount = () => {
        var text = document.getElementById('keywordinput').value;
        if(text.trim() == "")
        {
            this.setState({wordcount: 0});
        }
        else{
            const words = text.trim().split(',');
            if(words.length > 50)
            {   
				this.customAlertMessage("warning","Keywords limit exceeded.");
                var trimmedwords = text.trim().split(',').slice(0, 3).join(',');
                document.getElementById('keywordinput').value = trimmedwords;
                this.setState({wordcount: 3});
            }
            else{
                this.setState({wordcount: words.length});
            }           
        }        
    }
    togglekeywords() {
      this.setState( {keyworddisabled: !this.state.keyworddisabled} )
    }

      
    copyToClipboard = (e) => {
        const canCopy = canCopyImagesToClipboard();
        if (issafari) {
            this.copyimageforsafari(e.currentTarget.dataset.fileurl);
        }
        else if (canCopy) {
            copyImageToClipboard(e.currentTarget.dataset.fileurl)
            .then(() => {
              console.log('Image Copied')
              this.customAlertMessage('success','Copied!');
            })
            .catch((e) => {
              console.log('Error: ', e.message)
              this.customAlertMessage('error','Please try again!');
            })
        }
        else {
            this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
        }        
        // copy(e.currentTarget.dataset.fileurl);     
        e.target.focus();
    };

    copyimageforsafari = async(imageurl) => {
        try {
            const item = new ClipboardItem({
                'image/png': (async () => {
                  const response = await fetch(imageurl) // Reference from your source code.
                  return await response.blob()
                })(),
              });
              await navigator.clipboard.write([item])
              this.customAlertMessage('success','Copied!');
        } catch (error) {
            this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
        }
    }      

    geteleValue = (objectid,type) => {
       var text = "";
       if(document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue').length > 0 && document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue')[0].innerText.toLowerCase() != "none"){
        text = type + ": " + document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue')[0].innerText + " , ";
        // text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
       }
       return text;
    }
    gettexteleValue = (objectid,type) => {
        var text = "";
        if(document.getElementById(objectid).value.trim() != ""){
            text =   type + ": " + document.getElementById(objectid).value.trim();
            //text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
        }
        return text;
     }
    calltexttoimage = () => {        
        var self = this;
        var prompt = document.getElementById('textareaoutput').value;
        if(prompt.trim() == ""){
			this.customAlertMessage("error","Please enter text.");
			document.getElementById('textareaoutput').focus();
            return;
		}

        var re = new RegExp('"', 'g');
        prompt = prompt.replace(re, "");
        console.log(prompt);
        var ddlmood = this.geteleValue('ddlmood','Mood');
        var ddlmedium = this.geteleValue('ddlmedium',"Art medium");
        var ddlinspiration = this.geteleValue('ddlinspiration',"inspiration");
        var ddlstyle = this.geteleValue('ddlstyle',"Style");
        var keywords = this.gettexteleValue('keywordinput',"Keywords");
        var prompttext = prompt + " " + ddlmood + ddlmedium + ddlinspiration + ddlstyle + keywords;
        //var formdata = new FormData();
       
        this.showoverlay();
        // var fetchurl = `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/Getdiffusionimage?prompt=" + prompttext.trim('').trim(',') + "&uid="+UserID+"&companyid="+Companyid;
        var fetchurl = `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/Getdalleimage?prompt=" + prompttext.trim('').trim(',') + "&uid="+UserID+"&companyid="+Companyid;
        if(!Features.TextToImageDalle)
        {
            fetchurl = `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/Getclipdropimage?prompt=" + prompttext.trim('').trim(',') + "&uid="+UserID+"&companyid="+Companyid;
        }
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        
        var config = {
          method: 'get',
          url: fetchurl
        };
        
        axios(config)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
           var response = (resp.data);
            if (response.Message == "success" && response.Data != "") {
                var resultcount = self.state.generatedimages.length > 0 ? 
                self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
                // document.getElementById("generatedimageholder").getElementsByClassName('slider').length;
                var jsr = {};
                jsr.id = resultcount + 1;
                jsr.image = response.Data;
                jsr.processing = false;
                jsr.prompt = "";
                jsr.fetchurl = "";
                self.state.generatedimages.push(jsr);
                var joined = self.state.generatedimages.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedimages: joined });
                const element = document.getElementById('scrolldivparent');
                if (element) {
                    element.scrollTop = 0;
                }                
            }
            else if(response.Message == "processing" && response.Data != ""){
                var resultcount = self.state.generatedimages.length > 0 ? 
                self.state.generatedimages.sort(function(a,b) {return b.id - a.id;})[0].id : 0;
                //document.getElementById("generatedimageholder").getElementsByClassName('slider').length;
                var jsr = {};
                jsr.id = resultcount +1;
                jsr.image = imageprocessing;
                jsr.processing = true;
                jsr.prompt = prompttext.trim('');
                jsr.fetchurl = response.Data;
                self.state.generatedimages.push(jsr);
                var joined = self.state.generatedimages.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedimages: joined });
                const element = document.getElementById('scrolldivparent');
                if (element) {
                    element.scrollTop = 0;
                }             
                self.customAlertMessage('info', 'Image processing is taking longer than usual, image will be refreshed automatically.');   
            }
            else
            {
                // Show error
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
            }
            self.hideoverlay();
        })
        .catch(function (error) {
          console.log(error);
          self.customAlertMessage('error', 'Something went wrong, Try Again.');
          self.hideoverlay();
        });
      
    }
    historygoback = () => {
        window.history.back();
    }
    deletesingle = (e) => {
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.generatedimages;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                imagelist.splice(i, 1);
                break;
            }
          }
        this.setState({generatedimages: imagelist});
    }
    delateall = () => {
        this.setState({generatedimages: []});
    }    

    callprocessingimage = async(id,prompt,fetchurl) => {      
        var self = this;
        var prompttext = prompt;
        //var formdata = new FormData();       
        // this.showoverlay();
        var fetchurl = `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/getprocessedimagefordalle?fetchrequest="+ fetchurl+"&prompt=" + prompttext.trim('').trim(',') + "&uid="+UserID+"&companyid="+Companyid;
        
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        
        var config = {
          method: 'get',
          url: fetchurl
        };
        
        axios(config)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
           var response = (resp.data);
            if (response.Message == "success" && response.Data != "") {
                var imagesarr = self.state.generatedimages;
                for (let i = 0; i < imagesarr.length; i++) {
                    const element = imagesarr[i];
                    if (element.id == id) {
                        element.image = response.Data
                        element.processing = false;
                        break;
                    }                    
                }
                var joined = self.state.generatedimages.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedimages: joined });  
            }
            else if(response.Message == "processing" && response.Data != ""){
                //Do nothing
            }
            else
            {
                // Show error
                //self.customAlertMessage('error', 'Something went wrong, Try Again.');
            }
            //self.hideoverlay();
        })
        .catch(function (error) {
          console.log(error);
        //   self.customAlertMessage('error', 'Something went wrong, Try Again.');
        //   self.hideoverlay();
        });
      
    }

    checkimagecounters = (e) => {
        var self = this;
        var targettype = e.currentTarget.dataset.fbtype;
        const axios = require('axios');
        this.showoverlay();
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedImagesCount',
          headers: { }
        };
      
        axios.request(config)
        .then((response) => {
          if (response.data != undefined) {
            if (response.data.Status == "200") {
            var usedcounter = response.data.Data;
            var userI = getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            var parsedRes = JSON.parse(decodedUser); 
            var PlanType = parsedRes.PlanType;  
            var textToImage = parsedRes.TextToImageSub;
            console.log(usedcounter);
            this.hideoverlay();
            if (usedcounter <= 0 && PlanType < 1) {
                if (usedcounter == -9999) {
                    self.customAlertMessage('error', 'You have exhausted the usage limit for images.');
                }
                else{
                    document.getElementById('upgradeModelTextTti').innerText =  'You trial resources have been exhausted. Please upgrade to a paid subscription.';
                    document.getElementById('hrefSubTTI').click();
                }                
                return;
            }
            else if (PlanType > 0 && !textToImage) {
                document.getElementById('hrefsubscription').click();
                return;
            }
            else {
                self.calltexttoimage();                
            }
            }
            else{
                this.hideoverlay();
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
            }
          }
        })
        .catch((error) => {
            this.hideoverlay();
          console.log(error);
        });
      }

      proceedOnUpgrade = () =>{
        document.getElementById('btncancelUpgradeTTI').click();
        if (SSOUser) {      
          document.getElementById('lnkSSOUpgradeTTI').click();
        }
        else{
          document.getElementById('lnkNormalUpgradeTTI').click();
        }
      }

      closeTTISub = () =>{
        window.location.href="/";
      }

    render() {
      return(
        <>
        <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">            
            <div className="row">	 
                <div className="col-12">
                    <div className="title-card1">
                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Text to Image </span>-<i className="small-title">Create images using natural language.</i></h4>
                        
                    </div>
                </div>
                {/* Add your Content here */}
                <div className="col-12">
            <div className="row">
                <div className="col-4">
                <div className="lefside-bar">
                    <div className="row">
                        <div className="col-12 mb-30">
                            <div className="row">
                                <div className="col-12 label-info">
                                    <label>Image Description<span tooltip="Tell chocolatechips.ai what image you would like to see." flow="down"><FontAwesomeIcon icon={faInfoCircle} /></span></label>
                                </div>
                                        <React.StrictMode>
                                            <AudioToText placeholdertext="Describe the image you would like..." />
                                        </React.StrictMode>
                            </div>
                        </div>
                        <div className="col-12 mb-30">
                            <div className="row">
                                <div className="col-12 label-info">
                                    <label>Mood</label>
                                </div>
                                <div className="col-12">
                                <DDLmood />
                                    {/* <select className="common-select" id="ddlmood">
                                        <option value={-1} >None</option>
                                        <option value={1}>Calm</option>
                                        <option value={2}>Carefree</option>
                                        <option value={3}>Fun</option>
                                        <option value={4}>Gloomy</option>
                                        <option value={5}>Happy</option>                                            
                                        <option value={6}>Romantic</option>                                            
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-30">
                            <div className="row">
                                <div className="col-12 label-info">
                                    <label>Medium</label>
                                </div>
                                <div className="col-12">
                                    <DDLmedium></DDLmedium>
                                    {/* <select className="common-select" id="ddlmedium">
                                        <option value={-1}>None</option>
                                        <option value={1}>Chalk</option>
                                        <option value={2}>Charcoal</option>
                                        <option value={3}>Crayon</option>
                                        <option value={4}>Oil</option>
                                        <option value={5}>Pastel</option>
                                        <option value={6}>Pencil</option>
                                        <option value={7}>Watercolor</option>
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-30">
                            <div className="row">
                                <div className="col-12 label-info">
                                    <label>Inspiration</label>
                                </div>
                                <div className="col-12">
                                    <DDLinspiration></DDLinspiration>
                                    {/* <select className="common-select" id="ddlinspiration">
                                        <option value={-1}>None</option>
                                        <option value={1}>Caravaggio</option>
                                        <option value={2}>da Vinci</option>
                                        <option value={3}>Henri Matisse</option>
                                        <option value={4}>Jackson Pollock</option>
                                        <option value={5}>Michelangelo</option>     
                                        <option value={6}>Monet</option>                                     
                                        <option value={7}>Picasso</option>
                                        <option value={8}>Van Gogh</option>
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-30">
                            <div className="row">
                                <div className="col-12 label-info">
                                    <label>Style</label>
                                </div>
                                <div className="col-12">
                                    <DDLstyle></DDLstyle>
                                    {/* <select className="common-select" id="ddlstyle">
                                        <option value={-1}>None</option>
                                        <option value={1}>Abstract</option>
                                        <option value={2}>Anime</option>
                                        <option value={3}>Cubism</option>
                                        <option value={4}>Digital</option>
                                        <option value={5}>Fantasy</option>                                        
                                        <option value={6}>Impressionist</option>
                                        <option value={7}>Modern</option>
                                        <option value={8}>Pop</option>                                       
                                        <option value={9}>Surrealism</option>                                        
                                    </select> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-9 label-info">
                                    <label>Keywords<span tooltip="Write keywords separated by commas." flow="right"><FontAwesomeIcon icon={faCircleInfo} /> </span></label>
                                </div>
                                {/* <div className="col-3 switch-toggle">
                                    <input type="checkbox" id="switch2" onChange={this.togglekeywords.bind(this)} /><label for="switch2">Toggle</label>
                                </div> */}
                                <div className="col-12">
                                    <input id="keywordinput" type="text" className="common-input" onChange={this.setwordcount} disabled = {(this.state.keyworddisabled)? "disabled" : ""} placeholder="Behavior, Vision"/>
                                    <span className="character-limit">{this.state.wordcount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                
                <div className="col-8">
                
                    <div className="row">
                        <div className="col-12" style={{position:"relative"}}>
                        <p style={{fontSize:"13px",lineHeight:"25px"}}><b style={{color:"red"}}>Warning:</b> Please check community guidelines, before posting AI generated images to social media like Instagram. They could lock your account for violations.
                        </p>
                        {this.state.generatedimages.length > 0 ? <>
                        <h5 className="right-header" style={{display:"inline-block"}}>Generated Images: {this.state.generatedimages.length}</h5>
                            <span className='float-end clearnew-btn' onClick={this.delateall} style={{cursor:'pointer'}}>Clear All</span>
                            </>
                            : null}
                        <div id="overlay" className='overlay1'>
                            <div id="loading" style={{top:"50%"}}>
                                <h2 id="loaderheading">Generating...</h2>
                                <img src={loader} />  
                            </div>
                        </div>   
                        <div className="rightside-bar" style={{clear:"both"}} id="scrolldivparent">          
                        <div id="generatedimageholder">
                            {this.state.generatedimages ? this.state.generatedimages.map((d) => (
                                    <div className="col-12 slider">
                                    <div className="grey-box">
                                        <div className="row">
                                            <div className="col-12 top-box">
                                                {/* <span className="float-start"><h5 className="right-header">Image {d.id}</h5></span>     */}
                                                <span className="float-end tooltip1" tooltip="Delete" flow="down">
                                                    <a href='javascript:;' onClick={this.deletesingle} data-fileurl={d.id} >
                                                        <FontAwesomeIcon icon={faTimes} style={{marginLeft:'10px',fontSize:'20px'}}/>
                                                    </a>
                                                </span>                                         
                                                <span className='float-end tooltip1' data-fileurl={d.image} onClick={async (e) => await this.copyToClipboard(e)} tooltip="Copy Image" flow="down">
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                                <span className="float-end tooltip1" tooltip="Download" flow="down">
                                                    <a href='javascript:;' onClick={this.handleDownload} data-fileurl={d.image} ><FontAwesomeIcon icon={faCircleDown} /></a>
                                                </span>
                                          
                                                {/* {this.state.copySuccess} */}
                                            </div>
                                            <div className="col-12 text-center top-box">
                                                { d.processing == true ? <img className='ouputimageNewloading' src={d.image} key={Date.now()}/>
                                                : <img style={{maxWidth:'100%'}} src={d.image} key={Date.now()}/> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null }
                        </div>  
                        </div>   
                        </div>

                    </div>
                </div>
                
            </div>
            </div>
            </div>
        </div>

        <div className='bottom-fixed'>
		      <div className="row">
				  <div className="col-4">
				  		<div className="text-center">
                          <a href="javascript:;" className="common-save" onClick={this.checkimagecounters}>Generate</a>
						</div>
				  </div>
			  </div>
		  </div>
          <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>   
        <a href='javascript:;' id='hrefSubTTI' data-bs-toggle="modal"
                          data-bs-target="#upgradeModelLayoutTTI" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>                              

    </div>    
    <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
              <div className="col-12">
                  <p>
                  Text To Image Subscription is required to use this feature.
                  </p>
                  <p> Please click on this&nbsp;
                  <Link to="/texttoimagesub">link</Link>&nbsp;to subscribe
                    </p>  
                  
              </div>
              </div>
              <div className="modal-footer">
                <button type="button" style={{display:"block"}} onClick={this.closeTTISub} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
              </div>
          </div>
      </div>
      </div>
            {/* price sub model start */}
    <div className="modal fade" id="upgradeModelLayoutTTI" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content eulamodalcss">
                        <div className="modal-header">
                            <h5 className="modal-title" id=""><b>Upgrade Subscription</b> </h5>                  
                        </div>
                        <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                            <div className="col-12">
                                <div className="row" style={{marginBottom:"10px"}}>
                                    <div className='col-12'>
                                        <p id="upgradeModelTextTti" style={{color:"#000"}}>Please upgrade your plan to use this feature.</p>
                                    </div>
                                    <div className='col-12' >
                                        <div className="row" style={{marginBottom:"10px",display:"none"}}>
                                            <div className='col-3'>
                                                <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                                                <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                                            </div>
                                        </div>
                                        {/* {SSOUser == true ?
                                        <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                                        <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>}                                     */}
                                    </div>
                                </div>                                           
                            </div>
                        </div>
                        <div className="modal-footer">                        
                        <button type="button" style={{display:"block"}} id='btncancelUpgradeTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
                        <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
                        </div>
                    </div>
                </div>                
            </div>
            {/* price sub model end */}
    </>
      );
    }
  };

export default Texttoimages;
