export const EMPTY_TEMPLATE_HTML = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"><head><meta charset="UTF-8"><meta content="width=device-width, initial-scale=1" name="viewport"><meta name="x-apple-disable-message-reformatting"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta content="telephone=no" name="format-detection"><title></title><!--[if (mso 16)]>
    <style type="text/css">
    a {text-decoration: none;}
    </style>
    <![endif]--><!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]--><!--[if gte mso 9]>
<xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG></o:AllowPNG>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
<!-- </xml> -->
<![endif]--></head><body><div class="es-wrapper-color"><!--[if gte mso 9]>
			<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
				<v:fill type="tile" color="#f6f6f6"></v:fill>
			</v:background>
		<![endif]--><table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"><tbody><tr id="chatGPTcontent"><td class="esd-email-paddings" valign="top"><table class="esd-footer-popover es-content" cellspacing="0" cellpadding="0" align="center"><tbody><tr><td class="esd-stripe" align="center"><table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center"><tbody><tr><td class="es-p20t es-p20r es-p20l esd-structure" align="left"><table width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td class="esd-container-frame" width="560" valign="top" align="center"><table width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td align="left" class="esd-block-text"><p id="chatgptparagraph" contenteditable="true">Type your content here <br /></p></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></div></body></html>
`;
export const EMPTY_TEMPLATE_CSS = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"><head><meta charset="UTF-8"><meta content="width=device-width, initial-scale=1" name="viewport"><meta name="x-apple-disable-message-reformatting"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta content="telephone=no" name="format-detection"><title></title><!--[if (mso 16)]>
    <style type="text/css">
    a {text-decoration: none;}
    </style>
    <![endif]--><!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]--><!--[if gte mso 9]>
<xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG></o:AllowPNG>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
</xml>
<![endif]--></head><body><div class="es-wrapper-color"><!--[if gte mso 9]>
			<v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
				<v:fill type="tile" color="#f6f6f6"></v:fill>
			</v:background>
		<![endif]--><table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td class="esd-email-paddings" valign="top"><table class="esd-footer-popover es-content" cellspacing="0" cellpadding="0" align="center"><tbody><tr><td class="esd-stripe" align="center"><table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center"><tbody><tr><td class="es-p20t es-p20r es-p20l esd-structure" align="left"><table width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td class="esd-container-frame" width="560" valign="top" align="center"><table width="100%" cellspacing="0" cellpadding="0"><tbody><tr><td align="left" class="esd-block-text"><p>Text the content <u>with</u> the best <s>data</s></p></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></div></body></html>
`;

export const ChatGPTemailcontent = `<table class="esd-footer-popover es-content ui-draggable" cellspacing="0" cellpadding="0" align="center"><tbody><tr><td class="esd-stripe esd-frame esdev-disable-select esd-hover" align="center" esd-handler-name="stripeBlockHandler">
<div class="esd-structure-type">
Stripe - Content
</div><div class="esd-block-btn">
        <div class="esd-more"><a><span class="es-icon-dot-3"></span></a></div>        
<div class="esd-save" title="Save as module">
    <a><span class="es-icon-save"></span></a></div>
        
        <div class="esd-move ui-draggable-handle" title="Move">
            <a><span class="es-icon-move"></span></a>
        </div>
        <div class="esd-copy ui-draggable-handle" title="Copy">
            <a><span class="es-icon-copy"></span></a>
        </div>
        
        <div class="esd-delete" title="Delete" disabled="disabled">
            <a><span class="es-icon-delete"></span></a>
        </div>
    </div>
<div class="esd-add-stripe">
    <a><span class="es-icon-plus"></span></a>
    <div class="esd-stripes-popover esd-hidden-right">
        <div class="esd-popover-content">
            
                    <div class="esd-stripe-preview" esd-element-name="structureType_100">
                        
<div class="col-xs-12">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
                    <div class="esd-stripe-preview" esd-element-name="structureType_50_50">
                        
<div class="col-xs-6">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-6">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
                    <div class="esd-stripe-preview" esd-element-name="structureType_33_33_33">
                        
<div class="col-xs-4">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-4">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-4">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
                    <div class="esd-stripe-preview" esd-element-name="structureType_25_25_25_25">
                        
<div class="col-xs-3">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-3">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-3">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-3">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
                    <div class="esd-stripe-preview" esd-element-name="structureType_33_66">
                        
<div class="col-xs-4">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-8">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
                    <div class="esd-stripe-preview" esd-element-name="structureType_66_33">
                        
<div class="col-xs-8">
    <a class="esd-structure-preview"></a>
</div>
<div class="col-xs-4">
    <a class="esd-structure-preview"></a>
</div>
                    </div>
        </div>
    </div>
</div>
<table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center"><tbody class="ui-droppable"><tr class="ui-draggable"><td class="es-p20t es-p20r es-p20l esd-structure esd-frame esdev-disable-select esd-hover" align="left" esd-handler-name="structureBlockHandler">
<div class="esd-structure-type">
        

Structure

</div><div class="esd-block-btn">
        <div class="esd-more"><a><span class="es-icon-dot-3"></span></a></div>
        
<div class="esd-save" title="Save as module">
    <a><span class="es-icon-save"></span></a></div>
        
        <div class="esd-move ui-draggable-handle" title="Move">
            <a><span class="es-icon-move"></span></a>
        </div>
        <div class="esd-copy ui-draggable-handle" title="Copy">
            <a><span class="es-icon-copy"></span></a>
        </div>
        
        <div class="esd-delete" title="Delete" disabled="disabled">
            <a><span class="es-icon-delete"></span></a>
        </div>
    </div><table width="100%" cellspacing="0" cellpadding="0"><tbody class="ui-droppable"><tr class="ui-draggable"><td class="esd-container-frame esd-frame esd-hover esdev-disable-select" width="560" valign="top" align="center" esd-handler-name="containerHandler">
<div class="esd-structure-type">
        

Container

</div><div class="esd-block-btn">
        <div class="esd-more"><a><span class="es-icon-dot-3"></span></a></div>
        
<div class="esd-save" title="Save as module">
    <a><span class="es-icon-save"></span></a></div>
        
        <div class="esd-move ui-draggable-handle" title="Move">
            <a><span class="es-icon-move"></span></a>
        </div>
        <div class="esd-copy ui-draggable-handle" title="Copy">
            <a><span class="es-icon-copy"></span></a>
        </div>
        
        <div class="esd-delete" title="Delete">
            <a><span class="es-icon-delete"></span></a>
        </div>
    </div><table width="100%" cellspacing="0" cellpadding="0"><tbody class="ui-droppable"><tr class="ui-draggable"><td align="left" class="esd-block-text esd-frame esd-hover esdev-disable-select esd-draggable esd-block" esd-handler-name="textElementHandler"><div class="esd-block-btn 
            esd-no-block-library
            ">
        <div class="esd-more"><a><span class="es-icon-dot-3"></span></a></div>
        
        
        <div class="esd-move ui-draggable-handle" title="Move">
            <a><span class="es-icon-move"></span></a>
        </div>
        <div class="esd-copy ui-draggable-handle" title="Copy">
            <a><span class="es-icon-copy"></span></a>
        </div>
        
        <div class="esd-delete" title="Delete">
            <a><span class="es-icon-delete"></span></a>
        </div>
    </div><p id="chatgptparagraph" contenteditable="true">[PHchatgptcontenthere] <br><br><br><br> </p></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table>`;

    export const duplicateresultcontent = `<div class="slider result-view">
    <span class="result-top"><b>[PHCScount] Duplicate result</b> found for  [PHCSquerywords] words.
        Click a result below to see your content highlighted.</span>
    [PHCSparagraphlist]
</div>`;
    export const duplicateresultparacontenttemplate = `    <p>
    <a href="[PHCSurl]" class="rt" target="_blank"><u>[PHCStitle]</u></a><br>
    [PHCShtmlsnippet]... <br>
    <font color="#006600">[PHCSurltext]</font><br>
</p>`;

export const truidraggable =`<tr class="ui-draggable">
<td align="left" class="esd-block-text esd-frame esd-hover esd-draggable esd-block esdev-enable-select" contenteditable="true" esd-handler-name="textElementHandler"><div class="esd-block-btn 
        esd-no-block-library
        ">
    <div class="esd-more"><a><span class="es-icon-dot-3"></span></a></div>
    
    
    <div class="esd-move ui-draggable-handle" title="Move">
        <a><span class="es-icon-move"></span></a>
    </div>
    <div class="esd-copy ui-draggable-handle" title="Copy">
        <a><span class="es-icon-copy"></span></a>
    </div>
    
    <div class="esd-delete" title="Delete">
        <a><span class="es-icon-delete"></span></a>
    </div>
</div>
    <p contenteditable="true">[PHchatgptnewpara]</p>
</td>
</tr>`;

export const imgchattext= `<div class="counterclass container-input">
<div class="arrow">
    <div class="outer"></div>
    <div class="inner"></div>
</div>
<div class="message-body">
    <p>[PHtextonchat]</p>
</div>
</div>`;

export const imgchatimage = `<div className='counterclass outputimagegen'>                                                    
<span id="downloadimagespan" className="float-start" tooltip="Download" flow="right">
    <a id="downloadimagehref" data-fileurl={this.state.processedimage} href='javascript:;' onClick={this.handleDownload} ><FontAwesomeIcon icon={faCircleDown} /></a>
</span>
<span id="copyimagespan" className='float-start' data-fileurl={this.state.processedimage} onClick={this.copyToClipboard} tooltip="Copy Image" flow="right">
    <FontAwesomeIcon icon={faCopy} />
</span>
<img id="outputimage" className='ouputimageNew' src={"https://openaiimages.s3.us-west-1.amazonaws.com/styles/fadd980e-52ce-483d-9004-8aa7eb6647cf.jpg"}/>
</div>`;


export const fbexampletemplate = `<div class="row"><div class="col-9 output-length1"><input id="fbheadlineexample" type="text" class="exampleinputs" placeholder="Save money and be efficient" style="width: 100%;"></div><div class="col-3"><img src="/static/media/delete.019a40c971500fcf90ac0d7cb28a3144.svg" style="height: 20px; margin-left: -10px;"><img src="/static/media/add.11122d72cf5ca0bf1dbb4b73d951c7bb.svg" style="height: 20px; margin-left: 5px;"></div></div>`