import { useEffect, useRef, useState } from "react";
import {
  EmailId,
  UserID,
  EulaValue,
  Features,
  Role,
  Companyid,
  MasterId,
} from "../User";
import loader from "../img/sample.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faFile,
  faFilePdf,
  faCloudArrowUp,
  faCheck,
  faShield,
  faShieldAlt,
  faStar,
  faList,
  faSmileBeam,
  faUserTie,
  faCheckSquare,
  faLightbulb,
  faClose,
  faXmark,
  faSearch,
  faDeleteLeft,
  faRemove,
  faShieldHalved,
  faEdit,
  faEye,
  faEyeSlash,
  faChevronUp,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";
import grammarlypdf from "../pdf/grammarly.pdf";
import copyscapepdf from "../pdf/copyscape.pdf";
import shieldicon from "../img/shieldicon.png";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import heic2any from "heic2any";
import { n2d } from "n2d";
import moment from "moment";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import {
  duplicateresultcontent,
  duplicateresultparacontenttemplate,
  ChatGPTemailcontent,
  truidraggable,
} from "../email/templateBase";
import copy from "copy-to-clipboard";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import ContentLoader, { List } from "react-content-loader";
import Sherlockliveunauthorizedlist from "./sherlockliveunauthorizedlist";
import Sherlockliveauthorizedlist from "./sherlockliveauthorizedlist";
import Sherlockliveunauthorizeddetailslist from "./sherlockliveunauthorizeddetailslist";
import Sherlockliveauthorizeddetailslist from "./sherlockliveauthorizeddetailslist";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import SummaryTool from "node-summary"

export const getAccessToken = () => Cookies.get("userInfo");

const MyListLoader = () => <List speed={1} backgroundColor="#808080" foregroundColor="#ecebeb" />;
const CategoryLoader = (props) => (
  <ContentLoader 
    speed={2}
    // width={700}
    // height={300}
    viewBox="0 0 700 300"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="12" y="35" rx="0" ry="0" width="6" height="246" /> 
    <rect x="14" y="34" rx="0" ry="0" width="408" height="6" /> 
    <rect x="416" y="34" rx="0" ry="0" width="6" height="246" /> 
    <rect x="12" y="276" rx="0" ry="0" width="408" height="6" /> 
    <rect x="150" y="53" rx="6" ry="6" width="127" height="15" /> 
    <rect x="37" y="77" rx="7" ry="7" width="361" height="139" /> 
    <rect x="58" y="225" rx="0" ry="0" width="316" height="8" /> 
    <rect x="86" y="238" rx="0" ry="0" width="267" height="8" /> 
    <rect x="58" y="252" rx="0" ry="0" width="316" height="8" />
  </ContentLoader>
)
const MyLoader = () => (
  <ContentLoader
    speed={1}
    viewBox="0 0 200 20"
    backgroundColor="#808080"
    foregroundColor="#ecebeb"
    style={{ width: "100%" }}
  >
    <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
  </ContentLoader>
);

export const ismobile = () => {
  return window.screen.width <= 600;
};

const Sherlocklive = () => {
  var t = 0;
  const [query, setQuery] = useState("");
  const [upload_div_visibility, setupload_div_visibility] = useState(true);
  const autoCompleteRef = useRef(null);
  const [locationId, setLocationId] = useState("");
  const [generatedcontent, setgeneratedcontent] = useState("");
  const [copySacpeAvailable, setDataprop] = useState(false);
  const [grammarlyKeyData, setgrammarlyKeyData] = useState("");
  const [plagiarismmodalbody, setplagiarismmodalbody] = useState({
    __html: "",
  });
  const [uploadedfile, setuploadedfile] = useState([]);
  const [processedimage, setprocessedimage] = useState([]);
  const [orderedprocessedimage, setorderedprocessedimage] = useState([]);
  const [dropdownvalues, setdropdownvalues] = useState([]);
  const [videocreatedformdata, setvideocreatedformdata] = useState({});
  const [uploadedimages, setuploadedimages] = useState([]);
  const [processid, setprocessid] = useState("");
  const [analyzingroomtype, setanalyzingroomtype] = useState(null);
  const [imagecaption, setimagecaption] = useState([]);
  const [roomtypes, setroomtypes] = useState([]);
  const [compliancepassed, setcompliancepassed] = useState(null);
  const [listingdescriptionstatus, setlistingdescriptionstatus] = useState(-1);
  const [fileidentity, setfileidentity] = useState("");
  const [showdeclutter, setshowdeclutter] = useState(false);
  const localimagebasepath =
    `${process.env.REACT_APP_API_URL}` + "/Uploads/MLSCOMPLIANCE/";
  // const localimagebasepath = "https://localhost:44372/Uploads/MLSCOMPLIANCE/";

  const [brushradius, setbrushradius] = useState(20);
  const [declutterimagewidth, setdeclutterimagewidth] = useState(0);
  const [declutterimageheight, setdeclutterimageheight] = useState(0);
  const [clearcanvas, setclearcanvas] = useState(false);
  const [showdeclutteruntouched, setshowdeclutteruntouched] = useState("");
  const [
    compliancefailedimagefordeclutter,
    setcompliancefailedimagefordeclutter,
  ] = useState(null);
  const [decluttersourceimageuntouched, setdecluttersourceimageuntouched] =
    useState(null);
  const [declutterimagemask, setdeclutterimagemask] = useState(null);
  const [compliancefailedimage, setcompliancefailedimage] = useState(null);
  const [declutteridentity, setdeclutteridentity] = useState(null);
  const setRadius = (value) => {
    setbrushradius(value);
  };
  const [dashunauthorizedcount, setdashunauthorizedcount] = useState(-1);
  const [dashtotalcount, setdashtotalcount] = useState(-1);
  const [
    Sherlockliveunauthorizedlistdata,
    setSherlockliveunauthorizedlistdata,
  ] = useState([]);
  const [Sherlockliveauthorizedlistdata, setSherlockliveauthorizedlistdata] =
    useState([]);
  const [liveresponsedata, setliveresponsedata] = useState([]);
  const [unauthorizedlistfordetails, setunauthorizedlistfordetails] = useState([]);
  const [unauthorizedlistfordetailsdomain , setunauthorizedlistfordetailsdomain] = useState("");
  const [authorizedlistfordetails, setauthorizedlistfordetails] = useState([]);
  const [authorizedlistfordetailsdomain , setauthorizedlistfordetailsdomain] = useState("");
  const [imageurlinput,setimageurlinput] = useState("");
  const [progressval,setprogressval] = useState(0)
  const [livedatarequest,setlivedatarequest] = useState([]);
  const cleanCanvas = () => {};
  let progressInterval;
  let progressIntervalhtml;
  const abortController = new AbortController();
  var ajaxrequest;

  // useEffect(()=>{
  //   // setprogressval(2);
  //   // progressInterval = setInterval(incrementProgressBar, 2000);
  //   document.getElementById('progress').style.width = '0%';
  //   document.getElementById('progresspercent').innerText = + '0%';
  //   progressIntervalhtml = setInterval(incrementProgressBarhtml, 1000);
  // })

  useEffect(() => {
    // Code that relies on updated state
    if (uploadedfile.length > 0 && livedatarequest.length >= uploadedfile.length) {
      uploadcrawldatatoserver();
    }
    else if (livedatarequest.length > 0 && document.getElementById('imgurlinput').value.trim() != "") {
      uploadcrawldatatoserver();
    }
  }, [livedatarequest]);
  

  const dropzonechange = (e) => {
    try {
      var tempfilesarr = e.target.files; // $(".dropzone")[0].files;
      if (tempfilesarr.length <= 0) {
        return;
      }
      // showoverlaytwo();
      var tempfileaftervalidation = [];
      var uploadedfilearray = []; // uploadedfile;
      var containsiphonefiles = false;
      var invalidfilecount = 0;
      for (let i = 0; i < tempfilesarr.length; i++) {
        const element = tempfilesarr[i];
        var validfile =
          element.type === "image/jpg" ||
          element.type === "image/jpeg" ||
          element.type === "image/webp" ||
          element.type === "image/png";
        if (
          !validfile &&
          element.name.split(".").pop() != "heif" &&
          element.name.split(".").pop() != "heic"
        ) {
          customAlertMessage("error", "File type not supported files removed.");
          invalidfilecount += 1;
          continue;
        }
        // if (element.size < 10485760) {
        if (true) {
          var uploadedfile_json = {};
          var tempfileaftervalidation_json = {};
          if (
            element.name.split(".").pop() != "heif" &&
            element.name.split(".").pop() != "heic"
          ) {
            // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
            // if (alreadyuploadedfile.length > 0) {
            //     continue;
            // }
            var uniqueid = uuidv4();
            uploadedfile_json.identity = uniqueid;
            uploadedfile_json.binaryfile = element;
            uploadedfilearray.push(uploadedfile_json);

            tempfileaftervalidation_json.identity = uniqueid;
            tempfileaftervalidation_json.binaryfile = element;
            tempfileaftervalidation.push(tempfileaftervalidation_json);
            // console.log('file added: '+ element.name);
          } else {
            containsiphonefiles = true;
          }
        } else {
          customAlertMessage(
            "error",
            "Maximum file size exceeded images removed."
          );
          invalidfilecount += 1;
        }
      }
      if (containsiphonefiles) {
        for (let i = 0; i < tempfilesarr.length; i++) {
          const element = tempfilesarr[i];
          if (
            element.name.split(".").pop() == "heif" ||
            element.name.split(".").pop() == "heic"
          ) {
            var reader = new FileReader();
            reader.onload = function (e) {
              // showoverlaytwo();
              fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) =>
                  heic2any({ blob, toType: "image/jpeg", quality: 0.1 })
                )
                .then((conversionResult) => {
                  // conversionResult is a BLOB
                  // of the PNG formatted image
                  var url = URL.createObjectURL(conversionResult);
                  var file = new File([conversionResult], uuidv4() + ".jpg");
                  // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                  // if (alreadyuploadedfile.length <= 0) {
                  //     continue;
                  // }
                  var uploadedfile_json = {};
                  var tempfileaftervalidation_json = {};
                  var uniqueid = uuidv4();
                  uploadedfile_json.identity = uniqueid;
                  uploadedfile_json.binaryfile = file;
                  uploadedfilearray.push(uploadedfile_json);

                  tempfileaftervalidation_json.identity = uniqueid;
                  tempfileaftervalidation_json.binaryfile = file;
                  tempfileaftervalidation.push(tempfileaftervalidation_json);
                  // console.log('file added iphone: '+ file.name);
                  // console.log('files added : '+ tempfileaftervalidation.length);
                  if (
                    tempfilesarr.length <=
                    invalidfilecount + uploadedfilearray.length
                  ) {
                    // console.log('fired to load inside iphone')
                    setTimeout(() => {
                      readFile(tempfileaftervalidation);
                      setuploadedfile((uploadedfile) => [
                        ...uploadedfile,
                        ...uploadedfilearray,
                      ]);
                    }, 2000);
                  }
                })
                .catch((e) => {
                  // see error handling section
                  //customAlertMessage('error','Error occured while processing an image, please try again.');
                  // hideoverlaytwo();
                  invalidfilecount += 1;
                  if (
                    tempfilesarr.length <=
                    invalidfilecount + uploadedfilearray.length
                  ) {
                    readFile(tempfileaftervalidation);
                    setuploadedfile((uploadedfile) => [
                      ...uploadedfile,
                      ...uploadedfilearray,
                    ]);
                  }
                });
            };
            reader.readAsDataURL(element);
          }
        }
      } else {
        readFile(tempfileaftervalidation);
        setuploadedfile((uploadedfile) => [
          ...uploadedfile,
          ...uploadedfilearray,
        ]);
        // console.log('fired to load outside iphone')
      }
    } catch (error) {
      console.log(error);
      hideoverlaytwo();
    }
  };
  const dropzonedragenter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    $(".dropzone-wrapper").addClass("dragover");
  };
  const dropzonedragover = (e) => {
    e.preventDefault();
    e.stopPropagation();
    $(".dropzone-wrapper").addClass("dragover");
  };
  const dropzonedragleave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    $(".dropzone-wrapper").removeClass("dragover");
  };
  const dropzonefiledrop = (event) => {
    event.preventDefault();
    var tempfilesarr = event.dataTransfer.files;
    var tempfileaftervalidation = [];
    var uploadedfilearray = []; // uploadedfile;
    var containsiphonefiles = false;
    var invalidfilecount = 0;
    for (let i = 0; i < tempfilesarr.length; i++) {
      const element = tempfilesarr[i];
      var validfile =
        element.type === "image/jpg" ||
        element.type === "image/jpeg" ||
        element.type === "image/webp" ||
        element.type === "image/png";
      if (
        !validfile &&
        element.name.split(".").pop() != "heif" &&
        element.name.split(".").pop() != "heic"
      ) {
        customAlertMessage("error", "File type not supported files removed.");
        invalidfilecount += 1;
        continue;
      }
      // if (element.size < 10485760) {
      if (true) {
        var uploadedfile_json = {};
        var tempfileaftervalidation_json = {};
        if (
          element.name.split(".").pop() != "heif" &&
          element.name.split(".").pop() != "heic"
        ) {
          var uniqueid = uuidv4();
          uploadedfile_json.identity = uniqueid;
          uploadedfile_json.binaryfile = element;
          uploadedfilearray.push(uploadedfile_json);

          tempfileaftervalidation_json.identity = uniqueid;
          tempfileaftervalidation_json.binaryfile = element;
          tempfileaftervalidation.push(tempfileaftervalidation_json);
        } else {
          containsiphonefiles = true;
        }
      } else {
        customAlertMessage(
          "error",
          "Maximum file size exceeded images removed."
        );
        invalidfilecount += 1;
      }
    }
    if (containsiphonefiles) {
      for (let i = 0; i < tempfilesarr.length; i++) {
        const element = tempfilesarr[i];
        if (
          element.name.split(".").pop() == "heif" ||
          element.name.split(".").pop() == "heic"
        ) {
          var reader = new FileReader();
          reader.onload = function (e) {
            // showoverlaytwo();
            fetch(e.target.result)
              .then((res) => res.blob())
              .then((blob) =>
                heic2any({ blob, toType: "image/jpeg", quality: 0.1 })
              )
              .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult], uuidv4() + ".jpg");
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                var uniqueid = uuidv4();
                uploadedfile_json.identity = uniqueid;
                uploadedfile_json.binaryfile = file;
                uploadedfilearray.push(uploadedfile_json);

                tempfileaftervalidation_json.identity = uniqueid;
                tempfileaftervalidation_json.binaryfile = file;
                tempfileaftervalidation.push(tempfileaftervalidation_json);
                if (
                  tempfilesarr.length <=
                  invalidfilecount + uploadedfilearray.length
                ) {
                  readFileondrop(tempfileaftervalidation);
                  setuploadedfile((uploadedfile) => [
                    ...uploadedfile,
                    ...uploadedfilearray,
                  ]);
                }
              })
              .catch((e) => {
                // see error handling section
                //customAlertMessage('error','Error occured while processing an image, please try again.');
                // hideoverlaytwo();
                invalidfilecount += 1;
                if (
                  tempfilesarr.length <=
                  invalidfilecount + uploadedfilearray.length
                ) {
                  readFile(tempfileaftervalidation);
                  setuploadedfile((uploadedfile) => [
                    ...uploadedfile,
                    ...uploadedfilearray,
                  ]);
                }
              });
          };
          reader.readAsDataURL(element);
        }
      }
    } else {
      readFileondrop(tempfileaftervalidation);
      setuploadedfile((uploadedfile) => [
        ...uploadedfile,
        ...uploadedfilearray,
      ]);
    }
    // $(".dropzone")[0].value = "";
  };

  const readFileondrop = (inputfiles) => {
    var singletimearray = []; // processedimage;
    var propertyorderedlist = []; // [...orderedprocessedimage];
    let tempprocessedfiles = 0;
    for (let i = 0; i < inputfiles.length; i++) {
      const element = inputfiles[i].binaryfile;
      var reader = new FileReader();
      reader.onload = function (e) {
        tempprocessedfiles += 1;
        var uniqimg = {};
        var timingidentity = moment().valueOf();
        uniqimg.refid = inputfiles[i].identity;
        uniqimg.id = timingidentity;
        uniqimg.image = e.target.result;
        singletimearray.push(uniqimg);
        if (orderedprocessedimage.length > 0) {
          var selem = {};
          selem.orderid = orderedprocessedimage.length + (i + 1);
          selem.id = timingidentity;
          selem.refid = inputfiles[i].identity;
          selem.image = e.target.result;
          selem.binaryfile = element;
          propertyorderedlist.push(selem);
        }
        if (tempprocessedfiles == inputfiles.length) {
          var processedimagenew = singletimearray.sort(function (a, b) {
            return a.id - b.id;
          });
          setprocessedimage((processedimage) => [
            ...processedimage,
            ...processedimagenew,
          ]);
          setorderedprocessedimage((orderedprocessedimag) => [
            ...orderedprocessedimag,
            ...propertyorderedlist,
          ]);
          // hideoverlaytwo();
          // showoverlaytwo();
          // setTimeout(() => {
          //     loaddropdownfromstate();
          // }, 2000);
        }
      };
      reader.readAsDataURL(element);
    }
  };

  // const showdeclutteruntouched=()=>{

  // }

  // const toggledeclutterimage=()=>{
  //     setshowdeclutteruntouched(!showdeclutteruntouched);
  // }

  const usedeclutteredimage = () => {
    try {
      var declutterimageidentity = declutteridentity;
      var url = document.getElementById("declutterimagesource").src;
      const sourcedeclutterURL = url;
      const decluttermimeType = "image/jpeg";
      const declutterfilename = uuidv4() + ".jpeg";
      urlToFile(sourcedeclutterURL, decluttermimeType, declutterfilename)
        .then((file) => {
          uploadsingleimagestoserver(file, processid, declutterimageidentity);
          setshowdeclutter(false);
          document.getElementById("modaldismissbutton").click();
        })
        .catch((error) => {
          console.error("There was an error:", error);
          customAlertMessage(
            "error",
            "Error while processing image, Please try again."
          );
          hideoverlaydeclutter();
        });
    } catch (error) {
      console.error("There was an error:", error);
      customAlertMessage(
        "error",
        "Error while processing image, Please try again."
      );
      hideoverlaydeclutter();
    }
  };

  // const editcompliance=(e)=>{
  //     var identity  = e.currentTarget.dataset.filerefid;
  //     var declutterelement = uploadedimages.find(x=>x.image === identity);
  //     if (declutterelement != undefined && declutterelement.compliancefailedimagefordeclutter != "") {

  //         // tempuploadedimagearray.compliancefailedimagefordeclutter = element.srcimgurl;
  //         // tempuploadedimagearray.compliancefailedimagemask = element.maskurl;
  //         // tempuploadedimagearray.sourceimageheight = element.srcImgH;
  //         // tempuploadedimagearray.sourceimagewidth = element.srcImgW;
  //         setcompliancefailedimagefordeclutter(declutterelement.compliancefailedimagefordeclutter);
  //         setdecluttersourceimageuntouched(declutterelement.compliancefailedimage);
  //         setdeclutterimageheight(declutterelement.sourceimageheight);
  //         setdeclutterimagewidth(declutterelement.sourceimagewidth);
  //         setdeclutterimagemask(declutterelement.compliancefailedimagemask);
  //         setcompliancefailedimage(declutterelement.compliancefailedimage);
  //         setdeclutteridentity(identity);
  //         setshowdeclutteruntouched(true);
  //         setshowdeclutter(true);
  //         document.getElementById('hrefsubscription').click();
  //         document.getElementById('declutterimagesource').src = declutterelement.compliancefailedimagefordeclutter;
  //         document.getElementById('declutterimagesource').crossOrigin = 'anonymous'
  //     }
  //     else{
  //         customAlertMessage('error','Please use remove or replace option.Currently we are unable to process this image.');
  //     }
  //   }
  const hidedeclutter = (e) => {
    setshowdeclutter(false);
  };

  const base64ToFile = (base64, mimeType, filename) => {
    // Decode the base64 string
    const byteString = atob(base64.split(",")[1]);
    // Create a Uint8Array from the byteString
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
    // Create a Blob with the desired MIME type
    const blob = new Blob([byteArray], { type: mimeType });
    // Create a File object from the Blob
    const file = new File([blob], filename, { type: mimeType });
    return file;
  };

  // const savecanvasold=()=>{
  //     showoverlaydeclutter();
  //     try {
  //         var canvas = document.getElementsByTagName('canvas')[1];
  //     const ctx = canvas.getContext('2d');
  //     let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  //     const tempCanvas = document.createElement('canvas');
  //     tempCanvas.width = canvas.width;
  //     tempCanvas.height = canvas.height;
  //     const tempCtx = tempCanvas.getContext('2d');
  //     // Define a threshold to consider a pixel as part of the mask
  //     // Here, we are considering any pixel that's not fully white as part of the mask
  //     const threshold = 250;

  //     // for (let i = 0; i < imageData.data.length; i += 4) {
  //     //     let r = imageData.data[i];
  //     //     let g = imageData.data[i+1];
  //     //     let b = imageData.data[i+2];

  //     //     // If the pixel is not white, set it to black; otherwise, set to white
  //     //     let value = (r < threshold || g < threshold || b < threshold) ? 0 : 255;

  //     //     imageData.data[i] = value;
  //     //     imageData.data[i + 1] = value;
  //     //     imageData.data[i + 2] = value;
  //     // }

  //     for (let i = 0; i < imageData.data.length; i += 4) {
  //         let r = imageData.data[i];
  //         let g = imageData.data[i+1];
  //         let b = imageData.data[i+2];

  //         // Define a threshold to consider a pixel as part of the mask
  //         const threshold = 250;

  //         // If the pixel is white (or near white), set it to black; otherwise, set to white
  //         let value = (r > threshold && g > threshold && b > threshold) ? 0 : 255;

  //         imageData.data[i] = value;
  //         imageData.data[i + 1] = value;
  //         imageData.data[i + 2] = value;
  //     }

  //     tempCtx.putImageData(imageData, 0, 0);
  //     // tempCtx.putImageData(newImageData, 0, 0);
  //     const dataURL = tempCanvas.toDataURL('image/jpeg');
  //     const mimeType = "image/jpeg";
  //     const filename = uuidv4() + ".jpeg";

  //     const maskfile = base64ToFile(dataURL, mimeType, filename);

  //     const sourcedeclutterURL = document.getElementById('declutterimagesource').src;
  //     const decluttermimeType = "image/jpeg";
  //     const declutterfilename = uuidv4() + ".jpeg";
  //     var sourceimage;
  //     if (sourcedeclutterURL.indexOf('base64') != -1) {
  //         sourceimage = base64ToFile(sourcedeclutterURL,decluttermimeType,declutterfilename);
  //         declutter(sourceimage,maskfile);
  //         // this.hideoverlaydeclutter();
  //     }
  //     else{
  //         urlToFile(sourcedeclutterURL,decluttermimeType,declutterfilename).then(file => {
  //             // console.log(file);  // You'll have a File object here
  //             declutter(file,maskfile);
  //             // this.hideoverlaydeclutter();
  //         })
  //         .catch(error => {
  //             console.error("There was an error:", error);
  //             customAlertMessage('error','Error while processing image.')
  //             hideoverlaydeclutter();
  //         });;
  //     }
  //     } catch (error) {
  //         hideoverlaydeclutter();
  //     }
  //     return;
  // }
  // const urlToBase64 =(url)=> {
  //     return fetch(url)
  //         .then(response => {
  //             if (!response.ok) {
  //                 throw new Error(`Failed to fetch resource: ${response.statusText}`);
  //             }
  //             return response.blob();
  //         })
  //         .then(blob => {
  //             return new Promise((resolve, reject) => {
  //                 const reader = new FileReader();
  //                 reader.onloadend = () => {
  //                     resolve(reader.result);
  //                 };
  //                 reader.onerror = () => {
  //                     reject(new Error('Failed to read blob as DataURL'));
  //                 };
  //                 reader.readAsDataURL(blob);
  //             });
  //         });
  // }

  const declutter = (imgsource, imgmask) => {
    const axios = require("axios");
    const FormData = require("form-data");
    // const fs = require('fs');
    let data = new FormData();
    data.append("img", imgsource);
    data.append("mask", imgmask);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://aiapi.aelo.ai/declutter",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (parseInt(response.data.code) == 200) {
          cleanCanvas();
          document.getElementById("declutterimagesource").src =
            response.data.outputurl;
          document.getElementById("declutterimagesource").crossOrigin =
            "anonymous";
          setshowdeclutteruntouched(false);
          setTimeout(() => {
            hideoverlaydeclutter();
          }, 2000); // image refresh time
        } else {
          customAlertMessage("error", "Declutter failed, Please try again.");
          setTimeout(() => {
            hideoverlaydeclutter();
          }, 2000); // image refresh time
        }
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Declutter failed, Please try again.");
        hideoverlaydeclutter();
      });
  };

  const savecanvas = () => {
    showoverlaydeclutter();
    const sourcedeclutterURL = document.getElementById(
      "declutterimagesource"
    ).src; // compliancefailedimagefordeclutter;
    const decluttermimeType = "image/jpeg";
    const declutterfilename = uuidv4() + ".jpeg";
    urlToFile(sourcedeclutterURL, decluttermimeType, declutterfilename)
      .then((file) => {
        // console.log(file);  // You'll have a File object here
        const sourcedecluttermaskURL = declutterimagemask;
        const decluttermaskmimeType = "image/jpeg";
        const decluttermaskfilename = uuidv4() + ".jpeg";
        urlToFile(
          sourcedecluttermaskURL,
          decluttermaskmimeType,
          decluttermaskfilename
        )
          .then((maskfile) => {
            // console.log(file);  // You'll have a File object here
            declutter(file, maskfile);
            // this.hideoverlaydeclutter();
          })
          .catch((error) => {
            console.error("There was an error:", error);
            customAlertMessage("error", "Error while processing image.");
            hideoverlaydeclutter();
          });
      })
      .catch((error) => {
        console.error("There was an error:", error);
        customAlertMessage("error", "Error while processing image.");
        hideoverlaydeclutter();
      });
  };

  const urlToFile = (url, filename, mimeType) => {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch resource: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        return new File([blob], filename, { type: mimeType });
      });
  };

  const showoverlaydeclutter = () => {
    if (ismobile()) {
      showoverlaydecluttermobile();
    } else {
      document.getElementById("overlaydeclutter").style.display = "block";
      $("body").addClass("loader");
    }
  };
  const hideoverlaydeclutter = () => {
    if (ismobile()) {
      hideoverlaydecluttermobile();
    } else {
      document.getElementById("overlaydeclutter").style.display = "none";
      $("body").removeClass("loader");
    }
  };

  const showoverlaydecluttermobile = () => {
    document.getElementById("overlaydecluttermobile").style.display = "block";
    $("body").addClass("loader");
    $("html").addClass("loader");
    // loadermessagesrandom();
  };
  const hideoverlaydecluttermobile = () => {
    document.getElementById("overlaydecluttermobile").style.display = "none";
    $("body").removeClass("loader");
    $("html").removeClass("loader");
  };

  // const localimagebasepath = "https://localhost:44372/Uploads/MLSCOMPLIANCE/";
  const historygoback = () => {
    window.history.back();
  };
  let autoComplete;
  let createnewcontent = false;
  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };
  // function clearPropAddress(){
  //     document.getElementById('txtPropAddress').value ="";
  //     // document.getElementById("div1Result").style.display="none";
  // }

  // const resetpropertydetails=()=>{
  //     document.getElementById("txtArea").value = "";
  //     document.getElementById("ddlFloors").selectedIndex = 0;
  //     document.getElementById("ddlBathrooms").selectedIndex = 0;
  //     document.getElementById("ddlBedrooms").selectedIndex = 0;
  //     document.getElementById("ddlPropertyType").selectedIndex = 0;
  //     document.getElementById('ddlGaragetype').selectedIndex = 0;
  //     document.getElementById('ddlGaragespaces').selectedIndex = 0;

  //     document.querySelectorAll('.chkSchoolAmenities').forEach(element => {
  //         element.checked = false;
  //     });
  //     document.querySelectorAll('.chkHospitalAmenities').forEach(element => {
  //         element.checked = false;
  //     });
  //     document.querySelectorAll('.chkParkAmenities').forEach(element => {
  //         element.checked = false;
  //     });
  //     document.querySelectorAll('.chkSupermarketAmenities').forEach(element => {
  //         element.checked = false;
  //     });
  // }
  const showmaindiv=()=>{
    document.getElementById("main_sherlockinner_div").style.display = "flex";  
    document.getElementById("details_div").style.display = "none";
  }
const resettoinitialonback=()=>{
  resettoinitial();
  document.getElementById("upload_div").style.display = "block";
  document.getElementById("main_div").style.display = "none";
  showuploaddiv();
}
  const resettoinitial = () => {
    setQuery("");
    setLocationId("");
    setuploadedfile([]);
    setprocessedimage([]);
    setorderedprocessedimage([]);
    setuploadedimages([]);
    setprocessid("");
    setanalyzingroomtype(null);
    setimagecaption([]);
    setroomtypes([]);
    setcompliancepassed(null);
    setlistingdescriptionstatus(-1);
    setshowdeclutter(false);

    setbrushradius(20);
    setdeclutterimagewidth(0);
    setdeclutterimageheight(0);
    setclearcanvas(false);
    setshowdeclutteruntouched("");
    setcompliancefailedimagefordeclutter(null);
    setdecluttersourceimageuntouched(null);
    setdeclutterimagemask(null);
    setcompliancefailedimage(null);
    setdeclutteridentity(null);
    document.getElementsByClassName('dropzone')[0].value = "";

    setSherlockliveauthorizedlistdata([]);
    setSherlockliveunauthorizedlistdata([]);
    setdashunauthorizedcount(-1);
    setdashtotalcount(-1);
    document.getElementById('divResult').innerHTML = "";
    setunauthorizedlistfordetails([]);
    setunauthorizedlistfordetailsdomain("");
    setauthorizedlistfordetails([]);
    setauthorizedlistfordetailsdomain("");
    document.getElementById('processingwebhitsspan').innerText = "";
    setimageurlinput("");
    setlivedatarequest([]);
    document.getElementById("imgurlinput").value = "";
    try {      
      showmaindiv();
      if (document.getElementById('processingspan') != null) {
        document.getElementById('processingspan').style.display = 'none';        
      }
      abortController.abort();
      if (ajaxrequest != undefined) {
        ajaxrequest.abort();             
      }

    } catch (error) {
      console.log(error);
    }


    // setTimeout(() => {
    //   document.getElementById("txtPropAddress").value = "";
    //   document.getElementById("div2").classList.add("inactive");
    //   document.getElementById("div3").classList.add("inactive");
    //   document.getElementById("div4").classList.add("inactive");
    //   document.getElementsByClassName("photobreak")[0].innerHTML = "";
    //   document.getElementById("complianceproceed").style.display = "none";

    //   document.getElementById("txtArea").value = "";
    //   document.getElementById("ddlFloors").selectedIndex = 0;
    //   document.getElementById("ddlBathrooms").selectedIndex = 0;
    //   document.getElementById("ddlBedrooms").selectedIndex = 0;
    //   document.getElementById("ddlPropertyType").selectedIndex = 0;
    //   document.getElementById("ddlGaragetype").selectedIndex = 0;
    //   document.getElementById("ddlGaragespaces").selectedIndex = 0;

    //   document.getElementById("divSchoolsData").innerHTML = "";
    //   document.getElementById("divHospitalsData").innerHTML = "";
    //   document.getElementById("divParksData").innerHTML = "";
    //   document.getElementById("divSsupermarketsData").innerHTML = "";

    //   document.getElementById("showstartbtn").style.display = "none";
    //   document.getElementById("divGeneratedContent").innerHTML = "";
    //   document.getElementById("divpropertydetails").style.display = "none";
    //   document.getElementById("editordiv").style.display = "none";
    // }, 500);
  };
  const componentDidMount = () => {
    // load initial data
    // onTabClick(1);
    document.addEventListener("copy", function (e) {
      const text_only = document.getSelection().toString();
      const clipdata = e.clipboardData || window.clipboardData;
      clipdata.setData("text/plain", text_only);
      e.preventDefault();
    });
  };

  // function checkcopyscape(){
  //     const axios = require('axios');
  //     let config = {
  //       method: 'get',
  //       maxBodyLength: Infinity,
  //       url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
  //     };

  //     axios.request(config)
  //     .then((response) => {
  //       if (response.data != null && response.data.Data !="") {
  //         var resd = response.data.Data;
  //         setDataprop(resd);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setDataprop(false);
  //     });
  //   }

  useEffect(() => {
    componentDidMount();
    // loadScript(
    //   `https://maps.googleapis.com/maps/api/js?key=AIzaSyDxpx25xmwMAYNW3Diz0TjL3N8CtTURTK0&libraries=places`,
    //   () => handleScriptLoad(setQuery, autoCompleteRef, setLocationId)
    // );
    // setSherlockliveunauthorizedlistdata(reducedArray);
    // setdashtotalcount(livedata.length);
    // setdashunauthorizedcount(filteredData.length);
  }, [
    Sherlockliveunauthorizedlistdata,
    dashtotalcount,
    dashunauthorizedcount,
    setSherlockliveauthorizedlistdata,
  ]);

  useEffect(()=>{
    console.log(authorizedlistfordetailsdomain);
    console.log(unauthorizedlistfordetailsdomain);
  },
  [unauthorizedlistfordetails,unauthorizedlistfordetailsdomain,authorizedlistfordetailsdomain,authorizedlistfordetails])

  // griddata={authorizedlistfordetails} domainname={authorizedlistfordetailsdomain}

  const populateunauthorizeddetails = (e) => {
    var domainname = e.currentTarget.dataset.domainname;
    let filteredData = liveresponsedata.filter(
      (x) => 
      x.domainname == domainname &&
      x.sitematch == true &&
      x.whitelist == false &&
      x.logopiracy == true &&
      x.screenshoturl != ""
    );
    var unauthorizeddetailslist = [];
    filteredData.forEach((element) => {
      var unauthorizeddetailelem = {};
      unauthorizeddetailelem.matchedurl = element.websiteurl;
      unauthorizeddetailelem.imagesource =
        element.FullMatchedImages.split("|")[0];
      unauthorizeddetailelem.proof = element.screenshoturl;
      unauthorizeddetailelem.dateseen = moment(new Date()).format('MM-DD-YYYY h:mm A');
      unauthorizeddetailelem.imageurl = element.imageurl;
      unauthorizeddetailslist.push(unauthorizeddetailelem);
    });
    setunauthorizedlistfordetails([...unauthorizeddetailslist]);
    setunauthorizedlistfordetailsdomain(domainname);    
    document.getElementById("details_div_authdetails").style.display = "none";
    document.getElementById("details_div_unauthdetails").style.display = "block";
    document.getElementById("main_sherlockinner_div").style.display = "none";
    document.getElementById("details_div").style.display = "flex";
  };

  const populateauthorizeddetails = (e) => {
    var domainname = e.currentTarget.dataset.domainname;
    let filteredData = liveresponsedata.filter(
      (x) => 
      x.domainname == domainname &&
      x.whitelist == true
    );
    var authorizeddetailslist = [];
    filteredData.forEach((element) => {
      var authorizeddetailelem = {};
      authorizeddetailelem.matchedurl = element.websiteurl;
      authorizeddetailelem.imagesource = element.imageurl;
      authorizeddetailelem.dateseen = new Date();
      authorizeddetailslist.push(authorizeddetailelem);
    });
    setauthorizedlistfordetails([...authorizeddetailslist]);
    setauthorizedlistfordetailsdomain(domainname);    
    document.getElementById("details_div_authdetails").style.display = "block";
    document.getElementById("details_div_unauthdetails").style.display = "none";
    document.getElementById("main_sherlockinner_div").style.display = "none";
    document.getElementById("details_div").style.display = "flex";
  };

  const populategrid = (livedata) => {
    let filteredData = livedata.filter(
      (x) =>
        x.sitematch == true &&
        x.whitelist == false &&
        x.logopiracy == true &&
        x.screenshoturl != ""
    );
    const groupedData = filteredData.reduce((acc, curr) => {
      if (!acc[curr.domainname]) {
        acc[curr.domainname] = {
          domainname: curr.domainname,
          domaincount: 0,
          lastupdated: moment(new Date()).format('MM-DD-YYYY h:mm A'),
        };
      }
      acc[curr.domainname].domaincount++;
      return acc;
    }, {});
    // Extract values from the grouped data and concatenate them into a single array
    const reducedArray = [].concat(...Object.values(groupedData));
    //   setSherlockliveunauthorizedlistdata(reducedArray);
    setSherlockliveunauthorizedlistdata([...reducedArray]);
    setdashtotalcount(document.querySelectorAll('#divResult .row').length);
    setdashunauthorizedcount(filteredData.length);
// setdashunauthorizedcount(reducedArray.length);
    let filteredData1 = livedata.filter((x) => x.whitelist == true);
    const groupedData1 = filteredData1.reduce((acc, curr) => {
      if (!acc[curr.domainname]) {
        acc[curr.domainname] = {
          domainname: curr.domainname,
          domaincount: 0,
          lastupdated: new Date(),
        };
      }
      acc[curr.domainname].domaincount++;
      return acc;
    }, {});
    // Extract values from the grouped data and concatenate them into a single array
    const reducedArray1 = [].concat(...Object.values(groupedData1));
    //   setSherlockliveauthorizedlistdata(reducedArray1);
    setSherlockliveauthorizedlistdata([...reducedArray1]);
  };

  // useEffect(() => {
  //     if (analyzingroomtype == false) {
  //         setTimeout(() => {
  //             processcompliance(processid);
  //         }, 1000);
  //     }
  //  }, [analyzingroomtype]);

  //  useEffect(()=>{
  //     // if (compliancepassed) {
  //         // checkcopyscape();
  //     // }
  //  },[compliancepassed])

  //  useEffect(()=>{
  //     startprocess();
  //     console.log('startprocess from useeffect');
  //  },[processid])

  //  useEffect(()=>{
  //     if (processid != "") {
  //         startprocess();
  //         console.log('startprocess single from useeffect');
  //     }
  //  },[fileidentity])

  function handleScriptLoad(updateQuery, autoCompleteRef, updateLocation) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["address"], componentRestrictions: { country: "us" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "place_id",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery, updateLocation)
    );
  }

  async function handlePlaceSelect(updateQuery, UpdateLocation) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    // console.log(addressObject);
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng();
    // console.log(latitude + "," + longitude);
    UpdateLocation(latitude + "," + longitude);
    document.getElementById("hfLocationId").value = latitude + "," + longitude;
    onAddressSelection(latitude, longitude);
    // getBKIPropertyInfo(query);
  }

  function onAddressSelection(funlat, funlng) {
    //alert('inside address selection');
    var locationIdValue = document.getElementById("hfLocationId").value;
    //console.log('address location id '+ locationIdValue);
    // showoverlay();
    setTimeout(() => {
      loadAmenities(funlat, funlng);
    }, 3000);
  }

  function customAlertMessage(icon, message) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  }

  const triggerfileupload = (e) => {
    var dropzonenewbtn =
      e.currentTarget.parentElement.querySelectorAll(".dropzonenew")[0];
    dropzonenewbtn.click();
  };

  
  const showoverlaytwo = () => {
    document.getElementById("overlaytwomls").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlaytwo = () => {
    document.getElementById("overlaytwomls").style.display = "none";
    $("body").removeClass("loader");
  };
  const showoverlay = () => {
    return;
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlay = () => {
    return;
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };

  const showoverlay_s_i = (identity) => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  const hideoverlay_s_i = (identity) => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  const showpublicsearchloader=()=>{
    document.getElementById("publicsearchloader").style.display = "block";
  }
  const hidepublicsearchloader=()=>{
    document.getElementById("publicsearchloader").style.display = "none";
  }

  function checkImageSearch(imgurlinput,file) {
    var userId = "";
    var formData = new FormData();
    // formData.append('img', file);
    // var imgurlinput = (document.getElementById('imgurlinput').value);
    if (imgurlinput == "") {
      formData.append('img', file);
  }
  else if (imgurlinput != "") {
      formData.append('imgurl', imgurlinput);
  }
    showpublicsearchloader();
    var publicsearchurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getpublicsearch_combined";
    // var publicsearchurl = "https://localhost:44316/api/ArtificialIntelligence/getpublicsearch_combined";
    
    // if (EmailId.toLowerCase() == "subrao@planetre.com" || EmailId.toLowerCase() == "ragunath@planetre.us") {
    //   publicsearchurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getpublicsearch";
    // }
    ajaxrequest = $.ajax({
        type: 'POST',
        url: publicsearchurl,
        dataType: "html",
        data: formData,
        processData: false,
        contentType: false,
        success: function (resp) {
            var response = JSON.parse(resp);
            if (response.Data != null && response.Status == "200") {
              var liverequestarr=[];
              var crawldata = {};
                if (response.Data.PagesWithMatchingImage.length > 0) {
                  crawldata.PagesWithMatchingImage = response.Data.PagesWithMatchingImage;
                  crawldata.sourceimage = response.Data.sourceimage
                  liverequestarr.push(crawldata);
                  // setlivedatarequest(livedatarequest => [...livedatarequest,liverequestarr]);
                  setlivedatarequest(livedatarequest => [...livedatarequest, ...liverequestarr]);


                    document.getElementById("divResetSuccess").style.display = "none";
                    document.getElementById("lblUnsubText").innerHTML = "";
                    var divResult = document.getElementById("divResult");
                    divResult.style.display = "block";
                    var counter = 1;
                    if(document.querySelectorAll('.matchfoundcss .row').length > 0){
                      counter = (document.querySelectorAll('.matchfoundcss .row').length + 1);
                    }                    
                    var strMlsNumbers = "";
                    // var htmlRes = "<div class='col-sm-12'>Matches Found</div>";
                    var htmlRes = "";
                    response.Data.PagesWithMatchingImage.forEach(function (arrayItem) {
                        var fullmatchimages = arrayItem.fullMatchingImages;
                        var partialmatchimages = arrayItem.partialMatchingImages;
                        var imgUrl = "";
                        var mlsnumber = "";
                        if (fullmatchimages != null) {
                            imgUrl = fullmatchimages[0].url;
                            mlsnumber = "Full";
                        }
                        if (partialmatchimages != null) {
                            imgUrl = partialmatchimages[0].url;
                            mlsnumber = "Partial";
                        }
                        var targeturl = arrayItem.url
                        var Title = arrayItem.pageTitle != null ? arrayItem.pageTitle : "";

                        htmlRes += "<div class='row'><div class='col-12'><p>Result: " + counter + "</p></div>"+
                        "<div class='col-6'><img src='"+imgUrl +"' />"+
                        "</div><div class='col-6'>" +
                            "<p><b>Page title:</b> " + Title + "</p>" +
                            "<p><b>Match found:</b> <a href='"+targeturl +"' target='_blank'>" + targeturl + "</a></p>" +
                            "<p><b>Match Type:</b> " + mlsnumber + "</p>" +
                        "</div><div class='col-12'><hr class='bordermfline'></hr></div></div>";

                        // htmlRes += "<div class='col-sm-12 htmlelements' style='border-bottom:5px solid #9333ea;'><span style='display:block;padding-top:20px'><b>Result:</b><label> " + counter + "</label></span>" +
                        //     "<span style='display:block;padding-bottom:10px'><img style='max-height:400px;max-width:100%;' src='" + imgUrl + "' /img></span>" +
                        //     "<span style='display:block;padding-bottom:10px'><b>Page title:</b> <label>" + Title + "</label></span>" +
                        //     "<span style='display:block;padding-bottom:10px'><b>Match found:</b> <a href='" + targeturl + "' target='_blank'>" + targeturl + "</a></span>" +
                        //     "<span style='display:block;padding-bottom:10px'><b>Match Type:</b> <label>" + mlsnumber + "</label></span>" +
                        //     "</div>"
                        counter++;
                    });
                    divResult.innerHTML += htmlRes;
                    document.getElementById('progress').style.width = '100%';
                    document.getElementById('progresspercent').innerText = '100%';
                    setTimeout(() => {
                        document.getElementById("divResult").scrollIntoView({ behavior: "smooth" });
                    }, 1000);
                    document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
                    
                    // if (imgurlinput.trim() == "" && uploadedfile.length == livedatarequest.length) {
                    //   uploadcrawldatatoserver();
                    // }
                    // else{
                    //   uploadcrawldatatoserver();
                    // }
                    
                    // if (counter < 2) {
                    //     var divResult = document.getElementById("divResult");
                    //     divResult.style.display = "block";
                    //     document.getElementById("divResetSuccess").style.display = "block";
                    //     document.getElementById("lblUnsubText").innerHTML = "No matches found";
                    //     divResult.innerHTML = "";
                    // }
                    // <!-- <div class='col-sm-12'> -->

                    // <!-- </div> -->
                }
                else {
                    var divResult = document.getElementById("divResult");
                    divResult.style.display = "block";
                    document.getElementById("divResetSuccess").style.display = "block";
                    document.getElementById("lblUnsubText").innerHTML = "No matches found";
                }
                hidepublicsearchloader();
            }
            else {
                var divResult = document.getElementById("divResult");
                divResult.style.display = "block";
                document.getElementById("divResetSuccess").style.display = "block";
                document.getElementById("lblUnsubText").innerHTML = "No matches found";
                hidepublicsearchloader();
                // alert('No result found');				
            }
            //hideoverlay();
        },
        error: function (err) {
          hidepublicsearchloader();
        }
    });
}

async function checkImageSearch_prom (imgurlinput,file) {
  var userId = "";
  const axios = require('axios');
  const FormData = require('form-data');
  let formData = new FormData();
  // formData.append('img', file);
  // var imgurlinput = (document.getElementById('imgurlinput').value);
  if (imgurlinput == "") {
    formData.append('img', file);
}
else if (imgurlinput != "") {
    formData.append('imgurl', imgurlinput);
}
  showpublicsearchloader();
  var publicsearchurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getpublicsearch_combined";
  // var publicsearchurl = "https://localhost:44316/api/ArtificialIntelligence/getpublicsearch_combined";
  
  // if (EmailId.toLowerCase() == "subrao@planetre.com" || EmailId.toLowerCase() == "ragunath@planetre.us") {
  //   publicsearchurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getpublicsearch";
  // }

  
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: publicsearchurl,  
  data : formData
};

await axios.request(config)
.then((resp) => {
  console.log(JSON.stringify(resp.data));
  var response = (resp.data);
          if (response.Data != null && response.Status == "200") {
            var liverequestarr=[];
            var crawldata = {};
              if (response.Data.PagesWithMatchingImage.length > 0) {
                crawldata.PagesWithMatchingImage = response.Data.PagesWithMatchingImage;
                crawldata.sourceimage = response.Data.sourceimage
                liverequestarr.push(crawldata);
                // setlivedatarequest(livedatarequest => [...livedatarequest,liverequestarr]);
                setlivedatarequest(livedatarequest => [...livedatarequest, ...liverequestarr]);

                  document.getElementById("divResetSuccess").style.display = "none";
                  document.getElementById("lblUnsubText").innerHTML = "";
                  var divResult = document.getElementById("divResult");
                  divResult.style.display = "block";
                  var counter = 1;
                  if(document.querySelectorAll('.matchfoundcss .row').length > 0){
                    counter = (document.querySelectorAll('.matchfoundcss .row').length + 1);
                  }                    
                  var strMlsNumbers = "";
                  // var htmlRes = "<div class='col-sm-12'>Matches Found</div>";
                  var htmlRes = "";
                  response.Data.PagesWithMatchingImage.forEach(function (arrayItem) {
                      var fullmatchimages = arrayItem.fullMatchingImages;
                      var partialmatchimages = arrayItem.partialMatchingImages;
                      var imgUrl = "";
                      var mlsnumber = "";
                      if (fullmatchimages != null) {
                          imgUrl = fullmatchimages[0].url;
                          mlsnumber = "Full";
                      }
                      if (partialmatchimages != null) {
                          imgUrl = partialmatchimages[0].url;
                          mlsnumber = "Partial";
                      }
                      var targeturl = arrayItem.url
                      var Title = arrayItem.pageTitle != null ? arrayItem.pageTitle : "";

                      htmlRes += "<div class='row'><div class='col-12'><p>Result: " + counter + "</p></div>"+
                      "<div class='col-6'><img src='"+imgUrl +"' />"+
                      "</div><div class='col-6'>" +
                          "<p><b>Page title:</b> " + Title + "</p>" +
                          "<p><b>Match found:</b> <a href='"+targeturl +"' target='_blank'>" + targeturl + "</a></p>" +
                          "<p><b>Match Type:</b> " + mlsnumber + "</p>" +
                      "</div><div class='col-12'><hr class='bordermfline'></hr></div></div>";

                      // htmlRes += "<div class='col-sm-12 htmlelements' style='border-bottom:5px solid #9333ea;'><span style='display:block;padding-top:20px'><b>Result:</b><label> " + counter + "</label></span>" +
                      //     "<span style='display:block;padding-bottom:10px'><img style='max-height:400px;max-width:100%;' src='" + imgUrl + "' /img></span>" +
                      //     "<span style='display:block;padding-bottom:10px'><b>Page title:</b> <label>" + Title + "</label></span>" +
                      //     "<span style='display:block;padding-bottom:10px'><b>Match found:</b> <a href='" + targeturl + "' target='_blank'>" + targeturl + "</a></span>" +
                      //     "<span style='display:block;padding-bottom:10px'><b>Match Type:</b> <label>" + mlsnumber + "</label></span>" +
                      //     "</div>"
                      counter++;
                  });
                  divResult.innerHTML += htmlRes;
                  document.getElementById('progress').style.width = '100%';
                  document.getElementById('progresspercent').innerText = '100%';
                  setTimeout(() => {
                      document.getElementById("divResult").scrollIntoView({ behavior: "smooth" });
                  }, 1000);
                  document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
                  
                  // if (imgurlinput.trim() == "") {
                  //   uploadcrawldatatoserver();
                  // }
                  
                  // if (counter < 2) {
                  //     var divResult = document.getElementById("divResult");
                  //     divResult.style.display = "block";
                  //     document.getElementById("divResetSuccess").style.display = "block";
                  //     document.getElementById("lblUnsubText").innerHTML = "No matches found";
                  //     divResult.innerHTML = "";
                  // }
                  // <!-- <div class='col-sm-12'> -->

                  // <!-- </div> -->
              }
              else {
                  var divResult = document.getElementById("divResult");
                  divResult.style.display = "block";
                  document.getElementById("divResetSuccess").style.display = "block";
                  document.getElementById("lblUnsubText").innerHTML = "No matches found";
              }
              hidepublicsearchloader();
          }
          else {
              var divResult = document.getElementById("divResult");
              divResult.style.display = "block";
              document.getElementById("divResetSuccess").style.display = "block";
              document.getElementById("lblUnsubText").innerHTML = "No matches found";
              hidepublicsearchloader();
              // alert('No result found');				
          }
          //hideoverlay();
          return true;
})
.catch((error) => {
  console.log(error);
  hidepublicsearchloader();
  return false;
});




  // ajaxrequest = $.ajax({
  //     type: 'POST',
  //     url: publicsearchurl,
  //     dataType: "html",
  //     data: formData,
  //     processData: false,
  //     contentType: false,
  //     success: function (resp) {
  //         var response = JSON.parse(resp);
  //         if (response.Data != null && response.Status == "200") {
  //           var liverequestarr=[];
  //           var crawldata = {};
  //             if (response.Data.PagesWithMatchingImage.length > 0) {
  //               crawldata.PagesWithMatchingImage = response.Data.PagesWithMatchingImage;
  //               crawldata.sourceimage = response.Data.sourceimage
  //               liverequestarr.push(crawldata);
  //               setlivedatarequest(livedatarequest => [...livedatarequest,liverequestarr]);

  //                 document.getElementById("divResetSuccess").style.display = "none";
  //                 document.getElementById("lblUnsubText").innerHTML = "";
  //                 var divResult = document.getElementById("divResult");
  //                 divResult.style.display = "block";
  //                 var counter = 1;
  //                 if(document.getElementsByClassName('htmlelements').length > 0){
  //                   counter = (document.getElementsByClassName('htmlelements').length + 1);
  //                 }                    
  //                 var strMlsNumbers = "";
  //                 // var htmlRes = "<div class='col-sm-12'>Matches Found</div>";
  //                 var htmlRes = "";
  //                 response.Data.PagesWithMatchingImage.forEach(function (arrayItem) {
  //                     var fullmatchimages = arrayItem.fullMatchingImages;
  //                     var partialmatchimages = arrayItem.partialMatchingImages;
  //                     var imgUrl = "";
  //                     var mlsnumber = "";
  //                     if (fullmatchimages != null) {
  //                         imgUrl = fullmatchimages[0].url;
  //                         mlsnumber = "Full";
  //                     }
  //                     if (partialmatchimages != null) {
  //                         imgUrl = partialmatchimages[0].url;
  //                         mlsnumber = "Partial";
  //                     }
  //                     var targeturl = arrayItem.url
  //                     var Title = arrayItem.pageTitle != null ? arrayItem.pageTitle : "";

  //                     htmlRes += "<div class='row'><div class='col-12'><p>Result: " + counter + "</p></div>"+
  //                     "<div class='col-6'><img src='"+imgUrl +"' />"+
  //                     "</div><div class='col-6'>" +
  //                         "<p><b>Page title:</b> " + Title + "</p>" +
  //                         "<p><b>Match found:</b> <a href='"+targeturl +"' target='_blank'>" + targeturl + "</a></p>" +
  //                         // "<p><b>Match Type:</b> " + mlsnumber + "</p>" +
  //                     "</div><div class='col-12'><hr class='bordermfline'></hr></div></div>";

  //                     // htmlRes += "<div class='col-sm-12 htmlelements' style='border-bottom:5px solid #9333ea;'><span style='display:block;padding-top:20px'><b>Result:</b><label> " + counter + "</label></span>" +
  //                     //     "<span style='display:block;padding-bottom:10px'><img style='max-height:400px;max-width:100%;' src='" + imgUrl + "' /img></span>" +
  //                     //     "<span style='display:block;padding-bottom:10px'><b>Page title:</b> <label>" + Title + "</label></span>" +
  //                     //     "<span style='display:block;padding-bottom:10px'><b>Match found:</b> <a href='" + targeturl + "' target='_blank'>" + targeturl + "</a></span>" +
  //                     //     "<span style='display:block;padding-bottom:10px'><b>Match Type:</b> <label>" + mlsnumber + "</label></span>" +
  //                     //     "</div>"
  //                     counter++;
  //                 });
  //                 divResult.innerHTML += htmlRes;
  //                 document.getElementById('progress').style.width = '100%';
  //                 document.getElementById('progresspercent').innerText = '100%';
  //                 setTimeout(() => {
  //                     document.getElementById("divResult").scrollIntoView({ behavior: "smooth" });
  //                 }, 1000);
  //                 document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
                  
  //                 // if (imgurlinput.trim() == "") {
  //                 //   uploadcrawldatatoserver();
  //                 // }
                  
  //                 // if (counter < 2) {
  //                 //     var divResult = document.getElementById("divResult");
  //                 //     divResult.style.display = "block";
  //                 //     document.getElementById("divResetSuccess").style.display = "block";
  //                 //     document.getElementById("lblUnsubText").innerHTML = "No matches found";
  //                 //     divResult.innerHTML = "";
  //                 // }
  //                 // <!-- <div class='col-sm-12'> -->

  //                 // <!-- </div> -->
  //             }
  //             else {
  //                 var divResult = document.getElementById("divResult");
  //                 divResult.style.display = "block";
  //                 document.getElementById("divResetSuccess").style.display = "block";
  //                 document.getElementById("lblUnsubText").innerHTML = "No matches found";
  //             }
  //             hidepublicsearchloader();
  //         }
  //         else {
  //             var divResult = document.getElementById("divResult");
  //             divResult.style.display = "block";
  //             document.getElementById("divResetSuccess").style.display = "block";
  //             document.getElementById("lblUnsubText").innerHTML = "No matches found";
  //             hidepublicsearchloader();
  //             // alert('No result found');				
  //         }
  //         //hideoverlay();
  //         return true;
  //     },
  //     error: function (err) {
  //       hidepublicsearchloader();
  //       return false;
  //     }
  // });
}




  const readFile = (inputfiles) => {
    // var input = $('.dropzone')[0];
    // var inputfiles = input.files;
    var singletimearray = []; // [...processedimage];
    var propertyorderedlist = []; // [...orderedprocessedimage];
    // console.log('inputfiles.length',inputfiles.length);
    let tempprocessedfiles = 0;
    for (let i = 0; i < inputfiles.length; i++) {
      const element = inputfiles[i].binaryfile;
      // console.log('reading inputfiles : ',element.name);
      var reader = new FileReader();
      reader.onload = function (e) {
        tempprocessedfiles += 1;
        var uniqimg = {};
        var timingidentity = moment().valueOf();
        uniqimg.refid = inputfiles[i].identity;
        uniqimg.id = timingidentity;
        uniqimg.image = e.target.result;
        singletimearray.push(uniqimg);
        if (orderedprocessedimage.length > 0) {
          var selem = {};
          selem.orderid = orderedprocessedimage.length + (i + 1);
          selem.id = timingidentity;
          selem.refid = inputfiles[i].identity;
          selem.image = e.target.result;
          selem.binaryfile = element;
          propertyorderedlist.push(selem);
        }
        if (tempprocessedfiles == inputfiles.length) {
          var processedimagenew = singletimearray.sort(function (a, b) {
            return a.id - b.id;
          });
          setprocessedimage((processedimage) => [
            ...processedimage,
            ...processedimagenew,
          ]);
          setorderedprocessedimage((orderedprocessedimag) => [
            ...orderedprocessedimag,
            ...propertyorderedlist,
          ]);
          // hideoverlaytwo();
          // showoverlaytwo();
          // setTimeout(() => {
          //     loaddropdownfromstate();
          // }, 2000);
        }
      };
      reader.readAsDataURL(element);
    }
  };

  const uploadimages = async() => {
    // setupload_div_visibility(false);
    // var txtPropAddress = document.getElementById("txtPropAddress").value;
    // if (txtPropAddress.trim() === "") {
    //   customAlertMessage("error", "Please enter property address.");
    //   return;
    // }
    var imgurlinput = document.getElementById('imgurlinput').value;        
    if (uploadedfile.length > 0 || imgurlinput.trim() != "") {
      //   if (uploadedimages.length <= 0) {
      // uploadimagestoserver();
      //   }
      //   else {
      //     uploadimagestoserverwithprocessid();
      //   }
      document.getElementById("upload_div").style.display = "none";
      document.getElementById("main_div").style.display = "block";
      
      setTimeout(() => {
        document.getElementById("main_div").scrollIntoView();
      }, 1000);
      //   document.getElementById("div2").classList.remove("inactive");
      //   document.getElementById("showstartbtn").style.display = "flex";
      //   document.getElementById("imagesdiv").style.display = "block";
      //   document.getElementById("divpropertydetails").style.display = "none";
      //   document.getElementById("editordiv").style.display = "none";

      // setTimeout(() => {
        document.getElementById("divResult").innerHTML = "";
        var imgurlinput = document.getElementById('imgurlinput').value;

        if (imgurlinput.trim() == "") {
          const requestPromises = [];
          for (let i = 0; i < uploadedfile.length; i++) {
            const element = uploadedfile[i].binaryfile;
            // setprogressval(2);
            // progressInterval = setInterval(incrementProgressBar, 4000);
            document.getElementById('progress').style.width = '0%';
            document.getElementById('progresspercent').innerText =  '0%';
            progressIntervalhtml = setInterval(incrementProgressBarhtml, 3000);
            requestPromises.push(checkImageSearch_prom("",element));      
            showcrawldiv();      
          }
          await Promise.all(requestPromises);
          // setTimeout(() => {
          //   uploadcrawldatatoserver();            
          // }, 2000);

        }
        else{
          setimageurlinput(imgurlinput.trim());
          // setprogressval(2);
          // progressInterval = setInterval(incrementProgressBar, 4000);
          document.getElementById('progress').style.width = '0%';
          document.getElementById('progresspercent').innerText =  '0%';
          progressIntervalhtml = setInterval(incrementProgressBarhtml, 3000);
          checkImageSearch(imgurlinput,null);
          showcrawldiv();
        }
        
        
      // }, 1000);

    } else {
      customAlertMessage("error", "Please select files and proceed.");
    }
  };
  const uploadimagestoserver = () => {
    const axios = require("axios");
    const FormData = require("form-data");
    // const fs = require('fs');
    // showoverlaytwo();
    document.getElementById('processingspan').style.display = 'inline-block';
    var imgurlinput = document.getElementById('imgurlinput').value;
    let data = new FormData();
    data.append("uid", MasterId);
    data.append("companyid", Companyid);
    if (imgurlinput.trim() != "") {
      data.append("imgurl",imgurlinput.trim());
    }
    else if (uploadedfile.length > 0) {
      for (let i = 0; i < uploadedfile.length; i++) {
        const element = uploadedfile[i].binaryfile;
        data.append(uploadedfile[i].identity, element);
      }
    } else {
      customAlertMessage("error", "Please select files and proceed");
      document.getElementById("upload_div").style.display = "block";
      document.getElementById("main_sherlockinner_div").style.display = "none";
      return;
    }
    document.querySelectorAll('.mlsuploadedphoto span.removeuploaded').forEach(e=> e.remove());
    var sherlockliveurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getsherlocklivecall";
    // var sherlockliveurl = "https://localhost:44316/api/ArtificialIntelligence/getsherlocklivecall";
    // if (EmailId.toLowerCase() == "subrao@planetre.com") {
    //   sherlockliveurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getsherlocklivecall";
    // }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: sherlockliveurl,
      // url: 'https://localhost:44316/api/ArtificialIntelligence/getsherlocklivecall',
      data: data,
      signal: abortController.signal 
    };

    axios
      .request(config)
      .then((response) => {
        var Responsedata = response.data.Data;
        var ResponseStatus = response.data.Status;
        document.getElementById('processingspan').style.display = 'none';
        if (parseInt(ResponseStatus) != 200) {
          customAlertMessage(
            "error",
            "Something went wrong, Please try again."
          );

          setdashtotalcount(document.querySelectorAll('#divResult .row').length);
          document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
          setdashunauthorizedcount(0);

          return;
        }
        if (Responsedata != null && Responsedata.length > 0) {
          setliveresponsedata(Responsedata);
          populategrid(Responsedata);
        } else {
          setdashtotalcount(document.querySelectorAll('#divResult .row').length);
          document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
          setdashunauthorizedcount(0);
        }
        // showcrawldiv();
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was aborted
          console.log('Request aborted:', error.message);
        } else {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        document.getElementById("upload_div").style.display = "block";
        document.getElementById("main_sherlockinner_div").style.display = "none";
        document.getElementById('processingspan').style.display = 'none';
        }
        // hideoverlaytwo();
      });
  };

  const uploadcrawldatatoserver = () => {
    const axios = require("axios");
    const FormData = require("form-data");
    // const fs = require('fs');
    // showoverlaytwo();
    document.getElementById('processingspan').style.display = 'inline-block';
    var imgurlinput = document.getElementById('imgurlinput').value;
    let data = new FormData();
    data.append("uid", MasterId);
    data.append("companyid", Companyid);
    console.log('livedatarequest_request',JSON.stringify(livedatarequest));
    data.append("crawldata",JSON.stringify(livedatarequest));
    document.querySelectorAll('.mlsuploadedphoto span.removeuploaded').forEach(e=> e.remove());
    var sherlockliveurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getsherlocklivecallfromresults";
    // var sherlockliveurl = "https://localhost:44316/api/ArtificialIntelligence/getsherlocklivecallfromresults";
    // var sherlockliveurl = "https://localhost:44316/api/ArtificialIntelligence/getsherlocklivecall";
    // if (EmailId.toLowerCase() == "subrao@planetre.com") {
    //   sherlockliveurl = `${process.env.REACT_APP_SHERLOCKAPI_URL}` + "/api/ArtificialIntelligence/getsherlocklivecall";
    // }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: sherlockliveurl,
      // url: 'https://localhost:44316/api/ArtificialIntelligence/getsherlocklivecall',
      data: data,
      signal: abortController.signal 
    };

    axios
      .request(config)
      .then((response) => {
        var Responsedata = response.data.Data;
        var ResponseStatus = response.data.Status;
        document.getElementById('processingspan').style.display = 'none';
        if (parseInt(ResponseStatus) != 200) {
          customAlertMessage(
            "error",
            "Something went wrong, Please try again."
          );

          setdashtotalcount(document.querySelectorAll('#divResult .row').length);
          document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
          setdashunauthorizedcount(0);

          return;
        }
        if (Responsedata != null && Responsedata.length > 0) {
          setliveresponsedata(Responsedata);
          populategrid(Responsedata);
        } else {
          setdashtotalcount(document.querySelectorAll('#divResult .row').length);
          document.getElementById('processingwebhitsspan').innerText = document.querySelectorAll('#divResult .row').length;
          setdashunauthorizedcount(0);
        }
        // showcrawldiv();
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was aborted
          console.log('Request aborted:', error.message);
        } else {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        document.getElementById("upload_div").style.display = "block";
        document.getElementById("main_sherlockinner_div").style.display = "none";
        document.getElementById('processingspan').style.display = 'none';
        }
        // hideoverlaytwo();
      });
  };

  const uploadimagestoserverwithprocessid = () => {
    const axios = require("axios");
    const FormData = require("form-data");
    // const fs = require('fs');
    showoverlaytwo();
    let data = new FormData();
    data.append("uid", "3a2ab8ee-5264-4a8a-895b-bbcbb96882e0");
    data.append("companyid", "15");
    data.append("processid", processid);
    if (uploadedfile.length > 0) {
      for (let i = 0; i < uploadedfile.length; i++) {
        var filteredprocessimage = uploadedimages.filter(function (entry) {
          return entry.image === uploadedfile[i].identity + ".jpeg";
        });
        if (filteredprocessimage.length <= 0) {
          const element = uploadedfile[i].binaryfile;
          data.append(uploadedfile[i].identity, element);
        }
      }
    } else {
      customAlertMessage("error", "Please select files and proceed");
      document.getElementById("upload_div").style.display = "block";
      document.getElementById("main_sherlockinner_div").style.display = "none";
      return;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_URL}` +
        "/api/ArtificialIntelligence/addmlscomplianceimages",
      //  url: 'https://localhost:44372/api/ArtificialIntelligence/addmlscomplianceimages',
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        var Responsedata = response.data.Data;
        if (
          Responsedata != "" &&
          Responsedata != null &&
          Object.keys(Responsedata).length > 0
        ) {
          var tempuploadedimagearray = [];
          var tempuploadedimages = Responsedata.imagelist;
          var tempduplicateimages = Responsedata.duplicates;
          tempuploadedimages.forEach((element) => {
            var imagejson = {};
            imagejson.image = element;
            // imagejson.processed = "-1";
            // imagejson.compliant = "-1";
            imagejson.compliancefailedimage = "";
            // imagejson.spacetype = "";
            imagejson.description = "";
            imagejson.compliancefailedimagefordeclutter = "";
            imagejson.compliancefailedimagemask = "";
            imagejson.sourceimageheight = "";
            imagejson.sourceimagewidth = "";
            imagejson.nooftries = 0;
            if (tempduplicateimages.indexOf(element) >= 0) {
              // imagejson.editpermission = 2;
              imagejson.spacetype = "Duplicate image.";
              imagejson.editpermission = 1;
              imagejson.description = "";
              imagejson.processed = "1";
              imagejson.compliant = "0";
            } else {
              imagejson.editpermission = 0;
              imagejson.spacetype = "";
              imagejson.processed = "-1";
              imagejson.compliant = "-1";
            }
            tempuploadedimagearray.push(imagejson);
          });
          setuploadedimages((upimage) => [
            ...uploadedimages,
            ...tempuploadedimagearray,
          ]);
          setfileidentity(uuidv4());
          // setprocessid(Responsedata.processid);
          // setTimeout(() => {
          //     startprocess_single();
          // }, 3000);
          hideoverlaytwo();
        }
        hideoverlaytwo();
        // else{
        //     customAlertMessage('error','Something went wrong, Please try again.');
        //     document.getElementById('upload_div').style.display ="block";
        //     document.getElementById('main_sherlockinner_div').style.display = "none";
        //     hideoverlaytwo();
        // }
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        document.getElementById("upload_div").style.display = "block";
        document.getElementById("main_sherlockinner_div").style.display = "none";
        hideoverlaytwo();
      });
  };

  const replacefile = (referenceid, file, newfilename) => {
    //Remove from first step
    getBase64(file).then((data) => {
      // console.log(data);
      var filteredprocessimage = processedimage;
      filteredprocessimage.forEach((element) => {
        if (element.refid == referenceid.replace(".jpeg", "")) {
          element.image = data;
          element.refid = newfilename.replace(".jpeg", "");
        }
      });
      // var filteredprocessimage = processedimage.filter(function (entry) { return entry.refid == (referenceid.replace(".jpeg","")); });
      // filteredprocessimage.image = data;
      setprocessedimage([...filteredprocessimage]);
      var filterorderedprocessedimage = orderedprocessedimage;
      filterorderedprocessedimage.forEach((element) => {
        if (element.refid == referenceid.replace(".jpeg", "")) {
          element.image = data;
        }
      });
      // var  filterorderedprocessedimage = orderedprocessedimage.filter(function (entry) { return entry.refid == (referenceid.replace(".jpeg","")); });
      // filterorderedprocessedimage.image = data;
      setorderedprocessedimage([...filterorderedprocessedimage]);
    });
    var filtereduploadedfile = uploadedfile;
    filtereduploadedfile.forEach((element) => {
      if (element.identity == referenceid.replace(".jpeg", "")) {
        element.binaryfile = file;
        element.identity = newfilename.replace(".jpeg", "");
      }
    });
    // var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.identity == (referenceid.replace(".jpeg","")); });
    // filtereduploadedfile.image = data;
    setuploadedfile([...filtereduploadedfile]);
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const uploadsingleimagestoserver = (file, processid, name) => {
    // var lielementtemp = document.getElementById(name.split('.').join('_'));
    // var roomtypetext = lielementtemp.getElementsByTagName('h4')[0].innerText;
    // var existroomtypes = roomtypes.filter(x=> x.name == roomtypetext);
    // if (existroomtypes != undefined && existroomtypes.length > 0) {
    //     existroomtypes[0].count += 1;
    // }
    const axios = require("axios");
    const FormData = require("form-data");
    // const fs = require('fs');
    showoverlaytwo();
    let data = new FormData();
    data.append("uid", "3a2ab8ee-5264-4a8a-895b-bbcbb96882e0");
    data.append("companyid", "15");
    data.append("files", file);
    data.append("processid", processid);
    data.append("name", name);
    if (file == undefined || file == null) {
      customAlertMessage("error", "Please select files and proceed");
      document.getElementById("upload_div").style.display = "block";
      document.getElementById("main_sherlockinner_div").style.display = "none";
      return;
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_URL}` +
        "/api/ArtificialIntelligence/uploadmlscomplianceimages_s_h",
      //url: 'https://localhost:44372/api/ArtificialIntelligence/uploadmlscomplianceimages_s_h',
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        var Responsedata = response.data.Data;
        if (Responsedata != "" && Responsedata != null) {
          var tempuploadedimagearrayempty = [];
          var tempuploadedimages = uploadedimages;
          tempuploadedimages.forEach((element) => {
            if (element.image == name) {
              element.image = Responsedata;
              if (response.data.Message == "duplicate") {
                element.processed = "1";
                element.compliant = "0";
                // element.editpermission = 2;
                element.spacetype = "Duplicate image.";
                element.editpermission = 1;
                element.description = "";
              } else {
                element.processed = "0";
                element.compliant = "-1";
              }
              replacefile(name, file, Responsedata);
            }
          });
          setuploadedimages((upimage) => [
            ...tempuploadedimagearrayempty,
            ...tempuploadedimages,
          ]);
          // var tempuploadedimagearray = uploadedimages.filter(x=>x.image != name);
          // var tempuploadedimagearray11 = [];
          // if (tempuploadedimagearray != undefined) {
          //     var imagejson = {};
          //     imagejson.image = Responsedata;
          //     imagejson.processed = "-1";
          //     imagejson.compliant = "-1";
          //     tempuploadedimagearray11.push(imagejson);
          // }
          // setuploadedimages(upimage => [...tempuploadedimagearray,...tempuploadedimagearray11]);
          hideoverlaytwo();
          // var lielement = document.getElementById(name.split('.').join('_'));
          // lielement.getElementsByTagName('img')[0].src = localimagebasepath + processid + "/" + Responsedata;
          setTimeout(() => {
            if (response.data.Message != "duplicate") {
              // processcompliance_s_i(processid,Responsedata);
            }
            // processroomtypes_s_i(processid,Responsedata);
          }, 1000);
          // var tempuploadedimages = Responsedata.imagelist;
          // setuploadedimages(upimage => [...upimage , ...tempuploadedimages])
          // setprocessid(Responsedata.processid);
          // hideoverlaytwo();
          // setTimeout(() => {
          //     processroomtypes(Responsedata.processid);
          // }, 1000);
        } else {
          customAlertMessage(
            "error",
            "Something went wrong, Please try again."
          );
          document.getElementById("upload_div").style.display = "block";
          document.getElementById("main_sherlockinner_div").style.display = "none";
          hideoverlaytwo();
        }
      })
      .catch((error) => {
        console.log(error);
        customAlertMessage("error", "Something went wrong, Please try again.");
        document.getElementById("upload_div").style.display = "block";
        document.getElementById("main_sherlockinner_div").style.display = "none";
        hideoverlaytwo();
      });
  };
  // const processroomtypes=(processidparam)=>{
  //     const axios = require('axios');
  //     const FormData = require('form-data');
  //     showoverlay();
  //     setanalyzingroomtype(true);
  //     let data = new FormData();
  //     data.append('processid',(processid == ""?processidparam: processid));
  //     let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/imagecaption',
  //     data : data
  //     };

  //     axios.request(config)
  //     .then((response) => {
  //     console.log(JSON.stringify(response.data));
  //     var DATA = response.data.Data;
  //     var resultroomtypes = [];
  //     if (DATA != null && DATA != "") {
  //         var results = DATA;
  //         setimagecaption(results);
  //         results.forEach(element => {
  //             var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //             lielement.getElementsByTagName('h4')[0].innerText = (element.roomtype);
  //             lielement.getElementsByTagName('p')[0].innerText = (element.description);
  //             var roomjson = {};
  //             var existroomtypes = resultroomtypes.filter(x=> x.name == element.roomtype);
  //             if (existroomtypes != undefined && existroomtypes.length > 0) {
  //                 existroomtypes[0].count += 1;
  //             }
  //             else{
  //                 roomjson.name = element.roomtype;
  //                 roomjson.count = 1;
  //                 resultroomtypes.push(roomjson);
  //             }
  //         });
  //         setroomtypes(room => [...room,...resultroomtypes]);
  //         setanalyzingroomtype(false);

  //     }
  //     else{
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //         setanalyzingroomtype(null);
  //     }
  //     hideoverlay();
  //     })
  //     .catch((error) => {
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //         console.log(error);
  //         setanalyzingroomtype(null);
  //         hideoverlay();
  //     });

  // }
  // const processcompliance=(processidparam)=>{
  //     const axios = require('axios');
  //     const FormData = require('form-data');
  //     showoverlaypropcomp();
  //     // setcompliancepassed(false);
  //     let data = new FormData();
  //     data.append('processid',(processid == ""?processidparam: processid));
  //     let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/imagecompliance',
  //     data : data
  //     };

  //     axios.request(config)
  //     .then((response) => {
  //     console.log(JSON.stringify(response.data));
  //     var DATA = response.data.Data;
  //     var resultroomtypes = [];
  //     if (DATA != null && DATA != "") {
  //         var compliancepassed = DATA.compliancepassed;
  //         // setcompliancepassed(compliancepassed);
  //         if (compliancepassed == false) {

  //         }
  //         var results = DATA.list;
  //         // setimagecaption(results);
  //         // results.forEach(element => {
  //         //     var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //         //     lielement.getElementsByTagName('h4')[0].innerText = (element.roomtype);
  //         //     lielement.getElementsByTagName('p')[0].innerText = (element.description);
  //         //     var roomjson = {};
  //         //     var existroomtypes = resultroomtypes.filter(x=> x.name == element.roomtype);
  //         //     if (existroomtypes != undefined && existroomtypes.length > 0) {
  //         //         existroomtypes[0].count += 1;
  //         //     }
  //         //     else{
  //         //         roomjson.name = element.roomtype;
  //         //         roomjson.count = 1;
  //         //         resultroomtypes.push(roomjson);
  //         //     }
  //         // });

  //         // setroomtypes(room => [...room,...resultroomtypes]);

  //     }
  //     else{
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //     }
  //     // setanalyzingroomtype(false);
  //     updateProgressBartoend()
  //     setTimeout(() => {
  //         hideoverlaypropcomp();
  //     }, 2000);

  //     })
  //     .catch((error) => {
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //         console.log(error);
  //         updateProgressBartoend();
  //         hideoverlaypropcomp();
  //     });

  // }

  const processroomtypes_s_i = (processidparam, imageidentity) => {
    const axios = require("axios");
    const FormData = require("form-data");
    // showoverlay();
    // setanalyzingroomtype(true);
    let data = new FormData();
    data.append("processid", processid == "" ? processidparam : processid);
    data.append("name", imageidentity);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_URL}` +
        "/api/ArtificialIntelligence/imagecaption_s_i",
      // url: 'https://localhost:44372/api/ArtificialIntelligence/imagecaption_s_i',
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        var DATA = response.data.Data;
        var resultroomtypes = [];
        if (roomtypes.length > 0) {
          resultroomtypes = roomtypes;
        }
        if (DATA != null && DATA != "") {
          var results = DATA;
          // setimagecaption(results);
          results.forEach((element) => {
            // var lielement = document.getElementById(element.imagename.split('.').join('_'));
            var roomtype =
              element.roomtype == "error"
                ? "Unidentified Spaces"
                : element.roomtype;
            var roomdescription =
              element.description == "error" ? "" : element.description;
            var condition = element.condition;
            // lielement.getElementsByTagName('h4')[0].innerText = (roomtype);
            // lielement.getElementsByTagName('p')[0].innerText = (roomdescription);

            // var existroomtypes = resultroomtypes.filter(x=> x.name == element.roomtype);
            // if (existroomtypes != undefined && existroomtypes.length > 0) {
            //     existroomtypes[0].count += 1;
            // }
            // else{
            //     var roomjson = {};
            //     roomjson.name = element.roomtype;
            //     roomjson.count = 1;
            //     resultroomtypes.push(roomjson);
            // }
            var tempuploadedimages = uploadedimages;
            var tempuploadedimagearray = tempuploadedimages.find(
              (x) => x.image == element.imagename
            );
            if (tempuploadedimagearray != undefined) {
              if (roomtype == "Non-Compliant_front_view") {
                tempuploadedimagearray.compliant = "0";
                tempuploadedimagearray.processed = "1";
                // tempuploadedimagearray.spacetype = roomtype;
                // tempuploadedimagearray.description = roomdescription;
                //tempuploadedimagearray.editpermission = 3; // no edit option. only ignore / remove / replace
                tempuploadedimagearray.spacetype = roomtype;
                tempuploadedimagearray.description = roomdescription;
                tempuploadedimagearray.editpermission = 1;
                tempuploadedimagearray.condition = condition;
              } else {
                tempuploadedimagearray.processed = "1";
                tempuploadedimagearray.spacetype = roomtype;
                tempuploadedimagearray.description = roomdescription;
                tempuploadedimagearray.editpermission = 0;
                tempuploadedimagearray.condition = condition;
                addtoulphotobreak(roomtype, element.imagename);
              }
            }
            setuploadedimages([...tempuploadedimages]);
          });
          // var _room = [];
          // setroomtypes(room => [...room,...resultroomtypes]);
          // setroomtypes([...resultroomtypes]);
          // setanalyzingroomtype(false);
          // processcompliance_s_i(processidparam,imageidentity);
          setTimeout(() => {
            // processroomtypes(Responsedata.processid);
            startprocess_single();
          }, 1000);
        } else {
          customAlertMessage(
            "error",
            "Something went wrong, Please try again."
          );
          // setanalyzingroomtype(null);
          setTimeout(() => {
            // processroomtypes(Responsedata.processid);
            startprocess_single();
          }, 1000);
        }
        hideoverlay();
      })
      .catch((error) => {
        customAlertMessage("error", "Something went wrong, Please try again.");
        console.log(error);
        // setanalyzingroomtype(null);
        setTimeout(() => {
          // processroomtypes(Responsedata.processid);
          startprocess_single();
        }, 1000);
        hideoverlay();
      });
  };
  // const processcompliance_s_i=(processidparam,imageidentity)=>{
  //     const axios = require('axios');
  //     const FormData = require('form-data');
  //     let data = new FormData();
  //     data.append('processid',(processid == ""?processidparam: processid));
  //     data.append('name',imageidentity);
  //     data.append('uid',UserID);
  //     data.append('companyid',Companyid);
  //     let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/imagecompliance_s_i',
  //     // url: 'https://localhost:44372/api/ArtificialIntelligence/imagecompliance_s_i',
  //     data : data
  //     };

  //     axios.request(config)
  //     .then((response) => {
  //     // console.log(JSON.stringify(response.data));
  //     var DATA = response.data.Data;
  //     var resultroomtypes = [];
  //     if (DATA != null && DATA != "") {
  //         var compliancepassed = DATA.compliancepassed;
  //         var results = DATA.list;
  //         if (compliancepassed == false) {
  //             results.forEach(element => {
  //                 var tempuploadedimages = uploadedimages;
  //                 var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == element.imagename);
  //                 if (tempuploadedimagearray != undefined) {
  //                     if (element.watermark == true || element.watermark == "true") {
  //                         tempuploadedimagearray.compliant = "0";
  //                         tempuploadedimagearray.processed = "1";
  //                         tempuploadedimagearray.spacetype = "Watermark/Copyright found."
  //                         // tempuploadedimagearray.editpermission = 2;
  //                         tempuploadedimagearray.description = "";
  //                         tempuploadedimagearray.editpermission = 1;
  //                     }
  //                     else if (element.description.trim() == "cuda error" && parseInt(tempuploadedimagearray.nooftries) <= 0) {
  //                         tempuploadedimagearray.nooftries = parseInt(tempuploadedimagearray.nooftries) +1;
  //                         tempuploadedimagearray.processed = "-10";
  //                     }
  //                     else{
  //                         // console.log("description",element.description);
  //                         // console.log("tempuploadedimagearray",parseInt(tempuploadedimagearray.nooftries));
  //                         tempuploadedimagearray.compliant = "0";
  //                         tempuploadedimagearray.processed = "1";
  //                         tempuploadedimagearray.compliancefailedimage = element.outputurl;
  //                         tempuploadedimagearray.compliancefailedimagefordeclutter = element.srcimgurl;
  //                         tempuploadedimagearray.compliancefailedimagemask = element.maskurl;
  //                         tempuploadedimagearray.sourceimageheight = element.srcImgH;
  //                         tempuploadedimagearray.sourceimagewidth = element.srcImgW;
  //                         tempuploadedimagearray.spacetype = element.description.trim() == "cuda error" ? "Image error" : element.description.trim(); // roomdescription;
  //                         tempuploadedimagearray.description = "";
  //                         tempuploadedimagearray.editpermission = 1;
  //                         // var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //                         // lielement.getElementsByTagName('p')[1].innerText = (element.description);
  //                     }

  //                 }
  //                 setuploadedimages([...tempuploadedimages]);

  //                 // var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //                 // lielement.classList.add("errorcomplaince");
  //             });
  //                 setTimeout(() => {
  //                     startprocess_single();
  //                 }, 1000);
  //         }
  //         else{
  //             results.forEach(element => {
  //                 var tempuploadedimages = uploadedimages;
  //                 var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == element.imagename);
  //                 if (tempuploadedimagearray != undefined) {
  //                     tempuploadedimagearray.compliant = "1";
  //                     tempuploadedimagearray.processed = "0";
  //                 }
  //                 setuploadedimages([...tempuploadedimages]);
  //                 // processroomtypes_s_i(processid,imageidentity);
  //                 // var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //                 // lielement.classList.remove("errorcomplaince");
  //             });
  //         }

  //         // setimagecaption(results);
  //         // results.forEach(element => {
  //         //     var lielement = document.getElementById(element.imagename.split('.').join('_'));
  //         //     lielement.getElementsByTagName('h4')[0].innerText = (element.roomtype);
  //         //     lielement.getElementsByTagName('p')[0].innerText = (element.description);
  //         //     var roomjson = {};
  //         //     var existroomtypes = resultroomtypes.filter(x=> x.name == element.roomtype);
  //         //     if (existroomtypes != undefined && existroomtypes.length > 0) {
  //         //         existroomtypes[0].count += 1;
  //         //     }
  //         //     else{
  //         //         roomjson.name = element.roomtype;
  //         //         roomjson.count = 1;
  //         //         resultroomtypes.push(roomjson);
  //         //     }
  //         // });

  //         // setroomtypes(room => [...room,...resultroomtypes]);

  //     }
  //     else{
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //         setTimeout(() => {
  //             // processroomtypes(Responsedata.processid);
  //             startprocess_single();
  //         }, 1000);
  //     }
  //     // setanalyzingroomtype(false);
  //     // updateProgressBartoend()
  //     // setTimeout(() => {
  //     //     hideoverlaypropcomp();
  //     // }, 2000);

  //     })
  //     .catch((error) => {
  //         customAlertMessage('error','Something went wrong, Please try again.');
  //         console.log(error);
  //         setTimeout(() => {
  //             // processroomtypes(Responsedata.processid);
  //             startprocess_single();
  //         }, 1000);
  //         // updateProgressBartoend();
  //         // hideoverlaypropcomp();
  //     });

  // }
  // function showoverlaypropcomp() {
  //     document.getElementById('compliancecheck').style.display = "flex";
  //     document.getElementById('imagesdiv').style.display = "none";
  //     $('body').addClass("loader");
  //     updateProgressBar(uploadedimages.length);
  // }
  // function hideoverlaypropcomp(){
  //     document.getElementById('compliancecheck').style.display = "none";
  //     document.getElementById('imagesdiv').style.display = "block";
  //     $('body').removeClass("loader");
  // }

  function updateProgressBartoend() {
    var progressbar = document.querySelectorAll(".progress-bar");
    progressbar.forEach((element) => {
      const progressBarElement = element;
      progressBarElement.style.width = "100%";
    });
  }

  function updateProgressBar(numberOfImages) {
    // Assuming each image takes an average of 4 seconds to load
    const averageTimePerImage = 10000; // in milliseconds
    const totalTime = numberOfImages * averageTimePerImage;
    var progressbar = document.querySelectorAll(".progress-bar");
    progressbar.forEach((element) => {
      // const progressBarElement = element;
      updateSingleProgressBar(element, numberOfImages);
      // let currentTime = 0;
      // // Interval to update the progress bar every 100 milliseconds
      // const interval = setInterval(function () {
      //     // Calculate the percentage of the total time that has passed
      //     const percentage = (currentTime / totalTime) * 100;
      //     progressBarElement.style.width = percentage + '%';

      //     // Increment the current time
      //     currentTime += 100;

      //     // If the total time has passed, clear the interval
      //     if (currentTime >= totalTime) {
      //         clearInterval(interval);
      //         progressBarElement.style.width = '100%'; // Ensure the bar is fully filled
      //     }
      // }, 100);
    });
  }

  function updateSingleProgressBar(progressBarElement, numberOfImages) {
    // Assuming each image takes an average of 4 seconds to load
    const averageTimePerImage = 10000; // in milliseconds
    const totalTime = numberOfImages * averageTimePerImage;
    let currentTime = 0;

    // Interval to update the progress bar every 100 milliseconds
    const interval = setInterval(function () {
      // Calculate the percentage of the total time that has passed
      const percentage = (currentTime / totalTime) * 100;
      progressBarElement.style.width = percentage + "%";
      progressBarElement.innerText = Math.round(percentage) + "%";

      // Increment the current time
      currentTime += 100;

      // If the total time has passed, clear the interval
      if (currentTime >= totalTime) {
        clearInterval(interval);
        progressBarElement.style.width = "100%"; // Ensure the bar is fully filled
        // progressBarElement.innerText = '100%';
      }
    }, 100);
  }

  function showoverlayprop() {
    document.getElementById("newloaderprop").style.display = "flex";
    document.getElementById("main_sherlockinner_div").style.display = "none";
    $("body").addClass("loader");
  }
  function hideoverlayprop() {
    document.getElementById("newloaderprop").style.display = "none";
    document.getElementById("main_sherlockinner_div").style.display = "flex";
    $("body").removeClass("loader");
  }

  // const generateaicontentCounters=()=>{
  //     document.getElementById("divGeneratedContent").innerHTML="description start";
  //     try {
  //         document.getElementById("plagiarisminfo").style.display = "none";
  //     } catch (error) {

  //     }
  //     document.getElementById("divGeneratedContent").innerHTML="";
  //     // document.getElementById("h4Title").innerHTML="";
  //     document.getElementById("spanCharCount").style.display = "none";
  //     document.getElementById("divOutputIcons").style.display = "none";
  //     showoverlayprop();
  //     setlistingdescriptionstatus(0);
  //     var prompt = "";
  //     var txtPropAddress = document.getElementById('txtPropAddress').value;
  //     prompt = "Generate detailed property listing description for "+txtPropAddress +". ";

  //     // step 2 start ==>Property details
  //     var propTypeSel = document.getElementById("ddlPropertyType").value;
  //     if (propTypeSel != "select") {
  //         prompt += " with " + propTypeSel +" propertytype.";
  //     }
  //     else{
  //         prompt +=".";
  //     }
  //     var ddlBedroomsSel = document.getElementById("ddlBedrooms").value;
  //     if (ddlBedroomsSel != "select") {
  //         prompt += " It has "+ ddlBedroomsSel + " number of bedrooms and " ;
  //     }
  //     var ddlBathroomsSel = document.getElementById("ddlBathrooms").value;
  //     if (ddlBathroomsSel != "select") {
  //         prompt +=  ddlBathroomsSel + " number of bathrooms." ;
  //     }
  //     var ddlFloorsSel = document.getElementById("ddlFloors").value;
  //     if (ddlFloorsSel != "select") {
  //         prompt += " It is a " + ddlFloorsSel + " story home." ;
  //     }
  //     var txtAreaSel = document.getElementById("txtArea").value;
  //     if (txtAreaSel != "") {
  //         prompt += "It is having an area of "+txtAreaSel+ " sqft.";
  //     }
  //     // lot size comented
  //     // var txtAreaSel = document.getElementById("txtArea").value;
  //     // if (txtAreaSel != "") {
  //     //     prompt += "and "+txtAreaSel+ " lot size.";
  //     // }
  //     var ddlGaragetypeSel = document.getElementById("ddlGaragetype").value;
  //     var ddlGaragespacesSel = document.getElementById("ddlGaragespaces").value;
  //     if (ddlGaragetypeSel !="select" && ddlGaragespacesSel != "select") {
  //         prompt += " It has " + ddlGaragetypeSel + " garage with "+ ddlGaragespacesSel + " spaces.";
  //     }
  //     else if (ddlGaragetypeSel != "select") {
  //         prompt += " It has " + ddlGaragetypeSel + " garage." ;
  //     }
  //     else if (ddlGaragespacesSel != "select") {
  //         prompt += " It has " + ddlGaragespacesSel + " garage." ;
  //     }
  //     // prompt += "The property most Saleable features include ";
  //     // var txtMostSalableSel = document.getElementById("txtMostSalable").value;
  //     // if (txtMostSalableSel != "") {
  //     //     prompt += txtMostSalableSel + ".";
  //     // }
  //     // Step 2 end

  //     // step 1 start ==>Amenitinies

  //         //school amenity
  //         var markedCheckbox = document.getElementsByName('schoolAmenity');
  //         var schoolAmenitiesel = "";
  //         for (var checkbox of markedCheckbox) {
  //             if (checkbox.checked)  {
  //                 var lblIds = checkbox.id.replace("chkschool","lblschool");
  //                 schoolAmenitiesel += document.getElementById(lblIds).innerText + ', ';
  //             }
  //         }
  //         if (schoolAmenitiesel !="") {
  //             prompt += "The property has near by schools including " + schoolAmenitiesel + ".";
  //         }

  //         var markedCheckboxHosp = document.getElementsByName('hospitalAmenity');
  //         var hospitalAmenitiesel = "";
  //         for (var checkbox of markedCheckboxHosp) {
  //             if (checkbox.checked)  {
  //                 var lblIdshospital = checkbox.id.replace("chkhospital","lblhospital");
  //                 hospitalAmenitiesel += document.getElementById(lblIdshospital).innerText + ', ';
  //             }
  //         }
  //         if (hospitalAmenitiesel !="") {
  //             prompt += "The property has near by hospitals including " + hospitalAmenitiesel + ".";
  //         }

  //         var markedCheckboxpark = document.getElementsByName('parkAmenity');
  //         var parkAmenitiesel = "";
  //         for (var checkbox of markedCheckboxpark) {
  //             if (checkbox.checked)  {
  //                 var lblIdspark = checkbox.id.replace("chkpark","lblpark");
  //                 parkAmenitiesel += document.getElementById(lblIdspark).innerText + ', ';
  //             }
  //         }
  //         if (parkAmenitiesel !="") {
  //             prompt += "The property has near by parks including " + parkAmenitiesel + ".";
  //         }

  //         var markedCheckboxsupermarket = document.getElementsByName('supermarketAmenity');
  //         var supermarketAmenitiesel = "";
  //         for (var checkbox of markedCheckboxsupermarket) {
  //             if (checkbox.checked)  {
  //                 var lblIdssupermarket = checkbox.id.replace("chksupermarket","lblsupermarket");
  //                 supermarketAmenitiesel += document.getElementById(lblIdssupermarket).innerText + ', ';
  //             }
  //         }
  //         if (supermarketAmenitiesel !="") {
  //             prompt += "The property has near by supermarkets including " + supermarketAmenitiesel + ".";
  //         }

  //         // Step 1 end

  //     // prompt += getroomtypebreakdown() + " The property special features were ";

  //     prompt += " The property special features were "
  //     prompt += getroomfeatures();
  //     prompt += ". Also include headline note on header and call to action at the end of the description.";
  //     prompt +=" Make description paragraph wise."

  //     // console.log(prompt);
  //     var lengthcontroller = "2000";//this.getselectedcontentlength();
  //     var Supplement = "";//this.gettexteleValue("textareaoutput", "Supplement");
  //     var toneofvoice = "";//this.gettexteleValue("toneofvoice", "Tone");
  //     var keywords = "";//this.gettexteleValue("keywordinput", "Keyword");
  //     var prompttext = ("Topic: "+ prompt);
  //     var axios = require("axios");

  //     const FormData = require('form-data');
  //     var tokens = 4000;
  //     let data = new FormData();
  //     data.append('prompt', prompttext.trim());
  //     data.append('uid', (Role == 3 ? MasterId : UserID));
  //     data.append('cid', Companyid);
  //     data.append('token', tokens);

  //     let config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: `${process.env.REACT_APP_API_URL}`+'/api/ArtificialIntelligence/posttextmessagenew',
  //         data : data
  //     };

  //     axios(config)
  //       .then(function (resp) {
  //         var response = resp.data;
  //         if (resp != null && response != "" && response.Data !="") {
  //           var datalist = response.Data.split('\n');
  //           var finalData="";
  //           for(var i=0;i<datalist.length;i++)
  //           {
  //               if(datalist[i]!="")
  //               {
  //                 finalData = finalData +datalist[i] +"\n";
  //               }
  //           }
  //         //   hideoverlay();
  //         //   //window.scrollTop = document.body.scrollHeight;
  //           $("html, body").animate({ scrollTop: document.body.scrollHeight }, "slow");
  //           t = 0;
  //           finalData = finalData.replace("Headline: ","").replace("Headline:","").replace("headline: ","").replace("Paragraph 1:","").replace("Paragraph 2:","").replace("Paragraph 3:","").replace("Paragraph 4:","").replace("Paragraph 5:","").replace("Paragraph 6:","");
  //           finalData = finalData.replace("Call to Action: ","").replace("Headline note:","").replace("Headline Note:","");
  //           var divOuterContent = document.getElementById('divOuterContent');
  //           divOuterContent.scrollTop =  divOuterContent.scrollHeight;
  //           typeWriter(finalData);
  //           document.getElementById('divGeneratedContent').contentEditable ="true";
  //           document.getElementById("spanCharCount").style.display = "block";
  //         }
  //         hideoverlayprop();
  //         setlistingdescriptionstatus(1);
  //       })

  //       .catch(function (error) {
  //         console.log(error);
  //         hideoverlayprop();
  //         setlistingdescriptionstatus(2);
  //         //document.getElementById("newloaderprop").style.display="none";
  //         customAlertMessage(
  //           "error",
  //           "Something went wrong, please try again."
  //         );
  //       });
  //   }
  const typeWriter = (datalist, Dataobjects) => {
    var speed = 10;
    if (t < datalist.length) {
      var textLst = datalist.charAt(t);
      document.getElementById("divGeneratedContent").innerHTML +=
        textLst === "\n" ? "<p></p>" : textLst;
      t++;
      document.getElementById("lblCcount").innerHTML = t;
      setTimeout(function () {
        typeWriter(datalist, Dataobjects);
      }, speed);
      //window.scrollTo(0, document.body.scrollHeight);
      var elementsDiv1 = document.getElementById("divGeneratedContent");
      elementsDiv1.scrollTop = elementsDiv1.scrollHeight;
    }
    if (t + 1 == datalist.length) {
      console.log("inside final");
      document.getElementById("divOutputIcons").style.display = "block";
      // Highlightkeys(Dataobjects);
    }
  };
  function showoverlay4() {
    document.getElementById("overlayloader4").style.display = "block";
    $("body").addClass("loader");
  }
  function hideoverlay4() {
    document.getElementById("overlayloader4").style.display = "none";
    $("body").removeClass("loader");
  }
  // function Checkplagiarism() {
  //     try {
  //       if (document.getElementById("divGeneratedContent").innerText == "") {
  //         this.customAlertMessage(
  //           "warning",
  //           "cannot check plagiarism for empty content."
  //         );
  //         return;
  //       }
  //     } catch (error) {
  //       this.customAlertMessage("error", "Error occured, Please try again.");
  //       return;
  //     }

  //     showoverlay4();

  //     var axios = require("axios");
  //     var FormData = require("form-data");
  //     var data = new FormData();
  //     data.append(
  //       "postdata",
  //       document.getElementById("divGeneratedContent").innerText.trim()
  //     );
  //     data.append("uid",(Role == 3 ? MasterId : UserID));
  //     data.append("companyid",Companyid);

  //     var config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios(config)
  //       .then(function (resp) {
  //         var response = resp.data;
  //         if (response.Data != null) {
  //           if (response.Data.count != null && response.Data.count === 0) {
  //             customAlertMessage("success", "No plagiarism found");
  //             document.getElementById("plagiarisminfo").style.display = "none";
  //             // document.getElementById("plagiarismmodalbody").innerHTML = "";
  //             setplagiarismmodalbody({ __html: '' });
  //             // $(elem).find('.checkmark').show();
  //             // $(elem).find('.ban').hide();
  //             // $(elem).attr('tooltip', "No plagiarism found.");
  //             // $(elem).attr('onclick', "return false;");
  //           } else if (response.Message == "small content") {
  //             customAlertMessage("info", response.Data);
  //           } else {
  //             document.getElementById("plagiarisminfo").innerText =
  //               response.Data.count + " copies found";
  //             document.getElementById("plagiarisminfo").style.display =
  //               "inline-block";
  //             var htmlnew = duplicateresultcontent;
  //             htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
  //             htmlnew = htmlnew.replace(
  //               "[PHCSquerywords]",
  //               response.Data.querywords
  //             );
  //             var duplicateresultparagraphlist = "";
  //             for (var i = 0; i < response.Data.count; i++) {
  //               var result = response.Data.result[i];
  //               var duplicateresultparacontent =
  //                 duplicateresultparacontenttemplate;
  //               duplicateresultparacontent = duplicateresultparacontent.replace(
  //                 "[PHCSurl]",
  //                 result.url
  //               );
  //               duplicateresultparacontent = duplicateresultparacontent.replace(
  //                 "[PHCSurltext]",
  //                 result.url
  //               );
  //               duplicateresultparacontent = duplicateresultparacontent.replace(
  //                 "[PHCStitle]",
  //                 result.title
  //               );
  //               duplicateresultparacontent = duplicateresultparacontent.replace(
  //                 "[PHCShtmlsnippet]",
  //                 result.textsnippet
  //               );
  //               duplicateresultparagraphlist =
  //                 duplicateresultparagraphlist + duplicateresultparacontent;
  //             }
  //             htmlnew = htmlnew.replace(
  //               "[PHCSparagraphlist]",
  //               duplicateresultparagraphlist
  //             );
  //             // document.getElementById("plagiarismmodalbody").innerHTML = htmlnew;
  //             setplagiarismmodalbody({ __html:htmlnew});
  //             customAlertMessage("error", "Plagiarism found");
  //           }
  //         } else {
  //           customAlertMessage("error", "Error occured, Please try again."); // 'success','failure','info'
  //         }
  //         hideoverlay4();
  //       })
  //       .catch(function (error) {
  //         //console.log(error);
  //         customAlertMessage("error", "Something went wrong, Try Again.");
  //         hideoverlay4();
  //       });
  //   };

  //   const showthirdstep=()=>{
  //     var filteredarray = [...document.querySelectorAll('.mlsright li')].filter(el => (el.dataset.processed == "0" || el.dataset.processed == "-1"));
  //     if (filteredarray.length > 0) {
  //         customAlertMessage("error", "Please wait while we are processing the images.");
  //         // var filteredprocessingarray = [...document.querySelectorAll('.mlsright li')].filter(el => (el.dataset.processed == "0"));
  //         // if (filteredprocessingarray.length <= 0) {

  //         // }
  //         return;
  //     }
  //     document.getElementById('imagesdiv').style.display = 'none';
  //     document.getElementById('divpropertydetails').style.display = 'block';
  //     document.getElementById('div3').classList.remove('inactive');
  //     // var txtPropAddress = document.getElementById('txtPropAddress').value;
  //     // if (txtPropAddress.trim() === "") {
  //     //     customAlertMessage('error', 'Please enter property address.');
  //     //     return;
  //     // }
  //     // generateaicontentCounters();
  //   }

  //   const generatecontentfromdescription=()=>{
  //     // var txtPropAddress = document.getElementById('txtPropAddress').value;
  //     // if (txtPropAddress.trim() === "") {
  //     //     customAlertMessage('error', 'Please enter property address.');
  //     //     return;
  //     // }
  //     // validatePropertyDetails();
  //     var propTypeSel = document.getElementById("ddlPropertyType").value;
  //     if (propTypeSel == "select") {
  //         customAlertMessage('error', 'Please select property type.');
  //         setTimeout(() => {
  //             document.getElementsByClassName('prop-top-title')[0].scrollIntoView();
  //         }, 1000);
  //         return false;
  //     }
  //     var ddlBedrooms = document.getElementById("ddlBedrooms").value;
  //     if(ddlBedrooms == "select"){
  //         customAlertMessage('error', 'Number of bedrooms is required.');
  //         setTimeout(() => {
  //             document.getElementsByClassName('prop-top-title')[0].scrollIntoView();
  //         }, 1000);
  //         return false;
  //     }
  //     var ddlBathrooms = document.getElementById("ddlBathrooms").value;
  //     if(ddlBathrooms == "select"){
  //         customAlertMessage('error', 'Number of bathrooms is required.');
  //         setTimeout(() => {
  //             document.getElementsByClassName('prop-top-title')[0].scrollIntoView();
  //         }, 1000);
  //         return false;
  //     }
  //     var ddlFloors = document.getElementById("ddlFloors").value;
  //     if(ddlFloors == "select"){
  //         customAlertMessage('error', 'Number of floors is required.');
  //         setTimeout(() => {
  //             document.getElementsByClassName('prop-top-title')[0].scrollIntoView();
  //         }, 1000);
  //         return false;
  //     }
  //     var txtArea = document.getElementById("txtArea").value;
  //     if(txtArea == ""){
  //         customAlertMessage('error', 'Floor area is required.');
  //         setTimeout(() => {
  //             document.getElementsByClassName('prop-top-title')[0].scrollIntoView();
  //         }, 1000);
  //         return false;
  //     }
  //     document.getElementById('divpropertydetails').style.display = 'none';
  //     document.getElementById('editordiv').style.display = 'block';
  //     document.getElementById('div4').classList.remove('inactive');
  //     generateaicontentCounters();

  //   }

  const getroomtypebreakdown = () => {
    var photobreakli = document.querySelectorAll(".photobreak li");
    var roomtypetext = "";
    photobreakli.forEach((element, idx, array) => {
      var photobreaklispan = element.getElementsByTagName("span");
      if (photobreaklispan.length > 0) {
        roomtypetext +=
          "It has a " +
          photobreaklispan[1].innerText +
          " " +
          photobreaklispan[0].innerText +
          ", ";
        if (idx !== array.length - 1) {
          roomtypetext += ", ";
        }
      }
    });
    return roomtypetext;
  };

  const getroomfeatures = () => {
    var roomfeaturestext = "";
    // var SummaryTool = require('node-summary');
    try {
      var photobreak = document.querySelectorAll(
        ".photobreak li span:first-child"
      );
      photobreak.forEach((element) => {
        var categorytext = "";
        var filteredheading = Array.from(
          document.querySelectorAll(
            ".mlsright li:not(.errorcomplaince) .validcompliance h4"
          )
        ).filter((item) => item.innerText.includes(element.innerText));
        if (filteredheading.length > 0) {
          var filteredheadingfirst = filteredheading[0];
          categorytext +=
            filteredheadingfirst.parentElement.getElementsByTagName("p")[0]
              .innerText;
          // filteredheading.forEach(element => {
          //     categorytext += element.parentElement.getElementsByTagName('p')[0].innerText;
          // });

          // SummaryTool.summarize(element.innerText, categorytext, function(err, summary) {
          //     if(err) console.log("Something went wrong man!");

          //     console.log(summary);
          //     roomfeaturestext  += categorytext + ", ";

          //     // console.log("Original Length " + (title.length + content.length));
          //     // console.log("Summary Length " + summary.length);
          //     // console.log("Summary Ratio: " + (100 - (100 * (summary.length / (title.length + content.length)))));
          // });
        }
        roomfeaturestext += categorytext + ", ";
      });

      // var photobreakli = document.querySelectorAll('.mlsright li:not(.errorcomplaince)');// document.querySelectorAll('.mlsright li');
      // photobreakli.forEach((element, idx, array) => {
      //     var photobreaklipara = element.getElementsByTagName('p');
      //     if (photobreaklipara.length > 0) {
      //         roomfeaturestext  += photobreaklipara[0].innerText;
      //         if (idx !== array.length - 1){
      //             roomfeaturestext  +=", ";
      //         }
      //     }
      // });
    } catch (error) {}
    return roomfeaturestext;
  };

  //   const comingsoon =()=>{
  //     customAlertMessage('info','We are working on it, Coming Soon.');
  //   }

  //   const ignorecompliance=(e)=>{
  //     var imageidentity = e.currentTarget.dataset.filerefid;
  //     var editpermission = e.currentTarget.dataset.editpermission;
  //     var spacetype = e.currentTarget.dataset.spacetype;
  //     if (parseInt(editpermission) == 2) {
  //         customAlertMessage('error','Ignore compliance is not allowed for this image.');
  //         return;
  //     }
  //     if (spacetype.trim() == "Non-Compliant_front_view") {
  //         var tempuploadedimages = uploadedimages;
  //         var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == imageidentity);
  //         if (tempuploadedimagearray != undefined) {
  //             tempuploadedimagearray.compliant = "1";
  //             tempuploadedimagearray.processed = "1";
  //             tempuploadedimagearray.editpermission = 0;
  //             tempuploadedimagearray.spacetype = "front_view";
  //             removeulphotobreak("Non-Compliant_front_view",imageidentity);
  //             addtoulphotobreak("front_view",imageidentity);
  //         }
  //         setuploadedimages([...tempuploadedimages]);
  //         var compliancefailed = tempuploadedimages.filter(x=>x.compliant == "0");
  //         setcompliancepassed(!(compliancefailed.length > 0));
  //     }
  //     else{
  //         var liitem = [...document.querySelectorAll(".photobreak li")].filter(a => a.innerText.includes("front_view"));
  //         if (parseInt(editpermission) == 3 && (liitem && liitem.length <= 0)) {
  //             customAlertMessage('error','Atleast single front view is required to ignore compliance.');
  //             return;
  //         }
  //         var tempuploadedimages = uploadedimages;
  //         var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == imageidentity);
  //         if (tempuploadedimagearray != undefined) {
  //             tempuploadedimagearray.compliant = "1";
  //             tempuploadedimagearray.processed = parseInt(editpermission) == 2 ? "1": "0";
  //             tempuploadedimagearray.editpermission = 0;
  //         }
  //         setuploadedimages([...tempuploadedimages]);
  //         if (parseInt(editpermission) != 2) {
  //             // processroomtypes_s_i(processid,imageidentity);
  //         }
  //         else if(parseInt(editpermission) == 2){
  //             addtoulphotobreak(spacetype,imageidentity);
  //         }
  //     }

  //   }

  //   const removefromprocessedlist = (e)=>{
  //     var referenceid = e.currentTarget.dataset.filerefid;
  //     var spacetype = e.currentTarget.dataset.spacetype;
  //     var filteredprocessimage = uploadedimages.filter(function (entry) { return entry.image !== referenceid; });
  //     setuploadedimages([...filteredprocessimage]);
  //     // un comment to make image compliance mandatory
  //     var compliancefailed = filteredprocessimage.filter(x=>x.compliant == "0");
  //     setcompliancepassed(!(compliancefailed.length > 0));

  //     //Remove from first step
  //     var filteredprocessimage = processedimage.filter(function (entry) { return entry.refid !== (referenceid.replace(".jpeg","")); });
  //     setprocessedimage([...filteredprocessimage]);
  //     var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.identity !== (referenceid.replace(".jpeg","")); });
  //     setuploadedfile([...filtereduploadedfile]);
  //     var  filterorderedprocessedimage = orderedprocessedimage.filter(function (entry) { return entry.refid !== (referenceid.replace(".jpeg","")); });
  //     setorderedprocessedimage([...filterorderedprocessedimage]);

  //     if (e.currentTarget.parentElement.classList.contains('validcomp')) {
  //         removeulphotobreak(spacetype,referenceid);
  //     }
  //     removefilefromserver(referenceid);

  //   }
  const removefromlist = (e) => {
    var referenceid = e.currentTarget.dataset.filerefid;
    var filteredprocessimage = processedimage.filter(function (entry) {
      return entry.refid !== referenceid;
    });
    setprocessedimage([...filteredprocessimage]);
    var filtereduploadedfile = uploadedfile.filter(function (entry) {
      return entry.identity !== referenceid;
    });
    setuploadedfile([...filtereduploadedfile]);
    var filterorderedprocessedimage = orderedprocessedimage.filter(function (
      entry
    ) {
      return entry.refid !== referenceid;
    });
    setorderedprocessedimage([...filterorderedprocessedimage]);

    // if (uploadedimages.length > 0) {
    //     var filteredprocessimage = uploadedimages.filter(function (entry) { return entry.image !== (referenceid+".jpeg"); });
    //     setuploadedimages([...filteredprocessimage]);

    //     var compliancefailed = filteredprocessimage.filter(x=>x.compliant == "0");
    //     setcompliancepassed(!(compliancefailed.length > 0));
    //     removefilefromserver(referenceid);
    // }
  };

  // const removefilefromserver=(imagename)=>{
  //     // showoverlaytwo();
  //     const axios = require('axios');
  //     const FormData = require('form-data');
  //     let data = new FormData();
  //     data.append('uid', UserID);
  //     data.append('companyid', Companyid);
  //     data.append('processid', processid);
  //     data.append('name', imagename);
  //         let config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: `${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/removeimage',
  //         // url: 'https://localhost:44372/api/ArtificialIntelligence/removeimage',
  //         data : data
  //         };

  //         axios.request(config)
  //         .then((response) => {
  //             // console.log(JSON.stringify(response.data));
  //             var Responsedata = response.data.Message;
  //             if (Responsedata != "" && Responsedata != null && Responsedata=="success") {
  //                 // hideoverlaytwo();
  //             }
  //             else{
  //                 // customAlertMessage('error','Something went wrong, Please try again.');
  //                 // hideoverlaytwo();
  //             }

  //         })
  //         .catch((error) => {
  //             console.log(error);
  //             customAlertMessage('error','Something went wrong, Please try again.');
  //             // hideoverlaytwo();
  //         });
  // }

  const startprocess_single = () => {
    if (uploadedimages.length <= 0) {
      return;
    }
    var filteredarray = [...document.querySelectorAll(".mlsright li")].filter(
      (el) => el.dataset.processed == "-1"
    );
    var filteredarrayfailed = [
      ...document.querySelectorAll(".mlsright li"),
    ].filter((el) => el.dataset.processed == "-10");
    if (filteredarray.length > 0) {
      var originalidentity = filteredarray[0].dataset.actualidentity;
      // processcompliance_s_i(processid,originalidentity);
      setanalyzingroomtype(true);
      var tempuploadedimages = uploadedimages;
      var tempuploadedimagearray = tempuploadedimages.find(
        (x) => x.image == originalidentity
      );
      if (tempuploadedimagearray != undefined) {
        tempuploadedimagearray.processed = "0";
      }
      setuploadedimages([...tempuploadedimages]);
    } else if (filteredarrayfailed.length > 0) {
      var originalidentity = filteredarrayfailed[0].dataset.actualidentity;
      // processcompliance_s_i(processid,originalidentity);
      setanalyzingroomtype(true);
      var tempuploadedimages = uploadedimages;
      var tempuploadedimagearray = tempuploadedimages.find(
        (x) => x.image == originalidentity
      );
      if (tempuploadedimagearray != undefined) {
        tempuploadedimagearray.processed = "0";
      }
      setuploadedimages([...tempuploadedimages]);
    } else {
      var processedimages = uploadedimages.filter((x) => x.processed == "1");
      if (processedimages.length == uploadedimages.length) {
        var compliancefailed = uploadedimages.filter((x) => x.compliant == "0");
        setcompliancepassed(!(compliancefailed.length > 0));
        document.getElementById("complianceproceed").style.display =
          "inline-block";
        setanalyzingroomtype(false);
      }
    }
  };

  // const startprocess=()=>{
  //     if (uploadedimages.length <= 0) {
  //         return;
  //     }
  //     var filteredarray = [...document.querySelectorAll('.mlsright li')].filter(el => el.dataset.processed == "-1");
  //     if(filteredarray.length > 0)
  //     {
  //         const slicedArray = filteredarray.slice(0, 10);
  //         slicedArray.forEach(element => {
  //             var originalidentity = element.dataset.actualidentity;
  //             // processcompliance_s_i(processid,originalidentity);
  //             setanalyzingroomtype(true);
  //             var tempuploadedimages = uploadedimages;
  //             var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == originalidentity);
  //             if (tempuploadedimagearray != undefined) {
  //                 tempuploadedimagearray.processed = "0";
  //             }
  //             setuploadedimages([...tempuploadedimages]);
  //         });
  //     }
  //     else{
  //         var processedimages = uploadedimages.filter(x=>x.processed == "1");
  //         if (processedimages.length == uploadedimages.length) {
  //             // un comment to make image compliance mandatory
  //             var compliancefailed = uploadedimages.filter(x=>x.compliant == "0");
  //             setcompliancepassed(!(compliancefailed.length > 0));
  //             document.getElementById('complianceproceed').style.display = 'inline-block';
  //             setanalyzingroomtype(false);
  //         }

  //     }
  //     return;
  //     var lilist = document.querySelectorAll('.mlsright li');
  //     var itemstoprocess = 0;
  //     lilist.filter(el=> el.dataset.processed == "-1")
  //     lilist.forEach(element => {
  //         var processed = element.dataset.processed;
  //         var originalidentity = element.dataset.actualidentity;
  //         if (processed === "-1") {
  //             // processcompliance_s_i(processid,originalidentity);
  //             // processroomtypes_s_i(processid,originalidentity);
  //             var tempuploadedimages = uploadedimages;
  //             var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == originalidentity);
  //             if (tempuploadedimagearray != undefined) {
  //                 tempuploadedimagearray.processed = "0";
  //             }
  //             //check array updating automatically
  //             setuploadedimages([...tempuploadedimages]);
  //             // element.dataset.actualidentity = "0";
  //             itemstoprocess += 1;
  //         }
  //         else{
  //             var processedimages = uploadedimages.filter(x=>x.processed == "1");
  //             if (processedimages.length == uploadedimages.length) {
  //                 var compliancefailed = uploadedimages.filter(x=>x.compliant == "0");
  //                 setcompliancepassed(!(compliancefailed.length > 0));
  //                 setanalyzingroomtype(false);
  //             }

  //         }
  //     });
  // }

  // function validatePropertyDetails (){
  //     var propTypeSel = document.getElementById("ddlPropertyType").value;
  //     if (propTypeSel != "select") {
  //         customAlertMessage('error', 'Please select property type.');
  //         return false;
  //     }
  //     var ddlBedrooms = document.getElementById("ddlBedrooms").value;
  //     if(ddlBedrooms == "select"){
  //         customAlertMessage('error', 'Number of bedrooms is required.');
  //         return false;
  //     }
  //     var ddlBathrooms = document.getElementById("ddlBathrooms").value;
  //     if(ddlBathrooms == "select"){
  //         customAlertMessage('error', 'Number of bathrooms is required.');
  //         return false;
  //     }
  //     var ddlFloors = document.getElementById("ddlFloors").value;
  //     if(ddlFloors == "select"){
  //         customAlertMessage('error', 'Number of floors is required.');
  //         return false;
  //     }
  //     var txtArea = document.getElementById("txtArea").value;
  //     if(txtArea == ""){
  //         customAlertMessage('error', 'Floor area is required.');
  //         return false;
  //     }
  // }

  function loadAmenities(placelat, placeng) {
    //sleep(3);
    var serviceData = new window.google.maps.places.PlacesService(
      document.createElement("div1Result")
    );
    var pyrmont = new window.google.maps.LatLng(placelat, placeng);
    const request = {
      location: pyrmont,
      fields: ["name"],
      radius: "8033",
      rankby: "distance",
      type: ["school"],
    };
    serviceData.nearbySearch(request, callbackSchool);
    //   >hospital
    //   >park
    //   >supermarket
    const requesthospitals = {
      location: pyrmont,
      fields: ["name"],
      radius: "8033",
      rankby: "distance",
      type: ["hospital"],
    };
    serviceData.nearbySearch(requesthospitals, callbackHospitals);

    const requestpark = {
      location: pyrmont,
      fields: ["name"],
      radius: "8033",
      rankby: "distance",
      type: ["park"],
    };
    serviceData.nearbySearch(requestpark, callbackParks);

    const requestsupermarket = {
      location: pyrmont,
      fields: ["name"],
      radius: "8033",
      rankby: "distance",
      type: ["supermarket"],
    };
    serviceData.nearbySearch(requestsupermarket, callbackSupermarkets);

    document.getElementById("div1Result").style.display = "block";
  }

  function callbackSchool(results, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      var divSchoolsData = document.getElementById("divSchoolsData");
      divSchoolsData.style.display = "block";
      if (results != undefined && results.length > 0) {
        var schoolsText = "<ul class='checkbox-category'>";
        for (var i = 0; i < results.length; i++) {
          // create list of schools
          //console.log('schools data '+i + " " + results[i].name);
          schoolsText +=
            "<li><input class='chkSchoolAmenities' id='chkschoolAmenity" +
            i +
            "' name='schoolAmenity' type='checkbox' />&nbsp;<label id='lblschoolAmenity" +
            i +
            "'>" +
            results[i].name +
            "</label></li>";
          if (i == 9) {
            break;
          }
        }
        schoolsText = schoolsText + "</ul>";
        divSchoolsData.innerHTML = schoolsText;
      } else {
        divSchoolsData.innerText = "No data found.";
      }
    } else {
      document.getElementById("divSchoolsData").innerText = "No data found.";
    }
    hideoverlay();
  }

  function callbackHospitals(results, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      var divSchoolsData = document.getElementById("divHospitalsData");
      divSchoolsData.style.display = "block";
      if (results != undefined && results.length > 0) {
        var schoolsText = "<ul class='checkbox-category'>";
        for (var i = 0; i < results.length; i++) {
          // create list of schools
          //console.log('schools data '+i + " " + results[i].name);
          schoolsText +=
            "<li><input class='chkHospitalAmenities' id='chkhospitalAmenity" +
            i +
            "' name='hospitalAmenity' type='checkbox' />&nbsp;<label id='lblhospitalAmenity" +
            i +
            "'>" +
            results[i].name +
            "</label></li>";
          if (i == 9) {
            break;
          }
        }
        schoolsText = schoolsText + "</ul>";
        divSchoolsData.innerHTML = schoolsText;
      } else {
        divSchoolsData.innerHTML = "<p>No data found.</p>";
      }
    } else {
      document.getElementById("divHospitalsData").innerText = "No data found.";
    }
    hideoverlay();
  }

  function callbackParks(results, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      var divSchoolsData = document.getElementById("divParksData");
      divSchoolsData.style.display = "block";
      if (results != undefined && results.length > 0) {
        var schoolsText = "<ul class='checkbox-category'>";
        for (var i = 0; i < results.length; i++) {
          // create list of schools
          //console.log('schools data '+i + " " + results[i].name);
          schoolsText +=
            "<li><input class='chkParkAmenities' id='chkparkAmenity" +
            i +
            "' name='parkAmenity' type='checkbox' />&nbsp;<label id='lblparkAmenity" +
            i +
            "'>" +
            results[i].name +
            "</label></li>";
          if (i == 9) {
            break;
          }
        }
        schoolsText = schoolsText + "</ul>";
        divSchoolsData.innerHTML = schoolsText;
      } else {
        divSchoolsData.innerText = "No data found.";
      }
    } else {
      document.getElementById("divParksData").innerText = "No data found.";
    }
    hideoverlay();
  }

  function callbackSupermarkets(results, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      var divSchoolsData = document.getElementById("divSsupermarketsData");
      divSchoolsData.style.display = "block";
      if (results != undefined && results.length > 0) {
        var schoolsText = "<ul class='checkbox-category'>";
        for (var i = 0; i < results.length; i++) {
          // create list of schools
          //console.log('schools data '+i + " " + results[i].name);
          schoolsText +=
            "<li><input class='chkSupermarketAmenities' id='chksupermarketAmenity" +
            i +
            "' name='supermarketAmenity' type='checkbox' />&nbsp;<label id='lblsupermarketAmenity" +
            i +
            "'>" +
            results[i].name +
            "</label></li>";
          if (i == 9) {
            break;
          }
        }
        schoolsText = schoolsText + "</ul>";
        divSchoolsData.innerHTML = schoolsText;
      } else {
        divSchoolsData.innerText = "No data found.";
      }
    } else {
      document.getElementById("divSsupermarketsData").innerText =
        "No data found.";
    }
    hideoverlay();
  }

  function countUniqueStrings(csv) {
    // Split the string by commas, trim whitespace, and filter out empty strings
    const items = csv
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);

    // Use a Set to store unique items
    const uniqueItems = new Set(items);

    // Return the count of unique items
    return uniqueItems.size;
  }
  const addtoulphotobreak = (name, identity) => {
    try {
      var liitem = [...document.querySelectorAll(".photobreak li")].filter(
        (a) => a.innerText.includes(name)
      );
      if (liitem && liitem.length > 0) {
        // var count = parseInt(liitem[0].getElementsByTagName('span')[1].innerText);
        if (
          liitem[0]
            .getElementsByTagName("span")[1]
            .dataset.identities.indexOf(identity) == -1
        ) {
          var commaseparated =
            liitem[0].getElementsByTagName("span")[1].dataset.identities +
            identity +
            ",";
          liitem[0].getElementsByTagName("span")[1].dataset.identities =
            commaseparated;
          liitem[0].getElementsByTagName("span")[1].innerText =
            countUniqueStrings(commaseparated);
        }
      } else {
        var li = document.createElement("li");
        var span = document.createElement("span");
        span.innerText = name;
        li.appendChild(span);
        var cntspan = document.createElement("span");
        cntspan.innerText = "1";
        cntspan.dataset.identities = identity + ",";
        li.appendChild(cntspan);
        document.getElementsByClassName("photobreak")[0].appendChild(li);
        lirearrange();
      }
    } catch (error) {}
  };

  const lirearrange = () => {
    const list = document.querySelector(".photobreak");

    // Convert the NodeList of <li> elements to an array
    const itemsArray = Array.from(list.querySelectorAll("li"));

    // Sort the array
    itemsArray.sort((a, b) => a.textContent.localeCompare(b.textContent));

    // Append each sorted <li> back to the parent element
    itemsArray.forEach((li) => list.appendChild(li));
  };

  const removeulphotobreak = (name, identity) => {
    try {
      var liitem = [...document.querySelectorAll(".photobreak li")].filter(
        (a) => a.innerText.includes(name)
      );
      if (liitem && liitem.length > 0) {
        // var count = parseInt(liitem[0].getElementsByTagName('span')[1].innerText);
        if (
          liitem[0]
            .getElementsByTagName("span")[1]
            .dataset.identities.indexOf(identity) != -1
        ) {
          var commaseparated = liitem[0]
            .getElementsByTagName("span")[1]
            .dataset.identities.replace(identity, "");
          liitem[0].getElementsByTagName("span")[1].dataset.identities =
            commaseparated;
          var countstrings = countUniqueStrings(commaseparated);
          if (countstrings == 0) {
            liitem[0].remove();
            lirearrange();
          } else {
            liitem[0].getElementsByTagName("span")[1].innerText = countstrings;
          }
        }
      }
      // else{
      //     var li = document.createElement('li');
      //     var span = document.createElement('span');
      //     span.innerText = name;
      //     li.appendChild(span);
      //     var cntspan = document.createElement('span');
      //     cntspan.innerText = "1";
      //     cntspan.dataset.identities = identity+",";
      //     li.appendChild(cntspan);
      //     document.getElementsByClassName('photobreak')[0].appendChild(li);
      // }
    } catch (error) {}
  };
  //   const resettoinitialwithajaxcheck =()=>{
  //     var filteredarray = [...document.querySelectorAll('.mlsright li')].filter(el => (el.dataset.processed == "0" || el.dataset.processed == "-1"));
  //     if (filteredarray.length > 0) {
  //         customAlertMessage("error", "Please wait while we are processing the images.");
  //         return;
  //     }
  //     document.getElementById('upload_div').style.display ="block";
  //     document.getElementById('main_sherlockinner_div').style.display = "none";
  //     resettoinitial();
  //   }
  //   //comehere
  //   const Addmoreimage=()=>{
  //     document.getElementById('upload_div').style.display ="block";
  //     document.getElementById('main_sherlockinner_div').style.display = "none";
  //     document.getElementById('showstartbtn').style.display = "none";
  //     document.getElementsByClassName('dropzone')[0].click();
  //   }

  //   const removerequiredfield =(e)=>{
  //     var vallll = e.currentTarget.value;
  //     var element = e.currentTarget;
  //     // var tempdropdownvalues = dropdownvalues;
  //     if (vallll != "-1") {
  //         element.classList.remove("requirederror");
  //     }
  //     else {
  //         element.classList.add("requirederror");
  //     }
  //     var identity = e.currentTarget.dataset.filerefid;
  //     var selectedspacetype = e.currentTarget.dataset.selectedspacetype;
  //     var tempuploadedimages = uploadedimages;
  //     var tempuploadedimagearray = tempuploadedimages.find(x=>x.image == identity);
  //     if (tempuploadedimagearray != undefined) {
  //         var existingspacetype = tempuploadedimagearray.spacetype;
  //         removeulphotobreak(existingspacetype,identity);
  //         if (tempuploadedimagearray.spacetype != vallll) {
  //             tempuploadedimagearray.spacetype = vallll;
  //             // var liitem = [...document.querySelectorAll(".photobreak li")].filter(a => a.innerText.includes(name));
  //             // if (liitem && liitem.length > 0) {
  //             addtoulphotobreak(vallll,identity);
  //         }
  //         setuploadedimages([...tempuploadedimages]);
  //     }
  //   }
  // const removerequiredfield = (e) => {
  //     var vallll = e.currentTarget.value;
  //     var element = e.currentTarget;
  //     var tempdropdownvalues = dropdownvalues;
  //     if (vallll != "-1") {
  //         element.classList.remove("requirederror");
  //     }
  //     else {
  //         element.classList.add("requirederror");
  //     }
  //     var refid = element.dataset.filerefid;
  //     var selectedoption = tempdropdownvalues.filter(function (entry) { return entry.refid == refid; });
  //     if (selectedoption.length > 0) {
  //         selectedoption[0].text = vallll;
  //     }
  //     else {
  //         var sitem = {};
  //         sitem.refid = refid;
  //         sitem.text = vallll;
  //         tempdropdownvalues.push(sitem);
  //     }
  //     setdropdownvalues(tempdropdownvalues);
  // }
  const showuploaddiv=()=>{
    document.getElementById('crawldiv').style.display = "none";
    document.getElementById('sherlockdiv').style.display = "none";
    document.getElementById('main_div').style.display = "none";
    document.getElementById('upload_div').style.display = "block";
    document.getElementById('livelink').classList.remove('active');
    document.getElementById('crawllink').classList.remove('active');
    document.getElementById('uploadlink').classList.add('active');  
  }
  const showcrawldiv=()=>{
    var imgurlinput = document.getElementById('imgurlinput').value;
    if (uploadedfile.length <= 0 && imgurlinput.trim() == "") {
      customAlertMessage('error','Please select files and click on continue.');
      document.getElementById('livelink').classList.remove('active');
      document.getElementById('crawllink').classList.remove('active');
      document.getElementById('uploadlink').classList.add('active');  
      return;
    }
    document.getElementById('crawldiv').style.display = "block";
    document.getElementById('sherlockdiv').style.display = "none";
    document.getElementById('main_div').style.display = "block";
    document.getElementById('upload_div').style.display = "none";
    document.getElementById('uploadlink').classList.remove('active');
    document.getElementById('livelink').classList.remove('active');
    document.getElementById('crawllink').classList.add('active');    
  }
  const showlivediv=()=>{
    var imgurlinput = document.getElementById('imgurlinput').value;
    if (uploadedfile.length <= 0 && imgurlinput.trim() == "") {
      customAlertMessage('error','Please select files and click on continue.');
      document.getElementById('livelink').classList.remove('active');
      document.getElementById('crawllink').classList.remove('active');
      document.getElementById('uploadlink').classList.add('active');  
      return;
    }
    document.getElementById('crawldiv').style.display = "none";
    document.getElementById('sherlockdiv').style.display = "block";
    document.getElementById('main_div').style.display = "block";
    document.getElementById('upload_div').style.display = "none";
    document.getElementById('uploadlink').classList.remove('active');
    document.getElementById('crawllink').classList.remove('active');
    document.getElementById('livelink').classList.add('active');    
  }

  const scrolltotop=()=>{
    // document.getElementById("mlscompliancediv").scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 0)
  }

const  infoshow = () => {
    document.getElementById('popuptextinfoshow').style.display = "block";
}
const infohide = () => {
    document.getElementById('popuptextinfoshow').style.display = "none";
}
// Function to increment the progress bar
function incrementProgressBarhtml() {
  // Get the progress bar element
  var progressBar = document.getElementById('progress');
  
  // Get the current width of the progress bar
  var currentWidth = parseFloat(progressBar.style.width) || 0;
  
  // Set the new width of the progress bar (increment by 1%)
  let x = parseInt(Math.random() * 7); 
  var newWidth = (currentWidth + x);// Math.min(currentWidth + x, 100); // Cap at 100%
  
  
  // If progress reaches 100%, clear the interval
  // if (newWidth === 100) {
    if (newWidth >= 90) {
    clearInterval(progressInterval);
  }
  else{
    progressBar.style.width = newWidth + '%';
    document.getElementById('progresspercent').innerText = newWidth + '%';
  }
}



// Function to increment the progress bar
function incrementProgressBar() {
  // Get the progress bar element
  //debugger;
  if (progressval < 90) {
    let x = parseInt(Math.random() * 7); 
    setprogressval(progressval + x);  
  }
  else{
    clearInterval(progressInterval);
  }
  // // Get the current width of the progress bar
  // var currentWidth = parseFloat(progressBar.style.width) || 0;
  
  // // Set the new width of the progress bar (increment by 1%)
  // var newWidth = Math.min(currentWidth + 1, 100); // Cap at 100%
  // progressBar.style.width = newWidth + '%';
  
  // // If progress reaches 100%, clear the interval
  // if (newWidth === 100) {
  //   clearInterval(progressInterval);
  // }
}


  // render(){
  //htmlhere
  return (
    <>
      <div className="main_content_iner overly_inner dashboard-page">
        <div className="container-fluid p-0 " id="mlscompliancediv">        
        <div className="row">
        <div className="col-12">
              <div className="title-card1">
                <h4>
                  <span style={{ cursor: "pointer" }} onClick={historygoback}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                    Live Track
                  </span>
                </h4>
              </div>
            </div>
        </div>  
        <div className='col-12' style={{ marginBottom: "10px" }}>
                            <ul className='sherlocklivetab'>
                                <li id="uploadlink" className='active' onClick={showuploaddiv}><a>PHOTOS: <span>{imageurlinput != "" ? "1" : uploadedfile.length > 0 ? uploadedfile.length:""}</span></a></li>
                                <li id="crawllink" onClick={showcrawldiv}><a>WEB HITS: <span id="processingwebhitsspan"></span></a></li>
                                <li id="livelink" onClick={showlivediv}><a>UNAUTHORIZED URL MATCHES: 
                                {dashunauthorizedcount == -1 ? 
                                <span id="processingspan" style={{display:'none'}} className='loadingellipsis'>Searching</span> :
                                 <span>{dashunauthorizedcount.toLocaleString()}</span>
                                }
                                </a></li>
                            </ul>
                        </div>
                        <div className="col-12 float-end" style={{ marginBottom: "20px" }}>
                            <a href="javascript:;" className="float-end" style={{color:'red'}} onClick={resettoinitialonback}><b><u>Reset</u></b></a>
                        </div>
  <div className='col-12' id='totalphotos'>
                            <div className='row' style={{ justifyContent: "center" }}>
                                <div className='col-6' style={{ marginBottom: "20px" }}>


          <div className="row" id="upload_div">         
          <div className='col-12 imageuploaddiv' style={{ marginBottom: "30px" }}>
                                            <div className='row' style={{ position: "relative" }}>
                                                <div className='col-lg-3 col-md-3'>
                                                    <label style={{ paddingTop: "15px" }}>Image URL:</label>
                                                </div>
                                                <div className='col-lg-8 col-md-8'>
                                                    <input id="imgurlinput" type="text" className='common-input' />
                                                </div>
                                                <div className='col-lg-1 col-md-1'>
                                                    <FontAwesomeIcon icon={faQuestionCircle} className='questioncirclelive' onMouseOver={infoshow} onMouseLeave={infohide} />
                                                    <div className='popuptextinfo' style={{ display: "none" }} id="popuptextinfoshow">
                                                        <p>
                                                            To find the URL for an image on any webpage, follow these simple steps:
                                                            <ul>
                                                                <li>Right click on the image you want to get the URL for.</li>
                                                                <li>In the menu that pops up, click "Copy image address" or "Copy image link" (this may vary slightly depending on your browser).</li>
                                                                <li>This will copy the direct URL to that image onto your clipboard.</li>
                                                                <li>You can then paste this image URL to the Image URL box.</li>
                                                                <li>The image URL will start with "https://" and end with the file extension, like ".jpg" or ".png". This direct link points to the actual image file location online.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 imageuploaddiv'>
                                            <center><p>OR</p></center>
                                        </div>   
            <div className="col-12 imageuploaddiv">
              <div
                className="dropzone-wrapper mobiletop0"
                style={{
                  width: "100%",
                  marginBottom: "40px",
                  marginTop: "15px",
                }}
                onDragEnter={dropzonedragenter}
                onDragOver={dropzonedragover}
                onDragLeave={dropzonedragleave}
                onDrop={dropzonefiledrop}
              >
                <div id="overlayiphone">
                  <div id="loading" style={{ top: "35%" }}>
                    <img src={loader} />
                    <h2 id="loaderheading" className="loaderheading">
                      <span>Processing...</span>
                    </h2>
                  </div>
                </div>
                <div className="dropzone-desc">
                  <h4>Drag and Drop</h4>
                  <p>
                    <b>or Click to upload</b>
                  </p>
                  <FontAwesomeIcon icon={faCloudArrowUp} />
                  <p>Supported Files: PNG, JPG, WEBP, HEIF, HEIC.</p>
                  {processedimage && processedimage.length > 0 ? (
                    <p>{processedimage.length} images selected.</p>
                  ) : null}
                </div>
                <input
                  type="file"
                  name="img_logo"
                  className="dropzone"
                  title=""
                  onChange={dropzonechange}
                  accept="image/png, image/jpeg, image/jpg, image/heif, image/heic, image/webp"
                  multiple={true}
                ></input>
              </div>
            </div>
            <div className="col-12">
              <ul className="mlsuploadedphoto">
                {processedimage && processedimage.length > 0
                  ? processedimage.map((d) => (
                      <li key={uuidv4()}>
                        <img src={d.image} />
                        <span
                          className="removeuploaded"
                          data-filerefid={d.refid}
                          onClick={removefromlist}
                        >
                          <FontAwesomeIcon icon={faRemove} />
                        </span>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
            {/* {processedimage && processedimage.length > 0 ? ( */}
              <div className='col-12 mb-30 text-center'>
                {/* <a href="javascript:;" className="common-save common-save-grey" style={{ marginRight: "10px" }} onClick={resettoinitial}>Clear</a> */}
                <a href="javascript:;" className="common-save" onClick={uploadimages}>Continue</a>
                {/* <div id="progress-bar" style={{width:'100%',height:'20px',border:'1px solid black',backgroundColor:'#ccc',borderRadius:'5px'}}>
                  <div id="progress" style={{width:'0px',height:'100%',backgroundColor:'#9333ea'}}></div>                  
                </div>
                <span id="progresspercent">2%</span> */}
                {/* <ProgressBar completed={progressval} bgColor="#9333ea" labelColor="#ffffff"/> */}               
              </div>              
             {/* ) : null} */}
          </div>
</div>
</div>
</div>

          <div id="main_div" style={{display:'none'}}>
            <div id="crawldiv">
            <div className="row">            
              </div>
              <div className="row" id="divResetSuccess" style={{display:'none'}}>
                <div className="col-sm-12">
                    <center>
                        <br />
                        <label id="lblUnsubText">Loading...</label>
                    </center>
                </div>
              </div>
              <div className="row">
              <div className='col-12 matchfoundcss' id="divResult">
              
              </div>
              </div>
              <div id="publicsearchloader" style={{display:'none',width:'100%'}}>
              {/* <MyListLoader/> */}
              <div className="col-12">
<div className="row" style={{justifyContent:'center',alignItems:'center'}}>
<div className="col-6">

<div id="progress-bar" style={{width:'100%',height:'20px',border:'1px solid black',backgroundColor:'#ccc',borderRadius:'10px'}}>
                  <div id="progress" style={{width:'0px',height:'100%',backgroundColor:'#9333ea',borderRadius:'10px'}}></div>                  
                </div>
                
<center>
<span id="progresspercent">2%</span>
</center>
</div>
</div>
              </div>
              
                
              {/* <ProgressBar id="progressloader" completed={progressval} bgColor="#9333ea" labelColor="#ffffff"/> */}
              </div>
              <div className="scroll" id="hidscroll" style={{color:'#fff',display:'block',position:'fixed',bottom:'20px',right:'10px',zIndex:9999999,background:'#9333ea',width:'40px',textAlign:'center'}}>
              <p>
                <a href="javascript:;" onClick={scrolltotop}>
                  <FontAwesomeIcon style={{position:'relative',fontSize:'30px',top:'10px',color:"#fff"}} icon={faChevronUp} />
                </a>
              </p>
    </div>
            </div>
            <div id="sherlockdiv" style={{display:'none'}}>
              <div className="row" id="main_sherlockinner_div">              
              <div className="col-12" style={{ position: "relative" }}>
                <div
                  id="overlayloader4"
                  className="email-overlay"
                  style={{ display: "none" }}
                >
                  <div id="loading">
                    <img src={loader} />
                    <h2 id="loaderheading" className="loaderheading">
                      <span>Processing...</span>
                    </h2>
                  </div>
                </div>
                <div id="overlaytwomls">
                  <div id="loading" style={{ top: "30%" }}>
                    <img src={loader} />
                    <h2 id="loaderheading" className="loaderheading">
                      <span>Processing...</span>
                    </h2>
                  </div>
                </div>
                <div id="overlay">
                  <div id="loading" style={{ top: "30%" }}>
                    <img src={loader} />
                    <h2 id="loaderheading" className="loaderheading">
                      <span>Analyzing room types...</span>
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-12" style={{ marginBottom: "20px",display:'none' }}>
                <div className="row">
                <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#e2ffed" }}
                    >
                      <h4>Total Photos</h4>
                      {imageurlinput != "" ? <h2>1</h2> : (uploadedfile.length <= 0 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>{uploadedfile.length.toLocaleString()}</h2>
                      ))}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#e2ffed" }}
                    >
                      <h4>Total Web Hits</h4>
                      {dashtotalcount == -1 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>{dashtotalcount.toLocaleString()}</h2>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div
                      className="sherlock-box"
                      style={{ background: "#ffefef" }}
                    >
                      <h4>Total Unauthorized Matches</h4>
                      {dashunauthorizedcount == -1 ? (
                        <h2>
                          <MyLoader />
                        </h2>
                      ) : (
                        <h2>{dashunauthorizedcount.toLocaleString()}</h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12" style={{ marginTop: "20px" }}>
                <div className="row">
                  <div className="col-12">
                    <Sherlockliveunauthorizedlist
                      griddata={Sherlockliveunauthorizedlistdata} listcount={dashunauthorizedcount} onClick={populateunauthorizeddetails}
                    ></Sherlockliveunauthorizedlist>
                  </div>
                </div>
              </div>
              {/* <div className="col-12" style={{ marginTop: "20px",display:'none' }}>
                <div className="row">
                  <div className="col-12">
                    <Sherlockliveauthorizedlist
                      griddata={Sherlockliveauthorizedlistdata} onClick={populateauthorizeddetails}
                    ></Sherlockliveauthorizedlist>
                  </div>
                </div>
              </div> */}
              </div>
              <div className="row" id="details_div" style={{ display: "none" }}>
                {/* <div className="row" id="main_sherlockinner_div"> */}
                <div className="col-12">
                  <div className="title-card1">
                    <h4>
                      <span style={{ cursor: "pointer" }} onClick={showmaindiv}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Back
                      </span>
                    </h4>
                  </div>
                </div>
                {/* <div className="col-12" style={{ position: "relative" }}>
                  <div
                    id="overlayloader4"
                    className="email-overlay"
                    style={{ display: "none" }}
                  >
                    <div id="loading">
                      <img src={loader} />
                      <h2 id="loaderheading" className="loaderheading">
                        <span>Processing...</span>
                      </h2>
                    </div>
                  </div>
                  <div id="overlaytwomls">
                    <div id="loading" style={{ top: "30%" }}>
                      <img src={loader} />
                      <h2 id="loaderheading" className="loaderheading">
                        <span>Processing...</span>
                      </h2>
                    </div>
                  </div>
                  <div id="overlay">
                    <div id="loading" style={{ top: "30%" }}>
                      <img src={loader} />
                      <h2 id="loaderheading" className="loaderheading">
                        <span>Analyzing room types...</span>
                      </h2>
                    </div>
                  </div>
                </div> */}
                <div className="col-12" style={{ marginTop: "20px" }} id="details_div_unauthdetails">
                  <div className="row">
                    <div className="col-12">
                      <Sherlockliveunauthorizeddetailslist
                        griddata={unauthorizedlistfordetails} domainname={unauthorizedlistfordetailsdomain}
                      ></Sherlockliveunauthorizeddetailslist>
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ marginTop: "20px" }} id="details_div_authdetails">
                  <div className="row">
                    <div className="col-12">
                      <Sherlockliveauthorizeddetailslist
                        griddata={authorizedlistfordetails} domainname={authorizedlistfordetailsdomain}
                      ></Sherlockliveauthorizeddetailslist>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="modal fade shareemailui" id="shareemail" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share Email</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <label>To:</label>
                    <input type="text" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>CC:</label>
                    <input type="text" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>BCC:</label>
                    <input type="text" placeholder="Enter Email"/>
                </div>
                <div className="col-12">
                    <label>Message:</label>
                    <textarea placeholder="Enter Message"></textarea>
                </div>
                <div className="col-12">
                    <center>
                        <a href="javascript:;" tabindex="-1" class="common-save">Send</a>
                    </center>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</div>
    </>
  );
  // }
};

export default Sherlocklive;
