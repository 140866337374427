import Cookies from 'js-cookie';
import nopicture from "./img/nopic.png"

const getUserInfo = () => {
    try {
       return JSON.parse(Cookies.get('userInfo'));      
    } catch (error) {
        
    }
    return Object;
} 
export const UserID = getUserInfo().UserId;
export const Companyid = getUserInfo().CompanyId;
export const Name = getUserInfo().FirstName + " " +  getUserInfo().LastName;
export const Phonenumber = getUserInfo().phonenumber;
export const Dob = getUserInfo().Dob;
export const StreetAddress = getUserInfo().StreenAddress;
export const City = getUserInfo().City;
export const State = getUserInfo().State;
export const Zip = getUserInfo().Zip;
export const ThirdPartyId = getUserInfo().ThirdPartyId;
export const ThirdPartyType = getUserInfo().ThirdPartyType;
export const ThirdPartyToken = getUserInfo().ThirdPartyToken;
export const Role = getUserInfo().Role;
export const ProfileImg = (getUserInfo().ProfileImg == "" ? nopicture : getUserInfo().ProfileImg);
export const TeamId = getUserInfo().TeamId;
export const CompanyName = getUserInfo().CompanyName;
export const PaymentCustomerId = getUserInfo().PaymentCustomerId;
export const Rolenamestring = () => {
    switch(Role){
        case 1:
        return "Admin";
        break;
        case 2:
        return "User";
        break;
        case 3:
        return "User";
        break;
        case 4:
        return "";
        break;
    }
}
export const Rolename = Rolenamestring();
export const EulaValue = getUserInfo().IsEULAUpdated;
export const PlanType = getUserInfo().PlanType;
export const PlanLevel = getUserInfo().PlanLevel;
export const TotalWords = getUserInfo().TotalWordsCount;
export const TotalImages = getUserInfo().TotalImagesCount;
export const UsedWords = getUserInfo().UsedWordsCount;
export const UsedImages = getUserInfo().UsedImagesCount;
export const Features = getUserInfo().Features;
export const MasterId = getUserInfo().MasterId;
export const TextToImageSub = getUserInfo().TextToImageSub;
export const ImageTransformSub = getUserInfo().ImageTransformSub;
export const HomedecorSub = getUserInfo().HomedecorSub;
export const ImageRes = getUserInfo().ImageRes;
export const EmailId = getUserInfo().EmailId;
export const Trail = getUserInfo().Trial;
export const SSOUser = getUserInfo().SSOUser;
export const SubscriptionState = getUserInfo().SubscriptionState;