import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name,Role} from "../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from "query-string";
import CsvDownloader from 'react-csv-downloader';
import { faArrowDown, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faMailBulk, faMessage, faRemove } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

// import ExternalInfo from "components/ExternalInfo";

const Sherlockauthorizeddetailslist = () => {
    const [tabledata, settabledata] = useState([]);
    const [tabledatacsv, settabledatacsv] = useState([]);
    const [maxDatetime, setmaxDatetime] = useState("")
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Discoveries", field: "matchedurl", sortable: true },
        { name: "Image Source", field: "imagesource", sortable: true },        
        { name: "Discovered On", field: "dateseen", sortable: true }
    ];

    const columns = [
        {
          id: 'matchedurl',
          displayName: 'Discoveries',
        },
        {
          id: 'imagesource',
          displayName: 'Image Source',
        },
        {
            id: 'dateseen',
            displayName: 'Discovered On',
          }
      ];

    useEffect(() => {      
        const getData = () => {
            var querystringdomainname = queryString.parse(window.location.search).id == undefined
            ? ""
            : queryString.parse(window.location.search).id;
            if (querystringdomainname == "") {
                return;
            }
            showLoader();
            var config = {
                method: 'get',
                maxBodyLength: Infinity,
                url:`${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/getauthorizedlistdetails?filterdomain='+querystringdomainname+'&companyid=&mlsname='
                // url:'https://localhost:44372/api/ArtificialIntelligence/getauthorizedlistdetails?filterdomain='+querystringdomainname+'&companyid=&mlsname='
              };
        
            axios(config)
                .then(function (resp) {                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            settabledata(JSON.parse(response.Data));                            
                            getMaxDatetime(JSON.parse(response.Data));

                            const modifiedJsonArray = JSON.parse(response.Data).map(obj => {
                                return {
                                    ...obj,
                                    dateseen: moment.utc(obj.dateseen).local().format('MM-DD-YYYY h:mm A')
                                };
                            });
                            settabledatacsv(modifiedJsonArray);

                            // settabledatacsv(JSON.parse(response.Data));
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });
        };

        getData();
    }, []);

    const tableData = useMemo(() => {
        let computeddata = tabledata;

        if (search) {

            computeddata = computeddata.filter(
                tdata =>
                tdata.matchedurl.toLowerCase().includes(search.toLowerCase()) 
                // || 
                // tdata.imagesource.toLowerCase().startsWith(search.toLowerCase())
            );
        }

        setTotalItems(computeddata.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            if (sorting.field == 'domaincount') {
                if (reversed == -1) {
                    computeddata = computeddata.sort((a, b) => b[sorting.field] - a[sorting.field]);
                }
                else{
                    computeddata = computeddata.sort((a, b) => a[sorting.field] - b[sorting.field])
                }
            }
            else{
                computeddata = computeddata.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
            
        }

        //Current Page slice
        return computeddata.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tabledata, currentPage, search, sorting]);

    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };

       const getMaxDatetime = (tabledatacsv) => {
        // const { tabledatacsv } = this.state;
        if (tabledatacsv.length === 0) return null;
    
        const maxDatetime = tabledatacsv.reduce((max, item) => {
          return new Date(item.dateseen) > new Date(max) ? item.dateseen : max;
        }, tabledatacsv[0].dateseen);    
        setmaxDatetime(new Date(maxDatetime).toString());
      }

      const removewhitelistdomain = () => {
        var querystringdomainname = queryString.parse(window.location.search).id == undefined
        ? ""
        : queryString.parse(window.location.search).id;
        if (querystringdomainname == "") {
            return;
        }
        showLoader();
        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            //url:`${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/getunauthorizedlist?companyid=&mlsname='
            url:`${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/removedomainfromwhitelist?filterdomain='+querystringdomainname+'&companyid=&mlsname='
          };
    
        axios(config)
            .then(function (resp) {                    
                var response = (resp.data);
                console.log(resp.data);
                if (response.Status == "200") {
                    if (response.Data != null && response.Data == "true") {
                        customAlertMessage('success','Domain removed from whitelist successfully.')
                        // settabledata(JSON.parse(response.Data));
                        // settabledatacsv(JSON.parse(response.Data));
                    }
                    else {                        
                        console.log('Failed to load data.');
                        customAlertMessage('error','Something went wrong, Try Again.')
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const downloadpdf= async ()=>{      
        var querystringdomainname = queryString.parse(window.location.search).id == undefined
        ? ""
        : queryString.parse(window.location.search).id;
        if (querystringdomainname == "") {
            customAlertMessage('error','No domain found.');
            return;
        }
        var fileName = new Date().toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) + "_Authorized_"+querystringdomainname.replace(".","(dot)");
        const apiUrl = 'https://uatapi.chocolatechips.ai/api/ArtificialIntelligence/getauthorizedpdfreport?filterdomain='+querystringdomainname +'&companyid=&mlsname=';

        try {
            showLoader();
            const response = await axios({
                url: apiUrl,
                method: 'GET',
                responseType: 'blob' // Set responseType to 'blob' for binary data
            });
    
            // Check if the response status code indicates success
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;

                // Trigger a click event to initiate the download
                link.click();

                // Clean up
                window.URL.revokeObjectURL(link.href);

                console.log('File downloaded successfully.');
                hideLoader();
            } else {
                console.error('Failed to download file. Server returned status code:', response.status);
                hideLoader();
            }
        } catch (error) {
            console.error('Error downloading file:', error);
            hideLoader();
        }
    }
    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}
            <div className="row">
                <div className="col-12">
                    <div className="title-card1">
                        <span className="float-start">
                        <h4><span>Authorized Domain:</span>&nbsp;<span>{queryString.parse(window.location.search).id}</span></h4>
                        <p>Last Update: <span id="lastupdatedtime">{new Date(maxDatetime).toLocaleString('en-US', { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'long' })}</span></p>
                        </span>
                    </div>
                </div>
            </div>
            
            <div className='row'>
                            <div className='col-12' style={{background:"#373737",padding:"10px"}}>
                                <span className='float-start'>
                                    <b>Total Authorized Matches: {totalItems > 0 ? totalItems : "0"}</b>
                                </span>
                                <span className='float-end'>                                    
                                    {/* <span className="tooltip1" tooltip="Remove from whitelist" flow="down" style={{float:"left"}} onClick={removewhitelistdomain} >
                                        <FontAwesomeIcon icon={faRemove} className='iconcircle-sherlock' style={{padding:"2px 5px"}}></FontAwesomeIcon>
                                    </span> */}
                                    {/* <FontAwesomeIcon icon={faRemove} className='iconcircle-sherlock' style={{padding:"2px 5px"}}></FontAwesomeIcon> */}
                               
                                    <span className="tooltip1" tooltip="Share in Email" flow="down" style={{float:"left"}}>
                                        <FontAwesomeIcon icon={faEnvelope} className='iconcircle-sherlock'  style={{padding:"4px 5px",fontSize:"12px"}} data-bs-toggle="modal" data-bs-target="#shareemail"></FontAwesomeIcon>
                                    </span>
                                    <span className="tooltip1" tooltip="Download Domain Report in PDF format" flow="down" style={{float:"left"}} onClick={downloadpdf}>
                                        <FontAwesomeIcon icon={faFilePdf} className='iconcircle-sherlock'  style={{padding:"4px 5px",fontSize:"12px"}}></FontAwesomeIcon>
                                    </span>
                                    <span className="tooltip1" tooltip="Download Domain Report in CSV format" flow="left" style={{float:"left"}}>
                                    <CsvDownloader datas={tabledatacsv} columns={columns} filename={new Date().toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) + "_Authorized_"+ (queryString.parse(window.location.search).id).replace(".","(dot)")}>
                                        <FontAwesomeIcon icon={faArrowDown} className='iconcircle-sherlock' style={{padding:"2px 5px"}}></FontAwesomeIcon>
                                    </CsvDownloader>
                                    </span>
                                    
                                </span>
                            </div>
                        </div>

            <div className="row w-100" style={{marginTop:"15px"}}>
                <div className="col mb-3 col-12 text-center" style={{paddingRight:"0"}}>
                    <div className="row">                    
                        <div className="col-md-12 d-flex flex-row-reverse" style={{paddingRight:"0"}}>
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                   <div className="row">
                   <div className="rightside-bar pr5 nominheight" style={{minHeight:'unset'}}>
                    <table className="table table-hover common-table sherlocktable">
                        <Header
                            headers={headers}
                            defaultsort="lastupdated" 
                            defaultsortorder="desc" 
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
<tbody>
{tableData.length > 0 ? tableData.map((d) => (
                                <tr style={{textAlign:'left'}}>
									{/* <td className="center"><input type="checkbox"/></td> */}
									{/* <td style={{cursor:"pointer"}}><a href='javascript:;' onClick={this.openemaildetails} data-to={"/email?id="+d.id}>{d.title}</a> </td> */}
									<td style={{cursor:"pointer"}}><a href={d.matchedurl} target="_blank">{d.matchedurl}</a></td>
									<td style={{cursor:"pointer"}}><a href={(d.imagesource).toString().split('|')[0]} target="_blank">{d.imagesource}</a></td>
                                    <td>{moment.utc(d.dateseen).local().format('MM-DD-YYYY h:mm A')}</td>
								</tr>

                            )) : <tr>
                                <td colSpan={3} className="text-center">No data found</td>
                                </tr>
                                }
</tbody>                        
                    </table>
                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 sherlocktable">
                            <center>
                            <Pagination
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </center>                                
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default Sherlockauthorizeddetailslist;