import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";
import React, { useRef,useImperativeHandle } from "react";

const RoomtypeOld = () => {
  const selectInputRef = useRef();
  const options = [
    { value: "-1", label: "Select" },
    { value: "bar", label:"Bar"},
    { value: "Back Yard", label: "Back Yard" },
    { value: "bathroom", label:"Bathroom/Ensuite"},
    { value: "bedroom", label:"Bedroom"},
    { value: "dining", label:"Dining"},
    { value: "foyer", label:"Foyer"},
    { value: "gameroom", label:"Game Room/Rumpus Room"},
    { value: "hobbyroom", label:"Hobby Room/Craft Room"},
    { value: "kidsroom", label:"Kids Room"},
    { value: "kitchen", label:"Kitchen"},
    { value: "laundry", label:"Laundry"},
    { value: "livingroom", label:"Living Room/Family Room/Lounge"},
    { value: "mediaroom", label:"Media Room"},
    { value: "nursery", label:"Nursery"},
    { value: "Outdoor Patio", label: "Outdoor Patio" },
    { value: "pantry", label:"Pantry"},
    { value: "singleroom", label:"Single Room Studio/Unit"},
    { value: "study", label:"Study"},
    { value: "sunroom", label:"Sunroom"}
    
  ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };
  const onClear = () => {
    selectInputRef.current.clearValue();
  };
  return (
    // <Select options={options} styles={customStyles} />
    <>
      <a href="javascript:;" id="roomtypeclear" onClick={onClear} style={{display:'none'}}>Clear</a>
    <Select ref={selectInputRef} defaultValue={options[0]} placeholder="Select" id="roomtype" options={options} maxMenuHeight={400}/>
    </>
  );
};

export default RoomtypeOld;