import React from 'react';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import { ElementsConsumer, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import * as axios from 'axios';

export const getAccessToken = () => Cookies.get('userInfo')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()

class CheckoutForm extends React.Component {

    componentDidMount(){
        this.getSetupIntentKey();
    }

    getSetupIntentKey = () =>{
        var self = this;
        var config = {
            method: 'get',
            "url": `${process.env.REACT_APP_API_URL}` + "/api/AIUserManager/GetSetUpIntentSecret"  ,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null && response.Data !="") {
                        document.getElementById('hfSetUpIntent').value = response.Data;
                    }
                    else {
                        self.customAlertMessage('error', "No setup intent created.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    handleSubmitNew = async event =>{
        event.preventDefault();
        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        var setupSecret = document.getElementById('hfSetUpIntent').value;
        if (setupSecret !="") {            
            this.showoverlay();
            const card = elements.getElement(CardNumberElement);
            const result = await stripe.handleCardSetup(
                setupSecret, 
                card
            );
            if (result.error) {
                this.hideoverlay();
                console.log(result.error.message);
                this.customAlertMessage('error', result.error.message);
                return;
            } else {      
                this.hideoverlay();      
                console.log(result.setupIntent);
                // create customer in ajax and navigate to dashboard
                this.createAccountSubscription(result.setupIntent.payment_method);
            }
        } 
        else {
            
        }        
    }

    handleSubmit = async event => {
        event.preventDefault();

        const { stripe, elements } = this.props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            console.log(result.error.message);
            this.customAlertMessage('failure', result.error.message);
            return;
        } else {            
            console.log(result.token.id);
            // create customer in ajax and navigate to dashboard
           this.createAccountSubscription(result.token.id);
        }
    };

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }
    myFunc = () => {

    }
    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    createAccountSubscription = (tokenId) => {
        var userI = getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var uname = parsedRes.FirstName;
        var userId = parsedRes.UserId;
        var emailId = parsedRes.EmailId
        var phone = parsedRes.phonenumber;
        var planType = parsedRes.PlanType;
        var planLevel = parsedRes.PlanLevel;
        var planTypeSel = parsedRes.SelectedPlanType;
        var pLevelSel = parsedRes.SelectedPlanLevel;  

        var self = this;
        this.showoverlay(); //adasdf

        //string firstName,string lastName, string EmailId, string Passw,string phoneNumber
        //var data = JSON.stringify({
        //    "firstName": PwdFN,
        //    "lastName": PwdLN,
        //    "EmailId": uname,
        //    "Passw": pass,
        //    "phoneNumber": PwdMobile
        //}); ,string PlanType,string PlanLevel)

        //CreateUserSubscription(string Name, string EmailAddress, string Phone, string Token,string UserId
        //string SelPlanType, string SelPlanLevel,string Trial
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CreateUserSubscription?Name=" + uname + "&EmailAddress=" + emailId + "&Phone=" + phone + "&Token="
                + tokenId + "&UserId=" + userId + "&PlanType=" + planType + "&PlanLevel=" + planLevel +
                "&SelPlanType=" + planTypeSel + "&SelPlanLevel=" + pLevelSel + "&Trial=1"  ,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then(function (resp) {
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //Cookies.set('access_token', response.Data.userInfo.UserId, { expires: inOneHour }) 
                        console.log(response.Data);
                        Cookies.set('takepayment', false);
                        var userI1 = getAccessToken();
                        var decodedUser1 = decodeURIComponent(userI1);
                        //console.log(decodedUser1);
                        var parsedRes1 = JSON.parse(decodedUser1);
                        parsedRes1.PaymentCustomerId = response.Data;
                        const serializedState1 = JSON.stringify(parsedRes1);
                        Cookies.set('userInfo', serializedState1); 
                        window.location.href = window.location.origin;
                    }
                    else {
                        self.customAlertMessage('error', "Card authorization failed. "+response.Message);
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message);
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    render() {
        return (
            <div className="form-container1">
                <input type='hidden' id='hfSetUpIntent'></input>
                <div className="product-info loginstyle">
                    <h2>Try it free for 2 weeks</h2>
                    <h3 className="product-price">Cancel your subscription anytime.</h3>
                    {/* <h5 style={{margin:"15px 0",lineHeight:"25px"}}>Everything in Starter plan + powerful tools for writing full length content (like blog posts) <br/>with added control & flexibility.</h5> */}
                </div>
                <form onSubmit={this.handleSubmitNew}>
                    <CardSection />
                    <button disabled={!this.props.stripe} className="btn-pay">
                        Authorize
                     </button>
                     <img src='https://cdn.brandfolder.io/KGT2DTA4/at/g65qkq94m43qc3c9fqnhh3m/Powered_by_Stripe_-_black.svg' className='stripe-logo'/>
                </form>
            </div>
        );
    }
}

export default function InjectedCheckoutForm() {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}