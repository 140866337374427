import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";

const DDLmood = () => {
  const options = [
    { value: "-1", label: "None" },
    { value: "1", label: "Calm" },
    { value: "2", label: "Carefree" },
    { value: "3", label: "Fun" },
    { value: "4", label: "Gloomy" },
    { value: "5", label: "Happy" },
    { value: "6", label: "Romantic" },
  ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  return (
    // <Select options={options} styles={customStyles} />
    <Select id="ddlmood" options={options} maxMenuHeight={200}/>
  );
};

export default DDLmood;