import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React, { useEffect, useState, useMemo } from "react";
 import {UserID,EulaValue,Features,Role} from "../User";
import * as axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import loader from "../img/sample.gif";
import $ from 'jquery';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';

const options = [
    'one', 'two', 'three'
  ];  

 const myArray = [];

class Superadminhome extends React.Component {
    
    componentDidMount(){
        // Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		// 	el.classList.remove('active');
		// });
		// document.getElementById('0').classList.add("active");
        // this.documentinit();
        // this.checkFeaturesEnable();
        this.loadCompanyList();
        //const [comments, setComments] = useState([]);
    }

    companySelection = (e) =>{
        //alert('inside company selection');
        var selCompany = e.value;
        document.getElementById("hfSelCompId").value = selCompany;
        this.loadCompanyFeatures(selCompany);
    }

    loadCompanyFeatures = (compId) =>{
        var self = this;
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetSuperadminFeatures?CompanyId="+compId,
            // "url": "https://localhost:44372/api/AIUserManager/GetSuperadminFeatures?CompanyId="+compId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                   console.log(response.Data);
                   var feature = response.Data;
                   var chkTextChat = document.getElementById('chkTextChat');
                   chkTextChat.checked = feature.TextChat;
                    var chkVoiceChat = document.getElementById('chkVoiceChat');
                    chkVoiceChat.checked = feature.VoiceChat;
                    var chkGoogleSTT = document.getElementById('chkGoogleSTT');
                    chkGoogleSTT.checked = feature.GoogleSTT;
                    var chkGrammerly = document.getElementById('chkGrammerly');
                    chkGrammerly.checked = feature.Grammerly;
                    var chkPlagiarism = document.getElementById('chkPlagiarism');
                    chkPlagiarism.checked = feature.Plagiarism;
                    //obj.Zapier = obj.SurfSEO = obj.TextToImage = obj.ImageTransform = obj.Homedocor = true;
                    var chkZapier = document.getElementById('chkZapier');
                    chkZapier.checked = feature.Zapier;
                    var chkSurfSeo = document.getElementById('chkSurfSeo');
                    chkSurfSeo.checked = feature.SurfSEO;
                    var chkTextToImage = document.getElementById('chkTextToImage');
                    chkTextToImage.checked = feature.TextToImage;
                    var chkImageTransf = document.getElementById('chkImageTransf');
                    chkImageTransf.checked = feature.ImageTransform;
                    var chkHomedecor = document.getElementById('chkHomedecor');
                    chkHomedecor.checked = feature.Homedocor;
                    var chkfunsection = document.getElementById('chkfunsection');
                    chkfunsection.checked = feature.Funsection;
                    var chkaeloplus = document.getElementById('chkaeloplus');
                    chkaeloplus.checked = feature.Aeloplus;
                    var chkPropertyDesc = document.getElementById('chkPropertyDesc');
                    chkPropertyDesc.checked = feature.PropertyDescriptionGen;
                    var chkMarketReport = document.getElementById('chkMarketReport');
                    chkMarketReport.checked=feature.MarketReport;
                    var chklab = document.getElementById('chklab');
                    chklab.checked=feature.Labsection;
                    var chkautocomplete = document.getElementById('chkautocomplete');
                    chkautocomplete.checked=feature.Autocomplete; 
                    var chkpropertvideo = document.getElementById('chkpropertvideo');
                    chkpropertvideo.checked=feature.Propertyvideo; 
                    var chklistingdescadvance = document.getElementById('chklistingdescadv');
                    chklistingdescadvance.checked=feature.ListingDescriptionAdvanced; 
                    var chkphotocompliance = document.getElementById('chkphotocompliance');
                    chkphotocompliance.checked=feature.PhotoCompliance; 
                    var chkimgdescription = document.getElementById('chkimgdescription');
                    chkimgdescription.checked=feature.ImageDescription; 
                    var chkimgsearch = document.getElementById('chkimgsearch');
                    chkimgsearch.checked=feature.ImageSearch; 
                    //chkDashboard chkSerlockAI chkLiveTrack chkActivity
                    var chkDashboard = document.getElementById('chkDashboard');
                    chkDashboard.checked=feature.Dashboard; 
                    var chkSerlockAI = document.getElementById('chkSerlockAI');
                    chkSerlockAI.checked=feature.SherlockAI; 
                    var chkLiveTrack = document.getElementById('chkLiveTrack');
                    chkLiveTrack.checked=feature.LiveTrack; 
                    var chkActivity = document.getElementById('chkActivity');
                    chkActivity.checked=feature.Activity; 
                    var chkAIVideoEditor = document.getElementById('chkAIVideoEditor');
                    chkAIVideoEditor.checked = feature.Aivideo;
                    
                    document.getElementById('divFeatures').style.display = "block";                  
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
    }

    loadCompanyList = () =>{
        var self = this;
        self.showoverlay();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAICompanyList",
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                   //console.log(response.Data);
                   var compArray = response.Data;
                  // var myArray = [];
                //    var myEleone = { value: "0", label: "Select" }
                //    myArray[0] = myEleone;
                   if(compArray.length > 0) {
                        for (var i = 0; i < compArray.length; i++) 
                        { 
                            var itemData = compArray[i];
                            var myEle = { value: itemData.CompanyId, label: itemData.EmailId }
                            myArray[i] = myEle;
                        }
                    }
                    //setComments(myArray);
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.customAlertMessage('error',error);
                self.hideoverlay();
            });
    }    


    customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,//'success',
            title: message// 'Signed in successfully'
        })
    }

    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }


    updateCompanyFeatures = () =>{
        var self = this;

        var chkTextChat = document.getElementById('chkTextChat');
        var textChat = chkTextChat.checked ? "1":"0" ;
         var chkVoiceChat = document.getElementById('chkVoiceChat');
         var voiceChat = chkVoiceChat.checked ? "1":"0" ;
         var chkGoogleSTT = document.getElementById('chkGoogleSTT');
         var googleStt = chkGoogleSTT.checked ? "1":"0" ;
         var chkGrammerly = document.getElementById('chkGrammerly');
         var grammerly = chkGrammerly.checked ? "1":"0" ;
         var chkPlagiarism = document.getElementById('chkPlagiarism');
         var plagiarism = chkPlagiarism.checked ? "1":"0" ;
         var compId = document.getElementById("hfSelCompId").value;
         var chkZapier = document.getElementById('chkZapier');
         var zapier = chkZapier.checked  ? "1":"0" ;
         var chkSurfSeo = document.getElementById('chkSurfSeo');
         var surfseo = chkSurfSeo.checked ? "1":"0" ;
         var chkTextToImage = document.getElementById('chkTextToImage');
         var textToImg = chkTextToImage.checked  ? "1":"0" ;
         var chkImageTransf = document.getElementById('chkImageTransf');
         var imgTransform = chkImageTransf.checked  ? "1":"0" ;
         var chkHomedecor = document.getElementById('chkHomedecor');
         var homeDecor = chkHomedecor.checked  ? "1":"0" ;
         var chkfunsection = document.getElementById('chkfunsection');
         var funsection = chkfunsection.checked  ? "1":"0" ;
         var chkaeloplus = document.getElementById('chkaeloplus');
         var aeloplus = chkaeloplus.checked  ? "1":"0" ;
         var chkPropertyDesc = document.getElementById('chkPropertyDesc');
         var propertyDesc = chkPropertyDesc.checked ? "1" : "0";
         var chkMarketReport = document.getElementById('chkMarketReport');
         var marketR = chkMarketReport.checked ? "1" : "0";
         var chklab = document.getElementById('chklab');
         var chklab = chklab.checked ? "1":"0";
         var chkautocomplete = document.getElementById('chkautocomplete');
         var chkautocomplete = chkautocomplete.checked ? "1":"0";
         var chkpropertvideo = document.getElementById('chkpropertvideo');
         var propertvideo = chkpropertvideo.checked ? "1":"0";
         var chklistingdescadvance = document.getElementById('chklistingdescadv');
         var chklistingdescadvanced = chklistingdescadvance.checked ? "1" : "0"; 
         var chkphotocompliance = document.getElementById('chkphotocompliance');
         var chkphotocompliancevalue = chkphotocompliance.checked ? "1" : "0";
         var chkimgdescription = document.getElementById('chkimgdescription');
         var chkimgdescriptionvalue = chkimgdescription.checked ? "1" : "0";
         var chkimgsearch = document.getElementById('chkimgsearch');
         var chkimgsearchvalue = chkimgsearch.checked ? "1" : "0";
         //chkDashboard chkSerlockAI chkLiveTrack chkActivity
         var chkDashboard = document.getElementById('chkDashboard');
         var chkDashboardvalue = chkDashboard.checked ? "1" : "0";
         var chkSerlockAI = document.getElementById('chkSerlockAI');
         var chkSerlockAIvalue = chkSerlockAI.checked ? "1" : "0";
         var chkLiveTrack = document.getElementById('chkLiveTrack');
         var chkLiveTrackvalue = chkLiveTrack.checked ? "1" : "0";
         var chkActivity = document.getElementById('chkActivity');
         var chkActivityvalue = chkActivity.checked ? "1" : "0";
         var chkAIVideoEditor = document.getElementById('chkAIVideoEditor');
         var chkAIVideoEditorvalue = chkAIVideoEditor.checked?"1":"0";
         
         //alert('compId' + compId);
        //CreateTeam(string TName,string UserId)
        // string Zapier, string Surfseo, string TextToImage, string ImageTransf, string Homedecor
        self.showoverlay();
        var config = {
            method: 'post',
            // "url": "https://localhost:44372//api/AIUserManager/UpdateFeaturesList?CompanyId="+compId+"&TextChat="+textChat 
            // +"&VoiceChat="+voiceChat+"&GoogleStt="+googleStt+"&Grammerly="+grammerly+"&Plagiarism="+plagiarism
            // +"&Zapier="+zapier+"&Surfseo="+surfseo+"&TextToImage="+textToImg+"&ImageTransf="+imgTransform+"&Homedecor="+homeDecor
            // +"&funsection="+funsection+"&aeloplus="+aeloplus+"&PropertyDesc="+propertyDesc+"&MarketReport="+marketR+"&labsection="+chklab
            // +"&autocomplete="+chkautocomplete+"&propertyvideo="+propertvideo+"&listingdescriptionadvanced="+chklistingdescadvanced+
            // "&photocompliance="+chkphotocompliancevalue+"&imagedescription="+chkimgdescriptionvalue+"&imagesearch="+chkimgdescriptionvalue,
           "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateFeaturesList?CompanyId="+compId+"&TextChat="+textChat 
            +"&VoiceChat="+voiceChat+"&GoogleStt="+googleStt+"&Grammerly="+grammerly+"&Plagiarism="+plagiarism
            +"&Zapier="+zapier+"&Surfseo="+surfseo+"&TextToImage="+textToImg+"&ImageTransf="+imgTransform+"&Homedecor="+homeDecor
            +"&funsection="+funsection+"&aeloplus="+aeloplus+"&PropertyDesc="+propertyDesc+"&MarketReport="+marketR+"&labsection="+chklab
            +"&autocomplete="+chkautocomplete+"&propertyvideo="+propertvideo+"&listingdescriptionadvanced="+chklistingdescadvanced+
            "&photocompliance="+chkphotocompliancevalue+"&imagedescription="+chkimgdescriptionvalue+"&imagesearch="+chkimgdescriptionvalue +
            "&Dashboard="+chkDashboardvalue+"&SherlockAI="+chkSerlockAIvalue+"&Livetrack="+chkLiveTrackvalue+"&Activity="+chkActivityvalue+"&Aivideo="+chkAIVideoEditorvalue,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    self.customAlertMessage('success', "Updated successfully");
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });
      }
      logoutSuperadmin = () =>{
        Cookies.remove("userInfo");
        Cookies.remove("refresh_token");
        window.location.href = window.location.origin;
      }

    render(){
return (
    <>
        <div style={{padding:"20px"}} className="main_content_iner overly_inner">
            <div className="container-fluid p-0 ">
                <div className="row">

                    <div id="overlay">
                        <div id="loading">
                            <h2 id="loaderheading">Loading...</h2>
                            <img src={loader} />
                        </div>
                    </div> 
                    
                    <div className="col-12">
                        <div className="title-card">
                            <h4>
                            <FontAwesomeIcon icon={faAnglesRight} />chocolatechips.ai</h4>
                        </div>
                    </div>

                    <div className="col-12 adminborder">
                        <div className='row'>
                            <div className='col-3'>
                                <h4>Superadmin Features</h4>
                                
                            </div>
                            <div className='col-3'>
                                <Link to="/createcompany">Create sherlock Company</Link>
                            </div>
                            <div className='col-3'>
                                    <Link to="/extendFreeTrial">Extend Free Trial</Link>
                                </div>
                            <div className='col-3'>
                                <a href="javascript:;" onClick={this.logoutSuperadmin} style={{float:"right"}}>Log Out </a>   
                            </div>
                        </div>                        
                    </div>

                    <div className="col-12">
                        <div className='row'>
                            <div className='col-2'>
                                <label style={{float:"right",paddingTop:"5px",fontSize:"20px"}}>Select account:</label> 
                            </div>
                            <div className='col-3'>
                                <Dropdown id="ddlCompanyList" options={myArray} onChange={this.companySelection} placeholder="Select an account" />
                            </div>
                        </div>
                    </div>

                    <div id='divFeatures' style={{display:"none",padding:"10px"}} className='col-12'>
                        <div className='row'>                        
                        <div className='col-5'>
                            <center><label style={{color:"#fff",fontSize:"20px"}}><b><u>Features</u></b></label></center>
                        </div>                            
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Dashboard</label></div>
                            <div className='col-3'><input id='chkDashboard' type='checkbox'></input></div>
                           
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Text Chat</label></div>
                            <div className='col-3'><input id='chkTextChat' type='checkbox'></input></div>
                           
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'> <label>Voice Chat</label></div>
                            <div className='col-3'><input id='chkVoiceChat' type='checkbox'></input> </div>
                        
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'> <label>Google STT</label></div>
                            <div className='col-3'><input id='chkGoogleSTT' type='checkbox'></input>  </div>
                       
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'> <label>Grammerly</label></div>
                            <div className='col-3'><input id='chkGrammerly' type='checkbox'></input></div>
                         
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Plagiarism</label></div>
                            <div className='col-3'><input id='chkPlagiarism' type='checkbox'></input> </div>
                         
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Zapier</label></div>
                            <div className='col-3'><input id='chkZapier' type='checkbox'></input> </div>
                         
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Surfseo</label></div>
                            <div className='col-3'><input id='chkSurfSeo' type='checkbox'></input></div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Text to Image</label></div>
                            <div className='col-3'><input id='chkTextToImage' type='checkbox'></input></div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'> <label>Image Transformation</label></div>
                            <div className='col-3'><input id='chkImageTransf' type='checkbox'></input> </div>
                        
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Aelo</label></div>
                            <div className='col-3'><input id='chkHomedecor' type='checkbox'></input></div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Fun Section</label></div>
                            <div className='col-3'><input id='chkfunsection' type='checkbox'></input></div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Aelo Plus</label></div>
                            <div className='col-3'><input id='chkaeloplus' type='checkbox'></input></div>
                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Property Description</label></div>
                            <div className='col-3'><input id='chkPropertyDesc' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Market Report</label></div>
                            <div className='col-3'><input id='chkMarketReport' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Lab Section</label></div>
                            <div className='col-3'><input id='chklab' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Autocomplete</label></div>
                            <div className='col-3'><input id='chkautocomplete' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Property Video</label></div>
                            <div className='col-3'><input id='chkpropertvideo' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Listing Description Generator (Advanced)</label></div>
                            <div className='col-3'><input id='chklistingdescadv' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Photo Compliance</label></div>
                            <div className='col-3'><input id='chkphotocompliance' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Image Description</label></div>
                            <div className='col-3'><input id='chkimgdescription' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Image Search</label></div>
                            <div className='col-3'><input id='chkimgsearch' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Sherlock AI</label></div>
                            <div className='col-3'><input id='chkSerlockAI' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Live Track</label></div>
                            <div className='col-3'><input id='chkLiveTrack' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>Activity</label></div>
                            <div className='col-3'><input id='chkActivity' type='checkbox'></input></div>                          
                        </div>
                        <div className='row'>
                            <div className='col-1'></div>
                            <div className='col-2'><label>AI Video Editor</label></div>
                            <div className='col-3'><input id='chkAIVideoEditor' type='checkbox'></input></div>                          
                        </div>
                        <div style={{padding:"20px"}} className='row'>
                        <div className='col-1'></div>
                            <div className='col-2'>
                            <input type="hidden" id="hfSelCompId"></input>
                          <center>  <button type="button" onClick={this.updateCompanyFeatures} className="btn btn-primary">Update</button></center>
                            </div>
                        </div>
                    </div>                   

                </div>
            </div>
        </div>
      </>
)
}
};
export default Superadminhome;