import Cookies from 'js-cookie'
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import logo from "./img/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCrosshairs, faEye, faRemove, faRotate, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './css/login.css';
import msftLogo from "./img/msftssologolight.svg";
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

export const getAccessToken = () => Cookies.get('userInfo');
export const getRefreshToken = () => Cookies.get('refresh_token');
export const isAuthenticated = () => !!getAccessToken();
export const checkPayInfo = () => Cookies.get('takepayment');

export const msalConfig = {
    auth: {
        clientId: "66d843de-844f-47ae-a700-34ab39af641b",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: `${process.env.REACT_APP_MS_Redirecturi}`,  
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        //alert(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log('inside default');
                        console.log(message);
                        return;
                }	
            }	
        }	
    }
};

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalInstance = new PublicClientApplication(msalConfig);

const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      //alert('inside enter');
      document.getElementById('hrefLogIn').click();
    }
    else{
        //alert('else enter');
    }
}


class Login extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showpassword:true
        }
    }

    componentDidMount(){
        document.getElementById('toggler').addEventListener('click', this.showHidePassword);  
        try {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var crecvied = url.searchParams.get("register");
            var superAdminRec = url.searchParams.get("superadmin");
            if (crecvied != null && crecvied != undefined && crecvied == "Y") {
                this.changeScreen(1); // signup
            } 
            else if (superAdminRec != null && superAdminRec != undefined && superAdminRec == "Y") {
                this.changeScreen(3); // signup
            }
            else {
                this.changeScreen(2); // by default login
            }
        } catch (error) {
            this.changeScreen(2); // by default login
        }         
    };

    //Miami start
    initiateMSFTLogin = () =>{
        msalInstance.loginPopup({
            redirectUri: `${process.env.REACT_APP_MS_Redirecturi}`,
        }).then((tokenResponse) => {
            //alert('inside handle promise');
            //console.log(tokenResponse);
            // Check if the tokenResponse is null
            // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
            // If the tokenResponse === null, you are not coming back from an auth redirect.
            if (tokenResponse !== null) {
                this.updateUserDataToServerMSFT(tokenResponse.idToken,'',tokenResponse.account.username);
            } else {
                this.customAlertMessage('error', 'Authorization failed.');
            }
        }).catch((error) => {
            // handle error, either in the library or coming back from the server
            console.log(error);
            this.customAlertMessage('error', 'Authorization failed.');
        })
        ;
        //this.callBackMSFT();
    }

    updateUserDataToServerMSFT = (resData,newName,newEmail) =>{

        if (resData != "") {
            this.showoverlay();
            var tokenId ="";
            var accountType="2";
            var thirdToken = "";
            var uname = "";
            var pass = "";
            var PwdFN = ""
            var PwdLN = "";
            var PwdMobile = ""
            var PwdCompanyName = "";

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` + "/api/AIUserManager/CreateUserOAuthMicrosoft?accountType=2&CompanyName=" + PwdCompanyName 
                    + "&CompId=0"+"&JwtToken="+resData+"&Name="+newName+"&Email="+newEmail,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios(config)
            .then((resp) => {
                var response = (resp.data);
                if(response !=null && response.Status == "200"){
                    var responseData = response.Data;
                    Cookies.set('access_token', response.Data.UserId);
                    const serializedState = JSON.stringify(responseData);
                    //var a = localStorage.setItem('userInfo', serializedState);
                    Cookies.set('userInfo', serializedState); 
                    Cookies.set('logintime', new Date().getTime());
                    if(response.Data.PaymentCustomerId != "")   {
                        Cookies.set('takepayment', false);
                    }
                    else{
                        console.log('SubscriptionState ' + response.Data.SubscriptionState);
                        //alert('SubscriptionState ' + response.Data.SubscriptionState);
                        if (response.Data.SubscriptionState > 0) {
                            Cookies.set('takepayment', false);
                        }
                        else{
                            Cookies.set('takepayment', true);
                        }                            
                    }
                    var upgLink = this.checkUpgradePlan();
                    var aivLink = this.checkAIVideo();
                    window.location.href = window.location.origin + upgLink + aivLink;
                }
                else
                {
                    this.customAlertMessage('error', 'Something went wrong, Try Again.');
                }
                this.hideoverlay();
            })
            .catch((err) => {
                this.hideoverlay();
                 console.log(err);
                 this.customAlertMessage('error', 'Something went wrong, Try Again.');
             });
        }
    }

    showHidePassword = () => {
        var password = document.getElementById('PwdEmail');
        var toggler = document.getElementById('toggler');  
        if (password.type == 'password') {
            this.setState(({showpassword: false}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:false});
            password.setAttribute('type', 'text');
      
        } else {
            this.setState(({showpassword: true}), () => { console.log(this.state.showpassword)});
            //this.setState({showpassword:true});
            password.setAttribute('type', 'password');
      
        }
      
    }

    callonSuccess = (res) =>{
        console.log(res);
        this.updateUserDataToServer(res.credential);
    }

    callonError=(error) =>{
        console.log(error);
    }
    
    updateUserDataToServer = (resData) =>{
    
        if (resData != "") {
            this.showoverlay();
            var tokenId ="";
            var accountType="1";
            var thirdToken = "";
            var uname = "";
            var pass = "";
            var PwdFN = ""
            var PwdLN = "";
            var PwdMobile = ""
            var PwdCompanyName = "";
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` + "/api/AIUserManager/CreateUserOAuth?accountType=2&CompanyName=" + PwdCompanyName 
                    + "&CompId=0"+"&JwtToken="+resData,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
    
            axios(config)
                .then((resp) => {
                    var response = (resp.data);
                    if(response !=null && response.Status == "200"){
                        var responseData = response.Data;
                        Cookies.set('access_token', response.Data.UserId);
                        const serializedState = JSON.stringify(responseData);
                        //var a = localStorage.setItem('userInfo', serializedState);
                        Cookies.set('userInfo', serializedState); 
                        Cookies.set('logintime', new Date().getTime());
                        if(response.Data.PaymentCustomerId != "")   {
                            Cookies.set('takepayment', false);
                        }
                        else{
                            console.log('SubscriptionState ' + response.Data.SubscriptionState);
                            //alert('SubscriptionState ' + response.Data.SubscriptionState);
                            if (response.Data.SubscriptionState > 0) {
                                Cookies.set('takepayment', false);
                            }
                            else{
                                Cookies.set('takepayment', true);
                            }                            
                        }
                        var upgLink = this.checkUpgradePlan();
                        window.location.href = window.location.origin + upgLink;
                    }
                    else
                    {
                        this.customAlertMessage('error', 'Something went wrong, Try Again.');
                    }
                    this.hideoverlay();
                })
                .catch((err) => {
                    this.hideoverlay();
                     console.log(err);
                     this.customAlertMessage('error', 'Something went wrong, Try Again.');
                 });
            }
    }    

    checkUpgradePlan = () =>{
        var appendUL ="";
        try {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var upgradeplan = url.searchParams.get("upgradeplan");
            console.log(upgradeplan);
            if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "Y") {
                //appendUL ="?upgradeplan=Y";   
                Cookies.set("NAVTOUPGRADE", "true");          
            }
        } catch (error) {
            
        }
        return appendUL;        
    }

    checkAIVideo = () =>{
        var appendUL ="";
        try {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var upgradeplan = url.searchParams.get("AIE");
            console.log(upgradeplan);
            if (upgradeplan != null && upgradeplan != undefined && upgradeplan == "1") {
                appendUL ="?AIE=1";   
                Cookies.set("NAVTOAIVIDEO", "true");          
            }
        } catch (error) {
            
        }
        return appendUL;        
    }

//CreateUserOAuth(string firstName, string lastName, string EmailId, string Passw, string phoneNumber,
// string accountType, string CompanyName, string CompId,string TokenId,string ThirdPartyType, 
//string ThirdPartyToken) https://api.chocolatechips.ai/api/AIUserManager/    

    customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
    }
    showoverlay = () => {
        document.getElementById('overlay').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlay = () => {
        document.getElementById('overlay').style.display = "none";
        $('body').removeClass("loader");
    }    

    changeScreen = (sType) =>{
        if (sType == 1) { //signup
            document.getElementById('divSignIn').style.display = "none";
            document.getElementById('divSignUp').style.display = "block";   
            document.getElementById('divsuperadminLogin').style.display = "none";             
        } 
        else if (sType == 3) { //signup
            document.getElementById('divSignIn').style.display = "none";
            document.getElementById('divSignUp').style.display = "none"; 
            document.getElementById('divsuperadminLogin').style.display = "block";           
        }
        else { //loginIn
            document.getElementById('divSignIn').style.display = "block";
            document.getElementById('divSignUp').style.display = "none"; 
            document.getElementById('divsuperadminLogin').style.display = "none"; 
        }
    }

    setaccesstoken = () => {
        var uname = document.getElementById('Email').value;
        var pass = document.getElementById('PwdEmail').value;
        if (uname == "" || pass == "") {
            this.customAlertMessage("error", "Please enter username and password.");
            return;
        }

        var self = this;
        this.showoverlay();

        //var data = JSON.stringify({
        //    "loginId": uname,
        //    "password": pass
        //});
        var FormData = require('form-data');
        // var fs = require('fs');
        var data = new FormData();
        data.append("loginId", uname);
        data.append("password", pass);

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/Login",
            //headers: {
            //    'Content-Type': 'application/json'
            //},
            data: data
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //tokens.access_token = "7fb17240-b02f-4e72-9816-edd1709b264e";
                        //tokens.refresh_token = "6ad81ff8-4539-4d53-8bc4-a6c2b37399e7";
                        //// you will have the exact same setters in your Login page/app too
                        //Cookies.set('access_token', response.Data.userInfo.UserId, { expires: inOneHour })
                        var responseData = response.Data;
                        Cookies.set('access_token', response.Data.UserId);
                        const serializedState = JSON.stringify(responseData);
                        //var a = localStorage.setItem('userInfo', serializedState);
                        Cookies.set('userInfo', serializedState); 
                        Cookies.set('logintime', new Date().getTime());
                        if(response.Data.PaymentCustomerId != "")   {
                            Cookies.set('takepayment', false);
                        }
                        else{
                            console.log('SubscriptionState ' + response.Data.SubscriptionState);
                            //alert('SubscriptionState ' + response.Data.SubscriptionState);
                            if (response.Data.SubscriptionState > 0) {
                                Cookies.set('takepayment', false);
                            }
                            else{
                                Cookies.set('takepayment', true);
                            } 
                        }
                        //console.log("A:", a);  
                        //self.customAlertMessage('info', "Account created Successfully.");
                        window.location.href = window.location.origin;
                    }
                    else {                        
                        self.customAlertMessage('error', "User not present.");
                    }
                }
                else {
                    self.customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                self.customAlertMessage('error', 'Something went wrong, Try Again.');
                self.hideoverlay();
            });

        //const expires = (60 * 60) * 1000
        //const inOneHour = new Date(new Date().getTime() + expires)
        //var tokens = {};
        //tokens.access_token = "7fb17240-b02f-4e72-9816-edd1709b264e";
        //tokens.refresh_token = "6ad81ff8-4539-4d53-8bc4-a6c2b37399e7";
        //// you will have the exact same setters in your Login page/app too
        //Cookies.set('access_token', tokens.access_token, { expires: inOneHour })
        //Cookies.set('refresh_token', tokens.refresh_token);        
        //window.location.href = window.location.origin;
    }

    render(){
    return(
    <div className="container-fluid">

        <div className="d-flex justify-content-center align-items-center app-height loginstyle">
            <div className="text-start w-24">

            <div className='show-login' style={{position:"relative"}}>
<center>
            <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div>  
            <img src={logo} className="login-logo" />
            {/* signin start */}
            <div id='divSignIn'>                            
                <h2>Welcome back!</h2>
                <center>
                <GoogleOAuthProvider clientId="541732705369-opjt766r4um81jm4n686igo4ano89lei.apps.googleusercontent.com"> 
                    <GoogleLogin text='signin_with'
                            onSuccess= {this.callonSuccess} 
                            onError={this.callonError} />
                </GoogleOAuthProvider>
                </center><br></br>                 
                <center>
                    <a href="javascript:;" className='msftButton' onClick={ () => this.initiateMSFTLogin()}> <img src={msftLogo}></img> </a>    
                </center><br></br>
                <h5>Don't have an account?&nbsp;
                    <a href="javascript:;" onClick={ () => this.changeScreen(1)}> <b style={{color:"#9333ea"}}><u>Sign up for free</u></b> </a>    
                </h5>
            </div>
            {/* signin start */}
            {/* login id display none start */}
            <div id="divsuperadminLogin" style={{display:"none"}}>
                <h4> sign in with your email</h4>
                
                <label>Email</label>
                <input id="Email" type="text" className="common-input" required />
                <label style={{marginTop:"15px"}}>Password</label>

                <div className='pwdcontainer'>
                    <input id="PwdEmail" type="password" className="common-input" onKeyDown={handleKeyDown} required />
                    { (this.state.showpassword) ?
                        <FontAwesomeIcon icon={faEye} id='toggler'/>
                    :
                        <FontAwesomeIcon icon={faEyeSlash} id='toggler'/>
                    }
                </div>   
                <a href='javascript:;' id="hrefLogIn" tabIndex={-1} onClick={this.setaccesstoken} className="common-btnnew">Log In</a> 
                <h5>       
                    <Link to="/forgotpassword">
                        Forgot Password?
                    </Link>
                </h5>                                
            </div> 
            {/* login id display none end */}
             {/* signup start */}
             <div id='divSignUp'>
                <h2>Get started for Free</h2>
                <center>
                <GoogleOAuthProvider clientId="541732705369-opjt766r4um81jm4n686igo4ano89lei.apps.googleusercontent.com"> 
                    <GoogleLogin text='signup_with' auto_select='false'
                            onSuccess= {this.callonSuccess} 
                            onError={this.callonError} />
                </GoogleOAuthProvider>
                </center>  <br></br>
                <center>
                    <a href="javascript:;" className='msftButton' onClick={ () => this.initiateMSFTLogin()}> <img src={msftLogo}></img> </a>    
                </center> <br></br>
                <h5>Already have an account? 
                    <a href="javascript:;" onClick={ () => this.changeScreen(2)}> <b style={{color:"9333ea"}}><u>Sign In</u></b> </a>    
                </h5>
                <label style={{fontSize:"12px",marginTop:"20px"}}>
                Click "Sign Up" to agree to chocolatechips.ai <a href='https://www.chocolatechips.ai/terms-of-use' target='_blank' style={{textDecoration:"none"}}><u>Terms of Use</u></a> and acknowledge that chocolatechips.ai's <a href='https://planetre.com/privacy-policy.html' target='_blank' style={{textDecoration:"none"}}><u>Privacy Policy</u></a> applies to you. 
                </label>
             </div>             
            <div className="col-sm-12 text-center mt-5">
                <small>Copyright © planetRE. All Rights Reserved.</small>
            </div>
            </center>
            </div>
            </div>
        </div>
    </div>
    )
            }
  };
  
  export default Login;