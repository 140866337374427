import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faChevronLeft,
  faMicrophone,
  faInfo,
  faInfoCircle,
  faRotateRight,
  faShield,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { json, Link } from "react-router-dom";
import React, { useState } from "react";
import AudioToText from "./AudioToText";
import $ from "jquery";
// import Emailstripo from "../email/stripoapp";
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import {
  duplicateresultcontent,
  duplicateresultparacontenttemplate,
  ChatGPTemailcontent,
  truidraggable,
} from "../email/templateBase";
import queryString from "query-string";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import {
  CompositeDecorator,
  ContentState,
  EditorState,
  SelectionState,
  Modifier,
  convertToRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
// import { Editor } from "react-draft-wysiwyg";
import "/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";
import Cookies from 'js-cookie';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg, TeamId, MasterId, SSOUser} from "../User";
import cleareditorbtn from "../img/broom.png";
import AudioToTextforEditor from "./AudioToTextforEditor";
import draftToHtml from 'draftjs-to-html';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import {SSE} from "sse.js";
import copy from 'copy-to-clipboard';

export const getAccessToken = () => Cookies.get('userInfo');

class QuillEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyworddisabled: false,
      tonedisabled: false,
      copySuccess: "",
      wordcount: 0,
      Tonecount: 0,
      generatedimages: [],
      emailguid: NIL_UUID,
      emailhtml: "",
      emailhtmlcount: 0,
      defaultcontenterased: false,
      editorState: "",
      lastEmailtext: "",
      editorRef: React.createRef(),
      previousrequeststring:"",
      previousrequeststringlength:0,
      currentrequeststring:"",
      currentrequeststringlength:0,
      initrequest:true,
      genrateanother:false,
      grammarlykey:(Cookies.get('g_key') != undefined ? Cookies.get('g_key') : ''),
      copyscapeavailable:false,
      plagiarismmodalbody:{ __html: '' },
      briefdescription:'',
      interruptedcontent:false
    };
    this.modules = {
        toolbar: [
          [{ 'header': [1,2,3,4,5, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
      };
   
    
      this.formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
      ];
      this.quillRef = null; // Quill instance
      this.reactQuillRef = null; // ReactQuill component
      this.cursors = null;
      this.ccaiCursorVisible = false;
      this.ccaihideCursorFlag = true;
      this.ccaiCursorIndex = null;
      this.ccaiCursorLength = 1000;
      this.insertAICursorVisible = true;
  }

checkcopyscape = () =>{
  const axios = require('axios');
  var self = this;
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Getcopyscapeavailable?userid=' + (Role == 3 ? MasterId : UserID)
  };

  axios.request(config)
  .then((response) => {
    if (response.data != null && response.data.Data !="") {
      self.setState({copyscapeavailable:response.data.Data});
      // self.state.copyscapeavailable = response.data.Data;
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

checkcounters = () => {
  const axios = require('axios');
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}` + '/api/AIUserManager/GetUserCounters?UserId='+(Role == 3 ? MasterId : UserID)+'&CounterType=UsedWordsCount',
    headers: { }
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    if (response.data != undefined) {
      console.log(response.data.Data);
      if ((response.data.Data != "" || response.data.Data == 0) && response.data.Status == "200") {
      var availblecounter = response.data.Data;
      var lengthcontroller = parseInt(this.getselectedcontentlength());
      if (availblecounter >= lengthcontroller) {
        this.generateaicontent();
      }
      else if (availblecounter < lengthcontroller) {
        var userI = getAccessToken();
        var decodedUser1 = decodeURIComponent(userI);
        var parsedRes1 = JSON.parse(decodedUser1); 
        var PlanType = parsedRes1.PlanType;  
        console.log('PlanType' +PlanType) ;
        console.log('availblecounter' + availblecounter);
        if (PlanType < 1) {
          if (availblecounter == -9999) {
            this.customAlertMessage('error', 'You have exhausted the usage limit for words.');
          }
          else{
            document.getElementById('psubtext').innerText = 'You trial resources have been exhausted. Please upgrade to a paid subscription.';
            // document.getElementById('You do not have sufficient tokens to complete this request');
            document.getElementById('hrefsubscription').click();
          }
        }        
        else{
            //Show popup to upgrade subscription
          document.getElementById('psubtext').innerText = 'You resources have been exhausted. Please upgrade to a higher subscription plan level.';
          // document.getElementById('You do not have sufficient tokens to complete this request');
          document.getElementById('hrefsubscription').click();
        }        
        return;
      }
      }
    }
  })
  .catch((error) => {
    console.log(error);
  });
}

proceedOnUpgrade = () =>{
  document.getElementById('btncancelTTI').click();
  if (SSOUser) {      
    document.getElementById('lnkSSOUpgradeTTI').click();
  }
  else{
    document.getElementById('lnkNormalUpgradeTTI').click();
  }
}

attachQuillRefs = () => {
  if (typeof this.reactQuillRef.getEditor !== 'function') return;
  this.quillRef = this.reactQuillRef.getEditor();
  this.cursors = this.quillRef.getModule('cursors');
  this.showInsertAICursor();
};

showccaiCursor = () => {
  if (this.cursors && !this.ccaiCursorVisible) {
      this.cursors.createCursor("cc", "cc", "#000000");
      var range = this.quillRef.getLength();
      let position = range ? range.index : 0;
      this.cursors.moveCursor("cc", {
          index: position,
          length: this.ccaiCursorLength
      });
      // var t = this.ccaihideCursorFlag;
      this.cursors.toggleFlag("cc", true);
      this.ccaiCursorVisible = true;
  }
}

hideccaiCursor = ()=> {
  if(this.cursors && this.ccaiCursorVisible)
  {
    this.cursors.toggleFlag("cc", false);
    this.cursors.removeCursor("cc");
    this.ccaiCursorVisible = false;
  }    
}

updateccaiCursor = (t) => {
    var e = 0;// arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
    this.ccaiCursorIndex = t;
    this.ccaiCursorLength = e;
    if(this.cursors && this.ccaiCursorVisible)    
    { 
      this.cursors.moveCursor("cc", {
        index: this.ccaiCursorIndex,
        length: this.ccaiCursorLength
    });
  }
}

showInsertAICursor = () => {
  if(this.cursors && !this.insertAICursorVisible)
  {
    this.cursors.createCursor("InsertAI", "InsertAI", "#B318FF");
    var range = this.quillRef.getLength();
    let position = range ? range.index : 0;
    this.cursors.moveCursor("InsertAI", {
      index: position,
      length: 0
    });
    this.insertAICursorVisible = true;
    this.cursors.toggleFlag("InsertAI", true);
  }
}
hideInsertAICursor = () => {
  if(this.cursors && this.insertAICursorVisible) {
    this.cursors.removeCursor("InsertAI");
    this.insertAICursorVisible = false;
    this.cursors.toggleFlag("InsertAI", false);
  }
}
updateInsertAICursor = (t)=> {
  var e = 0;// arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
  // this.insertAICursorIndex = t,
  // this.insertAICursorLength = e,
  this.ccaiCursorIndex = t;
  this.ccaiCursorLength = e;
  if(this.cursors && this.insertAICursorVisible)
  {
      //   this.cursors.moveCursor("InsertAI", {
      //     index: this.insertAICursorIndex,
      //     length: this.insertAICursorLength
      // });
      this.cursors.moveCursor("InsertAI", { index: this.ccaiCursorIndex,length: this.ccaiCursorLength});
      this.cursors.toggleFlag("InsertAI", true);
  } 
}

  componentDidMount() {
    this.checkcopyscape();
    this.jquerycode();
    this.attachQuillRefs();
    // setInterval(() => {
    //   this.checkandupdatecontent();      
    // }, 10000);
    const query = queryString.parse(window.location.search);
    const token = query.id;
    //console.log('Token set: '+ token);
    //console.log('before set: '+this.state.emailguid);
    if (token != undefined) {
      this.setState({ emailguid: token });
      this.loadtemplate(token);           
    } else {
      this.setState({ emailguid: uuidv4() });
      setTimeout(() => this.quillRef.setSelection(0, 0), 1);
    }
    // this.checkgrammarly();
    
    //console.log('After set: '+this.state.emailguid);
    //console.log(this.state.emailguid);
    console.log(Cookies.get('g_key'));
  }
  componentDidUpdate() {
    this.attachQuillRefs();
  }
  requestGetTemplateFinal = (method, url, data, callback) => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (req.readyState === 4 && req.status === 200) {
        callback(req.responseText);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.error(
          "Can not complete request. Please enter valid auth token"
        );
      }
    };
    req.open(method, url, true);
    if (method !== "GET") {
      req.setRequestHeader("content-type", "application/json");
      req.setRequestHeader(
        "Header",
        "ES-PLUGIN-AUTH: Bearer " + document.getElementById("hfAuthToken").value
      );
    }
    req.send(data);
  };

  loadDemoTemplate = (cssurl, htmlurl) => {
    var self = this;
    self.requestGetTemplateFinal("GET", htmlurl, null, function (html) {
      //Set html to the editor
      var el = document.createElement("html");
      el.innerHTML = html; // "<html><head><title>titleTest</title></head><body><a href='test0'>test01</a><a href='test1'>test02</a><a href='test2'>test03</a></body></html>";

      el.getElementsByTagName("body"); // Live NodeList of your anchor elements
      //self.appendhtmltoeditor(el.getElementsByTagName("body")[0].innerHTML);
      self.quillhtmlappend(el.getElementsByTagName("body")[0].innerHTML);      
      setTimeout(() => self.quillRef.setSelection(self.quillRef.getLength(), 0), 1);
      if (self.quillRef.getLength() > 1) {
        self.setState({initrequest:!(self.quillRef.getLength() > 1),
          previousrequeststring:self.quillRef.getText(),
          previousrequeststringlength:self.quillRef.getLength()});
      } 

      // self.requestGetTemplateFinal('GET', cssurl, null, function (css) {

      // });
    });
  };
  // const Emails = () => {
  loadtemplate = (token) => {
    var self = this;
    var axios = require("axios");
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/gettemplate?id=" +
        token,
      headers: {},
    };

    axios(config)
      .then(function (resp) {
        // console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data.length > 0) {
          var htmlurl = response.Data[0].htmlurl + "?v=" + uuidv4();
          var cssurl = response.Data[0].cssurl + "?v=" + uuidv4();
          var title = response.Data[0].title;
          var description = response.Data[0].description;
          var contentbrief = response.Data[0].contentbrief;
          var tone = response.Data[0].tone;
          var keyword = response.Data[0].keyword;
          var outlen = response.Data[0].outlen;          
          document.getElementById("templatetitle").value = title == "No title" ? "" : title;
          document.getElementById("templatedescription").value = description;
          document.getElementById("textareaoutput").value = contentbrief;
          document.getElementById("toneofvoice").value = tone;
          document.getElementById("keywordinput").value = keyword;
          self.setwordcount();
          self.getradiofromdb(outlen);
          self.loadDemoTemplate(cssurl, htmlurl);
          self.setState({briefdescription:contentbrief});
          if(title.trim() != "No title")
          {
            self.toggletemplatediv();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  storeeditorplaintext = () => {
    this.setState({
      lastEmailtext: this.quillRef.getText(),
    });
  };

  // geteditorquestion = () => {
  //   var blocks = this.state.editorState.getCurrentContent().getBlocksAsArray();
  //   var html = stateToHTML(this.state.editorState.getCurrentContent());
    
  //   for (var i = blocks.length - 1; i >= 0; i--) {
  //     var block = blocks[i];
  //     var txxxx = block.text;
  //     if (txxxx.trim() != "") {
  //       console.log(txxxx);
  //       break;
  //     }
  //   }
  //   return;
  //   blocks
  //     .slice()
  //     .reverse()
  //     .forEach((block) => {
  //       var txxxx = block.text;
  //       if (txxxx.trim() != "") {
  //         console.log(txxxx);
  //         return false;
  //       }
  //     });
  //   var oldtext = this.state.lastEmailtext;
  //   var currenttext = this.state.editorState
  //     .getCurrentContent()
  //     .getPlainText("\u0001");
  //   var question = currenttext.replace(oldtext, "");
  //   console.log(question);
  //   //return question;
  // };

  updateeditorstate = () => {
    const data = "";
    let { contentBlocks, entityMap } = htmlToDraft(data);
    let contentState = Modifier.replaceWithFragment(
      this.state.editorState.getCurrentContent(),
      this.state.editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    this.setState({ editorState: EditorState.createWithContent(contentState) });
  };

  appendhtmltoeditor = (gptresponse) => {
    const data = gptresponse.replace(/(<\/?)figure((?:\s+.*?)?>)/g, ''); // "<p>"+gptresponse+"</p>";
    let { contentBlocks, entityMap } = htmlToDraft(data);
    const currentContent = this.state.editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
   
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    let contentState = Modifier.replaceWithFragment(
      this.state.editorState.getCurrentContent(),
      selection,
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );
    var editorWithInsert = EditorState.push(
      this.state.editorState,
      contentState,
      "insert-fragment"
    );
    this.setState({ editorState: EditorState.createWithContent(contentState) });
    const newEditorState = EditorState.moveFocusToEnd(
      editorWithInsert,
      contentState.getSelectionAfter()
    );
     EditorState.forceSelection(editorWithInsert,contentState.getSelectionAfter());
    
    
    //this.storeeditorplaintext();
    // this.setState({editorState:newEditorState })
  };
  appendtexttoeditor = (gptresponse) => {
    // get current editor state
    const currentContent = this.state.editorState.getCurrentContent();
    const data = gptresponse.replace(/(<\/?)figure((?:\s+.*?)?>)/g, '');
    // create new selection state where focus is at the end
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });

    //insert text at the selection created above
    const textWithInsert = Modifier.insertText(
      currentContent,
      selection,
      data,
      null
    );
    const editorWithInsert = EditorState.push(
      this.state.editorState,
      textWithInsert,
      "insert-characters"
    );

    //also focuses cursor at the end of the editor
    const newEditorState = EditorState.moveSelectionToEnd(
      editorWithInsert,
      textWithInsert.getSelectionAfter()
    );
    this.setState({ editorState: newEditorState });
    //    setEditorState(newEditorState);
  };

  onChangeeditor = (editorState) => {
    this.setState({
      editorState: editorState,
    });
    this.checkandupdatecontent();
  };
  jquerycode = () => {
    var self = this;
    Array.from(
      document.getElementById("sidebar_menu").querySelectorAll("li")
    ).forEach(function (el) {
      el.classList.remove("active");
    });
    document.getElementById("1").classList.add("active");    

    setTimeout(() => {
      document.getElementsByClassName('ql-editor')[0].classList.add('rightside-bar-editor');
    }, 100);
    
  };
  setwordcount = () => {
    var text = document.getElementById("keywordinput").value;
    if (text.trim() == "") {
      this.setState({ wordcount: 0 });
    } else {
      const words = text.trim().split(",");
      if (words.length > 50) {
        this.customAlertMessage("warning", "Keywords limit exceeded.");
        var trimmedwords = text.trim().split(",").slice(0, 3).join(",");
        document.getElementById("keywordinput").value = trimmedwords;
        this.setState({ wordcount: 3 });
      } else {
        this.setState({ wordcount: words.length });
      }
    }
  };

  togglekeywords() {
    this.setState({ keyworddisabled: !this.state.keyworddisabled });
  }

  toggletone() {
    this.setState({ tonedisabled: !this.state.tonedisabled });
  }

  updatetonecount = (e) => {
    this.setState({ Tonecount: e.currentTarget.value.length });
  };
  toggletemplatediv = () => {
    $("#templatesavediv").toggle();
    $("#btnsavetemplate").toggle();
    if ($("#templatetogglelink").text() == "Save As Template") {
      $("#templatetogglelink").text("Hide save as");
    } else {
      $("#templatetogglelink").text("Save As Template");
    }
  };
  customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };
  showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };
  hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };
  geteleValue = (objectid) => {
    var text = "";
    if (document.getElementById(objectid).value != "-1") {
      text =
        document.getElementById(objectid).options[
          document.getElementById(objectid).selectedIndex
        ].text + " , ";
    }
    return text;
  };
  gettexteleValue = (objectid, prefixtext) => {
    var text = "";
    if (document.getElementById(objectid).value.trim() != "") {
      text =
        prefixtext + ": " + document.getElementById(objectid).value + " , ";
    }
    return text;
  };
  generatetoneandkeywordprompt = (tone,keyword) => {
    var promptsupport = "";
    if(tone != "" && keyword != "")
    {
      promptsupport = 'Write in '+tone+' tone contains '+keyword+' keywords.';
    }
    else if (tone != "" && keyword == "")
    {
      promptsupport = 'Write in '+tone+' tone.';
    }
    else if (tone != "" && keyword == "")
    {
      promptsupport = 'Write using '+keyword+' keywords.';
    }
    return (promptsupport == "" ? "" : promptsupport + "\n");
}
  generateaicontent = () => {
    var self = this;
    var prompt = this.quillRef.getText();
    var txtarea = document.getElementById('textareaoutput').value;
    var prevbriefdescription = self.state.briefdescription;
    var contentchanged = false;
    if (prompt.trim() == "" && txtarea.trim() == "") {
      this.customAlertMessage("error", "Please enter text.");
      //document.getElementById("emailinputprompt").focus();
      return;
    }
    
    
    if (prompt.trim() == "") {
      self.quilltextappendforbrief(txtarea.trim());
      prompt = txtarea.trim();
      self.setState({briefdescription:txtarea.trim(),genrateanother:false});
    }
    else {
      var fulltextlength = this.quillRef.getText().trim().length;
      var range = this.quillRef.getSelection() == null ? this.quillRef.getText().length : this.quillRef.getSelection().index;
      if (txtarea.trim() != prevbriefdescription) {
        self.setState({briefdescription:txtarea.trim()});
        self.quilltextappendforbrief(txtarea.trim());
        if (range < fulltextlength) {
          prompt = this.quillRef.getText();
          prompt = prompt.substring(0,range).trim();
          prompt += txtarea.trim();
          self.setState({interruptedcontent:true});
        }
        else{
          prompt = this.quillRef.getText();
          self.setState({genrateanother:false});
        }       
      }
      else{
        
        if (range < fulltextlength) {
          prompt = this.quillRef.getText();
          prompt = prompt.substring(0,range).trim();
          self.setState({interruptedcontent:true});
        }   
        else{
          self.setState({genrateanother:false});
        }     
      }
    }

    
    var lengthcontroller = this.getselectedcontentlength();
    // var Supplement = this.gettexteleValue("textareaoutput", "Supplement");
    // var toneofvoice = this.gettexteleValue("toneofvoice", "Tone");
    // var keywords = this.gettexteleValue("keywordinput", "Keyword");
    // var prompttext = ("Topic: "+ prompt + " " + toneofvoice + keywords + Supplement);

    var tone = document.getElementById('toneofvoice').value.trim();
    var keys = document.getElementById('keywordinput').value.trim();
    var toneandkeywordprompt = this.generatetoneandkeywordprompt(tone,keys);
    var prompttext = toneandkeywordprompt +  prompt + (self.state.genrateanother ? "\nShow me another example.":"");
    self.showoverlay();
    if (!self.state.initrequest) {
      self.setState({initrequest:!(this.quillRef.getLength() > 1),
        previousrequeststring:this.quillRef.getText(),
        previousrequeststringlength:this.quillRef.getLength()},()=>{
          console.log('previousrequeststringlength',self.state.previousrequeststringlength)
        });
    }
    else{
      if (this.quillRef.getLength() > 1) {
          self.setState({
            initrequest:!(this.quillRef.getLength() > 1),
            previousrequeststring:this.quillRef.getText(),
            previousrequeststringlength:this.quillRef.getLength()
          });  
      }
      
    }
    // if (this.quillRef.getSelection() == null) 
    {
      setTimeout(() => this.quillRef.setSelection(this.quillRef.getLength(), 0), 1);
    }
    this.hideInsertAICursor();
    this.showccaiCursor();
    // debugger;
    if (this.quillRef.getSelection() != null) {
      this.updateccaiCursor(this.quillRef.getSelection().index);
    }
    else{
      this.updateccaiCursor(this.quillRef.getLength());
    }
    
    prompttext = prompttext.trim()
    // var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //         if(!format.test(prompttext.slice(-1)))
    //         {
    //           prompttext += ".";
    //         }
    var axios = require("axios");
    var data = JSON.stringify({
      // model: "text-davinci-003",
      prompt: prompttext,
      temperature: 0.98,
      max_tokens: parseInt(lengthcontroller),
      // top_p: 0.95,
      stream: true,
      n: 1
    });
    var url = "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT2/completions?api-version=2023-09-15-preview";// "https://chocolatechipsai.openai.azure.com/openai/deployments/realEstateGPT1/completions?api-version=2023-09-15-preview";

    let source = new SSE(url, {
      headers: {
        "Content-Type": "application/json",
        "api-key": `${process.env.REACT_APP_AZURE_OPENAI}`,
      },
      method: "POST",
      payload: (data),
    });

    source.addEventListener("message", (event) => {
      if (event.data != "[DONE]") {
        self.hideoverlay();
        var fulltextlength = this.quillRef.getText().trim().length;
        var range = this.quillRef.getSelection() == null ? this.quillRef.getText().length : this.quillRef.getSelection().index;
      
        if ((range < fulltextlength) == false) {
          const element = document.getElementsByClassName('ql-editor')[0];
          if (element) {
              element.scrollTop = element.scrollHeight;
          }
        }
        // else{
        //   const contentEditableDiv = document.getElementsByClassName('ql-editor')[0];
        //   var cursorScrollHeight = this.getCursorScrollHeight(contentEditableDiv)
        //   console.log("Cursor Scroll Height:", cursorScrollHeight);
        //   if (contentEditableDiv) {
        //     contentEditableDiv.scrollTop = cursorScrollHeight;
        // }
        // }
        let payload = JSON.parse(event.data);
        if (payload.choices != null && payload.choices.length > 0) {
          let text = payload.choices[0].text;
        self.quilltextappend(text);
        // this.updateccaiCursor(this.quillRef.getLength());
        if (this.quillRef.getSelection() != null) {
          this.updateccaiCursor(this.quillRef.getSelection().index);
        }
        else{
          this.updateccaiCursor(this.quillRef.getLength());
        }
        }        
        // if (text != "\n") {
        //   console.log("Text: " + text);
        // }
      } else {
        //console.log("end detected:" + JSON.stringify(e));
        document.getElementById("plagiarisminfo").style.display = "none";
        source.close();
        self.hideccaiCursor();       
        if (this.quillRef.getLength() === self.state.previousrequeststringlength) {
          self.setState({genrateanother:true});
          //self.generateaicontent();
          self.checkcounters();
          return;
        }
        else{
          self.setState({genrateanother:false});
        }
        self.setState({
          currentrequeststring:this.quillRef.getText(),
          currentrequeststringlength:this.quillRef.getLength()
        });
        try {
            self.checkandupdatecontent();
            } catch (error) {
            
            }
            try {
              var lengthcontroller = parseInt(this.getselectedcontentlength());
              var tone = document.getElementById('toneofvoice').value.trim();
              var keys = document.getElementById('keywordinput').value.trim();
              var toneandkeywordprompt = this.generatetoneandkeywordprompt(tone,keys);
              var txtarea = document.getElementById('textareaoutput').value;
              var prompttext = toneandkeywordprompt + txtarea.trim() + this.quillRef.getText();              
              var newcharacters = (this.quillRef.getLength() - self.state.previousrequeststringlength);   
              var tokenused = prompttext.match(/(\w+)/g).length; //Math.ceil(newcharacters / 4);          
              self.savegptresponse(parseInt(lengthcontroller).toString(),tokenused);
            } catch (error) {}
      }
    });

    source.addEventListener("readystatechange", (e) => {
      if (e.readyState >= 2) {
        // setIsLoading(false);
      }
    });
    source.addEventListener("error", (e) => {
        // self.customAlertMessage(
        //           "error",
        //           "Something went wrong, please try again."
        //         );
        self.hideoverlay();
      });
try {
    source.stream(); 
} catch (error) {
    // self.customAlertMessage(
    //     "error",
    //     "Something went wrong, please try again."
    //   );
self.hideoverlay();
}
    


    // axios(config)
    //   .then(function (resp) {
    //     //console.log(JSON.stringify(resp.data));
    //     var response = resp.data;
    //     console.log(response.choices[0].text);
       
    //     if (response.choices != null && response.choices.length > 0) {
    //       var datalist = response.choices[0].text.split('\n');
    //       var finalData="";
    //       for(var i=0;i<datalist.length;i++)
    //       {
    //           if(datalist[i]!="")
    //           {
    //             finalData = finalData+"<p style='background-color:palegreen;'>" + datalist[i] + "</p>";
    //           }
    //       }
    //       self.appendhtmltoeditor(finalData + "<p style='background-color:none;'>&nbsp</p>");
    //       try {
    //         this.checkandupdatecontent();
    //       } catch (error) {
            
    //       }
    //       try {
    //         self.savegptresponse(
    //           JSON.stringify(resp.data),
    //           response.usage.total_tokens
    //         );
    //       } catch (error) {}
    //     }
    //     self.hideoverlay();
    //   })
    //   .catch(function (error) {
    //     //console.log(error);
    //     self.hideoverlay();
    //     self.customAlertMessage(
    //       "error",
    //       "Something went wrong, please try again."
    //     );
    //   });
  };

  getCursorScrollHeight = (element) => {
    const range = window.getSelection().getRangeAt(0);
    
    // Create a temporary element at cursor position
    const tempElement = document.createElement('span');
    range.insertNode(tempElement);
    
    // Get the scroll height of the element
    const scrollHeight = tempElement.offsetTop;
    
    // Remove the temporary element
    tempElement.parentNode.removeChild(tempElement);
    
    return scrollHeight;
  }
//   generateaicontent = () => {
//     var self = this;
//     var prompt = "";
//     var blocks = this.state.editorState.getCurrentContent().getBlocksAsArray();
//     for (var i = blocks.length - 1; i >= 0; i--) {
//       var block = blocks[i];
//       var txxxx = block.text;
//       if (txxxx.trim() != "") {
//         prompt = txxxx.trim();
//         break;
//       }
//     }
//     var txtarea = document.getElementById('textareaoutput').value;
//     if (prompt.trim() == "" && txtarea.trim() == "") {
//       this.customAlertMessage("error", "Please enter text.");
//       //document.getElementById("emailinputprompt").focus();
//       return;
//     }
//     if (prompt.trim() == "") {
//       prompt = txtarea.trim();
//     }
//     // var prompt = document.getElementById('textareaoutput').value;
//     // if(prompt.trim() == ""){
//     // 	this.customAlertMessage("error","Please enter text.");
//     // 	document.getElementById('textareaoutput').focus();
//     // 	return;
//     // }
// var lengthcontroller = this.getselectedcontentlength();
//     var Supplement = this.gettexteleValue("textareaoutput", "Supplement");
//     var toneofvoice = this.gettexteleValue("toneofvoice", "Tone");
//     var keywords = this.gettexteleValue("keywordinput", "Keyword");
//     var prompttext = ("Topic: "+ prompt + " " + toneofvoice + keywords + Supplement + " "+ lengthcontroller);
//     self.showoverlay();
//     var axios = require("axios");
//     var data = JSON.stringify({
//       model: "text-davinci-003",
//       prompt: prompttext.trim(),
//       temperature: 1,
//       max_tokens: 1000,
//     });

//     var config = {
//       method: "post",
//       url: "https://api.openai.com/v1/completions",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization:
//           "Bearer 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",
//       },
//       data: data,
//     };

//     axios(config)
//       .then(function (resp) {
//         //console.log(JSON.stringify(resp.data));
//         var response = resp.data;
//         console.log(response.choices[0].text);
       
//         if (response.choices != null && response.choices.length > 0) {
//           var datalist = response.choices[0].text.split('\n');
//           var finalData="";
//           for(var i=0;i<datalist.length;i++)
//           {
//               if(datalist[i]!="")
//               {
//                 finalData = finalData+"<p>" + datalist[i] + "</p>";
//               }
//           }
//           self.appendhtmltoeditor("<p></p>"+finalData);
//           try {
//             self.savegptresponse(
//               JSON.stringify(resp.data),
//               response.usage.total_tokens
//             );
//           } catch (error) {}
//         }
//         self.hideoverlay();
//       })
//       .catch(function (error) {
//         //console.log(error);
//         self.hideoverlay();
//         self.customAlertMessage(
//           "error",
//           "Something went wrong, please try again."
//         );
//       });
//   };
  // 	generateaicontent = () => {

  //         var self = this;

  // 		var prompt = document.getElementById('emailinputprompt').value;
  // 		if(prompt.trim() == ""){
  // 			this.customAlertMessage("error","Please enter text.");
  // 			document.getElementById('emailinputprompt').focus();
  // 			return;
  // 		}
  //         // var prompt = document.getElementById('textareaoutput').value;
  // 		// if(prompt.trim() == ""){
  // 		// 	this.customAlertMessage("error","Please enter text.");
  // 		// 	document.getElementById('textareaoutput').focus();
  // 		// 	return;
  // 		// }

  // 		var Supplement = this.gettexteleValue('textareaoutput',"Supplement");
  // 		var toneofvoice = this.gettexteleValue('toneofvoice',"Tone");
  // 		 var keywords = this.gettexteleValue('keywordinput',"Keyword");
  //          var prompttext = prompt + " " + toneofvoice + keywords + Supplement;
  // 		 self.showoverlay();
  // 		var axios = require('axios');
  // 		var data = JSON.stringify({
  // 		"model": "text-davinci-003",
  // 		"prompt": prompttext,
  // 		"temperature": 1,
  // 		"max_tokens": 1000
  // 		});

  // 		var config = {
  // 		method: 'post',
  // 		url: 'https://api.openai.com/v1/completions',
  // 		headers: {
  // 			'Content-Type': 'application/json',
  // 			'Authorization': 'Bearer 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'
  // 		},
  // 		data : data
  // 		};

  // axios(config)
  // .then(function (resp) {
  //   //console.log(JSON.stringify(resp.data));
  //   var response = resp.data;
  //   if (response.choices != null && response.choices.length > 0) {
  // 	var htmlnew = truidraggable;
  // 	htmlnew = htmlnew.replace("[PHchatgptnewpara]", response.choices[0].text);
  // 	//if(self.state.defaultcontenterased)
  // 	{
  // 		document.getElementsByTagName('iframe')[0].contentWindow.document.querySelectorAll('.esd-container-frame  tbody.ui-droppable')[0].innerHTML += htmlnew;
  // 		//document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').innerText += response.choices[0].text;
  // 		// $('#chatGPTcontent .ui-droppable').append(htmlnew);
  // 		//document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText;
  // 	}
  // 	// else{
  // 	// 	document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').getElementsByClassName('ui-droppable')[0].innerHTML = htmlnew;
  // 	// 	//$('iframe.stripo-preview-frame').contentWindow.document.getElementById('chatGPTcontent').getElementsByClassName('ui-droppable')[0].innerHTML = htmlnew;
  // 	// 	// $('#chatGPTcontent .ui-droppable').innerHTML = htmlnew;
  // 	// 	self.setState({defaultcontenterased:true});
  // 	// }
  // 	try {
  // 		self.savegptresponse(JSON.stringify(resp.data),response.usage.total_tokens);
  // 	} catch (error) {

  // 	}
  //   }
  //   self.hideoverlay();
  // })
  // .catch(function (error) {
  //   //console.log(error);
  //   self.hideoverlay();
  //   self.customAlertMessage('error','Something went wrong, please try again.')
  // });

  // 	}

  Checkplagiarism = () => {
    try {
      if (this.quillRef.getText().trim() == "") {
        this.customAlertMessage(
          "warning",
          "cannot check plagiarism for empty content."
        );
        return;
      }

      // if(document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerText.trim() == ""){
      // 	this.customAlertMessage('warning',"cannot check plagiarism for empty content.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').textContent.trim() == ""){
      // 	this.customAlertMessage('warning',"cannot check plagiarism for empty content.");
      // 	return;
      // }

      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode == null){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.nodeName != "#text"){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
      // if(document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText == ""){
      // 	this.customAlertMessage('warning',"Please select the text to check plagiarism.");
      // 	return;
      // }
    } catch (error) {
      this.customAlertMessage("error", "Error occured, Please try again.");
      return;
    }

    var self = this;
    this.showoverlay();

    var axios = require("axios");
    var FormData = require("form-data");
    // var fs = require('fs');
    var data = new FormData();
    // data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.getSelection().anchorNode.wholeText);
    //data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph').textContent.trim());
    //data.append('postdata', document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerText.trim());
    data.append(
      "postdata",
      this.quillRef.getText().trim()
    );
    data.append("uid",(Role == 3 ? MasterId : UserID));
    data.append("companyid",Companyid);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/checkduplicatebyformdata",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (resp) {
        //console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Data != null) {
          if (response.Data.count != null && response.Data.count === 0) {
            self.customAlertMessage("success", "No plagiarism found");
            document.getElementById("plagiarisminfo").style.display = "none";
            // document.getElementById("plagiarismmodalbody").innerHTML = "";
            self.setState({plagiarismmodalbody:{ __html: '' }})
            // $(elem).find('.checkmark').show();
            // $(elem).find('.ban').hide();
            // $(elem).attr('tooltip', "No plagiarism found.");
            // $(elem).attr('onclick', "return false;");
          } else if (response.Message == "small content") {
            self.customAlertMessage("info", response.Data);
          } else {
            document.getElementById("plagiarisminfo").innerText =
              response.Data.count + " copies found";
            document.getElementById("plagiarisminfo").style.display =
              "inline-block";
            var htmlnew = duplicateresultcontent;
            htmlnew = htmlnew.replace("[PHCScount]", response.Data.count);
            htmlnew = htmlnew.replace(
              "[PHCSquerywords]",
              response.Data.querywords
            );
            var duplicateresultparagraphlist = "";
            for (var i = 0; i < response.Data.count; i++) {
              var result = response.Data.result[i];
              var duplicateresultparacontent =
                duplicateresultparacontenttemplate;
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCSurl]",
                result.url
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCSurltext]",
                result.url
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCStitle]",
                result.title
              );
              duplicateresultparacontent = duplicateresultparacontent.replace(
                "[PHCShtmlsnippet]",
                result.htmlsnippet
              );
              duplicateresultparagraphlist =
                duplicateresultparagraphlist + duplicateresultparacontent;
            }
            htmlnew = htmlnew.replace(
              "[PHCSparagraphlist]",
              duplicateresultparagraphlist
            );
            // document.getElementById("plagiarismmodalbody").innerHTML = htmlnew;
            self.setState({plagiarismmodalbody:{ __html: htmlnew }})
            self.customAlertMessage("error", "Plagiarism found");
          }
        } else {
          self.customAlertMessage("error", "Error occured, Please try again."); // 'success','failure','info'
        }
        self.hideoverlay();
      })
      .catch(function (error) {
        //console.log(error);
        self.customAlertMessage("error", "Something went wrong, Try Again.");
        self.hideoverlay();
      });
  };
  historygoback = () => {
    window.history.back();
  };
  checkandupdatecontent = () => {
    // if (this.state.editorState.getUndoStack().size <= 0) return;
    const oldcontent = this.state.emailhtml;
    const htmlcount = this.state.emailhtmlcount;
    // if(!document.getElementsByTagName('iframe').length > 0)
    // return;

    // const currentcontent = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatgptparagraph');
    // const currentcontent = document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent');
    // if(currentcontent == null)
    // return;

    // if(currentcontent.textContent.trim() == "Type your content here")
    // if(currentcontent.innerText.trim() == "Type your content here")
    // return;
    var contenthtml = this.quillRef.root.innerHTML;// stateToHTML(this.state.editorState.getCurrentContent());
    var currentcontentlength = contenthtml.length;
    // if(oldcontent != currentcontent.textContent.trim())
    if (currentcontentlength != htmlcount) {
      //document.getElementsByTagName('iframe')[0].contentWindow.document.getElementById('chatGPTcontent').innerHTML.length
      //console.log('changed content');
      this.setState({
        emailhtml: contenthtml,
        emailhtmlcount: currentcontentlength,
      });
      //console.log(this.state.emailhtml);
      this.gettemplate();
    } else {
      //console.log('no change');
    }
  };
  gettemplate = () => {
    var pltext = this.quillRef.getText();
    var html =this.quillRef.root.innerHTML;// draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));// stateToHTML(this.state.editorState.getCurrentContent());
    var css = html;
    this.autosavehtml(html, css, false);
    // $(".rightside-bar-editor div").prop("contenteditable", "true");
    // $(".rightside-bar-editor div span").prop("contenteditable", "true");
    // var self = this;
    // window.StripoApi.getTemplate(function (html, css) {
    // 	var rhtml = html.substring(html.indexOf("<html"));
    // 	self.autosavehtml(rhtml,css,false);
    // });
  };
  gettemplatebtn = () => {
    var title = document.getElementById('templatetitle').value;
    if (title == "") {
      this.customAlertMessage("error","Please enter Title");
      return;
    }
    var html = this.quillRef.root.innerHTML;//draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));// stateToHTML(this.state.editorState.getCurrentContent());
    var css = html;
    this.autosavehtml(html, css, true);
    // var self = this;
    // window.StripoApi.getTemplate(function (html, css) {
    // 	var rhtml = html.substring(html.indexOf("<html"));
    // 	self.autosavehtml(rhtml,css,true);
    // });
  };
  requestGetTemplateFinal = (method, url, data, callback) => {
    var req = new XMLHttpRequest();
    req.onreadystatechange = function () {
      if (req.readyState === 4 && req.status === 200) {
        //save data
        //console.log(req.responseText);
        callback(req.responseText);
      } else if (req.readyState === 4 && req.status !== 200) {
        console.error(
          "Can not complete request. Please enter valid auth token"
        );
      }
    };
    req.open(method, url, true);
    if (method !== "GET") {
      req.setRequestHeader("content-type", "application/json");
      req.setRequestHeader(
        "Header",
        "ES-PLUGIN-AUTH: Bearer " + document.getElementById("hfAuthToken").value
      );
    }
    req.send(data);
  };

  autosavehtml = (html, css, frombtn) => {
    var self = this;
    var titledefault = document.getElementById("templatetitle").value;
    if (titledefault.trim() == "") {
      titledefault = "No title";
    }
    var descriptiondefault = document
      .getElementById("templatedescription")
      .value.trim();
    var emailidentity =
      queryString.parse(window.location.search).id == undefined
        ? this.state.emailguid
        : queryString.parse(window.location.search).id;
        var contentbrief = document.getElementById("textareaoutput").value;
        var tone = document.getElementById("toneofvoice").value;
        var keyword = document.getElementById("keywordinput").value;
        var outlen = this.getoutlength();
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    console.log("before API: " + emailidentity);
    data.append("tempid", emailidentity);
    data.append("userid", UserID);
    data.append("companyid", Companyid);
    data.append("html", html);
    data.append("css", css);
    data.append("title", titledefault);
    data.append("description", descriptiondefault);
    data.append("teamid", TeamId);
    data.append("contentbrief", contentbrief);
    data.append("tone", tone);
    data.append("keyword", keyword);
    data.append("outlen", outlen);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/autosavetemplate",
      data: data,
    };

    axios(config)
      .then(function (resp) {
        //   console.log(JSON.stringify(resp.data));
        var response = resp.data;
        if (response.Status == "200" && frombtn) {
          self.customAlertMessage("success", "Template saved successfully.");
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  getoutlength = () =>{
    var rdbtnlength = document.getElementsByName('radioforlength');
    var selectedtext = "";
    var outputtext = "";
    for (let i = 0; i < rdbtnlength.length; i++) {
      if(rdbtnlength[i].checked){
        selectedtext = rdbtnlength[i].dataset.text;
        break;
      }
    }
    switch (selectedtext) {
        case "short":
          outputtext = "1";
          break;
          case "medium":
          outputtext = "2";
          break;
          case "long":
          outputtext = "3";
          break;    
        default:
          outputtext = "-1";
          break;
      }
    return outputtext;
  }
  getradiofromdb = (dbval) => {
    var rdbtnlength = document.getElementsByName('radioforlength');
    var outputtext = "";
    switch (dbval.toString()) {
      case "1":
        outputtext = "short";
        break;
        case "2":
        outputtext = "medium";
        break;
        case "3":
        outputtext = "long";
        break;    
      default:
        outputtext = "";
        break;
    }
    for (let i = 0; i < rdbtnlength.length; i++) {
      if(rdbtnlength[i].dataset.text === outputtext){
        rdbtnlength[i].checked = true;
        rdbtnlength[i].dataset.waschecked = "true";
        break;
      }
    }
  }
  savegptresponse = (result, totaltoken) => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("prompt", "prompt");
    data.append("userid", Role == 3 ? MasterId : UserID);
    data.append("companyid", Companyid);
    data.append("result", result);
    data.append("token", totaltoken);

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/savegptresponse",
      data: data,
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  getselectedcontentlength =() => {
    var rdbtnlength = document.getElementsByName('radioforlength');
    var selectedtext = "";
    var outputtext = "";
    for (let i = 0; i < rdbtnlength.length; i++) {
      if(rdbtnlength[i].checked){
        selectedtext = rdbtnlength[i].dataset.text;
        break;
      }
    }
    
    // switch (selectedtext) {
    //   case "short":
    //     outputtext = "Write an short abstract for above topic";
    //     break;
    //     case "medium":
    //     outputtext = "Explain in detail for above topic";
    //     break;
    //     case "long":
    //     outputtext = "Write an blog post on above topic";
    //     break;    
    //   default:
    //     outputtext = "";
    //     break;
    // }
    switch (selectedtext) {
      case "short":
        outputtext = "400";
        break;
        case "medium":
        outputtext = "1000";
        break;
        case "long":
        outputtext = "2000";
        break;    
      default:
        outputtext = "2000";
        break;
    }
    return outputtext;
  }

  cleareditor =()=>{
    document.getElementById('plagiarisminfo').style.display = 'none';
    this.quillRef.setContents([]);
    // const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState: "" });
  }
  testhtmlappend = () =>{
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    this.quillRef.insertText(position, 'Hello, World! ');
    // this.appendhtmltoeditor("<GPT>this is test data</GPT>");
  }
  quillhtmlappend = (savedhtml) => {    
    this.quillRef.clipboard.dangerouslyPasteHTML(savedhtml);
    var range = this.quillRef.getLength();
    let position = range ? range.index : 0;    
    setTimeout(() => {
        this.quillRef.setSelection(position,0);
    }, 1);
  }

  quilltextappend = (gpttext) =>{
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    // if (range == null && this.quillRef.getLength() > 0) {
    //   position = this.quillRef.getLength();
    // }    
    this.quillRef.insertText(position, gpttext);
  }

  quilltextappendforbrief = (gpttext) =>{
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    if (range == null && this.quillRef.getLength() > 0) {
      position = this.quillRef.getLength();
    }    
    this.quillRef.insertText(position,"\r\n" + gpttext);
  }

  quilltextappendforvoice = (gpttext) =>{
    var scrolltoend = false;
    var range = this.quillRef.getSelection();
    let position = range ? range.index : 0;
    if (range == null && this.quillRef.getLength() > 0) {
      position = this.quillRef.getLength();
      scrolltoend = true;
    }    
    this.quillRef.insertText(position, "\n"+ gpttext);
    const element = document.getElementsByClassName('ql-editor')[0];
    if (element && scrolltoend) {
        element.scrollTop = element.scrollHeight;
    }
  }
  checkandreset = (e) => {
    var radio = e.currentTarget;        
    // if this was previously checked
    if (radio.dataset.waschecked == "true")
    {
        radio.checked = false;
        radio.dataset.waschecked = "false";
    }
    else{
      radio.dataset.waschecked = "true";
      radio.checked = true;
    }
    var rdbtnlength = document.getElementsByName('radioforlength');
    for (let i = 0; i < rdbtnlength.length; i++) {
      if(e.currentTarget.dataset.text != rdbtnlength[i].dataset.text){
        rdbtnlength[i].dataset.waschecked = "false";
      }
    }
  }
  getChildren = (n, skipMe) =>{
    var r = [];
    for ( ; n; n = n.nextSibling ) 
       if ( n.nodeType == 1 && n != skipMe)
          r.push( n );        
    return r;
};

copyToClipboard = (e) => {
  var selection = this.quillRef.getSelection();
  if (selection != null && selection.length > 0) {
    var selectedContent = this.quillRef.getContents(selection.index, selection.length);
    if (selectedContent.ops.length) {
      copy(selectedContent.ops[0].insert,{format:"text/plain"});
      this.customAlertMessage('success','Copied!');  
    }
    else{      
      var Content = this.quillRef;
      copy(Content.container.innerText,{format:"text/plain"});
      this.customAlertMessage('success','Copied!');
    }    
  }
  else{
    var Content = this.quillRef;
    copy(Content.container.innerText,{format:"text/plain"});
    this.customAlertMessage('success','Copied!');
  } 
};

  render() {
    return (
      <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">
          <div className="row">
            <input type="hidden" id="hfAuthToken"></input>

            <div className="col-12">
              <div className="title-card1">
                  <span className="float-start">
                    <h4>
                      <span>
                        <a href="/" style={{textDecoration:"none", color:"#fff"}}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                        Email </a>-<i className="small-title">Create email campaigns using chatGPT. Use the options on the left side or give commands inside the right side box.</i>
                      </span>
                    </h4>
                  </span>
                  <span className="float-end mt-20">
                        <Link to="/emailtemplate" className="float-start">Saved List</Link>
					        </span>
                
                {/* {this.state.emailguid} */}
              </div>
            </div>

            <div className="col-12">
			
              <div className="row">
                <div className="col-4">
                  <div class="lefside-bar" style={{maxHeight:"unset"}}>
                    <div className="row">
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-12 label-info">
                            <label>
                              Content Description/Brief
                              <span
                                tooltip="Tell chocolatechips.ai what you are writing about."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          <React.StrictMode>
                            <AudioToText />
                          </React.StrictMode>
                        </div>
                      </div>
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-9 label-info">
                            <label>
                              Tone of Voice
                              <span
                                tooltip="Try using different combinations of words like witty, friendly, disappointed, polite, creative, professional or add a well-known personality such as Adelle."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          {/* <div className="col-3 switch-toggle">
										<input type="checkbox" id="switch1" onChange={this.toggletone.bind(this)} /><label htmlFor="switch1">Toggle</label>
									</div> */}
                          <div className="col-12">
                              <input
                                id="toneofvoice"
                                type="text"
                                maxLength={60}
                                className="common-input"
                                onKeyUp={this.updatetonecount}
                                disabled={
                                  this.state.tonedisabled ? "disabled" : ""
                                }
                                placeholder="Professional, Friendly"
                              />

                            <span className="character-limit">
                              {this.state.Tonecount}/60
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-30">
                        <div className="row">
                          <div className="col-9 label-info">
                            <label>
                              Keywords
                              <span
                                tooltip="Write keywords separated by commas."
                                flow="down"
                              >
                                <FontAwesomeIcon icon={faInfoCircle} />
                              </span>
                            </label>
                          </div>
                          {/* <div className="col-3 switch-toggle">
									<input type="checkbox" id="switch2" onChange={this.togglekeywords.bind(this)} /><label for="switch2">Toggle</label>
									</div> */}
                          <div className="col-12">
                              <input
                                id="keywordinput"
                                type="text"
                                className="common-input"
                                onChange={this.setwordcount}
                                disabled={
                                  this.state.keyworddisabled ? "disabled" : ""
                                }
                                placeholder="Behavior, Vision"
                              />

                            <span className="character-limit">
                              {this.state.wordcount}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-20">
                        <div className="row">
                          <div className="col-12 label-info">
                            <label>Output Length</label>
                          </div>
                          <div className="col-12 output-length">
                              <div className="container-radio">
                                      <div className="radio_container">
                                          <input type="radio" name="radioforlength" id="one" data-text="short" data-waschecked="false" onClick={this.checkandreset} />
                                          <label htmlFor="one">Short</label>
                                          <input type="radio" name="radioforlength" id="two" data-text="medium" data-waschecked="false" onClick={this.checkandreset}/>
                                          <label htmlFor="two">Medium</label>
                                          <input type="radio" name="radioforlength" id="three" data-text="long" data-waschecked="false" onClick={this.checkandreset}/>
                                          <label htmlFor="three">Long</label>
                                      </div>
                                    </div>
                          </div>
                        </div>
                      </div> 
                      <div className="col-12 mb-30">
                        <div className="row">
                          {/* <div className="col-12 text-center">
												<a href="javascript:;" className="common-save" onClick={this.generateaicontent}>Generate</a>
											</div> */}
                          <div className="col-12">
                            <a
                              id="templatetogglelink"
                              href="javascript:;"
                              className="float-end save-temp"
                              onClick={this.toggletemplatediv}
                            >
                              Save As Template
                            </a>
                          </div>
                        </div>
                      </div>
                      <div id="templatesavediv" style={{ display: "none" }}>
                        <div className="col-12 mb-30">
                          <div className="row">
                            <div className="col-9 label-info">
                              <label>
                                Title
                                <span
                                  tooltip="Enter a title to save as template and you can continue where you left off."
                                  flow="right"
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              </label>
                            </div>
                            {/* <div className="col-3 switch-toggle">
											<input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
										</div> */}
                            <div className="col-12">
                                <input
                                  id="templatetitle"
                                  type="text"
                                  className="common-input"
                                />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 mb-30">
                          <div className="row">
                            <div className="col-9 label-info">
                              <label>
                                Description
                                <span
                                  tooltip="A slight note to help you identify what the topic you were working on."
                                  flow="right"
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} />
                                </span>
                              </label>
                            </div>
                            {/* <div className="col-3 switch-toggle">
											<input type="checkbox" id="switch1" /><label htmlFor="switch1">Toggle</label>
										</div> */}
                            <div className="col-12">
                                <input
                                  id="templatedescription"
                                  type="text"
                                  className="common-input"
                                />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="text-center"
                            id="btnsavetemplate"
                            style={{ display: "none" }}
                          >
                            <a
                              href="javascript:;"
                              onClick={this.gettemplatebtn}
                              className="common-save"
                            >
                              Save
                            </a>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-8">
                  <div class="">
                    <div className="row">
                      <div className="col-12 text-end chech-right" style={{marginBottom:"10px"}}>
                        {/* <span><FontAwesomeIcon icon={faCopy} onClick={this.copyToClipboard} style={{marginLeft:'0px'}} /></span> */}
                        {this.state.copyscapeavailable == true ? 
                        <span onClick={this.Checkplagiarism} tooltip="Check plagiarism" flow="left">
                          <FontAwesomeIcon icon={faShield} style={{fontSize:'24px',cursor:"pointer",color:'#9333ea'}} />
                        </span> : null }
                        <span
                          id="plagiarisminfo"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}
                        >
                          5 copies found
                        </span>
                        {/* <span className="text-count">5 words</span> */}
                      </div>
                    </div>
                    <div className="row" style={{display:'none'}}><span>{this.state.previousrequeststringlength}</span><span>{this.state.genrateanother.toString()}</span><span>{this.state.initrequest.toString()}</span></div>
                    <div className="row">  
                      <div className="col-12" style={{position:"relative", marginBottom:"20px"}} >
                      <div id="overlay" className="email-overlay">
                        <div id="loading">
                          <h2 id="loaderheading">Generating...</h2>
                          <img src={loader} />
                        </div>
                      </div>
                      <div className="editorstt">
                          <AudioToTextforEditor parentCallback={this.quilltextappendforvoice}></AudioToTextforEditor>
                      </div>
                      {(this.state.grammarlykey != "" ? 
                      <GrammarlyEditorPlugin clientId={this.state.grammarlykey}>
                          <div className="text-editor">
                            <EditorToolbar />
                            <ReactQuill 
                            ref={(el) => {this.reactQuillRef = el;}} 
                            theme="snow" 
                            modules={modules} 
                            formats={formats} 
                            onChange={this.onChangeeditor} 
                            placeholder='Start writing or speaking your command here and click Generate. Example, "Write an email to invite potential buyers to my open house."' >
                            </ReactQuill>
                          </div>
                      </GrammarlyEditorPlugin>     
                       : <div className="text-editor">
                          <EditorToolbar />
                          <ReactQuill 
                          ref={(el) => {this.reactQuillRef = el;}} 
                          theme="snow" 
                          modules={modules} 
                          formats={formats} 
                          onChange={this.onChangeeditor} 
                          placeholder='Start writing or speaking your command here and click Generate. Example, "Write an email to invite potential buyers to my open house."' >
                          </ReactQuill>
                        </div> 
                    )}
                      </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                          <div className="text-center">
                            {/* <input
                              type="text"
                              id="emailinputprompt"
                              className="generateinput"
                              placeholder="Ask me anything about..."
                            /> */}
                            
                            <a
                              href="javascript:;"
                              className="common-save"
                              onClick={this.checkcounters}
                            >
                              Generate
                            </a>  
                            <a
                              href="javascript:;"
                              className="common-save common-save-grey"
                              onClick={this.cleareditor}
                              style={{marginRight:"10px"}} 
                            >Clear&nbsp;
                              <img src={cleareditorbtn} width={20} height={20}></img>
                            </a>     
                            {/* <a href="javascript:;" onClick={this.getSelectionHtml}>test</a>            */}
                          </div>
                        </div>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-fixed">
            
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Plagiarism
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" id="plagiarismmodalbody" dangerouslySetInnerHTML={this.state.plagiarismmodalbody}>
                  
              </div>
            </div>
          </div>
        </div>
        <a href='javascript:;' id='hrefsubscription' data-bs-toggle="modal"
                          data-bs-target="#subscriptionnmodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
 <div className="modal fade" id="subscriptionnmodal" tabIndex={-1} aria-labelledby="subscriptionModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="subscriptionModalLabel"><b>Upgrade Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
                <div className="col-12">
                  <p id="psubtext"></p>
                  <div className="row" style={{marginBottom:"10px",display:"none"}}>
                      <div className='col-3'>
                          <Link id="lnkNormalUpgradeTTI" to="/upgradeplan">Upgrade</Link>
                          <Link id="lnkSSOUpgradeTTI" to="/upgradeplansso">Upgrade</Link>
                      </div>
                  </div>
                  {/* {SSOUser == true ?
                  <p> Please click on this&nbsp;<Link to="/upgradeplansso">link</Link>&nbsp;to upgrade</p> :
                 <p> Please click on this&nbsp;<Link to="/upgradeplan">link</Link>&nbsp;to upgrade</p>} */}
                </div>
              </div>
              <div className="modal-footer">   
              <button type="button" style={{display:"block"}} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>            
              <a href='javascript:;' id='btnUpgrade' onClick={this.proceedOnUpgrade} className="common-save float-start">Upgrade</a>                            
              </div>
          </div>
      </div>
      </div>

      </div>
    );
  }
}



const HANDLE_REGEX = /@[\w]+/g;
const HASHTAG_REGEX =  /#[\w\u0590-\u05ff]+/g; // /<gpt[^>]*>[\s\S]+<\/gpt>/g; // /#[\w\u0590-\u05ff]+/g;

function handleStrategy(contentBlock, callback, contentState) {
  findWithRegex(HANDLE_REGEX, contentBlock, callback);
}
function hashtagStrategy(contentBlock, callback, contentState) {
  findWithRegex(HASHTAG_REGEX, contentBlock, callback);
}

// function handleCodeStrategy(contentBolock, callback, contentState) {
//   findWithRegex(HANDLE_CODE_REGEX, contentBolock, callback);
// }

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}
const HandleSpan = props => {
  return (
    <span style={styles.handle} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};
const HashtagSpan = props => {
  return (
    <span style={styles.hashtag} data-offset-key={props.offsetKey}>
      {props.children}
    </span>
  );
};

// class MdWrapper extends React.Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     return (
//       <div style={{ border: "2px solid blue" }}>
//         <textarea
//           type="input"
//           placeholder="Please write code here."
//           value={this.props.code}
//         />
//       </div>
//     );
//   }
// }

const styles = {
  root: {
    fontFamily: "'Helvetica', sans-serif",
    padding: 20,
    width: 600
  },
  editor: {
    border: "1px solid #ddd",
    cursor: "text",
    fontSize: 16,
    minHeight: 40,
    padding: 10
  },
  button: {
    marginTop: 10,
    textAlign: "center"
  },
  handle: {
    color: "rgba(98, 177, 254, 1.0)",
    direction: "ltr",
    unicodeBidi: "bidi-override"
  },
  hashtag: {
    color: "rgba(95, 184, 138, 1.0)"
  }
};
export default QuillEmail;
