import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import Cookies from 'js-cookie'
import {UserID,Companyid,CompanyName,Name} from "../User";
import loader from "../img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';


class CreateUser extends React.Component {

  constructor(props) {
		super(props);
		this.state = { 
      tabledata:[] 
	  }
  }
  componentDidMount(){
    this.toggleUsersCreation(true);
    //this.loadUsersData();
  }
  historygoback = () => {
        window.history.back();
  };

  toggleUsersCreation = (isAddUser) => {
    if(isAddUser){
      document.getElementById("divUsersGrid").style.display="none";
      document.getElementById("divCreateUser").style.display="block";
      document.getElementById("spanCreateNew").style.display="none";
      document.getElementById("spanBack").style.display="block";
    }
    else {
      document.getElementById("divUsersGrid").style.display="block";
      document.getElementById("divCreateUser").style.display="none";
      document.getElementById("spanCreateNew").style.display="block";
      document.getElementById("spanBack").style.display="none";
    }
}

  loadUsersData = () =>{
    var self = this;
    self.showoverlay();
    var config = {
        method: 'post',
        "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAdditionalUsers?companyId="+Companyid,
        headers: {
           'Content-Type': 'application/json' 
        }
    };

    axios(config)
        .then(function (resp) {
            
            var response = (resp.data);
            if (response.Status == "200") {
                if (response.Data != null) {
                    //
                    self.setState({tabledata:response.Data});
                    // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId                        
                    console.log('Inside show users response');
                    console.log(response);
                }
                else {                        
                    console.log('Failed to load data.');
                }
            }
            else {
              console.log('error', response.Message); // 'success','failure','info'
            }
            self.hideoverlay();
        })
        .catch(function (error) {
          console.log('Something went wrong, Try Again.');
            self.hideoverlay();
        });
  }

  deleteAdditionalUser = (userId) => {
    var self = this;        
    var result = window.confirm("Are you sure you want to delete this user?");
        if (!result) {
            return;
        }
    self.showoverlay();
    var config = {
        method: 'post',
        "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteAdditionalUser?UserId="+userId,
        headers: {
           'Content-Type': 'application/json' 
        }
    };

    axios(config)
        .then(function (resp) {
            
            var response = (resp.data);
            if (response.Status == "200") {
                    //team created load  all teams
                    self.customAlertMessage('success', "User deleted successfully");
                    self.loadUsersData();
                
            }
            else {
                self.customAlertMessage('error', response.Message); // 'success','failure','info'
            }
            self.hideoverlay();
        })
        .catch(function (error) {
            self.customAlertMessage('error', 'Something went wrong, Try Again.');
            self.hideoverlay();
        });
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  addUserData = () => {
    var self = this;
    var txtFN = document.getElementById('txtFN').value;
    if(txtFN == ""){
        self.customAlertMessage("error", "Please enter First Name.");
        return;
    }
    var txtLN = document.getElementById('txtLN').value;
    if(txtLN == ""){
        self.customAlertMessage("error", "Please enter Last Name.");
        return;
    }
    var txtEmail = document.getElementById('txtEmail').value;
    if(txtEmail == ""){
      self.customAlertMessage("error", "Please enter Email Address.");
      return;
    }
    else {
      var isValidEmail = this.validateEmail(txtEmail);
      if(!isValidEmail){
        self.customAlertMessage("error", "Please enter valid Email Address.");
        return;
      }
    }
    var cname = document.getElementById('txtcompanyname').value;
    if (cname == "") {
      cname = CompanyName;
    }
    var txtMN = document.getElementById('txtMN').value;
    self.showoverlay();

//CreateAdditionalUser(string firstName, string lastName, string EmailId, string phoneNumber, string accountType,
// string CompanyName, string CompId, string MasterId)

    var config = {
        method: 'post',
        "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CreateAdditionalUser?firstName="+txtFN+"&lastName="+txtLN + 
        "&EmailId="+txtEmail + "&phoneNumber="+txtMN + "&accountType=1&CompanyName=" + cname 
        + "&CompId="+Companyid + "&MasterId="+UserID,
        headers: {
           'Content-Type': 'application/json' 
        }
    };

    axios(config)
        .then(function (resp) {
            
            var response = (resp.data);
            if (response.Status == "200") {
                if (response.Data != null && response.Data != "") {
                    //team created load  all teams
                    self.loadUsersData();
                    document.getElementById('txtFN').value = "";
                    document.getElementById('txtLN').value = "";
                    document.getElementById('txtEmail').value = "";
                    document.getElementById('txtMN').value = "";
                    self.toggleUsersCreation(false);
                    self.customAlertMessage('success', "User added successfully");   
                    //document.getElementById('lnkBack').click();
                    document.getElementById('lnkBack').click();
                }
                else {                        
                    self.customAlertMessage('error', "Failed to add user, Please try again.");
                }
            }
            else {
                self.customAlertMessage('error', response.Message); // 'success','failure','info'
            }
            self.hideoverlay();
        })
        .catch(function (error) {
            self.customAlertMessage('error', 'Something went wrong, Try Again.');
            self.hideoverlay();
        });

  }

  customAlertMessage = (icon, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon, //'success',
      title: message, // 'Signed in successfully'
    });
  };

  showoverlay = () => {
    document.getElementById("overlay").style.display = "block";
    $("body").addClass("loader");
  };

  hideoverlay = () => {
    document.getElementById("overlay").style.display = "none";
    $("body").removeClass("loader");
  };

  sendLoginAdditionalUser = (userUserId,userFirstName,userEmailId) => {
    var self = this;        
    // SendLoginInfo(string AUName, string AUEmailId, string SenderName,string UserId)
    self.showoverlay();
    var config = {
        method: 'post',
        "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/SendLoginInfo?AUName="+userFirstName + "&AUEmailId="+userEmailId +
        "&SenderName="+Name + "&UserId="+userUserId,
        headers: {
           'Content-Type': 'application/json' 
        }
    };

    axios(config)
        .then(function (resp) {
            
            var response = (resp.data);
            if (response.Status == "200") {
                    //team created load  all teams
                    self.customAlertMessage('success', "Login link sent to user successfully.");
                    self.loadUsersData();
                
            }
            else {
                self.customAlertMessage('error', response.Message); // 'success','failure','info'
            }
            self.hideoverlay();
        })
        .catch(function (error) {
            self.customAlertMessage('error', 'Something went wrong, Try Again.');
            self.hideoverlay();
        });
  }


    render(){
return (

    <div className="main_content_iner overly_inner dashboard-page">
        <div className="container-fluid p-0 ">
          <div className="row">	  
              <div id="overlay">
                    <div id="loading">
                        <h2 id="loaderheading">Loading...</h2>
                        <img src={loader} />
                    </div>
              </div> 

              <div className="col-12">
                  <div className="title-card1">
                    <span className="float-start">
                      <h4>
                      <span style={{ cursor: "pointer" }} onClick={this.historygoback}>
                          <FontAwesomeIcon icon={faChevronLeft} />
                          Create User
                      </span>
                      </h4>
                    </span>
                    <span id='spanCreateNew' className="float-end mt-20">
                        <Link to="/createuser">
                            Create New user
                        </Link>
                      {/* <a href='/createuser' tabIndex={-1} onClick={() =>this.toggleUsersCreation(true)} className="common-save">Create New User</a> */}
					</span>
                    <span id='spanBack' className="float-end mt-20">
                        <Link id="lnkBack" style={{display:"none"}} to="/manageusers">
                            Back
                        </Link>
                      {/* <a href='/manageusers' tabIndex={-1} onClick={() => this.toggleUsersCreation(false)} className="common-back"><u>Back</u></a> */}
                    </span>
                  </div>                  
              </div>
              <div className="col-12">
                <br></br>
              </div>
          </div>

          <div id="divUsersGrid" className="row">            

            <div className="col-12 rightside-bar">
              
            </div>

            {/* <div className="col-12 rightside-bar">                        
              <table className="common-table">
                <thead>
                  <tr>
                    <th className="width-inc">First Name</th>
                    <th className="width-inc">Last Name</th>
                    <th className="width-inc">Email</th>
                    <th className="width-inc">Created On</th>
                    <th className="width-inc"> </th>
                    <th className="width-inc"> </th>
                  </tr>
                </thead>
                <tbody>
                              {this.state.tabledata.length > 0 ? this.state.tabledata.map((d,index) => (
                                  <tr key={d.UserId} data-item={d.UserId}>
                    <td>{d.FirstName}</td>
                    <td>{d.LastName}</td>
                    <td>{d.EmailId}</td>
                    <td>{moment(d.CreatedDate).format('MMM DD YYYY h:mm A')}</td>
                    <td> <a href='javascript:;' onClick={() => this.sendLoginAdditionalUser(d.UserId,d.FirstName,d.EmailId)} >Send Login</a> </td>
                    <td> <a href='javascript:;' onClick={() => this.deleteAdditionalUser(d.UserId)} >Delete</a> </td>
                  </tr>

                              )) : <tr>
                                  <td colSpan={3} className="text-center">No data found</td>
                                  </tr>
                                  }
                </tbody>							
              </table> 
            </div>*/}
          </div> 

          <div id='divCreateUser' className='row'>
            <div className="col-12 dashboard-scroll profile-bottom">
              <div className='row'>
                <div className='col-4'>
                  <div className='col-12 mb-30'>
                      <label>First Name</label>
                      <input id="txtFN" type="text" className='common-input'/>
                  </div>  
                  <div className='col-12 mb-30'>
                      <label>Mobile Phone</label>
                      <input id="txtMN" type="text" className='common-input'/>
                  </div>   
                  <div className='col-12 mb-30'>
                      <label>Company Name</label>
                      <input id="txtcompanyname" type="text" className='common-input'/>
                  </div>             
                  
                </div>     

                <div className='col-4'>
                <div className='col-12 mb-30'>
                      <label>Last Name</label>
                      <input id="txtLN" type="text" className='common-input'/>
                  </div>
                <div className='col-12 mb-30'>
                      <label>Email</label>
                      <input id="txtEmail" type="text" className='common-input'/>
                  </div>
                  
                  
                </div>   
              </div>
              <div className='row'>
                <div className='col-4'>
                  <a href='javascript:;' tabIndex={-1} onClick={this.addUserData} className="common-save">Add User</a>
                  &nbsp;                
                </div>           
              </div>
            </div>
          </div>

        </div>
    </div>

    )
}
};
export default CreateUser;