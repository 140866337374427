import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";

const DDLmedium = () => {
  const options = [
    { value: "-1", label: "None" },
    { value: "1", label: "Chalk" },
    { value: "2", label: "Charcoal" },
    { value: "3", label: "Crayon" },
    { value: "4", label: "Oil" },
    { value: "5", label: "Pastel" },
    { value: "6", label: "Pencil" },
    { value: "7", label: "Watercolor" },
  ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  return (
    // <Select options={options} styles={customStyles} />
    <Select id="ddlmedium"  menuPlacement="bottom" options={options} maxMenuHeight={200} />
  );
};

export default DDLmedium;