import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faMicrophone,faInfo,faInfoCircle, faRotateRight, faShield, faCloudArrowUp, faCircleDown, faFile, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import { Link } from "react-router-dom";
import React from 'react';
import { render } from '@testing-library/react';
// import copy from 'copy-to-clipboard';
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard } from 'copy-image-clipboard'
import loader from "../img/sample.gif";
import Roomtheme from './roomtheme';
import Roomtype from './roomtypeold';
import '../css/roomgpt.css';
import Imgsample from "./imgsamples"
import ImageResolution from "./ImageResolution"
import heic2any from "heic2any";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;
class Transformation extends React.Component {
	constructor(props){
	super(props);
	this.state = {
		uploadedfile: null,
		processedimage: "",
		copySuccess: '',
		uploadedbase64:""
	}
	}
	componentDidMount(){
		this.documentinit()
	}
		
	handleDownload = (e) => {
		var url = e.currentTarget.dataset.fileurl;
		var axios = require('axios');
		axios.get(url, {
		  responseType: 'blob',
		})
		.then((res) => {
		var filename = url.substring(url.lastIndexOf('/')+1);
		fileDownload(res.data, filename);
		})
	}
	customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
	documentinit = () => {
		var self = this;
		Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
			el.classList.remove('active');
		});
		document.getElementById('3').classList.add("active");
		$(".dropzone").on("change",function () {
			var tempfile = $(".dropzone")[0].files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
					self.readFile();
				}
			}
			else {
			    this.customAlertMessage('error', 'Maximum file size exceeded.');
			    return;
			}
			//self.readFile();
		  });
  
		  $('.dropzone-wrapper').on('dragover', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').addClass('dragover');
		  });
  
		  $('.dropzone-wrapper').on('dragleave', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').removeClass('dragover');
		  });
		  document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {			
			event.preventDefault();
			var tempfile = event.dataTransfer.files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			self.state.uploadedfile = tempfile;
			if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
				self.readFileondrop(event.dataTransfer);
			}
		});
  
		  $('.remove-preview').on('click', function () {
			var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
			var previewZone = $('.remove-preview').parents('.preview-zone');
			//var dropzone = $('.remove-preview').parents('.form-group').find('.dropzone');
			//$('#originalimagediv').hide();
			// boxZone.empty();
			previewZone.addClass('hidden');
			$('.dropzone-wrapper').removeClass('hidden');
			document.getElementsByClassName("dropzone")[0].value = "";
			//self.reset(dropzone);
		  });

	}
	blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

    readiphoneFile = () => {
        var self = this;
        var input = $('.dropzone')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {                
                fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file });
                var wrapperZone = $(input).parent();
				var previewZone = $(input).parent().parent().find('.preview-zone');
				wrapperZone.removeClass('dragover');
				previewZone.removeClass('hidden');
				$('.dropzone-wrapper').addClass('hidden');
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    readiphoneFileondrop = (input) => {
        var self = this;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob }))
                .then((conversionResult) => {
                    // conversionResult is a BLOB
                    // of the PNG formatted image
                    var url = URL.createObjectURL(conversionResult);
                    var file = new File([conversionResult],  uuidv4() + ".png");
                    self.setState({ processedimage: url,uploadedfile:file });
                    var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					$('.dropzone-wrapper').addClass('hidden');
                    self.hideoverlayiphone();
                })
                .catch((e) => {
                    // see error handling section
                    self.customAlertMessage('error','Error occured, please try again.'); 
                    self.hideoverlayiphone();
                });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
			readFile = () => {
				var self = this;
				 var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $(input).parent();
					var previewZone = $(input).parent().parent().find('.preview-zone');
					var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				}
			}

			readFileondrop = (input) => {
				var self = this;
				 //var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					//var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				  //document.getElementById('srcImg').src=this.state.processedimage;
				}
			}

			showoverlayiphone = () => {
				document.getElementById('overlayiphone').style.display = "block";
				$('body').addClass("loader");
			}
			hideoverlayiphone = () => {
				document.getElementById('overlayiphone').style.display = "none";
				$('body').removeClass("loader");
			}
	  
			reset = (e) => {
				var eleel = $('.remove-preview').parents('.form-group').find('.dropzone');
				//$('#originalimagediv').hide();
				// eleel.wrap('<form>').closest('form').get(0).reset();
				// eleel.unwrap();
				$('.remove-preview').click();
				document.getElementById('formdiv').style.display='flex';
 				document.getElementById('outputdiv').style.display='none';
				document.getElementById('GImage').src='';
				document.getElementById('roomtypeclear').click()
				document.getElementById('roomthemeclear').click()
			}
	
			
			copyToClipboard = (e) => {
				const canCopy = canCopyImagesToClipboard();
				if (issafari) {
					this.copyimageforsafari(e.currentTarget.dataset.fileurl);
				}
				else if (canCopy) {
					copyImageToClipboard(e.currentTarget.dataset.fileurl)
					.then(() => {
					  console.log('Image Copied')
					  this.customAlertMessage('success','Copied!');
					})
					.catch((e) => {
					  console.log('Error: ', e.message)
					  this.customAlertMessage('error','Please try again!');
					})
				}
				else {
					this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
				}        
				// copy(e.currentTarget.dataset.fileurl);     
				e.target.focus();
			};
		
			copyimageforsafari = async(imageurl) => {
				try {
					const item = new ClipboardItem({
						'image/png': (async () => {
						  const response = await fetch(imageurl) // Reference from your source code.
						  return await response.blob()
						})(),
					  });
					  await navigator.clipboard.write([item])
					  this.customAlertMessage('success','Copied!');
				} catch (error) {
					this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
				}
			}
			historygoback = () => {
				window.history.back();
			}
			processImage=()=>{
				let n_prompt = 'Longbody, lowres, bad anatomy, bad hands, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality';
				let result = '';
				const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				const charactersLength = characters.length;
				let counter = 0;
				while (counter < 10) {
					result += characters.charAt(Math.floor(Math.random() * charactersLength));
					counter += 1;
				}
				let n_inputfilename = result+".jpg";		
				
				if(document.getElementById('roomtype').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText=='Select')
				{
					this.customAlertMessage('error','Please choose your home region!');
					return;
				}
				if(document.getElementById('roomtheme').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText=='Select')
				{
					this.customAlertMessage('error','Please choose your new look!');
					return;
				}
				let prompt = document.getElementById('roomtype').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText+", "+document.getElementById('roomtheme').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
				//document.getElementById('formdiv').style.display='none';
				document.getElementById('outputdiv').style.display='block';
				document.getElementsByClassName('outputdream')[0].style.display='none';
				document.getElementsByClassName('outputdream')[1].style.display='none';
				document.getElementsByClassName('outputdream')[2].style.display='none';
				document.getElementsByClassName('outputdream')[3].style.display='none';
				document.getElementById('generatetext').innerText=document.getElementById('roomtype').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText;
					const axios = require('axios');
					const FormData = require('form-data');
					var self = this;
					let data = new FormData();
					
					data.append('img', self.state.uploadedfile);
					data.append('prompt', document.getElementById('roomtype').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
					data.append('a_prompt', document.getElementById('roomtheme').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
					data.append('n_prompt', n_prompt);
					data.append('num_sample', document.getElementById('imgsample').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText);
					data.append('image_resolution', "768");
                    data.append('n_inputfilename', n_inputfilename);
					this.showoverlay();
					let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: `${process.env.REACT_APP_FLASK_URL}` +'/dreamhome',
					
					
					data : data
					};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
    let counter = 0;
    while (counter < 5) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
  //let awsurl= "https://openaiimages.s3.us-west-1.amazonaws.com/"+response.data;
 //document.getElementById('DonloadImg').dataset.fileurl=response.data.result[0]; download
 if(document.getElementById('imgsample').getElementsByClassName('css-1dimb5e-singleValue')[0].innerText=="4")
 {
	document.getElementsByClassName('outputdream')[0].style.display='block';
	document.getElementsByClassName('outputdream')[1].style.display='block';
	document.getElementsByClassName('outputdream')[2].style.display='block';
	document.getElementsByClassName('outputdream')[3].style.display='block';
	document.getElementById('GImage').src=response.data.result[0];
	document.getElementById('GImage1').src=response.data.result[1];
	document.getElementById('GImage2').src=response.data.result[2];
	document.getElementById('GImage3').src=response.data.result[3];
	document.getElementById('download').dataset.fileurl=response.data.result[0]; 
	document.getElementById('download1').dataset.fileurl=response.data.result[1]; 
	document.getElementById('download2').dataset.fileurl=response.data.result[2]; 
	document.getElementById('download3').dataset.fileurl=response.data.result[3]; 
 }
 else
 {
	document.getElementsByClassName('outputdream')[0].style.display='block';
	document.getElementById('GImage').src=response.data.result[0];
	document.getElementById('download').dataset.fileurl=response.data.result[0]; 
 }
 
 
 this.hideoverlay();
})
.catch((error) => {
	this.hideoverlay();
  console.log(error);
});
			}
		render(){
			return(
				<div className="main_content_iner overly_inner ">
				<div className="container-fluid p-0 ">
				  <div className="row">
					<div className="col-12">
						<div className="title-card1">
							<span className="float-start">
							<h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />HomeGPT</span></h4>
								
							</span>
						</div>
					</div>
					</div>
					
					<form action="" method="POST" encType="multipart/form-data">
					<div className='row' id='formdiv'>
					<div className="col-4">
						<div className='lefside-bar'>
						<div className="row">
							<div className='col-12 label-info mb-30'>
								<label>What do you want this space to be</label>
								<Roomtype/>
							</div>
							<div className='col-12 label-info mb-30'>
								<label>Choose your new look</label>
								<Roomtheme/>
							</div>
							 {/* <div className='col-12 label-info mb-30'>
								<label>Enter colors and patterns preference</label>
								<textarea id='a_prompt' className='common-textarea' style={{minHeight:"50px"}}></textarea>
							</div>  */}
							<div className='col-12 label-info mb-30'>
								<label>Number Of Sample</label>
								<Imgsample/>
							</div>
							{/* <div className='col-12 label-info mb-30'>
								<label>Image Resolution</label>
								<ImageResolution/>
							</div> */}
							<div className="col-12">
								
								<div className="dropzone-wrapper" style={{width:"100%", marginBottom:"40px"}}>
									<div id="overlayiphone">
										<div id="loading" style={{ top: "35%" }}>
											<h2 id="loaderheading">Generating...</h2>
											<img src={loader} />
										</div>
									</div> 
									<div className="dropzone-desc">
									<h4>Drag and Drop</h4>
									<p><b>or Click to upload</b></p>										
									<FontAwesomeIcon icon={faCloudArrowUp} />
									<p>
										Supported Files: PNG, JPG, HEIF, HEIC.
									</p>
									{/* <p>
										Size: up to 10MB
									</p> */}
									</div>
									<input type="file" name="img_logo" className="dropzone" accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"></input>
								</div>
		
											<div className="preview-zone hidden">
											  <div className="box box-solid">
												<div className="grey-box box-dream">
														<div className="row">															
															<div className="col-12 top-box" id="originalimagediv">
																<div className="row">
																	<div className="col-12">
																		<span className="float-start"><h5 className="right-header"><b>Original Image</b></h5></span>
																		<span className="float-end tooltip1 crossmark-dream" tooltip="Remove" flow="left">
																			<a href='javascript:;' className="btn btn-danger btn-xs remove-preview">
													  							<FontAwesomeIcon icon={faTimes} />
																			</a>
																		</span>
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 text-center top-box">
																		<img id="originalimage" className='dreamoriginal' src={this.state.processedimage}></img>																		
																	</div>
																	{/* <div className='col-12'>
																		<a href="javascript:;" className="common-save" onClick={this.processImage}>Show Me</a>
																	</div> */}
																</div>

																<div className='bottom-fixed roombottom-fixed' style={{paddingBottom:"0"}}>
																	<div className="row">
																		<div className="col-4" style={{background:"#121418"}}>
																				<div className="text-center">
																					<a href="javascript:;" className="common-save" onClick={this.processImage} style={{marginBottom:"10px"}}>Show Me</a>
																				</div>
																		</div>
																	</div>
																</div>
														
															</div>
												</div>
												
												</div>
											  </div>
											</div>
		
							</div>
						</div>
					</div>
					</div>

					 <div className='col-8' id='outputdiv' style={{display:'none'}}>
						<div className='row remodeledtheme'>
							
							
							<div className='col-12'>
								<h4 id='generatetext'>Generated Room</h4>
								<div className='row'>
									<div className='col-12' style={{position:"relative"}}>								
										<div id="overlay" className='overlayborder'>
													<div id="loading">
														<h2 id="loaderheading">Generating...</h2>
														<img src={loader} />  
													</div>
										</div>
									</div>
								</div>	
								<div className='rightside-bar'>
								<div className='outputdream'>
									<div className='row'>
										<div className="col-12 top-box">                            
											
											<span className="float-end tooltip1" tooltip="Download" flow="down">
												<a id="download" onClick={this.handleDownload} href='javascript:;'><FontAwesomeIcon icon={faCircleDown} /></a>
											</span>
										</div>
										<div className='col-12'>
											<img id='GImage' src=""/>
										</div>
									</div>
								</div>
								<div className='outputdream'>
									<div className='row'>
										<div className="col-12 top-box">                            
											
											<span className="float-end tooltip1" tooltip="Download" flow="down">
												<a id="download1" onClick={this.handleDownload} href='javascript:;'><FontAwesomeIcon icon={faCircleDown} /></a>
											</span>
										</div>
										<div className='col-12'>
											<img id='GImage1' src=""/>
										</div>
									</div>
								</div>
								<div className='outputdream'>
									<div className='row'>
										<div className="col-12 top-box">                            
											
											<span className="float-end tooltip1" tooltip="Download" flow="down">
												<a id="download2" onClick={this.handleDownload} href='javascript:;'><FontAwesomeIcon icon={faCircleDown} /></a>
											</span>
										</div>
										<div className='col-12'>
											<img id='GImage2' src=""/>
										</div>
									</div>
								</div>
								<div className='outputdream'>
									<div className='row'>
										<div className="col-12 top-box">                            
											<span className="float-end tooltip1" tooltip="Download" flow="down">
												<a id="download3" onClick={this.handleDownload} href='javascript:;'><FontAwesomeIcon icon={faCircleDown} /></a>
											</span>
										</div>
										<div className='col-12'>
											<img id='GImage3' src=""/>
										</div>
									</div>
								</div>

								</div>
							</div>


						</div>
					</div> 
					</div>
					
					
					{/* <div className='row' id='outputdiv' style={{display:'none'}}>
						<div className='col-12 remodeledtheme'>
							
							<div className='row'>
								<div className='col-6'>	
									<h4>Original</h4>							
									<img id='srcImg' src={this.state.processedimage}/>
								</div>
								<div className='col-6'>
									<h4 id='generatetext'>Generated Room</h4>
									<div className='row'>
										<div className='col-12' style={{position:"relative"}}>								
											<div id="overlay" className='overlayborder'>
														<div id="loading">
															<h2 id="loaderheading">Generating...</h2>
															<img src={loader} />  
														</div>
											</div>
										</div>
									</div>							
									<img id='GImage' src=""/>
								</div>
								<div className='col-12'>
									<center>
										<a href='javascript:;' onClick={this.handleDownload} id='DonloadImg' className='common-save'>Download Generated Room</a>
										<a href="javascript:;" onClick={this.reset} className="common-clear">Back</a>
									</center>
								</div>
							</div>
						</div>
				  	</div>        */}

				  </form>

				</div>
			  </div>
			)
		}
    
};

export default Transformation;