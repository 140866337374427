import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, { useState } from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader, { List } from "react-content-loader";


// const MyLoader = () => <List />
const MyLoader = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

const MyLoader1 = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

class sherlocksetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyworddisabled: true,
            tonedisabled: true,
            copySuccess: '',
            wordcount: 0,
            Tonecount: 0,
            generatedimages: [],
            emailguid: NIL_UUID,
            emailhtml: "",
            defaultcontenterased: false,
            tabledata: [],
            dashtotalcount: -1,
            dashrecentimagescount: -1,
            dashauthorizedcount: -1,
            dashunauthorizedcount: -1,
            dashlastupdated: ""


        }
    }
    componentDidMount() {
        //this.loadtabledata();
        // this.setState({emailguid: uuidv4()});
        this.jquerycode();
        this.gettoppaneldata();
        // setInterval(() => {			
        // 	this.checkandupdatecontent();
        //   }, 10000);
    }


    jquerycode = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('1').classList.add("active");
    }


    historygoback = () => {
        window.history.back();
    }

    gettoppaneldata = () => {
        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` + '/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.Status == "200") {
                    var data = response.data.Data;
                    this.setState({
                        dashtotalcount: data.Totalcount,
                        dashrecentimagescount: data.Recentimagescount,
                        dashauthorizedcount: data.Authorizedcount,
                        dashunauthorizedcount: data.Unauthorizedcount,
                        dashlastupdated: new Date(data.Lastupdated).toString()
                    })
                }
                else {

                }

            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4><span style={{ cursor: "pointer" }} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} /><u>Sherlock AI Set Up:</u> MLS Integration</span></h4>
                                    <p>
                                        To connect your MLS data, please enter your MLS credentials below. Provide the name of your MLS along with your username and password. This will allow us to link to your MLS and pull in all of your latest listings automatically. The name you give this connection will be how it is identified in your account going forward. Please make sure the credentials you enter are correct and up-to-date. If your MLS username or password changes in the future, you can simply update the information here to ensure continued access to your MLS data. Entering the details accurately now will enable a smooth and ongoing data integration.</p>
                                </span>
                            </div>
                        </div>

                        <div className='col-6' style={{ marginBottom: "20px" }}>
                            <div className='row'>
                                <div className='col-12 mb-30'>
                                    <label>MLS Name</label>
                                    <input id="txtFN" type="text" className='common-input' />
                                </div>
                                <div className='col-12 mb-30'>
                                    <label>MLS URL:</label>
                                    <input id="txtLN" type="text" className='common-input' />
                                </div>
                                <div className='col-12 mb-30'>
                                    <label>MLS UserName:</label>
                                    <input id="txtMN" type="text" className='common-input' />
                                </div>
                                <div className='col-12 mb-30'>
                                    <label>MLS Password:</label>
                                    <input id="txtMN" type="text" className='common-input' />
                                </div>
                                <div className='col-12 mb-30 text-center'>
                                    <a href="javascript:;" className="common-save common-save-grey" style={{marginRight:"10px"}}>Cancel</a>
                                    <a href="javascript:;" className="common-save">Save</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        )
    }

}

export default sherlocksetup;