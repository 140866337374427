import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove, faImage, faCloudArrowUp, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, { useState } from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg } from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader, { List } from "react-content-loader";


// const MyLoader = () => <List />
const MyLoader = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

const MyLoader1 = () => (
    <ContentLoader
        speed={1}
        width={200}
        height={20}
        viewBox="0 0 200 20"
        backgroundColor="#808080"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
    >
        <rect x="0" y="8" rx="3" ry="3" width="88" height="10" />
        {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
        {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
        {/* <circle cx="20" cy="20" r="20" /> */}
    </ContentLoader>
);

class sherlocklivenew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyworddisabled: true,
            tonedisabled: true,
            copySuccess: '',
            wordcount: 0,
            Tonecount: 0,
            generatedimages: [],
            emailguid: NIL_UUID,
            emailhtml: "",
            defaultcontenterased: false,
            tabledata: [],
            dashtotalcount: -1,
            dashrecentimagescount: -1,
            dashauthorizedcount: -1,
            dashunauthorizedcount: -1,
            dashlastupdated: ""


        }
    }
    componentDidMount() {
        //this.loadtabledata();
        // this.setState({emailguid: uuidv4()});
        this.jquerycode();
        this.gettoppaneldata();
        // setInterval(() => {			
        // 	this.checkandupdatecontent();
        //   }, 10000);
    }


    jquerycode = () => {
        var self = this;
        Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function (el) {
            el.classList.remove('active');
        });
        document.getElementById('1').classList.add("active");
    }


    historygoback = () => {
        window.history.back();
    }
    infoshow = () => {
        document.getElementById('popuptextinfoshow').style.display = "block";
    }
    infohide = () => {
        document.getElementById('popuptextinfoshow').style.display = "none";
    }

    gettoppaneldata = () => {
        const axios = require('axios');
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` + '/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.Status == "200") {
                    var data = response.data.Data;
                    this.setState({
                        dashtotalcount: data.Totalcount,
                        dashrecentimagescount: data.Recentimagescount,
                        dashauthorizedcount: data.Authorizedcount,
                        dashunauthorizedcount: data.Unauthorizedcount,
                        dashlastupdated: new Date(data.Lastupdated).toString()
                    })
                }
                else {

                }

            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {
        return (
            <div className="main_content_iner overly_inner">
                <div className="container-fluid p-0 ">
                    <div className="row">
                        <div className="col-12" style={{ marginBottom: "20px" }}>
                            <div className="title-card1">
                                <span className="float-start">
                                    <h4><span style={{ cursor: "pointer" }} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} /><u>Live Track</u></span></h4>
                                </span>
                            </div>
                        </div>

                        <div className='col-12' style={{ marginBottom: "30px" }}>
                            <ul className='sherlocklivetab'>
                                <li className='active' onClick={this.totalphotos}><a>TOTAL PHOTOS: <span>1</span></a></li>
                                <li onClick={this.webhits}><a>WEB HITS: <span>1</span></a></li>
                                <li onClick={this.unauthorized}><a>UNAUTHORIZED DOMAIN MATCHES: <span className='loadingellipsis'>Searching</span></a></li>
                            </ul>
                        </div>

                        <div className='col-12' id='totalphotos'>
                            <div className='row' style={{ justifyContent: "center" }}>
                                <div className='col-6' style={{ marginBottom: "20px" }}>
                                    <div className='row'>
                                        <div className='col-12' style={{ marginBottom: "30px" }}>
                                            <div className='row' style={{ position: "relative" }}>
                                                <div className='col-lg-3 col-md-3'>
                                                    <label style={{ paddingTop: "15px" }}>Image URL:</label>
                                                </div>
                                                <div className='col-lg-8 col-md-8'>
                                                    <input id="txtFN" type="text" className='common-input' />
                                                </div>
                                                <div className='col-lg-1 col-md-1'>
                                                    <FontAwesomeIcon icon={faQuestionCircle} className='questioncirclelive' onMouseOver={this.infoshow} onMouseLeave={this.infohide} />
                                                    <div className='popuptextinfo' style={{ display: "none" }} id="popuptextinfoshow">
                                                        <p>
                                                            To find the URL for an image on any webpage, follow these simple steps:
                                                            <ul>
                                                                <li>Right click on the image you want to get the URL for.</li>
                                                                <li>In the menu that pops up, click "Copy image address" or "Copy image link" (this may vary slightly depending on your browser).</li>
                                                                <li>This will copy the direct URL to that image onto your clipboard.</li>
                                                                <li>You can then paste this image URL to the Image URL box.</li>
                                                                <li>The image URL will start with "https://" and end with the file extension, like ".jpg" or ".png". This direct link points to the actual image file location online.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <center><p>OR</p></center>
                                        </div>
                                        <div className='col-12' style={{ marginBottom: "30px" }}>
                                            <div className="dropzone-wrapper dropzone-wrapper-new">
                                                <div id="overlayiphone">
                                                    <div id="loading" style={{ top: "35%" }}>
                                                        <h2 id="loaderheading">Generating...</h2>
                                                        <img src={loader} />
                                                    </div>
                                                </div>
                                                <div className="dropzone-desc">
                                                    <h4>Drag and Drop</h4>
                                                    <p>
                                                        <b>or Click to upload</b>
                                                    </p>
                                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                                    <p>Supported Files: PNG, JPG, HEIF, HEIC.</p>
                                                </div>
                                                <input
                                                    type="file"
                                                    name="img_logo"
                                                    className="dropzone"
                                                    accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className='col-12' style={{ marginBottom: "30px" }}>
                                            <ul className="mlsuploadedphoto">
                                                <li key={uuidv4()}>
                                                    <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=600" />
                                                    <span className="removeuploaded">
                                                        <FontAwesomeIcon icon={faRemove} />
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='col-12 mb-30 text-center'>
                                            <a href="javascript:;" className="common-save common-save-grey" style={{ marginRight: "10px" }}>Clear</a>
                                            <a href="javascript:;" className="common-save">Continue</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12' style={{ display: "none" }} id="webhits">
                            <div className='row'>
                                <div className='col-12'>
                                    <h5>Matches Found</h5>
                                </div>
                                <div className='col-12 matchfoundcss'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p>Result: 1</p>
                                        </div>
                                        <div className='col-6'>
                                            <img src="https://cdn-5.urmy.net/images/plans/URD/bulk/6583/the-destination-exterior-rear-3.jpg" />
                                        </div>
                                        <div className='col-6'>
                                            <p><b>Page title:</b> Beautiful Two Story Modern Style House Plan 6583</p>
                                            <p><b>Match found:</b> https://www.dfdhouseplans.com/plan/6583/</p>
                                            <p><b>Match Type:</b> Full</p>
                                        </div>
                                        <div className='col-12'>
                                            <hr className='bordermfline'></hr>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 matchfoundcss'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <p>Result: 2</p>
                                        </div>
                                        <div className='col-6'>
                                            <img src="https://cdn-5.urmy.net/images/plans/URD/bulk/6583/the-destination-exterior-rear-3.jpg" />
                                        </div>
                                        <div className='col-6'>
                                            <p><b>Page title:</b> Beautiful Two Story Modern Style House Plan 6583</p>
                                            <p><b>Match found:</b> https://www.dfdhouseplans.com/plan/6583/</p>
                                            <p><b>Match Type:</b> Full</p>
                                        </div>
                                        <div className='col-12'>
                                            <hr className='bordermfline'></hr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12' id="unauthorizeddomain">
                            
                        </div>





                    </div>
                </div>
            </div>
        )
    }

}

export default sherlocklivenew;