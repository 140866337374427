import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name} from "../User";
import { faEdit, faMailBulk, faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
// import ExternalInfo from "components/ExternalInfo";

const TeamsNew = () => {
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No"},
        { name: "Team Name", field: "TeamName", sortable: true },        
        { name: "No. of users", field: "UsersCount", sortable: false },        
        { name: "Created On", field: "CreatedOn", sortable: true },
        { name: "Action"}
    ];

    useEffect(() => {
        const getData = () => {
            showLoader();

            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamsWithCount?UserId="+UserID,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
        
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //
                            
                            // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                            setComments(response.Data);
                            console.log('Inside show teams response');
                            console.log(response);                            
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });
        };

        getData();
    }, []);

    const getTeamsData = () => {
        showLoader();

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamsWithCount?UserId="+UserID,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //
                        
                        // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                        setComments(response.Data);
                        console.log('Inside team users response');
                        console.log(response);                            
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.TeamName.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    const deleteTeamData = (e) => {
        var teamId = e.currentTarget.dataset.refid;           
        var result = window.confirm("Are you sure you want to delete this team?");
            if (!result) {
                return;
            }
            showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteTeam?TeamId="+teamId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Data != null && response.Data != "") {
                        //team created load  all teams
                        customAlertMessage('success', "Team deleted successfully");
                        getTeamsData();                    
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }

      
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      
      const updateTeam = () => {

        var txtTeamName = document.getElementById('txtTeamName');
        if (txtTeamName.value == "") {
          customAlertMessage("error", "Please enter team name.");
          return;
        }
        //CreateTeam(string TName,string UserId)
        showLoader();
        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/CreateTeam?TName="+txtTeamName.value+"&UserId="+UserID,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null && response.Data != "") {
                        //team created load  all teams
                        customAlertMessage('success', "Team added successfully");
                        txtTeamName.value = "";      
                        getTeamsData();                  
                    }
                    else {                        
                        customAlertMessage('error', "Failed, Please try again.");
                    }
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) { 
                hideLoader();
                customAlertMessage('error', 'Something went wrong, Try Again.');                
            });
        }

        const setupdateTeamData = (teamName,teamId) => {
            document.getElementById("txtUpdateName").value = teamName;
            document.getElementById("txtUpdateId").value = teamId;
            document.getElementById("hrefUpdatePopup").click();
        }

        const updateTeamData = () => {

            var txtTeamName = document.getElementById('txtUpdateName');
            if (txtTeamName.value == "") {
              customAlertMessage("error", "Please enter team name.");
              return;
            }
            var teamId = document.getElementById("txtUpdateId").value;
            //CreateTeam(string TName,string UserId)
            showLoader();
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/UpdateTeam?TName="+txtTeamName.value+"&TeamId="+teamId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null && response.Data != "") {
                            //team created load  all teams
                            customAlertMessage('success', "Team updated successfully");
                            txtTeamName.value = ""; 
                            document.getElementById("txtUpdateId").value ="";
                            document.getElementById("btnCloseUpdateTeam").click();
                            getTeamsData();                  
                        }
                        else {                        
                            customAlertMessage('error', "Failed, Please try again.");
                        }
                    }
                    else {
                        customAlertMessage('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) { 
                    hideLoader();
                    customAlertMessage('error', 'Something went wrong, Try Again.');                
                });
            }

    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}

            <div className='row'>
                <div className='col-12'>
                    <div className="row">
                        <div className='col-6'>
                            <label>Team Name: </label>
                            <input id="txtTeamName" type="text" className='common-input'/> &nbsp;
                        </div>
                        <div className='col-6'>
                            <a  href='javascript:;' tabIndex={-1} onClick={updateTeam} style={{marginTop:"25px"}} className="common-save float-start"> + Add Team</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <br></br>
                </div>
                <div className="col-12 rightside-bar-editor">

                    <div className="row w-100">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row">
                                <div className="col-md-6">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3" style={{marginTop:'9px',textAlign:"left"}}>
                                    <b><label>{totalItems > 0 ? totalItems + " Teams Found": ""} <span> </span></label></b>
                                </div>
                            </div>
                            <div className="row">
                            <div className="rightside-bar pr5 nominheight">
                            <table className="table table-hover common-table">
                                <Header
                                    headers={headers}
                                    onSorting={(field, order) =>
                                        setSorting({ field, order })
                                    }
                                />
                                <tbody>
                                {commentsData.length > 0 ? commentsData.map((comment,index) => (

                                    <tr>
                                    <td>{((currentPage - 1) * ITEMS_PER_PAGE) + index+1}</td>
                                    <td style={{cursor:"pointer"}}>
                                        <Link to={"/teamdetails?tId="+comment.TeamId+"&tN="+comment.TeamName} >{comment.TeamName}</Link> &nbsp;
                                        <a style={{color:'blue',cursor:'pointer'}} href="javascript:;" onClick={() => setupdateTeamData(comment.TeamName,comment.TeamId)} ><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></a>                                
                                    </td>
                                    <td><span>{comment.UsersCount}</span></td>
                                    <td>{moment.utc(comment.CreatedOn).local().format('MM-DD-YYYY h:mm A')}</td>
                                    <td>
                                        <span style={{color:'red',cursor:'pointer'}} data-refid={comment.TeamId} onClick={deleteTeamData}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>                                
                                    </td>
                                    </tr>
                                )
                                ): null}
                                
                                </tbody>
                            </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

{/* modal popup start */}
<a href="javascript:;" style={{display:"none"}} id="hrefUpdatePopup" className="common-save" data-bs-toggle="modal" data-bs-target="#teamdetailModal">Update Team Link</a>
<div className="modal fade" id="teamdetailModal" tabIndex={-1} aria-labelledby="teamdetailModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="teamdetailLabel">Update Team</h5>
                <button type="button" id="btnCloseUpdateTeam" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body" style={{display:"flex",justifyContent:"center",alignItems:"center",minHeight:"100px"}}>   
                <div className="col-12">
                    <div className="row">
                        <div className="col-2">
                            <label style={{marginTop:"5px"}}>Team Name:</label>                        
                        </div>
                        <div className="col-6">
                            <input type="hidden" id="txtUpdateId"></input>
                            <input type="text" className="form-control" id="txtUpdateName"></input> &nbsp;                            
                        </div>                        
                    </div>
                    <div className="row">
                    <div className="col-2"></div>
                        <div className="col-3">
                            <a  href='javascript:;' tabIndex={-1} onClick={updateTeamData} className="common-save pad-reduce"> Update</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* modal popup end */}

            {loader}
        </>
    );
};

export default TeamsNew;