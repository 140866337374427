import Select from "react-select";
import "bootstrap/dist/css/bootstrap.css";

const DDLinspiration = () => {
    const options = [
        { value: "-1", label: "None" },
        { value: "1", label: "Caravaggio" },
        { value: "2", label: "da Vinci" },
        { value: "3", label: "Henri Matisse" },
        { value: "4", label: "Jackson Pollock" },
        { value: "5", label: "Michelangelo" },
        { value: "6", label: "Monet" },
        { value: "7", label: "Picasso" },
        { value: "8", label: "Van Gogh" },
      ];
  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#212529",
      padding: "10px",
      border: "none",
      boxShadow: "none",
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "#fff" }),
  };

  return (
    // <Select options={options} styles={customStyles} />
    <Select id="ddlinspiration" menuPlacement="top" options={options} maxMenuHeight={200} />
  );
};

export default DDLinspiration;