export const passwordregex6digits = new RegExp("^(?=.{6,})");
export const passwordregexLowercase = new RegExp("^(?=.*[a-z])");
export const passwordregexUppercase = new RegExp("^(?=.*[A-Z])");
export const passwordregexNumber = new RegExp("^(?=.*[0-9])");
export const passwordRegexSpecial = new RegExp("^(?=.*[!@#$%^&*])");
export const passwordRegexAll = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})");
export const fadeIn = (element, duration) => {
    (function increment(value = 0) {
        element.style.opacity = String(value);
        if (element.style.opacity !== '1') {
            setTimeout(() => {
                increment(value + 0.1);
            }, duration / 10);
        }
    })();
};

export const fadeOut = (element, duration) => {
    (function decrement() {
        (element.style.opacity -= 0.1) < 0 ? element.style.display = 'none' : setTimeout(() => {
            decrement();
        }, duration / 10);
    })();
};