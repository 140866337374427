import React, { useEffect, useState, useMemo } from "react";
// import Pagination from "react-bootstrap/Pagination";
import "../Pagination/index.less";
//import "rc-select/assets/index.less"
import Pagination from 'rc-pagination';

const PaginationComponent = ({
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);
    let ellipsisPosition = [];

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);


    const paginationItems = useMemo(() => {
        const pages = [];
        let ellipsisIndex = null; // Variable to store the index of the ellipsis

        // Function to add ellipsis
        const addEllipsis = (start, end) => {
            const position = Math.ceil((start + end) / 2); // Calculate position of the ellipsis
            ellipsisIndex = pages.length; // Update index of the ellipsis
            pages.push(
                <Pagination.Ellipsis key={start + end} onClick={() => onPageChange(position)} />
            );
        };

        // Add first page
        pages.push(
            <Pagination.Item
                key={1}
                active={1 === currentPage}
                onClick={() => onPageChange(1)}
            >
                {1}
            </Pagination.Item>
        );

        // Add pages and ellipsis in the middle
        if (totalPages > 1) {
            if (currentPage <= 3) {
                for (let i = 2; i <= Math.min(5, totalPages - 1); i++) {
                    pages.push(
                        <Pagination.Item
                            key={i}
                            active={i === currentPage}
                            onClick={() => onPageChange(i)}
                        >
                            {i}
                        </Pagination.Item>
                    );
                }
                if (totalPages > 6) {
                    addEllipsis(5, totalPages);
                }
            } else if (currentPage >= totalPages - 2) {
                if (totalPages > 6) {
                    addEllipsis(2, totalPages - 4);
                }
                for (let i = Math.max(2, totalPages - 4); i <= totalPages - 1; i++) {
                    pages.push(
                        <Pagination.Item
                            key={i}
                            active={i === currentPage}
                            onClick={() => onPageChange(i)}
                        >
                            {i}
                        </Pagination.Item>
                    );
                }
            } else {
                addEllipsis(currentPage - 2, currentPage + 2);
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    pages.push(
                        <Pagination.Item
                            key={i}
                            active={i === currentPage}
                            onClick={() => onPageChange(i)}
                        >
                            {i}
                        </Pagination.Item>
                    );
                }
                addEllipsis(currentPage + 2, totalPages - 1);
            }
        }

        // Add last page
        if (totalPages > 1) {
            pages.push(
                <Pagination.Item
                    key={totalPages}
                    active={totalPages === currentPage}
                    onClick={() => onPageChange(totalPages)}
                >
                    {totalPages}
                </Pagination.Item>
            );
        }

        // Check if there are multiple ellipses and remove extra ellipses
        if (ellipsisIndex !== null && ellipsisIndex < pages.length - 1) {
            pages.splice(ellipsisIndex + 1, pages.length - ellipsisIndex - 1);
        }

        return pages;
    }, [totalPages, currentPage]);
  

    if (totalPages === 0) return null;

    return (
        <>
        {/* <Pagination>
            <Pagination.First onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
            />
            <Pagination.Prev
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            />
            {paginationItems}
            <Pagination.Next
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            />
             <Pagination.Last onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
            />
        </Pagination> */}

<Pagination onChange={onPageChange} current={currentPage} total={total} locale="en_US" />
</>
    );
};

export default PaginationComponent;