import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAnglesRight,
    faChevronLeft,
    faMicrophone,
    faInfo,
    faInfoCircle,
    faRotateRight,
    faShield,
    faCloudArrowUp,
    faCircleDown,
    faFile,
    faTimes,
    faCopy,
    faImage,
    faCheck,
    faCross,
    faXmark,
    faRemove
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import copy from 'copy-to-clipboard';
import { UserID, Name, Companyid, Role, Rolename, ProfileImg, MasterId } from "../User";
import fileDownload from "js-file-download";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import loader from "../img/sample.gif";
import loadernew from "../img/loader1.gif";
// import withReactContent from 'sweetalert2-react-content'
import Propertytype from "./propertytype";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
//import SearchLocationInput from "./SearchLocationInput";
import * as axios from 'axios';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
import 'react-dropdown/style.css';
import { ToggleButton } from "react-bootstrap";
import PropDescImg from '../img/19.jpg';
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import { Draggable } from "react-drag-reorder";
import Sortable from 'sortablejs';
import heic2any from "heic2any";
import { n2d } from "n2d";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

var outsideUploaded = [];

const Create3dmodel = () => {

    const [processedimage, setprocessedimage] = useState([]);
    const [uploadedfile, setuploadedfile] = useState([]);
    const [orderedprocessedimage, setorderedprocessedimage] = useState([]);
    const [finaluploadedfile, setfinaluploadedfile] = useState([]);
    const [isVideoData, setisVideoData] = useState(["0"]);
useEffect(()=>{
    console.log('processedimage');
    console.log(processedimage);
},[processedimage])
    const historygoback = () => {
        window.history.back();
    };
    
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
        });
    
        Toast.fire({
            icon: icon, //'success',
            title: message, // 'Signed in successfully'
        });
    };
    
    const showoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "block";
        $("body").addClass("loader");
    };
    
    const hideoverlaytwothree = () => {
        document.getElementById("overlaytwothree").style.display = "none";
        $("body").removeClass("loader");
    };

    const showoverlaytwothree = () => {
        document.getElementById("overlaytwothree").style.display = "block";
        $("body").addClass("loader");
    };
    
    const hideoverlaytwo = () => {
        document.getElementById("overlaytwo").style.display = "none";
        $("body").removeClass("loader");
    };
    
    const dropzonedragenter = (e) => {
        e.preventDefault();
            e.stopPropagation();
            $('.dropzone-wrapper').addClass('dragover');
    }
    const dropzonedragover = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').addClass('dragover');
    };
    const dropzonedragleave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $('.dropzone-wrapper').removeClass('dragover');
    };
    
    const create3dVideofn = () =>{

        var txtTitle = document.getElementById('txtTitle').value;
        if (txtTitle ==="") {
            customAlertMessage('error','Please enter title.');
            return;
        }
        if (processedimage.length <=0) {
            customAlertMessage('error','Please upload images or one video.');
            return;
        }
        var hfIsVideo =document.getElementById('hfIsVideo').value;
        if(hfIsVideo == "1"){

        }
        else{        
            if (processedimage.length > 0 && processedimage.length <=10) {
                customAlertMessage('error','Please upload more than 10 images.');
                return;
            }
            if (processedimage.length > 0 && processedimage.length > 30) {
                customAlertMessage('error','Please upload images between 11 and 30.'); 
                return;
            }
        }
        console.log('outsideUploaded');
        console.log(processedimage);
        let data = new FormData();
        //var insideArray = [outsideUploaded.length];
        if(hfIsVideo == "1"){
            data.append("Images",processedimage[0]);
        }
        else{
            for (let index = 0; index < processedimage.length; index++) {
                data.append("Images",processedimage[index].binaryfile);       
            }
        }
        //console.log('insideArray');
        //console.log(insideArray);
        
        data.append('title', txtTitle.trim());
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);
showoverlaytwo();
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatapi.aelo.ai/api/AIUserManager/createCapture',
            data: data
        };
        axios(config)
        .then(function (resp) {
            console.log(resp);
            var response = resp.data;
            console.log(response.Data);
//debugger;
            if (resp != null && response != "" && response.Data !="" && response.Data !=" " && resp.Status != "404") {            
                document.getElementById('hfSlugVaalue').value = response.Data;
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="block";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="none";
                document.getElementById('spanStatus').innerText="Status: Queued";
                //document.getElementById('spanProgress').innerText="Status: Queued";
                hideoverlaytwo();
            }
            else if(resp !=null && (resp.data.Status == "404" || resp.data.Status == "201")) {
                hideoverlaytwo();
                customAlertMessage(
                    "error",
                    "Please try again by clicking on 'Generate' button."
                );
            }
        })

        .catch(function (error) {
            console.log(error);
            hideoverlaytwo();
            customAlertMessage(
                "error",
                "Something went wrong, please try again."
            );
        });

        //end of axis
    }

    const triggerNerfCaptureRefresh = () =>{
        window.location.href="/";
    }

    const triggerNerfCapture = () =>{

        var hfSlugVaalue = document.getElementById('hfSlugVaalue').value;
        
        let data = new FormData();
        //var insideArray = [outsideUploaded.length];
        for (let index = 0; index < outsideUploaded.length; index++) {
            data.append("Images",outsideUploaded[index]);       
        }
        //console.log('insideArray');
        //console.log(insideArray);
        showoverlaytwothree();
        data.append('slug', hfSlugVaalue.trim());
        data.append('uid', (Role == 3 ? MasterId : UserID));
        data.append('cid', Companyid);

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://uatapi.aelo.ai/api/AIUserManager/GetCapture',
            data: data
        };
        axios(config)
        .then(function (resp) {
            console.log(resp);
            var response = resp.data;
            console.log(response.Data);
//debugger;
            if (resp != null && response != "" && response.Data !="" && response.Data !=" " && response.Status == "200") {                            
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="none";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="block";
                document.getElementById('spanStatus').innerText="Status: Completed";
                document.getElementById('spanProgress').innerText="";
                document.getElementById('videoSource').src = response.Data;
                document.getElementById('videoSourceMain').load();
                document.getElementById('spanOutputUrl').innerText="Output video url " + response.Data;
                hideoverlaytwothree();
                // video tag data 
            }
            else if(response !=null && (response.Data !="" && response.Status == "201")) {
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="block";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="none";
                document.getElementById('spanStatus').innerText="Status: Queued";
                document.getElementById('spanProgress').innerText="Progress ==> " + response.Data + "%";
                hideoverlaytwothree();
            }
            else if(response.Status == "201") {
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="block";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="none";
                document.getElementById('spanStatus').innerText="Status: Queued";
                document.getElementById('spanProgress').innerText="Progress ==> " + (response != null && response.Data !=null &&  response.Data !="") ? (response.Data + "%") : ""; 
                hideoverlaytwothree();
            }
            else if(response.Status == "202") {
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="none";
                document.getElementById("divNewTrigger").style.display="block";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="none";
                document.getElementById('spanStatus').innerText="Status: Failed";
                document.getElementById('spanProgress').innerText= "Reason ==> " + (response != null && response.Data !=null &&  response.Data !="") ? response.Data : "Camera pose estimation failed: failed to converge";
                hideoverlaytwothree();
            }
            else{
                document.getElementById("uploadimages").style.display="none";
                document.getElementById("divGenerate").style.display="none";
                document.getElementById("divTrigger").style.display="block";
                document.getElementById("divProgress").style.display="block";
                document.getElementById("divOutput").style.display="none";            
                document.getElementById('spanProgress').innerText= "Something went wrong.";
                hideoverlaytwothree();
            }
        })

        .catch(function (error) {
            console.log(error);
            hideoverlaytwothree();
            customAlertMessage(
                "error",
                "Something went wrong, please try again."
            );
        });

        //end of axis
    }
    
    const readFileondrop = (inputfiles) => {
        //var input = $('.dropzone')[0];
        // var inputfiles = input.files;
        var singletimearray = []; // processedimage;
        var propertyorderedlist = [];// [...orderedprocessedimage];
        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id = timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                // if (orderedprocessedimage.length > 0) 
                {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                     var processedimagenew = singletimearray.sort(function (a, b) { return a.id - b.id; });
                    // console.log(_processedimage);
                    // var processedimagenew = [...singletimearray];
                    // setprocessedimage([...processedimagenew]);
                    // setorderedprocessedimage([...propertyorderedlist]);
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 4000);
                    // var wrapperZone = $('.dropzone-wrapper');
                    // var previewZone = $('.preview-zone');
                    // wrapperZone.removeClass('dragover');
                    // previewZone.removeClass('hidden');
                    // $('.dropzone-wrapper').addClass('hidden');
                }
                // var _processedimage = singletimearray.sort(function(a,b) {return b.id - a.id;});
                // setprocessedimage(_processedimage);
    
            };
            reader.readAsDataURL(element);
        }
    }
    
    const dropzonefiledrop = (event) => {
        event.preventDefault();
        var tempfilesarr = event.dataTransfer.files;
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        outsideUploaded = [];
        if(tempfilesarr[0].type === "video/mp4"){            
            readVideoFile(tempfilesarr[0]);
            return;
        }
        else{
            setisVideoData("0");
        }
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png"  || element.type === "video/mp4")
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 10485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);
    
                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                    outsideUploaded.push(element);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);
    
                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFileondrop(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
                                }
                            });
    
                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFileondrop(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
        }
    };
    
    const dropzonechange = (e)=> {
        var tempfilesarr = e.target.files;// $(".dropzone")[0].files;
        debugger;
        if (tempfilesarr.length <= 0) {
            return;
        }
        if(tempfilesarr[0].type === "video/mp4"){            
            readVideoFile(tempfilesarr[0]);
            return;
        }
        else{
            setisVideoData("0");
        }
        var tempfileaftervalidation = [];
        var uploadedfilearray = [];// uploadedfile;
        var containsiphonefiles = false;
        var invalidfilecount = 0;
        outsideUploaded = [];
        for (let i = 0; i < tempfilesarr.length; i++) {
            const element = tempfilesarr[i];
            console.log('element.type ' + element.type);
            console.log('element.size ' + element.size);
            var validfile = (element.type === 'image/jpg' || element.type === 'image/jpeg' || element.type === "image/png" || element.type === 'video/mp4')
            if (!validfile && element.name.split('.').pop() != "heif" && element.name.split('.').pop() != "heic") {
                customAlertMessage('error', 'File type not supported files removed.');
                invalidfilecount += 1;
                continue;
            }
            if (element.size < 100485760) {
                var uploadedfile_json = {};
                var tempfileaftervalidation_json = {};
                if (element.name.split('.').pop() != 'heif' && element.name.split('.').pop() != 'heic') {
                    // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                    // if (alreadyuploadedfile.length > 0) {
                    //     continue;
                    // }
                    var uniqueid = uuidv4();
                    uploadedfile_json.identity = uniqueid;
                    uploadedfile_json.binaryfile = element;
                    uploadedfilearray.push(uploadedfile_json);
    
                    tempfileaftervalidation_json.identity = uniqueid;
                    tempfileaftervalidation_json.binaryfile = element;
                    tempfileaftervalidation.push(tempfileaftervalidation_json);
                    outsideUploaded.push(element);
                    console.log('file added: '+ element.name);
                }
                else {
                    containsiphonefiles = true
                }
            }
            else {
                customAlertMessage('error', 'Maximum file size exceeded images removed.');
                invalidfilecount += 1;
            }
        }
        if (containsiphonefiles) {
            for (let i = 0; i < tempfilesarr.length; i++) {
                const element = tempfilesarr[i];
                if (element.name.split('.').pop() == "heif" || element.name.split('.').pop() == "heic") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        showoverlaytwo();
                        fetch(e.target.result)
                            .then((res) => res.blob())
                            .then((blob) => heic2any({ blob, toType: "image/jpeg", quality: 0.1 }))
                            .then((conversionResult) => {
                                // conversionResult is a BLOB
                                // of the PNG formatted image
                                var url = URL.createObjectURL(conversionResult);
                                var file = new File([conversionResult], uuidv4() + ".jpg");
                                // var alreadyuploadedfile = uploadedfile.filter(function (entry) { return entry.binaryfile == element; });
                                // if (alreadyuploadedfile.length <= 0) {
                                //     continue;
                                // }
                                var uploadedfile_json = {};
                                var tempfileaftervalidation_json = {};
                                var uniqueid = uuidv4();
                                uploadedfile_json.identity = uniqueid;
                                uploadedfile_json.binaryfile = file;
                                uploadedfilearray.push(uploadedfile_json);
    
                                tempfileaftervalidation_json.identity = uniqueid;
                                tempfileaftervalidation_json.binaryfile = file;
                                tempfileaftervalidation.push(tempfileaftervalidation_json);
                                console.log('file added iphone: '+ file.name);
                                console.log('files added : '+ tempfileaftervalidation.length);
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    console.log('fired to load inside iphone')
                                    setTimeout(() => {
                                        readFile(tempfileaftervalidation);
                                        setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);    
                                    }, 2000);
                                }
                            })
                            .catch((e) => {
                                // see error handling section
                                //customAlertMessage('error','Error occured while processing an image, please try again.'); 
                                // hideoverlaytwo();
                                invalidfilecount += 1;
                                if (tempfilesarr.length <= (invalidfilecount + uploadedfilearray.length)) {
                                    readFile(tempfileaftervalidation);
                                    setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);  
                                }
                            });
    
                    }
                    reader.readAsDataURL(element);
                }
            }
        }
        else {
            readFile(tempfileaftervalidation);
            setuploadedfile(uploadedfile => [...uploadedfile, ...uploadedfilearray]);
            console.log('fired to load outside iphone')
        }
    };

    const readFile = (inputfiles) => {
        // var input = $('.dropzone')[0];
        // var inputfiles = input.files;
        var singletimearray = [];// [...processedimage];
        var propertyorderedlist = [];// [...orderedprocessedimage];
        console.log('inputfiles.length',inputfiles.length);

        for (let i = 0; i < inputfiles.length; i++) {
            const element = inputfiles[i].binaryfile;
            console.log('reading inputfiles : ',element.name);
            var reader = new FileReader();
            reader.onload = function (e) {
                var uniqimg = {};
                var timingidentity = moment().valueOf();
                uniqimg.refid = inputfiles[i].identity;
                uniqimg.id =timingidentity;
                uniqimg.image = e.target.result;
                singletimearray.push(uniqimg);
                // if (orderedprocessedimage.length > 0) 
                {
                    var selem = {};
                    selem.orderid = orderedprocessedimage.length + (i+1);
                    selem.id = timingidentity;
                    selem.refid = inputfiles[i].identity;
                    selem.image = e.target.result;
                    selem.binaryfile = element;
                    propertyorderedlist.push(selem);
                }
                if (i == (inputfiles.length - 1)) {
                    var processedimagenew = propertyorderedlist.sort(function (a, b) { return a.id - b.id; });
                    // var processedimagenew = [...singletimearray];//.splice(0);
                    // console.log(processedimagenew.length);
                    // console.log('checking with r');
                    // console.log([...processedimagenew]);
                    // //setprocessedimage([...processedimagenew]);
                    // console.log('before outsideUploaded updated');
                    // console.log(outsideUploaded);
                    // console.log('after outsideUploaded updated');
                    //outsideUploaded = processedimagenew;
                    //setorderedprocessedimage(orderedprocessedimag => [...orderedprocessedimag , ...propertyorderedlist]);
                    setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
                    showoverlaytwo();
                    setTimeout(() => {
                        loaddropdownfromstate();
                    }, 4000);
                    // var previewZone = $('.preview-zone');
                    // previewZone.removeClass('hidden');
                    // $('.dropzone-wrapper').addClass('hidden');
                }
            };
            reader.readAsDataURL(element);
        }
    }

    const loaddropdownfromstate = () => {
        //divGenerate 
        document.getElementById("divGenerate").style.display="block";
        hideoverlaytwo();
        console.log('after upload hide');
        // console.log(processedimage);
        // console.log(processedimage.length);
        console.log('From outsideUploaded');
        console.log(outsideUploaded);
    }

    
    const removefromlist = (e) => {
        var referenceid = e.currentTarget.dataset.filerefid;
        var filteredprocessimage = processedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setprocessedimage([...filteredprocessimage]);
        var filtereduploadedfile = uploadedfile.filter(function (entry) { return entry.refid !== referenceid; });
        setuploadedfile([...filtereduploadedfile]);
        var  filterorderedprocessedimage = orderedprocessedimage.filter(function (entry) { return entry.refid !== referenceid; });
        setorderedprocessedimage([...filterorderedprocessedimage]);       
    }

    const readVideoFile = (inputFile) => {
        // let reader = new  FileReader();
        // var bufferData = reader.readAsArrayBuffer(inputFile);
        // console.log('buffer data');        
        // let videoBlob = new Blob([new Uint8Array(bufferData)], { type: 'video/mp4' });
        // let url = window.URL.createObjectURL(videoBlob);
        // console.log(videoBlob);
        var videoTag = document.getElementById('videoSourceUploadedFile');
        // videoTag.src = url;    
        // console.log('video blob url '+ url)    
        if (inputFile) {
            var reader = new FileReader();        
            reader.onload = function(e) {
                videoTag.src = e.target.result;
               document.getElementById('videoSourceUploadedFileMain').load();
            }.bind(this)        
            reader.readAsDataURL(inputFile);
        }
        var processedimagenew = [];
        processedimagenew.push(inputFile);
        setprocessedimage(processedimage => [...processedimage , ...processedimagenew]);
        setisVideoData("1");
        setTimeout(() => {
            loaddropdownfromstate();            
        }, 4000);
    }

    // function onFileSelected(e) {
    //     // The file uploaded by the user:
    //     let file = e.target.files[0];
      
    //     // Create a file reader:
    //     let reader = new  FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function(e) {
    //       video.src = e.target.result;
    //     }
    //   }

    return (
        <div className="main_content_iner overly_inner">
            <div className="container-fluid p-0 ">
                <div className="row">

                    <div className="col-12">
                        <div className="title-card1">
                            <span className="float-start" onClick={historygoback}>
                                <h4>
                                    <span
                                        style={{ cursor: "pointer" }}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                        AI VFX
                                    </span>
                                </h4>
                            </span>
                        </div>
                        <input type="hidden" id="hfCurrentTab" value="1"></input>
                        <input type="hidden" id="hfSlugVaalue" value=""></input>
                        <input type="hidden" id="hfIsVideo" value={isVideoData}></input>
                    </div>
                    {/* loader start  */}
                     <div className="col-12 processedimagediv" style={{ position: 'relative' }}>
                        <div id="overlaytwothree" style={{display:"none"}}>
                            <div id="loading" style={{ top: "30%" }}>
                                <h2 id="loaderheading">Generating...</h2>
                                <img src={loader} />
                            </div>
                        </div>
                        </div> 
                     {/* loader end */}

                    {/* content start */}
                    <div className="col-12">
                        <div className="row" id="uploadimages" style={{ display: 'block'  }}>                            
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <label className="prop-lab-title"><b>Title</b><span style={{ color: "red" }}><b>*</b></span></label>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" className="form-control" id="txtTitle"></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                            <div className="dropzone-wrapper" style={{ width: "100%", marginBottom: "40px", marginTop: "25px" }} onDragEnter={dropzonedragenter} onDragOver={dropzonedragover} onDragLeave={dropzonedragleave} onDrop={dropzonefiledrop} >
                                <div className="dropzone-desc">
                                    <h4>Upload between 11 to 30 images or</h4>
                                    <h4>1 mp4 video.</h4>
                                    <h4>Drag and Drop</h4>
                                    <p><b>or Click to upload</b></p>
                                    <FontAwesomeIcon icon={faCloudArrowUp} />
                                    <p>
                                        Supported Files: PNG, JPG, JPEG and MP4 video.
                                    </p>
                                </div>
                                <input type="file" name="img_logo" className="dropzone" onChange={dropzonechange} accept="image/png, image/jpeg, image/jpg, image/heif, image/heic, application/x-zip-compressed, video/mp4" multiple></input>
                            </div>  
                            <div className="col-12 processedimagediv" style={{ position: 'relative' }}>
                                        <div id="overlaytwo">
                                            <div id="loading" style={{ top: "10%" }}>
                                                <h2 id="loaderheading">Generating...</h2>
                                                <img src={loader} />
                                            </div>
                                        </div>
                                        {processedimage.length > 0 && isVideoData == "0" ? processedimage.map((d) => (
                                            <div className="image-drag" key={uuidv4()} style={{ position: "relative" }}>
                                                <img src={d.image} alt="Property image" />
                                                <FontAwesomeIcon icon={faRemove} className="removeimage" data-filerefid={d.refid} onClick={removefromlist}></FontAwesomeIcon>                                                
                                            </div>
                                        )) : null}
                                        {isVideoData == "1" ?
                                        <div id="divVideo" style={{ top: "10%" }}>
                                            <center>
                                            <video width="320" id="videoSourceUploadedFileMain" height="250" controls>
                                                <source src="" id="videoSourceUploadedFile" type="video/mp4"></source>
                                            </video>
                                            </center>
                                        </div>
                                        : null}
                                    </div> 
                            </div>                         
                        </div>
                        <div className="row" id="divGenerate" style={{display:"none",top:"20%"}}>
                            <div className="col-12">
                                <span className="float-end">
                                    <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={create3dVideofn}>Generate 3D Model</a>
                                </span>
                            </div>
                        </div>                        
                        <div className="row" id="divProgress" style={{display:"none"}}>
                            <br></br>
                            <div className="col-12">
                                <span className="float-start" id="spanStatus"></span> <br></br>
                                <span className="float-start" id="spanProgress"></span>
                            </div>                            
                        </div>
                        <div className="row" id="divOutput" style={{display:"none"}}>
                        <br></br>
                            <div className="col-12">
                                {/* video tag */}
                                <span className="float-start" id="spanOutputUrl"></span> <br></br>
                                <video width="320" height="240" controls id="videoSourceMain">
                                    <source src="" id="videoSource" type="video/mp4"></source>
                                    Your browser does not support the video tag.
                                </video>
                            </div>                            
                        </div>
                        <div className="row" id="divTrigger" style={{display:"none"}}>
                            <br></br>
                            <div className="col-12">
                                <span className="float-start">
                                    <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={triggerNerfCapture}>Check Output Status</a>
                                </span>
                            </div>                            
                        </div>
                        <div className="row" id="divNewTrigger" style={{display:"none"}}>
                            <br></br>
                            <div className="col-12">
                                <span className="float-start">
                                    <a href="javascript:;" style={{ margin: "15px 0 25px" }} className="common-save" onClick={triggerNerfCaptureRefresh}>Back to dashboard</a>
                                </span>
                            </div>                            
                        </div>
                        {/* <div className="row">
                            <div className="col-12">
                            <iframe src="https://cdn-luma.com/e087fbafff45e2bdf26630dfc1800b590e1b9a2f743e5c51f3d514a8b5fbb025.mp4">
                            </iframe>
                            </div>
                        </div> */}
                    </div>
                    {/* content end */}

                </div>
            </div>
            </div>
    );

}
export default Create3dmodel;