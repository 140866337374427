import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name,Role} from "../User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
import CsvDownloader from 'react-csv-downloader';
import { faArrowDown, faChevronDown, faChevronLeft, faCrosshairs, faEnvelope, faMailBulk, faMessage, faRemove } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";
import Cookies from 'js-cookie';
import $ from 'jquery';

const Sherlocksetupdomainlist = () => {
    const [tabledata, settabledata] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "Domain Name", field: "DomainName", sortable: true },
        { name: "Delete" }
    ];

      

    useEffect(() => {
        const getData = () => {
            showLoader();
            var userI = Cookies.get('userInfo');// getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            // console.log(decodedUser);
            var parsedRes = JSON.parse(decodedUser);
            var compId = parsedRes.CompanyId;
    
            var config = {
                method: 'get',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetWhitelistData?CompanyId="+compId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
        
            axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                   //console.log(response.Data);
                   var compWaterMark = response.Data;
                   console.log(compWaterMark);
                   if (compWaterMark != null && compWaterMark != "") {
                    document.getElementById('divWhitelistData').style.display = "block";
                    settabledata(compWaterMark);
                   } else {
                    document.getElementById('divWhitelistData').style.display = "block";
                   }
                  }
                else {
                    //self.customAlertMessage('error', response.Message); // 'success','failure','info'
                    document.getElementById('divWhitelistData').style.display = "block";
                }
                hideLoader();
            })
            .catch(function (error) {
                hideLoader();
                document.getElementById('divWhitelistData').style.display = "block";
            });
        };

        getData();
    }, []);

    const tableData = useMemo(() => {
        let computeddata = tabledata;

        if (search) {

            computeddata = computeddata.filter(
                tdata =>
                tdata.DomainName.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computeddata.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            if (sorting.field == 'DomainName') {
               
                computeddata = computeddata.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
            
        }

        //Current Page slice
        return computeddata.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [tabledata, currentPage, search, sorting]);

    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };

      const showoverlayCSV = () => {
        document.getElementById('overlayCSV').style.display = "block";
        $('body').addClass("loader");
    }
    const hideoverlayCSV = () => {
        document.getElementById('overlayCSV').style.display = "none";
        $('body').removeClass("loader");
    }

    const changeTextField = (e) =>{
        var Id = e.currentTarget.dataset.refid;
    console.log(e.currentTarget.id);
    $("#btnDomain"+Id).show();
    $("#txtDomain"+Id).show();
    $("#txtDomain"+Id).val($("#lblDomain"+Id).text());
    $("#lblDomain"+Id).hide();
    $("#btnCancelDomain"+Id).show();    
//btnDomain
    }

    const updateDomainName = (e) =>{
        var Id = e.currentTarget.dataset.refid;
        var txtId = "txtDomain"+Id;
        console.log(txtId);
        var domainValue = document.getElementById(txtId).value;
        if (domainValue == "") {
            customAlertMessage('error', "Domain name should not be empty.");
            return;
        }
        //call api and update domain value;
        showLoader();
        var config = {
            method: 'get',
            "url": `${process.env.REACT_APP_SHERLOCKAPI_URL}` +"/api/ArtificialIntelligence/UpdateDomainwhitelist?DomainName="+domainValue+"&Id="+Id,
            headers: {
               'Content-Type': 'application/json'
            },
        };

        axios(config)
            .then(function (resp) {

                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        var responseData = response.Data;
                        if (responseData == "true") {
                            //on success
                            $("#btnDomain"+Id).hide();
                            $("#txtDomain"+Id).hide();
                            $("#btnCancelDomain"+Id).hide();
                            $("#lblDomain"+Id).show();
                            $("#lblDomain"+Id).text(domainValue);
                            getWhiteListData();
                            customAlertMessage('success', "Updated successfully.");
                        }
                         else {
                            customAlertMessage('error', "Failed.");
                        }                        
                    }
                    else {
                        customAlertMessage('error', "Failed.");
                    }
                }
                else if(response.Status == "202"){
                    customAlertMessage('error', "Domain name should not be empty.");
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              customAlertMessage('error', "Something went wrong.");
                hideLoader();
            });        
    }

    const cancelUpdateDomain = (e) =>{
        var Id = e.currentTarget.dataset.refid;
        $("#btnDomain"+Id).hide();
    $("#txtDomain"+Id).hide();
    $("#lblDomain"+Id).show();
    $("#btnCancelDomain"+Id).hide();
    }

      const deleteDomain = (e) => {
        var Id = e.currentTarget.dataset.refid;           
        var result = window.confirm("Are you sure you want to delete this domain?");
            if (!result) {
                return;
            }
            showLoader();
            var userI = Cookies.get('userInfo');// getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            // console.log(decodedUser);
            var parsedRes = JSON.parse(decodedUser);
            var compId = parsedRes.CompanyId;
            var FormData = require('form-data');
            var data = new FormData();
            data.append('Id',Id);// this.blobToFile(this.state.croppedimage, "filename"));
            // data.append('file',fs.createReadStream(this.state.croppedimage));
            data.append('CompanyId', compId);
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteWhiteListDomain",
            headers: {
               'Content-Type': 'application/json' 
            },
            data : data
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                        //team created load  all teams
                        customAlertMessage('success', "Domain deleted successfully");
                        getWhiteListData();             
                }
                else {
                    customAlertMessage('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
                customAlertMessage('error', 'Something went wrong, Try Again.');
                hideLoader();
            });
      }

      const getWhiteListData = () => {
        showLoader();

        var userI = Cookies.get('userInfo');// getAccessToken();
            var decodedUser = decodeURIComponent(userI);
            // console.log(decodedUser);
            var parsedRes = JSON.parse(decodedUser);
            var compId = parsedRes.CompanyId;
    
            var config = {
                method: 'get',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetWhitelistData?CompanyId="+compId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //
                        
                        // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                        settabledata(response.Data);
                        console.log('Inside show users response');
                       // console.log(response);                            
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const downloadFile = () => {

        var fileName ="SampleDomain.csv";
        var urlData = "https://uat.chocolatechips.ai/SampleWhitelistDomainnames.csv"
        var aLink = document.createElement('a');
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("click");
        aLink.download = fileName;
        aLink.href = urlData;
        aLink.click(evt);
      }

   const uploadCsvFile = () =>{

        var inputData = document.getElementById('btnfileUpload');
        var uploadedFile = inputData.files[0];
        if(uploadedFile == undefined){
            return;
        }
        var self = this;
          var userI = Cookies.get('userInfo');// getAccessToken();
          var decodedUser = decodeURIComponent(userI);
          // console.log(decodedUser);
          var parsedRes = JSON.parse(decodedUser);
          var compId = parsedRes.CompanyId;
          var FormData = require('form-data');
          var data = new FormData();    
          data.append("companyid", compId);
          data.append("fileData", uploadedFile);
      
          //showoverlayCSV();
          showLoader();
      
          var config = {
              method: 'post',
              "url": `${process.env.REACT_APP_SHERLOCKAPI_URL}` +"/api/ArtificialIntelligence/processcsvfile",
              //"url": "https://localhost:44316/api/ArtificialIntelligence/processcsvfile",
              data: data
          };
      
          axios(config)
              .then(function (resp) {
      
                  var response = (resp.data);
                  if (response.Status == "200") {
                    document.getElementById('btnfileUpload').value="";
                    getWhiteListData();             
                    customAlertMessage('success', "Imported successfully.");  
                  }
                  else if(response.Status == "201") {
                    customAlertMessage('error', 'No records found in the uploaded file.');
                  }
                  else {
                      customAlertMessage('error', 'Error occured, Please check the input data.'); // 'success','failure','info'
                  }
                 //hideoverlayCSV();
                  hideLoader();
              })
              .catch(function (error) {
                customAlertMessage('error', "Import failed.");
                  //hideoverlayCSV();
                  hideLoader();
              });
      
      }

    return (
        <>
            {/* <Header title="Building a data table in react" /> */}
            {/* <ExternalInfo page="datatable" /> */}    
            <div className="setup-title">
                <Link to="/addwhitelisteddomain" style={{marginTop:"15px",display:"inline-block"}}>Add Whitelisted Domain</Link><br></br><br></br>
                 OR <br></br><br></br>Import .csv file:  &nbsp;<input type='file' onChange={uploadCsvFile} id='btnfileUpload' accept='.csv' /> <br></br>
                 <a href='javascript:;' onClick={downloadFile}>Click here</a> to download sample .csv file.
                <div id="overlayCSV" style={{display:"none"}}>
                <div id="loadingCSV">
                    <h2 id="loaderheadingCSV">Loading...</h2>
                    <img src={loader} style={{maxWidth:"125Px"}} />
                    </div>
                </div>                
            </div>                   
            <div className="row w-100" style={{marginTop:"15px"}}>
                <div className="col mb-3 col-12 text-center" style={{paddingRight:"0"}}>
                    <div className="row">                      
                        <div className="col-md-12 d-flex flex-row-reverse" style={{paddingRight:"0"}}>
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                   <div className="row">
                   <div className="rightside-bar pr5 nominheight" style={{minHeight:'unset'}}>
                    <table className="table table-hover common-table sherlocktable" style={{minHeight:'unset'}}>
                        <Header
                            headers={headers}
                            defaultsort="DomainName" 
                            defaultsortorder="desc" 
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
<tbody>
{tableData.length > 0 ? tableData.map((d) => (
                                <tr style={{textAlign:'left'}}>									
									<td>   
                                        <label id={"lblDomain"+d.Id} data-refid={d.Id} className="tooltip1" tooltip="Click to edit" flow="right" style={{cursor:"pointer"}} onClick={changeTextField}>{d.DomainName}</label>                                      
                                        <input id={"txtDomain"+d.Id} style={{display:"none"}} type="text" ></input> &nbsp; 
                                        <input id={"btnDomain"+d.Id} style={{display:"none"}} data-refid={d.Id} type="button" value="Update" onClick={updateDomainName}></input>
                                        <input id={"btnCancelDomain"+d.Id} style={{display:"none"}} data-refid={d.Id} type="button" value="Cancel" onClick={cancelUpdateDomain}></input>
                                        </td>
									{/* <td>{d.domaincount.toString()}</td>
									<td>{moment.utc(d.lastupdated).local().format('MM-DD-YYYY h:mm A')}</td> */}
                                    <td>
                                    <span style={{color:'red',cursor:'pointer'}} data-refid={d.Id} onClick={deleteDomain}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>
                                    </td>
								</tr>

                            )) : <tr style={{textAlign:'left'}}>
                                <td colSpan={2} className="text-center">No data found</td>
                                </tr>
                                }
</tbody>                        
                    </table>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 sherlocktable">
                        <center>
                        <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </center>
                        </div>
                    </div>
                </div>
            </div>
            {loader}
        </>
        
    );
};

export default Sherlocksetupdomainlist;