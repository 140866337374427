import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faClock, faCrosshairs, faEnvelope, faExclamation, faExclamationCircle, faMailBulk, faMessage, faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import React, {useState} from 'react';
import AudioToText from "./AudioToText";
import $ from 'jquery';
import Emailstripo from "../email/stripoapp";
import Swal from 'sweetalert2'
import loader from "../img/sample.gif";
import { v4 as uuidv4,NIL as NIL_UUID } from 'uuid';
import moment from 'moment';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg} from "../User";
import Sherlockunauthorizedlist from "./sherlockunauthorizedlist"
import Sherlockauthorizedlist from './sherlockauthorizedlist';
import ContentLoader,{List} from "react-content-loader";
import * as axios from 'axios';
import Cookies from 'js-cookie';
import Sherlocksetupdomainlist from './sherlocksetupdomainlist';

export const getAccessToken = () => Cookies.get('userInfo');

// const MyLoader = () => <List />
const MyLoader = () => (    
  <ContentLoader 
  speed={1}
  width={200}
  height={20}
  viewBox="0 0 200 20"
  backgroundColor="#808080"
  foregroundColor="#ecebeb"
  style={{ width: '100%' }}
>
  <rect x="0" y="8" rx="3" ry="3" width="88" height="10" /> 
  {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
  {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
  <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
  <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
  {/* <circle cx="20" cy="20" r="20" /> */}
</ContentLoader>
  );

  const MyLoader1 = () => (    
    <ContentLoader 
    speed={1}
    width={200}
    height={20}
    viewBox="0 0 200 20"
    backgroundColor="#808080"
    foregroundColor="#ecebeb"
    style={{ width: '100%' }}
  >
    <rect x="0" y="8" rx="3" ry="3" width="88" height="10" /> 
    {/* <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />  */}
    {/* <rect x="0" y="30" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="46" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="62" rx="3" ry="3" width="178" height="6" />  */}
    {/* <circle cx="20" cy="20" r="20" /> */}
  </ContentLoader>
    );

function deleteNew(){
  alert('inside');  
}



class sherlocksetup extends React.Component {

    constructor(props) {
		super(props);
		this.state = { 
		  keyworddisabled: true,
		  tonedisabled:true,
		  copySuccess: '',
		  wordcount: 0,
		  Tonecount:0,
		  generatedimages: [],
		  emailguid: NIL_UUID,
		  emailhtml: "",
		  defaultcontenterased: false,
      tabledata:[],
      dashtotalcount: -1,
      dashrecentimagescount: -1,
      dashauthorizedcount: -1,
      dashunauthorizedcount: -1,
      dashlastupdated: "",
      newTableWaterMark:[]    
		  
	  }
	  }
    
      componentDidMount(){
        //this.loadtabledata();
		// this.setState({emailguid: uuidv4()});
		this.jquerycode();
    //this.gettoppaneldata();
		// setInterval(() => {			
		// 	this.checkandupdatecontent();
		//   }, 10000);
    this.loadWaterMarkData();
    this.loadWhiteListData();
	  };
//
deleteWaterMark = (Id) =>{

  var result = window.confirm("Are you sure you want to delete watermark logo?");
  if (!result) {
      return;
  }
  //var Id = e.currentTarget.dataset.refid;
  var self = this;
    var userI = Cookies.get('userInfo');// getAccessToken();
    var decodedUser = decodeURIComponent(userI);
    // console.log(decodedUser);
    var parsedRes = JSON.parse(decodedUser);
    var compId = parsedRes.CompanyId;
    var FormData = require('form-data');
    //var fs = require('fs');   
    var data = new FormData();    
    data.append("CompanyId", compId);
    data.append("Id", Id);

    self.showoverlay();

    var config = {
        method: 'post',
        "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/DeleteWaterMarkInfo",
        //headers: {
        //    'Content-Type': 'application/json'
        //},
        data: data
    };

    axios(config)
        .then(function (resp) {

            var response = (resp.data);
            if (response.Status == "200") {
                if (response.Data != null) {
                    var responseData = response.Data;
                    if (responseData == "true") {
                        document.getElementById('divWaterMarkData').style.display = "block";
                        document.getElementById('divWaterMarkWitoutData').style.display = "inline-block";
                        self.loadWaterMarkData();
                        self.customAlertMessage('success', "Deleted successfully.");                            
                    } else {
                        self.customAlertMessage('error', "Delete failed.");
                    }                        
                }
                else {
                    self.customAlertMessage('error', "Delete failed.");
                }
            }
            else {
                self.customAlertMessage('error', response.Message); // 'success','failure','info'
            }
            self.hideoverlay();
        })
        .catch(function (error) {
          self.customAlertMessage('error', "Delete failed.");
            self.hideoverlay();
        });

}    

    loadWaterMarkData = () =>{
      var self = this;
        self.showoverlay();

        var userI = Cookies.get('userInfo');// getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        // console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var compId = parsedRes.CompanyId;

        var config = {
            method: 'get',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAllWaterMarkInfo?CompanyId="+compId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                   //console.log(response.Data);
                   var compWaterMark = response.Data;
                   if (compWaterMark != null && compWaterMark.length > 0){                    
                    //compWaterMark != "" && compWaterMark.WaterMarkContent !="") {
                  
                    document.getElementById('divWaterMarkData').style.display = "block";
                    document.getElementById('divWaterMarkWitoutData').style.display = "inline-block";
                    var tableContent ="";
                    self.setState({ newTableWaterMark: compWaterMark });;
                    return;
                    for (var i = 0; i < compWaterMark.length; i++) 
                    { 
                        var itemData = compWaterMark[i];
                        tableContent += "<tr>"+
                        "<td>"+
                         "<label  style='color:black'>"+itemData.WaterMarkContent+"</label>"+
                        "</td><td>"+
                          "<img src='"+itemData.WaterMarkLogo+"' style='max-width:150px' />"+
                        "</td>"+
                        "<td style='textAlign:right'>"+
                            "<a href='/sherlockwatermark?Id="+itemData.Id+"' class='greybtnsetup'>View</a>&nbsp;"+
                            "<a href='javascript:;' onClick='deleteWaterMark("+itemData.Id+")' class='greybtnsetup'>Delete</a>"+
                        "</td></tr>";
                    }
                    document.getElementById('tbWaterMarkData').innerHTML = tableContent;

                   } else {
                    document.getElementById('divWaterMarkData').style.display = "block";
                    document.getElementById('divWaterMarkWitoutData').style.display = "inline-block";
                   }
                  }
                else {
                    //self.customAlertMessage('error', response.Message); // 'success','failure','info'
                    document.getElementById('divWaterMarkData').style.display = "block";
                    document.getElementById('divWaterMarkWitoutData').style.display = "inline-block";
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                //self.customAlertMessage('error', 'Something went wrong, Try Again.');
                document.getElementById('divWaterMarkData').style.display = "block";
                document.getElementById('divWaterMarkWitoutData').style.display = "inline-block";
                self.hideoverlay();
            });
    }

    loadWhiteListData = () =>{
      var self = this;
        self.showoverlay();

        var userI = Cookies.get('userInfo');// getAccessToken();
        var decodedUser = decodeURIComponent(userI);
        // console.log(decodedUser);
        var parsedRes = JSON.parse(decodedUser);
        var compId = parsedRes.CompanyId;

        var config = {
            method: 'get',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetWhitelistData?CompanyId="+compId,
            headers: {
               'Content-Type': 'application/json' 
            }
        };

        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                   //console.log(response.Data);
                   var compWaterMark = response.Data;
                   console.log(compWaterMark);
                   if (compWaterMark != null && compWaterMark != "") {
                    document.getElementById('divWhitelistData').style.display = "block";
                   } else {
                    document.getElementById('divWhitelistData').style.display = "block";
                   }
                  }
                else {
                    //self.customAlertMessage('error', response.Message); // 'success','failure','info'
                    document.getElementById('divWhitelistData').style.display = "block";
                }
                self.hideoverlay();
            })
            .catch(function (error) {
                //self.customAlertMessage('error', 'Something went wrong, Try Again.');
                document.getElementById('divWhitelistData').style.display = "block";
                self.hideoverlay();
            });
    }
      
    showoverlay = () => {
      document.getElementById('overlay').style.display = "block";
      $('body').addClass("loader");
  }
  hideoverlay = () => {
      document.getElementById('overlay').style.display = "none";
      $('body').removeClass("loader");
  }  

jquerycode = () =>{
	var self = this;
	Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('1').classList.add("active");
}

      
	  historygoback = () => {
        window.history.back();
    }

    showwhitelisted = () =>{
      if (document.getElementById('whitelistedtable').style.display == "block") {
        document.getElementById('whitelistedtable').style.display="none";  
      }
      else{
        document.getElementById('whitelistedtable').style.display="block";
        document.getElementById("scrolltowhitelistview").scrollIntoView({ behavior: "smooth" });
      }
      
    }

    gettoppaneldata=()=>{
      const axios = require('axios');
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SHERLOCKAPI_URL}` +'/api/ArtificialIntelligence/getdashboard?companyid=&mlsname=',
        headers: { }
      };

      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.Status == "200") {
          var data = response.data.Data;
            this.setState({
              dashtotalcount: data.Totalcount,
              dashrecentimagescount: data.Recentimagescount,
              dashauthorizedcount: data.Authorizedcount,
              dashunauthorizedcount: data.Unauthorizedcount,
              dashlastupdated: new Date(data.Lastupdated).toString()
            })
        }
        else{

        }
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
    customAlertMessage = (icon,message) =>{
      const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
      })

      Toast.fire({
          icon: icon,//'success',
          title: message// 'Signed in successfully'
      })
  }

  
   
      render() {
        return (
          <>          
            <div className="main_content_iner overly_inner">
        <div className="container-fluid p-0 ">
          <div className="row" style={{position:'relative'}}>
          <div id="overlay">
                <div id="loading">
                    <h2 id="loaderheading">Loading...</h2>
                    <img src={loader} />
                </div>
            </div>
            <div className="col-12" style={{marginBottom:"20px"}}>
				<div className="title-card1">
					<span className="float-start">
                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Sherlock Setup</span></h4>
                    <p>
                    Here you can upload the watermark logos your company uses. Additionally, you can review the currently whitelisted domains. Whitelisted domains help Sherlock to differentiate between authorized and unauthorized discoveries. Feel free to add new domains to or remove domains from the Whitelist.</p>
					</span>
				</div>
            </div>

            <div className='col-12' style={{marginBottom:"20px"}}>
                <div className='row'>

                  {/* 
                    <div className='col-12' style={{marginBottom:"30px"}}>
                        <div className='setup-title'>
                            <h2>MLS Integration <Link to="/mlsintegration"><FontAwesomeIcon icon={faPlusCircle}></FontAwesomeIcon></Link></h2>
                        </div>
                        <table className='setup-table'>
                            <tbody>
                                <tr>
                                    <td>Miami Realtor MLS</td>
                                    <td className='greentdsetup'><FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>Connected</td>
                                    <td>
                                        <a href="javascript:;" className='greybtnsetup'>Edit</a>
                                        <a href="javascript:;" className='greybtnsetup'>Delete</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>CCAR MLS</td>
                                    <td className='redtdsetup'><FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>Not Connected</td>
                                    <td>
                                        <a href="javascript:;" className='greybtnsetup'>Edit</a>
                                        <a href="javascript:;" className='greybtnsetup'>Delete</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
*/}
                    <div className='col-12' style={{marginBottom:"30px"}}>
                        <div className='setup-title'>
                            <h2>Watermark Logo <Link to="/sherlockwatermark" id='divWaterMarkWitoutData' tooltip="Upload additional watermark logos" flow="right"><FontAwesomeIcon icon={faPlusCircle} style={{color:"green"}}></FontAwesomeIcon></Link></h2>
                        </div>
                        {/* <div id='divWaterMarkWitoutData' style={{display:"none"}}>
                          <Link to="/sherlockwatermark" className=''>Add</Link>
                        </div> */}
                        <div id='divWaterMarkData' style={{display:"none"}}>
                          <table className='setup-table'>
                              <tbody id="tbWaterMarkData">
                              {this.state.newTableWaterMark.length > 0 ? this.state.newTableWaterMark.map((d) => (
                                   <tr>
                                      <td>
                                       <label id="lblWaterMarkCont" style={{color:"black"}}>{d.WaterMarkContent}</label>
                                      </td>
                                      <td>
                                        <img id='imgWaterMark' style={{maxWidth:"150px"}} src={d.WaterMarkLogo}/>
                                      </td>
                                      <td style={{textAlign:'right'}}>
                                          <Link to={"/sherlockwatermark/?Id="+d.Id}   className='greybtnsetup'>View</Link>
                                          <a href='javascript:;' onClick={()=>this.deleteWaterMark(d.Id)}  className='greybtnsetup'>Delete</a>
                                      </td>
                                  </tr> )) : null
                                }
                              </tbody>
                          </table>
                        </div>
                    </div>
                    <div className='col-12'>
                      <hr className='hrsetupline'></hr>
                    </div>
                    <div className='col-12' style={{marginBottom:"30px"}}>
                        <div className='setup-title'>
                            <h2><Link to="/manageusers">Manage Users</Link></h2>
                        </div>
                    </div>
                    <div className='col-12'>
                      <hr className='hrsetupline'></hr>
                    </div>
                    <div className='col-12' id="scrolltowhitelistview">

                    </div>
                    <div className='col-12' style={{position:"relative"}}>
                        <div className='setup-title'>
                            <h2>Whitelisted Domains <FontAwesomeIcon icon={faChevronDown} style={{marginLeft:"10px",cursor:"pointer"}} onClick={this.showwhitelisted}></FontAwesomeIcon>
                              <Link to="/whitelisthistory" className='tooltip1' tooltip="History" flow="right"><FontAwesomeIcon icon={faClock} style={{marginLeft:"10px"}}></FontAwesomeIcon></Link></h2>
                        </div>                       
                        <div id="whitelistedtable" style={{display:"none"}}>                          
                          <div id='divWhitelistData' style={{display:"block"}}>
                          <Sherlocksetupdomainlist></Sherlocksetupdomainlist>
                          </div>
                        </div>
                        
                    </div>
                </div>
            </div>
			
			
			
          </div>          
        </div>
      </div>
      </>
        )
      }

}

export default sherlocksetup;