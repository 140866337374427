import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faInfo,faMicrophone,faInfoCircle, faCircleInfo, faCircleDown, faFile,faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import React, {useState} from 'react';
import AudioToText from "./AudioToText";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import copy from 'copy-to-clipboard';
import $ from 'jquery'
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard,requestClipboardWritePermission   } from 'copy-image-clipboard'
import loader from "../img/sample.gif";
import DDLmood from './ddlmood';
import DDLmedium from './ddlmedium';
import DDLinspiration from './ddlinspiration';
import DDLstyle from './ddlstyle';
// import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import {UserID,Name,Companyid,Role,Rolename, ProfileImg,PlanType,TextToImageSub} from "../User";
import imageprocessing from  "../img/sample.gif";//"../img/imageprocessing.gif";
// import "jquery-nice-select/css/style.css";
// require("jquery-nice-select");

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;

class Texttovideo extends React.Component {
    constructor(props) {
      super(props);
    //   this.selectRef = React.createRef();
      this.state = { 
        keyworddisabled: false,
        copySuccess: '',
        wordcount: 0,
        generatedimages:[]
        // generatedimages: [
        //     {id:1,image:ProfileImg,processing:false,prompt:"",fetchurl:""},
        //     {id:2,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:3,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:4,image:"https://openaiimages.s3.us-west-1.amazonaws.com/styles/000da745-37d6-4a52-9394-fa81e6f68413.jpg",processing:false,prompt:"",fetchurl:""},
        //     {id:5,image:imageprocessing,processing:true,prompt:"",fetchurl:"https://stablediffusionapi.com/api/v3/fetch/1746944"}
        // ] 
    }
    }
componentDidMount(){
    Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
		el.classList.remove('active');
	});
	document.getElementById('2').classList.add("active");
    var self = this;
    //check for subscription
    //this.checkSubscription();
    //
    // $(this.selectRef.current).niceSelect();    
    setInterval(function () {
        self.checkforprocessingimages();
    }, 5000);
}

checkSubscription = () =>{
    if(PlanType > 0){
        if(!TextToImageSub){
            //this.customAlertMessage("error","Please subscribe to 'Text To Image' Subscription");
            document.getElementById('hrefEula').click();
        }
    }
}

checkforprocessingimages = async() =>{
    var processingimages = this.state.generatedimages.filter(
        d => d.processing == true
    );
    for (let i = 0; i < processingimages.length; i++) {
        const element = processingimages[i];
        await this.callprocessingimage(element.id,element.prompt,element.fetchurl);
        
    }
}

	handleDownload = (e) => {
		var url = e.currentTarget.dataset.fileurl;
		var axios = require('axios');
		axios.get(url, {
		  responseType: 'blob',
		})
		.then((res) => {
		var filename = url.substring(url.lastIndexOf('/')+1);
		fileDownload(res.data, filename);
		})
	}

    customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
    setwordcount = () => {
        var text = document.getElementById('keywordinput').value;
        if(text.trim() == "")
        {
            this.setState( {wordcount: 0} )
        }
        else{
            const words = text.trim().split(' ');
            if(words.length > 3)
            {   
                var trimmedwords = text.trim().split(' ').slice(0, 3).join(' ');
                document.getElementById('keywordinput').value = trimmedwords;
                this.setState({wordcount: 3})
            }
            else{
                this.setState({wordcount: words.length})
            }
            
        }
        
    }
    togglekeywords() {
      this.setState( {keyworddisabled: !this.state.keyworddisabled} )
    }

      
    copyToClipboard = (e) => {
        const canCopy = canCopyImagesToClipboard();
        if (issafari) {
            this.copyimageforsafari(e.currentTarget.dataset.fileurl);
        }
        else if (canCopy) {
            copyImageToClipboard(e.currentTarget.dataset.fileurl)
            .then(() => {
              console.log('Image Copied')
              this.customAlertMessage('success','Copied!');
            })
            .catch((e) => {
              console.log('Error: ', e.message)
              this.customAlertMessage('error','Please try again!');
            })
        }
        else {
            this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
        }        
        // copy(e.currentTarget.dataset.fileurl);     
        e.target.focus();
    };

    copyimageforsafari = async(imageurl) => {
        try {
            const item = new ClipboardItem({
                'image/png': (async () => {
                  const response = await fetch(imageurl) // Reference from your source code.
                  return await response.blob()
                })(),
              });
              await navigator.clipboard.write([item])
              this.customAlertMessage('success','Copied!');
        } catch (error) {
            this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
        }
    }      

    geteleValue = (objectid,type) => {
       var text = "";
       if(document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue').length > 0 && document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue')[0].innerText.toLowerCase() != "none"){
        text = type + ": " + document.getElementById(objectid).getElementsByClassName('css-1dimb5e-singleValue')[0].innerText + " , ";
        // text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
       }
       return text;
    }
    gettexteleValue = (objectid,type) => {
        var text = "";
        if(document.getElementById(objectid).value.trim() != ""){
            text =   type + ": " + document.getElementById(objectid).value.trim();
            //text = document.getElementById(objectid).options[document.getElementById(objectid).selectedIndex].text + " , ";
        }
        return text;
     }
    calltexttoimage = () => {    
              if(document.getElementById('prompt').value=='')
				{
					this.customAlertMessage('error','Please enter Description');
					return;
				}    
            const axios = require('axios');
            const FormData = require('form-data');
            let data = new FormData();
            data.append('prompt', document.getElementById('prompt').value);
            document.getElementById('outputdiv').style.display='block';
            this.showoverlay();
            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://stgmint.skyharbor.app/texttovideo',
            data : data
            };
            
            axios.request(config)
            .then((response) => {
            console.log(JSON.stringify(response.data));
           
            document.getElementById('VideoSrc').src=response.data.result;
            document.getElementById('donloaddiv').style.display='block';
            document.getElementById('downloadbtn').dataset.fileurl=response.data.result; 
            this.hideoverlay();
            })
            .catch((error) => {
            console.log(error);
            this.hideoverlay();
            });
      
    }
    historygoback = () => {
        window.history.back();
    }
    deletesingle = (e) => {
        var identity = e.currentTarget.dataset.fileurl;
        var imagelist = this.state.generatedimages;
        for (let [i, image] of imagelist.entries()) {
            if (image.id === parseInt(identity)) {
                imagelist.splice(i, 1);
                break;
            }
          }
        this.setState({generatedimages: imagelist});
    }
    delateall = () => {
        this.setState({generatedimages: []});
    }    

    callprocessingimage = async(id,prompt,fetchurl) => {      
        var self = this;
        var prompttext = prompt;
        //var formdata = new FormData();       
        // this.showoverlay();
        var fetchurl = `${process.env.REACT_APP_API_URL}` +"/api/ArtificialIntelligence/getprocessedimageforstablediffusion?fetchrequest="+ encodeURIComponent(fetchurl)+"&prompt=" + prompttext.trim('').trim(',') + "&uid="+UserID+"&companyid="+Companyid;
        
        var axios = require('axios');
        var FormData = require('form-data');
        var data = new FormData();
        
        var config = {
          method: 'get',
          url: fetchurl
        };
        
        axios(config)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
           var response = (resp.data);
            if (response.Message == "success" && response.Data != "") {
                var imagesarr = self.state.generatedimages;
                for (let i = 0; i < imagesarr.length; i++) {
                    const element = imagesarr[i];
                    if (element.id == id) {
                        element.image = response.Data
                        element.processing = false;
                        break;
                    }                    
                }
                var joined = self.state.generatedimages.sort(function(a,b) {return b.id - a.id;});
                self.setState({ generatedimages: joined });  
            }
            else if(response.Message == "processing" && response.Data != ""){
                //Do nothing
            }
            else
            {
                // Show error
                //self.customAlertMessage('error', 'Something went wrong, Try Again.');
            }
            //self.hideoverlay();
        })
        .catch(function (error) {
          console.log(error);
        //   self.customAlertMessage('error', 'Something went wrong, Try Again.');
        //   self.hideoverlay();
        });
      
    }
    render() {
      return(
        <>
        <div className="main_content_iner overly_inner ">
        <div className="container-fluid p-0 ">            
            <div className="row">	 
                <div className="col-12">
                    <div className="title-card1">
                    <h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Text to Video</span></h4>
                        
                    </div>
                </div>
                {/* Add your Content here */}
                <div className="col-12">
            <div className="row">
                <div className="col-4">
                <div className="lefside-bar">
                    <div className="row">
                        
                    <div className="col-12 mb-30">
                        <div className="row">
                            <div className="col-12 label-info">
                                <label>Description</label>
                                <input id="prompt" maxLength={80} className="common-input"/>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                
                
                <div className="col-8" id="outputdiv" style={{display:'none'}}>
                
                    <div className="row">
                        <div className="col-12" style={{position:"relative"}}>
                            <h5 className='right-header'>Generated Video</h5>                        
                        <div id="overlay" className='overlay1'>
                            <div id="loading" style={{top:"33%"}}>
                                <h2 id="loaderheading">Generating...</h2>
                                <img src={loader} />  
                            </div>
                        </div>   
                        <div className="rightside-bar generatedvideo" style={{clear:"both"}} id="scrolldivparent" >          
                            <center>
                                <video id='VideoSrc' width={"auto"} height={"400"} autoPlay={true} controls>                               
                                </video>
                            </center>
                        </div>   
                        </div>

                    </div>
                </div>
                
            </div>
            </div>
            </div>
        </div>

        <div className='bottom-fixed'>
		      <div className="row">
				  <div className="col-4">
				  		<div className="text-center">
                          <a href="javascript:;" className="common-save" onClick={this.calltexttoimage}>Generate</a>
						</div>
				  </div>
                  <div className="col-8" id='donloaddiv' style={{display:'none'}}>
				  		<div className="text-center">
                          <a href="javascript:;" className="common-save" onClick={this.handleDownload} id='downloadbtn' >Download</a>
						</div>
				  </div>
			  </div>
		  </div>
          <a href='javascript:;' id='hrefEula' data-bs-toggle="modal"
                          data-bs-target="#eulamodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>                                

    </div>    
    <div className="modal fade" id="eulamodal" tabIndex={-1} aria-labelledby="listeningModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="listeningModalLabel"><b>Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"420px",overflowY:"auto",paddingBottom:"15px"}}>   
              <div className="col-12">
                  <p>
                  Text To Image Subscription is required to use this feature.
                  </p>
                  <p> Please click on this&nbsp;
                  <Link to="/texttoimagesub">link</Link>&nbsp;to subscribe
                    </p>  
                  
              </div>
              </div>
              <div className="modal-footer">
               
              </div>
          </div>
      </div>
      </div>
    </>
      );
    }
  };

export default Texttovideo;
