import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name} from "../User";
import { faMailBulk, faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
import queryString from "query-string";
// import ExternalInfo from "components/ExternalInfo";

const TeamDetails = () => {
    const [comments, setComments] = useState([]);
    const [nonteamusers, setnonTeamUsers] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [totalItemsNon, setTotalItemsNon] = useState(0);
    const [currentPageNon, setCurrentPageNon] = useState(1);
    const [searchNon, setSearchNon] = useState("");
    const [sortingNon, setSortingNon] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = 10;

    const headers = [
        { name: "No"},
        { name: "First Name", field: "FirstName", sortable: true },
        { name: "Last Name", field: "LastName", sortable: true },
        { name: "Email", field: "EmailId", sortable: true },
        { name: "Added On", field: "AddedOn", sortable: true },
        { name: "Action"}
    ];

    const headersNon = [
        { name: "No"},
        { name: "First Name", field: "FirstName", sortable: true },
        { name: "Last Name", field: "LastName", sortable: true },
        { name: "Email", field: "EmailId", sortable: true },
        { name: "Action"}
    ];
    
    const query = queryString.parse(window.location.search);
    const token = query.tId;
    const loggedUserId = UserID;
    const tName = query.tN;

    useEffect(() => {
        const getData = () => {
            showLoader();

            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamUsers?TeamId="+token,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
        
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //
                            
                            // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                            document.getElementById('lblTeamName').textContent = tName;      
                            setComments(response.Data);
                            console.log('Inside show teams response');
                            console.log(response);                            
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });

                var config = {
                    method: 'post',
                    "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamOutsideUsers?MasterId="+loggedUserId+"&TeamId="+token,
                    headers: {
                       'Content-Type': 'application/json' 
                    }
                };
            
                axios(config)
                    .then(function (resp) {
                        
                        var response = (resp.data);
                        if (response.Status == "200") {
                            if (response.Data != null) {
                                //
                                
                                // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                                setnonTeamUsers(response.Data);
                                console.log('Inside show teams response');
                                console.log(response);                            
                            }
                            else {                        
                                console.log('Failed to load data.');
                            }
                        }
                        else {
                          console.log('error', response.Message); // 'success','failure','info'
                        }
                        hideLoader();
                    })
                    .catch(function (error) {
                      console.log('Something went wrong, Try Again.');
                      hideLoader();
                    });
        };

        getData();
    }, []);
    

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                comment.FirstName.toLowerCase().includes(search.toLowerCase()) ||
                comment.LastName.toLowerCase().includes(search.toLowerCase()) ||
                comment.EmailId.toLowerCase().includes(search.toLowerCase()) 
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    const nonTeamUsers = useMemo(() => {
        let computedNonTeamUsers = nonteamusers;

        if (searchNon) {
            computedNonTeamUsers = computedNonTeamUsers.filter(
                nonT =>
                    nonT.FirstName.toLowerCase().includes(searchNon.toLowerCase()) ||
                    nonT.LastName.toLowerCase().includes(searchNon.toLowerCase()) ||
                    nonT.EmailId.toLowerCase().includes(searchNon.toLowerCase()) 
            );
        }

        setTotalItemsNon(computedNonTeamUsers.length);

        //Sorting comments
        if (sortingNon.field) {
            const reversed = sortingNon.order === "asc" ? 1 : -1;
            computedNonTeamUsers = computedNonTeamUsers.sort(
                (a, b) =>
                    reversed * a[sortingNon.field].localeCompare(b[sortingNon.field])
            );
        }

        //Current Page slice
        return computedNonTeamUsers.slice(
            (currentPageNon - 1) * ITEMS_PER_PAGE,
            (currentPageNon - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [nonteamusers, currentPageNon, searchNon, sortingNon]);

    

      
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      
      
        const showTeamDetails = (teamId,teamName) => {
            // get team details and show
            console.log('inside show team details '+teamId);            
                    
            showLoader();
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamUsers?TeamId="+teamId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //Show all team Users TeamId UserId AddedOn UserName
                            setComments(response.Data);
                            // add teamname name // teamName
                            document.getElementById('lblTeamName').innerHTML = teamName;                        
                            console.log(response);
                            showNonTeamUsers(teamId,UserID);
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                    console.log('Something went wrong, Try Again.');
                    hideLoader();
                });
          }
    
          const deleteTeamUser = (teamId,userId) =>{      
            var result = window.confirm("Are you sure you want to remove this user?");
            if (!result) {
                return;
            }
            teamId = token;
            var txtTeamName = document.getElementById('lblTeamName').innerHTML; 
            showLoader();
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/RemoveTeamUser?UserId="+userId+"&TeamId="+teamId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null && response.Data != "") {
                            //team created load  all teams
                            customAlertMessage('success', "User removed successfully");
                            showTeamDetails(teamId,txtTeamName);
                        }
                        else {                        
                            customAlertMessage('error', "Failed to remove user from team, Please try again.");
                        }
                    }
                    else {
                        customAlertMessage('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                    hideLoader();
                    customAlertMessage('error', 'Something went wrong, Try Again.');
                });
          }
          // delete team users end
    
          const showNonTeamUsers = (teamId,UserId) =>{
            //tableNonTeamUsers
            console.log('inside show non team Users '+teamId);
            document.getElementById('hfDetailTeamId').value = teamId;        
            //GetTeamOutsideUsers(string MasterId, string TeamId)
            showLoader();
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetTeamOutsideUsers?MasterId="+UserId+"&TeamId="+teamId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //
                            setnonTeamUsers(response.Data);
                            // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId                        
                            console.log('Inside show all non team users response');
                            console.log(response);
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                    hideLoader();
                });
          }
    
          const addUserToTeam = (userId) =>{
    
            var teamId = token; //document.getElementById('hfDetailTeamId').value;
            var txtTeamName = document.getElementById('lblTeamName').innerHTML; 
            //InsertTeamUser(Guid TeamId, Guid UserId)
            showLoader();
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/AddTeamUser?TeamId="+teamId+"&UserId="+userId,
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
    
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null && response.Data != "") {
                            //team created load  all teams
                            customAlertMessage('success', "User added successfully");
                            showTeamDetails(teamId,txtTeamName);
                        }
                        else {                        
                            customAlertMessage('error', "Failed, Please try again.");
                        }
                    }
                    else {
                        customAlertMessage('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                    hideLoader();
                    customAlertMessage('error', 'Something went wrong, Try Again.');                    
                });
          }

    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}

            <input type="hidden" id="hfDetailTeamId"></input>
            <div className='row'>
                <div className="col-12">
                    <b>Team Name:</b> <label id="lblTeamName"></label>       
                    <span className="float-end mt-20">
                        <Link to={"/teamsuserslog?tId="+token+"&tN="+tName} className='common-save pad-reduce'>
                                Team Users Log
                        </Link>
                        &nbsp;
                        <a href="javascript:;" className="common-save pad-reduce" data-bs-toggle="modal" data-bs-target="#teamdetailModal">Add Users to Team</a>
					</span>             
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <br></br>
                </div>
            </div>
            
            <div className="row w-100">                
                <div className="col mb-3 col-12 text-center">
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3" style={{marginTop:'9px',textAlign:"left"}}>
                            <b>Team Members: <label>{totalItems > 0 ? totalItems + " User(s) Found": ""} <span> </span></label></b>
                        </div>
                    </div>
                    <div className="row">
                    <div className="rightside-bar pr5 nominheight">
                    <table className="table table-hover common-table">
                        <Header
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                        {commentsData.length > 0 ? commentsData.map((comment,index) => (

                            <tr>
                                <td>{((currentPage - 1) * ITEMS_PER_PAGE) + index+1}</td>
                            <th scope="row" key={comment.UserId}>
                                {comment.FirstName}
                            </th>
                            <td>{comment.LastName}</td>
                            <td>{comment.EmailId}</td>
                            <td>{moment.utc(comment.AddedOn).local().format('MM-DD-YYYY h:mm A')}</td>
                            <td>
                                &nbsp;<span style={{color:'red',cursor:'pointer'}} 
                                data-refid={comment.UserId} onClick={() => deleteTeamUser(comment.TeamId,comment.UserId)} >
                                    <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>                                
                            </td>
                            </tr>
                        )
                        ): 
                        <tr>
                        <td colSpan={6} style={{textAlign:"center"}}>
                        <label>No records to display.</label>
                        </td>
                    </tr>   
                        }
                           
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </div>  

            <div className="modal fade" id="teamdetailModal" tabIndex={-1} aria-labelledby="teamdetailModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
<div className="modal-dialog">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="teamdetailLabel">Add Users to Team</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">   
            <div className="col-12">
            {/* <div className='row'>
                <div className="col-12">
                    <br/>
                    <b>Add Users to Team:</b>
                </div>
            </div>                            */}
                                    
            {/* non team start    */}
            <div className="row">
                <div className="col-3" style={{marginTop:'10px',textAlign:"left", marginBottom:"10px"}}>
                    <b><label>{totalItemsNon > 0 ? totalItemsNon + " User(s) Found": ""} <span> </span></label></b>
                </div>
            </div>
            <div className="row w-100">
                <div className="col mb-3 col-12 text-center">
                    <div className="row">
                        <div className="col-md-6">
                            <Pagination
                                total={totalItemsNon}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPageNon}
                                onPageChange={page => setCurrentPageNon(page)}
                            />
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearchNon(value);
                                    setCurrentPageNon(1);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-12" style={{display:"flex",justifyContent:"center",alignItems:"center",maxHeight:"420px", overflowY:"auto"}}>
                    <table className="table table-hover common-table">
                        <Header
                            headers={headersNon}
                            onSorting={(field, order) =>
                                setSortingNon({ field, order })
                            }
                        />
                        <tbody>
                        {nonTeamUsers.length > 0 ? nonTeamUsers.map((comment,index) => (

                            <tr>
                            <td>{((currentPageNon - 1) * ITEMS_PER_PAGE) + index+1}</td>
                            <th scope="row" key={comment.UserId}>
                                {comment.FirstName}
                            </th>
                            <td>{comment.LastName}</td>
                            <td>{comment.EmailId}</td>                           
                            <td>
                                <span style={{color:'green',cursor:'pointer'}} data-refid={comment.UserId} onClick={() => addUserToTeam(comment.UserId)}>Add to Team</span>                                
                            </td>
                            </tr>
                        )
                        ): 
                        <tr>
                        <td colSpan={5} style={{textAlign:"center"}}>
                        <label>No records to display.</label>
                        </td>
                    </tr>   
                        }
                           
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</div>






           
            {loader}
        </>
    );
};

export default TeamDetails;