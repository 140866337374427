import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown,faArrowUp, faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons'

const Header = (props) => {    
    const Sort = props.defaultsort ?? "";
    const Sortorder = props.defaultsortorder ?? "";
    const [sortingField, setSortingField] = useState(Sort);
    const [sortingOrder, setSortingOrder] = useState(Sortorder);

    const onSortingChange = (field) => {
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        props.onSorting(field, order);
    };

    return (
        <thead>
            <tr>                
            {props.headers.length > 0 ? props.headers.map((mainD) => ( 
                <th
                key={mainD.name}
                onClick={() =>
                    mainD.sortable ? onSortingChange(mainD.field) : null
                }
            >
                {mainD.name.toUpperCase()}

                {/* {sortingField && sortingField === mainD.field && (
                    <FontAwesomeIcon
                        icon={
                            sortingOrder === "" ? faSort :
                            sortingOrder === "asc"
                                ? faSortAsc
                                : faSortDesc
                        }
                    />
                )} */}
                          {sortingField && sortingField === mainD.field ?
                    <FontAwesomeIcon
                        icon={                            
                            sortingOrder === "asc"
                                ? faSortAsc
                                : faSortDesc
                        }/>
                        : mainD.sortable ? <FontAwesomeIcon icon={faSort} /> : null
                    
                }
            </th>
            )
            ): null}
            </tr>
        </thead>
    );
};

export default Header;