import { Outlet, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/metisMenu.css?v=5';
import '../css/style1.css?v=5';
import '../css/colors/default.css?v=5';
// import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse,faBarsStaggered, faMailReply, faEnvelope, faUpload, faImages, faVideoCamera, faChalkboard } from '@fortawesome/free-solid-svg-icons'

import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap.bundle';
import React from "react";
import loader from "../img/sample.gif";
import { faFacebook, faInstagram, faPinterest, faTiktok, faTwitter } from "@fortawesome/free-brands-svg-icons";
import "../index"
import Cookies from "js-cookie";
import logo from "../img/logo.png";
import logosmall from "../img/logosmall.png";
import {UserID,Name,Companyid,Role,Rolename, ProfileImg} from "../User";


class Adminlayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0
    };
  }
  componentDidMount(){
    this.documentinit();    
  }
  documentinit = () => {
    $(".open_miniSide").on("click",function () {
      $(".sidebar").toggleClass("mini_sidebar");
      $(".main_content ").toggleClass("full_main_content");
      $(".footer_part ").toggleClass("full_footer");
      //window.getSelection().anchorNode.wholeText => plagiarism
  });
  this.toggleUsersTeams();
  }

  toggleUsersTeams = () =>{
    if(Role == "1"){
        document.getElementById('lnkManageUsers').style.display ="block";
        document.getElementById('lnkManageTeams').style.display ="block";
        document.getElementById('lnkManagePayment').style.display ="block";
        //lnkManagePayment
    }
    else if(Role == "2"){
      document.getElementById('lnkManagePayment').style.display ="block";
      document.getElementById('lnkManageUsers').style.display ="none";
        document.getElementById('lnkManageTeams').style.display ="none";
    }
    else{
        document.getElementById('lnkManageUsers').style.display ="none";
        document.getElementById('lnkManageTeams').style.display ="none";
        document.getElementById('lnkManagePayment').style.display ="none";
    }
  }

  handlemenuClick = (event) => {
    this.setState({active: event.target.closest("li").id});    
  }
  logout = () =>{
    Cookies.remove("userInfo");
    Cookies.remove("refresh_token");
    window.location.href = window.location.origin;
  }
  render(){
  return (
    <>
    <div className="crm_body_bg">
    <nav className="sidebar no-print">
      <div className="logo d-flex justify-content-center">
        {/* <a className="large_logo" style={{textDecoration:"none"}}><b style={{fontSize:"24px",color:"#000"}}>AI Assistant</b></a>
        <a className="small_logo" style={{textDecoration:"none"}}><b style={{fontSize:"24px",color:"#000"}}>AI</b></a> */}
        <Link to="/" className="large_logo">
            <img src={logo} />
        </Link>
        {/* <a className="large_logo"><img src={logo} /></a> */}
        <Link to="/" className="small_logo" style={{textAlign:"center"}}>
            <img src={logosmall} style={{width:"50%"}}/>
        </Link>
        {/* <a className="small_logo" style={{textAlign:"center"}}><img src={logosmall} style={{width:"50%"}}/></a> */}

        <div className="sidebar_close_icon d-lg-none">        
          <i className="ti-close"></i>
        </div>
      </div>
      <ul id="sidebar_menu">
        <li id="0" className={this.state.active == "0" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faHouse} />
            </div>
            <div className="nav_title">
              <span>Dashboard </span>
            </div>
          </Link>
          </li>
          <li id="1" style={{display:"none"}} className={this.state.active == "1" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/emailtemplate">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="nav_title">
              <span>Email </span>
            </div>
          </Link>
          </li>
          <li id="2" style={{display:"none"}} className={this.state.active == "2" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/texttoimage">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faImages} />
            </div>
            <div className="nav_title">
              <span>Text to Image </span>
            </div>
          </Link>
          </li>
          <li id="3" style={{display:"none"}} className={this.state.active == "3" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/uploadimage">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faUpload} />
            </div>
            <div className="nav_title">
              <span>Upload Image </span>
            </div>
          </Link>
          </li>

          <li id="4" style={{display:"none"}} className={this.state.active == "4" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/facebook">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faFacebook} />
            </div>
            <div className="nav_title">
              <span>Facebook</span>
            </div>
          </Link>
          </li>

          <li id="5" style={{display:"none"}} className={this.state.active == "5" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/instagram">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faInstagram} />
            </div>
            <div className="nav_title">
              <span>Instagram</span>
            </div>
          </Link>
          </li>

          <li id="6" style={{display:"none"}} className={this.state.active == "6" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/twitter">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faTwitter} />
            </div>
            <div className="nav_title">
              <span>Twitter</span>
            </div>
          </Link>
          </li>

          <li id="7" style={{display:"none"}} className={this.state.active == "7" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/tiktok">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faTiktok} />
            </div>
            <div className="nav_title">
              <span>TikTok</span>
            </div>
          </Link>
          </li>

          <li id="8" style={{display:"none"}} className={this.state.active == "8" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/pinterest">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faPinterest} />
            </div>
            <div className="nav_title">
              <span>Pinterest</span>
            </div>
          </Link>
          </li>


          <li style={{display:"none"}} id="9" className={this.state.active == "9" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/aiavatar">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faVideoCamera} />
            </div>
            <div className="nav_title">
              <span>AI Avatar </span>
            </div>
          </Link>
        </li>
        <li id="10" style={{display:"none"}} className={this.state.active == "10" ? "active" : ""} onClick={this.handlemenuClick}>
          <Link to="/imagechat">
            <div className="nav_icon_small">
                <FontAwesomeIcon icon={faChalkboard} />
            </div>
            <div className="nav_title">
              <span>Image Chat<sup style={{color:"#599bdb",fontSize:"12px",marginLeft:"5px"}}>Beta</sup></span>
            </div>
          </Link>
        </li>
      </ul>
      <div className="footer_part">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer_iner text-center">
                <p>Copyright © planetRE. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <section className="main_content dashboard_part large_header_bg">
      <div className="container-fluid g-0 no-print">
        <div className="row">
          <div className="col-lg-12 p-0 ">
            <div className="header_iner d-flex justify-content-between align-items-center">
              <div className="sidebar_icon d-lg-none">
                <i className="ti-menu"></i>
              </div>
              <div className="line_icon open_miniSide d-none d-lg-block" style={{cursor:"pointer"}}>                
                    <FontAwesomeIcon icon={faBarsStaggered} />
              </div>
              <div className="header_right d-flex justify-content-between align-items-center">
                <div className="profile_info">
                  <img src={ProfileImg} alt="#" />
                  <div className="profile_info_iner">
                    <div className="profile_author_name">
                      <p>{Rolename}</p>
                      <h5>{Name}</h5>
                    </div>
                    <div className="profile_info_details">
                      <Link to="/profile">
                        Profile
                      </Link>
                      <Link id="lnkManagePayment" to="/paymentupdate">
                        Manage Payment
                      </Link>
                      <Link id="lnkManageUsers" to="/manageusers">
                        Manage Users
                      </Link>
                      <Link id="lnkManageTeams" to="/manageteams">
                        Manage Teams
                      </Link>
                      <a href="javascript:;" onClick={this.logout}>Log Out </a>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
      
      
    </section>
    </div>
    </>
  )
    }
};

export default Adminlayout;