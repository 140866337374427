import Cookies from 'js-cookie'
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import * as axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faCrosshairs, faEye, faRemove, faRotate, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './css/login.css';
import './css/plans.css';
import logo from "./img/logo.png";
// import PaymentGateway from './PaymentGateway';

export const getAccessToken = () => Cookies.get('userInfo');
export const getRefreshToken = () => Cookies.get('refresh_token');
export const isAuthenticated = () => !!getAccessToken();

class Plans extends React.Component {

    componentDidMount(){
        //document.getElementById('toggler').addEventListener('click', this.showHidePassword);  
        let yearlyPlans= false;        
        const DISCOUNT = 0.25; 
        let slider = document.getElementById("singleuserslider");
        let price = document.getElementById("singleuserprice");
        let pageviews = document.getElementById("singleuserpageviews");
        
        // let toggle = document.getElementById("toggle");
        let text = document.getElementById("singleuserperiod");        
        
        var prices =[29, 59,75,99,149,179,269,349];
        
        pageviews.innerHTML = "20k";
        
        // function discount() {
        //   text.innerHTML = "";
        
        //   if (yearlyPlans) {
        //     text.innerHTML = "year";
        //     for (let i = 0; i < prices.length; i++) {
        //       prices[i] = prices[i] - prices[i] * DISCOUNT;
        //     }
        //     this.listener();
        //   } else {
        //     text.innerHTML = "month";
        //     prices = [29, 59, 75, 99];
        //     this.listener();
        //   }
        // }
        
        
        
        // slider.addEventListener("mousedown", () => {
        //   this.listener();
        //   slider.addEventListener("mousemove", this.listener);
        // });
        // slider.addEventListener("mouseup", () => {
        //   slider.removeEventListener("mousemove", this.listener);
        // });
        
        // slider.addEventListener("keydown", this.listener);
        
        // slider.oninput = () => {
        //   var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
        //   slider.style.background =
        //   "linear-gradient(to right, #3368fa 0%, #3368fa " +
        //   value +
        //   "%, #eaeefb " +
        //   value +
        //   "%, #eaeefb 100%)";

        //   this.listener(slider.value);
        // };     

        //
        let teamuserslider = document.getElementById("teamuserslider");
        let teamuserprice = document.getElementById("teamuserprice");
        let teamuserpageviews = document.getElementById("teamuserpageviews");
        
        // let toggle = document.getElementById("toggle");
        let teamusertext = document.getElementById("teamuserperiod");        
        
        var teamuserprices = [345,495,745,995];
        
        teamuserpageviews.innerHTML = "80k";
        
        // function discount() {
        //     teamusertext.innerHTML = "";
        
        //   if (yearlyPlans) {
        //     teamusertext.innerHTML = "year";
        //     for (let i = 0; i < teamuserprices.length; i++) {
        //         teamuserprices[i] = teamuserprices[i] - teamuserprices[i] * DISCOUNT;
        //     }
        //     teamuserlistener();
        //   } else {
        //     teamusertext.innerHTML = "month";
        //     teamuserprices = [65, 99, 269, 559];
        //     teamuserlistener();
        //   }
        // }
        
        // var teamuserlistener = function () {
        //   window.requestAnimationFrame(function () {
        //     switch (teamuserslider.value) {
        //       case "1":
        //         teamuserprice.innerHTML = Number(teamuserprices[0]).toFixed(2);
        //         teamuserpageviews.innerHTML = "22K";
        //         break;
        //       case "2":
        //         teamuserprice.innerHTML = Number(teamuserprices[1]).toFixed(2);
        //         teamuserpageviews.innerHTML = "37K";
        //         break;
        //       case "3":
        //         teamuserprice.innerHTML = Number(teamuserprices[2]).toFixed(2);
        //         teamuserpageviews.innerHTML = "63K";
        //         break;
        //       case "4":
        //         teamuserprice.innerHTML = Number(teamuserprices[3]).toFixed(2);
        //         teamuserpageviews.innerHTML = "79K";
        //         break;
        //     }        
        //   });
        // };
        
        // teamuserslider.addEventListener("mousedown", function () {
        //     teamuserlistener();
        //   teamuserslider.addEventListener("mousemove", teamuserlistener);
        // });
        // teamuserslider.addEventListener("mouseup", function () {
        //     teamuserslider.removeEventListener("mousemove", teamuserlistener);
        // });
        
        // teamuserslider.addEventListener("keydown", teamuserlistener);
        
        // teamuserslider.oninput = function () {
        //   var value = (this.value - this.min) / (this.max - this.min) * 100;
        //   this.style.background =
        //   "linear-gradient(to right, #3368fa 0%, #3368fa " +
        //   value +
        //   "%, #eaeefb " +
        //   value +
        //   "%, #eaeefb 100%)";
        // }; 
        //
    }

    logoutPay = () =>{
        Cookies.remove("userInfo");
        Cookies.remove("refresh_token");
        Cookies.remove("g_key");
        //document.getElementById('lnkSignInPage').click();
        window.location.href="/";
      }

    sliderchanged = (e) => {
        let slider = document.getElementById("singleuserslider");
        let price = document.getElementById("singleuserprice");
        let pageviews = document.getElementById("singleuserpageviews");
        var slidval = e.target.value;
        var prices = [29, 59,75,99,149,179,269,349];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            console.log(slidval);
            document.getElementById('premiumfeaturesul').style.display = 'block';
            var spanSBP = document.getElementById('spanSBP');
          switch (slidval) {
            case "0":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "20K";
              document.getElementById('premiumfeaturesul').style.display = 'none';
              spanSBP.innerHTML="Base";
              break;
            case "1":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "20K";
              document.getElementById('premiumfeaturesul').style.display = 'none';
              spanSBP.innerHTML="Base";
              break;
            case "2":
              price.innerHTML = Number(prices[1]);
              pageviews.innerHTML = "40K";
              spanSBP.innerHTML="Premium";
              break;
            case "3":
            price.innerHTML = Number(prices[2]);
              pageviews.innerHTML = "80K";
              spanSBP.innerHTML="Premium 2";
              break;
            case "4":
              price.innerHTML = Number(prices[3]);
              pageviews.innerHTML = "100K";
              spanSBP.innerHTML="Premium 3";
              break;
              case "5":
              price.innerHTML = Number(prices[4]);
              pageviews.innerHTML = "120K";
              spanSBP.innerHTML="Premium 4";
              break;
              case "6":
              price.innerHTML = Number(prices[5]);
              pageviews.innerHTML = "160K";
              spanSBP.innerHTML="Premium 5";
              break;
              case "7":
              price.innerHTML = Number(prices[6]);
              pageviews.innerHTML = "250K";
              spanSBP.innerHTML="Premium 6";
              break;
              case "8":
              price.innerHTML = Number(prices[7]);
              pageviews.innerHTML = "350K";
              spanSBP.innerHTML="Premium 7";
              break;
          }        
        });
    }
    // listener = (slidval) => {
    //     let slider = document.getElementById("singleuserslider");
    //     let price = document.getElementById("singleuserprice");
    //     let pageviews = document.getElementById("singleuserpageviews");
        
    //     // let toggle = document.getElementById("toggle");
    //     let text = document.getElementById("singleuserperiod");        
        
    //     var prices = [29, 59, 75, 99];
    //     window.requestAnimationFrame(function(){
    //         console.log(slidval);
    //       switch (slidval) {
    //         case "1":
    //           price.innerHTML = Number(prices[0]).toFixed(2);
    //           pageviews.innerHTML = "22K";
    //           break;
    //         case "2":
    //           price.innerHTML = Number(prices[1]).toFixed(2);
    //           pageviews.innerHTML = "37K";
    //           break;
    //         case "3":
    //           price.innerHTML = Number(prices[2]).toFixed(2);
    //           pageviews.innerHTML = "63K";
    //           break;
    //         case "4":
    //           price.innerHTML = Number(prices[3]).toFixed(2);
    //           pageviews.innerHTML = "79K";
    //           break;
    //       }        
    //     });
    //   };

    sliderchangedteamuser = (e) => {
        //document.getElementById("teamuserslider").value
        let slider = document.getElementById("teamuserslider");
        let price = document.getElementById("teamuserprice");
        let pageviews = document.getElementById("teamuserpageviews");
        var slidval = e.target.value;
        var prices = [345,495,745,995];
             var value = (slider.value - slider.min) / (slider.max - slider.min) * 100;
          slider.style.background =
          "linear-gradient(to right, #3368fa 0%, #3368fa " +
          value +
          "%, #eaeefb " +
          value +
          "%, #eaeefb 100%)";
        window.requestAnimationFrame(function(){
            document.getElementById('premiumfeaturesulteam').style.display = 'block';
            var spanTBP = document.getElementById('spanTBP');
          switch (slidval) {
            case "0":
                price.innerHTML = Number(prices[0]);
                pageviews.innerHTML = "80K";
                document.getElementById('premiumfeaturesulteam').style.display = 'none';
                break;
            case "1":
              price.innerHTML = Number(prices[0]);
              pageviews.innerHTML = "80K";
              document.getElementById('premiumfeaturesulteam').style.display = 'none';
              spanTBP.innerHTML="Base";
              break;
            case "2":
              price.innerHTML = Number(prices[1]);
              pageviews.innerHTML = "100K";
              spanTBP.innerHTML="Premium";
              break;
                case "3":
                  price.innerHTML = Number(prices[2]);
                  pageviews.innerHTML = "320K";
                  spanTBP.innerHTML="Premium 2";
                  break;
                case "4":
                  price.innerHTML = Number(prices[3]);
                  pageviews.innerHTML = "750K";
                  spanTBP.innerHTML="Premium 3";
                  break;
          }        
        });
    }

    setPlans = (planTypeSel) =>{
        var userI1 = getAccessToken();
        var decodedUser1 = decodeURIComponent(userI1);
        var pLevelSel =  "1";
        var roleId = "2";
        if(planTypeSel == "1"){
            pLevelSel = document.getElementById("singleuserslider").value;
        }
        else if(planTypeSel == "2"){
            pLevelSel = document.getElementById("teamuserslider").value;
            roleId="1";
        }
        var parsedRes1 = JSON.parse(decodedUser1);
        parsedRes1.PlanType = 0;//planTypeSel;
        parsedRes1.PlanLevel =0;// pLevelSel;
        parsedRes1.SelectedPlanType = planTypeSel;
        parsedRes1.SelectedPlanLevel = pLevelSel;
        parsedRes1.Role = roleId;     
        const serializedState1 = JSON.stringify(parsedRes1);
        Cookies.set('userInfo', serializedState1); 
        document.getElementById("btnUpgradeSub").click();
    }

    render() {
        return (
            <div>
            <div className="container-fluid mt-5 pt-4">
                <div className="row">
                    <div className="col-12 text-center plansui">
                    <img src={logo}/>
                    <h4>PLANS & PRICING</h4>
                    <h2>Ready to get started?</h2>
                    <Link to="/paymentgateway" style={{display:"none"}} id='btnUpgradeSub' className="common-save float-start">Go</Link>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row justify-content-md-center">
                    {/* <div className="col-12">
                    <div className="container-wrap container-wrap1">
                        <div className="toggle-container">
                            Monthly Billing
                            <label className="switch">
                            <input type="checkbox" id="toggle" onclick="discount()">
                            <span className="slide round"></span>
                            </label>
                            <br className="show">
                            Yearly Billing
                        </div>
                    </div>
                    </div> */}
                    <div className="col-4">
                    <div className="container-wrap">
                        <div className="topupper">
                            <h4>Single User</h4>
                            <p>For an individual</p>
                            <p>
                                Lite usage and progressively grow your subscription with increased content needs.
                            </p>
                        </div>
                        <div className="upper">
                            <div className="left">
                                <span id="singleuserpageviews">20k</span> words/mo
                                {/* <span id="singleuserimageviews">0</span> Images/mo */}
                            </div>
                            <div className="right">
                                <span className="price">$<span id="singleuserprice">29</span></span> / <span id="singleuserperiod">month</span>
                            </div>
                        </div>
                        <div className="slider">
                            <input type="range" min="1" max="8" id="singleuserslider" onChange={this.sliderchanged} defaultValue="1"></input>
                            <center><span id="spanSBP">Base</span></center>
                        </div>
                        <div className="startbutton">
                            <a onClick={() => this.setPlans("1")} className="start-btn">Start Free</a>
                            <h5>2 weeks free trial with 3k words and 5 Images</h5>
                        </div>
                        <div className="bottom" style={{display:"block", minHeight:"380px"}}>
                        <h4>FEATURES</h4>
                            <ul id="premiumfeaturesul" style={{display:'none'}}>
                                <li><span>Grammar Check</span></li>
                                <li><span>Copyright Check</span></li>
                                <li></li>
                            </ul>
                            <strong><p style={{fontSize:"14px"}}>Contents with ChatGPT for</p></strong>
                            <ul>
                                <li><span>Email Campaigns</span></li>
                                <li><span>Facebook Posts/Ads</span></li>
                                <li><span>Instagram Posts</span></li>
                                <li><span>Twitter Posts</span></li>
                                <li><span>TikTok Posts</span></li>
                                <li><span>Pinterest Posts</span></li>
                            </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-4">	
                        <div className="container-wrap">
                            <div className="topupper">
                                <h4>Team</h4>
                                <p>For teams (up to 5 users)</p>
                                <p>
                                    Ideal for teams for sharing<br/> and collaboration.
                                </p>
                            </div>
                            <div className="upper">
                            <div className="left">
                                <span id="teamuserpageviews"></span> words/mo
                            </div>
                            <div className="right">
                                <span className="price">$<span id="teamuserprice">345</span></span> / <span id="teamuserperiod">month</span>
                            </div>
                            </div>
                            <div className="slider">
                            <input type="range" min="1" max="4" onChange={this.sliderchangedteamuser} defaultValue="1" id="teamuserslider"></input>
                            <center><span id="spanTBP">Base</span></center>
                            </div>
                    <div className="startbutton">
                        <a onClick={() => this.setPlans("2")} className="start-btn">Start Free</a>
                        <h5>2 weeks free trial with 3k words and 5 Images</h5>
                    </div>
                            <div className="bottom" style={{display:"block", minHeight:"380px"}}>
                            <h4>FEATURES</h4>
                                <ul id="premiumfeaturesulteam" style={{display:'none'}}>
                                    <li><span>Grammar Check</span></li>
                                    <li><span>Copyright Check</span></li>
                                    <li></li>
                                </ul>
                                <strong><p style={{fontSize:"14px"}}>Sharing and Collaboration of<br/> Contents with ChatGPT for</p></strong>
                                <ul>
                                    <li><span>Email Campaigns</span></li>
                                    <li><span>Facebook Posts/Ads</span></li>
                                    <li><span>Instagram Posts</span></li>
                                    <li><span>Twitter Posts</span></li>
                                    <li><span>TikTok Posts</span></li>
                                    <li><span>Pinterest Posts</span></li>
                                </ul>
                            </div>
                        </div>			
                    </div>
                    {/* <div className="col-4">	
                        <div className="container-wrap">
                            <div className="topupper">
                                <h4>Business</h4>
                                <p>For growing teams and businesses</p>
                                <p>
                                    The AI built for teams with the ability to learn your brand voice, and the support you need to scale.
                                </p>
                            </div>
                            <div className="upper">
                            <div className="left">
                                <span id="pageviews"></span> words/mo
                            </div>
                            <div className="right">
                                <span className="price">$<span id="price">16.00</span></span> / <span id="period">month</span>
                            </div>
                            </div>
                            <div className="slider">
                            <input type="range" min="1" max="5" value="3" id="slider">
                            </div>
                    <div className="startbutton">
                        <a className="start-btn">Start Free</a>
                        <h5>5-day free trial with 10k credits</h5>
                    </div>
                            <div className="bottom">
                        <h4>Quick look at features:</h4>
                            <ul>
                                <li><span>Unlimited websites</span></li>
                                <li><span>100% data ownership</span></li>
                                <li><span>Email reports</span></li>
                            </ul>
                            </div>
                        </div>			
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-sm-12 text-center mt-2 anchor-blue">
                    <a href="javascript:;" onClick={this.logoutPay}>Log Out </a>                                        
                    </div>  
                </div>  
            </div>
            </div>
            )
            }
  };
export default Plans;