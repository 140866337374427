import React, { useEffect, useState, useMemo } from "react";
//import Header from "../components/DataTable/Header";
import { Header, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import * as axios from 'axios';
import moment from 'moment';
import {UserID,Companyid,CompanyName,Name} from "../User";
import { faMailBulk, faTrash } from '@fortawesome/free-solid-svg-icons'
import { json, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";
//import ExampleCal from "../components/Calender";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import ExternalInfo from "components/ExternalInfo";

const UsersLogNew = () => {
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const ITEMS_PER_PAGE = 10;
    var fromDate="";
    var toDate ="";
    var filterType="0";

    const headers = [
        // {name:"No"},
        { name: "First Name", field: "FirstName", sortable: true },
        { name: "Last Name", field: "LastName", sortable: true },
        { name: "Email", field: "EmailId", sortable: true },
        { name: "Created On ("+ new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3] + ")", field: "CreatedDate", sortable: true },
        { name: "Status", field: "DeletedUser", sortable: true },
        { name: "Deleted On ("+ new Date().toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3]+ ")", field: "DeletedOn", sortable: true }
    ];

    useEffect(() => {
        const getData = () => {
            showLoader();
//GetAllAdminUsers(string companyId,string Filter,string From,string To)
            var config = {
                method: 'post',
                "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAllAdminUsers?companyId="+Companyid+"&Filter=&From=&To=",
                headers: {
                   'Content-Type': 'application/json' 
                }
            };
        
            axios(config)
                .then(function (resp) {
                    
                    var response = (resp.data);
                    if (response.Status == "200") {
                        if (response.Data != null) {
                            //
                            
                            // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                            setComments(response.Data);
                            console.log('Inside show users response');
                            console.log(response);                            
                        }
                        else {                        
                            console.log('Failed to load data.');
                        }
                    }
                    else {
                      console.log('error', response.Message); // 'success','failure','info'
                    }
                    hideLoader();
                })
                .catch(function (error) {
                  console.log('Something went wrong, Try Again.');
                  hideLoader();
                });
        };

        getData();
    }, []);


    const applyFilterDate = () =>{
        document.getElementById("hfFromFilter").value ="1";
        getUsersData();
    }

    const getUsersData = () => {
        showLoader();
        var isFromFilter = document.getElementById("hfFromFilter").value;
        if(isFromFilter == "1"){
            if(startDate !=null && startDate !=""){
            fromDate = moment(startDate).format("MM/DD/YYYY");
            }
            else{
                fromDate="";
            }            

            if(endDate !=null && endDate !=""){
                toDate = moment(endDate).format("MM/DD/YYYY");
            }
            else{
                toDate="";
            }
        }
        else{
            fromDate="";
            toDate="";
        }

        console.log(fromDate);
         console.log(toDate);
         var editFilterOption = document.getElementById("ddlFilterType").value;

        var config = {
            method: 'post',
            "url": `${process.env.REACT_APP_API_URL}` +"/api/AIUserManager/GetAllAdminUsers?companyId="+Companyid 
            + "&Filter="+editFilterOption+"&From="+fromDate+"&To="+toDate+"",
            headers: {
               'Content-Type': 'application/json' 
            }
        };
    
        axios(config)
            .then(function (resp) {
                
                var response = (resp.data);
                if (response.Status == "200") {
                    if (response.Data != null) {
                        //
                        
                        // ud.UserId, ud.FirstName, ud.LastName, ud.EmailId   
                        setComments(response.Data);
                        console.log('Inside show users response');
                        console.log(response);                            
                    }
                    else {                        
                        console.log('Failed to load data.');
                    }
                }
                else {
                  console.log('error', response.Message); // 'success','failure','info'
                }
                hideLoader();
            })
            .catch(function (error) {
              console.log('Something went wrong, Try Again.');
              hideLoader();
            });
    };

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.FirstName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.LastName.toLowerCase().includes(search.toLowerCase()) || 
                    comment.EmailId.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);    

      
    const customAlertMessage = (icon, message) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
    
        Toast.fire({
          icon: icon, //'success',
          title: message, // 'Signed in successfully'
        });
      };
      
      const onFilterType = () =>{
        console.log('inside filter change');
        filterType = document.getElementById("ddlFilterType").value;
        //getUsersData();
        //var output = {format(startDate, "dd-MM-yyyy")};
        //var op2 = moment.date(startDate).format("MM/dd/yyyy");
        // var op = moment.utc(startDate).format("MM/dd/yyyy");
        // console.log(op);
        //console.log("op2" +op2);
      }

      const resetResults = () =>{
        setStartDate("");
        setEndDate("");
        setStartDate(null);
        setEndDate(null);
        document.getElementById("ddlFilterType").value = "0";
        document.getElementById("hfFromFilter").value ="0";
        getUsersData();
        // var urlpage = window.location.href;
        // window.location.href = urlpage;
      }

    return (
        <>
            {/* <Header title="Building a data table in react" /> */}

            {/* <ExternalInfo page="datatable" /> */}

            <div id="divFilters" className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-3">
                            From: <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={startDate} showIcon isClearable onChange={(date) => setStartDate(date)} /> 
                        </div>
                        <div className="col-3">
                            To: <DatePicker className="form-control" dateFormat="MM/dd/yyyy" selected={endDate} showIcon isClearable onChange={(date) => setEndDate(date)} /> 
                        </div>
                        
                    </div>
                    {/* <div className="row">
                        <div className="col-3"> <br></br>
                            
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <br></br>
                    </div> */}
                    
                </div>
            </div>
            <div id="divUsersGrid" className="row" style={{marginTop:'20px'}}>
                        
                <div className="row w-100">
                    <div className="col mb-3 col-12">
                        <div className="row userlogcss" style={{marginBottom:"20px",alignItems:"end"}}>
                        <div className="col-4">
                        Select:<select id="ddlFilterType" className="form-control" onChange={onFilterType}>
                            <option value={0}>All Users</option>
                            <option value={1}>Current Users</option>
                            <option value={2}>Deleted Users</option>
                        </select>
                        </div>
                        <div className="col-4">
                            <br></br>
                            <input type="hidden" id="hfFromFilter" value={1}></input>
                            <a href='javascript:;' onClick={() =>resetResults()} style={{backgroundColor:'#cbd0d5',color:'#000',marginLeft:'5px'}} tabIndex={-1} className="common-save pad-reduce" >Reset</a> 
                            <a href='javascript:;' tabIndex={-1} onClick={() =>applyFilterDate()} className="common-save pad-reduce">Apply Filter</a>
                            {/* <button onClick={() =>resetResults()} style={{backgroundColor:'#cbd0d5',color:'#000',marginLeft:'5px'}} ></button> */}
                        </div>
                            <div className="col-md-4 d-flex flex-row-reverse">
                                <Search
                                    onSearch={value => {
                                        setSearch(value);
                                        setCurrentPage(1);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3" style={{marginTop:'9px',textAlign:"left"}}>
                            <b><label>{totalItems > 0 ? totalItems + " User(s) Found": ""} <span> </span></label></b>
                            </div>
                        </div>
                        <div className="row">
                        <div className="rightside-bar-editor pr5 nominheight">
                        <table className="table table-hover common-table">
                            <Header
                                headers={headers}
                                onSorting={(field, order) =>
                                    setSorting({ field, order })
                                }
                            />
                            <tbody>
                            {commentsData.length > 0 ? commentsData.map((comment,index) => (

                                <tr>
                                    {/* <td>{((currentPage - 1) * ITEMS_PER_PAGE) + index+1}</td> */}
                                    <th scope="row" key={comment.UserId}>
                                        {comment.FirstName}
                                    </th>
                                    <td>{comment.LastName}</td> 
                                    <td>{comment.EmailId}</td>
                                    {/* <td>{moment(comment.CreatedDate).format('MMM DD YYYY h:mm A')}</td> */}
                                    <td>{moment.utc(comment.CreatedDate).local().format('MM-DD-YYYY h:mm A')}</td>
                                    <td>{comment.DeletedUser != "Deleted" ? "Active" : comment.DeletedUser}</td>
                                    <td>
                                        {comment.DeletedUser == "Deleted" ? moment.utc(comment.DeletedOn).local().format('MM-DD-YYYY h:mm A'): ""}
                                    </td>
                                </tr>
                            )
                            ): 
                            <tr>
                                <td colSpan={7} style={{textAlign:"center"}}>
                                <label>No records to display.</label>
                                </td>
                            </tr>
                            }
                            
                            </tbody>
                        </table>
                        </div>
                        </div>
                        {
                            totalItems > 0 ?
                                <div className="row">
                                    <div className="col-12">
                                        <center>
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={ITEMS_PER_PAGE}
                                                currentPage={currentPage}
                                                onPageChange={page => setCurrentPage(page)}
                                            />
                                        </center>
                                    </div>
                                </div>
                            : null
                        }
                        
                    </div>
                </div>
            </div>
            {loader}
        </>
    );
};

export default UsersLogNew;