import { useEffect, useState } from "react";
import {UserID,EulaValue,Features,Role, Companyid, MasterId} from "../User";
import loader from "../img/sample.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faFile, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import $ from 'jquery';
import Cookies from "js-cookie";
import grammarlypdf from "../pdf/grammarly.pdf"
import copyscapepdf from "../pdf/copyscape.pdf"

const Integrations = () => {
const [Copyscape,SetCopyscape] = useState(Features.Plagiarism)
const [Grammarly,SetGrammarly] = useState(Features.Grammerly)
const [Zapier,SetZapier] = useState(Features.Zapier)
useEffect(() =>{
    getvalues();
});

const customAlertMessage = (icon,message) =>{
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    
    Toast.fire({
        icon: icon,//'success',
        title: message// 'Signed in successfully'
    })
}
const showoverlay = () => {
    document.getElementById('overlay').style.display = "block";
    $('body').addClass("loader");
}
const hideoverlay = () => {
    document.getElementById('overlay').style.display = "none";
    $('body').removeClass("loader");
}
const historygoback = () => {
    window.history.back();
}
const getvalues = () => {

    const axios = require('axios');
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: (`${process.env.REACT_APP_API_URL}` +'/api/ArtificialIntelligence/Getexternalintegration?userid='+(Role == 3 ? MasterId : UserID))
    };

    axios.request(config)
    .then((response) => {
    console.log(JSON.stringify(response.data));
    if (response.data != null && response.data.Status == '200') {
        if (response.data.Data.length > 0) {
            var jsonobj = response.data.Data[0];
            document.getElementById('fbzap').value = jsonobj.facebook;
            document.getElementById('instazap').value = jsonobj.instagram;
            document.getElementById('pinzap').value = jsonobj.pinterest;
            document.getElementById('twzap').value = jsonobj.twitter;
            document.getElementById('copyscape').value = jsonobj.copyscape;
            document.getElementById('copyscapename').value = jsonobj.copyscapename;
            document.getElementById('grammarly').value = jsonobj.grammarly;
            Cookies.set('g_key', jsonobj.grammarly);
        }
    }
    })
    .catch((error) => {
    console.log(error);
    });

}

const savetoDB = () => {
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('fb', document.getElementById('fbzap').value);
    data.append('tw', document.getElementById('twzap').value);
    data.append('in', document.getElementById('instazap').value);
    data.append('pi', document.getElementById('pinzap').value);
    data.append('copyscape', document.getElementById('copyscape').value);
    data.append('copyscapename', document.getElementById('copyscapename').value);
    data.append('grammarly', document.getElementById('grammarly').value);
    data.append('uid', Role == 3 ? MasterId : UserID);
    data.append('cid', Companyid);
    showoverlay();
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/saveexternalintegration',
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      if (response.data != null && response.data.Status == '200') {
        Cookies.set('g_key', document.getElementById('grammarly').value);
        customAlertMessage('success','Saved Successfully');
      }
      else{
        customAlertMessage('error', 'Something went wrong, Try Again.');
      }
      hideoverlay();
    })
    .catch((error) => {
        hideoverlay();
      console.log(error);
      customAlertMessage('error', 'Something went wrong, Try Again.');
    });
    
}
const testzap = (e) => {
    var zaptotest = e.currentTarget.dataset.zaptarget;
    var zapurl = document.getElementById(zaptotest).value;
    const axios = require('axios');
    const FormData = require('form-data');
    let data = new FormData();
    data.append('zapurl', zapurl);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Testzapclient',
      data : data
    };
    
    axios.request(config)
    .then((response) => {
        if (response.data.Status == "200") {
            customAlertMessage('success','Test message pushed to Zapier.');
          }
          else{
            customAlertMessage('error', 'Something went wrong, Try Again.');
          }
    })
    .catch((error) => {
      console.log(error);
      customAlertMessage('error', 'Something went wrong, Try Again.');
    });


}
    // render(){
        return (
        <div className="main_content_iner overly_inner dashboard-page">
        <div id="overlay">
                        <div id="loading">
                            <h2 id="loaderheading">Loading...</h2>
                            <img src={loader} />
                        </div>
                    </div>
                <div className="container-fluid p-0 ">
                  <div className="row">
        
            
        
                    <div className="col-12">
                        <div className="title-card1">
                            <h4>
                            <span
                                style={{ cursor: "pointer" }}
                                onClick={historygoback}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                                Integrations
                            </span>
                            </h4>
                        </div>
                    </div>
                    <div className="col-12" style={{marginBottom:"20px"}}>
                        <div className="row">
                            <div className="col-12 integrationcontent">
                                <p>
                                Here you can set the integration between chocolatechips.ai and other programs. The integration process is easy to set up. Please review the FAQ documents for the program you would like to integrate with. It contains detailed information that will help with the integration process. 
                                </p>
                                <ul>
                                    <li><a href={grammarlypdf} target="_blank"><FontAwesomeIcon icon={faFile} />Steps to Integrate with Grammarly</a></li>
                                    <li><a href={copyscapepdf} target="_blank"><FontAwesomeIcon icon={faFile} />Steps to Integrate with CopyScape</a></li>
                                    {/* <li><a href="#" target="_blank"><FontAwesomeIcon icon={faFile} />Steps to Integrate with Zapier for Facebook </a></li>
                                    <li><a href="#" target="_blank"><FontAwesomeIcon icon={faFile} />Steps to Integrate with Zapier for Twitter </a></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 profile-bottom'>
                        <div className='row'>
                            <div className='col-12'>
                              <div className='lefside-bar'>
                                {(Copyscape == true ?
                                <>
                                <div className='col-12 mb-30'>
                                    <label>Copyscape Name</label>
                                    <input id="copyscapename" type="text" className='common-input'/>
                                </div>
                                <div className='col-12 mb-30'>
                                    <label>Copyscape Key</label>
                                    <input id="copyscape" type="text" className='common-input'/>
                                </div>
                                </>
                                 : <><input id="copyscape" type="text" className='common-input' style={{display:'none'}}/>
                                 <input id="copyscapename" type="text" className='common-input' style={{display:'none'}}/></>
                                 )}               
                                {(Grammarly ? 
                                <div className='col-12 mb-30'>
                                    <label>Grammarly Key</label>
                                    <input id="grammarly" type="text" className='common-input'/>
                                </div> : <input id="grammarly" type="text" className='common-input' style={{display:'none'}}/>)}
                                {(Zapier ?
                                <>
                                <div className="row mb-30">
                                    <div className='col-10'>
                                        <label>Facebook Zap Webhook</label>
                                        <input id="fbzap" type="text" className='common-input'/>
                                    </div>
                                    <div className='col-2'>
                                        <a href="javascript:;" className="common-save" style={{marginTop:'28px'}} data-zaptarget="fbzap" onClick={testzap}>Test</a>
                                    </div>
                                </div>                                
                                <div className="row mb-30" style={{display:'none'}}>
                                    <div className='col-10'>
                                        <label>Instagram Zap Webhook (Business) </label>
                                        <input id="instazap" type="text" className='common-input'/>
                                    </div> 
                                    <div className='col-2'>
                                        <a href="javascript:;" className="common-save" style={{marginTop:'28px'}} data-zaptarget="instazap" onClick={testzap}>Test</a>
                                    </div>
                                </div>
                                <div className="row mb-30">
                                    <div className='col-10'>
                                        <label>Twitter Zap Webhook</label>
                                        <input id="twzap" type="text" className='common-input'/>
                                    </div>
                                    <div className='col-2'>
                                        <a href="javascript:;" className="common-save" style={{marginTop:'28px'}} data-zaptarget="twzap" onClick={testzap}>Test</a>
                                    </div>
                                </div>
                                
                            
                                <div className="row mb-30" style={{display:'none'}}>
                                    <div className='col-10'>
                                        <label>Pinterest Zap Webhook </label>
                                        <input id="pinzap" type="text" className='common-input'/>
                                        
                                    </div> 
                                    <div className='col-2'>
                                        <a href="javascript:;" className="common-save" style={{marginTop:'28px'}} data-zaptarget="pinzap" onClick={testzap}>Test</a>
                                    </div>
                                </div>
                                </>:
                                <>
                                <div style={{display:'none'}}>
                                    <input id="fbzap" type="text" className='common-input'/>
                                    <input id="instazap" type="text" className='common-input'/>
                                    <input id="twzap" type="text" className='common-input'/>
                                    <input id="pinzap" type="text" className='common-input'/>
                                </div>
                                </>
                                )}
                                </div>                      
                            </div>
                            <div className='col-12 mb-30'>
                                    {/* <a href='javascript:;' tabIndex={-1} onClick={this.updateUserInfo} className="common-save float-start">Update</a> */}
                                    
                                </div>
        
                        </div>
                    </div>
                  </div>
                  <div className='bottom-fixed'>
                      <div className="row">
                          <div className="col-12">
                                <div className="text-center">
                                <a href='javascript:;' className="common-save" onClick={savetoDB}>Save</a>
                                </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
        )
        // }
  };
  
  export default Integrations;