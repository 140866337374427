import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight,faChevronLeft,faMicrophone,faInfo,faInfoCircle, faRotateRight, faShield, faCloudArrowUp, faCircleDown, faFile, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons'
import $ from 'jquery'
import { Link } from "react-router-dom";
import React from 'react';
import { render } from '@testing-library/react';
// import copy from 'copy-to-clipboard';
import fileDownload from 'js-file-download'
import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import { copyImageToClipboard,canCopyImagesToClipboard } from 'copy-image-clipboard'
import loader from "../img/sample.gif";
import {UserID,Name,Companyid,Role,Rolename, ProfileImg,PlanType,TextToImageSub, ImageTransformSub} from "../User";
import heic2any from "heic2any";
import { v4 as uuidv4, NIL as NIL_UUID } from "uuid";

// const MySwal = withReactContent(Swal)
const issafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') == -1 &&
navigator.userAgent.indexOf('FxiOS') == -1;
class Uploadimage extends React.Component {
	constructor(props){
	super(props);
	this.state = {
		uploadedfile: null,
		processedimage: "",
		copySuccess: '',
		uploadedbase64:""
	}
	}
	componentDidMount(){
		this.documentinit()
		this.checkSubscription();
	}

	checkSubscription = () =>{
		if(PlanType > 0){
			console.log('PlanType '+ PlanType);
			console.log('TextToImageSub '+ TextToImageSub);
			console.log('ImageTransformSub '+ ImageTransformSub);
			if(!TextToImageSub){
				//this.customAlertMessage("error","Please subscribe to 'Text To Image' Subscription");				
				// lnkTextToImage
				// lnkImageTransorm
				document.getElementById("lnkTextToImage").style.display="block";
				if(ImageTransformSub){
					document.getElementById("lnkImageTransorm").style.display="none";
				}
				document.getElementById('hrefEula').click();
			}
			else{
				if(!ImageTransformSub)
				{
					document.getElementById("lnkTextToImage").style.display="none";
					document.getElementById("lnkImageTransorm").style.display="block";
					document.getElementById('hrefEula').click();				
				}
			}			
		}
	}
		
	handleDownload = (e) => {
		var url = e.currentTarget.dataset.fileurl;
		var axios = require('axios');
		axios.get(url, {
		  responseType: 'blob',
		})
		.then((res) => {
		var filename = url.substring(url.lastIndexOf('/')+1);
		fileDownload(res.data, filename);
		})
	}
	customAlertMessage = (icon,message) =>{
		const Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: false,
			didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})
		
		Toast.fire({
			icon: icon,//'success',
			title: message// 'Signed in successfully'
		})
	}
	showoverlay = () => {
		document.getElementById('overlay').style.display = "block";
		$('body').addClass("loader");
	}
	hideoverlay = () => {
		document.getElementById('overlay').style.display = "none";
		$('body').removeClass("loader");
	}
	showoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "block";
        $('body').addClass("loader");
    }
    hideoverlayiphone = () => {
        document.getElementById('overlayiphone').style.display = "none";
        $('body').removeClass("loader");
    }
	documentinit = () => {
		var self = this;
		Array.from(document.getElementById('sidebar_menu').querySelectorAll('li')).forEach(function(el) { 
			el.classList.remove('active');
		});
		document.getElementById('3').classList.add("active");
		$(".dropzone").on("change",function () {
			var tempfile = $(".dropzone")[0].files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                    self.readiphoneFile();
                }
                else{
                    self.readFile();
                }
			}
			else {
			    this.customAlertMessage('error', 'Maximum file size exceeded.');
			    return;
			}
			//self.readFile();
		  });
  
		  $('.dropzone-wrapper').on('dragover', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').addClass('dragover');
		  });
  
		  $('.dropzone-wrapper').on('dragleave', function (e) {
			e.preventDefault();
			e.stopPropagation();
			$('.dropzone-wrapper').removeClass('dragover');
		  });
		  document.querySelector('.dropzone-wrapper').addEventListener("drop", (event) => {			
			event.preventDefault();
			var tempfile = event.dataTransfer.files[0];
			var validfile = (tempfile.type === 'image/jpg'  || tempfile.type === 'image/jpeg' || tempfile.type === "image/png")
			if (!validfile && tempfile.name.split('.').pop() != "heif" && tempfile.name.split('.').pop() != "heic") {
				this.customAlertMessage('error', 'File type not supported.');
				return;
			}
			if (tempfile.size < 10485760) {
				self.state.uploadedfile = tempfile;
				if (tempfile.name.split('.').pop() == "heif" || tempfile.name.split('.').pop() == "heic") {
                self.readiphoneFileondrop(event.dataTransfer);
            }
            else{
                self.readFileondrop(event.dataTransfer);
            }
			}
			else {
				this.customAlertMessage('error', 'Maximum file size exceeded.');
				return;
			}
		});
  
		  $('.remove-preview').on('click', function () {
			var boxZone = $('.remove-preview').parents('.preview-zone').find('.top-box');
			var previewZone = $('.remove-preview').parents('.preview-zone');
			//var dropzone = $('.remove-preview').parents('.form-group').find('.dropzone');
			//$('#originalimagediv').hide();
			// boxZone.empty();
			previewZone.addClass('hidden');
			$('.dropzone-wrapper').removeClass('hidden');
			document.getElementsByClassName("dropzone")[0].value = "";
			//self.reset(dropzone);
		  });

		  $(".filterslist").on('click', function (event) {
			event.stopPropagation();
			event.stopImmediatePropagation();
			//(... rest of your JS code)
			var stylecode = event.target.dataset.stylecode;
			self.applyfilter(self.state.uploadedfile, stylecode);
			$(".filterslist").removeClass("active");
			event.currentTarget.classList.add("active");
		});
	}

	blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

    readiphoneFile = () => {
        var self = this;
        var input = $('.dropzone')[0];
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {                
                fetch(e.target.result)
            .then((res) => res.blob())
            .then((blob) => heic2any({ blob,toType: "image/jpeg",quality: 0.1 }))
            .then((conversionResult) => {
                // conversionResult is a BLOB
                // of the PNG formatted image
                var url = URL.createObjectURL(conversionResult);
                var file = new File([conversionResult],  uuidv4() + ".png");
                self.setState({ processedimage: url,uploadedfile:file });
                var wrapperZone = $(input).parent();
				var previewZone = $(input).parent().parent().find('.preview-zone');
				var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	
				wrapperZone.removeClass('dragover');
				previewZone.removeClass('hidden');
				$('.dropzone-wrapper').addClass('hidden');
                self.hideoverlayiphone();
            })
            .catch((e) => {
                // see error handling section
                self.customAlertMessage('error','Error occured, please try again.'); 
                self.hideoverlayiphone();
            });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    readiphoneFileondrop = (input) => {
        var self = this;
        
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            self.showoverlayiphone();
            reader.onload = function (e) {
                fetch(e.target.result)
                .then((res) => res.blob())
                .then((blob) => heic2any({ blob }))
                .then((conversionResult) => {
                    // conversionResult is a BLOB
                    // of the PNG formatted image
                    var url = URL.createObjectURL(conversionResult);
                    var file = new File([conversionResult],  uuidv4() + ".png");
                    self.setState({ processedimage: url,uploadedfile:file });
                    var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					$('.dropzone-wrapper').addClass('hidden');
                    self.hideoverlayiphone();
                })
                .catch((e) => {
                    // see error handling section
                    self.customAlertMessage('error','Error occured, please try again.'); 
                    self.hideoverlayiphone();
                });
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

			readFile = () => {
				var self = this;
				 var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $(input).parent();
					var previewZone = $(input).parent().parent().find('.preview-zone');
					var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				}
			}

			readFileondrop = (input) => {
				var self = this;
				 //var input = $('.dropzone')[0];

				if (input.files && input.files[0]) {
				  var reader = new FileReader();
	  
				  reader.onload = function (e) {
					self.setState({processedimage: e.target.result});
					//self.state.processedimage = e.target.result;
					//var htmlPreview ='<div class="row"><div class="col-12"><span class="float-start"><h5 class="right-header">Image 1</h5></span><span class="float-end"><i class="fa-solid fa-circle-down"></i><i class="fa-solid fa-file"></i></span></div></div><div class="row"><div class="col-12 text-center top-box"><img width="50%" src="' + e.target.result + '" />' +'<p>' + input.files[0].name + '</p></div></div>';
					var wrapperZone = $('.dropzone-wrapper');
					var previewZone = $('.preview-zone');
					//var boxZone = $(input).parent().parent().find('.preview-zone').find('.grey-box').find('.top-box');
	  
					wrapperZone.removeClass('dragover');
					previewZone.removeClass('hidden');
					//boxZone.empty();
					//boxZone.append(htmlPreview);
					$('.dropzone-wrapper').addClass('hidden');
				  };
	  
				  reader.readAsDataURL(input.files[0]);
				}
			}
	  
			reset = (e) => {
				var eleel = $('.remove-preview').parents('.form-group').find('.dropzone');
				$('#originalimagediv').hide();
				// eleel.wrap('<form>').closest('form').get(0).reset();
				// eleel.unwrap();
			}
	
			applyfilter = (file,code) => {				
				var self = this;
				this.showoverlay();
				
				var axios = require('axios');
				var FormData = require('form-data');
				// var fs = require('fs');
				var data = new FormData();
				data.append('file', file);
				data.append('code', code);
				
				var config = {
				  method: 'post',
				  url: `${process.env.REACT_APP_API_URL}` + '/api/ArtificialIntelligence/Applyfilters',
				  data : data
				};
				
				axios(config)
				.then(function (resp) {
				  console.log(JSON.stringify(resp.data));
				  var response = (resp.data);
				  if(response.Data != "")
				  {
					self.setState({ processedimage: response.Data });
					$('#actionitemsdiv').show();
					// self.customAlertMessage('success', 'Processed successfully.');
				  }
				  else{
					self.customAlertMessage('error', 'Something went wrong, Try Again.');
				  }
				   self.hideoverlay();
				})
				.catch(function (error) {
				  console.log(error);
				  self.customAlertMessage('error', 'Something went wrong, Try Again.');
				   self.hideoverlay();
				});
			}
			
			copyToClipboard = (e) => {
				const canCopy = canCopyImagesToClipboard();
				if (issafari) {
					this.copyimageforsafari(e.currentTarget.dataset.fileurl);
				}
				else if (canCopy) {
					copyImageToClipboard(e.currentTarget.dataset.fileurl)
					.then(() => {
					  console.log('Image Copied')
					  this.customAlertMessage('success','Copied!');
					})
					.catch((e) => {
					  console.log('Error: ', e.message)
					  this.customAlertMessage('error','Please try again!');
					})
				}
				else {
					this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
				}        
				// copy(e.currentTarget.dataset.fileurl);     
				e.target.focus();
			};
		
			copyimageforsafari = async(imageurl) => {
				try {
					const item = new ClipboardItem({
						'image/png': (async () => {
						  const response = await fetch(imageurl) // Reference from your source code.
						  return await response.blob()
						})(),
					  });
					  await navigator.clipboard.write([item])
					  this.customAlertMessage('success','Copied!');
				} catch (error) {
					this.customAlertMessage('error','Copy to clipboard is not allowed in this browser!');
				}
			}
			historygoback = () => {
				window.history.back();
			};
			closeTTISub = () =>{
				window.location.href="/";
			  };
		render(){
			return(
				<>
				<div className="main_content_iner overly_inner ">
				<div className="container-fluid p-0 ">
				  <div className="row">
					<div className="col-12">
						<div className="title-card1">
							<span className="float-start">
							<h4><span style={{cursor:"pointer"}} onClick={this.historygoback}><FontAwesomeIcon icon={faChevronLeft} />Image Transform</span>-<i className="small-title">Upload your image and apply filters to transform it.</i></h4>
								
							</span>
						</div>
					</div>
					<div id="overlay">
            <div id="loading">
                <h2 id="loaderheading">Generating...</h2>
                <img src={loader} />  
            </div>
        </div>
					
					<div className="col-12">
					<form action="" method="POST" enctype="multipart/form-data">
						<div className="row">
							<div className="col-12">
								<div className="dropzone-wrapper">
								<div id="overlayiphone">
                                        <div id="loading" style={{ top: "35%" }}>
                                            <h2 id="loaderheading">Generating...</h2>
                                            <img src={loader} />
                                        </div>
                                    </div> 
											  <div className="dropzone-desc">
												<h4>Drag and Drop</h4>
												<p><b>or Click to upload</b></p>										
												<FontAwesomeIcon icon={faCloudArrowUp} />
												<p>
													Supported Files: PNG, JPG, HEIF, HEIC.
												</p>
												<p>
													Size: up to 10MB
												</p>
											  </div>
											  <input type="file" name="img_logo" className="dropzone" accept="image/png, image/jpeg, image/jpg, image/heif, image/heic"></input>
											</div>
		
											<div className="preview-zone hidden">
											  <div className="box box-solid">
												<div className="grey-box">
														<div className="row">															
															<div className="col-12 top-box" id="originalimagediv">
																<div className="row">
																	<div className="col-12">
																		<span className="float-start"><h5 className="right-header">Output Image</h5></span>
																		<span className="float-end tooltip1" tooltip="Remove" flow="left">
																			<a href='javascript:;' className="btn btn-danger btn-xs remove-preview">
													  							<FontAwesomeIcon icon={faTimes} />
																			</a>
																		</span>
																		<div id="actionitemsdiv" style={{display:"none"}}>
																			<span className='float-end' data-fileurl={this.state.processedimage} onClick={this.copyToClipboard} tooltip="Copy Image" flow="down">
																				<FontAwesomeIcon icon={faCopy} />
																			</span>
																			<span className="float-end" tooltip="Download" flow="down">
																				<a href='javascript:;' onClick={this.handleDownload} data-fileurl={this.state.processedimage} ><FontAwesomeIcon icon={faCircleDown} /></a>                                            
																			</span>																			
																		</div>
																		
																		{/* {this.state.copySuccess} */}
																	</div>
																</div>
																<div className="row">
																	<div className="col-12 text-center top-box">
																		<img id="originalimage" src={this.state.processedimage}></img>
																	</div>
																</div>
														
															</div>
												</div>
												<div className="filter-thumbnail">
													<h4>Select a filter to apply to your uploaded image</h4>
													<span className="filterslist">
														<img data-stylecode="0" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/0thumb.jpg"/>
														<h5>Original</h5>
													</span>
													{/* <span className="filterslist">
														<img data-stylecode="11" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/11thumb.jpg"/>
														<h5>Mosaic</h5>
			</span> */}
													<span className="filterslist">
														<img data-stylecode="1" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/1thumb.jpg"/>
														<h5>Greyscale</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="2" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/2thumb.jpg"/>
														<h5>Sharpen</h5>
													</span>
														<span className="filterslist">
														<img data-stylecode="3" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/3thumb.jpg"/>
														<h5>Sepia</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="4" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/4thumb.jpg"/>
														<h5>Pencil Sketch Grey</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="5" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/5thumb.jpg"/>
														<h5>Color Sketch</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="6" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/6thumb.jpg"/>
														<h5>HDR</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="7" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/7thumb.jpg"/>
														<h5>Invert</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="8" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/8thumb.jpg"/>
														<h5>Summer</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="9" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/9thumb.jpg"/>
														<h5>Winter</h5>
													</span>
													<span className="filterslist">
														<img data-stylecode="10" src="https://openaiimages.s3.us-west-1.amazonaws.com/preset/10thumb.jpg"/>
														<h5>Oil Painting</h5>
													</span>
												</div>
												
												</div>
											  </div>
											</div>
		
							</div>
						</div>
					</form>
					</div>
					
					
				  </div>          
				</div>
				<a href='javascript:;' id='hrefEula' data-bs-toggle="modal"
                          data-bs-target="#eulamodal" 
                          className="text-count"
                          style={{
                            backgroundColor: "#f44336",
                            color: "#fff",
                            display: "none",
                          }}></a>  
			  </div>

			  <div className="modal fade" id="eulamodal" tabIndex={-1} aria-labelledby="listeningModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog">
          <div className="modal-content eulamodalcss">
              <div className="modal-header">
                  <h5 className="modal-title" id="listeningModalLabel"><b>Subscription:</b></h5>                  
              </div>
              <div className="modal-body" style={{display:"flex",justifyContent:"left",alignItems:"left",maxHeight:"220px",overflowY:"auto",paddingBottom:"15px"}}>   
              <div className="col-12">
                  <p>
                  'Text To Image' & 'Image Transform' Subscriptions are required to use this feature.
                  </p>
                  <p id="lnkTextToImage"> Please click on this&nbsp;
                  <Link to="/texttoimagesub">link</Link>&nbsp;to subscribe 'Text To Image'
                    </p>  
					<p id="lnkImageTransorm"> Please click on this&nbsp;
                  <Link to="/imagetransformsub">link</Link>&nbsp;to subscribe 'Image Transform'
                    </p>  
                  
              </div>
              </div>
              <div className="modal-footer">
			  <button type="button" style={{display:"block"}} onClick={this.closeTTISub} id='btncancelTTI' className="common-clear float-start" data-bs-dismiss="modal">Cancel</button>
              </div>
          </div>
      </div>
      </div>

			  </>
			)
		}
    
};

    export default Uploadimage;