import Cookies from 'js-cookie';
import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import loader from "./img/sample.gif";
import $ from 'jquery';
import "./Style.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import logo from "./img/logo.png";


const stripePromise = loadStripe(`${process.env.REACT_APP_Stripe_PK}`);

class PaymentGateway extends React.Component {
// functions start
logoutPay = () =>{
    Cookies.remove("userInfo");
    Cookies.remove("refresh_token");
    Cookies.remove("g_key");
    //document.getElementById('lnkSignInPage').click();
    window.location.href="/";
  }

// functions end

    //Html start
    render() {
        return (
            <div className="container-fluid app-height col-12">

                <div className="row">
                    <div id="overlay">
                        <div id="loading">
                            <h2 id="loaderheading">Loading...</h2>
                            <img src={loader} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 text-center">
                        {/* <img src="https://www.planetre.net/Image/Tlogo.jpg" style="height:40px;" /> */}
                        <a className="large_logo"><img src={logo} style={{maxWidth:"240px",marginBottom:"10px",marginTop:"20px"}}/></a>
                        {/* <h3 style={{ fontWeight: "400", fontSize: "23.5px", marginTop: "15px" }}>Payment Gateway</h3>
                        <h4 style={{ fontWeight: "400", fontSize: "20.5px", marginTop: "15px" }}>Enter your card details </h4>*/}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 text-center">
                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                        </Elements>
                    </div>
                </div>
                <div className="row">
                <div className="col-sm-12 text-center mt-2 anchor-blue">
                <a href="javascript:;" onClick={this.logoutPay}>Log Out </a>     
                                <Link id="lnkSignInPage" to="/" style={{textDecoration:"none",display:"none"}}>
                                Back
                                </Link>
                            </div>  
                            </div>     
            </div>

            )
    }
    //Html end

};

export default PaymentGateway;