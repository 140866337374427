/* eslint-disable react-hooks/exhaustive-deps */
import { default as React, useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import * as io from "socket.io-client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight,faChevronLeft,faInfo,faMicrophone,faInfoCircle, faCircleInfo, faCircleDown, faFile,faTimes } from '@fortawesome/free-solid-svg-icons'
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import { Features } from "../User";
import Cookies from "js-cookie";




const sampleRate = 16000;
var sampletext = "";
const getMediaStream = () => navigator.mediaDevices.getUserMedia({
    audio: {
        deviceId: "default",
        sampleRate: sampleRate,
        sampleSize: 16,
        channelCount: 1,
    },
    video: false,
});
const AudioToTextforEditor = (props) => {
    const [count, setCount] = React.useState(0);
    const [connection, setConnection] = useState();
    const [currentRecognition, setCurrentRecognition] = useState();
    const [recognitionHistory, setRecognitionHistory] = useState([]);
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState();
    const [grammarlykey,setGrammarlykey] = useState(Cookies.get('g_key') != undefined ? Cookies.get('g_key') : '');
    const processorRef = useRef();
    const audioContextRef = useRef();
    const audioInputRef = useRef();
    const speechRecognized = (data) => {
        if (data.isFinal) {
            document.getElementById('editablecontenteditor').innerText += " "+ data.text;
            setEndOfContenteditable();
            // document.getElementById('showloadingeditordiv').style.display = "none";
            setCurrentRecognition("...");
            setRecognitionHistory((old) => [data.text, ...old]);
        }
        else {
            // document.getElementById('showloadingeditordiv').style.display = "inline-block";
            sampletext += data.text;
            setCurrentRecognition(sampletext);
            document.getElementById("speechtotextresulteditor").innerText = data.text;
            // document.getElementById('textareaoutput').value = data.text;
            // var str = new String(data.text);
            // document.getElementById('charcountspan').text = str.length;
        }
    };
    const connect = () => {
        connection === null || connection === void 0 ? void 0 : connection.disconnect();
        //const socket = io.connect("http://localhost:8081");
        // const socket = io.connect("https://nodestt.planetre.net");
        const socket = io.connect("https://ccstt.chocolatechips.ai");
        // const socket = io.connect("https://ccstt.azurewebsites.net");
        socket.on("connect", () => {
            console.log("connected", socket.id);
            setConnection(socket);
            document.getElementById('btnconnecteditor').style.display = "none";
            document.getElementById('btndisconnecteditor').style.display = "block";

            document.getElementById('popuplisteningeditor').style.display = "block";
            document.getElementById('popuplisteneditor').style.display = "none";
        });
        socket.emit("send_message", "hello world");
        socket.emit("startGoogleCloudStream");
        socket.on("receive_message", (data) => {
            console.log("received message", data);
        });
        socket.on("receive_audio_text", (data) => {
            speechRecognized(data);
            console.log("received audio text", data);
        });
        socket.on("disconnect", () => {
            console.log("disconnected", socket.id);
            document.getElementById('btndisconnecteditor').style.display = "none";
            document.getElementById('btnconnecteditor').style.display = "block";

            document.getElementById('popuplisteningeditor').style.display = "none";
            document.getElementById('popuplisteneditor').style.display = "block";

        });
    };
    const disconnect = () => {
        var _a, _b, _c;
        if (!connection)
            return;
        connection === null || connection === void 0 ? void 0 : connection.emit("endGoogleCloudStream");
        connection === null || connection === void 0 ? void 0 : connection.disconnect();
        (_a = processorRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        (_b = audioInputRef.current) === null || _b === void 0 ? void 0 : _b.disconnect();
        (_c = audioContextRef.current) === null || _c === void 0 ? void 0 : _c.close();
        setConnection(undefined);
        setRecorder(undefined);
        setIsRecording(false);
    };
    useEffect(() => {
        (async () => {
            if (connection) {
                if (isRecording) {
                    return;
                }
                const stream = await getMediaStream();
                audioContextRef.current = new window.AudioContext();
                await audioContextRef.current.audioWorklet.addModule("/src/worklets/recorderWorkletProcessor.js");
                audioContextRef.current.resume();
                audioInputRef.current = audioContextRef.current.createMediaStreamSource(stream);
                processorRef.current = new AudioWorkletNode(audioContextRef.current, "recorder.worklet");
                processorRef.current.connect(audioContextRef.current.destination);
                audioContextRef.current.resume();
                audioInputRef.current.connect(processorRef.current);
                processorRef.current.port.onmessage = (event) => {
                    const audioData = event.data;
                    connection.emit("send_audio_data", { audio: audioData });
                };
                setIsRecording(true);
            }
            else {
                console.log("No connection");
            }
        })();

        return () => {
            var _a, _b, _c, _d;
            if (isRecording) {
                (_a = processorRef.current) === null || _a === void 0 ? void 0 : _a.disconnect();
                (_b = audioInputRef.current) === null || _b === void 0 ? void 0 : _b.disconnect();
                if (((_c = audioContextRef.current) === null || _c === void 0 ? void 0 : _c.state) !== "closed") {
                    (_d = audioContextRef.current) === null || _d === void 0 ? void 0 : _d.close();
                }
            }
        };
    }, [connection, isRecording, recorder]);

    const appendtext = () => {
        // props.parentCallback(document.getElementById("speechtotextresulteditor").innerText);
        // document.getElementById("speechtotextresulteditor").innerText = "";
        props.parentCallback(document.getElementById("editablecontenteditor").innerText);
        document.getElementById("editablecontenteditor").innerText = "";
        disconnect();
    }
    const setEndOfContenteditable = () =>
    {
        var range,selection;
        var contentEditableElement = document.getElementById('editablecontenteditor');
        if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
        {
            range = document.createRange();//Create a range (a range is a like the selection but invisible)
            range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            selection = window.getSelection();//get the selection object (allows you to change selection)
            selection.removeAllRanges();//remove any selections already made
            selection.addRange(range);//make the range you have just created the visible selection
        }
        else if(document.selection)//IE 8 and lower
        { 
            range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
            range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
            range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
            range.select();//Select the range (make it the visible selection
        }
    }
    return (
        <>
        <div className="col-12">
        <div className="text-mic-wrap">     
        {
            (Features.GoogleSTT == true ? 
            <>
                <span id="btnconnecteditor" className="mic-icon" data-bs-toggle="modal" data-bs-target="#listeningModaleditor" onClick={connect}><FontAwesomeIcon icon={faMicrophone} /></span>
                <span id="btndisconnecteditor" className="mic-icon" style={{color:"red", display:"none"}} onClick={disconnect}><FontAwesomeIcon icon={faMicrophone} /></span>
            </> : null)
        }
        </div>
    </div>

<div className="modal fade" id="listeningModaleditor" tabIndex={-1} aria-labelledby="listeningModalLabel1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
<div className="modal-dialog">
    <div className="modal-content">
        <div className="modal-header">
            {/* <h5 className="modal-title" id="listeningModalLabel">Speech to text</h5> */}
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={disconnect}></button>
        </div>
        <div className="modal-body" style={{display:"flex",justifyContent:"center",alignItems:"center",minHeight:"350px"}}>   
        <div className="col-12 text-center">
                    <div className="title-card1">
                    <h4 id="popuplisteningeditor" className="text-center"><span style={{fontSize:"40px"}}>Listening...<FontAwesomeIcon icon={faMicrophone} style={{color:"red",fontSize:"40px",paddingLeft:"35px"}} onClick={disconnect}/></span></h4>
                    <h4 id="popuplisteneditor" className="text-center" style={{display:"none"}}><span style={{fontSize:"40px"}}>Stopped...<FontAwesomeIcon icon={faMicrophone} style={{fontSize:"40px",paddingLeft:"35px"}} onClick={connect}/></span></h4>
                        
                    </div>
                    <p id="speechtotextresulteditor" style={{display:'none'}}></p>
                    {
                        grammarlykey != "" ?
                        <GrammarlyEditorPlugin clientId={grammarlykey}>
                            <div contentEditable="true" className="editablediv" suppressContentEditableWarning={true} id="editablecontenteditor">
                            </div>
                        </GrammarlyEditorPlugin> : 
                        <div contentEditable="true" className="editablediv" suppressContentEditableWarning={true} id="editablecontenteditor">
                        </div>
                    }                     
                    <a href="javascript:;" data-bs-dismiss="modal" aria-label="Close" className="common-save" onClick={appendtext}>Add</a>
                </div>
        </div>
    </div>
</div>
</div>
</>
        
        // React.createElement(React.Fragment, null,
        // React.createElement(Container, { className: "py-5 text-center" },
        //     React.createElement(Container, { fluid: true, className: "py-5 bg-primary text-light text-center " },
        //         React.createElement(Container, null,
        //             React.createElement(Button, { className: isRecording ? "btn-danger" : "btn-outline-light", onClick: connect, disabled: isRecording }, "Start"),
        //             React.createElement(Button, { className: "btn-outline-light", onClick: disconnect, disabled: !isRecording }, "Stop"))),
        //     React.createElement(Container, { className: "py-5 text-center" },
        //         recognitionHistory.map((tx, idx) => (React.createElement("p", { key: idx }, tx))),
        //         React.createElement("p", null, recognitionHistory),
        //         React.createElement("p", null, currentRecognition),
        //         React.createElement("input", { type: "text", className: "form-control", id: "speechoutput" }))))
                
                );
};
// const AudioToText = () => {
//   return <h1>Blog Articles</h1>;
// };
 export default AudioToTextforEditor;